import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import { Button, BasicModal as Modal, FormItem, Label } from '@luxe/components';
import { useTheme } from 'emotion-theming';
import { capitalizeFirstLetter } from 'admin/utils';
const { Text } = Typography;

// TODO: Replace HeaderRow in Material && Product groups to use this generic component
const HeaderRow = ({ form, item, itemName, deleteFunction, editFunction, cancelFunction }) => {
  const { space, colors, fontSizes } = useTheme();
  const history = useHistory();
  const backUrl = `/admin/${itemName}-groups/`;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const nameRules = [
    {
      required: true,
      message: `The ${itemName === 'asset' ? 'facility' : itemName} name cannot be blank`,
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: `${space.xsmall}px` }}>
        <Col span={10} align="start">
          <FormItem
            name="name"
            label={`${capitalizeFirstLetter(itemName === 'asset' ? 'facility' : itemName)} Name`}
            labelCol={{ span: 24 }}
            rules={nameRules}
          >
            <Label />
          </FormItem>
        </Col>
        <Col span={14} align="end">
          <Space>
            <Button
              variant="menu"
              type="link"
              form={form}
              onClick={editFunction}
              style={{ backgroundColor: colors.primary }}
            >
              Save
            </Button>
            <Button variant="menu" type="link" onClick={() => (cancelFunction ? cancelFunction() : history.goBack())}>
              Cancel
            </Button>
            <Button variant="menu" type="link" form={form} onClick={() => setDeleteModalVisible(true)}>
              Delete
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        visible={deleteModalVisible}
        title={`Delete ${capitalizeFirstLetter(itemName === 'asset' ? 'facility' : itemName)}?`}
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        onOk={() => {
          setDeleteModalVisible(false);
          deleteFunction(item.id);
          history.push(backUrl);
        }}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          Are you sure that you want to delete {item.name}?
        </Text>
      </Modal>
    </>
  );
};

export default HeaderRow;

HeaderRow.propTypes = {
  itemName: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  deleteFunction: PropTypes.func.isRequired,
  editFunction: PropTypes.func.isRequired,
};

HeaderRow.defaultProps = {};
