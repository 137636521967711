import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BaseSelector from './BaseSelector';

const mapStateToProps = ({ events, filters }) => {
  return {
    events,
    filters,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class WatchtowerSelector extends BaseSelector {
  static propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    events: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
  };

  get options() {
    return this.props.events.items
      .filter(x => x.status_cd === 'active')
      .map(x => {
        return {
          value: x.id,
          label: x.name,
        };
      });
  }

  get initialized() {
    return this.props.events.initialized === true;
  }
}
export default WatchtowerSelector = connect(mapStateToProps, mapDispatchToProps)(WatchtowerSelector);
