import React, { useMemo } from 'react';

import BaseSelector from './BaseSelector';

const UserSelector = ({ name = 'users', valueKey = 'id', ...props }) => {
  const onChange = ({ value }) => {
    props.onChange(value);
  };

  // We have to use useMemo to ensure that this config object doesn't mutate unless
  // the name is changed.. otherwise our object reference will be different every
  // render cycle and use effect inside of base selector will render over and over
  const config = useMemo(() => {
    return {
      name,
      url: 'users/?q=',
      valueKey,
    };
  }, [name, valueKey]);

  return (
    <>
      <BaseSelector name={name} config={config} onChange={onChange} placeholder="Select user" {...props} />
    </>
  );
};
export default UserSelector;
