import { Tabs as aTabs } from 'antd';
import TabView from './TabView';
import PreservedTabView from './PreservedTabView';
import { space, layout, flexbox, position } from 'styled-system';

import styled from '@emotion/styled';


const Tabs = styled(aTabs)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab, .ant-tabs-tab .ant-tabs-tab-btn {
    background-color: ${props => props.theme.colors.black3} !important;
    border-color: ${props => props.theme.colors.black3} !important;
    color: ${props => props.theme.colors.primary} !important;
  }
 .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-active.ant-tabs-tab-active {
    background-color: ${props => props.theme.colors.primaryPressed} !important;
    border-color: ${props => props.theme.colors.primaryPressed};
    color: ${props => props.theme.colors.white};
  }

  .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-active.ant-tabs-tab-active > .ant-tabs-tab-btn {
    background-color: ${props => props.theme.colors.primaryPressed} !important;
    color: ${props => props.theme.colors.white} !important;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    background-color: ${props => props.theme.colors.black3} !important;
    border-color: ${props => props.theme.colors.black3} !important;
    color: ${props => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
   background: ${props => props.theme.colors.primary} !important;
 }

 div.ant-tabs-nav::before {
   display: none;
 }

`;


const TabPane = styled(aTabs.TabPane)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  color: ${props => props.theme.colors.white};
`;

export { TabPane, Tabs, TabView, PreservedTabView }
