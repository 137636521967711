import { filter, map } from 'rxjs/operators';
import { TYPES, getFeedbackResponses } from 'feedback/modules/feedback';

export const getFeedbackEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_FEEDBACK_REQUEST_SUCCESS),
    map(() => {
      const { id } = state$.value.feedback.item;
      return getFeedbackResponses(id);
    }),
  );
};

const epics = {
  getFeedbackEpic,
};
export default epics;
