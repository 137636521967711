import React, { useEffect, useRef } from 'react';
import { Form } from 'antd';
import { Select } from 'layout/Select';
import {
  BasicModal as Modal,
  ModalText,
  FormItem,
  Label as Input,
} from '@luxe/components';

export const MODAL_TYPES_KEYS = {
  ADD: 'ADD',
  BULK_DELETE: 'BULK_DELETE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  ADD_TO_GROUP: 'ADD_TO_GROUP',
  REMOVE_FROM_GROUP: 'REMOVE_FROM_GROUP',
};

const DeleteModal = ({ group, onCancel, isModalShowing, modalSettings }) => {
  const getListOfDeletedNodes = () => {
    let nodes = [];
    const queue = [group];

    while (queue.length !== 0) {
      let currentGroup = queue.shift();
      nodes.push(currentGroup.title);
      if (currentGroup.children) {
        queue.push(...currentGroup.children);
      }
    }

    return nodes;
  };
  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => {
        modalSettings.onOk();
      }}
      onCancel={onCancel}
    >
      <p>The following {modalSettings.modalTitle === "Corporations" ? "corporation" : 'group'} will be deleted, but the {modalSettings.itemName === 'asset' ? 'facilitie' :  modalSettings.itemName}s in them will not:</p>
      <ul>
        {getListOfDeletedNodes().map(group => <li key={group}>{group}</li>)}
      </ul>
    </Modal>
  );
};

const BulkDeleteModal = ({ groupsMap, checkedItems, onCancel, isModalShowing, modalSettings }) => {
  const getListOfDeletedNodes = () => {
    let nodes = [];
    const queue = [...checkedItems];

    while (queue.length !== 0) {
      let currentId = queue.shift();
      let node = groupsMap[currentId];
      if (node !== undefined) {
        nodes.push(node);
      }
    }
    return nodes;
  };

  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => {
        modalSettings.onOk();
      }}
      onCancel={onCancel}
    >
      <p>The following groups will be deleted:</p>
      <ul>
        {getListOfDeletedNodes().map(group => <li key={group}>{group}</li>)}
      </ul>
    </Modal>
  );
};

const AddToGroupModal = ({ groups, checkedItems, itemName, onCancel, isModalShowing, modalSettings, info }) => {
  const modalText = modalSettings.modalTitle === "Corporations" ? "Corporation" : "Group";
  const [form] = Form.useForm();
  const checkedLabels = checkedItems.map(item => item.name);
  const checkedIDs = checkedItems.reduce((ids, item) => {
    if (itemName === 'corporation' && item.corporation) return [...ids, item.corporation.id];
    else if (item.groups) return [...ids, ...item.groups.map(v => v.id)];
    return ids;
  }, []);
  const options = groups
    .filter(g => !checkedIDs.includes(String(g.id)))
    .map(g => ({ label: g.name, value: g.id, parentName: g.parent_name }));
  const item = itemName !== 'corporation' ? checkedItems.map(item => item.id) : checkedItems;
  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      {modalSettings.modalTitle === "Corporations" && <h3 style={{fontSize: '13px', color: 'white'}}>This will remove items from any other corporation. An item can only belong to one corporation.</h3>}
      <ul>
        {checkedLabels.map(label => <li key={label}>{label}</li>)}
      </ul>
      <ModalText style={{marginTop: '20px'}}>{modalText}</ModalText>

      <Form form={form} name="add-to-group" onFinish={(values) => modalSettings.onOk(values.selectedGroup.value, item)}>
        <FormItem name='selectedGroup' rules={[{ required: true, message: 'Please select a group' }]}>
          <Select
            name={'selectedGroup'}
            placeholder="Select a parent"
            options={options}
            formatOptionLabel={function(data) {
              const parentNameLabel = data.parentName ? `<span style="color: #aaa"> - ${data.parentName}</span>` : '';
              const formattedLabel = `${data.label}${parentNameLabel}`;
              return (
                <span dangerouslySetInnerHTML={{ __html: formattedLabel }} />
              );
            }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

const RemoveFromGroupModal = ({ group, checkedItems, itemName, onCancel, isModalShowing, modalSettings, rowData }) => {
  const checkedLabels = checkedItems.map(item => item.name);
  const item = itemName !== 'corporation' ? checkedItems.map(item => item.id) : checkedItems;
  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => {
        modalSettings.onOk(group.id, item);
      }}
      onCancel={onCancel}
    >
      <ul>
        {checkedLabels.map(group => <li key={group}>{group}</li>)}
      </ul>
    </Modal>
  );
};

const AddModal = ({ groups, group, onCancel, isModalShowing, modalSettings }) => {
  const isCorporation = modalSettings.title === "New corporation";
  const groupName = isCorporation ? "Corporation Name" : "Group Name"; 
  const parentName = isCorporation ? "Parent Corporation" : "Parent Group"; 
  const spanStyle = isCorporation ? {span: 8} : {span: 6};

  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, isModalShowing });

  const noParentOption = { label: 'No parent', value: null };
  const options = [noParentOption];
  groups.filter(x => x.id !== group.id).forEach(g => options.push({ label: g.name, value: g.id, parentName: g.parent_name }));

  useEffect(() => {
    const parentOption = group.id !== "all" ? { label: group.title, value: group.id } : noParentOption;
    form.setFieldsValue({ parent: parentOption });
  }, [group, form, noParentOption]);

  const onFinish = values => {
    const data = {
      title: values.name,
      id: group.id,
      parentId: values.parent,
    };
    modalSettings.onOk(data);
    form.resetFields();
  };
  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Form form={form} name="group" onFinish={onFinish}>
        <FormItem name="name" label={groupName} labelCol={spanStyle} rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input placeholder={groupName} width={'100%'} />
        </FormItem>
        {options.length >= 1 && (
          <FormItem name="parent" label={<label style={{ color: 'white' }}>{parentName}</label>} labelCol={spanStyle} >
            <Select
              placeholder="Select a parent"
              options={options}
              formatOptionLabel={function(data) {
                const parentNameLabel = data.parentName ? `<span style="color: #aaa"> - ${data.parentName}</span>` : '';
                const formattedLabel = `${data.label}${parentNameLabel}`;
                return (
                  <span dangerouslySetInnerHTML={{ __html: formattedLabel }} />
                );
              }}
            />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
};

const EditModal = ({ groups, group, onCancel, isModalShowing, modalSettings }) => {
  const isCorporation = modalSettings.modalTitle === "Corporations";
  const spanStyle = isCorporation ? {span: 8} : {span: 6};
  const groupName = isCorporation ? "Corporation Name" : "Group Name"; 
  const parentName = isCorporation ? "Parent Corporation" : "Group Name"; 

  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, isModalShowing });

  const noParentOption = { label: 'No parent', value: null };
  const options = [noParentOption];
  groups.filter(x => x.id !== group.id).forEach(g => options.push({ label: g.name, value: g.id, parentName: g.parent_name }));

  useEffect(() => {
    const parentOption = group.parentId && group.parentName ? { label: group.parentName, value: group.parentId } : noParentOption;
    form.setFieldsValue({ name: group.title !== 'All' ? group.title : '', parent: parentOption });
  }, [group, form, noParentOption]);

  const onFinish = values => {
    const data = {
      title: values.name,
      id: group.id,
      parentId: values.parent,
    };
    modalSettings.onOk(data);
  };

  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Form form={form} name="group" onFinish={onFinish}>
        <FormItem name="name" label={groupName} labelCol={spanStyle} rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input placeholder={groupName} width={'100%'} />
        </FormItem>
        {options.length > 1 && (
          <FormItem name="parent" label={<label style={{ color: 'white' }}>{parentName}</label>} labelCol={spanStyle} >
            <Select
              placeholder="Select a parent"
              options={options}
              formatOptionLabel={function(data) {
                const parentNameLabel = data.parentName ? `<span style="color: #aaa"> - ${data.parentName}</span>` : '';
                const formattedLabel = `${data.label}${parentNameLabel}`;
                return (
                  <span dangerouslySetInnerHTML={{ __html: formattedLabel }} />
                );
              }}
            />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
};

const useResetFormOnCloseModal = ({ form, isModalShowing }) => {
  const prevVisibleRef = useRef();

  useEffect(() => {
    prevVisibleRef.current = isModalShowing;
  }, [isModalShowing]);

  useEffect(() => {
    if (!isModalShowing && prevVisibleRef.current) {
      form.resetFields();
    }
  }, [isModalShowing, form]);
};

// This component is not very react-friendly.
// It renders the same component with different child modals, but those child modals have different needs
// So it renders hooks in different orders which React doesn't like
// Once we refactor AssetGroups and UserGroups this should be removed
export const GroupModal = ({
  groups,
  modalType,
  isModalShowing,
  group,
  groupsMap,
  checkedItems,
  itemName,
  title,
  onAdd,
  onAddToGroup,
  onRemoveFromGroup,
  onEdit,
  onDelete,
  onBulkDelete,
  onCancel,
  rowData,
}) => {
  const MODAL_TYPES = {
    [MODAL_TYPES_KEYS.ADD]: {
      title: 'New Group',
      onOk: treeNode => {
        onAdd(treeNode.title, treeNode.id);
      },
      bodyWidget: AddModal,
    },
    [MODAL_TYPES_KEYS.EDIT]: {
      title: 'Edit',
      onOk: treeNode => {
        onEdit(treeNode.title, treeNode.id, treeNode.parentId);
      },
      bodyWidget: EditModal,
    },
    [MODAL_TYPES_KEYS.DELETE]: {
      title: 'Are you sure you want to delete this group?',
      onOk: () => {
        onDelete(group.id);
      },
      itemName: itemName,
      bodyWidget: DeleteModal,
    },
    [MODAL_TYPES_KEYS.BULK_DELETE]: {
      title: 'Are you sure you want to delete these groups?',
      onOk: () => {
        onBulkDelete();
      },
      bodyWidget: BulkDeleteModal,
    },
    [MODAL_TYPES_KEYS.ADD_TO_GROUP]: {
      title: `Add items to group`,
      onOk: onAddToGroup,
      bodyWidget: AddToGroupModal,
    },
    [MODAL_TYPES_KEYS.REMOVE_FROM_GROUP]: {
      title: 'Remove items from group',
      onOk: onRemoveFromGroup,
      bodyWidget: RemoveFromGroupModal,
    },
  };

  const modalSettings = MODAL_TYPES[modalType];

  if(!modalSettings){
    return null;
  }

  return <>{modalSettings.bodyWidget({ groups, group, groupsMap, checkedItems, modalSettings, onCancel, isModalShowing, rowData })}</>;
};

export const getGroupModalSettings = ({
  modalType,
  group,
  itemName,
  title,
  onAdd,
  onAddToGroup,
  onRemoveFromGroup,
  onEdit,
  onDelete,
  onBulkDelete,
}) => {
  const modalName = title === "Corporations" ? "corporation" : "group";
  const MODAL_TYPES = {
    [MODAL_TYPES_KEYS.ADD]: {
      title: `New ${modalName}`,
      onOk: treeNode => {
        onAdd(treeNode.title, treeNode.parentId, itemName);
      },
      bodyWidget: AddModal,
    },
    [MODAL_TYPES_KEYS.EDIT]: {
      title: 'Edit',
      onOk: treeNode => {
        onEdit(treeNode.title, treeNode.id, treeNode.parentId);
      },
      modalTitle: title,
      bodyWidget: EditModal,
    },
    [MODAL_TYPES_KEYS.DELETE]: {
      title: `Are you sure you want to delete this ${title === "Corporations" ? "corporation" : "group"}`,
      onOk: () => {
        onDelete(group.id);
      },
      itemName: itemName,
      modalTitle: title,
      bodyWidget: DeleteModal,
    },
    [MODAL_TYPES_KEYS.BULK_DELETE]: {
      title: 'Are you sure you want to delete these groups?',
      onOk: () => {
        onBulkDelete();
      },
      bodyWidget: BulkDeleteModal,
    },
    [MODAL_TYPES_KEYS.ADD_TO_GROUP]: {
      title: `Add items to ${modalName}`,
      modalTitle: title,
      bodyWidget: AddToGroupModal,
      onOk: onAddToGroup
    },
    [MODAL_TYPES_KEYS.REMOVE_FROM_GROUP]: {
      title: `Remove items from ${title === "Corporations" ? (itemName==='asset'?'facility':itemName) : "group"} ${group?.title ? group.title : ''}`,
      onOk: onRemoveFromGroup,
      bodyWidget: RemoveFromGroupModal,
    },
  };

  return MODAL_TYPES[modalType];
};

export default GroupModal;
