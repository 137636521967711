import React from 'react';

import { DatePicker, aRangePicker as RangePicker } from '@luxe/components';
import moment from 'moment';
const convertToLocal = date => moment(date);
const obj = {
  '>': ' 23:59:59.999999',
  '>=': ' 00:00:00.000000',
  '<': ' 00:00:00.000000',
  '<=': ' 23:59:59.999999',
};
/*
if the user choose 1/4/2023, 
>  2023-01-04 23:59:59
>= 2023-01-04 00:00:00
<  2023-01-04 00:00:00
<= 2023-01-04 23:59:59
between [2023-01-04T00:00:00, 2023-01-04T23:59:59]
then convert it to UTC
**/

const DateSelector = ({ filter, ...props }) => {
  const { operator, name } = filter;
  const date =
    operator === 'between'
      ? filter.value
        ? [convertToLocal(filter.value[0]), convertToLocal(filter.value[1])]
        : [null, null]
      : filter.value
      ? convertToLocal(filter.value)
      : null;
  const onChange = (value, dateString) => {
    value =
      operator === 'between'
        ? [`${dateString[0]} ${obj['>=']}`, `${dateString[1]} ${obj['<=']}`]
        : `${dateString} ${obj[operator]}`;
    props.onChange({
      value,
      operator,
      name,
    });
  };

  return (
    <>
      <div>Last Update</div>
      {operator === 'between' ? (
        <RangePicker onChange={onChange} value={date} />
      ) : (
        <DatePicker onChange={onChange} value={date} />
      )}
    </>
  );
};
export default DateSelector;
