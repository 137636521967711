import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_USER_PROFILE_NOTIFICATIONS: 'GET_USER_PROFILE_NOTIFICATIONS',
  GET_USER_PROFILE_NOTIFICATIONS_ERROR: 'GET_USER_PROFILE_NOTIFICATIONS_ERROR',
  GET_USER_PROFILE_NOTIFICATIONS_SUCCESS: 'GET_USER_PROFILE_NOTIFICATIONS_SUCCESS',

  GET_USER_CHANNELS: 'GET_USER_CHANNELS',
  GET_USER_CHANNELS_ERROR: 'GET_USER_CHANNELS_ERROR',
  GET_USER_CHANNELS_SUCCESS: 'GET_USER_CHANNELS_SUCCESS',

  SET_USER_CHANNELS: 'SET_USER_CHANNELS',
  SET_USER_CHANNELS_ERROR: 'SET_USER_CHANNELS_ERROR',
  SET_USER_CHANNELS_SUCCESS: 'SET_USER_CHANNELS_SUCCESS',

  CANCEL_USER_PROFILE_NOTIFICATIONS: 'CANCEL_USER_PROFILE_NOTIFICATIONS',
};

export const INITIAL_STATE = {};

export const getUserProfileChannels = () => {
  return callApi({
    types: {
      pending: TYPES.GET_USER_PROFILE_NOTIFICATIONS,
      success: TYPES.GET_USER_PROFILE_NOTIFICATIONS_SUCCESS,
      error: TYPES.GET_USER_PROFILE_NOTIFICATIONS_ERROR,
    },
    request: () => axios.get(`account/user/channels/`),
  });
};

export const getProfileChannelsFromId = userId => {
  return callApi({
    types: {
      pending: TYPES.GET_USER_CHANNELS,
      success: TYPES.GET_USER_CHANNELS_SUCCESS,
      error: TYPES.GET_USER_CHANNELS_ERROR,
    },
    request: () => axios.get(`account/user/${userId}/channels/`),
  });
};

export const putProfileChannelsFromId = (userId, data) => {
  return callApi({
    types: {
      pending: TYPES.SET_USER_CHANNELS,
      success: TYPES.SET_USER_CHANNELS_SUCCESS,
      error: TYPES.SET_USER_CHANNELS_ERROR,
    },
    request: () => axios.put(`account/user/${userId}/channels/`, data),
    messages: {
      success: 'Settings saved successfully!',
      error: 'Save unsuccessful',
    },
  });
};

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.GET_USER_PROFILE_NOTIFICATIONS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_USER_PROFILE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        channels: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_USER_PROFILE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_USER_CHANNELS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_USER_CHANNELS_SUCCESS:
      return {
        ...state,
        userChannels: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_USER_CHANNELS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SET_USER_CHANNELS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.SET_USER_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.SET_USER_CHANNELS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
