import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import { updateInList, deleteFromListById } from 'admin/utils';
import { transformGraph } from 'assets/modules/graphs';
import { Graph } from './GraphProvider';
import { GRAPH_MODES, NETWORK_LINKS_LIMIT } from 'common/Constants';

const VIEW_TYPE = 'network_graph';

export const TYPES = {
  INIT: 'INIT',
  NO_ACTION: 'NO_ACTION',
  CLEARED_STATE: 'CLEARED_STATE',

  // Graph
  GET_NETWORK_ASSET_GRAPH: 'GET_NETWORK_ASSET_GRAPH',
  GET_NETWORK_ASSET_GRAPH_PENDING: 'GET_NETWORK_ASSET_GRAPH_PENDING',
  GET_NETWORK_ASSET_GRAPH_ERROR: 'GET_NETWORK_ASSET_GRAPH_ERROR',

  // Saved Views
  CREATE_NETWORK_GRAPH_VIEW: 'CREATE_NETWORK_GRAPH_VIEW',
  CREATE_NETWORK_GRAPH_VIEW_ERROR: 'CREATE_NETWORK_GRAPH_VIEW_ERROR',
  CREATE_NETWORK_GRAPH_VIEW_SUCCESS: 'CREATE_NETWORK_GRAPH_VIEW_SUCCESS',

  GET_NETWORK_GRAPH_VIEWS: 'GET_NETWORK_GRAPH_VIEWS',
  GET_NETWORK_GRAPH_VIEWS_ERROR: 'GET_NETWORK_GRAPH_VIEWS_ERROR',
  GET_NETWORK_GRAPH_VIEWS_SUCCESS: 'GET_NETWORK_GRAPH_VIEWS_SUCCESS',

  UPDATE_NETWORK_GRAPH_VIEW: 'UPDATE_NETWORK_GRAPH_VIEW',
  UPDATE_NETWORK_GRAPH_VIEW_ERROR: 'UPDATE_NETWORK_GRAPH_VIEW_ERROR',
  UPDATE_NETWORK_GRAPH_VIEW_SUCCESS: 'UPDATE_NETWORK_GRAPH_VIEW_SUCCESS',

  DELETE_NETWORK_GRAPH_VIEW: 'DELETE_NETWORK_GRAPH_VIEW',
  DELETE_NETWORK_GRAPH_VIEW_ERROR: 'DELETE_NETWORK_GRAPH_VIEW_ERROR',
  DELETE_NETWORK_GRAPH_VIEW_SUCCESS: 'DELETE_NETWORK_GRAPH_VIEW_SUCCESS',

  // Viz filters
  TOGGLED_FILTER_VISIBILITY: 'TOGGLED_FILTER_VISIBILITY',
  CHANGED_GRAPH_FILTERS: 'CHANGED_GRAPH_FILTERS',
  CHANGED_GRAPH_VIEW: 'CHANGED_GRAPH_VIEW',
  CHANGED_GRAPH_MODE: 'CHANGED_GRAPH_MODE',
  UPDATE_GRAPH_FILTERS: 'UPDATE_GRAPH_FILTERS',
  UPDATE_NODE_COLOR: 'UPDATE_NODE_COLOR',
  UPDATE_NODEID_URL: 'UPDATE_NODEID_URL',
  CLEAR_NODEID_URL: 'CLEAR_NODEID_URL',
  SHOW_FACILITY_DRAWER_ON_URL: 'SHOW_FACILITY_DRAWER_ON_URL',

  // Network View UI
  CHANGED_TABLE_VISIBILITY: 'CHANGED_TABLE_VISIBILITY',
  CHANGED_DRAWER_VISIBILITY: 'CHANGED_DRAWER_VISIBILITY',
  CHANGED_SUBTIER_VISIBILITY: 'CHANGED_SUBTIER_VISIBILITY',
  CHANGED_DRAWER_ASSET_NODE: 'CHANGED_DRAWER_ASSET_NODE',
  CHANGED_SUBTIER_DATA: 'CHANGED_SUBTIER_DATA',

  // Camera
  ZOOMED_CAMERA: 'ZOOMED_CAMERA',
  ZOOMED_TO_FIT_CAMERA: 'ZOOMED_TO_FIT_CAMERA',
  REVERTED_CAMERA: 'REVERTED_CAMERA',

  // Graph State
  FOCUS_NODE: 'FOCUS_NODE',
  UNFOCUS_NODE: 'UNFOCUS_NODE',
  SELECTED_NODE: 'SELECTED_NODE',
  DESELECTED_NODE: 'DESELECTED_NODE',
  SELECT_NODE_THROUGH_SEARCH: 'SELECT_NODE_THROUGH_SEARCH',
  CLEAR_SEARCHED_NODE: 'CLEAR_SEARCHED_NODE',
  SELECTED_LINKS: 'SELECTED_LINKS',
  UPDATE_VISIBLE_EDGES: 'UPDATE_VISIBLE_EDGES',
  CHANGED_HIDDEN: 'CHANGED_HIDDEN',
  CHANGED_COOLDOWN: 'CHANGED_COOLDOWN',
  CHANGED_VELOCITY: 'CHANGED_VELOCITY',
  CENTERED_ON_NODE: 'CENTERED_ON_NODE',
  UPDATE_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES: 'UPDATE_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES',
  RESET_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES: 'RESET_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES',
  UPDATE_DISCOVERED_FACILITIES_TOGGLE: 'UPDATE_DISCOVERED_FACILITIES_TOGGLE',

  // Saving and Updating Views Modal
  HID_SAVE_MODAL: 'HID_SAVE_MODAL',
  SHOWED_SAVE_MODAL: 'SHOWED_SAVE_MODAL',
  HID_DELETE_MODAL: 'HID_DELETE_MODAL',
  SHOWED_DELETE_MODAL: 'SHOWED_DELETE_MODAL',

  // Table state
  CHANGED_DEPENDENCY_TABLE_TOGGLE: 'CHANGED_DEPENDENCY_TABLE_TOGGLE',

  // Drawer tabs
  GET_ASSET_SUMMARY: 'GET_ASSET_SUMMARY',
  GET_ASSET_SUMMARY_ERROR: 'GET_ASSET_SUMMARY_ERROR',
  GET_ASSET_SUMMARY_SUCCESS: 'GET_ASSET_SUMMARY_SUCCESS',
  CLEAR_ASSET_SUMMARY: 'CLEAR_ASSET_SUMMARY',

  SET_INCIDENT_ID: 'SET_INCIDENT_ID',
  FETCH_NETWORK_ASSET_GRAPH_DETAILS: 'FETCH_NETWORK_ASSET_GRAPH_DETAILS',
  GET_NETWORK_ASSET_GRAPH_DETAILS: 'GET_NETWORK_ASSET_GRAPH_DETAILS',
  CLEAR_INCIDENT_DETAILS: 'CLEAR_INCIDENT_DETAILS',
  CLEAR_SELECTED_VIEW: 'CLEAR_SELECTED_VIEW',

  GET_IMPACTED_ASSETS: 'GET_IMPACTED_ASSETS',
  GET_IMPACTED_ASSETS_SUCCESS: 'GET_IMPACTED_ASSETS_SUCCESS',
  GET_IMPACTED_ASSETS_ERROR: 'GET_IMPACTED_ASSETS_ERROR',

  RESET_INITIAL_NODEID_UPDATE: 'RESET_INITIAL_NODEID_UPDATE',
};

export const initNetworkGraph = () => {
  return {
    type: TYPES.INIT,
  };
};

export const clearNetworkGraphState = () => {
  return {
    type: TYPES.CLEARED_STATE,
  };
};

export const resetInitialNodeIdUrl = flag => {
  return {
    type: TYPES.RESET_INITIAL_NODEID_UPDATE,
    payload: flag,
  };
};

export const getNetworkAssetGraph = (data, incId, facilityName, isMaterialDiscoverEnabled) => {
  let prefix = '?';
  let filteredURL = `graph/facilities/`;
  const allowed = [
    'limit',
    'max_depth',
    'countries',
    'hs_codes',
    'facilities',
    'skip',
    'order_by',
    'product',
    'asset_group',
    'material',
    'risk',
    'node_color',
    'incident_risk',
    'min_relationship_strength',
    'max_relationship_strength',
    'commodities',
  ];
  if (isMaterialDiscoverEnabled) allowed.push('discover_facility');
  const lists = ['product', 'facilities', 'asset_group', 'material', 'risk', 'incident_risk', 'commodities'];
  data.limit = data.graphMode === GRAPH_MODES.TIERED_2D ? NETWORK_LINKS_LIMIT.tiered2D : NETWORK_LINKS_LIMIT.default;
  for (const [key, value] of Object.entries(data)) {
    if (allowed.includes(key)) {
      if (key === 'countries') {
        if (data.countries?.length > 0) {
          filteredURL = filteredURL + `${prefix}country=${value}`;
        }
      } else if (key === 'hs_codes') {
        if (data.hs_codes.length > 0) {
          filteredURL = filteredURL + `${prefix}hscode6=${value}`;
        }
      } else if (key === 'discover_facility') {
        filteredURL = filteredURL + `${prefix}${key}=${value}`;
      } else if (lists.includes(key)) {
        if (data[key].length > 0) {
          filteredURL = filteredURL + `${prefix}${key}=${value}`;
        }
      } else if (key === 'node_color') {
        if (value === 'INCIDENT_RISK_SCORE' || value === 'TIER') {
          filteredURL += `${prefix}order_by=reactive__max_score`;
        } else {
          filteredURL += `${prefix}order_by=score`;
        }
      } else if (value) {
        filteredURL = filteredURL + `${prefix}${key}=${value}`;
      }
      prefix = '&';
    }
  }
  if (incId) {
    filteredURL += `&threat_id=${incId}`;
  }

  return callApi({
    types: {
      success: !incId ? TYPES.GET_NETWORK_ASSET_GRAPH : TYPES.FETCH_NETWORK_ASSET_GRAPH_DETAILS,
      pending: TYPES.GET_NETWORK_ASSET_GRAPH_PENDING,
      error: TYPES.GET_NETWORK_ASSET_GRAPH_ERROR,
    },
    request: () => axios.get(filteredURL),
  });
};

export const createNetworkGraphView = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_NETWORK_GRAPH_VIEW,
      success: TYPES.CREATE_NETWORK_GRAPH_VIEW_SUCCESS,
      error: TYPES.CREATE_NETWORK_GRAPH_VIEW_ERROR,
    },
    params: data,
    request: () => axios.post(`views/?type=network_graph`, { ...data, view_type: 'network_graph' }),
    messages: {
      success: 'Network Graph View has been successfully created!',
      error: 'Network Graph View creation was unsuccessful',
    },
    dynamicErrorMsg: true,
  });

export const getNetworkGraphViews = params =>
  callApi({
    types: {
      pending: TYPES.GET_NETWORK_GRAPH_VIEWS,
      success: TYPES.GET_NETWORK_GRAPH_VIEWS_SUCCESS,
      error: TYPES.GET_NETWORK_GRAPH_VIEWS_ERROR,
    },
    params: params,
    request: () => axios.get(`views/?type=network_graph`),
  });

export const updateNetworkGraphView = (id, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_NETWORK_GRAPH_VIEW,
      success: TYPES.UPDATE_NETWORK_GRAPH_VIEW_SUCCESS,
      error: TYPES.UPDATE_NETWORK_GRAPH_VIEW_ERROR,
    },
    params: data,
    request: () => axios.put(`views/${id}/?type=network_graph`, data),
    messages: {
      success: 'Network Graph View has been successfully updated!',
      error: 'Network Graph View update was unsuccessful',
    },
  });

export const deleteNetworkGraphView = id =>
  callApi({
    types: {
      pending: TYPES.DELETE_NETWORK_GRAPH_VIEW,
      success: TYPES.DELETE_NETWORK_GRAPH_VIEW_SUCCESS,
      error: TYPES.DELETE_NETWORK_GRAPH_VIEW_ERROR,
    },
    request: () => axios.delete(`views/${id}/?type=network_graph`),
    messages: {
      success: 'Network Graph View has been successfully deleted!',
      error: 'Network Graph View delete was unsuccessful',
    },
  });

export const getAssetSummary = assetId => {
  return callApi({
    types: {
      pending: TYPES.GET_ASSET_SUMMARY,
      success: TYPES.GET_ASSET_SUMMARY_SUCCESS,
      error: TYPES.GET_ASSET_SUMMARY_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/summary/`),
  });
};

export const getImpactedAssets = (inc_ID, nextUrl) => {
  return {
    type: TYPES.GET_IMPACTED_ASSETS,
  };
};

// Commenting for DIS-411 - Filter is hidden
// export const getImpactedAssets = (inc_ID, nextUrl) => {
//   let queryString = `?limit=300&offset=0`;
//   let offsetValue;
//   if (nextUrl) {
//     const query = new URLSearchParams(nextUrl);
//     offsetValue = query.get('offset');
//     queryString = `?limit=300&offset=${offsetValue}`;
//   }
//   const payload = {
//     columns: ['asset'],
//     offset: offsetValue ? offsetValue : '0',
//     limit: 300,
//     filters: [
//       {
//         name: 'threat_id',
//         operator: '==',
//         value: inc_ID,
//       },
//       {
//         name: 'system_type',
//         operator: 'in',
//         value: [1, 3],
//       },
//     ],
//     order_by: [],
//   };
//   return callApi({
//     types: {
//       pending: TYPES.GET_IMPACTED_ASSETS,
//       success: TYPES.GET_IMPACTED_ASSETS_SUCCESS,
//       error: TYPES.GET_IMPACTED_ASSETS_ERROR,
//     },
//     request: () => axios.post(`assets/query/${queryString}`, payload),
//   });
// };

export const clearAssetSummary = () => {
  return {
    type: TYPES.CLEAR_ASSET_SUMMARY,
  };
};

export const showSaveModal = () => {
  return {
    type: TYPES.SHOWED_SAVE_MODAL,
  };
};

export const hideSaveModal = () => {
  return {
    type: TYPES.HID_SAVE_MODAL,
  };
};

export const showDeleteModal = () => {
  return {
    type: TYPES.SHOWED_DELETE_MODAL,
  };
};

export const hideDeleteModal = () => {
  return {
    type: TYPES.HID_DELETE_MODAL,
  };
};

export const updateGraphFilters = filters => {
  return {
    type: TYPES.UPDATE_GRAPH_FILTERS,
    payload: filters,
  };
};

export const updateDiscoveredFacilitiesToggle = data => {
  return {
    type: TYPES.UPDATE_DISCOVERED_FACILITIES_TOGGLE,
    payload: data,
  };
};

export const updateGraphNodeColor = color => {
  return {
    type: TYPES.UPDATE_NODE_COLOR,
    payload: { node_color: color },
  };
};

export const changeGraphFilters = filters => {
  return {
    type: TYPES.CHANGED_GRAPH_FILTERS,
    payload: filters,
  };
};

export const clearGraphFilters = () => {
  return {
    type: TYPES.CHANGED_GRAPH_FILTERS,
    payload: {
      node_color: NODE_COLOR_INCIDENT_RISK_SCORE,
      graphMode: GRAPH_MODES.DEFAULT,
      max_depth: 1,
      asset_group: [],
      countries: [],
      facilities: [],
      hs_codes: [],
      material: [],
      product: [],
      risk: [],
      discover_facility: true,
      incident_risk: [],
      min_relationship_strength: null,
      max_relationship_strength: null,
      commodities: [],
    },
  };
};

export const setFilterPanelVisibility = visible => {
  return {
    type: TYPES.TOGGLED_FILTER_VISIBILITY,
    payload: visible,
  };
};

export const changeCooldown = cooldown => {
  return {
    type: TYPES.CHANGED_COOLDOWN,
    payload: cooldown,
  };
};

export const changeVelocity = velocity => {
  return {
    type: TYPES.CHANGED_VELOCITY,
    payload: velocity,
  };
};

export const changeGraphMode = (currentMode, newMode) => {
  return {
    type: TYPES.CHANGED_GRAPH_MODE,
    payload: { currentMode, newMode },
  };
};

export const changeGraphView = selectedViewId => {
  return {
    type: TYPES.CHANGED_GRAPH_VIEW,
    payload: selectedViewId,
  };
};

export const zoomToFitCamera = enabled => {
  return {
    type: TYPES.ZOOMED_TO_FIT_CAMERA,
    payload: enabled,
  };
};

export const zoomCamera = zoom => {
  return {
    type: TYPES.ZOOMED_CAMERA,
    payload: zoom,
  };
};

export const centerOnNode = node => {
  return {
    type: TYPES.CENTERED_ON_NODE,
    payload: node,
  };
};

export const revertCamera = () => {
  return {
    type: TYPES.REVERTED_CAMERA,
  };
};

export const selectNode = node => {
  return {
    type: TYPES.SELECTED_NODE,
    payload: node,
  };
};

export const selectNodeThroughSearch = nodeId => {
  return {
    type: TYPES.SELECT_NODE_THROUGH_SEARCH,
    payload: nodeId,
  };
};

export const updateSelectedNodeIdFromURL = node => {
  return {
    type: TYPES.UPDATE_NODEID_URL,
    payload: node,
  };
};

export const showFacilityDraweronURL = nodes => {
  return {
    type: TYPES.SHOW_FACILITY_DRAWER_ON_URL,
    payload: nodes,
  };
};

export const clearSelectedNodeIdFromURL = () => {
  return {
    type: TYPES.CLEAR_NODEID_URL,
  };
};

export const clearSearchedNode = () => {
  return {
    type: TYPES.CLEAR_SEARCHED_NODE,
  };
};

export const deselectNode = node => {
  return {
    type: TYPES.DESELECTED_NODE,
    payload: node,
  };
};

export const focusNode = nodeId => {
  return {
    type: TYPES.FOCUS_NODE,
    payload: nodeId,
  };
};

export const unfocusNode = nodeId => {
  return {
    type: TYPES.UNFOCUS_NODE,
    payload: nodeId,
  };
};

export const updateVisibleEdges = updatedEdges => {
  return {
    type: TYPES.UPDATE_VISIBLE_EDGES,
    payload: updatedEdges,
  };
};

export const updateNetworkGraphVisibleEdgesAndNodes = (edges, nodes) => {
  return {
    type: TYPES.UPDATE_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES,
    payload: { edges, nodes },
  };
};

export const resetNetworkGraphVisibleEdgesAndNodes = () => {
  return {
    type: TYPES.RESET_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES,
  };
};

// List table
export const setTableVisibility = visible => {
  return {
    type: TYPES.CHANGED_TABLE_VISIBILITY,
    payload: visible,
  };
};

export const setDrawerVisibility = visible => {
  return {
    type: TYPES.CHANGED_DRAWER_VISIBILITY,
    payload: visible,
  };
};

export const setSubTierVisibility = visible => {
  return {
    type: TYPES.CHANGED_SUBTIER_VISIBILITY,
    payload: visible,
  };
};

export const setSubTierData = data => {
  return {
    type: TYPES.CHANGED_SUBTIER_DATA,
    payload: data,
  };
};

export const clearSubTierData = () => {
  return {
    type: TYPES.CHANGED_SUBTIER_DATA,
    payload: DEFAULT_SUBTIER,
  };
};

export const changeDependencyTableToggle = value => {
  return {
    type: TYPES.CHANGED_DEPENDENCY_TABLE_TOGGLE,
    payload: value,
  };
};

export const setIncidentId = data => {
  return {
    type: TYPES.SET_INCIDENT_ID,
    payload: data,
  };
};

export const getAssetGraphOnFilters = () => {
  return {
    type: TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS,
  };
};

export const clearIncidentDetails = () => {
  return {
    type: TYPES.CLEAR_INCIDENT_DETAILS,
  };
};

export const clearSelectedView = () => {
  return {
    type: TYPES.CLEAR_SELECTED_VIEW,
  };
};

export const DEFAULT_CENTER = {
  x: 0,
  y: 0,
  z: 50,
};

export const DEFAULT_ZOOM_DURATION = 50;

export const DEFAULT_CAMERA = {
  position3d: DEFAULT_CENTER,
  centeredAt: DEFAULT_CENTER,
  zoom: 0,
  zoomToFitDuration: DEFAULT_ZOOM_DURATION,
  zoomedToFitCamera: false,
  ms: 50,
};

const DEFAULT_SAVE_MODAL = {
  view_type: VIEW_TYPE,
  currentViewName: '',
  publicChecked: false,
  userChangedPublic: false,
  isDefaultChecked: false,
  userChangedIsDefault: false,
};

const DEFAULT_SUBTIER = {
  list: {
    nodes: [],
    edges: [],
  },
  direction: 'Previous',
};

export const REFRESH_COOLDOWN = 1600;
export const STOP_COOLDOWN = 0;
export const REFRESH_VELOCITY = 0.25;
export const STOP_VELOCITY = 0.95;

export const DEPS_TABLE_STATE_FACILITIES = 'Facility';
export const DEPS_TABLE_STATE_COUNTRIES = 'Country';

export const NODE_COLOR_TIER = 'TIER';
export const NODE_COLOR_RISK_SCORE = 'RISK_SCORE';
export const NODE_COLOR_INCIDENT_RISK_SCORE = 'INCIDENT_RISK_SCORE';
export const NODE_COLORS = {
  [NODE_COLOR_TIER]: {
    0: '#2D8FA5',
    1: '#A94A73',
    2: '#6AB149',
    3: '#4A6AB1',
    default: '#7A7A7A',
  },
  [NODE_COLOR_RISK_SCORE]: {
    low: '#108904',
    medium: '#ff8600',
    high: '#d32100',
    default: '#7A7A7A',
  },
  [NODE_COLOR_INCIDENT_RISK_SCORE]: {
    low: '#108904',
    medium: '#ff8600',
    high: '#d32100',
    default: '#7A7A7A',
  },
};

const DEFAULT_STATE = {
  status: undefined,
  error: undefined,
  params: undefined,
  // Domain state
  graph: new Graph(),
  viewsNetworkGraph: [],
  // App state
  visibleNodes: [],
  visibleEdges: [],
  collapsedEdges: [],
  cooldown: REFRESH_COOLDOWN,
  velocity: REFRESH_VELOCITY,
  filters: {
    node_color: NODE_COLOR_INCIDENT_RISK_SCORE,
    graphMode: GRAPH_MODES.DEFAULT,
    max_depth: 1,
    asset_group: [],
    countries: [],
    facilities: [],
    hs_codes: [],
    material: [],
    product: [],
    risk: [],
    discover_facility: true,
    incident_risk: [],
    min_relationship_strength: null,
    max_relationship_strength: null,
    commodities: [],
  },
  selectedView: undefined,
  camera: DEFAULT_CAMERA,
  prevCamera: DEFAULT_CAMERA,
  nodeLabel: true,
  selectedNode: null,
  focusedNodeId: null,
  fetchedNodeIdFromURL: null,
  searchedNodeId: null,
  selectedLinks: [],
  filterDisplayName: {},
  saveModal: DEFAULT_SAVE_MODAL,
  subTierData: DEFAULT_SUBTIER,
  drawerAssetNode: { id: null },
  statusLoading: true,
  // UI state
  filterPanelVisible: false,
  saveModalVisible: false,
  deleteModalVisible: false,
  tableVisible: false,
  drawerVisible: false,
  subTierShowing: false,
  limitExceeded: false,
  zoomToFit: false,
  // Table state
  depsTableToggleState: DEPS_TABLE_STATE_FACILITIES,
  // Incident
  incidentId: null,
  nextImpactedAssetsURL: null,
  impactedAssets: [],
  initialNodeIdUpdate: null,
};

export const networkGraphReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TYPES.CLEARED_STATE:
      return {
        ...state,
        filters: { ...state.filters, graphMode: GRAPH_MODES.DEFAULT },
        cooldown: REFRESH_COOLDOWN,
        velocity: REFRESH_VELOCITY,
      };
    // Graph
    case TYPES.GET_NETWORK_ASSET_GRAPH:
    case TYPES.FETCH_NETWORK_ASSET_GRAPH_DETAILS:
      const newGraph = transformGraph(action.payload);
      const enableDirectedGraph = state.filters.graphMode === GRAPH_MODES.TIERED_2D;
      const graph = state.graph.loadGraph(newGraph, enableDirectedGraph);
      return {
        ...state,
        graph,
        statusLoading: false,
        visibleNodes: [],
        visibleEdges: [],
        status: REQUESTED_DATA_STATUS,
        subTierData: DEFAULT_SUBTIER,
        tableVisible: false,
        subTierShowing: false,
        limitExceeded: action.payload.limit_exceeded,
      };
    case TYPES.GET_NETWORK_ASSET_GRAPH_PENDING:
      return {
        ...state,
        statusLoading: true,
        graph: new Graph(),
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_NETWORK_ASSET_GRAPH_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    // Saved Views
    case TYPES.CREATE_NETWORK_GRAPH_VIEW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: {},
      };
    case TYPES.CREATE_NETWORK_GRAPH_VIEW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CREATE_NETWORK_GRAPH_VIEW_SUCCESS:
      action.payload.is_default_view = action?.params?.is_default;
      const viewList = action?.params?.is_default
        ? state.viewsNetworkGraph?.map(x => {
            x.is_default_view = false;
            return x;
          })
        : state.viewsNetworkGraph;
      const newNetworkGraphView = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        viewsNetworkGraph: [...viewList, newNetworkGraphView],
        selectedView: newNetworkGraphView.id,
      };
    case TYPES.GET_NETWORK_GRAPH_VIEWS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        viewsNetworkGraphStatus: TYPES.GET_NETWORK_GRAPH_VIEWS,
        params: {},
      };
    case TYPES.GET_NETWORK_GRAPH_VIEWS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        viewsNetworkGraphStatus: TYPES.GET_NETWORK_GRAPH_VIEWS_ERROR,
        error: action.payload,
      };
    case TYPES.GET_NETWORK_GRAPH_VIEWS_SUCCESS:
      const updatedViews = action.payload.map(x => {
        x.is_default_view = x?.id === action?.params;
        return x;
      });
      const viewsNetworkGraphSelect = action.payload.map(savedFilter => {
        return { label: savedFilter.name, value: savedFilter.id };
      });
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        viewsNetworkGraphStatus: TYPES.GET_NETWORK_GRAPH_VIEWS_SUCCESS,
        viewsNetworkGraph: updatedViews,
        viewsNetworkGraphSelect,
        viewsFetched: true,
      };
    case TYPES.UPDATE_NETWORK_GRAPH_VIEW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: {},
      };
    case TYPES.UPDATE_NETWORK_GRAPH_VIEW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_NETWORK_GRAPH_VIEW_SUCCESS:
      action.payload.is_default_view = action?.params?.is_default;
      const updatedViewList = action?.params?.is_default
        ? state.viewsNetworkGraph?.map(x => {
            x.is_default_view = false;
            return x;
          })
        : state.viewsNetworkGraph;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        viewsNetworkGraph: [...updateInList(action.payload, updatedViewList)],
        selectedView: action.payload,
      };
    case TYPES.DELETE_NETWORK_GRAPH_VIEW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: {},
      };
    case TYPES.DELETE_NETWORK_GRAPH_VIEW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_NETWORK_GRAPH_VIEW_SUCCESS:
      const deletedNetworkGraphView = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        viewsNetworkGraph: [...deleteFromListById(deletedNetworkGraphView, state.viewsNetworkGraph)],
        selectedView: state?.selectedView?.id === deletedNetworkGraphView ? null : state?.selectedView,
      };
    // Network View UI
    case TYPES.CHANGED_TABLE_VISIBILITY:
      return {
        ...state,
        tableVisible: action.payload,
        drawerVisible: action.payload ? false : state.drawerVisible,
      };
    case TYPES.CHANGED_DRAWER_VISIBILITY:
      return {
        ...state,
        drawerVisible: action.payload,
        tableVisible: action.payload ? false : state.tableVisible,
      };
    case TYPES.CHANGED_SUBTIER_VISIBILITY:
      return {
        ...state,
        subTierShowing: action.payload,
      };
    case TYPES.CHANGED_DRAWER_ASSET_NODE:
      return {
        ...state,
        drawerAssetNode: action.payload,
      };
    case TYPES.CHANGED_SUBTIER_DATA:
      return {
        ...state,
        subTierData: action.payload,
      };
    // Viz Filters
    case TYPES.TOGGLED_FILTER_VISIBILITY:
      return {
        ...state,
        filterPanelVisible: action.payload,
      };
    case TYPES.CHANGED_COOLDOWN:
      if (state.cooldown !== action.payload) {
        return {
          ...state,
          cooldown: action.payload,
        };
      }
      return state;
    case TYPES.CHANGED_VELOCITY:
      if (state.velocity !== action.payload) {
        return {
          ...state,
          velocity: action.payload,
        };
      }
      return state;
    case TYPES.UPDATE_GRAPH_FILTERS:
    case TYPES.CHANGED_GRAPH_FILTERS:
    case TYPES.UPDATE_DISCOVERED_FACILITIES_TOGGLE:
    case TYPES.UPDATE_NODE_COLOR:
      let changed_graph_filters_changes = {
        filters: { ...state.filters, ...action.payload },
      };
      if (state.selectedNode !== null && action.type !== TYPES.UPDATE_GRAPH_FILTERS) {
        changed_graph_filters_changes = { ...changed_graph_filters_changes, selectedNode: null };
      }
      return {
        ...state,
        ...changed_graph_filters_changes,
      };
    case TYPES.CHANGED_GRAPH_MODE:
      let cgm_changes = { cooldown: REFRESH_COOLDOWN };
      if (state.filters.graphMode !== action.payload.newMode) {
        cgm_changes = { ...cgm_changes, filters: { ...state.filters, graphMode: action.payload.newMode } };
      }
      if (state.selectedNode !== null) {
        cgm_changes = { ...cgm_changes, selectedNode: null };
      }
      return {
        ...state,
        ...cgm_changes,
        graph: state.graph.directedGraph(action.payload.newMode === GRAPH_MODES.TIERED_2D).updateGraph(),
      };
    case TYPES.CHANGED_GRAPH_VIEW:
      const newView = state.viewsNetworkGraph?.find(view => view.id === action.payload);
      return {
        ...state,
        selectedView: newView,
      };
    // Saving and Updating Views Modal
    case TYPES.HID_SAVE_MODAL:
      return {
        ...state,
        saveModalVisible: false,
      };
    case TYPES.SHOWED_SAVE_MODAL:
      return {
        ...state,
        saveModalVisible: true,
      };
    // Delete Modal
    case TYPES.HID_DELETE_MODAL:
      return {
        ...state,
        deleteModalVisible: false,
      };
    case TYPES.SHOWED_DELETE_MODAL:
      return {
        ...state,
        deleteModalVisible: true,
      };
    // Camera
    case TYPES.MOVED_CAMERA:
      return {
        ...state,
        prevCamera: { ...state.prevCamera, position: state.camera.position },
        camera: { ...state.camera, position: action.payload },
      };
    case TYPES.ZOOMED_TO_FIT_CAMERA:
      if (state.zoomToFit !== action.payload) {
        return {
          ...state,
          zoomToFit: action.payload,
        };
      }
      return state;
    case TYPES.CENTERED_ON_NODE:
      return {
        ...state,
        selectedNode: action.payload,
      };
    case TYPES.REVERTED_CAMERA:
      return {
        ...state,
        camera: { ...state.prevCamera },
        prevCamera: { ...state.camera },
      };
    case TYPES.SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload,
      };
    case TYPES.UPDATE_NODEID_URL:
      return {
        ...state,
        fetchedNodeIdFromURL: action.payload,
        initialNodeIdUpdate: true,
      };
    case TYPES.RESET_INITIAL_NODEID_UPDATE:
      return {
        ...state,
        initialNodeIdUpdate: action.payload,
      };
    case TYPES.CLEAR_NODEID_URL:
      return {
        ...state,
        fetchedNodeIdFromURL: null,
      };
    case TYPES.SELECT_NODE_THROUGH_SEARCH:
      return {
        ...state,
        searchedNodeId: action.payload,
      };
    case TYPES.CLEAR_SEARCHED_NODE:
      return {
        ...state,
        searchedNodeId: null,
      };
    case TYPES.DESELECTED_NODE:
      return {
        ...state,
        selectedNode: null,
      };
    case TYPES.FOCUS_NODE:
      return {
        ...state,
        focusedNodeId: action.payload,
      };
    case TYPES.UNFOCUS_NODE:
      return {
        ...state,
        focusedNodeId: null,
      };
    case TYPES.CHANGED_DEPENDENCY_TABLE_TOGGLE:
      return {
        ...state,
        depsTableToggleState: action.payload,
      };
    case TYPES.UPDATE_VISIBLE_EDGES:
      return {
        ...state,
        visibleEdges: action.payload,
      };
    case TYPES.UPDATE_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES:
      return {
        ...state,
        graph: state.graph.updateVisibleNodesAndLinks(
          action.payload.nodes.map(n => n.id),
          action.payload.edges.map(l => l.id),
        ),
      };
    case TYPES.RESET_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES:
      return {
        ...state,
        graph: state.graph.resetVisibleNodesAndLinks().updateGraph(),
      };
    case TYPES.GET_ASSET_SUMMARY:
      return {
        ...state,
        assetSummary: {},
      };
    case TYPES.GET_ASSET_SUMMARY_SUCCESS:
      return {
        ...state,
        assetSummary: action.payload,
      };
    case TYPES.GET_ASSET_SUMMARY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CLEAR_ASSET_SUMMARY:
      return {
        ...state,
        assetSummary: undefined,
      };
    case TYPES.SET_INCIDENT_ID:
      return {
        ...state,
        incidentId: action.payload,
      };
    case TYPES.GET_IMPACTED_ASSETS_SUCCESS:
      return {
        ...state,
        impactedAssets: [...state.impactedAssets, ...action.payload.results],
        nextImpactedAssetsURL: action.payload.next,
      };
    case TYPES.CLEAR_INCIDENT_DETAILS:
      return {
        ...state,
        incidentId: null,
        impactedAssets: [],
        nextImpactedAssetsURL: null,
        filters: {
          node_color: NODE_COLOR_INCIDENT_RISK_SCORE,
          max_depth: 1,
          asset_group: [],
          countries: [],
          facilities: [],
          hs_codes: [],
          material: [],
          product: [],
          risk: [],
          discover_facility: true,
          incident_risk: [],
          commodities: [],
        },
      };
    case TYPES.CLEAR_SELECTED_VIEW:
      return {
        ...state,
        selectedView: null,
        selectedNode: null,
      };
    default:
      return state;
  }
};
