import { filter, map } from 'rxjs/operators';
import { TYPES, getRiskModelScores } from 'admin/riskmodels/modules/riskmodels';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { getCountryRiskHistory } from 'assets/modules/riskhistory';
import { push } from 'connected-react-router';

//redirects after creation
export const createRiskModelRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.SAVE_RISK_MODEL_SUCCESS),
    map(action => push(`/admin/risk-models/${action.payload.id}/`)),
  );
};

//redirect after delete
export const deleteRiskModelRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_RISK_MODEL_SUCCESS),
    map(() => push(`/admin/risk-models/`)),
  );
};

export const getRiskModelProfiles = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_RISK_MODELS_SUCCESS),
    map(() => push(`/admin/risk-models/`)),
  );
};

export const getRiskModelEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_RISK_MODEL_SUCCESS),
    map(() => {
      const { id } = state$.value.riskModels.RiskModel;
      const countryId = state$.value.regions?.region;
      // TODO: this is pulling risk model scores every time you fetch the risk model
      if (countryId) return getCountryRiskHistory({ countryId, riskModelId: id });
      return getRiskModelScores({ id });
    }),
  );
};

export const getRiskModelsEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === TYPES.GET_RISK_MODELS_SUCCESS) {
        return state$.value.riskProfiles.status === undefined;
      }
      return false;
    }),
    map(() => getRiskProfiles()),
  );
};

const epics = {
  createRiskModelRedirectEpic,
  deleteRiskModelRedirectEpic,
  getRiskModelEpic,
  getRiskModelsEpic,
};
export default epics;
