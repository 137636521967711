import React from 'react';
import { Row, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { valColor } from 'discover/modules/graphUtilities';

export const AssetDrawerDiscoverThreatScore = styled(props => {
  return (
    props.risk_score && (
      <span className={`${props.className}`}>
        <h3>{props.title}</h3>
        <span className="threat-score" style={{ backgroundColor: valColor(props.risk_score) }}>
          {props.risk_score}
        </span>
        <h3 className="capitalize">{props.category}</h3>
      </span>
    )
  );
})`
  display: flex;
  flex-direction: column;
  text-align: center;
  h3 {
    color: #bfbfbf;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 6px 0;
    margin: 0 auto;
    height: 30px;
  }
  .capitalize {
    text-transform: uppercase;
  }
  .threat-score {
    border-radius: 4px;
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
    padding: 3px 0;
    width: 85px;
    height: 43px;
  }
`;

export const AssetHalfDrawerDiscoverThreatScore = styled(props => {
  const toolTipStyling = { backgroundColor: '#141414', fontSize: '10px', color: '#ffffff', textTransform: 'uppercase' };
  return (
    (props.external.risk_score || props.internal.risk_score) && (
      <span className={`${props.className}`}>
        <Tooltip title="External Risks" overlayInnerStyle={toolTipStyling}>
          <span className="threat-score" style={{ backgroundColor: valColor(props.external.risk_score) }}>
            {props.external.risk_score}
          </span>
        </Tooltip>
        <Tooltip title="Internal Risks" overlayInnerStyle={toolTipStyling}>
          <span className="threat-score bottom" style={{ backgroundColor: valColor(props.internal.risk_score) }}>
            {props.internal.risk_score}
          </span>
        </Tooltip>
      </span>
    )
  );
})`
  display: flex;
  flex-direction: column;
  margin: 30px 0 15px 5px;
  max-width: 100px;
  text-align: center;
  .threat-score {
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    padding: 2px 24px 2px 24px;
    height: 18px;
  }
  .bottom {
    margin-top: 8px;
  }
`;

export const AssetDrawerThreats = styled(({ threatScores, className }) => {
  return (
    <Row className={`${className}`}>
      <AssetDrawerDiscoverThreatScore
        title="Strategic Score"
        risk_score={threatScores.overall.score}
        category={threatScores.overall.category}
      />
      <AssetHalfDrawerDiscoverThreatScore
        external={{ risk_score: threatScores.external.score }}
        internal={{ risk_score: threatScores.internal.score }}
      />
      <AssetDrawerDiscoverThreatScore
        title="Incident Score"
        risk_score={threatScores.max.score}
        category={threatScores.max.category}
        className="max"
      />
    </Row>
  );
})`
  display: flex;
  flex-direction: row;
  position: relative;

  .max {
    margin-left: auto;
  }
`;

export default AssetDrawerThreats;
