import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Global, css } from '@emotion/react';

import { BasicModal as Modal } from '@luxe/components';
import { ignoreThreat } from 'riskfeed/modules/riskfeed';

const Remove = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const { id: threatId = null } = useParams();

  const onCancel = () => {
    props.setVisible(false);
  };
  return (
    <>
      <Global
        styles={css`
          .ant-modal-title {
            font-size: 22px;
          }
          .ant-modal-body {
            font-size: 12px;
          }
        `}
      />
      <Modal
        className="remove-modal"
        visible={props.visible}
        title="Are you sure you want to remove this incident from your organization?"
        okText="Confirm Removal"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          props.mixpanelTrack('Action Center - Remove incident', {
            Incident: threatId,
          });
          dispatch(ignoreThreat(threatId));
          props.setVisible(false);
        }}
      >
        Removing an incident means that this incident will no longer affect Risk Scores, and no one in your organization
        will be able to be notified about this incident updates.
      </Modal>
    </>
  );
})`
  .ant-modal-title {
    background-color: red;
    color: blue;
    width: 95%;
    font-size: 22px;
  }
  .ant-modal-body {
    font-size: 12px;
    color: green;
  }
`;

export default Remove;
