import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Layout, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { DetailViewContent } from '@luxe/components/src/Container';
import HeaderRow from './HeaderRow';
import { ItemDetailGroupsSection } from '@luxe/components/src/ItemDetail';
import { deleteUser, getUserFromId, updateUser } from 'admin/users/modules/users';
import { addUsersToGroup, getUserGroups, removeUserFromGroup } from 'admin/usergroups/modules/userGroups';
import UserSettingsSection from './UserSettingsSection';
import UserTabView from './UserTabView';
import NotificationsView from 'modal/userprofile/containers/UserProfileView/Notifications/Notifications';
import { NotificationContent } from '@luxe/components/src/Container';
import { getProfileChannelsFromId, putProfileChannelsFromId } from 'modal/userprofile/modules/notifications';
import { CHANNEL_ACTIONS } from 'common/Constants';
import { getUserProfileFromId } from 'modal/userprofile/modules/userprofile';

const UserDetailView = () => {
  const { space } = useTheme();
  const { id: userId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const users = useSelector(store => store.users);
  const accountOrg = useSelector(store => store.accountOrg)?.accountOrg;
  const user = users?.['user'];
  const account = useSelector(store => store.account);
  const curr_user = account?.account;
  const userGroups = useSelector(store => store.userGroups)?.userGroups;
  const userChannels = useSelector(store => store.userNotifications?.userChannels);
  const userWorkflowId = useSelector(store => store.userProfile?.selectedUserProfile?.workflow);
  const settingsRef = useRef(null);
  const groupsRef = useRef(null);
  const [userChannelPayload, setUserChannelPayload] = useState({});
  const [activeTab, setActiveTab] = useState('User Settings');
  const isAdmin = curr_user?.isAdmin;

  const sso = accountOrg?.prefs.sso?.enabled;

  const updateUserProfile = updatedValue => {
    setUserChannelPayload(existingPayload => ({ ...existingPayload, ...updatedValue }));
  };

  const handleSave = data => {
    if (data.channel) {
      data = { ...userChannelPayload, ...data };
    } else {
      data = userChannelPayload;
    }
    dispatch(putProfileChannelsFromId(userId, data));
  };

  useEffect(() => {
    if (!userGroups) {
      dispatch(getUserGroups());
    }
  }, [dispatch, userGroups]);

  useEffect(() => {
    if (user) {
      const initialValues = {
        name: user.name,
        email: user.email,
        admin: user.isAdmin,
        phone: user.phone ?? '',
      };
      form.setFieldsValue(initialValues);
    }
  }, [user, form]);

  //find which channels the user currently has, uses OFF settings for those we dont
  const extractMatchingChannelData = (userChannels, currentAction) => {
    const matchingData = userChannels?.find(x => x.type === currentAction.key) || {};
    return {
      frequency: matchingData.frequency || '',
      is_realtime: matchingData.is_realtime || false,
    };
  };

  useEffect(() => {
    dispatch(getUserFromId(userId));
    dispatch(getUserProfileFromId(userId));
    dispatch(getProfileChannelsFromId(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const channelPayloadData = {};
    CHANNEL_ACTIONS.forEach(x => (channelPayloadData[x.value] = extractMatchingChannelData(userChannels, x)));
    setUserChannelPayload(channelPayloadData);
  }, [userChannels]);

  if (!user || !userChannels) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const tabs = [
    {
      title: 'User Settings',
      ref: settingsRef,
      content: <UserSettingsSection user={user} form={form} sso={sso} />,
    },
    {
      title: 'Groups',
      ref: groupsRef,
      content: (
        <ItemDetailGroupsSection
          item={user}
          itemName={'user'}
          groups={userGroups}
          deleteFromGroupFunction={removeUserFromGroup}
          addToGroupFunction={addUsersToGroup}
        />
      ),
    },
  ];
  const onTabChange = key => {
    const tab = tabs.find(tab => tab.title === key);
    if (tab) {
      setActiveTab(tab.title);
    }
  };

  if (isAdmin && user.type === 'contact') {
    tabs.push({
      title: 'Notifications',
      content: (
        <NotificationContent style={{ margin: `${space.none} ${space.small}px` }}>
          <NotificationsView
            userProfile={userChannelPayload}
            updateProfile={updateUserProfile}
            onSave={handleSave}
            userWorkflowId={userWorkflowId}
            userDetailView={true}
          />
        </NotificationContent>
      ),
    });
  }

  return (
    <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <div style={{ marginTop: '0' }}>
        <Form form={form} name="user_detail_form" preserve={false}>
          <HeaderRow
            form={form}
            user={user}
            deleteUserFunction={deleteUser}
            editUserFunction={updateUser}
            activeTab={activeTab}
          />
          <UserTabView tabs={tabs} onTabChange={onTabChange} activeTab={activeTab} />
        </Form>
      </div>
    </DetailViewContent>
  );
};

export default UserDetailView;
