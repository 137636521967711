import styled from '@emotion/styled';
import { Col, Radio, Row } from 'antd';
import IndividualSubIndexChart from 'assets/containers/AssetDetailView/AssetRiskSection/IndividualSubIndexChart';
import { subIndexChartOptions } from 'assets/containers/AssetDetailView/AssetRiskSection/SubIndexCharts';
import SliderRiskChart from 'assets/containers/OverallRiskCharts';
import React, { useEffect, useState } from 'react';

const RiskModelButtons = styled(({ proactiveScores, activeRiskModel, setActiveRiskModel, className }) => {
  return (
    <Radio.Group value={activeRiskModel} onChange={e => setActiveRiskModel(e.target.value)} className={className}>
      {proactiveScores?.map((model, index) => (
        <Radio.Button value={index} key={index}>
          <div className="button-wrapper">
            <span className="name">{model.model_name}</span>
            <span className={`score ${model.category || 'low'}`}>{model.score || 1}</span>
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
})`
  .ant-radio-button-wrapper-checked {
    background-color: #287e91 !important;
  }
  .ant-radio-button-wrapper {
    height: 40px;
    margin-right: 2px;
    font-size: 15px;
    line-height: 40px;
    border-radius: 4px !important;
    border: none !important;
    padding: 0 5px;
    background-color: transparent;
    color: white;
    box-shadow: none !important;
    ::hover {
      background-color: #287e91 !important;
    }
    ::before {
      display: none;
    }
    .button-wrapper {
      max-width: 25ch;
      display: flex;
      align-items: center;
      .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .score {
        border-radius: 4px;
        color: #eeeeee;
        width: 25px;
        height: 25px;
        overflow: visible;
        display: inline-flex;
        justify-content: center;
        margin-left: 5px;
        align-items: center;
      }
      span.low {
        background-color: #108904;
      }
      span.medium {
        background-color: #db992f;
      }
      span.high {
        background-color: #d32100;
      }
    }
  }
`;

const RisksTab = styled(({ proactiveScores, className, activeRiskModel, setActiveRiskModel, isEkg = false }) => {
  const [highChartOptions, setHighChartOptions] = useState();

  useEffect(() => {
    if (proactiveScores?.length) {
      setHighChartOptions(subIndexChartOptions(proactiveScores[activeRiskModel], {}, null, null, isEkg));
    } else {
      setHighChartOptions([]);
    }
  }, [activeRiskModel, proactiveScores, isEkg]);

  return (
    <div className={className}>
      {proactiveScores?.length ? (
        <>
          <RiskModelButtons {...{ proactiveScores, activeRiskModel, setActiveRiskModel }} />
          <Row className="slider-risks">
            <SliderRiskChart
              proactiveRisk={proactiveScores[activeRiskModel]}
              showHistory={false}
              showBenchMark={false}
              isEkg={isEkg}
            />
          </Row>
          {highChartOptions?.length ? (
            <>
              <Row>
                {highChartOptions.map((options, index) => (
                  <Col key={index} span={12}>
                    <span>{proactiveScores[activeRiskModel]?.indices[index]?.name}</span>
                    <IndividualSubIndexChart cName="spider-chart" options={options} />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <Row
              style={{
                width: '100%',
                justifyContent: 'center',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              No Risk Configured
            </Row>
          )}
        </>
      ) : (
        <Row style={{ width: '100%' }}>There is no risk information available.</Row>
      )}
    </div>
  );
})`
  font-size: 18px;
  .slider-risks {
    flex-wrap: nowrap;
    > div {
      padding: 0;
      margin-right: 16px;
    }
  }
`;

export default RisksTab;
