import React from 'react';
import theme from 'theme';
import { ExportIcon, ImportIcon } from '@luxe/components';
import { Link } from 'react-router-dom';
import { stringSorter } from 'utils';

export const nextPrevNodeSorter = (assetId, a, b) => {
  const aString = a.from_node?.id === assetId ? a.to_node?.name : a.from_node?.name;
  const bString = b.from_node?.id === assetId ? b.to_node?.name : b.from_node?.name;
  return stringSorter(aString, bString);
};

export const getFlowIcon = (assetId, flow) => {
  let color = '';
  if (flow.from_node?.id === assetId) {
    color = theme.colors[flow.to_node?.risk?.category + 'Risk'];
    return <ExportIcon style={{ color }} />;
  } else {
    color = theme.colors[flow.from_node?.risk?.category + 'Risk'];
    return <ImportIcon style={{ color }} />;
  }
};

export const getNextPrevLoc = (assetId, { from_node, to_node }) => {
  let url = '';
  let name = '';
  if (from_node?.id === assetId && to_node) {
    url = '/assets/' + to_node.id + '/';
    name = to_node.name;
  } else if (from_node) {
    url = '/assets/' + from_node?.id + '/';
    name = from_node?.name;
  } else {
    return null;
  }
  return <Link to={url}>{name}</Link>;
};

export const getFinalLoc = ({ final_node }) =>
  final_node ? <Link to={`/assets/${final_node.id}/`}>{final_node.name}</Link> : null;
