import { isStateEmpty, assignState } from './utils';

export const TYPES = {
  UPDATE_RISK_FILTER: 'UPDATE_RISK_FILTER',
  REMOVE_INDEX_RISK_FILTERS: 'REMOVE_INDEX_RISK_FILTERS',
  REMOVE_RISK_FILTER: 'REMOVE_RISK_FILTER',
};

export const INITIAL_STATE = {
  isFiltered: false,
  filteredIndices: {},
  currentlyFiltered: [],
};

export const riskFilterReducer = (state = INITIAL_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case TYPES.UPDATE_RISK_FILTER:
      newState = assignState(state);
      if (!newState.filteredIndices.hasOwnProperty(action.filterType)) {
        newState.filteredIndices[action.filterType] = [];
      }
      if (!newState.filteredIndices[action.filterType].includes(action.payload)) {
        newState.filteredIndices[action.filterType] = [...newState.filteredIndices[action.filterType], action.payload];
      }
      if (!newState.currentlyFiltered.includes(action.filterType)) {
        newState.currentlyFiltered = [...newState.currentlyFiltered, action.filterType];
      }
      if (newState.isFiltered === false) {
        newState.isFiltered = true;
      }
      return newState;
    case TYPES.REMOVE_INDEX_RISK_FILTERS:
      newState = assignState(state);
      newState.filteredIndices[action.filterType] = [];
      let i = newState.currentlyFiltered.indexOf(action.filterType);
      newState.currentlyFiltered.splice(i, 1);
      if (isStateEmpty(newState)) {
        newState.isFiltered = false;
      }
      return newState;
    case TYPES.REMOVE_RISK_FILTER:
      newState = assignState(state);
      newState.filteredIndices[action.filterType] = state.filteredIndices[action.filterType].filter(
        item => item !== action.payload,
      );
      if (!newState.filteredIndices[action.filterType].length) {
        let i = newState.currentlyFiltered.indexOf(action.filterType);
        newState.currentlyFiltered.splice(i, 1);
      }
      if (isStateEmpty(newState)) {
        newState.isFiltered = false;
      }
      return newState;
    default:
      return state;
  }
};

export const removeIndexRiskFilters = type => ({
  type: TYPES.REMOVE_INDEX_RISK_FILTERS,
  filterType: type,
});

export const updateRiskFilter = (data, type) => ({
  type: TYPES.UPDATE_RISK_FILTER,
  payload: data,
  filterType: type,
});

export const removeRiskFilter = (data, type) => ({
  type: TYPES.REMOVE_RISK_FILTER,
  payload: data,
  filterType: type,
});
