import YAML from 'yaml';

export const getIndex = (object, listOfObjects) => {
  return listOfObjects.findIndex(o => o.id === object.id);
};

export const updateInList = (updatedObject, listOfObjects) => {
  const objectIndex = getIndex(updatedObject, listOfObjects);
  listOfObjects[objectIndex] = updatedObject;
  return listOfObjects;
};

export const deleteFromList = (deletedObject, listOfObjects) => {
  let updatedList = listOfObjects;
  return updatedList.filter(o => o.id !== deletedObject.id);
};

export const deleteRecursivelyFromList = (deletedObject, listOfObjects) => {
  //Deletes an object and all its children from a list
  let queue = [];
  let updatedList = listOfObjects;
  queue.push(deletedObject);

  while (queue.length !== 0) {
    let currentObject = queue.shift();
    updatedList = updatedList.filter(o => o.id !== currentObject.id);

    let children = updatedList.filter(o => o.parent_id === currentObject.id);
    queue.push(...children);
  }
  return updatedList;
};

export const deleteFromListById = (deleted_ids, listOfObjects) => {
  let updatedList = listOfObjects;
  return updatedList.filter(o => !deleted_ids.includes(o.id));
};

export const groupToTreeNode = group => ({
  title: group.name,
  id: group.id,
  parentId: group.parent_id,
  parentName: group.parent_name,
  key: group.path,
  children: [],
});

export const arrangeIntoTree = (groups, defaultGroups = []) => {
  const tree = [...defaultGroups];
  if (groups.length > 0) {
    const rootNodes = [];
    const treeMap = {};
    groups.forEach(x => {
      const node = groupToTreeNode(x);
      if (node.parentId === null) {
        rootNodes.push(node);
      }
      if (treeMap[node.parentId]) {
        treeMap[node.parentId].push(node);
      } else {
        treeMap[node.parentId] = [node];
      }
    });
    const queue = [...rootNodes];
    while (queue.length > 0) {
      const parent = queue.shift();
      const new_children = treeMap[parent.id];
      if (new_children) {
        parent.children.push(...new_children);
        queue.push(...new_children);
      }
    }
    tree.push(...rootNodes);
  }
  return tree;
};

//TODO: validate YAML before setting
export const treeToYAML = groups => {
  let tree = arrangeIntoTree(groups);
  let assetGroups = tree.map(group => nodeToYAML(group));
  return YAML.stringify({ groups: assetGroups });
};

const nodeToYAML = node => {
  if (node.children.length === 0) {
    return { name: node.title };
  }
  return { name: node.title, groups: node.children.map(child => nodeToYAML(child)) };
};

export function getParseParams(search) {
  const paramParser = new URLSearchParams(search);
  const newParams = {};
  paramParser.forEach((value, key) => {
    newParams[key] = value;
  });
  return newParams;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateSelectOptions = (value, key, label) => ({
  value,
  key,
  label,
});

export function convertedNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num?.toString();
}
