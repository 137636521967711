import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Row, Space, Typography } from 'antd';
import { Button, BasicModal as Modal, Label as Input } from '@luxe/components';
import { useTheme } from 'emotion-theming';
import {
  cancelRiskModelChanges,
  createRiskModel,
  deleteRiskModel,
  saveRiskModel,
} from 'admin/riskmodels/modules/riskmodels';

const SAVEAS_MODAL_BODY = 'Save as a new risk model';
const { Text } = Typography;

const HeaderRow = ({
  item,
  riskModels,
  onDeleteClick,
  form,
  cancelRiskModelChanges,
  createRiskModel,
  deleteRiskModel,
  saveRiskModel,
  isEditable,
}) => {
  const { space, colors, fontSizes } = useTheme();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [saveAsButtonDisabled, setSaveAsButtonDisabled] = useState(false);
  const [isSaveAsModalShowing, setIsSaveAsModalShowing] = useState(false);

  useEffect(() => {
    if (riskModels.valid && riskModels.updated) {
      setSaveButtonDisabled(false);
      setSaveAsButtonDisabled(true);
    } else if (riskModels.valid) {
      setSaveAsButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
      setSaveAsButtonDisabled(true);
    }
    if (item.scoring_type === 'max_score') {
      setSaveButtonDisabled(false);
      setSaveAsButtonDisabled(false);
    }
  }, [riskModels.valid, riskModels.updated, riskModels.scoring_type, item.scoring_type]);

  useEffect(() => {
    if (riskModels.created) {
      riskModels.created = false;
      const newUrl = `/admin/risk-models/${riskModels.riskModels.id}/`;
      history.push(newUrl);
    }
  }, [riskModels, history]);

  const saveRiskModelChanges = () => {
    setSaveButtonDisabled(true);
    setSaveAsButtonDisabled(false);
    //TODO: add check if weight distribution makes sense; can check by using the state and disabling or enabling the save button
    if (saveRiskModel) {
      saveRiskModel(riskModels.RiskModel);
    }
  };

  const deleteRiskModelConfirm = () => {
    if (deleteRiskModel) {
      deleteRiskModel({ id: riskModels.RiskModel.id });
    }
  };

  const cancelChanges = () => {
    if (cancelRiskModelChanges) {
      cancelRiskModelChanges();
    }
  };

  const copyRiskModelModal = () => {
    setIsSaveAsModalShowing(true);
  };

  const removeIds = arr => {
    arr.forEach((value, index) => {
      delete value.id;
      if (value.indices !== undefined) {
        value.indices = removeIds(value.indices);
      }
      if (value.components !== undefined) {
        value.components = removeIds(value.components);
      }
    });
    return arr;
  };

  const onCreate = values => {
    const indices = removeIds(riskModels.RiskModel.indices);
    createRiskModel({
      ...values,
      indices: indices,
    });
    setIsSaveAsModalShowing(false);
  };

  if (!isEditable) {
    return (
      <>
        <Row justify="end" className="risk-model-header-row" style={{ marginBottom: `${space.xsmall}px` }}>
          <Space>
            <Button variant="menu" type="link" form={form} onClick={copyRiskModelModal}>
              Save As
            </Button>
            <Link to="/admin/risk-models/">
              <Button variant="menu" type="link" style={{ width: '150px' }}>
                Back
              </Button>
            </Link>
          </Space>
        </Row>
        <SaveAsRiskModelForm
          visible={isSaveAsModalShowing}
          onCreate={onCreate}
          onCancel={() => {
            setIsSaveAsModalShowing(false);
          }}
          riskModels={riskModels}
        />
      </>
    );
  }

  return (
    <>
      <Row justify="end" className="risk-model-header-row" style={{ marginBottom: `${space.xsmall}px` }}>
        <Space>
          <Button variant="menu" type="link" form={form} onClick={copyRiskModelModal} disabled={saveAsButtonDisabled}>
            Save As
          </Button>
          {!saveButtonDisabled ? (
            <Button
              variant="menu"
              type="link"
              form={form}
              onClick={saveRiskModelChanges}
              style={{ backgroundColor: colors.primary }}
            >
              Save
            </Button>
          ) : (
            <Button variant="disabled" type="link" style={{ width: '150px' }}>
              Save
            </Button>
          )}
          {riskModels.updated ? (
            <Button variant="menu" type="link" form={form} onClick={() => setVisible(!visible)}>
              Cancel
            </Button>
          ) : (
            <Link to="/admin/risk-models/">
              <Button variant="menu" type="link" onClick={cancelChanges}>
                Cancel
              </Button>
            </Link>
          )}
          <Button variant="menu" type="link" form={form} onClick={deleteRiskModelConfirm}>
            Delete
          </Button>
        </Space>
      </Row>
      <SaveAsRiskModelForm
        visible={isSaveAsModalShowing}
        onCreate={onCreate}
        onCancel={() => {
          setIsSaveAsModalShowing(false);
        }}
        riskModels={riskModels}
      />
      <Modal
        visible={visible}
        title="Cancel Risk Model Changes?"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => {
          setVisible(!visible);
        }}
        onOk={() => {
          setVisible(!visible);
        }}
        footer={[
          <Button key={'modal-onCancel-risk-model'} onClick={() => setVisible(!visible)}>
            No
          </Button>,
          <Button key={'modal-onSave-risk-model'} onClick={cancelChanges}>
            <Link to="/admin/risk-models/">Yes</Link>
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          Are you sure you want to cancel your changes?
        </Text>
      </Modal>
    </>
  );
};

function mapStateToProps({ riskModels, router }) {
  return {
    riskModels,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveRiskModel,
      deleteRiskModel,
      cancelRiskModelChanges,
      createRiskModel,
    },
    dispatch,
  );
}

const SaveAsRiskModelForm = ({ visible, onCreate, onCancel, riskModels }) => {
  const [form] = Form.useForm();
  const riskModelName = riskModels.RiskModel.name + ' - Copy';
  return (
    <Modal
      visible={visible}
      title={SAVEAS_MODAL_BODY}
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="saveas_risk_model_form"
        initialValues={{
          modifier: 'public',
          name: riskModelName,
        }}
      >
        <Form.Item
          name="name"
          label={<label style={{ color: 'red' }}>Name</label>}
          rules={[
            {
              required: true,
              message: 'Please input the title of Risk Model!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRow);
