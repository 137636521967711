import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_INDUSTRY: 'GET_INDUSTRY',
  GET_INDUSTRY_SUCCESS: 'GET_INDUSTRY_SUCCESS',
  GET_INDUSTRY_ERROR: 'GET_INDUSTRY_ERROR',
  GET_NOTIFICATIONTEMPLATE: 'GET_NOTIFICATIONTEMPLATE',
  GET_NOTIFICATIONTEMPLATE_SUCCESS: 'GET_NOTIFICATIONTEMPLATE_SUCCESS',
  GET_NOTIFICATIONTEMPLATE_ERROR: 'GET_NOTIFICATIONTEMPLATE_ERROR',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',
  GET_THREATS: 'GET_THREATS',
  GET_THREATS_SUCCESS: 'GET_THREATS_SUCCESS',
  GET_THREATS_ERROR: 'GET_THREATS_ERROR',
  GET_COMMODITY: 'GET_COMMODITY',
  GET_COMMODITY_SUCCESS: 'GET_COMMODITY_SUCCESS',
  GET_COMMODITY_ERROR: 'GET_COMMODITY_ERROR',
};

export const INITIAL_STATE = {
  industries: [],
  subindustries: [],
  threats: [],
};

export const getIndustry = () => {
  return callApi({
    types: {
      pending: TYPES.GET_INDUSTRY,
      success: TYPES.GET_INDUSTRY_SUCCESS,
      error: TYPES.GET_INDUSTRY_ERROR,
    },
    request: () => axios.get(`industry/`),
  });
};

export const getCommodities = () => {
  return callApi({
    types: {
      pending: TYPES.GET_COMMODITY,
      success: TYPES.GET_COMMODITY_SUCCESS,
      error: TYPES.GET_COMMODITY_ERROR,
    },
    request: () => axios.get(`commodities/`),
  });
};

export const getNotificationTemplates = () => {
  return callApi({
    types: {
      pending: TYPES.GET_NOTIFICATIONTEMPLATE,
      success: TYPES.GET_NOTIFICATIONTEMPLATE_SUCCESS,
      error: TYPES.GET_NOTIFICATIONTEMPLATE_ERROR,
    },
    request: () => axios.get(`notification/templates`),
  });
};

export const getCountries = () => {
  return callApi({
    types: {
      pending: TYPES.GET_COUNTRIES,
      success: TYPES.GET_COUNTRIES_SUCCESS,
      error: TYPES.GET_COUNTRIES_ERROR,
    },
    request: () => axios.get(`countries/`),
  });
};

export const getThreats = () => {
  return callApi({
    types: {
      pending: TYPES.GET_THREATS,
      success: TYPES.GET_THREATS_SUCCESS,
      error: TYPES.GET_THREATS_ERROR,
    },
    request: () => axios.get(`threats/families/`),
  });
};

export const networkReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.GET_INDUSTRY:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        industries: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_INDUSTRY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_COMMODITY:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_COMMODITY_SUCCESS:
      return {
        ...state,
        commodities: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_COMMODITY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_NOTIFICATIONTEMPLATE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_NOTIFICATIONTEMPLATE_SUCCESS:
      return {
        ...state,
        notificationTemplates: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_NOTIFICATIONTEMPLATE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_COUNTRIES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        controlFormattedCountries: action.payload.map(c => ({ label: c.name, value: c.id })),
        countries: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_COUNTRIES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_THREATS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_THREATS_SUCCESS:
      return {
        ...state,
        families: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_THREATS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default networkReducer;
