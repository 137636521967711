import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from 'emotion-theming';
import { Spin, Row, Col } from 'antd';
import { BasicModal as Modal } from '@luxe/components';
import { Container, DetailViewContent } from '@luxe/components/src/Container';
import HeaderRow from './HeaderRow';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { getLane, getLanes, deleteLanes } from 'admin/lanes/modules/lanes';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { capitalizeFirstLetter } from 'admin/utils';

const DELETE_MODAL_TITLE = 'Are you sure you want to delete this lane?';
const DELETE_MODAL_TEST = 'This operation cannot be undone!';

const LaneStops = stops => {
  return stops.map((stop, i) => (
    <div key={i}>
      <Row>
        <Col span={2} style={{ paddingLeft: `12px` }}>
          <label>Name</label>
        </Col>
        <Col span={22}>{stop.asset.name}</Col>
      </Row>
      <Row>
        <Col span={2} style={{ paddingLeft: `12px` }}>
          <label>Location</label>
        </Col>
        <Col span={22}>
          {stop.asset.location.city}, {stop.asset.location.state} {stop.asset.location.postal_code}
          {stop.asset.location.country}
        </Col>
      </Row>
      <Row>
        <Col span={2} style={{ paddingLeft: `12px` }}>
          <label>Latitude</label>
        </Col>
        <Col span={22}>{stop.asset.location.latitude}</Col>
      </Row>
      <Row style={{ marginBottom: `12px` }}>
        <Col span={2} style={{ paddingLeft: `12px` }}>
          <label>Longitude</label>
        </Col>
        <Col span={22}>{stop.asset.location.longitude}</Col>
      </Row>
    </div>
  ));
};

const LaneDetailView = ({ lanes, params, status, location, history, getLane, getLanes, deleteLanes }) => {
  const { space } = useTheme();
  const dispatch = useDispatch();
  const lane = lanes.Lane;
  const laneId = lane ? lane.id : null;
  const [isModalShowing, setIsModalShowing] = useState(false);

  useEffect(() => {
    dispatch(getRiskProfiles());
  }, [dispatch]);

  useEffect(() => {
    const id = location.pathname.match('lanes/(.*)/')[1];
    if (laneId == null || laneId !== id) {
      getLane({ id });
    }
  }, [location.pathname, laneId, getLane]);

  const isReadOnly = lane => {
    return lane && lane.org === null;
  };
  const riskProfiles = useSelector(store => store.riskProfiles)?.riskProfiles || [];

  if (status === REQUESTED_DATA_STATUS.PENDING && !lane) {
    return <Spin size={'large'} />;
  } else if (lane || status === REQUESTED_DATA_STATUS.SUCCESS) {
    if (!lane) {
      return <Spin size={'large'} />;
    }

    const profiles = lane.profile_ids.map(profileId => riskProfiles.find(profile => profile.id === profileId));

    const laneProfiles = [];
    profiles.forEach(profile => {
      laneProfiles.push(profile.name);
    });

    return (
      <div>
        <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
          <HeaderRow
            title={lane.name}
            disabled={isReadOnly(lane)}
            form="edit_lane"
            onDeleteClick={() => {
              setIsModalShowing(true);
            }}
          />
          <Container>
            <Row style={{ marginBottom: `12px` }}>
              <Col span={2}>
                <h3>Risk Profiles</h3>
              </Col>
              <Col span={22}>{laneProfiles.join(', ')}</Col>
            </Row>
            <Row>
              <h3>Risk</h3>
            </Row>
            <Row>
              <Col span={2} style={{ paddingLeft: `12px` }}>
                <label>Score</label>
              </Col>
              <Col span={22}>{lane.risk.score}</Col>
            </Row>
            <Row>
              <Col span={2} style={{ paddingLeft: `12px` }}>
                <label>Category</label>
              </Col>
              <Col span={22}>{capitalizeFirstLetter(lane.risk.category)}</Col>
            </Row>
            <Row style={{ marginBottom: `12px` }}>
              <Col span={2} style={{ paddingLeft: `12px` }}>
                <label>Phenomena</label>
              </Col>
              <Col span={22}>{lane.risk.phenomena.join(', ')}</Col>
            </Row>
            <Row>
              <h3>Stops</h3>
            </Row>
            {LaneStops(lane.stops)}
          </Container>
        </DetailViewContent>
        <Modal
          title={DELETE_MODAL_TITLE}
          visible={isModalShowing}
          onCancel={() => {
            setIsModalShowing(false);
          }}
          onOk={() => {
            setIsModalShowing(false);
            deleteLanes(lane.id);
            history.push('/admin/lanes/');
          }}
        >
          <p>{DELETE_MODAL_TEST}</p>
        </Modal>
      </div>
    );
  } else {
    return null;
  }
};

function mapStateToProps({ lanes, router }) {
  return {
    lanes,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLane: getLane,
      getLanes: getLanes,
      deleteLanes: deleteLanes,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LaneDetailView);
