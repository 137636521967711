import React from 'react';
import { Button } from '@luxe/components';
import styled from '@emotion/styled';

const RiskButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  float: left;
  text-transform: capitalize;
  .active {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  :nth-of-type(1) {
    border-radius: 4px 0px 0px 4px;
    margin-right: -1px;
  }
  :nth-of-type(2) {
    border-radius: 0px 0px 0px 0px;
    margin-right: -1px;
  }
  :nth-of-type(3) {
    border-radius: 0px 4px 4px 0px;
  }
`;

export default class RiskCategorySelector extends React.Component {
  get value() {
    return this.props.filter.value;
  }

  get values() {
    return ['low', 'medium', 'high'].map(riskCategory => {
      const isIn = this.props.filter.operator === 'in';
      const defaultValue = isIn ? [] : null;
      let value = this.value || defaultValue || [];
      let selectedClass = value === riskCategory ? 'active' : '';
      if (isIn) {
        selectedClass = value.includes(value) ? 'active' : '';
      }

      const onClick = () => {
        let newValue = !isIn
          ? riskCategory
          : value.includes(riskCategory)
          ? value.filter(x => x !== riskCategory)
          : [...value, riskCategory];
        const data = {
          value: newValue.length === 0 ? null : newValue,
          operator: this.props.filter.operator,
          name: this.props.filter.name,
        };
        this.props.onChange(data);
      };

      return (
        <RiskButton
          key={riskCategory}
          variant={'primary'}
          onClick={onClick}
          type="button"
          className={`btn btn-secondary risk ${selectedClass}`}
          ghost={value ? (!value.includes(riskCategory) ? true : false) : true}
        >
          {riskCategory}
        </RiskButton>
      );
    });
  }

  render() {
    return (
      <div className="form-group col-md-6">
        <label>Category</label>
        <br />
        <div className="btn-group" data-toggle="buttons" style={{ content: '', clear: 'both', display: 'table' }}>
          {this.values}
        </div>
      </div>
    );
  }
}
