import React, { useContext, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getAssetGraph,
  resetAssetGraphVisibleLinksAndNodes,
  updateAssetGraphMode,
  updateAssetGraphVisibleLinksAndNodes,
} from 'assets/modules/graphs';
import { Section } from '@luxe/components';
import { Col, Row, Spin, notification } from 'antd';
import AssetDiscoverDimension from './AssetDiscoverDimension';
import AssetDiscoverGraph from './AssetDiscoverGraph';
import AssetDiscoverFilters from './AssetDiscoverFilters';
import AssetDiscoverTable from './AssetDiscoverTable';
import AssetDiscoverSubTable from './AssetDiscoverSubTable';

import { getDiscoverAssets } from 'admin/assets/modules/assets';
import { WarningOutlined } from '@ant-design/icons';
import GraphProvider, { GraphContext, useGraphSelector } from 'discover/modules/GraphProvider';
import { showWarningNotification } from 'common/components/Notification';
import { GRAPH_MODES, NETWORK_LINKS_LIMIT } from 'common/Constants';

const AssetDiscoverSection = () => {
  const { Asset } = useSelector(store => store.assets);
  const { discover_assets } = useSelector(store => store.assets);
  const { graphMode } = useSelector(store => store.graphs);
  const [loaded, setLoaded] = useState(false);
  const GRAPH_PENDING = 'pending';
  const GRAPH_LOADED = 'loaded';
  const GRAPH_EMPTY = 'empty';
  const [graphStatus, setGraphStatus] = useState(GRAPH_PENDING);
  const [tableData, setTableData] = useState({
    nodes: [],
    links: [],
  });
  const [nodesRefreshed, setNodesRefreshed] = useState(false);
  const subTierDataDefault = {
    list: {
      nodes: [],
      links: [],
    },
    direction: 'Previous',
  };
  const [subTierData, setSubTierData] = useState(subTierDataDefault);
  const [subTierShowing, setSubTierShowing] = useState(false);
  const [graphFilters, setGraphFilters] = useState({
    id: Asset.id,
    max_depth: 1,
    limit: NETWORK_LINKS_LIMIT.scorecard,
    asset_group: [],
    countries: [],
    facilities: [],
    hs_codes: [],
    material: [],
    product: [],
    risk: [],
  });
  const dispatch = useDispatch();
  const ref = useRef(null);

  const graph = useContext(GraphContext);
  const nodes = useGraphSelector(graph => graph.observers.nodes);
  const links = useGraphSelector(graph => graph.observers.links);
  const initialized = useGraphSelector(graph => graph.observers.initialized);

  useEffect(() => {
    if (graphMode === GRAPH_MODES.TIERED_2D) {
      graph.directedGraph(true).updateGraph();
    } else {
      graph.directedGraph(false).updateGraph();
    }
  }, [graphMode, graph]);

  useEffect(() => Asset && graphFilters && dispatch(getAssetGraph(graphFilters)), [dispatch, Asset, graphFilters]);

  useEffect(() => {
    if (nodes.length > 0 && links.length > 0) {
      dispatch(getDiscoverAssets(nodes.map(n => n.name)));
      setGraphStatus(GRAPH_LOADED);
    } else {
      setGraphStatus(GRAPH_EMPTY);
    }
  }, [nodes, links, dispatch]);

  useEffect(() => {
    if (discover_assets) {
      if (!loaded) setLoaded(true);
      const nodesNowWithAssetsAssociatedWithThem = nodes.map(tableNode => {
        tableNode.asset_id = discover_assets.find(da => da.asset.name === tableNode.name)?.asset.id;
        return tableNode;
      });
      setTableData({ nodes: nodesNowWithAssetsAssociatedWithThem, links: links });
    }
  }, [discover_assets, loaded, nodes, links]);

  useEffect(() => {
    setGraphStatus(GRAPH_PENDING);
  }, []);

  useEffect(() => {
    if (links?.length >= NETWORK_LINKS_LIMIT.scorecard && graphStatus === GRAPH_LOADED) {
      notification.open({
        message: `Your search result exceed the allowed limit. Please refine your search criteria.`,
        className: 'notification-discover-warning',
        duration: 0,
        key: 'nodes-limit',
        placement: 'top',
        top: 0,
        icon: <WarningOutlined />,
        getContainer: () => ref.current,
      });
    } else {
      notification.destroy('nodes-limit');
    }
    if (nodes?.length === 0 && initialized) {
      showWarningNotification(
        'Your search did not return any results. Please refine your search criteria.',
        'emptyAssetGraph',
        ref.current,
      );
    }
  }, [nodes, links, graphStatus, initialized]);

  const setGraphMode = mode => {
    dispatch(updateAssetGraphMode(mode));
  };

  const clearFilters = () => {
    setGraphFilters({
      id: Asset.id,
      max_depth: 1,
      limit: NETWORK_LINKS_LIMIT.scorecard,
      asset_group: [],
      countries: [],
      facilities: [],
      hs_codes: [],
      material: [],
      product: [],
      risk: [],
      clear: true,
    });
  };

  const updateFilters = filterValue => {
    setGraphFilters({
      ...graphFilters,
      ...filterValue,
    });
  };

  const toggleSubTier = (list, direction, selectedNode) => {
    !subTierShowing
      ? setSubTierData({
          list,
          direction,
          selectedNode,
        })
      : setSubTierData(subTierDataDefault);

    setSubTierShowing(!subTierShowing);
  };

  const updateTableData = data => {
    setTableData({ nodes: data.nodes, links: data.links });
  };

  return graphStatus === GRAPH_LOADED || initialized ? (
    <Section title={'Network'}>
      <Col style={{ width: '100%', marginBottom: '10px' }} className="discover-notification-container" ref={ref}>
        <Row>
          <AssetDiscoverGraph
            graphMode={graphMode}
            asset={Asset}
            graphFilters={graphFilters}
            updateTableData={updateTableData}
            nodesRefreshed={nodesRefreshed}
            setNodesRefreshed={setNodesRefreshed}
          />
          <AssetDiscoverDimension value={graphMode} setGraphMode={setGraphMode} setNodesRefreshed={setNodesRefreshed} />
          {subTierShowing && subTierData?.list?.nodes?.length > 0 ? (
            <WrapperAssetDiscoverSubTable subTierData={subTierData} toggleSubTier={toggleSubTier} />
          ) : (
            <>
              <AssetDiscoverFilters
                nodes={nodes}
                clearFilters={clearFilters}
                graphFilters={graphFilters}
                updateFilters={updateFilters}
              />
              <AssetDiscoverTable nodes={tableData.nodes} links={tableData.links} toggleSubTier={toggleSubTier} />
            </>
          )}
        </Row>
      </Col>
    </Section>
  ) : graphStatus === GRAPH_PENDING || !initialized ? (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {' '}
      Please wait while the graph is loading...
      <Spin size="large" />
    </div>
  ) : (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        width: '100%',
      }}
      ref={ref}
    >
      Network Data is Not Available For This Asset
    </div>
  );
};

const WrapperAssetDiscoverSubTable = ({ subTierData, toggleSubTier }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateAssetGraphVisibleLinksAndNodes(subTierData.list?.links, [
        ...subTierData.list?.nodes,
        subTierData.selectedNode,
      ]),
    );
    return () => {
      dispatch(resetAssetGraphVisibleLinksAndNodes());
    };
  }, [subTierData, dispatch]);

  return <AssetDiscoverSubTable subTierData={subTierData} toggleSubTier={toggleSubTier} />;
};

const AssetDiscoverSectionWrapper = Component => {
  const Wrapper = props => {
    const graph = useSelector(state => state.graphs.graph);
    return (
      <GraphProvider value={graph}>
        <Component {...props} />
      </GraphProvider>
    );
  };
  return Wrapper;
};

export default AssetDiscoverSectionWrapper(AssetDiscoverSection);
