import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export const aRangePicker = styled(RangePicker)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  background-color: ${props => props.theme.colors.black1};
  color: rgba(255, 255, 255, 0.75);
  border: ${props => `1px solid ${props.theme.colors.BG5}`};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.body};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  input.ant-input {
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    color: rgba(255, 255, 255, 0.45);
  }

  .ant-picker-suffix,
  .ant-picker-input input,
  .ant-picker-clear > span.anticon.anticon-close-circle > svg
  {
    color: white;
  }

  .ant-picker-clear > span.anticon.anticon-close-circle {
    background: black;
  }
`;
