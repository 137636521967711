import { Table as aTable } from 'antd';
import { SearchTable as searchTable } from './SearchTable';

import styled from '@emotion/styled';

export const Table = styled(aTable)`
  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  thead th {
    background-color: ${props => props.theme.colors.black1} !important;
    color: ${props => props.theme.colors.white};
  }
  thead tr th:hover {
    background-color: ${props => props.theme.colors.secondary} !important;
    color: ${props => props.theme.colors.white};
  }
  tbody.ant-table-tbody tr td.ant-table-cell-row-hover {
    background: ${props => props.theme.colors.black2};
  }
  tbody.ant-table-tbody tr:hover td {
    background-color: ${props => props.theme.colors.primaryHover} !important;
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }

  tbody {
    tr {
      & > .ant-table-cell > a {
        color: ${props => props.theme.colors.primary} !important;
      }
      &:hover > .ant-table-cell > a:hover {
        color: white !important;
      }
      td {
        border: none;
        background-color: ${props => props.theme.colors.black2};
        color: rgba(255, 255, 255, 0.85);
        overflow: hidden;
      }
    }
    tr:nth-of-type(even) td {
      background-color: ${props => props.theme.colors.black3};
    }
  }

  .ant-table-column-sorter-inner {
    background-color: transparent;
    span.anticon {
      color: rgba(255, 255, 255, 0.55);
    }

    span.anticon.active {
      color: ${props => props.theme.colors.primary};
    }
  }

  .ant-table-thead th.ant-table-column-sort {
    background-color: ${props => props.theme.colors.black1};
  }

  .ant-table-pagination {
    color: rgba(255, 255, 255, 0.45);
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  .ant-pagination-item {
    border-color: ${props => props.theme.colors.black4};
    background-color: ${props => props.theme.colors.background};
    a {
      color: #ffffff !important;
      &:visited,
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
    &:hover {
      border-color: ${props => props.theme.colors.primary};
    }
  }
  .ant-pagination-item-active {
    border-color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary};
    a {
      text-decoration: none;
      color: ${props => props.theme.colors.white};
      &:visited,
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
    &:hover {
      border-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white};
    }
  }

  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    margin: 0 6px;
  }
  .ant-pagination-options {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
      border: ${props => `1px solid ${props.theme.colors.BG5}`};
    }

    .ant-select-dropdown {
      background-color: ${props => props.theme.colors.black1};

      .ant-select-item-option {
        border: ${props => `1px solid ${props.theme.colors.BG5}`};
        color: ${props => props.theme.colors.white};
      }

      .ant-select-item-option-active,
      .ant-select-item-option-selected {
        background-color: ${props => props.theme.colors.black3};
        font-weight: 400;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: ${props => props.theme.colors.black1};
      border-color: ${props => props.theme.colors.BG5};
    }
  }

  .ant-table-tbody .ant-empty .ant-empty-description {
    color: ${props => props.theme.colors.white};
  }

  button.ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background-color: ${props => props.theme.colors.black4};
    border-color: ${props => props.theme.colors.black4};
    &:hover {
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
  .anticon-left svg {
    color: rgba(255, 255, 255, 0.45);
    fill: rgba(255, 255, 255, 0.45);
    &:hover {
      color: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
    }
    &:disabled {
      color: rgba(255, 255, 255, 0.45);
      fill: rgba(255, 255, 255, 0.45);
    }
  }
  .anticon-right svg {
    color: rgba(255, 255, 255, 0.45);
    fill: rgba(255, 255, 255, 0.45);
    &:hover {
      color: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
    }

    &:disabled {
      color: rgba(255, 255, 255, 0.45);
      fill: rgba(255, 255, 255, 0.45);
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: ${props => props.theme.colors.black3} !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${props => props.theme.colors.white};
    color: white;
  }
  input[type='checkbox'] + span {
    border-color: ${props => props.theme.colors.primary} !important;
  }

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} !important;
    background-color: ${props => props.theme.colors.primary} !important;
  }

  input[type='checkbox'] + span {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.white};
    &:focus {
      border-color: white !important;
    }
    &:disabled {
      background-color: ${props => props.theme.colors.disabled} !important;
    }
  }

  input[type='checkbox']:disabled + span{
      border-color: ${props => props.theme.colors.disabled} !important;
      background-color: ${props => props.theme.colors.black4} !important;
  }

  // Unless we decide to return counts with our server side tables, we need to hide the number buttons from antd
  .server-side-pagination {
    .ant-pagination-item {
      display: none !important;
    }
    .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
      display: none !important;
    }
    .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
      display: none !important;
    }
    &.loading {
      .ant-pagination-item-link {
        cursor: wait;
      }
    }
    &.ant-pagination-disabled .ant-pagination-item-link {
      background-color: ${props => props.theme.colors.black4};
      border-color: ${props => props.theme.colors.black4};
    }
  }
`;

export const SearchTable = searchTable;
