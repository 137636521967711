import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Select } from 'layout/Select';
import { BasicModal as Modal } from '@luxe/components';
import { updateFilter } from 'views/modules/views';
import widgets from './../widgets';
import { getFormOptions } from './utils';
import { getFamilies } from 'modal/incidentfeed/modules/utils';
import { changeAssetLabelToFacility } from 'utils';

const Column = ({ columns, filter, onFieldChanged, isColumnDisabled }) => {
  const selected = columns.find(x => x.value === filter.name);
  return (
    <>
      <div>Field</div>
      <Select
        placeholder="Select a Column"
        options={columns}
        isOptionDisabled={isColumnDisabled}
        onChange={e => {
          onFieldChanged('name', e.value);
        }}
        value={selected}
      />
    </>
  );
};

const Operator = ({ operators, filter, onFieldChanged }) => {
  const selected = operators.find(x => x.value === filter.operator);
  return (
    <>
      <div>Operator</div>
      <Select
        placeholder="Select a Operator"
        options={operators}
        onChange={e => {
          onFieldChanged('operator', e.value);
        }}
        value={selected}
      />
    </>
  );
};

const Value = ({ column, filter, onFieldChanged }) => {
  let widgetName = undefined;
  if (column?.widget && filter && filter.operator) {
    widgetName = Object.keys(widgets).includes(column.widget) ? column.widget : 'Input';
  }
  const Widget = useMemo(() => {
    if (widgetName) {
      return widgets[widgetName];
    }
    return undefined;
  }, [widgetName]);

  if (Widget === undefined) {
    return null;
  }

  return (
    <Widget
      filter={filter}
      onChange={({ value }) => {
        onFieldChanged('value', value);
      }}
      defaultValue={filter.value}
    />
  );
};

const FilterForm = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const familyData = useSelector(store => store.families.families);

  const { filter = {} } = props;
  const { visible } = filter;
  const title = props.formType === 'add' ? 'Create New Filter' : 'Edit Filter';

  const isRiskFeedRoute = window.location.pathname.includes('/risk-feed');

  useEffect(() => {
    dispatch(getFamilies(true));
  }, [dispatch]);

  const filterColumns = props.meta.allowed.filters
    .filter(x => {
      if (props.includes) {
        return props.includes.includes(x.name);
      }
      return true;
    })
    .map(item => {
      item.description = changeAssetLabelToFacility(item.description);
      return item;
    });

  if (isRiskFeedRoute) {
    filterColumns.push({
      description: 'Category',
      field: 'phenomena__family_id',
      name: 'phenomena__family_id',
      operators: ['in'],
      widget: 'CategorySelector',
    });
  }

  const column = filterColumns.find(x => x.name === filter.name);

  const [columns, operators] = getFormOptions(filterColumns, filter.name);

  const onFieldChanged = (attr, value) => {
    if (attr === 'name') {
      dispatch(updateFilter(props.datasource, { ...filter, name: value, operator: null, value: null }));
    } else if (attr === 'operator' && filter.name === 'last_update') {
      dispatch(updateFilter(props.datasource, { ...filter, [attr]: value, value: null }));
    } else {
      dispatch(updateFilter(props.datasource, { ...filter, [attr]: value }));
    }
  };

  const usedFilters = props.meta.current.filters.filter((_, i) => i !== filter.i).map(x => x.name);
  const isColumnDisabled = ({ value }) => {
    return usedFilters.includes(value);
  };

  const disabled = !(filter.name && filter.operator && filter.value);

  const getChildIds = original => {
    let dat = [];
    if (original?.length) {
      original.forEach(element => {
        let subChild = [];
        subChild = familyData.filter(val => val.parent_id === element).map(v => v.id);
        dat = [...dat, ...subChild];
      });
    }
    return dat;
  };

  const getTotalCategoryIds = (initialIds = [], finalIds = []) => {
    let result = getChildIds(initialIds);
    if (result?.length) {
      finalIds = getTotalCategoryIds(result, [...finalIds, ...result]);
    }
    return finalIds;
  };

  const handleSave = () => {
    if (filter?.name === 'phenomena__family_id') {
      let filterObj = JSON.parse(JSON.stringify(filter));
      let data = [];
      let arg = filterObj?.value?.length ? filterObj.value.map(v => v.value) : [];
      data = getTotalCategoryIds(arg, arg);
      filterObj.sendValue = data;
      props.onSave({
        ...filterObj,
      });
    } else {
      props.onSave({
        ...filter,
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title={title}
      okText="Save"
      okButtonProps={{ disabled }}
      cancelText="Cancel"
      onCancel={() => {
        dispatch(updateFilter(props.datasource, { visible: false }));
      }}
      onOk={() => handleSave()}
    >
      <div className={props.className}>
        <div className="field">
          <Column
            columns={columns}
            filter={filter}
            onFieldChanged={onFieldChanged}
            isColumnDisabled={isColumnDisabled}
          />
        </div>
        <div className="field">
          <Operator operators={operators} filter={filter} onFieldChanged={onFieldChanged} />
        </div>
        <div className="field">
          <Value column={column} filter={filter} onFieldChanged={onFieldChanged} />
        </div>
      </div>
    </Modal>
  );
})`
  .field {
    margin: 10px 0;
  }
`;
export default FilterForm;
