import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_MATERIAL: 'GET_MATERIAL',
  GET_MATERIAL_ERROR: 'GET_MATERIAL_ERROR',
  GET_MATERIAL_SUCCESS: 'GET_MATERIAL_SUCCESS',

  DELETE_MATERIAL: 'DELETE_MATERIAL',
  DELETE_MATERIAL_ERROR: 'DELETE_MATERIAL_ERROR',
  DELETE_MATERIAL_SUCCESS: 'DELETE_MATERIAL_SUCCESS',

  UPDATE_MATERIAL: 'UPDATE_MATERIAL',
  UPDATE_MATERIAL_ERROR: 'UPDATE_MATERIAL_ERROR',
  UPDATE_MATERIAL_SUCCESS: 'UPDATE_MATERIAL_SUCCESS',
};

export const getMaterial = materialId =>
  callApi({
    types: {
      pending: TYPES.GET_MATERIAL,
      success: TYPES.GET_MATERIAL_SUCCESS,
      error: TYPES.GET_MATERIAL_ERROR,
    },
    request: () => axios.get(`materials/${materialId}/`),
  });

export const deleteMaterial = materialId =>
  callApi({
    types: {
      pending: TYPES.DELETE_MATERIAL,
      success: TYPES.DELETE_MATERIAL_SUCCESS,
      error: TYPES.DELETE_MATERIAL_ERROR,
    },
    request: () => axios.delete(`materials/${materialId}/`),
    messages: {
      success: 'Material has been successfully deleted!',
      error: 'Material deletion unsuccessful',
    },
  });

export const updateMaterial = (materialId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_MATERIAL,
      success: TYPES.UPDATE_MATERIAL_SUCCESS,
      error: TYPES.UPDATE_MATERIAL_ERROR,
    },
    request: () => axios.put(`materials/${materialId}/`, data),
    messages: {
      success: 'Material has been successfully edited!',
      error: 'Material update unsuccessful',
    },
  });

export const INITIAL_STATE = {
  status: undefined,
  materials: [],
  error: undefined,
  params: undefined,
};

export const materialsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_MATERIAL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_MATERIAL_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Material: action.payload,
        error: undefined,
      };
    case TYPES.GET_MATERIAL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_MATERIAL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_MATERIAL_SUCCESS:
      const deletedMaterial = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: state.materials.filter(material => material.id !== deletedMaterial.id),
        error: undefined,
      };
    case TYPES.DELETE_MATERIAL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_MATERIAL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UPDATE_MATERIAL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};
