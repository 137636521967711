import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';
import { useTheme } from 'emotion-theming';
import { PlusCircleOutlined } from '@ant-design/icons';

import { BasicModal as Modal, Button, Label } from '@luxe/components';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import {
  createRiskProfile,
  deleteRiskProfiles,
  getRiskProfiles,
  trackRiskProfileChange,
} from 'admin/riskprofiles/modules/riskprofiles';
import RiskProfileTable from './RiskProfileTable';

const RiskProfileListView = () => {
  const dispatch = useDispatch();
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [selectedRiskProfiles, setSelectedRiskProfiles] = useState([]);
  const { riskProfiles } = useSelector(store => store.riskProfiles);
  const status = useSelector(store => store.riskProfiles.status);

  useEffect(() => {
    dispatch(getRiskProfiles());
    dispatch(trackRiskProfileChange(false));
  }, [dispatch]);

  const { space } = useTheme();
  const onCreate = values => {
    dispatch(createRiskProfile(values));
    setIsCreateModalShowing(false);
  };

  const onDelete = () => {
    selectedRiskProfiles.forEach(profile => dispatch(deleteRiskProfiles(profile.id)));
    setSelectedRiskProfiles([]);
    setIsDeleteModalShowing(false);
  };

  const setSelectedProfiles = selectedIDs => {
    const selectedProfiles = [];
    riskProfiles.forEach(profile => {
      if (selectedIDs.includes(profile.id)) {
        selectedProfiles.push({
          id: profile.id,
          name: profile.name,
        });
      }
    });
    setSelectedRiskProfiles(selectedProfiles);
  };

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view risk-profiles">
      <Row gutter={[4, 8]}>
        <Col span={10} align="start">
          <h1>Risk Profiles</h1>
        </Col>
        <Col span={10} align="end">
          {selectedRiskProfiles.length > 0 && (
            <>
              <span>{selectedRiskProfiles.length} Items Selected </span>
              <Button
                variant="danger"
                onClick={() => {
                  setIsDeleteModalShowing(true);
                }}
              >
                Delete Profile
              </Button>
            </>
          )}
        </Col>
        <Col span={4} align="end">
          <Button
            variant="primary"
            style={{ marginTop: '4px' }}
            onClick={() => {
              setIsCreateModalShowing(true);
            }}
          >
            <PlusCircleOutlined />
            Create Risk Profile
          </Button>
        </Col>
      </Row>
      <Container>
        {riskProfiles && (
          <RiskProfileTable riskProfiles={riskProfiles} status={status} setSelectedProfiles={setSelectedProfiles} />
        )}
      </Container>
      <CreateRiskProfileForm
        visible={isCreateModalShowing}
        onCancel={() => {
          setIsCreateModalShowing(false);
        }}
        onCreate={onCreate}
      />
      <DeleteRiskProfileForm
        visible={isDeleteModalShowing}
        onCancel={() => {
          setIsDeleteModalShowing(false);
        }}
        onDelete={onDelete}
        selectedRiskProfiles={selectedRiskProfiles}
      />
    </ListViewContent>
  );
};

const CreateRiskProfileForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new Risk Profile"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_risk_profile_form"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="name"
          label={<label style={{ color: 'white' }}>Name</label>}
          rules={[
            {
              required: true,
              message: 'Please input the title of Risk Profile!',
            },
          ]}
        >
          <Label placeholder="Risk Profile Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const DeleteRiskProfileForm = ({ visible, onDelete, onCancel, selectedRiskProfiles }) => {
  return (
    <Modal
      visible={visible}
      title="Delete a Risk Profile"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onDelete}
    >
      You will be deleting the following Risk Profiles:
      <ul>
        {selectedRiskProfiles.map((profile, index) => {
          return <li key={index}>{profile.name}</li>;
        })}
      </ul>
      Are you sure you want to do this?
    </Modal>
  );
};

export default RiskProfileListView;
