import React from 'react';
import { EditIcon } from '@luxe/components';
import { Link } from 'react-router-dom';

export const getEditLink = product => {
  let url = '/admin/products/' + product.id + '/';
  return (
    <Link to={url}>
      <EditIcon />
    </Link>
  );
};
