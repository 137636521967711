import React from 'react';
import { EditIcon } from '@luxe/components';
import { Link } from 'react-router-dom';

export const getEditLink = asset => {
  let url = '/admin/assets/' + asset.id + '/';
  return (
    <Link to={url}>
      <EditIcon />
    </Link>
  );
};
