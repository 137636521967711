import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_LOGGED_IN_USER: 'GET_LOGGED_IN_USER',
  GET_LOGGED_IN_USER_ERROR: 'GET_LOGGED_IN_USER_ERROR',
  GET_LOGGED_IN_USER_SUCCESS: 'GET_LOGGED_IN_USER_SUCCESS',
  PUT_LOGGED_IN_USER: 'PUT_LOGGED_IN_USER',
  PUT_LOGGED_IN_USER_ERROR: 'PUT_LOGGED_IN_USER_ERROR',
  PUT_LOGGED_IN_USER_SUCCESS: 'PUT_LOGGED_IN_USER_SUCCESS',
  PUT_ACCOUNT_USER_PREFERENCE: 'PUT_ACCOUNT_USER_PREFERENCE',
  PUT_ACCOUNT_USER_PREFERENCE_ERROR: 'PUT_ACCOUNT_USER_PREFERENCE_ERROR',
  PUT_ACCOUNT_USER_PREFERENCE_SUCCESS: 'PUT_LOGGED_USER_PREFERENCE_SUCCESS',
  SET_FRESH_LOGIN: 'SET_FRESH_LOGIN',
};

const INITIAL_STATE = {
  item: {},
  freshLogin: false,
  redirectUrl: '/users/profile/',
};

export default function reducer(state = INITIAL_STATE, action) {
  if (action.payload && action.payload.permissions) {
    action.payload.isAdmin = action.payload.permissions.includes('admin');
  }

  if (action.payload && action.payload.email) {
    action.payload.isStaff =
      action.payload.email.endsWith('riskpulse.com') || action.payload.email.endsWith('everstream.ai');
  }

  switch (action.type) {
    case TYPES.GET_LOGGED_IN_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_LOGGED_IN_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        account: action.payload,
        error: undefined,
      };
    case TYPES.PUT_LOGGED_IN_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.PUT_LOGGED_IN_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.PUT_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        account: action.payload,
        error: undefined,
      };
    case TYPES.PUT_ACCOUNT_USER_PREFERENCE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.PUT_ACCOUNT_USER_PREFERENCE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.PUT_ACCOUNT_USER_PREFERENCE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        account: action.payload,
        error: undefined,
      };
    case TYPES.SET_FRESH_LOGIN:
      return {
        ...state,
        freshLogin: true,
        redirectUrl: action.returnTo,
      };
    default:
      return state;
  }
}

export const getLoggedInUser = () => {
  return callApi({
    types: {
      pending: TYPES.GET_LOGGED_IN_USER,
      success: TYPES.GET_LOGGED_IN_USER_SUCCESS,
      error: TYPES.GET_LOGGED_IN_USER_ERROR,
    },
    request: () => {
      return axios.get(`account/user/`);
    },
  });
};

export const putLoggedInUser = data =>
  callApi({
    types: {
      pending: TYPES.PUT_LOGGED_IN_USER,
      success: TYPES.PUT_LOGGED_IN_USER_SUCCESS,
      error: TYPES.PUT_LOGGED_IN_USER_ERROR,
    },
    request: () => axios.put(`account/user/`, data),
  });

export const updateUserPreference = data =>
  callApi({
    types: {
      pending: TYPES.PUT_ACCOUNT_USER_PREFERENCE,
      success: TYPES.PUT_ACCOUNT_USER_PREFERENCE_SUCCESS,
      error: TYPES.PUT_ACCOUNT_USER_PREFERENCE_ERROR,
    },
    request: () => axios.patch(`account/user/prefs/`, data),
  });
