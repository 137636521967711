import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ScoreDetailsPage from 'assets/containers/LocationRiskDetailsView/ScoreDetailsView/ScoreDetailsPage';
import { getAllComponentsFromRiskIndex } from 'assets/modules/utils';
import { isEmpty } from 'utils';
import {
  clearComponentHistory,
  clearComponentRiskDetails,
  getComponentHistoryDetail,
  getComponentRiskDetails,
  updateComponentScoreData,
} from 'assets/modules/components';
import { getProactiveCountryRisk, getRiskModel, getRiskModels } from 'admin/riskmodels/modules/riskmodels';
import { Layout, Spin } from 'antd';

const ScoreDetails = () => {
  const { riskHistory, scoreData, riskDetails } = useSelector(store => store.components);
  const { id, riskModelId, compId } = useParams();
  const history = useHistory();
  const firstLoad = useRef(true);
  const dispatch = useDispatch();
  const { proactiveCountryRisk, RiskModel: riskModel } = useSelector(store => store.riskModels);

  useEffect(() => {
    if (proactiveCountryRisk && compId && riskModel) {
      const allComponents = getAllComponentsFromRiskIndex(proactiveCountryRisk);
      const modelComponents = getAllComponentsFromRiskIndex(riskModel);
      const component = allComponents.find(comp => comp.component_id.toString() === compId.toString());
      const modelComponent = modelComponents.find(comp => comp.id.toString() === compId.toString());
      component.summary = modelComponent.summary;
      component.description = modelComponent.description;
      component.rule = modelComponent.rule;
      dispatch(updateComponentScoreData(component));
    }
  }, [proactiveCountryRisk, compId, scoreData, riskModel, dispatch]);

  useEffect(() => {
    if (isEmpty(proactiveCountryRisk) && !riskModel) {
      dispatch(getProactiveCountryRisk({ countryId: id, riskModelId }));
      dispatch(getRiskModels());
      dispatch(getRiskModel({ id: riskModelId }));
    }
  }, [proactiveCountryRisk, riskModel, riskModelId, dispatch, id]);

  useEffect(() => {
    if (id && riskModelId && compId) {
      if (firstLoad.current) {
        dispatch(
          getComponentHistoryDetail({
            id: id,
            riskModelId: riskModelId,
            componentId: compId,
            datasource: 'countries',
          }),
        );
        dispatch(
          getComponentRiskDetails({
            id: id,
            riskModelId: riskModelId,
            componentId: compId,
            datasource: 'countries',
          }),
        );
      }
      firstLoad.current = false;
    }
  }, [proactiveCountryRisk, id, riskModelId, compId, dispatch, riskHistory, riskDetails]);

  const returnToScorecard = () => {
    history.push(`/country-risk/${id}/risk/`);
    dispatch(clearComponentHistory());
    dispatch(clearComponentRiskDetails());
  };

  if (!scoreData) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  return (
    <>
      <ScoreDetailsPage
        proactiveRisk={proactiveCountryRisk}
        riskScore={scoreData}
        riskDetails={riskDetails}
        riskhistory={riskHistory}
        returnToScorecard={returnToScorecard}
      />
    </>
  );
};

export default ScoreDetails;
