import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import { BehaviorSubject, Observable } from 'rxjs';
import { link2DWidth, linkName, nodeColor } from './graphUtilities';
import { NODE_COLOR_INCIDENT_RISK_SCORE, NODE_COLOR_RISK_SCORE, NODE_COLOR_TIER } from './viz';
import { isDiscovered } from './graphUtilities';

export class Graph {
  #nodes = new Map();
  #links = new Map();
  #allGraphNodes = new Map();
  #allGraphLinks = new Map();
  #visibleNodeIds = [];
  #visibleLinkIds = [];
  #countryDependency = new Map();
  #tier0NodeIds = new Set();
  // Any data accessed without using observers will not re-render react tree on update.
  observers = {
    initialized: new BehaviorSubject(false),
    nodes: new BehaviorSubject([]),
    links: new BehaviorSubject([]),
    visibleNodes: new BehaviorSubject([]),
    visibleLinks: new BehaviorSubject([]),
    graphNodes: new BehaviorSubject([]),
    graphLinks: new BehaviorSubject([]),
    nodeCount: new BehaviorSubject(0),
    totalNodeCount: new BehaviorSubject(0),
    countryDependency: new BehaviorSubject(new Map()),
    tier0NodeIds: new BehaviorSubject(new Set()),
  };

  generateGraphNodesAndLinks() {
    this.#allGraphNodes = new Map();
    this.#allGraphLinks = new Map();
    this.#nodes.forEach((node, nodeId) => {
      const newNode = {
        id: node.id,
        name: node.name,
        type: node.type,
        incidentRiskScore: node.reactive__max_score,
        proactiveScoresOverallScore: node.proactive_scores__overall_score,
        incidentColor: nodeColor(node, NODE_COLOR_INCIDENT_RISK_SCORE),
        riskColor: nodeColor(node, NODE_COLOR_RISK_SCORE),
        tierColor: nodeColor(node, NODE_COLOR_TIER),
        isDiscovered: isDiscovered(node),
        tierLevel: node.tier_level,
        sourceType: node.source_type,
        childNodes: [], // down stream
        parentNodes: [], // up stream
        childLinks: [], // down stream
        parentLinks: [], // up stream
        collapsed: false,
        hidden: false,
      };
      this.#allGraphNodes.set(nodeId, newNode);
    });
    this.#links.forEach((link, linkId) => {
      const newLink = {
        id: link.id,
        title: linkName(link),
        source: link.source,
        target: link.target,
        sourceNodeId: link.source,
        targetNodeId: link.target,
        collapsable: false,
        hidden: false,
      };
      this.#allGraphLinks.set(linkId, newLink);
      let node = this.#allGraphNodes.get(link.source);
      node.childNodes = _.uniq([...node.childNodes, link.target]);
      node.childLinks.push(link.id);
      node = this.#allGraphNodes.get(link.target);
      node.parentNodes = _.uniq([...node.parentNodes, link.source]);
      node.parentLinks.push(link.id);
    });
    return this;
  }

  determineDependencies() {
    const findDependencies = (node, t0NodeId, tier2 = [], tier3 = [], tier = 0) => {
      const tier1 = [];
      let traverseParent = false;
      if (node.childNodes.includes(t0NodeId)) {
        tier1.push(t0NodeId);
      }
      const originalNode = this.getNode(node.id);
      if (
        tier === 0 ||
        !originalNode.tier1 ||
        (!originalNode.tier1?.length && tier1.length) ||
        (!originalNode.tier2?.length && tier2.length)
      )
        traverseParent = true;
      node.tier = originalNode.tier = (originalNode.tier ?? 1000) < tier ? originalNode.tier ?? 0 : tier;
      originalNode.tier1 = [...new Set([...(originalNode.tier1 || []), ...this.getNodes(tier1)])];
      originalNode.tier2 = [...new Set([...(originalNode.tier2 || []), ...tier2])];
      originalNode.tier3 = [...new Set([...(originalNode.tier3 || []), ...tier3])];
      this.#countryDependency.has(originalNode.country) ||
        this.#countryDependency.set(originalNode.country, {
          tier1: [],
          tier2: [],
          tier3: [],
          countryRisk: originalNode.country_risk,
        });
      let countryDeps = this.#countryDependency.get(originalNode.country);
      tier1.length && (countryDeps.tier1 = [...new Set([...countryDeps.tier1, originalNode])]);
      tier2.length && (countryDeps.tier2 = [...new Set([...countryDeps.tier2, originalNode])]);
      tier3.length && (countryDeps.tier3 = [...new Set([...countryDeps.tier3, originalNode])]);

      traverseParent &&
        node.parentNodes
          .filter(nodeId => nodeId !== node.id && nodeId !== t0NodeId)
          .forEach(nodeId => {
            findDependencies(
              this.getGraphNode(nodeId),
              t0NodeId,
              originalNode.tier1.length ? [originalNode] : [],
              originalNode.tier2.length ? [originalNode] : [],
              tier + 1,
            );
          });
    };

    const tier0nodes = [...this.#allGraphNodes.values()].filter(n => n.tierLevel?.includes(0) || !n.childNodes.length);
    tier0nodes.forEach(n => findDependencies(n, n.id, [], [], 0));
    this.#tier0NodeIds = new Set(tier0nodes.map(n => n.id));

    // Calculate t1,t2, and t3 relations(links)
    this.#nodes.forEach(n => {
      if (!n.tier1) {
        n.tier1 = [];
        n.tier2 = [];
        n.tier3 = [];
        n.tier = undefined;
      }
      n.t1Links = this.getLinksBetweenNodes(
        n.id,
        n.tier1.map(n => n.id),
      );
      n.t2Links = this.getLinksBetweenNodes(
        n.id,
        n.tier2.map(n => n.id),
      );
      n.t3Links = this.getLinksBetweenNodes(
        n.id,
        n.tier3.map(n => n.id),
      );
    });
    _.defer(() => {
      this.observers.countryDependency.next(this.#countryDependency);
      this.observers.tier0NodeIds.next(this.#tier0NodeIds);
    });
    return this;
  }

  generateCollapsibleLinks() {
    // generate new links that combines multiple links
    this.#links.forEach(link => {
      const linkId = `${link.source}-${link.target}`;
      const newLink = this.#allGraphLinks.get(linkId) ?? {
        id: linkId,
        source: link.source,
        target: link.target,
        sourceNodeId: link.source,
        targetNodeId: link.target,
        collapsable: true,
        collapsed: true,
        hidden: false,
        collapsedLinks: [],
      };
      newLink.collapsedLinks.push(link.id);
      this.#allGraphLinks.set(linkId, newLink);
      let node = this.#allGraphNodes.get(link.source);
      node.childLinks = _.uniq([...node.childLinks, linkId]);
      node = this.#allGraphNodes.get(link.target);
      node.parentLinks = _.uniq([...node.parentLinks, linkId]);
    });
    // calculate width of links
    const widthTiers = [...new Set([...this.#allGraphLinks.values()].map(l => l.collapsedLinks?.length || 1))];

    this.#allGraphLinks.forEach(link => {
      if (!link.collapsable) return;
      const eachSplit = 1 / link.collapsedLinks.length;
      let updatedSplit = 0;
      link.width = link2DWidth(link, widthTiers);
      // calculate curvature of links
      link.collapsedLinks.map((linkId, i) => {
        const l = this.#allGraphLinks.get(linkId);
        if (i === 0) {
          l.curvature = 0;
        } else if (i % 2 === 0) {
          l.curvature = -updatedSplit;
        } else {
          updatedSplit += eachSplit;
          l.curvature = updatedSplit;
        }
        return l;
      });
    });
    return this;
  }

  expandCollapseNode(nodeId) {
    if (!nodeId) {
      this.#allGraphNodes.forEach(node => {
        node.collapsed = false;
        node.hidden = false;
      });
      this.#allGraphLinks.forEach(link => {
        link.hidden = false;
      });
      return this;
    }
    const hideNode = id => {
      const n = this.#allGraphNodes.get(id);
      if (n.hidden) return;
      let links = n.childLinks.map(linkId => this.#allGraphLinks.get(linkId));
      if (!links.filter(l => !l.hidden).length) {
        n.hidden = true;
        n.parentLinks.forEach(linkId => {
          this.#allGraphLinks.get(linkId).hidden = true;
        });
        n.parentNodes.forEach(nodeId => nodeId !== id && hideNode(nodeId));
      }
    };
    const showNode = id => {
      const n = this.#allGraphNodes.get(id);
      if (!n.collapsed && !n.hidden) return;
      n.hidden = false;
      n.collapsed = false;
      n.parentLinks.forEach(linkId => (this.#allGraphLinks.get(linkId).hidden = false));
      n.parentNodes.forEach(nodeId => showNode(nodeId));
    };

    const node = this.#allGraphNodes.get(nodeId);
    if (!node) return this;
    if (node.collapsed) {
      node.collapsed = false;
      node.parentLinks.forEach(linkId => {
        this.#allGraphLinks.get(linkId).hidden = false;
      });
      node.parentNodes.forEach(nodeId => showNode(nodeId));
    } else {
      node.collapsed = true;
      node.parentLinks.forEach(linkId => {
        this.#allGraphLinks.get(linkId).hidden = true;
      });
      node.parentNodes.forEach(nodeId => nodeId !== node.id && hideNode(nodeId));
    }
    // update graph if called with id
    this.updateGraph();
    return this;
  }

  expandCollapseLink(linkId) {
    if (!linkId) {
      this.#allGraphLinks.forEach(link => {
        link.collapsable && (link.collapsed = true);
      });
      return this;
    }
    const link = this.#allGraphLinks.get(linkId);
    if (!link) return this;
    if (link.collapsable) {
      link.collapsed = false;
    } else {
      const key = `${link.sourceNodeId}-${link.targetNodeId}`;
      const collapsableLink = this.#allGraphLinks.get(key);
      collapsableLink && (collapsableLink.collapsed = true);
    }
    // update graph if called with id
    this.updateGraph();
    return this;
  }

  updateGraph() {
    let nodes = [...this.#allGraphNodes.values()];
    if (this.#visibleNodeIds.length) {
      nodes = this.#visibleNodeIds.map(id => this.#allGraphNodes.get(id));
    }
    const newNodes = nodes.filter(n => !n.hidden);

    let links = [...this.#allGraphLinks.values()];
    if (this.#visibleLinkIds.length) {
      links = this.#visibleLinkIds.map(id => this.#allGraphLinks.get(id));
    }
    const nonCollapsedLinks = [];
    const newLinks = links.filter(l => {
      if (l.collapsed && !l.hidden) return true;
      if (!l.collapsable) return false;
      nonCollapsedLinks.push(...l.collapsedLinks);
      return false;
    });

    const filteredLinks = links.filter(l => nonCollapsedLinks.includes(l.id) && !l.hidden);
    const uniqueLinks = filteredLinks.reduce((prev, link) => {
      const isPresent = prev.findIndex(
        k => k.title === link.title && k.sourceNodeId === link.sourceNodeId && k.targetNodeId === link.targetNodeId,
      );
      if (isPresent === -1) {
        prev.push(link);
      }
      return prev;
    }, []);

    newLinks.push(...uniqueLinks);

    _.defer(() => {
      this.observers.graphNodes.next(newNodes);
      this.observers.graphLinks.next(newLinks);
    });
    return this;
  }

  resetVisibleNodesAndLinks() {
    this.#visibleNodeIds = [];
    this.#visibleLinkIds = [];
    _.defer(() => {
      this.observers.visibleNodes.next(this.getAllNodes());
      this.observers.visibleLinks.next(this.getAllLinks());
    });
    this.expandCollapseNode();
    this.expandCollapseLink();
    return this;
  }

  updateVisibleNodesAndLinks(nodeIds, linkIds) {
    this.#visibleNodeIds = nodeIds ?? [];
    if (linkIds) {
      this.#visibleLinkIds = [
        ...linkIds,
        ...this.getGraphLinks(linkIds).map(link => `${link.sourceNodeId}-${link.targetNodeId}`),
      ];
    } else if (this.#visibleNodeIds.length) {
      const nodes = this.#visibleNodeIds.map(id => this.#allGraphNodes.get(id));
      const linkIds = new Set();
      nodes.forEach(n => {
        n.childLinks.forEach(linkId => {
          const link = this.#allGraphLinks.get(linkId);
          if (this.#visibleNodeIds.includes(link.targetNodeId)) {
            linkIds.add(linkId);
            linkIds.add(`${link.sourceNodeId}-${link.targetNodeId}`);
          }
        });
      });
      this.#visibleLinkIds = [...linkIds];
    } else {
      this.#visibleLinkIds = [];
    }
    _.defer(() => {
      this.observers.visibleNodes.next(this.getNodes(this.#visibleNodeIds));
      this.observers.visibleLinks.next(this.getLinks(this.#visibleLinkIds));
    });
    this.expandCollapseNode();
    this.expandCollapseLink();
    this.updateGraph();
    return this;
  }

  directedGraph(status) {
    if (status) {
      const NODES_PER_COLUMN = 10;
      const calculatedNodes = new Set(this.#tier0NodeIds);
      const maxColumns = new Map();

      const calculateIndex = n => {
        const parentNodes = n.parentNodes.filter(nodeId => !calculatedNodes.has(nodeId));
        const totalColumns = Math.ceil(parentNodes.length / NODES_PER_COLUMN);
        if (maxColumns[n.tier + 1] ?? 0 < totalColumns) maxColumns[n.tier + 1] = totalColumns;
        parentNodes.forEach((nodeId, i) => {
          this.#allGraphNodes.get(nodeId).index2D = i % totalColumns;
          calculatedNodes.add(nodeId);
        });
        this.getGraphNodes(parentNodes).forEach(node => calculateIndex(node));
      };

      this.getGraphNodes([...this.#tier0NodeIds]).forEach(n => calculateIndex(n));
      this.#allGraphNodes.forEach(n => {
        n.fx =
          n.tier === 0
            ? 0
            : n.tier === 1
            ? (n.index2D ?? 0) * 10 + 100
            : n.tier === 2
            ? (n.index2D ?? 0) * 10 + 200 + maxColumns[1] * 10
            : n.tier === 3
            ? (n.index2D ?? 0) * 10 + 300 + maxColumns[2] * 10 + maxColumns[1] * 10
            : 450 + maxColumns[3] * 10 + maxColumns[2] * 10 + maxColumns[1] * 10;
      });
    } else {
      this.#allGraphNodes.forEach(n => (n.fx = undefined));
    }
    return this;
  }

  loadGraph({ links, nodes, nodeCount, totalNodeCount }, enableDirectedGraph = true) {
    this.#nodes = nodes;
    this.#links = links;
    this.generateGraphNodesAndLinks();
    this.determineDependencies();
    this.generateCollapsibleLinks();
    this.resetVisibleNodesAndLinks();
    this.directedGraph(enableDirectedGraph);
    this.updateGraph();
    _.defer(() => {
      // moving side effects out of redux state update
      this.observers.nodes.next([...this.#nodes.values()]);
      this.observers.links.next([...this.#links.values()]);
      this.observers.nodeCount.next(nodeCount);
      this.observers.totalNodeCount.next(totalNodeCount);
      this.observers.initialized.next(true);
    });
    return this;
  }

  getNode(nodeId) {
    if (nodeId === undefined || nodeId === null) return null;
    return this.#nodes.get(nodeId);
  }
  getNodes(nodeIds) {
    if (!nodeIds?.length) return [];
    return nodeIds.map(id => this.#nodes.get(id));
  }
  getAllNodes() {
    return [...this.#nodes.values()];
  }

  getGraphNode(nodeId) {
    if (nodeId === undefined || nodeId === null) return null;
    return this.#allGraphNodes.get(nodeId);
  }
  getGraphNodes(nodeIds) {
    if (!nodeIds?.length) return [];
    return nodeIds.map(id => this.#allGraphNodes.get(id));
  }

  getLink(linkId) {
    if (linkId === undefined || linkId === null) return null;
    return this.#links.get(linkId);
  }
  getLinks(linkIds) {
    if (!linkIds?.length) return [];
    return linkIds.filter(l => !String(l).includes('-')).map(id => this.#links.get(id));
  }
  getAllLinks() {
    return [...this.#links.values()];
  }

  getGraphLink(linkId) {
    if (linkId === undefined || linkId === null) return null;
    return this.#allGraphLinks.get(linkId);
  }
  getGraphLinks(linkIds) {
    if (!linkIds?.length) return [];
    return linkIds.map(id => this.#allGraphLinks.get(id));
  }
  getLinksBetweenNodes(sourceIds, targetIds) {
    const s = [sourceIds].flat(),
      t = [targetIds].flat();
    const sNodes = this.getGraphNodes(s);
    const allLinks = this.getLinks([...new Set(sNodes.flatMap(n => n.childLinks))]);
    return allLinks.filter(l => t.includes(l.target));
  }
}

/**
 * Selector function to get data from graph provider.
 * Updates will trigger re-render.
 * observables are available in Graph.observers
 *
 * @param {(graph:Graph)=>BehaviorSubject} selector
 * @returns {Observable}
 */
export const useGraphSelector = selector => {
  const graph = useContext(GraphContext);

  if (!selector(graph) instanceof BehaviorSubject) {
    throw Error('useGraphSelector: Selector should return BehaviourSubject instance');
  }

  const [data, setData] = useState(() => selector(graph).getValue());
  useEffect(() => {
    const sub = selector(graph).subscribe(setData);
    return () => {
      sub.unsubscribe();
    };
  }, [selector, graph]);
  return data;
};

export const GraphContext = createContext(new Graph());

const GraphProvider = ({ value, children }) => {
  return <GraphContext.Provider value={value}>{children}</GraphContext.Provider>;
};

export default GraphProvider;
