import React from 'react';
import { Tabs } from 'antd';
import { TabContainer } from 'assets/containers/AssetDetailView/styles';

const UserTabView = ({ tabs, onTabChange, activeTab }) => {
  const { TabPane } = Tabs;
  return (
    <>
      <TabContainer onChange={onTabChange} activeKey={activeTab} style={{ overflow: 'visible' }}>
        {tabs.map(tab => {
          return (
            <TabPane tab={tab.title} key={tab.title}>
              {tab.content}
            </TabPane>
          );
        })}
      </TabContainer>
    </>
  );
};

export default UserTabView;
