import { filter, map } from 'rxjs/operators';
import { TYPES, getAssetGroups } from 'admin/assetgroups/modules/assetgroups';
import { getAsset } from 'admin/assets/modules/assets';
import { fetchAssets } from 'admin/assetgroups/modules/utils';

const updateAssetGroupTree = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.BULK_UPDATE_ASSET_GROUPS_SUCCESS),
    map(() => getAssetGroups()),
  );
};

const refetchAssetGroups = (action$, state$) => {
  const refetchTypes = [
    TYPES.CREATE_ASSET_GROUPS_SUCCESS,
    TYPES.UPDATE_ASSET_GROUPS_SUCCESS,
    TYPES.DELETE_ASSET_GROUPS_SUCCESS,
  ];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getAssetGroups()),
  );
};

const refetchAssets = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_ASSETS_TO_GROUP_SUCCESS, TYPES.REMOVE_ASSETS_FROM_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && !action.params.isSingle),
    map(action => fetchAssets(action.params)),
  );
};

const refetchSingleAsset = (action$, state$) => {
  const refetchTypes = [TYPES.REMOVE_ASSETS_FROM_GROUP_SUCCESS, TYPES.ADD_ASSETS_TO_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && action.params.isSingle),
    map(action => getAsset({ id: action.params.id })),
  );
};

const epics = {
  updateAssetGroupTree,
  refetchAssetGroups,
  refetchAssets,
  refetchSingleAsset,
};
export default epics;
