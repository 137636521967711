import { filter, map } from 'rxjs/operators';
import { getAsset, TYPES } from 'admin/assets/modules/assets';

//state$.getState() -- redux-observable
//rxjs - observable library -- screen of streamed data (promise: success || error) -- observer pattern
export const getAssetWithDetail = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_ASSET_DETAIL_SUCCESS),
    map(action => getAsset({ id: action.payload.asset.id })),
  );
};

const epics = {
  getAssetWithDetailEpic: getAssetWithDetail,
};
export default epics;
