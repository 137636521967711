import React from 'react';
import { useSelector } from 'react-redux';

const ThreatInfo = () => {
  const { threat } = useSelector(store => store.feedback.item);
  return (
    <div className="threat-info">
      <div className="threat-desc">
        <h2>Incident details</h2>
        <h2>{threat.name}</h2>
        <p>{threat.description}</p>
      </div>
    </div>
  );
};

export default ThreatInfo;
