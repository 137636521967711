import React from 'react';

import { Spin } from 'antd';

const LoadingScreen = props => {
  const height = props.modal ? '50vh' : '100vh';
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height,
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LoadingScreen;
