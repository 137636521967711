import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { updateInList, deleteRecursivelyFromList, deleteFromListById } from 'admin/utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_CORPORATES: 'GET_CORPORATES',
  GET_CORPORATES_ERROR: 'GET_CORPORATES_ERROR',
  GET_CORPORATES_SUCCESS: 'GET_CORPORATES_SUCCESS',

  GET_CORPORATE: 'GET_CORPORATE',
  GET_CORPORATE_ERROR: 'GET_CORPORATE_ERROR',
  GET_CORPORATE_SUCCESS: 'GET_CORPORATE_SUCCESS',

  DELETE_CORPORATES: 'DELETE_CORPORATES',
  DELETE_CORPORATES_ERROR: 'DELETE_CORPORATES_ERROR',
  DELETE_CORPORATES_SUCCESS: 'DELETE_CORPORATES_SUCCESS',

  CREATE_CORPORATES: 'CREATE_CORPORATES',
  CREATE_CORPORATES_ERROR: 'CREATE_CORPORATES_ERROR',
  CREATE_CORPORATES_SUCCESS: 'CREATE_CORPORATES_SUCCESS',

  UPDATE_CORPORATES: 'UPDATE_CORPORATES',
  UPDATE_CORPORATES_ERROR: 'UPDATE_CORPORATES_ERROR',
  UPDATE_CORPORATES_SUCCESS: 'UPDATE_CORPORATES_SUCCESS',

  REMOVE_ASSETS_FROM_CORP: 'REMOVE_ASSETS_FROM_CORP',
  REMOVE_ASSETS_FROM_CORP_ERROR: 'REMOVE_ASSETS_FROM_CORP_ERROR',
  REMOVE_ASSETS_FROM_CORP_SUCCESS: 'REMOVE_ASSETS_FROM_CORP_SUCCESS',

  ADD_ASSETS_TO_CORP: 'ADD_ASSETS_TO_CORP',
  ADD_ASSETS_TO_CORP_ERROR: 'ADD_ASSETS_TO_CORP_ERROR',
  ADD_ASSETS_TO_CORP_SUCCESS: 'ADD_ASSETS_TO_CORP_SUCCESS',
};

//TODO: update to use supplier APIs
export const getCorporates = () =>
  callApi({
    types: {
      pending: TYPES.GET_CORPORATES,
      success: TYPES.GET_CORPORATES_SUCCESS,
      error: TYPES.GET_CORPORATES_ERROR,
    },
    request: () => axios.get('corporates/'),
  });

export const getCorporate = supplierId =>
  callApi({
    types: {
      pending: TYPES.GET_CORPORATE,
      success: TYPES.GET_CORPORATE_SUCCESS,
      error: TYPES.GET_CORPORATE_ERROR,
    },
    request: () => axios.get(`corporates/${supplierId}`),
  });

export const deleteCorporate = supplierId =>
  callApi({
    types: {
      pending: TYPES.DELETE_CORPORATES,
      success: TYPES.DELETE_CORPORATES_SUCCESS,
      error: TYPES.DELETE_CORPORATES_ERROR,
    },
    params: { supplierId },
    request: () => axios.delete(`corporates/${supplierId}/`),
    messages: {
      success: 'Corporation has been successfully deleted!',
      error: 'Corporation deletion unsuccessful',
    },
  });

export const bulkDeleteCorporates = data =>
  callApi({
    types: {
      pending: TYPES.BULK_DELETE_CORPORATES,
      success: TYPES.BULK_DELETE_CORPORATES_SUCCESS,
      error: TYPES.BULK_DELETE_CORPORATES_ERROR,
    },
    request: () => axios.post(`corporates/bulk/`, data),
    messages: {
      success: 'Corporates have been successfully deleted!',
      error: 'Corporate deletion unsuccessful',
    },
  });

export const createCorporates = data => {
  return callApi({
    types: {
      pending: TYPES.CREATE_CORPORATES,
      success: TYPES.CREATE_CORPORATES_SUCCESS,
      error: TYPES.CREATE_CORPORATES_ERROR,
    },
    request: () => axios.post('corporates/', data),
    messages: {
      success: 'Corporation has been successfully created!',
      error: 'Corporation creation unsuccessful',
    },
  });
};

export const updateCorporates = (supplierId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_CORPORATES,
      success: TYPES.UPDATE_CORPORATES_SUCCESS,
      error: TYPES.UPDATE_CORPORATES_ERROR,
    },
    request: () => axios.put(`corporates/${supplierId}/`, data),
    messages: {
      success: 'Corporate has been successfully edited!',
      error: 'Corporate update unsuccessful',
    },
  });

//TODO: not delete but update
export const removeAssetFromCorp = (object_ids, assets, viewDataParams) => {
  const assetIds = [];
  assets.forEach(asset => {
    assetIds.push(asset.id);
  });
  const payload = {
    action: 'add_assets',
    asset_ids: assetIds,
    corporation_id: null,
  };
  return callApi({
    types: {
      pending: TYPES.REMOVE_ASSETS_FROM_CORP,
      success: TYPES.REMOVE_ASSETS_FROM_CORP_SUCCESS,
      error: TYPES.REMOVE_ASSETS_FROM_CORP_ERROR,
    },
    request: () => axios.post('corporates/bulk/', payload),
    params: viewDataParams,
    messages: {
      success: 'Facility has been removed from corporation!',
      error: 'Facility removal unsuccessful',
    },
  });
};

export const addAssetToCorp = (corpId, assets, viewDataParams) => {
  const assetIds = [];
  assets.forEach(asset => {
    assetIds.push(asset.id);
  });
  const payload = {
    action: 'add_assets',
    asset_ids: assetIds,
    corporation_id: corpId,
  };
  return callApi({
    types: {
      pending: TYPES.ADD_ASSETS_TO_CORP,
      success: TYPES.ADD_ASSETS_TO_CORP_SUCCESS,
      error: TYPES.ADD_ASSETS_TO_CORP_ERROR,
    },
    request: () => axios.post('corporates/bulk/', payload),
    params: viewDataParams,
    messages: {
      success: 'Facility has been added to corporation!',
      error: 'Facility addition unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  corporates: [],
  error: undefined,
  params: undefined,
};

export const corporatesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CORPORATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_CORPORATES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        corporates: action.payload,
        error: undefined,
      };
    case TYPES.GET_CORPORATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.GET_CORPORATE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_CORPORATE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        supplier: action.payload,
        error: undefined,
      };
    case TYPES.GET_CORPORATE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_CORPORATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_CORPORATES_SUCCESS:
      const deletedCorporate = state.corporates.filter(x => x.id === action.params.supplierId).pop();
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        corporates: [...deleteRecursivelyFromList(deletedCorporate, state.corporates)],
        error: undefined,
      };
    case TYPES.DELETE_CORPORATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_DELETE_CORPORATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_DELETE_CORPORATES_SUCCESS:
      const deletedCorporates = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        corporates: [...deleteFromListById(deletedCorporates.instance_ids, state.corporates)],
        error: undefined,
      };
    case TYPES.BULK_DELETE_CORPORATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.CREATE_CORPORATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_CORPORATES_SUCCESS:
      const newCorporate = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        corporates: [...state.corporates, newCorporate],
        error: undefined,
      };
    case TYPES.CREATE_CORPORATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_CORPORATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_CORPORATES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        corporates: [...updateInList(action.payload, state.corporates)],
        error: undefined,
      };
    case TYPES.UPDATE_CORPORATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.REMOVE_ASSETS_FROM_CORP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.REMOVE_ASSETS_FROM_CORP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.REMOVE_ASSETS_FROM_CORP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    case TYPES.ADD_ASSETS_TO_CORP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.ADD_ASSETS_TO_CORP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.ADD_ASSETS_TO_CORP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    default:
      return state;
  }
};
