import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

const options = { year: 'numeric', month: 'long', day: 'numeric' };
export default class DateTimeFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    longDateFormat: PropTypes.bool,
  };

  render() {
    const { value, longDateFormat } = this.props;
    const timeFormat = 'YYYY-MM-DD h:mm a';
    if (value && !isNaN(value)) {
      return moment
        .unix(value / 1000)
        .local()
        .format(timeFormat);
    } else if (value) {
      if (longDateFormat) {
        return new Date(value).toLocaleDateString('en-US', options);
      } else {
        return `${moment(value)
          .local()
          .format(timeFormat)}`;
      }
    }
    return null;
  }
}
