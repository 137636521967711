import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import getFromConfig from 'constants/config';

const MIXPANEL_TOKEN = getFromConfig('REACT_APP_MIXPANEL_TOKEN');
const APP_REGION = getFromConfig('REACT_APP_MAGLEV_REGION');
const localEnv = getFromConfig('REACT_APP_MAGLEV_ENV') === 'local';

const onLoad = (mix, org, user) => {
  if (user?.email) {
    mix.identify(user.email);

    if (org) {
      mix.add_group('org_guid', org.guid);
      mix.add_group('org_name', org.name);
      mix.add_group('app_region', APP_REGION);
    }

    mix.people.set({ name: user.name, email: user.email });
  }
};

const useMixpanel = () => {
  const loggedInUser = useSelector(store => store.account.account);
  const accountOrg = useSelector(store => store.accountOrg.accountOrg);

  useEffect(() => {
    if (loggedInUser?.prefs.analytics.tracking === true) {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: localEnv,
        ignore_dnt: localEnv,
        loaded: mix => {
          onLoad(mix, accountOrg, loggedInUser);
        },
      });
    }
  }, [loggedInUser, accountOrg]);

  const mixpanelTrack = (event, properties = {}) => {
    if (mixpanel?.config) {
      mixpanel.track(event, properties);
    }
  };
  return mixpanelTrack;
};

export default useMixpanel;
