import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/materialgroups/modules/materialgroups';
import { getMaterialGroups } from 'admin/materialgroups/modules/materialgroups';
import { fetchMaterials } from 'admin/materialgroups/modules/utils';
import { getMaterial } from 'admin/materials/modules/materials';

const refetchMaterialGroups = (action$, state$) => {
  const refetchTypes = [
    TYPES.CREATE_MATERIAL_GROUPS_SUCCESS,
    TYPES.UPDATE_MATERIAL_GROUPS_SUCCESS,
    TYPES.DELETE_MATERIAL_GROUPS_SUCCESS,
  ];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getMaterialGroups()),
  );
};

const refetchMaterials = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_MATERIALS_TO_GROUP_SUCCESS, TYPES.REMOVE_MATERIALS_FROM_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && !action.params.isSingle),
    map(action => fetchMaterials(action.params)),
  );
};

const refetchMaterial = (action$, state$) => {
  const refetchTypes = [TYPES.REMOVE_MATERIALS_FROM_GROUP_SUCCESS, TYPES.ADD_MATERIALS_TO_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && action.params.isSingle),
    map(action => getMaterial(action.params.id)),
  );
};

const epics = {
  refetchMaterialGroups,
  refetchMaterials,
  refetchMaterial,
};

export default epics;
