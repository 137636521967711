import { Tooltip } from 'antd';
import { isDiscovered } from 'discover/modules/graphUtilities';
import React from 'react';

const DetermineDiscover = ({ item }) => {
  const dot = (
    <Tooltip
      title="Discovered"
      overlayInnerStyle={{
        border: '4px solid #474747',
        backgroundColor: '#2e2e2e',
        fontSize: '14px',
        color: '#ffffff',
      }}
    >
      <span
        className="dot"
        style={{
          height: '10px',
          width: '10px',
          backgroundColor: '#2D8FA5',
          borderRadius: '50%',
          display: 'inline-block',
        }}
      ></span>
    </Tooltip>
  );
  return <span>{isDiscovered(item) ? dot : ''}</span>;
};

export default DetermineDiscover;
