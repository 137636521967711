import React from 'react';
import { Button } from '../Button/index';
import styled from '@emotion/styled';
const StyleButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: white;
  cursor: pointer;
  float: left;
  text-transform: capitalize;
  transition: none;
  &:hover,
  &:focus {
    background-color: transparent;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  border-radius: 0px 0px 0px 0px;
  margin-right: -1px;

  :first-child {
    border-radius: 4px 0px 0px 4px;
    margin-right: -1px;
  }
  :last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;
const ButtonInputGroup = ({ value, onChange, className, ...props }) => {
  const allowMultiple = props.allowMultiple;
  const options = props.options.map(option => {
    const onClick = () => {
      if (allowMultiple) {
        const newValue = value.includes(option.value)
          ? value.filter(x => x !== option.value)
          : [...value, option.value];
        onChange(newValue);
      } else {
        onChange(option.value);
      }
    };
    const cssClass = value.includes(option.value) ? 'active' : '';
    return (
      <StyleButton key={option.value} variant={'primary'} onClick={onClick} type="button" className={cssClass}>
        {option.label}
      </StyleButton>
    );
  });
  return (
    <div className={className} data-toggle="buttons">
      {options}
    </div>
  );
};

export { ButtonInputGroup };
