import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import ForceGraph2D from 'react-force-graph-2d';
import { useWindowWidth } from '@react-hook/window-size';
import { arrowColor, linkColor } from 'discover/modules/graphUtilities';
import useMixpanel from 'utils/hooks/Mixpanel';
import SpriteText from 'three-spritetext';
import * as THREE from 'three';
import { forceCollide, forceManyBody } from 'd3-force';
import { GraphContext, useGraphSelector } from 'discover/modules/GraphProvider';
import { GRAPH_MODES } from 'common/Constants';

const AssetDiscoverGraph = props => {
  const [NodeLabels2D, setNodeLabels2D] = useState(null);
  const [hoveredNode, setHoveredNode] = useState();
  const [clickedNode, setClickedNode] = useState();
  const [onInit, setOnInit] = useState(true);
  const [assetNode, setAssetNode] = useState({});
  const graphRef = useRef();
  const windowWidth = useWindowWidth();
  const graphWidth = windowWidth - 120;
  const graphBackgroundColor = '#2e2e2e';
  const arrowLength = 1.5;
  const arrowRelativePosition = 1;
  const REFRESH_COOLDOWN = 2000;
  const [cooldown, setCooldown] = useState(REFRESH_COOLDOWN);
  const setNodesRefreshed = props.setNodesRefreshed;
  const nodesRefreshed = props.nodesRefreshed;
  const mixpanelTrack = useMixpanel();

  const graph = useContext(GraphContext);
  const nodes = useGraphSelector(graph => graph.observers.graphNodes);
  const links = useGraphSelector(graph => graph.observers.graphLinks);

  useEffect(() => {
    if (Object.keys(assetNode).length === 0) {
      setAssetNode(nodes.find(n => n.name === props.asset.name) || assetNode);
    }
  }, [assetNode, nodes, props.asset.name]);

  useEffect(() => {
    setOnInit(true);
  }, [props.graphMode, props.graphFilters, nodes, links]);

  useEffect(() => {
    if (props.graphFilters.clear) {
      setHoveredNode(null);
    }
    setNodesRefreshed(true);
  }, [setNodesRefreshed, props.graphFilters]);

  useEffect(() => {
    if (props.graphMode === GRAPH_MODES.TIERED_2D) {
      graphRef.current.d3Force(
        'collide',
        forceCollide()
          .radius(n => n._radius * 2)
          .strength(1),
      );
      graphRef.current.d3Force('charge', forceManyBody().strength(-100));
    }
  }, [graphRef, props.graphMode]);

  const centerOnNode = node => {
    if (node && onInit) {
      if ([GRAPH_MODES.CLUSTERED_2D, GRAPH_MODES.TIERED_2D].includes(props.graphMode)) {
        const padding = (600 < graphWidth ? 600 : graphWidth) / 2 - 50;
        graphRef.current.zoomToFit(50, padding, n => n.id === node.id);
      }
      //TODO: else display previously stored zoom for 2d and 3d
      else {
        if ([node.x, node.y, node.z].includes(undefined) || [node.x, node.y, node.z].includes(null)) {
          graphRef.current.zoomToFit(2000);
        } else {
          const distance = 100;
          const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
          graphRef.current.cameraPosition(
            { x: node.x * distRatio, y: node.z * distRatio, z: node.y * distRatio },
            node,
            2000,
          );
        }
      }
      setOnInit(false);
    }
    if (nodesRefreshed) {
      setNodesRefreshed(false);
      setCooldown(REFRESH_COOLDOWN);
    }
  };

  const hoverNode = (node, prevNode) => {
    setCooldown(0);
    if (node && node?.id !== prevNode?.id) {
      setHoveredNode(node);
    } else {
      setHoveredNode(null);
    }
  };

  const clickLink = link => {
    graph.expandCollapseLink(link.id);
    mixpanelTrack('Click 2D Edge Scorecard View');
  };

  const paint2DLink = useCallback((link, ctx) => {
    let d = 0;
    let [sx, sy, cp1x, cp1y, ex, ey] = [
      link.source.x < link.target.x ? link.source.x : link.target.x,
      link.source.x < link.target.x ? link.source.y : link.target.y,
      link.__controlPoints ? link.__controlPoints[0] : (link.source.x + link.target.x) / 2,
      link.__controlPoints ? link.__controlPoints[1] : (link.source.y + link.target.y) / 2,
      link.source.x < link.target.x ? link.target.x : link.source.x,
      link.source.x < link.target.x ? link.target.y : link.source.y,
    ];
    let prevPoint = null;

    const dist2D = (x1, y1, x2, y2) => {
      // distance between two points
      let dx = x2 - x1;
      let dy = y2 - y1;
      return Math.sqrt(dx * dx + dy * dy);
    };
    const getQuadraticXY = t => {
      // Calculate point in 3 point bexier curve at given length ratio t
      return {
        x: (1 - t) * (1 - t) * sx + 2 * (1 - t) * t * cp1x + t * t * ex,
        y: (1 - t) * (1 - t) * sy + 2 * (1 - t) * t * cp1y + t * t * ey,
      };
    };

    const pointAt = dl => {
      // calculate point and it's tangent at given length ratio in 3 point bezier curve
      const { x, y } = getQuadraticXY(dl / d);
      if (!prevPoint) {
        const { x: x1, y: y1 } = getQuadraticXY(dl / d - 0.001);
        prevPoint = [x1, y1];
      }
      const angle = Math.atan2(prevPoint[1] - y, prevPoint[0] - x) + Math.PI;
      prevPoint = [x, y];
      return [x, y, angle];
    };

    if (link.title) {
      let textDisplayed = link.title;
      ctx.font = `1px Sans-Serif`;
      let letterPadding = ctx.measureText(' ').width * 1.05;
      let start = 0;
      let nbspace = textDisplayed.split(' ').length - 1;

      // Aproximate total length of 3 point bezier curve, k=[0-1], number of partitions
      let path = [];
      for (let k = 0; k <= 1; k += 0.5) {
        const getQuad = getQuadraticXY(k);
        path.push(getQuad);
      }
      for (let i = 1; i < path.length; i++) {
        d += dist2D(path[i - 1].x, path[i - 1].y, path[i].x, path[i].y);
      }

      if (d < ctx.measureText(textDisplayed).width + (textDisplayed.length - 1 + nbspace) * letterPadding) {
        let overflow = '\u2026';
        let dt = overflow.length - 1;
        do {
          if (textDisplayed[textDisplayed.length - 1] === ' ') nbspace--;
          textDisplayed = textDisplayed.slice(0, -1);
        } while (
          textDisplayed &&
          d < ctx.measureText(textDisplayed + overflow).width + (textDisplayed.length + dt + nbspace) * letterPadding
        );
        textDisplayed += overflow;
      }

      start = d - ctx.measureText(textDisplayed).width - (textDisplayed.length + nbspace) * letterPadding;
      start /= 2;
      ctx.fillStyle = '#ffffff';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.lineWidth = 0.75;
      for (let t = 0; t < textDisplayed.length; t++) {
        let letter = textDisplayed[t];
        if (letter === ' ') letter = '-';
        let wl = ctx.measureText(letter).width;
        let p = pointAt(start + wl / 2);
        ctx.save();
        ctx.textAlign = 'center';
        ctx.translate(p[0], p[1]);
        ctx.rotate(p[2]);
        ctx.fillStyle = '#000000';
        if (letter === '\u2026') {
          ctx.fillRect(-0.8, -0.5, wl + 0.8, 1);
        } else {
          ctx.fillRect(-0.4, -0.5, wl + 0.45, 1);
        }
        ctx.fillStyle = '#ffffff';
        ctx.fillText(letter, 0, 0);
        ctx.restore();
        start += wl + letterPadding;
      }
    }
  }, []);

  const link2DWidth = link => link.width ?? 1;

  const curveLinks = (l, n) => l.curvature;

  const clickNode = node => {
    graph.expandCollapseNode(node.id);
    setClickedNode(node);
    if (props.graphType === GRAPH_MODES.CLUSTERED_3D) {
      graphRef.current.refresh();
    }
  };

  const nodeDetails = node => {
    if (node) {
      const discovered = `<span class="small" style="color:#2D8FA5;text-align:center;font-weight: bold;">DISCOVERED</span> `;
      return `
      <div style="border: 2px solid #c0c0c0;
      text-align: center;
      padding: 10px 10px;
      user-select: none;
      background-color: rgba(0,0,0,0.8);
      zIndex: 1;">

        <span class="small" style="color:#2D8FA5;text-align:center;font-size:14px;">${
          node.isDiscovered ? discovered : ''
        }${node.type.toUpperCase()}</span>\n
        <span class="small" style="color:White;text-align:center;border-radius: 100%; display: flex;">${
          node.name
        }</span>
        ${
          node.tierLevel
            ? `<span class="small" style="color:white;text-align:center;font-size:16px;">Tier ${node.tierLevel
                .sort()
                .join(', ')}</span>`
            : ''
        }
        ${
          node.proactiveScoresOverallScore
            ? `<span class="small" style="border-radius: 10px;
        vertical-align: middle;
        display: table;
        margin: 0 auto;
        background: ${node.riskColor};
        color: White;
        padding: 3px 10px;">${node.proactiveScoresOverallScore}</span>`
            : ''
        }\n\n
        </div>
      `;
    }
  };

  const focusOnActiveNode = () => centerOnNode(assetNode);

  const paintNode = useCallback(
    (node, ctx) => {
      const activeNodeID = clickedNode?.id;
      const hoveredNodeID = hoveredNode?.id;
      const color = node.riskColor;
      const hoveredWidth = 1;
      node._path = () => {}; // For reusing in pointerAreaPaint
      node._radius = 4; //For reusing in nodeVal
      if (node.id === assetNode?.id) {
        const a = (2 * Math.PI) / 6;
        const r = 3.5;
        node._radius = 7.5;
        ctx.beginPath();
        (node._path = ctx => {
          for (var i = 0; i < 6; i++) {
            ctx.lineTo(node.x + r * Math.cos(a * i), node.y + r * Math.sin(a * i));
          }
        })(ctx);
        ctx.strokeStyle = node.id === activeNodeID ? '#1a1a1a' : '#3eb040';
        ctx.fillStyle = color;
        ctx.lineWidth = node.id === hoveredNodeID ? hoveredWidth : 0.6;
        ctx.strokeStyle = node.id === hoveredNodeID ? '#2D8FA5' : '#bcd7de';
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        if (NodeLabels2D) {
          ctx.fillStyle = 'white';
          ctx.font = `1.5px Sans-Serif`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(node.name, node.x, node.y - 4.3);
        }
      }

      if (node.id !== assetNode?.id) {
        const width = node.id === activeNodeID ? 0.6 : 0.3;
        const style = node.id === activeNodeID ? '#1a1a1a' : '#bcd7de';
        if (node.collapsed) {
          ctx.beginPath();
          ctx.arc(node.x, node.y + 2, 1.5, -0.35, 1.1 * Math.PI, false);
          ctx.fillStyle = color;
          ctx.lineWidth = width;
          ctx.strokeStyle = style;
          ctx.closePath();
          ctx.stroke();
          ctx.fill();
          ctx.beginPath();
          ctx.arc(node.x - 2, node.y - 0.5, 1.5, 1.4, 1.8 * Math.PI);
          ctx.fillStyle = color;
          ctx.lineWidth = width;
          ctx.strokeStyle = style;
          ctx.closePath();
          ctx.stroke();
          ctx.fill();
          ctx.beginPath();
          ctx.arc(node.x + 2, node.y - 0.5, 1.5, -2.4, 0.6 * Math.PI);
          ctx.fillStyle = color;
          ctx.lineWidth = width;
          ctx.strokeStyle = style;
          ctx.closePath();
          ctx.stroke();
          ctx.fill();
        }
        ctx.beginPath();
        node._radius = 2.3;
        (node._path = ctx => {
          ctx.arc(node.x, node.y, 2, 0, 2 * Math.PI, false);
        })(ctx);
        ctx.fillStyle = color;
        ctx.lineWidth = node.id === hoveredNodeID ? hoveredWidth : width;
        ctx.strokeStyle = node.id === hoveredNodeID ? '#2D8FA5' : style;
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        if (node.sourceType === 'ekg') {
          ctx.beginPath();
          ctx.arc(node.x, node.y, 0.8, 0, 2 * Math.PI, false);
          ctx.fillStyle = '#2d8fa5';
          ctx.lineWidth = width;
          ctx.strokeStyle = '#FFF';
          ctx.closePath();
          node.id === hoveredNodeID && ctx.stroke();
          ctx.fill();
        }
        if (NodeLabels2D) {
          ctx.fillStyle = 'white';
          ctx.font = `1.5px Sans-Serif`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(node.name, node.x, node.y - 3);
        }
      }
    },
    [assetNode, hoveredNode, clickedNode, NodeLabels2D],
  );

  const nodePointerAreaPaint = (node, color, ctx) => {
    ctx.beginPath();
    ctx.fillStyle = color;
    node._path && node._path(ctx);
    ctx.closePath();
    ctx.fill();
  };

  const zoomEnd = coordinates => {
    setNodeLabels2D(coordinates.k >= 4 && 58 >= coordinates.k);
  };

  const get3DNodeColor = useCallback(
    node => {
      return clickedNode?.id === node.id ? '#2D8FA5' : node.riskColor;
    },
    [clickedNode],
  );

  const renderAssetNode = useCallback(
    node => {
      return new THREE.Mesh(
        new THREE.TorusGeometry(6, 2, 6, 6),
        new THREE.MeshStandardMaterial({
          color: get3DNodeColor(node),
          transparent: true,
          opacity: 0.95,
          flatShading: true,
          depthWrite: true,
        }),
      );
    },
    [get3DNodeColor],
  );

  const renderCollapsedNode = useCallback(
    node => {
      const group = new THREE.Group();
      group.add(
        new THREE.Mesh(
          new THREE.IcosahedronGeometry(4, 0),
          new THREE.MeshStandardMaterial({
            color: get3DNodeColor(node),
            transparent: true,
            opacity: node.sourceType === 'ekg' ? 0.65 : 0.9,
            flatShading: true,
            depthWrite: true,
          }),
        ),
      );
      if (node.sourceType === 'ekg') {
        group.add(
          new THREE.Mesh(
            new THREE.IcosahedronGeometry(1, 1),
            new THREE.MeshStandardMaterial({
              color: '#2D8FA5',
              flatShading: true,
              depthWrite: true,
            }),
          ),
        );
      }
      return group;
    },
    [get3DNodeColor],
  );

  const renderNormalNode = useCallback(
    node => {
      const group = new THREE.Group();
      group.add(
        new THREE.Mesh(
          new THREE.IcosahedronGeometry(3, 3),
          new THREE.MeshStandardMaterial({
            color: get3DNodeColor(node),
            transparent: true,
            opacity: node.sourceType === 'ekg' ? 0.65 : 0.9,
            flatShading: true,
            depthWrite: true,
          }),
        ),
      );
      if (node.sourceType === 'ekg') {
        group.add(
          new THREE.Mesh(
            new THREE.IcosahedronGeometry(1, 1),
            new THREE.MeshStandardMaterial({
              color: '#2D8FA5',
              flatShading: true,
              depthWrite: true,
            }),
          ),
        );
      }
      return group;
    },
    [get3DNodeColor],
  );

  const renderCustomNodeSet = useCallback(
    node => {
      const group = new THREE.Group();
      const getNodeLabel = node => {
        const sprite = new SpriteText(node.name.replace(/(?![^\n]{1,30}$)([^\n]{1,30})\s/g, '$1\n'));
        sprite.textHeight = 2;
        sprite.color = '#ffffff';
        sprite.backgroundColor = 'rgba(0,0,0,0.2)';
        sprite.borderColor = 'transparent';
        sprite.borderWidth = 2;
        sprite.padding = [2, 3];
        sprite.fontWeight = 200;
        sprite.borderRadius = 5;
        sprite.center.set(0.5, -0.15, 0.5);
        return sprite;
      };
      const nodeLabels = node => {
        const sprite = new SpriteText(node.name.replace(/(?![^\n]{1,30}$)([^\n]{1,30})\s/g, '$1\n'));
        sprite.color = 'lightgrey';
        sprite.textHeight = 1.5;
        sprite.position.y = 5;
        return sprite;
      };
      if (assetNode.id === node.id) {
        group.add(renderAssetNode(node));
        group.add(getNodeLabel(node));
        return group;
      }
      if (node.collapsed) {
        group.add(renderCollapsedNode(node));
        group.add(getNodeLabel(node));
        return group;
      }
      group.add(renderNormalNode(node));
      group.add(nodeLabels(node));
      return group;
    },
    [renderAssetNode, renderCollapsedNode, renderNormalNode, assetNode],
  );

  const get3dGraph = () => {
    return (
      <div style={{ zIndex: 1 }}>
        <ForceGraph3D
          ref={graphRef}
          graphData={{ nodes, links }}
          backgroundColor={graphBackgroundColor}
          onNodeClick={clickNode}
          nodeLabel={nodeDetails}
          onNodeHover={hoverNode}
          onNodeDrag={() => setCooldown(REFRESH_COOLDOWN)}
          onNodeDragEnd={node => {
            node.fx = node.x;
            node.fy = node.y;
            node.fz = node.z;
          }}
          width={graphWidth}
          height={500}
          linkDirectionalArrowLength={arrowLength}
          linkDirectionalArrowRelPos={arrowRelativePosition}
          linkDirectionalArrowColor={arrowColor}
          nodeThreeObject={renderCustomNodeSet}
          cooldownTime={cooldown}
          nodeResolution={30}
          onEngineStop={focusOnActiveNode}
        />
      </div>
    );
  };

  const get2dGraph = () => {
    return (
      <div style={{ zIndex: 1 }}>
        <ForceGraph2D
          ref={graphRef}
          graphData={{ nodes, links }}
          backgroundColor={graphBackgroundColor}
          onNodeClick={clickNode}
          onLinkClick={clickLink}
          nodeLabel={nodeDetails}
          onNodeHover={hoverNode}
          onNodeDrag={() => setCooldown(REFRESH_COOLDOWN)}
          onNodeDragEnd={node => {
            node.fx = node.x;
            node.fy = node.y;
          }}
          onZoom={zoomEnd}
          width={graphWidth}
          height={600}
          linkDirectionalArrowLength={arrowLength}
          linkDirectionalArrowRelPos={arrowRelativePosition}
          linkDirectionalArrowColor={arrowColor}
          linkColor={linkColor}
          linkCurvature={curveLinks}
          linkCanvasObjectMode={() => 'after'}
          linkCanvasObject={paint2DLink}
          linkWidth={link2DWidth}
          cooldownTime={cooldown}
          nodeResolution={30}
          nodeRelSize={1}
          nodeVal={n => n._radius * 2}
          nodeCanvasObject={paintNode}
          nodePointerAreaPaint={nodePointerAreaPaint}
          onEngineStop={focusOnActiveNode}
        />
      </div>
    );
  };
  switch (props.graphMode) {
    case GRAPH_MODES.CLUSTERED_3D:
      return get3dGraph();
    case GRAPH_MODES.CLUSTERED_2D:
      return get2dGraph();
    case GRAPH_MODES.TIERED_2D:
    default:
      return get2dGraph();
  }
};

export default AssetDiscoverGraph;
