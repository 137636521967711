import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

import {
  FormItem,
  Table,
  Label as Input,
  Numeric as InputNumber,
  Button,
  EditIcon,
  CheckIcon,
  PlusIcon,
  DeleteIcon,
  Empty,
} from '@luxe/components';
import { Select } from 'layout/Select';
import { REQUESTED_DATA_STATUS } from 'admin/constants';

const TASK_NAME_MAX_LENGTH = 120;
const dueDateUnitOptions = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
];

const isValidTask = task => {
  let isValid = true;
  if (!task?.name?.length) isValid = false;
  if (!task?.due_date_unit?.length) isValid = false;
  if (!task?.due_date_value) isValid = false;
  return isValid;
};

const Selector = ({ name, value, options, placeholder, onChange, isMulti = false, ...props }) => {
  const selected = Array.isArray(value) ? value : options.find(x => x.value === value);
  const onChangeHandler = option => {
    const val = Array.isArray(option) ? option : option === null ? [] : option.value;
    onChange(val);
  };
  return (
    <Select
      key={name}
      isMulti={isMulti}
      stayOpen={false}
      selected={selected}
      options={options}
      onChange={onChangeHandler}
      backspaceRemoves
      placeholder={placeholder}
      {...props}
    />
  );
};

const NameEditingComponent = ({ rowIndex, record }) => {
  const displayWarning = !!record?.name && record.name.length === TASK_NAME_MAX_LENGTH;
  return (
    <>
      <FormItem
        name={['template', rowIndex, 'name']}
        style={{
          margin: displayWarning ? '20px 0 0 0' : 0,
        }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          key={`${rowIndex}-name`}
          placeholder="Task name"
          className="plan-playbook__task-name"
          maxLength={TASK_NAME_MAX_LENGTH}
        />
      </FormItem>
      {displayWarning && (
        <div>
          <span className="warning">Warning:</span>
          {` The ${TASK_NAME_MAX_LENGTH} character limit has been reached.`}
        </div>
      )}
    </>
  );
};

const OwnerEditingComponent = ({ rowIndex }) => {
  const users = useSelector(store => store.users?.users) || [];
  const userOptions = users.map(user => ({
    value: user.id,
    label: user.name,
  }));

  return (
    <FormItem
      name={['template', rowIndex, 'assignees']}
      style={{
        margin: 0,
      }}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Selector placeholder="Owner" options={userOptions} isMulti={true} />
    </FormItem>
  );
};

const DueDateEditingComponent = ({ rowIndex }) => {
  return (
    <div className="plan-playbook__task-due-date">
      <FormItem
        name={['template', rowIndex, 'due_date_value']}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        name={['template', rowIndex, 'due_date_unit']}
        style={{
          margin: 0,
          width: '100%',
        }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Selector placeholder="Unit" options={dueDateUnitOptions} />
      </FormItem>
    </div>
  );
};

const cellComponents = {
  name: NameEditingComponent,
  assignees: OwnerEditingComponent,
  due_date: DueDateEditingComponent,
};

const EditableCell = ({ playbook, editing, rowIndex, dataIndex, title, inputType, record, children, ...restProps }) => {
  const CellEditingComponent = cellComponents[dataIndex];

  return <td {...restProps}>{editing ? <CellEditingComponent rowIndex={rowIndex} record={record} /> : children}</td>;
};

const PlanPlaybookTaskTable = ({
  value,
  status,
  playbook,
  selectedTaskIds,
  setSelectedTaskIds,
  editingId,
  setEditingId,
}) => {
  let description = 'There are no tasks available for this Playbook';
  const form = Form.useFormInstance();

  const existingTaskIds = playbook?.template?.map(task => task.id);
  const isNewTask = task => !existingTaskIds?.includes(task.id);

  const isEditing = task => task.id === editingId;
  const hasEditingTask = !!editingId;

  const users = useSelector(store => store.users?.users) || [];
  const template = value || [];

  const addEmptyTask = () => {
    const newTask = {
      id: _.uniqueId(),
      name: '',
      assignees: [],
      due_date_value: '',
      due_date_unit: '',
    };

    const newTemplate = [...template, newTask];

    setEditingId(newTask.id);

    form.setFieldsValue({ template: newTemplate });
  };

  const saveTask = task => {
    setEditingId('');
  };

  const edit = task => {
    setEditingId(task.id);
  };

  const cancelEditingTask = task => {
    if (isNewTask(task)) {
      const newTemplate = template.filter(savedTask => task.id !== savedTask.id);
      form.setFieldsValue({ template: newTemplate });
      if (selectedTaskIds.indexOf(task.id) >= 0) {
        setSelectedTaskIds(selectedTaskIds.filter(id => id !== task.id));
      }
    }
    setEditingId('');
  };

  const columns = [
    {
      title: 'Tasks',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignees',
      width: '30%',
      editable: true,
      render: (text, task, index) => {
        const assignedTo = task.assignees
          ?.map(x => {
            const user = users.find(u => u.id === (x.value || x));
            return user ? user.name : '';
          })
          ?.filter(fil => fil)
          ?.join(', ');
        if (!assignedTo) return null;
        return <span>{assignedTo}</span>;
      },
    },
    {
      title: 'Relative Due Date',
      dataIndex: 'due_date',
      width: '30%',
      editable: true,
      render: (text, task, index) => {
        const amount = task?.due_date_value;
        const isSingular = amount === 1;
        let unit = task?.due_date_unit;

        if (isSingular && unit?.slice) {
          unit = unit.slice(0, -1);
        }

        return `in ${amount} ${unit}`;
      },
    },
    {
      title: <PlusIcon />,
      width: '5%',
      align: 'center',
      render: (_, task) => {
        const editing = isEditing(task);

        let confirmTaskButtonClassName = 'plan-playbook__task-button';
        const taskIsValid = isValidTask(task);

        if (!taskIsValid) {
          confirmTaskButtonClassName += ' plan-playbook__task-button--invalid';
        }

        const saveHandler = event => {
          event.preventDefault();
          saveTask(task);
        };

        return editing ? (
          <span>
            <button disabled={!taskIsValid} className={confirmTaskButtonClassName} onClick={saveHandler}>
              <CheckIcon style={{ fontSize: '20px' }} />
            </button>
            <button className="plan-playbook__task-button" onClick={() => cancelEditingTask(task)}>
              <DeleteIcon style={{ fontSize: '20px', marginLeft: '8px' }} />
            </button>
          </span>
        ) : (
          <button className="plan-playbook__task-button" onClick={() => edit(task)} disabled={hasEditingTask}>
            <EditIcon style={{ fontSize: '20px' }} />
          </button>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: idsSelected => {
      setSelectedTaskIds(idsSelected);
    },
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    const dataIndex = col.dataIndex;
    const inputTypes = {
      due_date: 'date',
      assignees: 'select',
      name: 'string',
    };

    const inputType = inputTypes[dataIndex];

    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType,
        rowIndex,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Table
        components={components}
        dataSource={template}
        columns={mergedColumns}
        rowSelection={rowSelection}
        rowKey="id"
        size="small"
        description={description}
        pagination={false}
        className="plan-playbook__task-table"
        locale={{ emptyText: <Empty description={status === REQUESTED_DATA_STATUS.PENDING ? 'Loading' : 'No Data'} /> }}
      />
      <div className="plan-playbook__add_task">
        <Button disabled={hasEditingTask} onClick={addEmptyTask} variant="secondary">
          Add Task
        </Button>
      </div>
    </>
  );
};

export default PlanPlaybookTaskTable;
