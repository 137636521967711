import { CompTooltip, LocationDetail } from './styles';
import { Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { filterComponents, getToolTip } from './index';

import { Link } from 'react-router-dom';
import { getData } from 'assets/modules/utils';

function RiskChart({ riskModel, url, riskIndex, history, riskFilter, keyName, risk }) {
  const [height, setHeight] = useState(300);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    let data = getData(riskIndex, risk, riskIndex);
    if (data.length && riskFilter.isFiltered && riskFilter.currentlyFiltered.includes(keyName)) {
      data = filterComponents(data, riskFilter, keyName);
    }
    if (data.length > 18) {
      const extraRows = Math.ceil((data.length - 18) / 6) * 50;
      setHeight(160 + extraRows);
    }
    setChartData(data);
  }, [setHeight, setChartData, keyName, risk, riskFilter, riskIndex]);

  function Chart({ data, riskModel, url, riskIndex, history }) {
    let comp_url = '';
    if (data) {
      data = data.map((risk, key) => {
        comp_url = url ? `${url}model/${riskModel.id}/score-details/${risk.component_id}/` : comp_url;

        return (
          <CompTooltip
            getPopupContainer={trigger => {
              return trigger;
            }}
            overlayClassName={'risk-heatmap-tooltip'}
            key={`index-location-${key}`}
            title={() => {
              return getToolTip(risk, history, riskIndex);
            }}
            mouseEnterDelay={0.5}
            placement="topLeft"
          >
            <Link to={comp_url} className={`location-detail-risk-item risk-chart-item-${risk.category}`}>
              <div className={'location-detail-risk-item-label'}>
                <p>{risk.name}</p>
                <p>{risk.score || '-'}</p>
              </div>
            </Link>
          </CompTooltip>
        );
      });
    }
    return data;
  }

  if (riskIndex) {
    return (
      <LocationDetail style={{ height: height }} key={`location-datail-${riskIndex}`}>
        <Chart data={chartData} riskModel={riskModel} url={url} riskIndex={riskIndex} history={history} />
      </LocationDetail>
    );
  } else {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }
}

export default RiskChart;
