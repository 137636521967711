import React from 'react';
import { Radio } from 'antd';
import { FREQUENCY } from 'common/Constants';

const IncidentAction = ({ ...props }) => {
  const updateChannelActionData = event => {
    props.updateFrequency({
      action: props,
      frequency: event.target.value,
      is_realtime: props.action?.is_realtime,
    });
  };

  return (
    <Radio.Group buttonStyle="solid" name="frequency" value={props.action?.frequency}>
      {FREQUENCY.map((d, i) => (
        <Radio.Button key={i} value={d.value} onChange={updateChannelActionData}>
          {d.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default IncidentAction;
