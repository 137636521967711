import { getViewData } from 'views/modules/views';

export function fetchMaterials({ selectedGroup, searchTerm, currentPage, orderBy }) {
  const pageOffset = (currentPage - 1) * 10;

  const query = {
    columns: ['name', 'modified_date', 'id'],
    url: 'materials',
    filters: [{ name: 'type', operator: '==', value: 'component' }],
    limit: 10,
    offset: pageOffset,
    order_by: orderBy,
  };

  if (selectedGroup && selectedGroup.id !== 'all') {
    query.filters.push({ name: 'group_id', operator: '==', value: selectedGroup.id });
  }
  if (searchTerm) {
    query.filters.push({ name: 'query', operator: 'icontains', value: searchTerm });
  }
  return getViewData('materials', query);
}
