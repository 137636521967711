import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useWindowHeight } from '@react-hook/window-size';
import { Tabs, Row, Radio } from 'antd';
import { TabContainer } from 'assets/containers/AssetDetailView/styles';
import AssetDiscoverTable from 'assets/containers/AssetDetailView/AssetDiscoverSection/AssetDiscoverTable';
import AssetDiscoverSubTable from 'assets/containers/AssetDetailView/AssetDiscoverSection/AssetDiscoverSubTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDependencyTableToggle,
  clearSubTierData,
  DEPS_TABLE_STATE_COUNTRIES,
  DEPS_TABLE_STATE_FACILITIES,
  resetNetworkGraphVisibleEdgesAndNodes,
  setSubTierData,
  setSubTierVisibility,
  setTableVisibility,
  unfocusNode,
  updateNetworkGraphVisibleEdgesAndNodes,
} from 'discover/modules/viz';
import NetworkDependenciesTable from 'discover/containers/DiscoverNetworkView/NetworkDiscoverList/NetworkDependenciesTable';
import SupplierDependenciesSubTable from './SupplierDependenciesSubTable';
import CountryDependenciesSubTable from './CountryDependenciesSubTable';
import { useGraphSelector } from 'discover/modules/GraphProvider';
import AssetDiscoverSubInnerTable from 'assets/containers/AssetDetailView/AssetDiscoverSection/AssetDiscoverSubInnerTable';
import SubTierTable from './SubTierTable';

const NETWORK_LIST_TABLES = {
  ASSET_FLOW: 'flow',
  FACILITY_DEPENDENCIES: 'deps',
};

const DiscoverNetworkMainTable = styled(({ className, toggleSubTier, activeTable, setActiveTable, incId }) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight() - 80;
  const [drawerHeight, setDrawerHeight] = useState(400);
  const [isCloseHidden, setIsCloseHidden] = useState(false);
  const { depsTableToggleState } = useSelector(store => store.networkGraph);
  const nodes = useGraphSelector(graph => graph.observers.nodes);
  const links = useGraphSelector(graph => graph.observers.links);

  useEffect(
    () => () => {
      dispatch(unfocusNode());
    },
    [dispatch],
  );

  const dragOver = drawerPosition => {
    if (drawerPosition.clientY > 0 && drawerHeight !== windowHeight - drawerPosition.clientY) {
      const height = windowHeight - (drawerPosition.clientY < 60 ? 60 : drawerPosition.clientY);
      setDrawerHeight(height < 100 ? 100 : height);
    }
  };

  const hideClose = flag => {
    setIsCloseHidden(flag);
  };

  return (
    <div className={`${className}`} style={{ height: drawerHeight }}>
      <div className={'resizer'} draggable="true" onDrag={dragOver}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {!incId && (
        <TabContainer
          className="list-tab-container"
          activeKey={activeTable}
          onTabClick={e => {
            setActiveTable(e);
          }}
        >
          <TabPane tab={'Flow'} key={NETWORK_LIST_TABLES.ASSET_FLOW} className={'tab-label'}>
            <label className="table-title-label">Facility Flows</label>
            <AssetDiscoverTable
              nodes={nodes}
              links={links}
              toggleSubTier={(list, direction, selectedNode) => toggleSubTier({ list, direction, selectedNode })}
            />{' '}
          </TabPane>
          <TabPane tab={'Dependencies'} key={NETWORK_LIST_TABLES.FACILITY_DEPENDENCIES} className={'tab-label'}>
            <Row className={'title-row'}>
              {depsTableToggleState === DEPS_TABLE_STATE_FACILITIES && (
                <label className="table-title-label">Facility Dependencies</label>
              )}
              {depsTableToggleState === DEPS_TABLE_STATE_COUNTRIES && (
                <label className="table-title-label">Country Dependencies</label>
              )}
              <Radio.Group
                className="deps-table-toggle"
                defaultValue={DEPS_TABLE_STATE_FACILITIES}
                value={depsTableToggleState}
                buttonStyle="solid"
                onChange={e => {
                  dispatch(changeDependencyTableToggle(e.target.value));
                }}
                size="small"
                style={{
                  top: '8px',
                  right: '8px',
                }}
              >
                <Radio.Button value={DEPS_TABLE_STATE_FACILITIES}>{DEPS_TABLE_STATE_FACILITIES}</Radio.Button>
                <Radio.Button value={DEPS_TABLE_STATE_COUNTRIES}>{DEPS_TABLE_STATE_COUNTRIES}</Radio.Button>
              </Radio.Group>
            </Row>
            <Row>
              <NetworkDependenciesTable toggleSubTier={toggleSubTier} />
            </Row>
          </TabPane>
        </TabContainer>
      )}
      {incId && <SubTierTable incId={incId} hideClose={hideClose} />}
      {!isCloseHidden && (
        <div className={'tab-close'} onClick={() => dispatch(setTableVisibility(false))}>
          X
        </div>
      )}
    </div>
  );
})`
  background-color: #212121;
  border-top: 20px solid #000000;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: row;
  top: 0;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0.6em;
    height: 0.6em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid black;
  }

  .resizer {
    background-color: #000000;
    height: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 40;

    :active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      opacity: 0;
    }

    :hover {
      cursor: ns-resize;
    }

    span {
      display: block;
      margin: 3px auto;
      height: 2px;
      width: 30px;
      background: #212121;
      border-radius: 9px;
    }
  }

  .ant-tabs {
    flex: 1;
  }

  .ant-tabs-tab {
    margin-left: 15px;
    user-select: none;
  }

  .tab-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 25px;
  }

  // .deps-table-radio-option {
  //   color: #ffffff;
  // }

  .list-tab-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* or space-around */
    text-align: center;
    min-height: 32px;
  }

  .deps-table-toggle {
    label.ant-radio-button-wrapper {
      border: solid #38b1cc;
      background-color: #1a1a1a;
      color: #ffffff;
    }

    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border: solid #38b1cc;
      background-color: #38b1cc !important;
    }
  }

  .table-title-label {
    height: 28px;

    /* NG / Header 4 */
    // font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    /* identical to box height, or 140% */
    display: flex;
    align-items: center;

    color: #ffffff;
  }
`;

const DiscoverNetworkSubTable = styled(({ className, subTierData, toggleSubTier, activeTable }) => {
  const windowHeight = useWindowHeight() - 80;
  const [drawerHeight, setDrawerHeight] = useState(400);
  const depsTableToggleState = useSelector(state => state.networkGraph?.depsTableToggleState);
  const [innerDrillDownData, setInnerDrillDownData] = useState({});
  const dispatch = useDispatch();

  const draggingDrawer = drawerPosition => {
    if (drawerPosition.clientY > 0 && drawerHeight !== windowHeight - drawerPosition.clientY) {
      const height = windowHeight - (drawerPosition.clientY < 60 ? 60 : drawerPosition.clientY);
      setDrawerHeight(height < 100 ? 100 : height);
    }
  };

  useEffect(() => {
    const nodes = [...subTierData.list?.nodes];
    subTierData.selectedNode && nodes.push(subTierData.selectedNode);
    dispatch(updateNetworkGraphVisibleEdgesAndNodes(subTierData.list?.links, nodes));
    return () => {
      dispatch(resetNetworkGraphVisibleEdgesAndNodes());
    };
  }, [subTierData, dispatch]);

  useEffect(() => toggleSubTier, [toggleSubTier]);

  useEffect(() => {
    setInnerDrillDownData({});
  }, []);

  const toggleNextDrilldown = data => {
    if (data?.primarySelectedNode) {
      const nodes = [data?.primarySelectedNode];
      data?.currNodeData && nodes.push(data?.currNodeData);
      dispatch(updateNetworkGraphVisibleEdgesAndNodes(data?.links, nodes));
    } else {
      const nodes = [...subTierData.list?.nodes];
      subTierData.selectedNode && nodes.push(subTierData.selectedNode);
      dispatch(updateNetworkGraphVisibleEdgesAndNodes(subTierData.list?.links, nodes));
    }
    setInnerDrillDownData(data);
  };

  return (
    <div className={`${className}`} style={{ height: drawerHeight }}>
      <div className={'resizer'} draggable="true" onDrag={draggingDrawer}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {activeTable === NETWORK_LIST_TABLES.ASSET_FLOW ? (
        <>
          {!innerDrillDownData?.links && (
            <AssetDiscoverSubTable
              subTierData={subTierData}
              toggleSubTier={(list, direction, selectedNode) => toggleSubTier({ list, direction, selectedNode })}
              toggleNextDrilldown={(links, isInbound, selectedNode, primarySelectedNode, currNodeData) =>
                toggleNextDrilldown({ links, isInbound, selectedNode, primarySelectedNode, currNodeData })
              }
            />
          )}
          {innerDrillDownData?.links && (
            <AssetDiscoverSubInnerTable data={innerDrillDownData} toggleNextDrilldown={() => toggleNextDrilldown({})} />
          )}
        </>
      ) : activeTable === NETWORK_LIST_TABLES.FACILITY_DEPENDENCIES ? (
        depsTableToggleState === DEPS_TABLE_STATE_FACILITIES ? (
          <SupplierDependenciesSubTable subTierData={subTierData} toggleSubTier={toggleSubTier} />
        ) : depsTableToggleState === DEPS_TABLE_STATE_COUNTRIES ? (
          <CountryDependenciesSubTable subTierData={subTierData} toggleSubTier={toggleSubTier} />
        ) : (
          <>No Data</>
        )
      ) : (
        <>No Data</>
      )}
    </div>
  );
})`
  background-color: #212121;
  border-top: 20px solid #000000;
  overflow-x: scroll;
  padding: 5px;
  width: 100%;

  .resizer {
    background-color: #000;
    height: 20px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 40;

    :active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      opacity: 0;
    }

    :hover {
      cursor: ns-resize;
    }

    span {
      background: #212121;
      border-radius: 9px;
      display: block;
      margin: 3px auto;
      height: 2px;
      width: 30px;
    }
  }
`;

const DiscoverNetworkTables = ({ incId }) => {
  const [activeTable, setActiveTable] = useState(NETWORK_LIST_TABLES.ASSET_FLOW);
  const { subTierData, subTierShowing } = useSelector(store => store.networkGraph);
  const dispatch = useDispatch();

  const toggleSubTier = useCallback(
    data => {
      !subTierShowing ? dispatch(setSubTierData(data)) : dispatch(clearSubTierData());
      dispatch(setSubTierVisibility(!subTierShowing));
    },
    [dispatch, subTierShowing],
  );

  if (!subTierShowing) {
    return (
      <DiscoverNetworkMainTable
        className="discover-network-list-table"
        toggleSubTier={toggleSubTier}
        activeTable={activeTable}
        setActiveTable={setActiveTable}
        incId={incId}
      />
    );
  } else {
    return (
      <DiscoverNetworkSubTable subTierData={subTierData} toggleSubTier={toggleSubTier} activeTable={activeTable} />
    );
  }
};

export default DiscoverNetworkTables;
