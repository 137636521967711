import { filter, map } from 'rxjs/operators';
import { getUserProfileChannels } from 'modal/userprofile/modules/notifications';
import { TYPES } from 'modal/userprofile/modules/userprofile';
import { getLoggedInUser } from 'account/modules/account';

const refetchUserProfileChannels = (action$, state$) => {
  const refetchTypes = [TYPES.UPDATE_USER_PROFILE_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getUserProfileChannels()),
  );
};

const refetchLoggedInUser = (action$, state$) => {
  const refetchTypes = [TYPES.UPDATE_USER_PROFILE_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getLoggedInUser()),
  );
};

const epics = {
  refetchUserProfileChannels,
  refetchLoggedInUser,
};
export default epics;
