import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import { Button, BasicModal as Modal, FormItem, Label } from '@luxe/components';
import { useTheme } from 'emotion-theming';

const { Text } = Typography;

const HeaderRow = ({ form, material, deleteMaterialFunction, editMaterialFunction }) => {
  const { space, colors, fontSizes } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const backUrl = `/admin/material-groups/`;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const nameRules = [
    {
      required: true,
      message: 'The material name cannot be blank',
    },
  ];

  const saveMaterialChanges = () => {
    if (editMaterialFunction) {
      form.validateFields().then(values => {
        const payload = {
          name: values.name,
          description: values.description,
          total_spend: values.totalSpend,
          total_quantity: values.totalQuantity,
          total_quantity_unit: values.totalQuantityUnit,
        };
        dispatch(editMaterialFunction(material.id, payload));
      });
    }
  };

  const deleteMaterial = () => {
    setDeleteModalVisible(true);
  };

  return (
    <>
      <Row style={{ marginBottom: `${space.xsmall}px` }}>
        <Col span={10} align="start">
          <FormItem name="name" label="Material Name" labelCol={{ span: 24 }} rules={nameRules}>
            <Label />
          </FormItem>
        </Col>
        <Col span={14} align="end">
          <Space>
            <Button
              variant="menu"
              type="link"
              form={form}
              onClick={saveMaterialChanges}
              style={{ backgroundColor: colors.primary }}
            >
              Save
            </Button>
            <Button variant="menu" type="link" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button variant="menu" type="link" form={form} onClick={deleteMaterial}>
              Delete
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        visible={deleteModalVisible}
        title="Delete Material?"
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        onOk={() => {
          setDeleteModalVisible(false);
          if (deleteMaterialFunction) {
            dispatch(deleteMaterialFunction(material.id));
          }
          history.push(backUrl);
        }}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          Are you sure that you want to delete {material.name}?
        </Text>
      </Modal>
    </>
  );
};

export default HeaderRow;
