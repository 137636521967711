import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Layout, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { DetailViewContent } from '@luxe/components/src/Container';
import HeaderRow from './HeaderRow';
import MaterialSettingsSection from './MaterialSettingsSection';
import {
  addMaterialsToGroup,
  getMaterialGroups,
  removeMaterialsFromGroup,
} from 'admin/materialgroups/modules/materialgroups';
import { getMaterial, deleteMaterial, updateMaterial } from 'admin/materials/modules/materials';
import { ItemDetailGroupsSection } from '@luxe/components/src/ItemDetail';
import { TabView } from '@luxe/components/src/Tabs';

const MaterialDetailView = () => {
  const { space } = useTheme();
  const { id: materialId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const material = useSelector(store => store.materials)?.Material;
  const materialGroups = useSelector(store => store.materialGroups)?.materialGroups || [];

  const settingsRef = useRef(null);
  const groupsRef = useRef(null);

  useEffect(() => {
    dispatch(getMaterial(materialId));
  }, [dispatch, materialId]);

  useEffect(() => {
    dispatch(getMaterialGroups());
  }, [dispatch]);

  useEffect(() => {
    if (material) {
      const initialValues = {
        name: material.name,
        description: material.description,
        totalSpend: material.total_spend,
        totalQuantity: material.total_quantity,
        totalQuantityUnit: material.total_quantity_unit,
      };
      form.setFieldsValue(initialValues);
    }
  }, [material, form]);

  if (!material) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const tabs = [
    {
      title: 'Settings',
      ref: settingsRef,
      content: <MaterialSettingsSection />,
    },
    {
      title: 'Groups',
      ref: groupsRef,
      content: (
        <ItemDetailGroupsSection
          item={material}
          itemName={'material'}
          groups={materialGroups}
          deleteFromGroupFunction={removeMaterialsFromGroup}
          addToGroupFunction={addMaterialsToGroup}
        />
      ),
    },
  ];

  return (
    <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <div style={{ marginTop: '0' }}>
        <Form form={form} name="material_detail_form" preserve={false}>
          <HeaderRow
            form={form}
            material={material}
            deleteMaterialFunction={deleteMaterial}
            editMaterialFunction={updateMaterial}
          />
          <TabView tabs={tabs} />
        </Form>
      </div>
    </DetailViewContent>
  );
};

export default MaterialDetailView;
