import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { Button, DeleteIcon, Section, Table } from '@luxe/components';
import { stringSorter } from 'utils';
import {
  getGroupModalSettings,
  MODAL_TYPES_KEYS,
} from '@luxe/components/src/GroupModal';
import PropTypes from 'prop-types';

const ItemDetailGroupsSection = ({ groups, item, itemName, title, deleteFromGroupFunction, addToGroupFunction }) => {
  const dispatch = useDispatch();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState();
  let description = `The ${itemName==='asset'?'facility':itemName} is not a member of any groups`;
  const tooltipText = itemName === "corporation" ? "facility" : "group";
  const actionColumn = group => {
    return (
      <Tooltip title={`Remove ${itemName==='asset'?'facility':itemName} from ${tooltipText}`}>
        <DeleteIcon
          onClick={e => {
            setSelectedGroup({...group, title: group.name});
            setIsModalShowing(true);
            setModalType(MODAL_TYPES_KEYS.REMOVE_FROM_GROUP);
          }}
        />
      </Tooltip>
    );
  };
  const getColumns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      render: group => <span>{group}</span>,
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'Actions',
      render: actionColumn,
      align: 'center',
    },
  ];

  const groupModalSettings = getGroupModalSettings({
    modalType: modalType,
    group: selectedGroup,
    itemName: itemName,
    title,
    onAddToGroup: (...props) => {
      dispatch(addToGroupFunction(...props, { id: item.id, isSingle: true }));
      setIsModalShowing(false);
    },
    onRemoveFromGroup: (...props) => {
      dispatch(deleteFromGroupFunction(...props, { id: item.id, isSingle: true }));
      setIsModalShowing(false);
    },
  });
  const isCorporation = itemName === 'corporation';
  const tabTitle = isCorporation ? "Corporations" : "Groups";
  const buttonName = isCorporation ? "Add to Corporations" : "Add to Groups";
  const corporationData = item.corporation !== null ? [item.corporation] : null;
  const tableData = isCorporation ? corporationData : item.groups

  const addItemToGroupButton = <Button
    key={'add-item-to-group-button'}
    variant="primary"
    onClick={() => {
      setModalType(MODAL_TYPES_KEYS.ADD_TO_GROUP);
      setIsModalShowing(true);
    }}>
      {buttonName}
  </Button>
  return (
    <>
      <Section title={tabTitle} buttons={[addItemToGroupButton]}>
        <div style={{ width: '100%' }}>
          <Table
            rowKey="id"
            columns={getColumns()}
            dataSource={tableData}
            description={description}
            size="small"
            pagination={!isCorporation}
          />
        </div>
        {groupModalSettings && <groupModalSettings.bodyWidget
          checkedItems={[item]}
          group={selectedGroup}
          groups={groups}
          title={title}
          modalType={modalType}
          rowData={[item]}
          isModalShowing={isModalShowing}
          itemName={itemName}
          modalSettings={groupModalSettings}
          onCancel={() => setIsModalShowing(false)}
        />}
      </Section>
    </>
  );
};

export default ItemDetailGroupsSection;

ItemDetailGroupsSection.propTypes = {
  deleteFromGroupFunction: PropTypes.func.isRequired,
  addToGroupFunction: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  itemName: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
};

ItemDetailGroupsSection.defaultProps = {
};
