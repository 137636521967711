import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spin, Layout, Row, Col } from 'antd';
import { useTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { FormItem, Label as Input, Button, BasicModal as Modal } from '@luxe/components';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import {
  getPlanPlaybook,
  createPlanPlaybook,
  updatePlanPlaybook,
  updatePlanPlaybookTasks,
} from 'admin/playbooks/modules/planPlaybooks';
import { getAllUsers } from 'admin/users/modules/users';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import PlanPlaybookTaskTable from './PlanPlaybookTaskTable';

import './index.css';

const isValidTask = task => {
  let isValid = true;
  if (!task?.name?.length) isValid = false;
  if (!task?.due_date_unit?.length) isValid = false;
  if (!task?.due_date_value) isValid = false;
  return isValid;
};

const PlanPlaybookDetailView = () => {
  const { id: playbookId } = useParams();
  const { space } = useTheme();
  const dispatch = useDispatch();
  const firstLoad = useRef(true);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [editingId, setEditingId] = useState('');

  const playbook = useSelector(store => store.planPlaybooks)?.PlanPlaybook;
  const status = useSelector(store => store.planPlaybooks)?.detailStatus;
  const { users, userFetching } = useSelector(store => store.users);
  const isSuccess = status === REQUESTED_DATA_STATUS.SUCCESS;
  const isPending = status === REQUESTED_DATA_STATUS.PENDING;

  const [form] = Form.useForm();
  const formTemplate = Form.useWatch('template', form);
  const formName = Form.useWatch('name', form);

  const playbookStoreId = playbook?.id;
  const hasExistingPlaybook = !!playbookId && !!playbook && playbookStoreId === playbookId;
  const viewReady = (playbookId && isSuccess) || !playbookId;

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (playbookId && playbookId !== playbookStoreId && userFetching === false) {
      dispatch(getPlanPlaybook(playbookId));
    }
  }, [dispatch, playbookId, playbookStoreId, userFetching]);

  useEffect(() => {
    if (hasExistingPlaybook && firstLoad.current) {
      playbook.template = playbook?.template?.map(temp => {
        temp.assignees = temp.assignees?.reduce((arr, item) => {
          const user = users.find(x => x.id === (item.value || item));
          if (user) {
            arr.push({ value: user.id, label: user.name });
          }
          return arr;
        }, []);
        return temp;
      });
      const initialValues = {
        name: playbook?.name,
        template: playbook?.template,
      };
      form.setFieldsValue(initialValues);
      firstLoad.current = false;
    }
  }, [playbook, hasExistingPlaybook, form, users]);

  if (!viewReady) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const hasUnsavedTasks = !_.isEqual(playbook?.template, formTemplate);
  const hasEditedPlaybookName = formName !== playbook?.name;
  const hasChanges = hasUnsavedTasks || hasEditedPlaybookName;
  const allTasksValid = !!formTemplate?.length && formTemplate?.every(isValidTask);
  const existingTaskIds = playbook?.template?.map(task => task.id);
  const isNewTask = task => !existingTaskIds?.includes(task.id);

  const savePlanPlaybookHandler = values => {
    const data = JSON.parse(JSON.stringify(values));
    data.template = data?.template?.map(task => {
      if (isNewTask(task)) {
        delete task['id'];
      }
      task.assignees = task.assignees?.map(x => x.value);
      return task;
    });
    if (hasExistingPlaybook) {
      const payload = {
        ...playbook,
        ...data,
      };
      dispatch(updatePlanPlaybook(payload));
    } else {
      dispatch(createPlanPlaybook(data));
    }
  };

  const onDelete = () => {
    const data = form.getFieldsValue();

    data.template = data.template
      .filter(task => {
        return selectedTaskIds.indexOf(task.id) === -1;
      })
      .map(task => {
        if (isNewTask(task)) {
          delete task['id'];
        }
        return task;
      });

    if (hasExistingPlaybook) {
      const payload = {
        ...playbook,
        ...data,
      };
      dispatch(updatePlanPlaybookTasks(payload));
    }

    form.setFieldsValue(data);
    setSelectedTaskIds([]);
    setIsDeleteModalShowing(false);
  };

  if (isPending && !!playbookId) return;

  const hasOneTaskSelected = selectedTaskIds.length === 1;
  const selectedText = hasOneTaskSelected ? `1 Item Selected` : `${selectedTaskIds.length} Items Selected`;

  const initialValues = hasExistingPlaybook
    ? {
        name: playbook?.name || '',
        template: playbook?.template || [],
      }
    : {
        name: '',
        template: [],
      };

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view plan-playbooks">
      <Form
        id="plan_playbook_form"
        name="plan_playbook_form"
        form={form}
        initialValues={initialValues}
        onFinish={savePlanPlaybookHandler}
      >
        <Row justify="space-between" gutter={[4, 8]}>
          <Col align="start">
            <FormItem
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please enter a playbook name' },
                { max: 100, message: 'Max. 100 characters' },
              ]}
            >
              <Input style={{ fontSize: '14px', padding: 8 }} placeholder="Playbook Name" />
            </FormItem>
          </Col>
          <Col align="end">
            {!editingId && selectedTaskIds.length > 0 && (
              <>
                <span style={{ marginRight: '4px' }}>{selectedText}</span>
                <Button
                  variant="danger"
                  onClick={() => {
                    setIsDeleteModalShowing(true);
                  }}
                >
                  Delete
                </Button>
              </>
            )}
            <Link to="/admin/plan-playbooks">
              <Button variant="secondary" style={{ marginTop: '4px' }}>
                Cancel
              </Button>
            </Link>
            <Button
              variant="primary"
              style={{ marginTop: '4px' }}
              disabled={!hasChanges || !allTasksValid}
              form="plan_playbook_form"
              type="submit"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Container>
          <FormItem name="template">
            <PlanPlaybookTaskTable
              status={status}
              playbook={playbook}
              selectedTaskIds={selectedTaskIds}
              setSelectedTaskIds={setSelectedTaskIds}
              editingId={editingId}
              setEditingId={setEditingId}
            />
          </FormItem>
        </Container>
      </Form>

      <DeleteTaskForm
        visible={isDeleteModalShowing}
        onCancel={() => {
          setIsDeleteModalShowing(false);
        }}
        onDelete={onDelete}
        selectedTaskIds={selectedTaskIds}
      />
    </ListViewContent>
  );
};

const DeleteTaskForm = ({ visible, onDelete, onCancel, selectedTaskIds }) => {
  const hasOneTaskSelected = selectedTaskIds.length === 1;
  const titleText = `Are you sure you want to delete ${hasOneTaskSelected ? 'this' : 'these'} task${
    hasOneTaskSelected ? '' : 's'
  }?`;
  const bodyText = `Deleting ${hasOneTaskSelected ? 'this' : 'these'} task${
    hasOneTaskSelected ? '' : 's'
  } cannot be undone.`;
  return (
    <Modal visible={visible} title={titleText} okText="Delete" cancelText="Cancel" onCancel={onCancel} onOk={onDelete}>
      {bodyText}
    </Modal>
  );
};

export default PlanPlaybookDetailView;
