import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import IndustryView from './Industry';
import { Row, Col, Button, Typography } from 'antd';

import {
  updateGeneralAccountSettings,
  cancelAccountChanges,
  updateImage,
  clearImageDataAction,
  getAccountOrg,
  updateAccount,
} from 'account/modules/accountOrg';
import { BasicModal as Modal } from '@luxe/components';

import styled from '@emotion/styled';

import LogoUpload from './LogoUpload';
import { MainContent } from '@luxe/components/src/Container';
import NotificationTemplateView from './NotificationTemplate';
import { trackWorkFlowChange } from 'admin/workflows/modules/workflows';

const { Text } = Typography;
const GeneralView = styled(props => {
  const dispatch = useDispatch();
  const { space, colors, fontSizes } = useTheme();

  const { ignored_industries, preferred_notification_template, update } = useSelector(
    store => store.accountOrg,
  )?.accountOrg;
  const [visible, setVisible] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [imageUpdated, setImageUpdated] = useState(false);

  const handleSave = () => {
    if (update) {
      dispatch(updateGeneralAccountSettings({ ignored_industries, update: false, preferred_notification_template }));
    }
    dispatch(updateImage(true));
  };
  const handleCancel = () => {
    if (update || imageUpdated) {
      setVisible(true);
    }
  };

  useEffect(() => {
    if (update && isInitialLoad) dispatch(getAccountOrg());
    setIsInitialLoad(false);
  }, [update, isInitialLoad, dispatch]);

  useEffect(() => {
    dispatch(trackWorkFlowChange(false));
  }, [dispatch]);

  return (
    <MainContent>
      <div className={`${props.className}`}>
        <div className={'container'} style={{ margin: `${space.none} ${space.small}px`, size: '14px' }}>
          <Row gutter={[4, 8]}>
            <Col span={10} align="start">
              <header>
                <h1>General</h1>
              </header>
            </Col>
            <Col span={14} align="end">
              <Button className={'submit general-button'} onClick={handleSave}>
                Save
              </Button>
              <Button className={'cancel general-button'} onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              {' '}
              <LogoUpload
                updatedImage={val => {
                  setImageUpdated(val);
                  dispatch(updateAccount({ update: true }));
                }}
              />
            </Col>
          </Row>

          <IndustryView />
          <NotificationTemplateView />
        </div>
        <Modal
          visible={visible}
          title="Cancel Account Setting Changes?"
          okText="Yes"
          cancelText="No"
          onCancel={() => setVisible(!visible)}
          onOk={() => {
            setVisible(false);
            dispatch(cancelAccountChanges());
            dispatch(clearImageDataAction(true));
            dispatch(getAccountOrg());
          }}
        >
          <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
            Are you sure you want to cancel your changes?
          </Text>
        </Modal>
      </div>
    </MainContent>
  );
})`
  .container {
    color: white;
    .cancel {
      color: #ffffff;
      background-color: #545454;
    }

    .cancel:hover {
      background-color: transparent;
      border: 0;
    }

    .general-button {
      border: 0;
      border-radius: 4px;
      color: #eeeeee;
      cursor: pointer;
      display: inline;
      float: right;
      font-size: 12px;
      margin: 4px;
      padding: 4px 10px;
    }

    .submit {
      background-color: ${props => props.theme.colors.primary};
      border: 1px solid #39b2c6;
      color: #eeeeee;
    }

    header {
      padding: 24px;

      h1 {
        font-size: 40px;
        line-height: 32px;
        color: white;
      }
    }
  }
`;
export default GeneralView;
