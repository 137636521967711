import { red, green } from '@ant-design/colors';

const spacesScale = [0, 4, 8, 16, 24, 32, 40, 80, 96];
const fontSizesScale = [12, 14, 16, 20, 24, 28, 32, 48, 64];

const baseColors = {
  black1: '#141414',
  black11: '#1B1B1B',
  black2: '#212121',
  black3: '#2E2E2E',
  black4: '#3B3B3B',
  black5: '#474747',
  white: '#FFFFFF',
};

const assetColors = {
  highRisk: '#DE3700',
  lowRisk: '#009702',
  medRisk: '#FF8700',
  noRisk: '#666666',
};

const pressedColors = {
  primaryPressed: '#236E80',
  secondaryPressed: '#2E2E2E',
  dangerPressed: '#991503',
};

const hoverColors = {
  primaryHover: '#38B1CC',
  secondaryHover: '#7A7A7A',
  dangerHover: '#E51F05',
};

const offPrimaryColors = {
  BG1: '#131C1E',
  BG2: '#162A2F',
  BG3: '#1C3940',
  BG4: '#1F4B55',
  BG5: '#246472',
};

export default {
  name: 'Luxe',
  space: Object.assign(spacesScale, {
    none: spacesScale[0],
    xxsmall: spacesScale[1],
    xsmall: spacesScale[2],
    small: spacesScale[3],
    medium: spacesScale[4],
    mediumLarge: spacesScale[5],
    large: spacesScale[6],
    xlarge: spacesScale[7],
    xxlarge: spacesScale[8],
  }),
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: 'Menlo, monospace',
  },
  colors: {
    // Base Colors
    ...baseColors,
    // Product Colors
    primary: '#2D8FA5',
    secondary: '#545454',
    track: '#858585',
    lightGrey: '#bfbfbf',
    positive: green.primary,
    negative: red.primary,
    //asset colors
    ...assetColors,
    // Function Colors
    text: '#000',
    background: '#212121',
    potato: '#F00',
    danger: '#BF1A04',
    tomato: 'DE3700',
    border: 'rgba(0, 0, 0, 0.1)',
    disabled: '#A1A1A1',
    disabledText: 'rgba(255,255,255,0.45)',
    //Pressed Colors
    ...pressedColors,
    ...hoverColors,
    ...offPrimaryColors,
  },
  fontSizes: Object.assign(fontSizesScale, {
    small: fontSizesScale[0],
    body: fontSizesScale[1],
    heading: fontSizesScale[4],
    contentTitle: fontSizesScale[5],
  }),
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: '22px',
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  itemAlignment: {
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
  },
};
