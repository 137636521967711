import styled from '@emotion/styled';
import { Empty, Table } from '@luxe/components';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { getFormattedDate } from 'assets/modules/utils';
import { clearFeedbackState, getAssetFeedbackSummary } from 'feedback/modules/feedback';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateSorter, stringSorter } from 'utils';
import FeedbackDetailButton from './FeedbackDetailsModal';

const FeedbackTable = styled(({ assetId, className }) => {
  const feedback = useSelector(state => state.feedback?.feedbackSummary || {});
  const isLoading = useSelector(state => state.feedback?.status === REQUESTED_DATA_STATUS.PENDING);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetFeedbackSummary(assetId));
    return () => dispatch(clearFeedbackState());
  }, [dispatch, assetId]);

  const columns = [
    {
      title: 'Incident Name',
      dataIndex: 'threat',
      render: threat => <a href={threat?.base_url ?? '#'}>{threat?.name ?? '-'}</a>,
      sorter: (a, b) => stringSorter(a?.threat?.name, b?.threat?.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Impact',
      dataIndex: ['data', 'impacted'],
      render: impacted => (impacted === false ? 'No' : impacted ? 'Yes' : '-'),
      sorter: (a, b) => a?.data.impacted - b?.data?.impacted,
    },
    {
      title: 'Capacity',
      dataIndex: ['data', 'capacity'],
      render: data => data ?? '-',
      sorter: (a, b) => stringSorter(a?.data?.capacity, b?.data?.capacity),
    },
    {
      title: 'Recovery Date',
      dataIndex: ['data', 'recovery_date'],
      render: data => (data ? getFormattedDate(data, 'DD-MM-YYYY') : '-'),
      sorter: (a, b) => dateSorter(a?.data?.recovery_date, b?.data?.recovery_date),
    },
    {
      title: 'Status',
      dataIndex: 'data',
      render: data => (!!Object.keys(data ?? {}).length ? 'Recieved' : 'Requested'),
      sorter: (a, b) => !!Object.keys(a?.data ?? {}).length - !!Object.keys(b?.data ?? {}).length,
    },
    {
      title: 'When Provided',
      dataIndex: 'created_date',
      render: data => (data ? getFormattedDate(data, 'DD-MM-YYYY') : '-'),
      sorter: (a, b) => dateSorter(a?.created_date, b?.created_date),
    },
    {
      title: 'Details',
      dataIndex: 'threat',
      render: threat => <FeedbackDetailButton assetId={assetId} threat={threat} />,
      align: 'center',
    },
  ];

  return (
    <Table
      description="No matching records found"
      table="feedback-summary-table"
      className={className}
      rowKey={row => row?.threat?.id}
      columns={columns}
      dataSource={feedback?.responses || []}
      tableSize="small"
      locale={{ emptyText: <Empty description={isLoading ? 'Loading' : 'No Data'} /> }}
    />
  );
})`
  padding: 0;
  background-color: unset;
`;

export default FeedbackTable;
