import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

export function LoadingSpinner(props) {
  const showLoadingSpinner = useSelector(store => store.operations.isLoading);

  if (!showLoadingSpinner) {
    return null;
  }

  return <Spin size={'medium'} style={props.style} />;
}
