import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patchUserPreference, putUserProfile } from 'modal/userprofile/modules/userprofile';
import { getLoggedInUser } from 'account/modules/account';
import { getUserProfileChannels } from 'modal/userprofile/modules/notifications';
import LoadingScreen from 'layout/containers/LoadingScreen';
import { NotificationContent, ProfileContent } from '@luxe/components/src/Container';
import { useTheme } from 'emotion-theming';
import { urlParam } from 'utils';
import moment from 'moment-timezone';
import GeneralView from './General/General';
import { CHANNEL_ACTIONS, TRANSPORTATION_MODES } from 'common/Constants';
import styled from '@emotion/styled';
import getFromConfig from 'constants/config';
import NotificationsView from './Notifications/Notifications';
import WorkflowContainerView from 'admin/workflows/containers/WorkflowContainerView';
import useMixpanel from 'utils/hooks/Mixpanel';

const UserProfileView = styled(({ ...props }) => {
  const { space } = useTheme();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(store => store.account.account);
  const retrievedUserProfile = useSelector(store => store.account.account?.profile);
  const [userProfileSet, setUserProfileSet] = useState(false);
  const [defaultUserPreference, setDefaultUserPreference] = useState(false);
  const [userChannelsLoaded, setUserChannelsLoaded] = useState(false);
  const userChannels = useSelector(store => store.userNotifications.channels);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [unsubscribeIncident, setUnsubscribeIncident] = useState(false);
  const { mixpanel_event } = urlParam();
  const mixpanelTrack = useMixpanel();
  const [runUpdatePrefs, setRunUpdatePrefs] = useState(false);
  const updateUserStatus = useSelector(store => store.userProfile);

  const path = window.location.pathname.split('/');
  const cancelUrl = getFromConfig('REACT_APP_RISKBOX_BASE_URL');
  const userWorkflowId = loggedInUser?.profile?.workflow;

  const [userProfilePayload, setUserProfilePayload] = useState({
    asset_types: [],
    asset_groups: [],
    job_functions: [],
    countries: [],
    job_role: [],
    corporates: [],
    families: [],
    global_events_categories: [],
    industries: [],
    lane_modes: ['air', 'ocean', 'rail', 'truck'],
    shipment_modes: ['air', 'ocean', 'rail', 'truck'],
  });

  const [userPreferencePayload, setUserPreferencePayload] = useState({
    analytics: {
      privacy_policy_date: '',
      tracking: false,
    },
    units: {
      default: 'imperial',
      length: 'miles',
      temp: 'F',
      timezone: moment.tz.guess(true),
    },
  });

  const updateUserPreference = (type, prefs) => {
    setUserPreferencePayload(existingPayload => ({ ...existingPayload, [type]: prefs }));
  };

  const handleSave = data => {
    setRunUpdatePrefs(true);
    //if this 'save' comes from notifications tab, we will have channel preference and need to pass to endpoint
    //otherwise we have already appropriately updated UP payload and can continue
    if (data.channel) {
      data = { ...userProfilePayload, ...data };
    } else {
      data = userProfilePayload;
    }
    data = { ...data, ...userPreferencePayload };
    dispatch(putUserProfile(data));
  };

  const updateUserProfile = updatedValue => {
    setUserProfilePayload(existingPayload => ({ ...existingPayload, ...updatedValue }));
  };

  if (loggedInUser && !defaultUserPreference) {
    updateUserPreference('units', {
      ...userPreferencePayload.units,
      ...loggedInUser.prefs.units,
    });
    updateUserPreference('analytics', {
      ...userPreferencePayload.analytics,
      ...loggedInUser.prefs.analytics,
    });
    setDefaultUserPreference(true);
  }

  if (retrievedUserProfile && !userProfileSet) {
    setUserProfilePayload({ ...userProfilePayload, ...retrievedUserProfile });
    if (!retrievedUserProfile.lane_modes) {
      setUserProfilePayload(existingPayload => ({
        ...existingPayload,
        lane_modes: [...new Set(TRANSPORTATION_MODES.map(item => item.value))],
      }));
    }
    if (!retrievedUserProfile.lane_modes) {
      setUserProfilePayload(existingPayload => ({
        ...existingPayload,
        shipment_modes: [...new Set(TRANSPORTATION_MODES.map(item => item.value))],
      }));
    }
    if (retrievedUserProfile.industries?.length > 0) {
      if (typeof retrievedUserProfile.industries[0] !== 'number')
        setUserProfilePayload(existingPayload => ({
          ...existingPayload,
          industries: [...new Set(retrievedUserProfile.industries.map(item => item.id))],
        }));
    }
    setUserProfileSet(true);
  }

  if (userChannels && !userChannelsLoaded) {
    const channelTypes = [];
    userChannels.forEach(u => {
      channelTypes.push(u.type);
      setUserProfilePayload(existingPayload => ({
        ...existingPayload,
        [u.type.toLowerCase()]: { frequency: u.frequency, is_realtime: u.is_realtime },
      }));
    });
    const otherChannelTypes = CHANNEL_ACTIONS.filter(e => !channelTypes.includes(e.key));
    otherChannelTypes.forEach(o => {
      setUserProfilePayload(existingPayload => ({
        ...existingPayload,
        [o.value]: { is_realtime: false, frequency: '' },
      }));
    });
    setUserChannelsLoaded(true);
    setLoadingScreen(false);
  }

  useEffect(() => {
    if (mixpanel_event === 'user_notifications' && !unsubscribeIncident) {
      mixpanelTrack(`Real Time Email - Click Unsubscribe`);
      setUnsubscribeIncident(true);
    }
  }, [mixpanel_event, unsubscribeIncident, mixpanelTrack]);

  useEffect(() => {
    if (!userChannels) dispatch(getUserProfileChannels());
    if (!loggedInUser) dispatch(getLoggedInUser());
  }, [userChannels, loggedInUser, dispatch]);

  useEffect(() => {
    if (updateUserStatus.putProfileStatus === 'SUCCESS' && runUpdatePrefs) {
      dispatch(patchUserPreference({ analytics: userPreferencePayload.analytics }));
      setRunUpdatePrefs(false);
    }
  }, [updateUserStatus.putProfileStatus, userPreferencePayload.analytics, runUpdatePrefs, dispatch]);

  const selectedScreen = () => {
    return (
      <>
        {path[2] === 'profile' && (
          <ProfileContent style={{ margin: `${space.none} ${space.small}px` }}>
            <GeneralView
              user={loggedInUser}
              userProfile={userProfilePayload}
              userPreference={userPreferencePayload}
              updateProfile={updateUserProfile}
              updatePreference={updateUserPreference}
              cancelUrl={cancelUrl}
              onSave={handleSave}
            />
          </ProfileContent>
        )}
        {path[2] === 'notifications' && (
          <NotificationContent style={{ margin: `${space.none} ${space.small}px` }}>
            <NotificationsView
              userProfile={userProfilePayload}
              updateProfile={updateUserProfile}
              cancelUrl={cancelUrl}
              onSave={handleSave}
              userWorkflowId={userWorkflowId}
            />
          </NotificationContent>
        )}
        {path[2] === 'network' && <WorkflowContainerView userWorkflowId={userWorkflowId} />}
      </>
    );
  };
  return loadingScreen ? <LoadingScreen /> : selectedScreen();
})``;

export default UserProfileView;
