import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FundOutlined } from '@ant-design/icons';

export default class ScoreCardFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    if (this.props.value) {
      return (
        <Link to={`/assets/${this.props.value.id}`}>
          <FundOutlined />
        </Link>
      );
    }

    return '';
  }
}
