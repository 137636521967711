import React, { useState } from 'react';
import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Checkbox as AntdCheckbox, Input, InputNumber, Radio } from 'antd';
import RSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

const Checkbox = styled(AntdCheckbox)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  width: 14px;
  height: 14px;
  color: white;

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} ;
    background-color: ${props => props.theme.colors.primary} ;
  }

  input[type='checkbox'] + span {
    border: ${props => `1px solid ${props.theme.colors.BG5}`};
    background-color: ${props => props.theme.colors.background};
    &:focus {
      border-color: white ;
    }
    &:disabled {
      background-color: ${props => props.theme.colors.disabled};
      border-color: ${props => props.theme.colors.disabled};
      color: rgba(255, 255, 255, 0.45);
    }
  }
`;

const CheckboxGroup = styled(AntdCheckbox.Group)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  .ant-checkbox:disabled {
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    color: rgba(255, 255, 255, 0.45);
  }

  .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.disabled};

    &:hover {
      border-color: ${props => props.theme.colors.disabled};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.disabled};
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.primary} !important;
  }
`;

const RadioGroup = styled(Radio.Group)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  width: 14px;
  height: 14px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    color: rgba(255, 255, 255, 0.45);
  }

  .ant-radio-inner {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.disabled};

    &:disabled {
      background-color: ${props => props.theme.colors.disabled};
    }
  }

  .ant-radio-wrapper {
    color: white;
  }
`;

const TextArea = styled(Input.TextArea)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background};
  border-color: ${props => `1px solid ${props.theme.colors.primary}`};
  color: rgba(255, 255, 255, 0.75);

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    color: rgba(255, 255, 255, 0.45);
  }
`;

const Numeric = styled(InputNumber)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  width: ${props => props.width || '100px'};
  height: ${props => props.height || '35px'};
  display: flex;
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid ${props => props.theme.colors.BG5};
  background: ${props => props.theme.colors.black1};

  .ant-input-number-input-wrap input {
    background-color: ${props => props.theme.colors.black1};
  }

  &:focus, &:invalid, &:hover {
    border-color: ${props => props.theme.colors.primary} !important;
  }

  &:disabled {
    .ant-input-number-input-wrap {
      background: ${props => props.theme.colors.disabled};
      border-color: ${props => props.theme.colors.disabled};
      color: rgba(255, 255, 255, 0.45);
    }
  }

  span.ant-input-number-handler {
    background: ${props => props.theme.colors.disabled};
  }`;

const SearchInput = styled(Input.Search)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  background-color: ${props => props.theme.colors.background};
  color: rgba(255, 255, 255, 0.75);
  font-family: ${props => props.theme.fonts.body};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: 20%;
  min-width: 250px;
  visibility:${props => props.display};

  &:focus {
    border-color: ${props => props.theme.colors.primary};
    color: rgba(255, 255, 255, 0.75);
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }

  .ant-input {
    background-color: ${props => props.theme.colors.background};
    color: rgba(255, 255, 255, 0.75);
    border-color: ${props => props.theme.colors.primary};
    height: 100% !important;
  }

  .ant-input-group {
    height: 100%;
  }

  .ant-btn {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.primary};
    border-left: 0;
    height: 100%;

    .anticon-search{
      color: rgba(255, 255, 255, 0.75) !important;
    }
  }

  .anticon {
    color: ${props => props.theme.colors.background};

    .anticon-search {
      color: rgba(255, 255, 255, 0.75);
      fill: black;
    }

    .ant-input-search-icon {
      fill: black;

    }

    &:disabled {
      background-color: ${props => props.theme.colors.disabled};
      border-color: ${props => props.theme.colors.disabled};
      color: rgba(255, 255, 255, 0.45);
    }
  }
`;

const SelectStyle = Child => styled(Child)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  min-height: ${props => props.height || '32px'};
  width: ${props => props.width || '416px'};

  div.Select-input {
    height: 32px;
    width: 416px;
  }

  &&& [class*="-control"] {
    background-color: ${props => props.theme.colors.black1};
    border-color: ${props => (props.disabled ? props.theme.colors.disabled : props.theme.colors.BG5)};
    color: ${props => (props.isDisabled ? props.theme.colors.disabled : props.theme.colors.white)};
    height: 32px;
    border-radius: 4px;
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'default')};

    div.Select-placeholder {
      color: rgba(255, 255, 255, 0.65);
      text-align: left;
      font-family: ${props => props.theme.fonts.body};
    }

    span.Select-arrow-zone {
      background-color: ${props => props.theme.colors.background} !important;
      color: ${props => props.theme.colors.primary} !important;
      fill: ${props => props.theme.colors.primary} !important;
    }

    &:hover, &:active, &:focus {
      border-color: ${props => props.theme.colors.primary} !important;
    }
  }

  input[type=text] {
    height: auto;
    color: ${props => props.theme.colors.white} !important;
  }

  div.Select-input {
    width: 416px !important;
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.primary} !important;

    input {
      height: 32px;
      width: 416px;
      border: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.background};
      border-radius: 4px;

      &:hover, &:active, &:focus {
        border-color: ${props => props.theme.colors.primary} !important;
      }
    }
  }

  div.Select-menu-outer {
    background-color: ${props => props.theme.colors.background} !important;
    color: ${props => props.theme.colors.primary} !important;

    &:hover {
      color: ${props => props.theme.colors.primary} !important;
    }

    div.Select-menu {
      color: ${props => props.theme.colors.primary} !important;

      div.Select-option {
        color: rgba(255, 255, 255, 0.65);
        background-color: ${props => props.theme.colors.background} !important;
        text-align: left;

        &:hover {
          background-color: ${props => props.theme.colors.primary} !important;
          color: white !important;
        }
      }
    }
  }

  [class$='-singleValue'] {
    color: ${props => (props.isDisabled ? 'rgba(255, 255, 255, 0.45)' : 'rgba(255, 255, 255, 0.75)')};
  }

  [class$='-multiValue'],
  [class$='-multiValue'] div {
    background-color: ${props => props.theme.colors.secondary};
    color: #ffffff;
  }
  [class$='-control'],
  [class$='-menu'] {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    height: auto !important;
    width: 100%;
  }
  [class$='-menu'] {
    z-index: 101;
  }
  input[id^='react-select-'] {
    margin: 0;
  }
  [class$='-MenuList'] {
    background-color: #141414;
  }
  [class$='-option'] {
    background-color: #141414;
  }
  [class$='-option']:hover {
    background-color: #939393;
  }
`;

const Select = SelectStyle(
  React.forwardRef(({ menuPlacement, styles, isMulti, ...props }, ref) => {
    const [searchInput, SetSearchInput] = useState('');
    const searchSelectOptions = {};
    if (isMulti) {
      searchSelectOptions.inputValue = searchInput;
      searchSelectOptions.closeMenuOnSelect = false;
      searchSelectOptions.onInputChange = (input, action) =>
        ['input-change', 'menu-close'].includes(action.action) && SetSearchInput(input);
    }
    return (
      <RSelect
        ref={ref}
        {...{
          ...searchSelectOptions,
          ...props,
          isMulti: isMulti ?? false,
          menuPlacement: menuPlacement ? menuPlacement : 'auto',
          styles: styles
            ? { input: styles => ({ ...styles, color: 'white' }), ...styles }
            : { input: styles => ({ ...styles, color: 'white' }) },
        }}
      />
    );
  }),
);

const CreatableSelect = styled(CreatableReactSelect)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  min-height: 32px;
  min-width: 416px;

  &&& [class*="-control"] {
    background-color: ${props => props.theme.colors.black1};
    border-color: ${props => props.theme.colors.BG5};
    color: ${props => props.theme.colors.white};
    min-height: 32px;
    min-width: 416px;
    border-radius: 4px;

    div.Select-placeholder {
      color: rgba(255, 255, 255, 0.65);
      text-align: left;
      font-family: ${props => props.theme.fonts.body};
    }

    span.Select-arrow-zone {
      background-color: ${props => props.theme.colors.background} !important;
      color: ${props => props.theme.colors.primary} !important;
      fill: ${props => props.theme.colors.primary} !important;
    }

    &:hover, &:active, &:focus {
      border-color: ${props => props.theme.colors.primary} !important;
    }
  }

  div.Select-input {
    background-color: ${props => props.theme.colors.background} !important;
    border-color: ${props => props.theme.colors.primary} !important;
    min-height: 32px;
    min-width: 416px;

    input {
      min-height: 32px;
      min-width: 416px;
      border: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.background} !important;
      border-radius: 4px;

      &:hover, &:active, &:focus {
        border-color: ${props => props.theme.colors.primary} !important;
      }
    }
  }

  div.Select-menu-outer {
    background-color: ${props => props.theme.colors.background} !important;
    color: ${props => props.theme.colors.primary} !important;

    &:hover {
      color: ${props => props.theme.colors.primary} !important;
    }

    div.Select-menu {
      color: ${props => props.theme.colors.primary} !important;

      div.Select-option {
        color: rgba(255, 255, 255, 0.65);
        background-color: ${props => props.theme.colors.background} !important;
        text-align: left;

        &:hover {
          background-color: ${props => props.theme.colors.primary} !important;
          color: white !important;
        }
      }
    }
  }
  [class$='-singleValue'] {
    color: rgba(255, 255, 255, 0.75);
  }
  [class$='-multiValue'],
  [class$='-multiValue'] div {
    background-color: ${props => props.theme.colors.secondary};
    color: #ffffff;
  }
  [class$='-control'],
  [class$='-menu'] {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    height: auto !important;
    margin: 5px 0;
    width: 100%;
  }
  input[id^='react-select-'] {
    margin: 0;
  }
  [class$='-MenuList'] {
    background-color: #141414;
  }
  [class$='-option'] {
    background-color: #141414;
  }
  [class$='-option']:hover {
    background-color: #939393;
  }
`;

const Label = styled(Input)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};
  color: rgba(255, 255, 255, 0.75);
  border: ${props => `1px solid ${props.theme.colors.BG5}`};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => (props.size === 'large' ? '16px' : '14px')} ;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: ${props => props.width || '416px'};

  input.ant-input {
    background-color: transparent !important;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.black1};
    color: rgba(255, 255, 255, 0.65);
    border: ${props => `1px solid ${props.theme.colors.BG5}`};
    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &:hover {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`;

const Password = styled(Input.Password)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  background-color: ${props => props.theme.colors.black1};
  color: rgba(255, 255, 255, 0.75);
  border: ${props => `1px solid ${props.theme.colors.BG5}`};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.body};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  height: 36px;

  input[type="text"] {
    height: 25px; 
  }

  .ant-input {
    background-color: ${props => props.theme.colors.black1};
    color: rgba(255, 255, 255, 0.75);
  }

  .ant-input-password {
    background-color: ${props => props.theme.colors.black1};
    color: rgba(255, 255, 255, 0.75);
  }

  .ant-input-password-icon {
    color: white;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    color: rgba(255, 255, 255, 0.45);
  }
`;

export {
  Checkbox,
  TextArea,
  Numeric,
  SearchInput,
  Label,
  Select,
  SelectStyle,
  CreatableSelect,
  Password,
  RadioGroup,
  CheckboxGroup,
};
