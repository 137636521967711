import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Upload, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateImage,
  updateLogo,
  getAccountOrg,
  deleteLogo,
  clearImageDataAction,
  updateAccount,
} from 'account/modules/accountOrg';
import { BasicModal as Modal } from '@luxe/components';
import useMixpanel from 'utils/hooks/Mixpanel';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';

const { Dragger } = Upload;

const { Text } = Typography;

const LogoUpload = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { colors, fontSizes } = useTheme();
  const { imageUpdate } = useSelector(store => store.accountOrg)?.accountOrg;
  const { logo } = useSelector(store => store.accountOrg)?.accountOrg;
  const logoUpdate = useSelector(store => store.accountOrg)?.logoUpdate;
  const clearImageData = useSelector(store => store.accountOrg)?.clearImageData;
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [userUploadImage, setUserUploadImage] = useState(true);
  const [visible, setVisible] = useState(false);
  const [enterNext, setEnterNext] = useState(false);

  useEffect(() => {
    if (!selectedImage && logo) {
      setSelectedImageUrl(logo);
      setUserUploadImage(false);
    }
  }, [logo, selectedImage]);

  useEffect(() => {
    if (logoUpdate && enterNext) {
      setEnterNext(false);
      setSelectedImage();
      setSelectedImageUrl();
      dispatch(updateImage(false));
      dispatch(getAccountOrg());
      mixpanelTrack('Admin - General - Uploaded Logo');
    }
  }, [logoUpdate, enterNext, dispatch, mixpanelTrack]);

  useEffect(() => {
    if (clearImageData && selectedImage) {
      dispatch(updateImage(false));
      setSelectedImage(null);
      setUserUploadImage(true);
      if (logo) {
        setSelectedImageUrl(logo);
      } else {
        setSelectedImageUrl(null);
      }
      dispatch(clearImageDataAction(false));
    }
  }, [clearImageData, dispatch, logo, selectedImage]);

  useEffect(() => {
    if (imageUpdate && selectedImage) {
      const formData = new FormData();
      formData.append('logo', selectedImage);
      setEnterNext(true);
      dispatch(updateLogo(formData));
    } else {
      dispatch(updateImage(false));
    }
  }, [imageUpdate, dispatch, selectedImage]);

  const uploadProps = {
    name: 'logo',
    multiple: false,
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/svg';
      if (!isJpgOrPng) {
        dispatch({
          type: OPERATION_TYPES.SHOW_NOTIFICATION,
          payload: {
            content: 'You can only upload JPG/PNG/SVG file!',
            type: 'error',
          },
        });
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        dispatch({
          type: OPERATION_TYPES.SHOW_NOTIFICATION,
          payload: {
            content: 'Image must smaller than 1MB!',
            type: 'error',
          },
        });
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function() {
          var height = this.height;
          var width = this.width;
          if (height > 60 || width > 200) {
            dispatch({
              type: OPERATION_TYPES.SHOW_NOTIFICATION,
              payload: {
                content: 'Height and Width must not exceed 60px and 200px respectively!',
                type: 'error',
              },
            });
            return false;
          } else {
            if (isJpgOrPng && isLt1M) {
              dispatch(clearImageDataAction(false));
              setSelectedImage(file);
              setSelectedImageUrl(URL.createObjectURL(file));
              setUserUploadImage(true);
              props.updatedImage(true);
              return false;
            } else {
              return false;
            }
          }
        };
      };
      return false;
    },
    showUploadList: false,
  };

  return (
    <div className={props.className}>
      <div className={`logo-control`}>
        <h3 className="company-logo-text">
          {`Upload your company's logo`}
          <Tooltip title="The recommended size for the image is 160px wide and 40px high. The limits for a logo are are 60 pixels high by 200 pixels wide with a file size smaller than a megabyte.">
            <InfoCircleOutlined />
          </Tooltip>
        </h3>
        <h4>This logo will be displayed on email templates and within the platform.</h4>
        <div className={`${props.className}`}>
          <Dragger {...uploadProps} className="logo-drag-upload">
            {selectedImageUrl ? (
              <div className="logo-container">
                <img
                  src={selectedImageUrl}
                  alt="logo"
                  style={{
                    width: '100%',
                    marginRight: '10px',
                  }}
                />
                {!userUploadImage && (
                  <CloseCircleOutlined
                    className="close"
                    onClick={e => {
                      e.stopPropagation();
                      setVisible(true);
                    }}
                  />
                )}
              </div>
            ) : (
              <p className="ant-upload-text">Place Image</p>
            )}
          </Dragger>
        </div>
      </div>
      {visible ? (
        <Modal
          visible={visible}
          title="Delete Logo?"
          okText="Yes"
          cancelText="No"
          onCancel={() => setVisible(!visible)}
          onOk={() => {
            dispatch(deleteLogo(true));
            dispatch(updateAccount({ logo: null }));
            setVisible(false);
            setSelectedImage(null);
            setSelectedImageUrl(null);
            setUserUploadImage(true);
          }}
        >
          <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
            Are you sure you want to remove the logo?
          </Text>
        </Modal>
      ) : null}
    </div>
  );
})`
  .logo-control {
    padding: 14px;
    h3,
    h4 {
      color: white;
      font-weight: normal;
    }
    padding: 24px;
    .company-logo-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo-drag-upload {
      background: #212121 !important;
      padding: 25px;
      border: 1px dashed white !important;
      border-radius: 5px;
      .ant-upload-text {
        color: white !important;
      }
    }
    .ant-upload-drag-container {
      display: inline-block;
      position: relative;
    }
    .logo-container {
      display: flex;
      align-items: center;
    }
  }
`;
export default LogoUpload;
