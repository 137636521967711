import React, { useEffect, useState } from 'react';
import { stringSorter } from 'utils';
import { Table } from '@luxe/components';
import { ExportOutlined, ImportOutlined, FundOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isDiscovered, valColor } from 'discover/modules/graphUtilities';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { focusNode } from 'discover/modules/viz';

const AssetDiscoverTable = ({ nodes, links, toggleSubTier }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableNodes, setTableNodes] = useState([]);
  const [assetIds, setAssetIds] = useState([]);
  const account = useSelector(store => store.account);
  const { discover_assets } = useSelector(store => store.assets);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatedNodes = [];
    nodes.forEach(n => {
      if (n.tier_level.length > 1) {
        n.tier_level.forEach(t => {
          let holder = { ...n };
          holder.tier_level = [t];
          holder.unique = parseFloat(holder.id.toString() + '.' + t.toString());
          updatedNodes.push(holder);
        });
      } else {
        updatedNodes.push({ ...n, unique: n.id });
      }
    });
    setTableNodes(updatedNodes);
  }, [nodes]);

  useEffect(() => {
    const temp = {};
    (discover_assets || []).forEach(asset => {
      temp[asset.asset.name] = asset.asset.id;
    });
    setAssetIds(temp);
  }, [discover_assets]);

  useEffect(() => {
    const toolTipStyling = {
      border: '4px solid #474747',
      backgroundColor: '#2e2e2e',
      fontSize: '14px',
      color: '#ffffff',
    };
    const previousLocations = selectedNode => {
      const listOfLinks = links.filter(link => selectedNode.id === link.target);
      const listOfNodes = listOfLinks
        .map(linkFound =>
          tableNodes.find(
            node =>
              node.id === linkFound.source && selectedNode.tier_level?.sort()[0] < node.tier_level?.sort()?.at(-1),
          ),
        )
        ?.filter(node => {
          return node?.id;
        });
      return {
        nodes: listOfNodes[0] ? [...new Set(listOfNodes)] : [],
        links: listOfLinks.length ? [...new Set(listOfLinks)] : [],
      };
    };

    const nextLocations = selectedNode => {
      const listOfLinks = links.filter(link => selectedNode.id === link.source);
      const listOfNodes = listOfLinks
        .map(linkFound =>
          tableNodes.find(
            node =>
              node.id === linkFound.target && selectedNode.tier_level?.sort()?.at(-1) > node.tier_level?.sort()[0],
          ),
        )
        ?.filter(node => {
          return node?.id;
        });
      return {
        nodes: listOfNodes[0] ? [...new Set(listOfNodes)] : [],
        links: listOfLinks.length ? [...new Set(listOfLinks)] : [],
      };
    };

    const determineFlow = n => {
      const flow = {
        inBound: previousLocations(n).nodes.length > 0,
        outBound: nextLocations(n).nodes.length > 0,
      };
      return (
        <>
          <span>
            {flow.inBound && (
              <span>
                <ImportOutlined />
              </span>
            )}
            {flow.outBound && (
              <span>
                <ExportOutlined />
              </span>
            )}
          </span>
        </>
      );
    };
    const determineDiscover = n => {
      const dot = (
        <Tooltip title="Discovered" overlayInnerStyle={toolTipStyling}>
          <span
            className="dot"
            style={{
              height: '10px',
              width: '10px',
              backgroundColor: '#2D8FA5',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>
        </Tooltip>
      );
      return <span>{isDiscovered(n, account.id) ? dot : ''}</span>;
    };

    if (tableNodes.length > 0 && links.length > 0) {
      setDataSource(
        tableNodes.map(n => {
          return {
            unique: n?.unique || n.id,
            id: n.id,
            flow: determineFlow(n),
            facility: n.name,
            discovered: determineDiscover(n),
            incident_risk: n.reactive__max_score,
            proactive_risk: n.proactive_scores__overall_score,
            city: n.city,
            state_province: n.state_province,
            country: n.country,
            tier: n.tier_level,
            drilldown: assetIds[n.name ?? ''] ?? null,
            prev: previousLocations(n),
            next: nextLocations(n),
            selectedNode: n,
          };
        }),
      );
    }
  }, [tableNodes, links, account.id, assetIds]);

  const columns = [
    {
      title: '',
      dataIndex: 'discovered',
      render: nde => nde,
      width: 25,
    },
    {
      title: 'Facility',
      dataIndex: 'facility',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.facility, b.facility),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Tier',
      dataIndex: 'tier',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.tier, b.tier),
      width: 125,
      align: 'center',
    },
    {
      title: 'Incident Risk',
      dataIndex: 'incident_risk',
      render: nde => {
        return (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'White',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde || '-'}
          </span>
        );
      },
      sorter: (a, b) => a.incident_risk - b.incident_risk,
      width: 125,
      align: 'center',
    },
    {
      title: 'Strategic Risk',
      dataIndex: 'proactive_risk',
      render: nde => {
        return (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'White',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde || '-'}
          </span>
        );
      },
      sorter: (a, b) => a.proactive_risk - b.proactive_risk,
      width: 125,
      align: 'center',
    },
    {
      title: 'City',
      dataIndex: 'city',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.city, b.city),
    },
    {
      title: 'State/Province',
      dataIndex: 'state_province',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.state_province, b.state_province),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.country, b.country),
      align: 'center',
    },
    {
      title: 'Inbound Locations',
      dataIndex: 'prev',
      render: (nde, Row) => {
        const list = {
          nodes: nde.nodes,
          links: nde.links,
        };
        return nde.nodes.length > 0 ? (
          <span
            onMouseUp={() => toggleSubTier(list, 'Previous', Row.selectedNode)}
            style={{ color: '#2d8fa5', cursor: 'pointer' }}
          >
            {nde.nodes.length}
          </span>
        ) : (
          <span>{nde.nodes.length}</span>
        );
      },
      sorter: (a, b) => {
        return a.prev.nodes.length - b.prev.nodes.length;
      },
      width: 125,
      align: 'center',
    },
    {
      title: 'Outbound Locations',
      dataIndex: 'next',
      render: (nde, Row) => {
        const list = {
          nodes: nde.nodes,
          links: nde.links,
        };
        return nde.nodes.length > 0 ? (
          <span
            onMouseUp={() => toggleSubTier(list, 'Next', Row.selectedNode)}
            style={{ color: '#2d8fa5', cursor: 'pointer' }}
          >
            {nde.nodes.length}
          </span>
        ) : (
          <span>{nde.nodes.length}</span>
        );
      },
      sorter: (a, b) => a.next.nodes.length - b.next.nodes.length,
      width: 125,
      align: 'center',
    },
    {
      title: 'Action',
      dataIndex: 'drilldown',
      render: nde =>
        nde ? (
          <span>
            {nde && (
              <Link to={`/assets/${nde}`} target="_blank">
                <FundOutlined />
              </Link>
            )}
          </span>
        ) : null,
      sorter: (a, b) => stringSorter(a.drilldown, b.drilldown),
      width: 125,
      align: 'center',
    },
  ];

  return (
    <Table
      rowKey="unique"
      columns={columns}
      dataSource={dataSource}
      size="small"
      style={{
        width: '100%',
        margin: '0 auto',
        zIndex: '0',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#212121',
        userSelect: 'none',
      }}
      onRow={record => {
        return {
          onClick: () => {
            dispatch(focusNode(record.id));
          },
        };
      }}
    />
  );
};

export default AssetDiscoverTable;
