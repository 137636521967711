import styled from '@emotion/styled';


const Logo = styled.div`
  display: flex;
  margin-right: auto;
  align-self: flex-start;
  img {
    max-width: 140px;
    height: 28px;
    margin: ${({ theme }) => theme.space.small}px;
  }
  background-repeat: no-repeat;
  background-position: center center;
`;

export { Logo };
