import AutocompleteBaseSelector from './BaseSelector';

export default class LaneSelector extends AutocompleteBaseSelector {
  onAutocomplete = async (filterName, value) => {
    await this.props.onAutocomplete(filterName, value);
    return this.props.options['lanes'].map(item => ({ label: item.name, value: item.id }));
  };

  onChange = e => {
    this.props.updateViewMetaFilters(this.props.view, {
      value: e.value,
      operator: '==',
      name: this.props.label,
    });
  };

  get options() {
    return this.props.options['lanes'] ? this.props.options['lanes'] : null;
  }
}
