import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Landingpage from './Landingpage';
import { getUnsubscribeIncidentDetail } from '../../../assets/modules/incidents';
import styled from '@emotion/styled';

const UnsubscribeLandingView = styled(({ ...props }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const incidents = useSelector(store => store.incidents);
  const incidentDetail = incidents?.incidentDetail || {};

  const threatId = params.threat_id;
  const hashId = params.hash_id;

  useEffect(() => {
    if (threatId) {
      dispatch(getUnsubscribeIncidentDetail(threatId, hashId));
    }
  }, [dispatch, threatId, hashId]);

  return (
    <div className={`${props.className}`}>
      <div className="unsubscribe-confirm">
        <Landingpage incident={incidentDetail.properties} threatId={threatId} hashId={hashId} />
      </div>
    </div>
  );
})`
  height: 100%;
  .unsubscribe-confirm {
    text-align: center;
    margin-top: 5%;
  }
  .unsubscribe-button {
    margin-top: 3%;
  }
`;

export default UnsubscribeLandingView;
