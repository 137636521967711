import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Container, ListViewContent } from '@luxe/components/src/Container';

import { ViewTable } from 'views/components';

import { Col, Row } from 'antd';
import { deleteBulkLanes } from 'admin/lanes/modules/lanes';
import { useTheme } from 'emotion-theming';
import { useHistory } from 'react-router-dom';

import {
  getViewMeta,
  getViewData,
  addSelectedRows,
  removeSelectedRow,
  selectAll,
  deselectAll,
  selectAllVisible,
  clearSelection,
} from 'views/modules/views';

import { getParams } from 'views/utils';
import { SearchInput } from '@luxe/components';
import _ from 'lodash';

const LaneListView = ({
  lanes,
  deleteBulkLanes,
  riskProfiles,
  getViewMeta,
  getViewData,
  selectAll,
  deselectAll,
  selectAllVisible,
  addSelectedRows,
  removeSelectedRow,
  selectedRows,
  views,
  push,
  router,
  clearSelection,
}) => {
  const { space } = useTheme();
  const { search: url } = router.location;
  const firstLoad = useRef(true);
  const history = useHistory();

  const data = views.lanes;

  const getQuery = useCallback(
    () => ({
      columns: ['lane', 'risk'],
      order_by: [{ name: 'lane__name', direction: 'desc' }],
      ...getParams(url),
      limit: 10,
      url,
    }),
    [url],
  );

  const searchTermChange = _.debounce(value => {
    const searchParams = new URLSearchParams(url);
    const offset = searchParams.get('offset');
    if (value) {
      searchParams.set('query', value);
    } else {
      searchParams.delete('query');
    }
    if (offset) {
      searchParams.set('offset', 0);
    }
    history.replace({ search: searchParams.toString() });
  }, 350);

  useEffect(() => {
    const fetchData = () => {
      let query = getQuery();
      getViewData('lanes', query);
    };
    if (firstLoad.current) {
      firstLoad.current = false;
      getViewMeta('lanes', undefined, undefined, fetchData);
    } else {
      getViewData('lanes', getQuery());
    }
  }, [getViewMeta, url, getViewData, firstLoad, getQuery]);

  useEffect(
    () => () => {
      clearSelection('lanes');
    },
    [clearSelection],
  );

  const onSelect = (record, selected, selectedRow, event) => {
    if (!selected) {
      removeSelectedRow('lanes', record.lane.id);
    } else {
      addSelectedRows('lanes', record.lane.id);
    }
  };

  const onSelectAll = (selected, selectedRows, changeRows) => {
    if (!selected) {
      deselectAll('lanes');
    } else {
      selectAllVisible({ datasource: 'lanes', selectedRows: views.lanes.items.map(x => x.lane.id) });
    }
  };

  const selectAllLanes = () => selectAll('lanes', { ...data.meta.current }, getRowId);

  const getRowId = row => row.lane.id;
  const rowSelection =
    views.lanes && !views.lanes.pending
      ? {
          selectedRowKeys: views.lanes ? (views.lanes.selectedRows ? views.lanes.selectedRows : []) : [],
          onSelect: onSelect,
          onSelectAll: onSelectAll,
        }
      : null;

  const actions = [
    {
      title: 'Delete',
      action: selectedIds => {
        deleteBulkLanes(selectedIds);
      },
    },
  ];

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view lanes-list">
      <Row gutter={[4, 8]}>
        <Col span={24} align="start">
          <h1>Lanes</h1>
        </Col>
        <Col span={24} align="end">
          <SearchInput
            className="search-input"
            key={`lanes-search`}
            placeholder={''}
            onChange={e => searchTermChange(e.target.value)}
          />
        </Col>
      </Row>
      <Container>
        <ViewTable
          title="Lanes"
          actions={actions}
          data={data}
          push={push}
          rowSelection={rowSelection}
          rowKey={getRowId}
          selectAll={selectAllLanes}
          includes={['risk__category', 'origin__country_id', 'destination__country_id', 'profile']}
          allowFiltering={false}
        />
      </Container>
    </ListViewContent>
  );
};

function mapStateToProps({ lanes, riskProfiles, selectedRows, router, views }) {
  return {
    lanes,
    riskProfiles,
    selectedRows,
    router,
    views,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addSelectedRows,
      removeSelectedRow,
      selectAllVisible,
      deleteBulkLanes,
      selectAll,
      deselectAll,
      getViewData,
      getViewMeta,
      push,
      clearSelection,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LaneListView);
