import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spin, Layout, Row, Col, Divider, Radio, Tooltip } from 'antd';
import { BasicModal as Modal, Button, FormItem } from '@luxe/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTheme } from 'emotion-theming';
import { Label as Input } from '@luxe/components';
import { Select } from '@luxe/components';

import {
  deleteRiskProfiles,
  saveRiskProfiles,
  getRiskProfileDetail,
  updateRiskProfile,
  cloneRiskProfiles,
  trackRiskProfileChange,
} from 'admin/riskprofiles/modules/riskprofiles';

import RuleSection from '../RuleSection';
import { DetailViewContent } from '@luxe/components/src/Container';
import { useParams } from 'react-router-dom';
import { generateSelectOptions } from 'admin/utils';

import './index.css';

const RiskProfileDetailView = () => {
  const { id } = useParams();
  const { space } = useTheme();
  const dispatch = useDispatch();

  const RiskProfile = useSelector(store => store.riskProfiles.RiskProfile);
  const profiles = useSelector(store => store.riskProfiles.riskProfiles);

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [parentOptions, setParentOptions] = useState([]);

  const profileSelect = [
    { label: 'Profile Based On', value: false },
    { label: 'Profile Cloned From', value: true },
  ];

  useEffect(() => {
    dispatch(getRiskProfileDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (profiles.length > 0) {
      const createParentOptions = () => {
        const systemProfiles = profiles.reduce((acc, riskProfile) => {
          if (!riskProfile.parent) {
            acc.push(generateSelectOptions(riskProfile.id, riskProfile.id, riskProfile.name));
          }
          return acc;
        }, []);
        return [generateSelectOptions(null, null, '--- None ---', true), ...systemProfiles];
      };
      setParentOptions(createParentOptions());
    }
  }, [profiles]);

  const updateProfile = data => {
    dispatch(trackRiskProfileChange(true));
    dispatch(updateRiskProfile(data));
  };

  const saveRiskProfileHandler = ({ isClone }) => {
    if (isClone) {
      dispatch(cloneRiskProfiles(RiskProfile));
      return;
    }
    dispatch(saveRiskProfiles(RiskProfile));
  };

  const selectedParent = useMemo(
    () => parentOptions.find(option => option.value === RiskProfile?.parent_id) || parentOptions[0],
    [parentOptions, RiskProfile],
  );

  const isReadOnly = RiskProfile?.system_wide;

  return RiskProfile ? (
    <>
      <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
        <Row justify="space-between">
          <Form
            name="edit_risk_profile"
            initialValues={{
              name: RiskProfile?.name,
              parent: RiskProfile?.parent_id,
            }}
            onFinish={saveRiskProfileHandler}
          >
            <FormItem
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please enter a profile name' },
                { max: 100, message: 'Max. 100 characters' },
              ]}
            >
              <Input
                readOnly={isReadOnly}
                style={{ fontSize: '14px', padding: 8 }}
                onChange={e => updateProfile({ name: e.target.value })}
              />
            </FormItem>
            {RiskProfile?.rules.length === 0 && (
              <>
                <Row style={{ alignItems: 'center' }}>
                  <FormItem name="isClone">
                    <Radio.Group
                      style={{ fontSize: '14px', padding: 8 }}
                      options={profileSelect}
                      defaultValue={false}
                      optionType="default"
                      buttonStyle="outline"
                    />
                  </FormItem>
                  <Tooltip title='"Based on" Profile rules are not editable. "Cloned from" Profile rules are editable.'>
                    <InfoCircleOutlined style={{ color: 'white', marginBottom: '24px' }} />
                  </Tooltip>
                </Row>
                <FormItem
                  name="parent"
                  label="Profile Selection"
                  rules={[
                    {
                      required: false,
                      message: 'Select a parent risk profile',
                    },
                  ]}
                >
                  <>
                    <Select
                      options={parentOptions}
                      onChange={e => {
                        dispatch(trackRiskProfileChange(true));
                        updateProfile({
                          parent_id: e.value,
                        });
                      }}
                      value={selectedParent}
                      styles={{
                        input: styles => ({
                          ...styles,
                          color: 'white',
                        }),
                      }}
                    />
                  </>
                </FormItem>
              </>
            )}
          </Form>
          <Col>
            <Button
              disabled={isReadOnly}
              variant={isReadOnly ? 'disabled' : 'danger'}
              danger={true}
              onClick={() => {
                setIsModalShowing(true);
              }}
            >
              Delete
            </Button>
            <Button
              disabled={isReadOnly}
              variant={isReadOnly ? 'disabled' : 'primary'}
              htmlType="submit"
              form="edit_risk_profile"
            >
              Save
            </Button>
          </Col>
        </Row>
        <Divider />
        <RuleSection isReadOnly={isReadOnly} />
      </DetailViewContent>
      <Modal
        title="Are you sure you want to delete this Risk Profile?"
        visible={isModalShowing}
        onCancel={() => {
          setIsModalShowing(false);
        }}
        onOk={() => {
          setIsModalShowing(false);
          dispatch(deleteRiskProfiles(RiskProfile?.id));
        }}
      >
        <p>This operation cannot be undone!</p>
      </Modal>
    </>
  ) : (
    <Layout>
      <Spin size={'large'} />
    </Layout>
  );
};

export default RiskProfileDetailView;
