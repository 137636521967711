export const REQUESTED_DATA_STATUS = {
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const RULE_TYPES = {
  TIME_SERIES_ABSOLUTE: 1,
  SPATIAL_INTERSECT: 2,
  DISTANCE_BASED: 3,
  DURATION_BASED: 6,
  TIME_SERIES_WINDOW: 7,
  CUSTOM_ATTRIBUTE: 10,
};

export const MODAL_TYPES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};
