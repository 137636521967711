import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BasicModal } from '@luxe/components';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { deleteNetworkGraphView, hideDeleteModal } from 'discover/modules/viz';

const { Text } = Typography;

const DeleteModal = styled(() => {
  const { colors, fontSizes } = useTheme();
  const { selectedView } = useSelector(store => store.networkGraph);
  const dispatch = useDispatch();

  return (
    <BasicModal
      visible={true}
      closable={true}
      title={`Delete ${selectedView.name}?`}
      okText="Delete"
      onOk={() => {
        dispatch(deleteNetworkGraphView(selectedView.id));
        dispatch(hideDeleteModal());
      }}
      onCancel={() => {
        dispatch(hideDeleteModal());
      }}
      destroyOnClose={true}
      style={{ backgroundColor: '#212121', color: '#ffffff' }}
    >
      <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
        Are you sure you want to delete the view &ldquo;{selectedView.name}&rdquo;?
      </Text>
    </BasicModal>
  );
})``;

export default DeleteModal;
