import React from 'react';
import styled from '@emotion/styled';
import ScoreValue from 'assets/containers/LocationRiskDetailsView/ScoreDetailsView/ScoreValue';
import ScoreHistoryTable from './ScoreHistoryTable';
import RiskDetails from 'assets/containers/AssetDetailView/views/RiskDetails';
import { AboutScore } from 'assets/containers/LocationRiskDetailsView/ScoreDetailsView/AboutScore';
import { Button, Section } from '@luxe/components';
import { Row } from 'antd';

const StyledSection = styled(Section)`
  & {
    padding-left: 0;
    background-color: transparent;
  }

  .section-row-header {
    padding-left: 20px;
  }

  .grid-cols {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-auto-flow: column;
  }
`;

const ScoreDetailsPage = ({ riskScore, proactiveRisk, riskComponent, riskhistory, riskDetails, ...props }) => {
  const Back = () => {
    return (
      <Button onClick={props.returnToScorecard} variant="primary" type="link">
        Back
      </Button>
    );
  };
  return (
    <>
      <StyledSection
        title={riskScore.name}
        buttons={[<Back key={`details-back-${riskScore.name}`} />]}
        color="transparent"
      >
        <div className="grid-cols">
          <ScoreValue riskScore={riskScore} proactiveRisk={proactiveRisk} riskComponent={riskComponent} />
          {riskComponent && <AboutScore riskComponent={riskComponent} />}
        </div>
      </StyledSection>
      <RiskDetails attributes={riskDetails?.related_attributes || []} />
      <Section title="History:" color="#2E2E2E">
        <Row style={{ width: '100%', display: 'block' }}>
          <ScoreHistoryTable history={riskhistory} width="100%" />
        </Row>
      </Section>
    </>
  );
};

export default ScoreDetailsPage;
