import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';
import { useTheme } from 'emotion-theming';
import { PlusCircleOutlined } from '@ant-design/icons';

import { BasicModal as Modal, Button, Label as Input, RadioGroup } from '@luxe/components';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import {
  createRiskModel,
  deleteRiskModel,
  getRiskModels,
  trackRiskModelChange,
} from 'admin/riskmodels/modules/riskmodels';

import RiskModelTable from './RiskModelTable';

const CREATE_MODAL_BODY = 'Create a new risk model';
const DELETE_MODAL_TITLE = 'Are you sure you want to delete this Risk Model?';
const DELETE_MODAL_BODY = 'This operation cannot be undone!';
const CREATE_RISK_MODEL_RADIO_OPTIONS = [
  {
    label: 'Weighted Score',
    value: 'weighted_score',
  },
  {
    label: 'Max Score',
    value: 'max_score',
  },
];

const RiskModelListView = ({ ...props }) => {
  const { space } = useTheme();
  const dispatch = useDispatch();
  const [createRiskModelRadioOption, setCreateRiskModelRadioOption] = useState(CREATE_RISK_MODEL_RADIO_OPTIONS[0]);
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [selectedRiskModel, setSelectedRiskModel] = useState(null);
  const riskModels = useSelector(store => store.riskModels.RiskModels);
  const status = useSelector(store => store.riskModels.status);
  const riskProfiles = useSelector(store => store.riskProfiles.riskProfiles);

  useEffect(() => {
    dispatch(getRiskModels());
    dispatch(trackRiskModelChange(false));
  }, [dispatch]);

  const proactiveScoring = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.proactive?.scoring;
  if (!proactiveScoring) {
    return null;
  }

  const onCreate = values => {
    //TODO: this should happen in the API and db as a default rather than the UI if this is always expected
    dispatch(
      createRiskModel({
        ...values,
        scoring_type: createRiskModelRadioOption.value,
        indices: [{ name: 'External Risks' }, { name: 'Internal Risks' }],
      }),
    );
    setIsCreateModalShowing(false);
  };

  const onDelete = async () => {
    if (selectedRiskModel && Array.isArray(selectedRiskModel)) {
      await selectedRiskModel.forEach(item => {
        dispatch(deleteRiskModel(item));
      });
    }
    setIsDeleteModalShowing(false);
    setSelectedRiskModel(null);
  };

  const onRadioChange = event => {
    const selectedOption = CREATE_RISK_MODEL_RADIO_OPTIONS.find(option => option.value === event.target.value);
    setCreateRiskModelRadioOption(selectedOption);
  };

  const deleteSelectedItems = e => {
    e.preventDefault();
    if (!selectedRiskModel) {
      return alert('Please select a risk model.');
    }
    setIsDeleteModalShowing(true);
  };

  return (
    <>
      <CreateRiskModelForm
        visible={isCreateModalShowing}
        onCreate={onCreate}
        riskProfiles={riskProfiles}
        onCancel={() => {
          setIsCreateModalShowing(false);
        }}
        onRadioChange={onRadioChange}
        createRiskModelRadioOption={createRiskModelRadioOption}
      />

      <DeleteRiskModelModal
        isDeleteModalShowing={isDeleteModalShowing}
        setIsDeleteModalShowing={setIsDeleteModalShowing}
        selectedRiskModel={selectedRiskModel}
        setSelectedRiskModel={setSelectedRiskModel}
        onDelete={onDelete}
        onCancel={() => {
          setIsDeleteModalShowing(false);
          setSelectedRiskModel(null);
        }}
      />

      <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view risk-models">
        <Row gutter={[4, 8]}>
          <Col span={10} align="start">
            <h1>Risk Models</h1>
          </Col>
          <Col span={10} align="end">
            {selectedRiskModel && selectedRiskModel.length > 0 ? (
              <>
                <span>{selectedRiskModel.length} Items Selected </span>
                <Button variant="danger" onClick={e => deleteSelectedItems(e)}>
                  Delete Selected Risk Models
                </Button>
              </>
            ) : null}
          </Col>
          <Col span={4} align="end">
            <Button variant="primary" style={{ marginTop: '4px' }} onClick={() => setIsCreateModalShowing(true)}>
              <PlusCircleOutlined />
              Create Risk Model
            </Button>
          </Col>
        </Row>
        <Container>
          <RiskModelTable
            riskModels={riskModels}
            setSelectedRiskModel={setSelectedRiskModel}
            setIsDeleteModalShowing={setIsDeleteModalShowing}
            status={status}
          />
        </Container>
      </ListViewContent>
    </>
  );
};

const CreateRiskModelForm = ({ visible, onCreate, onCancel, onRadioChange, createRiskModelRadioOption }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title={CREATE_MODAL_BODY}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_risk_model_form"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="name"
          label={<label style={{ color: 'red' }}>Name</label>}
          rules={[
            {
              required: true,
              message: 'Please input the title of Risk Model',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <RadioGroup
            className={'risk-model-radio'}
            options={CREATE_RISK_MODEL_RADIO_OPTIONS}
            onChange={onRadioChange}
            value={createRiskModelRadioOption.value}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const DeleteRiskModelModal = ({ isDeleteModalShowing, selectedRiskModel, onDelete, onCancel }) => {
  return (
    <Modal title={DELETE_MODAL_TITLE} visible={isDeleteModalShowing} onCancel={onCancel} onOk={onDelete}>
      <p>{DELETE_MODAL_BODY}</p>
    </Modal>
  );
};

export default RiskModelListView;
