import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import LoadingScreen from 'layout/containers/LoadingScreen';

// This component is designed to not trigger duplicate renders when child components change.
// For more information please refer to this documentation:
// https://v5.reactrouter.com/web/api/Route/component
// https://github.com/auth0/auth0-react/issues/226
const ProtectedRoute = ({ children, ...args }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    isAuthenticated ? <Route {...args}>{children}</Route> : <LoadingScreen />
  );
};

export default ProtectedRoute;
