import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

export default class DurationFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
  };

  render() {
    const { value } = this.props;
    if (value) {
      const duration = moment.duration(value, 'seconds');
      let newValue = duration.humanize(false);
      newValue = newValue.replace('a minute', '1 minute');
      newValue = newValue.replace('an hour', '1 hour');
      newValue = newValue.replace('a day', '1 day');
      newValue = newValue.replace('a month', '1 month');
      return value < 0 ? `-${newValue}` : newValue;
    }
    return null;
  }
}
