import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { operators as Ops } from 'constants/application';

import { getViewData, getViewMeta } from 'views/modules/views';
import { ViewTable } from 'views/components';
import { getParams } from 'views/utils';

const ShipmentsTab = () => {
  const dispatch = useDispatch();
  const views = useSelector(store => store.views);
  const shipments = views?.shipments;
  const init = shipments === undefined;

  useEffect(() => {
    if (init) {
      const overrides = {
        kilometers_at_risk: { type: 'kilometers_at_risk' },
        miles_at_risk: { type: 'miles_at_risk' },
      };
      dispatch(getViewMeta('shipments', overrides));
    }
  }, [dispatch, init]);

  const hasMeta = shipments?.meta?.allowed !== undefined;
  const { id: threatId = null } = useParams();
  const { search: url } = useSelector(store => store.router.location);
  const units = useSelector(store => store.account?.account?.prefs?.units?.default);

  const query = useMemo(() => {
    if (!hasMeta || !units) {
      return null;
    }

    const params = getParams(url, ['channel', 'mixpanel_event']);
    const filters = [
      ...params.filters,
      {
        name: 'threat_id',
        operator: Ops.exact,
        value: threatId,
      },
    ];

    const distanceRiskCol = units === 'imperial' ? 'miles_at_risk' : 'kilometers_at_risk';
    return {
      columns: ['shipment', 'origin', 'destination', distanceRiskCol, 'risk'],
      ...params,
      filters,
      limit: 10,
    };
  }, [hasMeta, threatId, url, units]);

  useEffect(() => {
    if (query !== null) {
      dispatch(getViewData('shipments', query));
    }
  }, [dispatch, query]);

  const push = () => {};
  if (shipments?.items === undefined) {
    return null;
  }
  const getRowId = row => row.shipment.id;
  return <ViewTable data={shipments} push={push} rowKey={getRowId} />;
};

export default ShipmentsTab;
