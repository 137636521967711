import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 10px 20px 10px;
  margin-bottom: 24px;
  width: 100%;
  background-color: ${props => props.backgroundColor || props.theme.colors.black3};

  & .section-row-buttons {
    text-align: right;
    align-self: center;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const HeaderTitle = styled.div`
  font-size: ${({ theme, ...props }) => props.headerFontSize || theme.fontSizes.heading}px;
  font: ${({ theme }) => theme.fonts.body};
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const SectionDivider = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  height: 1px;
  margin-top: -5px;
  margin-bottom: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.span`
  margin: 2px;
`;

const ActionButtonContainer = styled.span`
  display: flex;
  white-space: nowrap;
`;

const ActionTextContainer = styled.span`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
`;

export const Section = props => {
  return (
    <SectionRow className={props.className} backgroundColor={props.backgroundColor}>
      <SectionHeader className="section-row-header">
        <HeaderTitle headerFontSize={props.headerFontSize}>{props.title}</HeaderTitle>
        <ActionButtonContainer>
          <ActionTextContainer>{props.actionText}</ActionTextContainer>
          {props.actionButtons.map(btn => (
            <ButtonContainer key={btn.key}>{btn}</ButtonContainer>
          ))}
        </ActionButtonContainer>
        <ButtonsContainer>{props.buttons}</ButtonsContainer>
      </SectionHeader>
      {!props.rules ? <SectionDivider /> : null}
      {props.children}
    </SectionRow>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  actionText: PropTypes.string,
  actionButtons: PropTypes.array,
  buttons: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Section.defaultProps = {
  className: '',
  title: '',
  actionText: '',
  actionButtons: [],
  buttons: [],
  children: [],
};
