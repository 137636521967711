import styled from '@emotion/styled';
import { variant } from 'styled-system';
import { Badge as aBadge } from 'antd';
import theme from 'theme';


const BadgeVariants = variant({
  variants: {
    primary: {
      '.ant-badge-count': {
        bg: theme.colors.primary,
        'boxShadow': 'none',
        'width': '70px'
      },
    },
    low: {
      '.ant-badge-count': {
        bg: theme.colors.lowRisk,
        'boxShadow': 'none'
      },
    },
    medium: {
      '.ant-badge-count': {
        bg: theme.colors.medRisk,
        'boxShadow': 'none'
      },
    },
    high: {
      '.ant-badge-count': {
        bg: theme.colors.highRisk,
        'boxShadow': 'none' 
      },
    },
  },
});

const RiskBadge = styled(aBadge)`
  ${BadgeVariants};
  margin: 5px;
  width: 35px;
`;

export { RiskBadge };
