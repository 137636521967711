import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { FormItem, Section, Select } from '@luxe/components';
import { useSelector } from 'react-redux';
import { formatSelectOptions } from 'common/utils';

const AssetFeedbackSection = ({ asset, form }) => {
  const firstLoad = useRef(true);
  const users = useSelector(store => store.users)?.users || [];

  useEffect(() => {
    if (asset?.contacts && firstLoad.current) {
      const initialValues = {
        feedback_providers: formatSelectOptions(
          asset.contacts.filter(contact => contact.type === 'feedback_provider').map(c => c.user),
        ),
        feedback_receivers: formatSelectOptions(
          asset.contacts.filter(contact => contact.type === 'feedback_receiver').map(c => c.user),
        ),
      };
      form.setFieldsValue(initialValues);
      firstLoad.current = false;
    }
  }, [asset, form]);

  return (
    <>
      <Section title={'Feedback Configuration'} headerFontSize={'12px'} backgroundColor={'transparent'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="feedback_providers" label="Feedback Providers" labelCol={{ span: 24 }}>
              <Select options={formatSelectOptions(users)} name="feedback_providers" width={'100%'} isMulti={true} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="feedback_receivers"
              label="Feedback Receivers"
              labelCol={{ span: 24 }}
              style={{ marginLeft: '20px' }}
            >
              <Select
                options={formatSelectOptions(users.filter(u => u.type === 'user'))}
                name="feedback_receivers"
                width={'100%'}
                isMulti={true}
              />
            </FormItem>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default AssetFeedbackSection;
