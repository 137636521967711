import React from 'react';
import PropTypes from 'prop-types';

export default class BaseThreatFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const value = this.props.value.headline ? this.props.value.headline : `Threat ${this.props.value.id}`;
    return (
      <>
        <a href={this.props.value.url}>{value}</a>
      </>
    );
  }
}
