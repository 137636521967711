import React, { useState } from 'react';

import { Typography } from 'antd';

import { useTheme } from 'emotion-theming';
import { Button, BasicModal as Modal } from '@luxe/components';

const { Text } = Typography;

const UserProfileCancelButton = ({ cancelWorkflowChanges, push, ...props }) => {
  const { colors, fontSizes } = useTheme();
  let [visible, setVisible] = useState(false);
  const onOk = () => {
    window.location.reload();
  };
  return (
    <>
      <Button className={'cancel profile-button'} type="link" onClick={() => setVisible(!visible)}>
        Cancel
      </Button>
      <Modal
        visible={visible}
        title="Cancel Changes?"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => setVisible(!visible)}
        footer={[
          <Button key={'modal-onCancel-workflow'} onClick={() => setVisible(!visible)}>
            No
          </Button>,
          <Button key={'modal-onSave-workflow'} onClick={onOk}>
            Yes
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>All unsaved changes will be lost</Text>
      </Modal>
    </>
  );
};

export default UserProfileCancelButton;
