import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

import { ListViewContent } from '@luxe/components/src/Container';
import { Skeleton, Button } from '@luxe/components';

import AssetTable from './AssetTable';
import ThreatInfo from './ThreatInfo';
import ThreatData from './ThreatData';

import { getFeedbackRequest, saveFeedbackResponses, showFeedbackValidationError } from 'feedback/modules/feedback';

const FeedbackResponseNotice = ({ style, title, description }) => {
  return (
    <div className={style}>
      <ListViewContent className="list view dark">
        <div className="completed">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </ListViewContent>
    </div>
  );
};

const FeedbackResponseView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const feedbackId = params.id;
  const item = useSelector(store => store.feedback.item);
  const status = useSelector(store => store.feedback.status);

  useEffect(() => {
    dispatch(getFeedbackRequest(feedbackId));
  }, [dispatch, feedbackId]);

  const onSave = () => {
    const data = item.assets;
    const resp = data.map(({ id: asset_id, data }) => {
      return { asset_id, data, hasError: typeof data?.['impacted'] === 'undefined' };
    });
    const errorCount = resp.filter(data => data.hasError)?.length;
    if (data?.length === errorCount) {
      dispatch(showFeedbackValidationError());
    } else {
      const filteredResp = resp
        ?.filter(asset => {
          return asset?.data && Object.keys(asset?.data)?.length !== 0 && typeof asset?.data?.impacted === 'boolean';
        })
        .map(({ hasError, ...rest }) => rest);
      dispatch(saveFeedbackResponses(feedbackId, filteredResp));
    }
  };

  if (Object.keys(item).length < 1 || status === 'PENDING') {
    return (
      <div className={`${props.className} loading`}>
        <Skeleton />
      </div>
    );
  }

  if (item.submitted) {
    return (
      <FeedbackResponseNotice
        title="Thank You"
        description="Your feedback request has been successfully transmitted."
        style={props.className}
      />
    );
  }

  if (item.expired) {
    return (
      <FeedbackResponseNotice
        title="Request Expired"
        description="Your feedback request has expired."
        style={props.className}
      />
    );
  }

  const filledAssets = item.assets?.reduce((acc, cur) => {
    if (cur.data && Object.keys(cur.data).length > 0 && typeof cur?.data?.impacted === 'boolean') acc++;

    return acc;
  }, 0);
  const totalAssets = item.assets?.length;

  return (
    item && (
      <div className={props.className}>
        <ListViewContent className="list view dark">
          <Row gutter={[4, 8]}>
            <Col span={20} align="start">
              <h1>{item.user?.name} - Feedback form</h1>
              <p>
                On this page you can provide feedback for nodes of your network that could potentially be impacted by
                the incident below.
              </p>
            </Col>
          </Row>
          <div className="threat">
            <div className="threat-desc">
              <ThreatInfo />
            </div>
            <div className="threat-data">
              <ThreatData />
            </div>
          </div>
          <div className="asset-table">
            <Row gutter={[4, 8]}>
              <Col span={20} align="start">
                <h3>Feedback for Facilities</h3>
                <p>Please provide feedback for the following Locations:</p>
              </Col>
            </Row>
            <AssetTable />
          </div>
          <div className="buttons">
            <div></div>
            <div></div>
            <div className="fill-status-info">
              <p>
                {filledAssets} / {totalAssets} facility feedback filled
              </p>
              <p>Only rows with an impact entered will be saved</p>
            </div>
            <div>
              <Button variant="disabled">Cancel</Button>
              <Button variant="primary" onClick={onSave} style={{ marginLeft: '16px' }}>
                Provide Feedback
              </Button>
            </div>
          </div>
        </ListViewContent>
      </div>
    )
  );
})`
  &.loading {
    padding: 38px;
    padding-right: 200px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  .threat {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  .threat-desc {
    flex: 2;
    margin-right: 24px;
    background-color: #212121;
    padding: 16px;
    align-self: stretch;

    h2 {
      font-size: 20px;
      line-height: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  .threat-data {
    flex: 1;
    background-color: #212121;
    padding: 43px 16px;
    align-self: stretch;

    p {
      font-size: 13px;
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
    }
    dt {
      width: 33%;
    }
    dd {
      margin-left: auto;
      width: 66%;
      text-align: right;
    }
  }

  .asset-table {
    background-color: #212121;
    padding: 8px;
    margin-bottom: 26px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    .fill-status-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      margin-bottom: 3px;
    }
  }

  .completed {
    margin: 100px;
    padding: 40px;
    background-color: #141417;
  }
`;

export default FeedbackResponseView;
