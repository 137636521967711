import React from 'react';
import PropTypes from 'prop-types';
import getFromConfig from 'constants/config';

export default class LaneFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const baseUrl = getFromConfig('REACT_APP_RISKBOX_BASE_URL');
    const url = `${baseUrl}lanes/${this.props.value.id}/`;
    return <a href={url}>{this.props.value.name}</a>;
  }
}
