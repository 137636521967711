import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Tooltip as aTooltip } from 'antd';

const Tooltip = styled(aTooltip)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  font-family: ${props => props.theme.fonts.body};
`;

export { Tooltip };
