import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { operators as Ops } from 'constants/application';

import { getViewData, getViewMeta } from 'views/modules/views';
import { ViewTable } from 'views/components';
import { getParams } from 'views/utils';

import { ASSET_META_OVERRIDES } from '.';
import { SYSTEM_TYPES } from 'common/Constants';
import ImpactedAssets from './ImpactedAssets';
import { useLocation, useHistory } from 'react-router-dom';

const SubTiersTab = ({ incId = null, subTableToggle, fromList = false }) => {
  const dispatch = useDispatch();
  const views = useSelector(store => store.views);
  const [selectedSubTier, setSelectedSubTier] = useState({});
  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(location?.search), [location]);

  const onValueClick = val => {
    queryParams.set('offset', '0');
    history.push({ search: queryParams.toString() });
    if (fromList) subTableToggle(true);
    setSelectedSubTier(val);
  };

  const onBackButtonClick = () => {
    if (fromList) subTableToggle(false);
    queryParams.set('offset', '0');
    history.push({ search: queryParams.toString() });
    setSelectedSubTier({});
  };

  const [additionalColumns] = useState([
    {
      widget: 'Input',
      description: 'Action',
      short_description: 'Action',
      name: 'scorecard',
      order_by: null,
      type: 'scorecard',
      maps: 'asset',
    },
  ]);
  const assets = views?.subTier;
  const init = assets === undefined;

  useEffect(() => {
    if (init) {
      const SUB_TIER_META_OVERRIDES = {
        ...ASSET_META_OVERRIDES,
        asset__name: { description: 'Facility' },
      };
      delete SUB_TIER_META_OVERRIDES.assets;
      dispatch(getViewMeta('subTier', SUB_TIER_META_OVERRIDES, additionalColumns, undefined, 'assets'));
    }
  }, [dispatch, init, additionalColumns]);

  const hasMeta = assets?.meta?.allowed !== undefined;
  const { id: threatId = null } = useParams();
  const { search: url } = useSelector(store => store.router.location);
  if (assets) assets.currentUrl = url;

  const query = useMemo(() => {
    if (!hasMeta) {
      return null;
    }

    const params = getParams(url, ['channel', 'mixpanel_event']);
    let filters = [
      {
        name: 'threat_id',
        operator: Ops.exact,
        value: threatId || incId,
      },
      {
        name: 'system_type',
        operator: Ops.exact,
        value: SYSTEM_TYPES.discovered,
      },
    ];

    if (!incId) {
      filters = [...filters, ...params.filters];
    }

    return {
      columns: ['asset__name', 'org_type', 'location__city', 'risk', 'downstream_asset_count'],
      ...params,
      filters,
      limit: 10,
    };
  }, [hasMeta, threatId, url, incId]);

  useEffect(() => {
    if (query !== null) {
      dispatch(getViewData('subTier', query, additionalColumns, 'assets'));
    }
  }, [dispatch, query, additionalColumns]);

  if (assets?.items === undefined) {
    return null;
  }

  if (assets?.meta?.current?.columns?.length > 0) {
    assets.meta.current.columns.forEach(x => {
      if (x.name === 'downstream_asset_count') {
        x.onValueClick = onValueClick;
        x.type = 'sub_table';
        x.description = 'Impacted Assets';
      }
    });
  }
  const getRowId = row => row.asset.id;

  return !selectedSubTier?.asset ? (
    <ViewTable data={assets} rowKey={getRowId} additionalColumns={additionalColumns} ignoreMargin={incId} />
  ) : (
    <ImpactedAssets onBackButtonClick={onBackButtonClick} selectedSubTier={selectedSubTier} fromList={fromList} />
  );
};

export default SubTiersTab;
