import React from 'react';

import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { history } from '../../../store';

import Pagination from './Pagination';
import { Menu, Dropdown } from 'antd';
import { Alert, Button, Empty, Table } from '@luxe/components';
import ViewFilters from '../ViewFilters';
import getFormatter from './formatters';
import { getUrl } from '../../utils';
import { convertedNumber } from 'admin/utils';
import { changeAssetLabelToFacility } from 'utils';

const getOrderByUrl = ({ order_by }, options) => {
  const orderBy = options.meta.current.order_by || [];
  const columnOrderBy = orderBy.find(x => x.name === order_by);
  const direction =
    columnOrderBy?.direction === 'asc' ? 'desc' : columnOrderBy?.direction === 'desc' ? undefined : 'asc';
  const { includes = [], paramsToKeep } = options;
  const meta = {
    ...options.meta,
    current: {
      ...options.meta.current,
      filters: options.meta.current.filters.filter(x => includes.includes(x.name)),
      order_by: direction && [{ name: order_by, direction }],
    },
  };
  const url = getUrl(meta, paramsToKeep);
  return url;
};

const formatColumnData = (column = {}, options) => {
  const Formatter = getFormatter(column.type);
  let renderer = (_, data) => {
    if (data[column.name])
      return (
        <Formatter
          value={data[column.name]}
          inAppNavigation={column.inAppNavigation}
          longDateFormat={column.longDateFormat}
          onValueClick={() => column?.onValueClick(data)}
        />
      );
  };
  if (column.name?.includes('__')) {
    renderer = (_, data) => {
      const cols = column.name.split('__');
      if (data[cols[0]] && data[cols[0]][cols[1]]) return <Formatter value={data[cols[0]][cols[1]]} />;
    };
  }

  let newCol = {
    title: changeAssetLabelToFacility(column.description),
    url: column.order_by ? getOrderByUrl(column, options) : null,
    dataIndex: column.name,
    key: column.guid ? column.guid : column.name,
    render: renderer,
    onHeaderCell: column => ({
      onClick: () => {
        column.url && history.push(column.url);
      },
    }),
  };
  const getTableSortDirection = value => {
    if (value === 'asc') {
      return 'ascend';
    } else if (value === 'desc') {
      return 'descend';
    }
  };
  if (column.order_by) {
    newCol = { ...newCol, sorter: true };
    const currentSort = options?.meta?.current?.order_by?.length ? options.meta.current.order_by[0] : {};
    if (column.order_by === currentSort.name) {
      newCol = { ...newCol, sortOrder: getTableSortDirection(currentSort.direction) };
    }
  }
  return newCol;
};

const getColumns = ({ pending, meta, includes, paramsToKeep }, viewColumns) => {
  if (pending || meta?.current === undefined) {
    return [];
  }
  const newCols = meta.current.columns.map(column => {
    if (viewColumns && viewColumns.map(x => x.includes('__') && x.split('__')[0]).includes(column.name)) {
      const colName = viewColumns.filter(x => x.includes(column.name))[0];
      return column.columns?.filter(c => c.name === colName)[0];
    } else {
      return column;
    }
  });
  return newCols.map(column => {
    return formatColumnData(column, { meta, includes, paramsToKeep });
  });
};

const includeAdditionalColumnsData = func => (data, additionalColumns) => {
  const reducedCols = additionalColumns.reduce((columns, currentColumn) => {
    return { ...columns, ...{ [currentColumn.maps]: currentColumn } };
  }, {});
  let columnData = [...data];

  if (data.length > 0) {
    Object.keys(reducedCols).forEach(key => {
      columnData = func(columnData, reducedCols, key);
    });
  }
  return columnData;
};

const Actions = ({ actions, data, ...props }) => {
  if (!data || !data.selectedRows || !data.selectedRows.length) {
    return null;
  }
  const ActionsMenu = (
    <Menu>
      {actions.map(action => {
        const onClick = () => {
          action.action(data.selectedRows);
        };
        return (
          <Menu.Item key={`action-${action.title}`} onClick={onClick}>
            {action.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={ActionsMenu}>
      <Button variant="primary">Actions</Button>
    </Dropdown>
  );
};

export const ViewTable = ({
  data,
  viewColumns,
  actions,
  className,
  allowFilterOptions,
  includes,
  sorted,
  actionColumn,
  additionalColumns,
  size,
  paramsToKeep,
  ignoreMargin,
  ...props
}) => {
  const dispatch = useDispatch();
  if (!data) {
    return null;
  }
  const { pending, next, previous, currentUrl } = data;
  const description = pending ? 'Loading' : 'No Data';
  const empty = <Empty description={description} />;
  const columns = getColumns({ ...data, includes, paramsToKeep }, viewColumns);
  if (!pending && actionColumn) {
    columns.push(actionColumn);
  }

  const mapData = (data, cols, key) => {
    return data.map(item => {
      if (item[key]) item[cols[key].name] = cols[key].formatter ? cols[key].formatter(item[key]) : item[key];
      return item;
    });
  };

  const { rowKey = 'id' } = props;
  const items = data.items || [];
  const tableData = additionalColumns ? includeAdditionalColumnsData(mapData)(items, additionalColumns) : items;
  const allowSelect = props.allowSelect !== undefined ? props.allowSelect === true : actions && actions.length > 0;
  const rowSelection = allowSelect ? props.rowSelection : undefined;

  const updatedTableData =
    !pending &&
    tableData &&
    tableData.map(item => {
      if (item.hasOwnProperty('downstream_asset_count')) {
        item.downstream_asset_count = item.downstream_asset_count.toString();
      }
      return {
        ...item,
        ...(item.summary && {
          summary: {
            ...item.summary,
            materials_out: item.summary.materials_out.toString(),
            materials_final_product: item.summary.materials_final_product.toString(),
            materials_final_product_value: convertedNumber(item.summary.materials_final_product_value),
          },
        }),
      };
    });

  const onFiltersChanged = filters => {
    if (props.onFiltersChanged !== undefined) {
      props.onFiltersChanged(filters);
    } else {
      const meta = {
        ...data.meta,
        current: {
          ...data.meta.current,
          filters,
        },
      };
      const url = getUrl(meta);
      dispatch(push(url));
    }
  };

  return (
    <div className={className}>
      {(data.askToSelect || data.selectedAll) && (
        <Alert
          message={
            <p>
              You have selected {data.selectedRows && data.selectedRows.length.toString()} items.
              {!data.selectedAll && (
                <Button type="link" onClick={() => props.selectAll()}>
                  Did you want to select all items?
                </Button>
              )}
            </p>
          }
          type="success"
          closable
        />
      )}
      <div
        style={{
          float: 'right',
          margin: (actions && actions.length < 1 && !allowFilterOptions) || ignoreMargin ? '0' : '15px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {allowFilterOptions && (
            <Button
              disabled={pending}
              variant={pending ? 'disabled' : 'primary'}
              style={{ marginRight: '10px' }}
              onClick={() => props.showFilters(data.datasource)}
            >
              Filters
            </Button>
          )}
          {actions && <Actions actions={actions} data={data} />}
        </div>
      </div>
      {props.allowFiltering && (
        <ViewFilters data={data} excludes={props.excludes} includes={includes} onFiltersChanged={onFiltersChanged} />
      )}
      <>
        <Table
          dataSource={updatedTableData}
          pagination={false}
          columns={columns}
          rowSelection={rowSelection}
          rowKey={rowKey}
          locale={{ emptyText: empty }}
          size={size}
        />
        <Pagination currentUrl={currentUrl} push={props.push} pending={pending} next={next} previous={previous} />
      </>
    </div>
  );
};
