import React from 'react';
import { BasicModal as Modal } from '@luxe/components';
import RuleSettings from './RuleSettings';

export const RuleModal = ({ profile, rule, editRule, createRiskProfileRule, updateRiskProfileRule }) => {
  const isUpdatingRule = rule?.id ? true : false;
  const readOnly = profile.id !== rule.profile_id || profile.system_wide;
  let modalTitle = readOnly
    ? profile.id !== rule.profile_id
      ? 'System Rule - Read Only'
      : 'Profile Rule'
    : isUpdatingRule
    ? 'Edit Rule'
    : 'Add new rule';

  return (
    <Modal
      width={600}
      okText="Save"
      okButtonProps={readOnly ? { style: { display: 'none' } } : {}}
      cancelButtonProps={readOnly ? { style: { display: 'none' } } : {}}
      title={modalTitle}
      visible={true}
      onCancel={() => {
        editRule({ mode: null, reset: true });
      }}
      onOk={() => {
        isUpdatingRule ? updateRiskProfileRule(rule.profile_id, rule) : createRiskProfileRule(rule.profile_id, rule);
      }}
    >
      <RuleSettings updateRule={editRule} disabled={readOnly} />
    </Modal>
  );
};
