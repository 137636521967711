import styled from '@emotion/styled';
import { SearchTable } from '@luxe/components';
import { clearAssetSummary, getAssetSummary } from 'discover/modules/viz';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearView, getViewData, getViewMeta } from 'views/modules/views';

const MaterialTable = styled(({ assetId, className }) => {
  const currentPage = useRef(1);
  const currentOrderBy = useRef('');
  const materialFlows = useSelector(state => state.views?.['material-flows'] || {});
  const assetSummary = useSelector(state => state.networkGraph?.assetSummary ?? {});
  const [activePage, setActivePage] = useState(1);
  const [orderBy, setOrderBy] = useState([{ name: 'material_value', direction: 'desc' }]);
  const dispatch = useDispatch();

  const firstLoad = materialFlows.datasource === undefined;
  const meta = materialFlows.meta;
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    materialFlows,
  );

  useEffect(
    () => () => {
      dispatch(clearView('material-flows'));
      dispatch(clearAssetSummary());
    },
    [dispatch],
  );

  useEffect(() => {
    if (firstLoad) {
      dispatch(getViewMeta('material-flows'));
      dispatch(getAssetSummary(assetId));
    }
  }, [dispatch, firstLoad, assetId]);

  useEffect(() => {
    const pageChanged = activePage !== currentPage.current;
    const orderByChanged = JSON.stringify(orderBy) !== currentOrderBy.current;
    const shouldLoad = meta && assetId && (firstLoad || pageChanged || orderByChanged);

    if (shouldLoad) {
      const params = {
        order_by: orderBy ?? [],
        limit: 7,
      };
      params.offset = (activePage - 1) * params.limit;
      currentPage.current = activePage;
      currentOrderBy.current = JSON.stringify(orderBy);
      const query = {
        columns: ['material_id', 'name', 'product', 'final_node', 'material_value'],
        ...params,
        utl: 'material-flows',
        filters: [{ name: 'from_node_id', operator: '==', value: assetId }],
      };
      dispatch(getViewData('material-flows', query));
    }
  }, [dispatch, firstLoad, meta, orderBy, assetId, activePage]);

  const columns = [
    {
      title: 'Outbound Materials / Products',
      dataIndex: 'name',
      sorter: true,
      orderBy: 'name',
    },
    {
      title: 'Final Product',
      dataIndex: 'product',
      sorter: true,
      orderBy: 'product',
    },
    {
      title: 'Final Facility',
      dataIndex: ['final_node', 'name'],
      sorter: true,
      orderBy: 'final_node',
    },
    {
      title: 'Value of Product',
      dataIndex: 'material_value',
      render: data => (data ? Intl.NumberFormat('en', { notation: 'compact' }).format(data) : '-'),
      sorter: true,
      orderBy: 'material_value',
      defaultSortOrder: 'descend',
      align: 'center',
    },
  ];

  const uniqueKey = row => {
    const materialID = row?.material_id;
    const productName = row?.product ? row?.product?.replaceAll(' ', '') : '';
    return `${materialID}_${productName}`;
  };

  return (
    <div className={className}>
      <div className="materials-summary-container">
        <div>
          Outbound<span>{assetSummary?.materials?.from || '-'}</span>Materials
        </div>
        <div>
          Final<span>{assetSummary?.materials?.final_products || '-'}</span>Products
        </div>
        <div>
          Value
          <span>
            {assetSummary?.materials?.final_product_value
              ? Intl.NumberFormat('en', { notation: 'compact' }).format(assetSummary?.materials?.final_product_value)
              : '-'}
          </span>
          of Products
        </div>
      </div>
      <SearchTable
        description="No matching records found"
        table="material-flows-search-table"
        className={'material-flows-search-table'}
        rowKey={row => uniqueKey(row)}
        columns={columns}
        rowData={materialFlows.items}
        onPageChange={setActivePage}
        onSort={setOrderBy}
        paginationData={paginationData}
        tableSize="small"
        displayHidden
      />
    </div>
  );
})`
  .material-flows-search-table {
    padding: 0;
    background-color: unset;

    .section-row-header {
      display: none;
    }
  }

  .materials-summary-container {
    display: flex;
    justify-content: center;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: #808080;
      font-weight: 300;
      font-family: 'Source Sans Pro';
      margin: 0 10px 10px;
      span {
        font: 30px 'League Gothic';
        color: #eeeeee;
        padding: 8px;
      }
    }
  }
`;

export default MaterialTable;
