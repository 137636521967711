import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider, Empty, TreeSelect } from 'antd';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { getIndustry, getThreats } from 'modal/userprofile/modules/network';
import { getFamilies } from 'modal/incidentfeed/modules/utils';
import { trackWorkFlowChange, updateWorkflow } from 'admin/workflows/modules/workflows';

import LoadingScreen from 'layout/containers/LoadingScreen';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ToggleRadioGroup from './ToggleRadioGroup';
import KeywordSelectors from './KeywordSelectors';

const MyNetworkView = styled(({ includedChannels, ...props }) => {
  const dispatch = useDispatch();
  const reactive = true;

  const industries = useSelector(store => store.userNetwork.industries);
  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const familyData = useSelector(store => store.families.families);
  const [familiesReady, setFamiliesReady] = useState(false);
  const [formattedGlobalCategory, setFormattedGlobalCategory] = useState([]);
  const [globalFamilyTreeValue, setGlobalFamilyTreeValue] = useState([]);
  const profile_global_events = props.workflow?.data.filtering?.global_events_categories;
  const user_profile_industries = props.workflow?.data.filtering?.industries;
  const [majorEvents, setMajorEvents] = useState(props.workflow?.data.filtering?.major_events || false);
  const toolTipStyling = { backgroundColor: '#141414', fontSize: '12px', color: '#ffffff' };
  const { keywords } = props.workflow?.data.filtering;

  const updateGlobalFamily = (familiesSelected, b, c) => {
    const familySet = [...new Set(familiesSelected.map(item => item.label))];
    if (familySet.length > 4) {
      limitSelection(familySet, true);
    } else {
      limitSelection(familySet);
    }
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(trackWorkFlowChange(true));
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            global_events_categories: familySet,
          },
        },
      }),
    );
  };

  const limitSelection = (family, reset) => {
    const updatedState = reset
      ? setChildrenFalse(formattedGlobalCategory, family)
      : setChildrenTrue(formattedGlobalCategory, family);
    setFormattedGlobalCategory(updatedState);
  };

  const setChildrenFalse = useCallback((parents, list) => {
    parents.forEach(p => {
      p.disableCheckbox = !list.includes(p.title);
      if (p.children?.length > 0) setChildrenFalse(p.children, list);
    });
    return parents;
  }, []);

  const setChildrenTrue = (parents, list) => {
    parents.forEach(p => {
      p.disableCheckbox = false;
      if (p.children?.length > 0) setChildrenTrue(p.children, list);
    });
    return parents;
  };

  useEffect(() => {
    if (formattedGlobalCategory && profile_global_events?.length > 4) {
      setChildrenFalse(formattedGlobalCategory, profile_global_events);
    }
  }, [profile_global_events, formattedGlobalCategory, setChildrenFalse]);

  useEffect(() => {
    const populateChild = arr => {
      let ind = [];
      arr.forEach(i => {
        ind.push({
          title: i.name,
          value: i.id,
          key: i.id,
          children: i.children?.length > 0 ? populateChild(i.children) : [],
        });
      });
      return ind;
    };

    if (industries?.length) {
      setFormattedIndustries(populateChild(industries));
    }
  }, [industries]);

  useEffect(() => {
    dispatch(getIndustry());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThreats());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFamilies(reactive));
  }, [dispatch, reactive]);

  useEffect(() => {
    if (familyData?.length > 0 && familiesReady) {
      const populateTreeValues = values => {
        const formattedList = [];
        if (values) {
          values.forEach(f => {
            const found = familyData.filter(fD => fD.name === f);
            if (found?.length > 0) {
              const familyItem = found.length > 1 ? found.sort((a, b) => b?.depth - a?.depth)[0] : found[0];
              const familyDataId = familyItem.id;
              formattedList.push({
                title: f,
                value: familyDataId,
                key: familyDataId,
              });
            }
          });
        }
        return formattedList;
      };
      setGlobalFamilyTreeValue(populateTreeValues(profile_global_events || []));
    }
  }, [familiesReady, familyData, profile_global_events, props.workflow]);

  const findNodeParent = (nodeList, fullData, onlyChildCheckable) => {
    let familyStructure = [];
    let familyParentObject = {};
    nodeList.forEach(p => {
      const path = p.path.split(':');
      path.pop();
      if (path.length < 1) {
        p.meta?.reactive
          ? familyStructure.push(populateFamily(p, onlyChildCheckable !== 'child'))
          : familyStructure.push(populateFamily(p, true));
      } else {
        familyParentObject = populateFamilyNonParents(fullData, p, onlyChildCheckable !== 'child');
      }
    });

    if (familyParentObject?.value) {
      familyStructure = fullData.filter(fD => fD.value !== familyParentObject.value);
      familyStructure.push(familyParentObject);
    }
    return familyStructure;
  };

  const populateFamilyNonParents = (fullData, p, populateParents) => {
    const path = p.path.split(':');
    path.pop();
    const foundObject = fullData.find(fD => fD.key === parseInt(path[0]));
    if (foundObject?.key === p.parent_id) {
      foundObject.children.push(populateFamily(p, populateParents));
    } else {
      let whichFamilyChild = foundObject.children.find(pO => pO.value === p.parent_id);
      if (whichFamilyChild) {
        whichFamilyChild.children.push(populateFamily(p, true));
      }
    }
    return foundObject;
  };

  const populateFamily = (family, checkbox) => {
    return {
      title: family.name,
      value: family.id,
      key: family.id,
      children: [],
      checkable: checkbox,
    };
  };
  if (familyData?.length > 0 && !familiesReady) {
    const maxDepth = Math.max.apply(
      Math,
      familyData.map(function(o) {
        return o.depth;
      }),
    );
    let ffData = [];
    let gcData = [];
    for (let i = 0; i < maxDepth + 1; i++) {
      let currentLevelNodesArray = familyData.filter(fD => fD.depth === i);
      ffData = findNodeParent(currentLevelNodesArray, ffData);
      gcData = findNodeParent(currentLevelNodesArray, gcData, 'child');
    }

    setFormattedGlobalCategory(gcData);
    setFamiliesReady(true);
  }

  const updateSubIndustries = industries => {
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(trackWorkFlowChange(true));
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            industries,
          },
        },
      }),
    );
  };

  const updateMajorEvents = e => {
    setMajorEvents(e.target.value);
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(trackWorkFlowChange(true));
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            major_events: e.target.value,
          },
        },
      }),
    );
  };

  const updateKeywords = keywords => {
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(trackWorkFlowChange(true));
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            keywords,
          },
        },
      }),
    );
  };

  if (industries.length === 0 || formattedGlobalCategory?.length === 0) {
    return (
      <div className={'loader'}>
        <LoadingScreen modal={true} />
      </div>
    );
  }
  const radioOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const renderEmpty = () => (
    <Empty
      imageStyle={{ height: 40 }}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span style={{ color: 'white' }}> No Data </span>}
    />
  );

  return (
    <div className={`${props.className}`}>
      <div className={'profile-content'}>
        <div className={'profile-network'}>
          {(includedChannels === undefined || includedChannels.includes('MAJOR_EVENT')) && (
            <div>
              <label>Major </label>
              <Tooltip
                title="Incidents that have global impact to all clients, regardless of where clients assets are located (e.g., global pandemic, war)"
                overlayInnerStyle={toolTipStyling}
              >
                <InfoCircleOutlined />
              </Tooltip>
              <div className={'notification-group'}>
                <ToggleRadioGroup
                  className={'profile-channel'}
                  name={'major-events'}
                  value={majorEvents}
                  onChange={updateMajorEvents}
                  options={radioOptions}
                />
              </div>
              <br></br>
            </div>
          )}
          <ConfigProvider renderEmpty={renderEmpty}>
            {(includedChannels === undefined || includedChannels.includes('GLOBAL_EVENTS')) && (
              <>
                <label>Category </label>
                <Tooltip title="Incidents that fall within our defined categories" overlayInnerStyle={toolTipStyling}>
                  <InfoCircleOutlined />
                </Tooltip>
                <div className={`${props.className}`}>
                  <TreeSelect
                    allowClear={true}
                    className={'network-global-families'}
                    labelInValue={true}
                    name="global_events_categories"
                    onChange={updateGlobalFamily}
                    placeholder={'Select Category'}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    showSearch={true}
                    treeCheckable={true}
                    dropdownStyle={{
                      backgroundColor: '#212121',
                    }}
                    treeData={formattedGlobalCategory}
                    value={globalFamilyTreeValue}
                    filterTreeNode={(search, option) => {
                      return (
                        option.title
                          .toString()
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                </div>
                <br></br>
              </>
            )}
            {(includedChannels === undefined || includedChannels.includes('INDUSTRY_COMMODITY')) && (
              <>
                <label>Industry </label>
                <Tooltip title=" Incidents that are relevant to a given industry" overlayInnerStyle={toolTipStyling}>
                  <InfoCircleOutlined />
                </Tooltip>
                <div className={`${props.className}`}>
                  <Global
                    styles={css`
                      .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
                        background-color: #2d8fa5 !important;
                        border-color: #2d8fa5 !important;
                      }
                    `}
                  />{' '}
                  <TreeSelect
                    allowClear={true}
                    className={'network-subindustry'}
                    name="industries"
                    onChange={updateSubIndustries}
                    placeholder={'Select Industries'}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    showSearch={true}
                    treeCheckable={true}
                    treeData={formattedIndustries}
                    value={user_profile_industries}
                    dropdownStyle={{
                      backgroundColor: '#212121',
                    }}
                    filterTreeNode={(search, option) => {
                      return (
                        option.title
                          .toString()
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                </div>
              </>
            )}
          </ConfigProvider>
          <br></br>
          <label>Keyword </label>
          <Tooltip
            title="Add up to 5 keywords to receive incidents that match the keyword entry in either the incident’s title or description, which will be delivered via the Industry notification channel."
            overlayInnerStyle={toolTipStyling}
          >
            <InfoCircleOutlined />
          </Tooltip>
          <div>
            <KeywordSelectors value={keywords || []} onChange={updateKeywords} />
          </div>
        </div>
      </div>
    </div>
  );
})`
  &.dark {
    background-color: #131c1e;

    h1,
    h2,
    h3,
    p,
    div {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.contentTitle}px;
    color: ${props => props.theme.colors.white};
    background-color: #141414;
  }
  .network-global-families {
    background-color: #2c2c2c;
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    display: block;
    width: 25%;
    margin: 0 0 10px;
  }
  .ant-picker-input > input {
    color: #ffffff;
  }

  .dropdown-labels {
    margin-top: 20px;
    padding: 0 0 0 20px;
    width: 80%;
  }
  .filters-apply {
    bottom: 30px;
    left: 10%;
    position: absolute;
    width: 80%;
  }
  .ant-select {
    background-color: #2c2c2c;
    color: #919191;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
  }
  .ant-tree-select {
    display: block;
    width: 50%;
  }
  .ant-select-tree,
  .ant-select-dropdown,
  .ant-tree-select-dropdown,
  .ant-tree-select-dropdown .ant-select-tree,
  .ant-select-multiple .ant-select-selection-item {
    background-color: #40424e;
    border: 1px solid #54596e;
    color: #ccc5b8;
    font-size: 85%;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #cccccc;
  }
  .loader {
    color: ${props => props.theme.colors.white};
  }
  button.untracked-switch {
    border: solid #2d8fa5;
    border-radius: 35px;
    padding: 0px;
    margin-top: 1px;
  }
  .switch-label {
    margin-left: 20px;
  }
  .ant-switch-handle {
    left: 2px;
    width: 12px;
    height: 12px;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px);
  }
  .ant-switch-checked {
    background-color: #2d8fa5;
    border-radius: 40px;
    border-color: #2d8fa5;
  }

  label {
    color: ${props => props.theme.colors.white};
  }
  .ant-radio-button-checked {
    border: 1px solid ${props => props.theme.colors.primary};
  }
  .ant-select-clear {
    background-color: #141414;
  }
`;

export default MyNetworkView;
