import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Select } from 'antd';

import PhenomenaTable from './Table';
import { updateFamily, trackWorkFlowChange } from 'admin/workflows/modules/workflows';

import styled from '@emotion/styled';
import { RightOutlined } from '@ant-design/icons';
import { sortRiskCategoryData } from 'admin/workflows/modules/utils';

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    border: 2px solid;
  }
  .ant-select-selection-item {
    background-color: ${props => props.theme.colors.primary} !important;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + span {
    border-color: ${props => props.theme.colors.white} !important;
    border: 2px solid;
  }

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} !important;
    background-color: ${props => props.theme.colors.primary} !important;
  }

  input[type='checkbox'] + span {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.white};
    &:focus {
      border-color: white !important;
    }
    &:disabled {
      background-color: ${props => props.theme.colors.disabled} !important;
    }
  }
`;

const familyPanel = ({ family, updateFamily, trackWorkFlowChange, className }) => {
  // DONT use useState, use family visible to make toggle icon
  const value = ['medium', 'high'];
  const op = ['low', 'medium', 'high'];
  const children = op.map((x, i) => {
    return (
      <Option key={i} value={x}>
        {x}
      </Option>
    );
  });
  const indeterminate = family.status === 'some_selected';
  const checkAll = family.status === 'all_selected';

  const toggleVisibility = () => {
    updateFamily(family.id, { visible: !family.visible });
  };

  const phenomena = <PhenomenaTable family={family} status={family.status} />;

  const content = family.visible ? <div className="content">{phenomena}</div> : null;

  const handleChange = value => {
    trackWorkFlowChange(true);
    updateFamily(family.id, { riskCategories: value });
  };

  const select =
    family.status === 'all_selected' ? (
      <StyledSelect
        mode="multiple"
        defaultValue={value}
        style={{ width: '300px' }}
        value={family?.riskCategories?.length ? sortRiskCategoryData(family?.riskCategories) : []}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </StyledSelect>
    ) : null;

  const clickCheckbox = e => {
    e.stopPropagation();
    const riskCategories = family.status === 'all_selected' ? [] : ['medium', 'high'];
    trackWorkFlowChange(true);
    updateFamily(family.id, { riskCategories });
  };

  return (
    <div className={className}>
      <div className="header" onClick={toggleVisibility}>
        <div className="title">
          <RightOutlined rotate={family.visible ? 90 : 0} style={{ margin: '10px' }} />
          {family.name}
        </div>
        <div className="widgets">{select}</div>

        <div className="checkbox">
          <StyledCheckbox
            indeterminate={indeterminate}
            checked={checkAll}
            className="checkbox"
            onClick={clickCheckbox}
          />
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
};
const FamilyPanel = styled(familyPanel)`
  & {
    margin-bottom: 15px;
  }
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    height: 40px;
    background-color: ${props => props.theme.colors.black3};
    cursor: pointer;

    & > .title {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
    }

    & > .widgets {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      text-align: center;
      padding-top: 4px;
    }

    & > .checkbox {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      line-height: 40px;
      text-align: right;
      padding-right: 15px;
    }
  }
`;

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateFamily, trackWorkFlowChange }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyPanel);
