import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationTemplates } from 'modal/userprofile/modules/network';
import { updateAccount } from 'account/modules/accountOrg';
import styled from '@emotion/styled';
import LoadingScreen from 'layout/containers/LoadingScreen';
import useMixpanel from 'utils/hooks/Mixpanel';
import { Select } from '@luxe/components';

const NotificationTemplateView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { accountOrg } = useSelector(store => store.accountOrg);
  const { notificationTemplates, status } = useSelector(store => store.userNetwork);
  const [formattedNotificationTemplates, setFormattedNotificationTemplates] = useState([
    { label: 'Default', value: null },
  ]);
  useEffect(() => {
    const populateChild = arr => {
      return [
        ...formattedNotificationTemplates,
        ...arr.map(i => {
          return {
            label: i.name,
            value: i.id,
          };
        }),
      ];
    };

    if (notificationTemplates?.length > 0 && formattedNotificationTemplates.length === 1) {
      setFormattedNotificationTemplates(populateChild(notificationTemplates));
    }
  }, [formattedNotificationTemplates, formattedNotificationTemplates.length, notificationTemplates]);

  useEffect(() => {
    if (!notificationTemplates) {
      dispatch(getNotificationTemplates());
    }
  }, [notificationTemplates, dispatch]);

  const updateSubIndustries = notificationTemplates => {
    mixpanelTrack('Admin Notification Template Preference Change');
    dispatch(updateAccount({ preferred_notification_template: notificationTemplates.value ?? null, update: true }));
    dispatch(getNotificationTemplates());
  };
  if (notificationTemplates?.length === 0 && status !== 'SUCCESS') {
    return (
      <div className={'loader'}>
        <LoadingScreen modal={true} />
      </div>
    );
  }
  return (
    <div className={props.className}>
      <div className={`notificationtemplate-control`}>
        <h3>Email Notification Template Configuration</h3>
        <h4>Template</h4>
        <div className={`${props.className}`}>
          <Select
            options={formattedNotificationTemplates}
            name="preferred_notification_template"
            width={'100%'}
            isMulti={false}
            onChange={updateSubIndustries}
            value={
              accountOrg.preferred_notification_template
                ? formattedNotificationTemplates.filter(f => f.value === accountOrg.preferred_notification_template)[0]
                : formattedNotificationTemplates[0]
            }
          />
        </div>
      </div>
    </div>
  );
})`
  .notificationtemplate-control {
    h3,
    h4 {
      color: white;
      font-weight: normal;
    }
    padding: 24px;
    width: 50%;
  }
`;
export default NotificationTemplateView;
