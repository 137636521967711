import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Select } from 'layout/Select';
import { updateWorkflow, trackWorkFlowChange } from 'admin/workflows/modules/workflows';
import { getOptions } from 'views/modules/options';

const GroupSelect = ({ value, groups, updateWorkflow, trackWorkFlowChange, getOptions, ...props }) => {
  useEffect(() => {
    const config = {
      name: 'recipients_groups',
      url: 'user-groups/?q=',
      valueKey: 'id',
      labelKey: 'name',
    };
    getOptions(config, '');
  }, [getOptions]);

  let items = [];
  const defaultGroup = { label: 'All Users', value: '*' };
  if (groups && groups.loading === false) {
    items = [defaultGroup, ...groups.options];
  }

  const onChange = groups => {
    groups = groups || [];
    const { recipients = [] } = props.workflows.workflow.data;
    const type = 'user_group_id';
    trackWorkFlowChange(true);
    updateWorkflow({
      data: {
        recipients: [
          ...recipients.filter(x => x.type !== type),
          ...groups.map(({ value, label }) => {
            return { type, value, label };
          }),
        ],
      },
    });
  };
  const isLoading = groups === undefined || groups.loading;
  return (
    <Select
      isMulti
      defaultValue={props.workflows.workflow.data.recipients.length === 0 ? defaultGroup : null}
      isLoading={isLoading}
      selected={value}
      onChange={onChange}
      options={items}
    />
  );
};

function mapStateToProps({ options, workflows }) {
  return {
    workflows,
    groups: options['recipients_groups'],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getOptions, updateWorkflow, trackWorkFlowChange }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSelect);
