import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',

  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',

  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
};

export const getProduct = productId =>
  callApi({
    types: {
      pending: TYPES.GET_PRODUCT,
      success: TYPES.GET_PRODUCT_SUCCESS,
      error: TYPES.GET_PRODUCT_ERROR,
    },
    request: () => axios.get(`materials/${productId}/`),
  });

export const deleteProduct = productId =>
  callApi({
    types: {
      pending: TYPES.DELETE_PRODUCT,
      success: TYPES.DELETE_PRODUCT_SUCCESS,
      error: TYPES.DELETE_PRODUCT_ERROR,
    },
    request: () => axios.delete(`materials/${productId}/`),
    messages: {
      success: 'Product has been successfully deleted!',
      error: 'Product deletion unsuccessful',
    },
  });

export const updateProduct = (productId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_PRODUCT,
      success: TYPES.UPDATE_PRODUCT_SUCCESS,
      error: TYPES.UPDATE_PRODUCT_ERROR,
    },
    request: () => axios.put(`materials/${productId}/`, data),
    messages: {
      success: 'Product has been successfully edited!',
      error: 'Product update unsuccessful',
    },
  });

export const INITIAL_STATE = {
  status: undefined,
  products: [],
  error: undefined,
  params: undefined,
};

export const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_PRODUCT:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Product: action.payload,
        error: undefined,
      };
    case TYPES.GET_PRODUCT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_PRODUCT:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_PRODUCT_SUCCESS:
      const deletedProduct = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: state.products.filter(product => product.id !== deletedProduct.id),
        error: undefined,
      };
    case TYPES.DELETE_PRODUCT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_PRODUCT:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};
