import React from 'react';
import { EditIcon } from '@luxe/components';
import { Link } from 'react-router-dom';

export const getEditLink = material => {
  let url = '/admin/materials/' + material.id + '/';
  return (
    <Link to={url}>
      <EditIcon />
    </Link>
  );
};
