import React, { useEffect, useState } from 'react';

import { ActiveThreatContainer } from '../styles';
import { Row } from 'antd';
import { getData } from 'assets/modules/utils';
import { useSelector } from 'react-redux';

export const ActiveThreats = ({ risk, asset, isEkg }) => {
  const [threatIncidentCount, setThreatIncidentCount] = useState('-');
  const [trackedRisksCount, setTrackedRisksCount] = useState('-');
  const [pendingFeedbackCount, setPendingFeedbackCount] = useState('-');
  const [dependentProducts, setDependentProducts] = useState(null);

  const materialOrgPref = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.materials?.enabled;
  const views = useSelector(store => store.views);
  const materialFlowItems = views?.['material-flows']?.items;

  useEffect(() => {
    if (risk && asset) {
      const threatCount = asset.risk?.threats?.length;
      setThreatIncidentCount(threatCount >= 0 ? threatCount : '-');
      setPendingFeedbackCount(asset.pending_feedback_count >= 0 ? asset.pending_feedback_count : '-');
      if (risk.indices?.length > 0) {
        const count = risk.indices.reduce((total, riskIndex, currentIndex) => {
          const data = getData(riskIndex, risk, riskIndex);
          return total + data.length;
        }, 0);
        setTrackedRisksCount(count || '-');
      }
    }
  }, [risk, asset]);

  useEffect(() => {
    if (materialFlowItems) {
      const productMap = materialFlowItems.map(item => item.product).filter(item => item);
      setDependentProducts([...new Set(productMap)]);
    }
  }, [views, materialFlowItems]);

  return (
    <Row>
      <ActiveThreatContainer>
        <h3>Active Incidents</h3>
        <div className="score-values">{threatIncidentCount}</div>
      </ActiveThreatContainer>
      <ActiveThreatContainer>
        <h3>Tracked Risks</h3>
        <div className="score-values">{trackedRisksCount}</div>
      </ActiveThreatContainer>
      <ActiveThreatContainer>
        <h3>Pending Feedback</h3>
        {pendingFeedbackCount > 0 && threatIncidentCount === 0 ? (
          <div className="no-feedback-text">
            No feedback
            <br />
            provided
          </div>
        ) : (
          <div className="score-values">{pendingFeedbackCount}</div>
        )}
      </ActiveThreatContainer>
      {materialOrgPref && !isEkg ? (
        <>
          <ActiveThreatContainer>
            <h3>Supplied Materials</h3>
            <div className="score-values">{materialFlowItems?.length >= 0 ? materialFlowItems.length : '-'}</div>
          </ActiveThreatContainer>
          <ActiveThreatContainer>
            <h3>Dependent Products</h3>
            <div className="score-values">{dependentProducts?.length >= 0 ? dependentProducts.length : '-'}</div>
          </ActiveThreatContainer>
        </>
      ) : null}
    </Row>
  );
};
