import React, { useEffect, useState } from 'react';
import { stringSorter } from 'utils';
import { Table } from '@luxe/components';
import { Button, Col, Row } from 'antd';
import { useSelector } from 'react-redux';

const AssetDiscoverSubInnerTable = ({ data, toggleNextDrilldown }) => {
  const [ds, setDataSource] = useState([]);
  const relationshipStrengthEnabled = useSelector(
    store => store.accountOrg?.accountOrg?.prefs?.discover?.relationship_strength_enabled,
  );

  useEffect(() => {
    if (data?.links?.length > 0) {
      setDataSource(
        data?.links?.map(l => {
          return {
            id: l.id,
            material: l.material__id,
            finalProduct: l.final_material__name,
            hsCode: l.hscode6,
            hsCodeDesc: l.hscode6_desc,
            relationshipStrength: l.relationship_strength,
          };
        }),
      );
    }
  }, [data]);

  const columns = [
    {
      title: 'Material',
      dataIndex: 'material',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.material, b.material),
      width: 100,
      align: 'center',
    },
    {
      title: 'Final Product',
      dataIndex: 'finalProduct',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.finalProduct, b.finalProduct),
      width: 125,
      align: 'center',
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCode, b.hsCode),
      width: 50,
      align: 'center',
    },
    {
      title: 'HS Code Description',
      dataIndex: 'hsCodeDesc',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCodeDesc, b.hsCodeDesc),
      width: 125,
      align: 'center',
    },
    relationshipStrengthEnabled && {
      title: 'Relationship Strength',
      dataIndex: 'relationshipStrength',
      render: nde => <span>{nde?.toFixed(2)}</span>,
      sorter: (a, b) => a.relationshipStrength - b.relationshipStrength,
      width: 50,
      align: 'center',
    },
  ].filter(e => e);

  return (
    <>
      <Col style={{ width: '100%', marginBottom: '10px' }}>
        <Row style={{ lineHeight: '40px' }}>
          <label style={{ margin: '0 0 10px 0', fontSize: '16px' }}>
            {data?.isInbound ? 'Inbound Materials from' : 'Outbound Materials to'}{' '}
            <strong>{data?.selectedNode?.name}</strong> {data?.isInbound ? 'to' : 'from'}{' '}
            <strong>{data?.primarySelectedNode?.name}</strong>
          </label>

          <Button
            onClick={() => toggleNextDrilldown()}
            style={{ backgroundColor: '#545454', position: 'absolute', right: '0', border: '0', padding: '0 6px' }}
          >
            Back
          </Button>
        </Row>
        <Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={ds}
            size="small"
            style={{ width: '100%', zIndex: '0', display: 'flex', flexDirection: 'column' }}
          />
        </Row>
      </Col>
    </>
  );
};

export default AssetDiscoverSubInnerTable;
