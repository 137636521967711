import React, { useState } from 'react';
import { Button } from '@luxe/components';
import { AboutScoreComponent, StyledModal } from './styles';

export const AboutScore = ({ riskComponent }) => {
  const isHTML = description => /<\/?[a-z][\s\S]*>/i.test(description);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!riskComponent?.summary && !riskComponent?.description) {
    return null;
  }

  return (
    <AboutScoreComponent>
      <div className="about-score-container">
        <h3>About Score:</h3>
        <p>{riskComponent.summary}</p>
        {riskComponent.description && (
          <Button
            variant="secondary"
            width="100px"
            style={{ margin: '0 auto' }}
            onClick={() => setIsModalVisible(true)}
          >
            Show More
          </Button>
        )}
      </div>
      <StyledModal
        visible={isModalVisible}
        title="About Score"
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width="700px"
        bodyWidth={'100%'}
      >
        <div className="modal-section">
          {isHTML(riskComponent.description) ? (
            <div className="modal-html" dangerouslySetInnerHTML={{ __html: riskComponent.description }} />
          ) : (
            <p>{riskComponent.description}</p>
          )}
        </div>
      </StyledModal>
    </AboutScoreComponent>
  );
};
