import React from 'react';
import PropTypes from 'prop-types';

export default class DistanceAtRiskFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  get labelClass() {
    if (this.props.value.high !== 0) {
      return 'risk-color-high';
    } else if (this.props.value.medium !== 0) {
      return 'risk-color-medium';
    } else {
      return 'risk-color-low';
    }
  }

  get label() {
    if (this.props.value.high !== 0) {
      return this.props.value.high;
    } else if (this.props.value.medium !== 0) {
      return this.props.value.medium;
    } else {
      return '—';
    }
  }

  get total() {
    const { value } = this.props;
    return value.high + value.medium + value.low;
  }

  getWidth(attr) {
    const { value } = this.props;
    const { total } = this;
    if (value.high === 0 && value.medium === 0 && attr === 'low') {
      return '100%';
    }

    if (total === 0) {
      return '0%';
    } else {
      const width = (value[attr] / this.total) * 100;
      return `${width}%`;
    }
  }

  inlineStyles(attr) {
    const background = { low: '#108904', medium: '#db992f', high: '#d32100' };
    return {
      width: this.getWidth(attr),
      backgroundColor: background[attr],
      display: this.props.value[attr] ? 'inline-block' : 'none',
    };
  }

  render() {
    return (
      <div id="risk-miles-container">
        <div className={this.labelClass} id="risk-bar-miles">
          {this.label}
        </div>
        <div id="risk-bar-container">
          <span className="flat-risk-high risk-bar" style={{ ...this.inlineStyles('high') }}>
            &nbsp;
          </span>
          <span className="flat-risk-medium risk-bar" style={{ ...this.inlineStyles('medium') }}>
            &nbsp;
          </span>
          <span className="flat-risk-low risk-bar" style={{ ...this.inlineStyles('low') }}>
            &nbsp;
          </span>
        </div>
      </div>
    );
  }
}
