import React from 'react';
import PropTypes from 'prop-types';
import { RiskBadge } from '@luxe/components';

export default class AssetScoreFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
  };

  render() {
    const assetScore = this.props.value[0] || {};
    const { score } = assetScore;
    let category = 'low';
    if (score >= 17 && score <= 25) {
      category = 'high';
    } else if (score >= 9 && score <= 16) {
      category = 'medium';
    }
    return assetScore.score ? (
      <RiskBadge key={`${assetScore.threat_id}-${assetScore.id}`} variant={category} count={assetScore.score} />
    ) : (
      <span>-</span>
    );
  }
}
