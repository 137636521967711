import React from 'react';
import RiskAndEconomicSection from './RiskAndEconomicSection';
import NetworkOverview from './NetworkOverview';

import { Row } from 'antd';
import { Section } from '@luxe/components';

const OverviewTab = props => {
  return (
    <Section rules={true}>
      <Row style={{ width: '100%' }}>
        <RiskAndEconomicSection />
      </Row>
      <NetworkOverview />
    </Section>
  );
};
export default OverviewTab;
