import React, { useEffect, useMemo, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { dateSorter, stringSorter } from 'utils';
import { getFormattedDate } from 'assets/modules/utils';
import { clearAssetFeedbackDetail, getAssetFeedbackDetail } from 'feedback/modules/feedback';
import { BasicModal, Empty, Table } from '@luxe/components';
import { Button } from 'antd';

const FeedbackDetailButton = ({ assetId, threat }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <FeedbackDetailModal assetId={assetId} threat={threat} closeModal={() => setShowModal(false)} />}
      <ExportOutlined onClick={() => setShowModal(true)} style={{ color: '#2D8FA5' }} />
    </>
  );
};

const FeedbackDetailModal = styled(({ assetId, threat, closeModal, className }) => {
  const details = useSelector(state => state.feedback?.feedbackDetail || {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetFeedbackDetail(assetId, threat?.id));
    return () => dispatch(clearAssetFeedbackDetail());
  }, [dispatch, assetId, threat]);

  const columns = useMemo(() => {
    const cols = [
      {
        title: 'Status',
        dataIndex: 'data',
        render: data => (!!Object.keys(data ?? {}).length ? 'Recieved' : 'Requested'),
        sorter: (a, b) => !!Object.keys(a?.data ?? {}).length - !!Object.keys(b?.data ?? {}).length,
      },
    ];
    cols.push(
      ...(details?.template?.attributes || []).map(col => ({
        title: col.name,
        dataIndex: ['data', col.attr_name],
        render:
          col.field_type === 'bool'
            ? d => (d === false ? 'No' : d ? 'Yes' : '-')
            : col.field_type === 'datetime'
            ? d => (d ? getFormattedDate(d, 'DD-MM-YYYY') : '-')
            : d => d ?? '-',
        sorter:
          col.field_type === 'bool'
            ? (a, b) => a?.data?.[col.attr_name] - b?.data?.[col.attr_name]
            : col.field_type === 'datetime'
            ? (a, b) => dateSorter(a?.data?.[col.attr_name], b?.data?.[col.attr_name])
            : (a, b) => stringSorter(a?.data?.[col.attr_name], b?.data?.[col.attr_name]),
      })),
    );
    cols.push(
      {
        title: 'Provided On',
        dataIndex: 'created_date',
        render: data => (data ? getFormattedDate(data, 'DD-MM-YYYY') : '-'),
        sorter: (a, b) => dateSorter(a?.created_date, b?.created_date),
      },
      {
        title: 'Provider',
        dataIndex: ['user', 'name'],
        render: data => data ?? '-',
        sorter: (a, b) => stringSorter(a?.user?.name, b?.user?.name),
      },
    );
    return cols;
  }, [details]);

  return (
    <BasicModal
      visible
      closable
      distroyOnClose
      title={`Feedback for ${threat?.name}`}
      className={className}
      width={'max-content'}
      onCancel={closeModal}
      footer={[
        <Button key="close" onClick={closeModal}>
          Close
        </Button>,
      ]}
    >
      <div>
        Incident: &emsp;<a href={threat?.base_url ?? '#'}>{threat?.headline ?? threat?.name}</a>
      </div>
      <Table
        description="No matching records found"
        table="feedback-details-table"
        className={className}
        rowKey={row => row.created_date + row.modified_date}
        columns={columns}
        dataSource={details?.responses || []}
        tableSize="small"
        locale={{ emptyText: <Empty description="No Data" /> }}
        pagination={{ pageSize: 5 }}
      />
    </BasicModal>
  );
})`
  top: 75px;
  .ant-modal-close-x {
    font-size: 22px;
    line-height: 22px;
    padding: 16px 0 0 0;
  }
  .ant-modal-header {
    padding: 16px 16px 0;
    .ant-modal-title {
      font-size: 22px;
      text-align: center;
    }
  }
  .ant-modal-body {
    padding: 32px 16px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.6em;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 30px;
      border: 1px solid black;
    }
  }
  .ant-modal-footer {
    padding: 6px 16px 16px;
    display: flex;
    justify-content: end;
    .ant-btn-default {
      background-color: #2d8fa5 !important;
      border: none;
    }
  }
`;

export default FeedbackDetailButton;
