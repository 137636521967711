import { selectNodeThroughSearch } from 'discover/modules/viz';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMixpanel from 'utils/hooks/Mixpanel';

const { AutoComplete } = require('common/components/AutoComplete');

const AssetSearchBar = props => {
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState('');
  const searchFacilities = useSelector(state => state.graphs.search_facilities);
  const selectedNode = useSelector(state => state.networkGraph?.selectedNode);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();

  useEffect(() => {
    if (selectedNode?.name === keyword) return;
    setOptions(
      (searchFacilities ?? [])
        .filter(facility => (keyword ? facility.name?.toUpperCase().includes(keyword.toUpperCase()) : false))
        .map(facility => {
          let start = facility.name.toUpperCase().indexOf(keyword.toUpperCase());
          if (start < 0) {
            return { value: facility.name, id: facility.id, label: facility.name };
          }
          return {
            value: facility.name,
            id: facility.id,
            label: (
              <>
                {facility.name.substring(0, start)}
                <span className="search-hit">{facility.name.substring(start, start + keyword.length)}</span>
                {facility.name.substring(start + keyword.length)}
              </>
            ),
          };
        }),
    );
  }, [searchFacilities, keyword, selectedNode]);

  useEffect(() => {
    setKeyword(selectedNode?.name || '');
  }, [selectedNode]);

  const handleSearch = value => {
    setKeyword(value);
  };

  const handleChange = (_, option) => {
    mixpanelTrack('Network button view - Search');
    dispatch(selectNodeThroughSearch(option.id));
    setKeyword(option.name);
  };

  return (
    <AutoComplete
      className={props.className}
      placeholder="Search facilities"
      value={keyword}
      options={options}
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};

export default AssetSearchBar;
