import React from 'react';
import styled from '@emotion/styled';

import { AutoComplete as AntAutoComplete, Input } from 'antd';

export const AutoComplete = styled(props => (
  <AntAutoComplete
    {...{
      dropdownClassName: 'auto-complete-dropdown',
      backfill: true,
      ...props,
      options: props.options.map((option, i) => ({ key: i, ...option })),
      placeholder: undefined,
    }}
  >
    <Input.Search className="search-input" placeholder={props.placeholder} />
  </AntAutoComplete>
))`
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .search-input {
    box-shadow: inset 0 1px 1px #000, 0 0 6px #333;
    border-radius: 4px;
    input,
    button {
      border: 1px solid #000 !important;
      background-color: #1a1a1a;
      color: #eeeeee;
      box-shadow: none;
    }
    input {
      border-radius: 4px;
      border-right: 0 !important;
      ::placeholder {
        color: #454e60;
      }
    }
    .ant-input-group-addon {
      background-color: transparent;
      button {
        border-radius: 4px !important;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-left: 0 !important;
        color: #8a96b2 !important;
      }
    }
  }
`;
