import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment';
import { severityLevels } from 'constants/application';

const ThreatData = styled(({ ...props }) => {
  const threat = useSelector(store => store.feedback.item.threat);
  const severity = useSelector(store => store.feedback.item.threat.severity);
  const area_description = useSelector(store => store.feedback.item.threat.area_description);

  const getAreaDescription = () => {
    return (
      <div>
        <EnvironmentOutlined />
        {area_description}
      </div>
    );
  };

  const getIcon = () => {
    return (
      <>
        <img src={severityLevels[severity]} alt={severity} />
        {severity}
      </>
    );
  };

  return (
    <div className={props.className}>
      <p>{threat.phenomena.name}</p>
      {severity in severityLevels && getIcon()}
      {area_description && getAreaDescription()}
      <dl>
        <dt>Date of Occurrence:</dt>
        <dd>{moment(threat.start).format('MM-DD-YYYY')}</dd>
        <dt>Last Updated:</dt>
        <dd>{moment(threat.modified_date).format('MM-DD-YYYY')}</dd>
      </dl>
    </div>
  );
})`
  img {
    margin-right: 10px;
  }
`;

export default ThreatData;
