import { Risk, RiskChart, Score, Title } from '../styles';

import React from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';

const CountryRiskStatus = () => {
  const { region = {} } = useSelector(store => store.regions);
  const { proactive_scores = [] } = region;

  const risks = {
    overall: {
      name: 'OVERALL RISK',
      score: '-',
      category: '',
    },
  };

  if (proactive_scores.length > 0) {
    const cra_risk = proactive_scores[0];
    risks.overall.score = cra_risk.score || '-';
    risks.overall.category = cra_risk.category;
  }

  return (
    <RiskChart>
      <Space size={150} direction={'horizontal'} align={'center'}>
        <div>
          <Space size={4} direction={'horizontal'}>
            <Risk>
              <Title>{risks.overall.name}</Title>
              <Score category={risks.overall.category}>{risks.overall.score}</Score>
              <Title>{risks.overall.category}</Title>
            </Risk>
          </Space>
        </div>
      </Space>
    </RiskChart>
  );
};

export default CountryRiskStatus;
