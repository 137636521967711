import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { Typography } from 'antd';

import { useTheme } from 'emotion-theming';
import { Button, BasicModal as Modal } from '@luxe/components';
import { cancelWorkflowChanges, trackWorkFlowChange } from 'admin/workflows/modules/workflows';

const { Text } = Typography;

const CancelButton = ({ cancelWorkflowChanges, trackWorkFlowChange, push, ...props }) => {
  const { colors, fontSizes } = useTheme();
  let [visible, setVisible] = useState(false);
  const onOk = () => {
    trackWorkFlowChange(false);
    cancelWorkflowChanges();
    push(props.cancelUrl);
  };
  return (
    <>
      <Button className={'cancel profile-button'} type="link" onClick={() => setVisible(!visible)}>
        Cancel
      </Button>
      <Modal
        visible={visible}
        title="Cancel Workflow Changes?"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => setVisible(!visible)}
        footer={[
          <Button key={'modal-onCancel-workflow'} onClick={() => setVisible(!visible)}>
            No
          </Button>,
          <Button key={'modal-onSave-workflow'} onClick={onOk}>
            Yes
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          Are you sure you want to cancel your changes?
        </Text>
      </Modal>
    </>
  );
};
function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cancelWorkflowChanges,
      push,
      trackWorkFlowChange,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
