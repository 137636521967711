import React from 'react';
import styled from '@emotion/styled';
import MiniMap from './MiniMap';
import AssetDrawerThreats from './AssetDrawerThreatScores';
import AssetDrawerMeta from './AssetDrawerMeta';
import { Button } from '@luxe/components';
import { Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const DrawerInformation = styled(
  ({
    className,
    scoreCardURL,
    showMapURL,
    actions,
    location,
    type,
    countryName,
    name,
    threatScores,
    meta,
    closeDrawer,
    subtitle,
    isEkg,
    tierLevels,
    selectedNode,
    isAssetDrawer,
  }) => {
    const materialsEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.materials?.enabled);
    const discoverEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled);
    const commodityEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled);

    const ActionsMenu = (
      <Menu className="drawer-action-menu">
        {actions?.map(action => {
          return (
            <Menu.Item key={`action-${action.title}`} onClick={action.action ?? (() => {})}>
              {action.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const ekgMetaData = {
      Tier: tierLevels,
      Address: selectedNode?.address || '',
      City: selectedNode?.city || '',
      'State/Province': selectedNode?.state_province || selectedNode?.state || '',
      'Postal Code': selectedNode?.zipcode || selectedNode?.postal_code || '',
      Country: countryName,
    };

    if ((materialsEnabled || discoverEnabled) && commodityEnabled && isEkg) {
      try {
        if (isAssetDrawer && meta?.Commodities?.length) {
          ekgMetaData['Commodities'] = meta?.Commodities;
        }
        if (!isAssetDrawer && selectedNode?.commodities?.length) {
          ekgMetaData['Commodities'] = selectedNode.commodities
            .map(val => {
              if (typeof val === 'string') {
                return val;
              } else {
                return val.name;
              }
            })
            .join(', ');
        }
      } catch (err) {}
    }

    return (
      <div className={className}>
        <div className="drawer-ux">
          {location?.latitude && <MiniMap {...{ location }} />}
          {scoreCardURL && (
            <Button className="btn btn-score" href={scoreCardURL}>
              Open Scorecard
            </Button>
          )}
          {actions && (
            <Dropdown overlay={ActionsMenu} className="btn btn-actions" trigger={['click']}>
              <Button>
                Actions
                <span className="caret" />
              </Button>
            </Dropdown>
          )}
          {showMapURL && (
            <Button className="btn btn-map" href={showMapURL}>
              Show on Map
            </Button>
          )}
          <div></div>
        </div>
        <div className="drawer-data">
          <div className="drawer-data-header">
            {type && (
              <span className="drawer-type">{`${isEkg ? 'Discovered ' : ''}
                ${_.capitalize(type.replaceAll('_', ' '))}`}</span>
            )}
            {countryName && <span className="drawer-location">{countryName}</span>}
            <span className="drawer-close" onClick={closeDrawer}>
              X
            </span>
          </div>
          <h2>{name}</h2>
          {threatScores && <AssetDrawerThreats threatScores={threatScores} />}
          {subtitle && (
            <div className="subtitle-section">
              <div className="subtitle">{subtitle}</div>
            </div>
          )}
          {meta && !isEkg && <AssetDrawerMeta meta={meta} />}
          {isEkg && <AssetDrawerMeta meta={ekgMetaData} />}
        </div>
      </div>
    );
  },
)`
  background-color: #212121;
  bottom: 0;
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;

  .drawer-ux {
    flex: 1;
    padding: 0 15px 0 10px;
  }

  .drawer-data {
    flex: 3;

    .drawer-data-header {
      position: relative;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 30px;
      padding-right: 25px;

      .drawer-type {
        background-color: #000000;
        border: 1px solid #246472;
        border-radius: 2ch;
        padding: 3px 6px;
      }

      .drawer-close {
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }

    h2 {
      color: #ffffff;
    }
    .subtitle-section {
      display: flex;
      flex-direction: row-reverse;
    }
    .subtitle {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
      text-align: right;
      margin-bottom: 8px;
      width: 50%;
    }
  }
  .btn-score,
  .btn-actions {
    color: #eeeeee;
    background-color: #2d8fa5;
    border-color: #2d8fa5;
    width: 100%;
    border-radius: 6px;
    padding: 6px;
    margin-bottom: 8px;
    max-width: 192px;
  }
  .btn-map {
    color: #eeeeee;
    background-color: #545454;
    border-color: #545454;
    width: 100%;
    border-radius: 6px;
    padding: 6px;
    margin-bottom: 8px;
    max-width: 192px;
  }
  .btn-actions {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .caret {
      display: inline-block;
      width: 0px;
      height: 0px;
      border-top: 4px dashed;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: absolute;
      right: 8px;
    }
  }
  .btn-actions {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .caret {
      display: inline-block;
      width: 0px;
      height: 0px;
      border-top: 4px dashed;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: absolute;
      right: 8px;
    }
  }
`;

export default DrawerInformation;
