import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Typography, Form, Tooltip } from 'antd';
import { AppstoreOutlined as GripIcon, EditOutlined } from '@ant-design/icons';
import { useTheme } from 'emotion-theming';
import { Numeric, BasicModal as Modal, Button } from '@luxe/components';
import RiskScoreComponentForm from './RiskScoreComponentForm';
import {
  deleteRiskComponent,
  updateRiskComponent,
  reorderRiskComponent,
  createRiskScore,
} from 'admin/riskmodels/modules/riskmodels';

const { Text } = Typography;

const RiskScoreComponent = ({
  key,
  label,
  weight,
  attribute,
  deleteRiskComponent,
  updateRiskComponent,
  reorderRiskComponent,
  indexArray,
  createRiskScore,
  ruleType: initialRuleType,
  rule,
  isEditable,
  showWeightedScoreElement,
}) => {
  const [form] = Form.useForm();
  const { space, colors, itemAlignment } = useTheme();
  const { RiskModelScores } = useSelector(store => store.riskModels);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [riskModelScore, setRiskModelScore] = useState(null);
  const [ruleType, setRuleType] = useState(initialRuleType);
  const lastRuleType = useRef(initialRuleType);

  useEffect(() => {
    const allRiskModelScores = RiskModelScores?.flatMap(scores => scores.riskModelScores) || [];
    const rmScore = allRiskModelScores.find(score => {
      return score.id === rule.id;
    });

    if (rmScore) {
      setRiskModelScore(rmScore);
    }
  }, [RiskModelScores, label, rule.id]);

  const onCreate = values => {
    setIsModalVisible(false);
    const formResponse = {
      name: values.name,
      weight: values.weight,
      rule_type: values.rule_type,
      rule: rule,
      datasource_id: riskModelScore.datasource_id,
    };
    lastRuleType.current = values.rule_type;
    if (values.rule_type === 'user_question') {
      const { question, choices } = values;
      formResponse.rule = {
        options: {
          question: question,
          attribute: rule.options.attribute,
          model: rule.options.model,
        },
        values: choices,
      };
    }

    formResponse.rule.id = riskModelScore.id;

    createRiskScore(indexArray, formResponse);
  };

  const deleteRiskScore = () => {
    if (deleteRiskComponent) {
      deleteRiskComponent(indexArray, rule.id);
    }
  };

  const editRiskScore = value => {
    updateRiskComponent(indexArray, { weight: value });
  };

  const handleOnDrop = e => {
    e.preventDefault();
    const { reorderComponent, draggedIndexArray } = JSON.parse(e.dataTransfer.getData('data'));
    if (reorderComponent) {
      reorderRiskComponent({
        indexArray: indexArray,
        fromIndex: draggedIndexArray.pop(),
        toIndex: indexArray.pop(),
      });
    }
  };

  const handleOnDragOver = e => {
    e.preventDefault();
  };

  const handleOnDragStart = e => {
    const data = {
      name: label,
      attribute: attribute,
      weight: weight,
      rule: rule,
      rule_type: ruleType,
    };
    e.dataTransfer.setData(
      'data',
      JSON.stringify({
        reorderComponent: true,
        draggedIndexArray: indexArray,
        draggingComponent: true,
        panelData: data,
      }),
    );
  };

  const componentEdit = e => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <Row
        className="risk-score-components"
        draggable={isEditable ? 'true' : 'false'}
        onDragStart={handleOnDragStart}
        onDragOver={handleOnDragOver}
        onDrop={e => handleOnDrop(e)}
        style={{
          padding: '0',
          marginBottom: space.xsmall,
          backgroundColor: colors.BG1,
          height: '5.125em',
          paddingRight: `${space.medium}px`,
        }}
      >
        <Col span={1} style={{ backgroundColor: colors.BG4, ...itemAlignment.center, height: '5.125em' }}>
          <GripIcon style={{ color: `${colors.white}` }} />
        </Col>
        <Col span={23} style={{ alignSelf: 'center', lineHeight: `${space.medium}px`, display: 'flex' }}>
          <span
            style={{
              paddingLeft: '25px',
              flex: '1',
              marginTop: '5px',
              wordBreak: !label?.includes(' ') ? 'break-all' : 'break-word',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text>
              {label.toUpperCase()}
              {riskModelScore?.user_editable && (
                <Tooltip title="This is a user editable score">
                  <EditOutlined width="18px" height="18px" style={{ color: `${colors.disabled}`, marginLeft: '5px' }} />
                </Tooltip>
              )}
            </Text>
          </span>
          <div style={{ float: 'right', marginTop: '5px' }}>
            <Row style={{ width: '22vw', display: 'flex', justifyContent: 'flex-end' }}>
              <Col span={10}>
                {isEditable && (
                  <>
                    <Button variant="link" type="link" onClick={componentEdit}>
                      Edit
                    </Button>
                    <Button variant="link" type="link" onClick={deleteRiskScore}>
                      Delete
                    </Button>
                  </>
                )}
              </Col>
              <Col span={13}>
                {showWeightedScoreElement() && (
                  <Row
                    gutter={16}
                    justify="center"
                    align="middle"
                    style={{ justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <Col>
                      <Text>Weight %</Text>
                    </Col>
                    <Col>
                      <Numeric
                        name="score-weight"
                        key={`score-weight-${key}`}
                        value={weight}
                        onChange={editRiskScore}
                        style={{ width: '65px' }}
                        max={100}
                        min={0}
                        disabled={!isEditable}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          title="Edit Risk Score"
          okText="Save"
          cancelText="Cancel"
          onCancel={() => {
            form.resetFields();
            setRuleType(lastRuleType.current);
            setIsModalVisible(false);
          }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                onCreate(values);
              })
              .catch(info => {
                console.error('Validate Failed:', info);
              });
          }}
        >
          <RiskScoreComponentForm
            riskModelScore={riskModelScore}
            attribute={attribute}
            weight={weight}
            label={label}
            form={form}
            ruleType={ruleType}
            setRuleType={setRuleType}
            rule={rule}
            showWeightedScoreElement={showWeightedScoreElement}
          />
        </Modal>
      )}
    </>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteRiskComponent,
      updateRiskComponent,
      reorderRiskComponent,
      createRiskScore,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskScoreComponent);
