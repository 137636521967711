import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@luxe/components';
import { hideIncident } from '../../../modal/incidentfeed/modules/incidentsubscribe';

const Landingpage = ({ ...props }) => {
  const dispatch = useDispatch();
  const onConfirm = () => {
    dispatch(hideIncident(props.threatId, props.hashId));
  };

  return (
    <div className={`${props.className}`}>
      <div>
        {props.incident && (
          <div>
            <p>{props.incident?.headline}</p>
            <p> Do you want to untrack this incident?</p>
            <Button variant="primary" className="unsubscribe-button" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Landingpage;
