import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';
import { useTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';

import { BasicModal as Modal, Button } from '@luxe/components';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import { deletePlanPlaybook, getPlanPlaybooks } from 'admin/playbooks/modules/planPlaybooks';
import PlanPlaybookTable from './PlanPlaybookTable';

const PlanPlaybookListView = () => {
  const dispatch = useDispatch();
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [selectedPlanPlaybooks, setSelectedPlanPlaybooks] = useState([]);
  const { planPlaybooks } = useSelector(store => store.planPlaybooks);
  const status = useSelector(store => store.planPlaybooks.status);

  useEffect(() => {
    dispatch(getPlanPlaybooks());
  }, [dispatch]);

  const { space } = useTheme();

  const onDelete = () => {
    selectedPlanPlaybooks.forEach(playbook => dispatch(deletePlanPlaybook(playbook.id)));
    setSelectedPlanPlaybooks([]);
    setIsDeleteModalShowing(false);
  };

  const setSelectedPlaybooks = selectedIDs => {
    const selectedPlaybooks = [];
    planPlaybooks.forEach(playbook => {
      if (selectedIDs.includes(playbook.id)) {
        selectedPlaybooks.push({
          id: playbook.id,
          name: playbook.name,
        });
      }
    });
    setSelectedPlanPlaybooks(selectedPlaybooks);
  };

  const hasOnePlanPlaybookSelected = selectedPlanPlaybooks.length === 1;
  const selectedText = hasOnePlanPlaybookSelected
    ? `1 Item Selected`
    : `${selectedPlanPlaybooks.length} Items Selected`;

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view plan-playbooks">
      <Row gutter={[4, 8]}>
        <Col span={10} align="start">
          <h1>Plan Playbooks</h1>
        </Col>
        <Col span={14} align="end">
          {selectedPlanPlaybooks.length > 0 && (
            <>
              <span style={{ marginRight: '4px' }}>{selectedText}</span>
              <Button
                variant="danger"
                onClick={() => {
                  setIsDeleteModalShowing(true);
                }}
              >
                Delete
              </Button>
            </>
          )}
          <Link to="/admin/plan-playbooks/edit">
            <Button variant="primary" style={{ marginTop: '4px' }}>
              Create Playbook
            </Button>
          </Link>
        </Col>
      </Row>
      <Container>
        {planPlaybooks && (
          <PlanPlaybookTable
            planPlaybooks={planPlaybooks}
            status={status}
            setSelectedPlaybooks={setSelectedPlaybooks}
          />
        )}
      </Container>
      <DeletePlanPlaybookForm
        visible={isDeleteModalShowing}
        onCancel={() => {
          setIsDeleteModalShowing(false);
        }}
        onDelete={onDelete}
        selectedPlanPlaybooks={selectedPlanPlaybooks}
      />
    </ListViewContent>
  );
};

const DeletePlanPlaybookForm = ({ visible, onDelete, onCancel, selectedPlanPlaybooks }) => {
  const hasOnePlanPlaybookSelected = selectedPlanPlaybooks.length === 1;
  const title = `Are you sure you want to delete ${hasOnePlanPlaybookSelected ? 'this' : 'these'} Plan Playbook${
    hasOnePlanPlaybookSelected ? '' : 's'
  }?`;
  return (
    <Modal visible={visible} title={title} okText="Delete" cancelText="Cancel" onCancel={onCancel} onOk={onDelete}>
      Deleting a Plan Playbook means that it will no longer be available to populate plans across the platform. This
      action cannot be undone.
    </Modal>
  );
};

export default PlanPlaybookListView;
