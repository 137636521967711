import React from 'react';
import PropTypes from 'prop-types';

export default class ShipmentFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const { url } = this.props.value;
    return <a href={url}> {this.props.value.name}</a>;
  }
}
