import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { Avatar } from 'antd';
import { Skeleton } from '@luxe/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMixpanel from 'utils/hooks/Mixpanel';
import { push } from 'connected-react-router';
import { urlParam } from 'utils';

import { fetchEvents } from 'riskfeed/modules/riskfeed';
import { IncidentIcon } from 'riskfeed/modules/utils';
import { SearchInput } from '@luxe/components';
import { Switch } from 'antd';

import _ from 'lodash';

const PAGE_SIZE = 10;
var keyWords;
const Loading = () => {
  return [1, 2, 3, 4, 5, 6].map(i => (
    <div className="riskfeed-item" key={i}>
      <Skeleton avatar paragraph={2} />
    </div>
  ));
};

const Item = ({ item, index, channel }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { id = null } = useParams();

  const onClick = () => {
    mixpanelTrack('Action Center - Click Incident', {
      Incident: item.threat.id,
      SearchTerm: keyWords ? keyWords : null,
    });
    const url = `/risk-feed/threats/${item.threat.id}/overview/?channel=${channel}`;
    dispatch(push(url));
  };

  const classNameWrap = id === item.threat.id ? 'risk-item-wrap active' : 'risk-item-wrap';
  const className = id === item.threat.id ? 'riskfeed-item active' : 'riskfeed-item';
  return (
    <div style={{ padding: '14px', borderBottom: '1px solid #141414' }} className={classNameWrap}>
      <div key={index} onClick={onClick} className={className}>
        <div className="image">
          <Avatar>
            <IncidentIcon width="24px" height="24px" type={item.threat.icon} />
          </Avatar>
        </div>
        <div className="detail">
          <h3>{item.threat.headline}</h3>
          <p>{item.threat.description}</p>
        </div>
      </div>
    </div>
  );
};

const Items = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const { channel = 'NETWORK_SUMMARY' } = urlParam();
  const riskfeed = useSelector(state => state.riskfeed);
  const { events = { loading: true } } = riskfeed;
  const filters = events.meta?.current?.filters;
  const data = events.items;

  useEffect(() => {
    // Something is causing it to send undefined
    // and it was loading twice.. easiest fix:
    if (channel !== undefined && filters !== undefined) {
      let filterData = JSON.parse(JSON.stringify(filters));
      let categoryFilterIndex = filterData.findIndex(val => val.name === 'phenomena__family_id');
      if (categoryFilterIndex !== -1) {
        let categoryFilter = filterData[categoryFilterIndex];
        categoryFilter.value = categoryFilter.sendValue;
        delete categoryFilter['sendValue'];
      }
      dispatch(
        fetchEvents({
          query: keyWords,
          filters: filterData,
          limit: PAGE_SIZE,
          channel,
          inactiveChecked: props.inactiveChecked,
        }),
      );
    }
  }, [dispatch, filters, channel, props.inactiveChecked]);

  if (events.loading) {
    return (
      <section>
        <div className="riskfeed-items">
          <Loading />
        </div>
      </section>
    );
  }

  if (data.length === 0) {
    // TODO: Get better verbiage from Jason:
    return (
      <section>
        <div className="message">
          <h3>No Results</h3>
          <p>
            There are currently no incidents that fall into this channel. Visit
            <Link to="/users/network/"> Network Settings </Link>
            to adjust your configuration.
          </p>
        </div>
      </section>
    );
  }

  const loadMore = () => {
    const nextUrl = events.next;
    let sendFilterData = props.getCategoryIncludesFilter(filters);
    dispatch(
      fetchEvents({
        query: keyWords,
        filters: sendFilterData,
        nextUrl,
        channel,
        append: true,
        inactiveChecked: props.inactiveChecked,
      }),
    );
  };

  const hasMore = data && data.length % PAGE_SIZE === 0;
  return (
    <section className={`${props.className} riskfeed-items`}>
      <div id="riskfeed-items" style={{ height: 800, overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="riskfeed-items"
        >
          {data.map((item, index) => (
            <Item item={item} index={index} key={item.threat.id} channel={channel} />
          ))}
        </InfiniteScroll>
      </div>
    </section>
  );
})`
  .message {
    margin: 24px;
    padding: 24px;
  }

  &.riskfeed-items {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
  }

  #riskfeed-items {
    align-self: stretch;
    flex-grow: 1;
    overflow: auto;
    min-height: 100%;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #3a3a3a;
    }

    &::-webkit-scrollbar {
      padding-top: 4px;
      padding-bottom: 4px;
      width: 6px;
      background-color: #3a3a3a;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }
  }

  .riskfeed-item {
    display: flex;
    padding: 12px;
    padding-right: 24px;
    border-bottom: 1px solid rgba(20, 20, 20, 1);
    cursor: pointer;

    &:hover,
    &.active {
      background-color: #545454;
    }

    .image {
      width: 52px;
      .ant-avatar {
        width: 40px;
        height: 40px;
        background-color: #000;
        border: 1px solid #246472;

        svg {
          margin-top: 11px;
          g,
          g > g {
            fill: #fff;
            fill-opacity: 1;
          }
        }
      }
    }
    .detail {
      flex-grow: 1;

      h3 {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 12px;
        line-height: 20px;
        color: #c3c3c3;
        text-align: justify;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const List = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();

  const events = useSelector(state => state.riskfeed.events);
  const { channel = 'NETWORK_SUMMARY' } = urlParam();
  const filters = events.meta?.current?.filters;
  const [inactiveChecked, setInactiveChecked] = useState(false);

  const getCategoryIncludesFilter = filterArr => {
    try {
      let filterData = JSON.parse(JSON.stringify(filterArr));
      let categoryFilterIndex = filterData.findIndex(val => val.name === 'phenomena__family_id');
      if (categoryFilterIndex !== -1) {
        let categoryFilter = filterData[categoryFilterIndex];
        categoryFilter.value = categoryFilter.sendValue;
        delete categoryFilter['sendValue'];
      }
      return filterData;
    } catch (e) {
      return filterArr;
    }
  };

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce(searchTerm => {
        keyWords = searchTerm;
        mixpanelTrack('Action Center - Search', {
          Searchterm: searchTerm ? searchTerm : null,
        });
        let sendFilterData = getCategoryIncludesFilter(filters);
        dispatch(
          fetchEvents({
            query: searchTerm,
            filters: sendFilterData,
            limit: PAGE_SIZE,
            channel,
            inactiveChecked: inactiveChecked,
          }),
        );
      }, 350),
    [dispatch, filters, channel, mixpanelTrack, inactiveChecked],
  );

  const switchOnChange = checked => {
    setInactiveChecked(checked);
  };

  return (
    <div className={`${props.className} list`}>
      <header>
        <div className="action-center-title">
          <h1>Action Center</h1>
          <div className="dis-flex">
            <h3 className="mr-5">Inactive Incidents</h3>
            <Switch checked={inactiveChecked} onChange={switchOnChange} className="inactive-switch" />
          </div>
        </div>

        <SearchInput
          className="search-input"
          onChange={e => {
            debouncedChangeHandler(e.target.value);
          }}
        />
      </header>
      <Items inactiveChecked={inactiveChecked} getCategoryIncludesFilter={getCategoryIncludesFilter} />
    </div>
  );
})`
  & > {
    header {
      padding: 24px;

      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      .search-input {
        width: 100%;
      }
    }
  }
  & > section {
    padding-top: 8px;
    border-top: 1px solid #141414;

    .message {
      border: 1px solid #000;
      padding: 24px;
      margin: 24px;

      p {
        color: #c3c3c3;
      }
    }

    .riskfeed-item {
      cursor: pointer;
      display: flex;
      padding: 12px;
      padding-right: 24px;
      border-bottom: none;
    }
    .risk-item-wrap:hover,
    .risk-item-wrap.active {
      background-color: #545454;
    }
    .link-wrap {
      paddin-right: 24px;
      display: flex;
      justify-content: end;
      align-items: center;

      .link-item {
        color: deepskyblue;
        font-weight: bold;
      }
    }
  }
  .action-center-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dis-flex {
      display: flex;
    }

    .mr-5 {
      margin-right: 5px;
    }
  }
  .inactive-switch {
    background-color: #38b1cc !important;
  }
  .inactive-switch:focus {
    box-shadow: none;
  }
`;
export default List;
