import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_INCIDENT_HISTORY_PENDING: 'GET_INCIDENT_HISTORY_PENDING',
  GET_INCIDENT_HISTORY_SUCCESS: 'GET_INCIDENT_HISTORY_SUCCESS',
  GET_INCIDENT_HISTORY_ERROR: 'GET_INCIDENT_HISTORY_ERROR',

  GET_INCIDENT_PENDING: 'GET_INCIDENT_PENDING',
  GET_INCIDENT_SUCCESS: 'GET_INCIDENT_SUCCESS',
  GET_INCIDENT_ERROR: 'GET_INCIDENT_ERROR',

  GET_UNSUBSCRIBE_INCIDENT_DETAIL_PENDING: 'GET_UNSUBSCRIBE_INCIDENT_DETAIL_PENDING',
  GET_UNSUBSCRIBE_INCIDENT_DETAIL_SUCCESS: 'GET_UNSUBSCRIBE_INCIDENT_DETAIL_SUCCESS',
  GET_UNSUBSCRIBE_INCIDENT_DETAIL_ERROR: 'GET_UNSUBSCRIBE_INCIDENT_DETAIL_ERROR',

  SHARE_INCIDENT_PENDING: 'SHARE_INCIDENT_PENDING',
  SHARE_INCIDENT_SUCCESS: 'SHARE_INCIDENT_SUCCESS',
  SHARE_INCIDENT_ERROR: 'SHARE_INCIDENT_ERROR',
};

export const getIncidentHistory = ({ incidentId }) =>
  callApi({
    types: {
      success: TYPES.GET_INCIDENT_HISTORY_SUCCESS,
      pending: TYPES.GET_INCIDENT_HISTORY_PENDING,
      error: TYPES.GET_INCIDENT_HISTORY_ERROR,
    },
    request: () => axios.get(`threats/${incidentId}/revisions/`),
  });
export const getIncident = incidentId => {
  return callApi({
    types: {
      success: TYPES.GET_INCIDENT_SUCCESS,
      pending: TYPES.GET_INCIDENT_PENDING,
      error: TYPES.GET_INCIDENT_ERROR,
    },
    request: () => axios.get(`threats/${incidentId}/`),
  });
};
export const getUnsubscribeIncidentDetail = (incidentId, hash_obj) => {
  return callApi({
    types: {
      success: TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_SUCCESS,
      pending: TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_PENDING,
      error: TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_ERROR,
    },
    request: () => axios.get(`threats/${incidentId}/?hash=${hash_obj}`),
  });
};

export const shareIncident = (incidentId, data) =>
  callApi({
    types: {
      pending: TYPES.SHARE_INCIDENT_PENDING,
      success: TYPES.SHARE_INCIDENT_SUCCESS,
      error: TYPES.SHARE_INCIDENT_ERROR,
    },
    request: () => axios.post(`threats/${incidentId}/notify/`, data),
    messages: {
      success: 'Incident has been successfully shared!',
      error: 'Incident sharing unsuccessful',
    },
  });

export const INITIAL_STATE = {
  status: undefined,
  incidentHistory: [],
  incident: {},
  incidentDetail: {},
  error: undefined,
};

export const incidentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_INCIDENT_HISTORY_SUCCESS:
      return {
        ...state,
        incidentHistory: action.payload,
        error: undefined,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_INCIDENT_HISTORY_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        incidentHistory: [],
        params: action.payload,
      };
    case TYPES.GET_INCIDENT_HISTORY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_INCIDENT_SUCCESS:
      return {
        ...state,
        incident: action.payload,
        error: undefined,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_INCIDENT_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        incident: {},
        params: action.payload,
      };
    case TYPES.GET_INCIDENT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_SUCCESS:
      return {
        ...state,
        incidentDetail: action.payload,
        error: undefined,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        incidentDetail: {},
        params: action.payload,
      };
    case TYPES.GET_UNSUBSCRIBE_INCIDENT_DETAIL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
