import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Menu, MenuItem, SubMenu } from '@luxe/components';
import { BarsOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import theme from '../../../theme';
import { getAccountOrg } from 'account/modules/accountOrg';

const UserProfileMenu = ({ location, accountOrg, getAccountOrg }) => {
  let key = 'profile';
  const subMenus = ['profile-head'];

  let pathname = location.pathname.split('/');
  key = pathname[2];

  return (
    <ThemeProvider theme={theme}>
      <Menu mode="inline" selectedKeys={[key]} defaultOpenKeys={subMenus}>
        <SubMenu title="Profile" key="profile-head" className="crappola" icon={<BarsOutlined />}>
          <MenuItem key="profile">
            <Link to="/users/profile/">
              <span>General</span>
            </Link>
          </MenuItem>
          <MenuItem key="network">
            <Link to="/users/network/">
              <span>Network Settings</span>
            </Link>
          </MenuItem>
          <MenuItem key="notifications">
            <Link to="/users/notifications/">
              <span>Notifications</span>
            </Link>
          </MenuItem>
        </SubMenu>
      </Menu>
    </ThemeProvider>
  );
};

function mapStateToProps({ accountOrg, router }) {
  return {
    accountOrg,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAccountOrg: getAccountOrg,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileMenu);
