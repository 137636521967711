import styled from '@emotion/styled';

export const StyledLabel = styled.span(props => ({
  position: 'absolute',
  top: '100%',
  textAlign: 'center',
  userSelect: 'none',
  transform: 'translateX(-50%)',
  left: `${props.offset}%`,
  color: `${props.theme.colors.track}`,
  fontSize: 10,
}));

export const StyledMark = styled.span(props => ({
  position: 'absolute',
  width: `${props.theme.space.xsmall}`,
  height: `${props.theme.space.xsmall}`,
  borderRadius: `${props.theme.space.xxsmall}`,
  background: `${props.theme.colors.white}`,
  border: `2px solid rgba(0, 0, 0, 0.25)`,
  transform: 'translateX(-50 %)',
  left: `${props.offset}%`,
}));

export const StyledThumb = styled.input(() => ({
  position: 'absolute',
  width: '100%',
}));

export const Styles = styled.div`
  position: relative;
  width: 100%;
  .track-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    width: 97%;
    height: 16px;
    .track {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: linear-gradient(
        90deg,
        rgba(0, 151, 2, 1) 0%,
        rgba(0, 151, 2, 1) 28%,
        rgba(255, 135, 0, 1) 38%,
        rgba(255, 135, 0, 1) 61%,
        rgba(222, 55, 0, 1) 72%,
        rgba(222, 55, 0, 1) 100%
      );
      transition: background 0.6s ease;
    }
  }
  .thumbs-wrapper {
    position: absolute;
    width: 100%;
    .thumb {
      position: absolute;
      width: 100%;
    }
  }

  .tooltip {
    position: absolute;
    padding: 12px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.75);
    top: 250%;
    transform: translateX(-50%);
    .close-btn {
      padding: 0px;
      margin-right: -8px;
      margin-top: -8px;
      color: white;
      background: rgba(0, 0, 0, 0);
      outline: none;
      border: none;
      font-size: 10px;

      &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.primary};
      }
    }

    .input-box {
      color: white;
      background: rgba(0, 0, 0, 0.75);
      width: 60px;
      border: 0;
    }

    .ant-typography-disabled:hover {
      cursor: default;
    }
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: none;
    pointer-events: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    display: block;
  }

  .thumbs-wrapper > input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
  }

  .thumbs-wrapper > input[type='range']::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background: ${props => props.theme.colors.black1};
    border: 2px solid ${props => props.theme.colors.BG4};
    pointer-events: auto;
    transform: translateX(-50%);

    transition: border-color 0.6s ease;
    &:hover {
      background: ${props => props.theme.colors.primary};
      cursor: pointer;
    }
  }

  .thumbs-wrapper > nput[type='range']::-moz-range-track {
    width: 100%;
    height: 100%;
  }

  input[type='range'],
  input[type='range']::-moz-range-track,
  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    background: none;
    pointer-events: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    display: block;
  }

  .thumbs-wrapper > input[type='range']::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background: ${props => props.theme.colors.black1};
    border: 2px solid ${props => props.theme.colors.BG4};
    pointer-events: auto;
    transform: translateX(-50%);

    transition: border-color 0.6s ease;
    &:hover {
      background: ${props => props.theme.colors.primary};
      cursor: pointer;
    }
  }
`;
