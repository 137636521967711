import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import { getParseParams } from 'admin/utils';

export const INITIAL_STATE = {
  status: undefined,
  assetFetchStatus: '',
  assets: [],
  error: undefined,
  params: undefined,
  next: null,
  previous: null,
  riskData: undefined,
};

export const TYPES = {
  CLEAR_ASSETS: 'CLEAR_ASSETS',

  GET_ASSETS: 'GET_ASSETS',
  GET_ASSETS_ERROR: 'GET_ASSETS_ERROR',
  GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',

  GET_DISCOVER_ASSETS: 'GET_DISCOVER_ASSETS',
  GET_DISCOVER_ASSETS_ERROR: 'GET_DISCOVER_ASSETS_ERROR',
  GET_DISCOVER_ASSETS_SUCCESS: 'GET_DISCOVER_ASSETS_SUCCESS',

  DELETE_DISCOVER_ASSET: 'DELETE_DISCOVER_ASSET',
  DELETE_DISCOVER_ASSET_ERROR: 'DELETE_DISCOVER_ASSET_ERROR',
  DELETE_DISCOVER_ASSET_SUCCESS: 'DELETE_DISCOVER_ASSET_SUCCESS',

  GET_ASSET: 'GET_ASSET',
  GET_ASSET_ERROR: 'GET_ASSET_ERROR',
  GET_ASSET_SUCCESS: 'GET_ASSET_SUCCESS',

  CREATE_ASSET: 'CREATE_ASSET',
  CREATE_ASSET_ERROR: 'CREATE_ASSET_ERROR',
  CREATE_ASSET_SUCCESS: 'CREATE_ASSET_SUCCESS',

  UPDATE_ASSET: 'UPDATE_ASSET',
  UPDATE_ASSET_ERROR: 'UPDATE_ASSET_ERROR',
  UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_SUCCESS',

  GET_ASSET_CONTACTS: 'GET_ASSET_CONTACTS',
  GET_ASSET_CONTACTS_ERROR: 'GET_ASSET_CONTACTS_ERROR',
  GET_ASSET_CONTACTS_SUCCESS: 'GET_ASSET_CONTACTS_SUCCESS',

  SAVE_ASSET: 'SAVE_ASSET',
  SAVE_ASSET_ERROR: 'SAVE_ASSET_ERROR',
  SAVE_ASSET_SUCCESS: 'SAVE_ASSET_SUCCESS',

  SAVE_ASSET_CONTACTS: 'SAVE_ASSET_CONTACTS',
  SAVE_ASSET_CONTACTS_ERROR: 'SAVE_ASSET_CONTACTS_ERROR',
  SAVE_ASSET_CONTACTS_SUCCESS: 'SAVE_ASSET_CONTACTS_SUCCESS',

  DELETE_ASSETS: 'DELETE_ASSETS',
  DELETE_ASSETS_ERROR: 'DELETE_ASSETS_ERROR',
  DELETE_ASSETS_SUCCESS: 'DELETE_ASSETS_SUCCESS',

  DELETE_ASSET: 'DELETE_ASSET',
  DELETE_ASSET_ERROR: 'DELETE_ASSET_ERROR',
  DELETE_ASSET_SUCCESS: 'DELETE_ASSET_SUCCESS',

  GET_ASSET_DETAIL: 'GET_ASSET_DETAIL',
  GET_ASSET_DETAIL_ERROR: 'GET_ASSET_DETAIL_ERROR',
  GET_ASSET_DETAIL_SUCCESS: 'GET_ASSET_DETAIL_SUCCESS',

  GET_ASSET_ATTRIBUTES: 'GET_ASSET_ATTRIBUTES',
  GET_ASSET_ATTRIBUTES_ERROR: 'GET_ASSET_ATTRIBUTES_ERROR',
  GET_ASSET_ATTRIBUTES_SUCCESS: 'GET_ASSET_ATTRIBUTES_SUCCESS',

  UPDATE_ASSET_ATTRIBUTE_DETAIL: 'UPDATE_ASSET_ATTRIBUTE_DETAIL',
  UPDATE_ASSET_ATTRIBUTE_DETAIL_PENDING: 'UPDATE_ASSET_ATTRIBUTE_DETAIL_PENDING',
  UPDATE_ASSET_ATTRIBUTE_DETAIL_ERROR: 'UPDATE_ASSET_ATTRIBUTE_DETAIL_ERROR',

  UPDATE_ASSET_ATTRIBUTE_BATCH: 'UPDATE_ASSET_ATTRIBUTE_BATCH',
  UPDATE_ASSET_ATTRIBUTE_BATCH_PENDING: 'UPDATE_ASSET_ATTRIBUTE_BATCH_PENDING',
  UPDATE_ASSET_ATTRIBUTE_BATCH_ERROR: 'UPDATE_ASSET_ATTRIBUTE_BATCH_ERROR',
};

export const createAssets = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_ASSET,
      success: TYPES.CREATE_ASSET_SUCCESS,
      error: TYPES.CREATE_ASSET_ERROR,
    },
    request: () => axios.post('assets/', data),
    messages: {
      success: 'Asset has been successfully created!',
      error: 'Asset creation unsuccessful',
    },
  });

export const getAssets = data => {
  return callApi({
    types: {
      pending: TYPES.GET_ASSETS,
      success: TYPES.GET_ASSETS_SUCCESS,
      error: TYPES.GET_ASSETS_ERROR,
    },
    request: () => {
      const currentUrl = window.location.href.split('?');
      const params = currentUrl.length > 1 ? getParseParams(currentUrl[1]) : {};
      const { offset = 0, limit = 10 } = params;
      data.order_by = [
        {
          name: 'name',
          direction: 'asc',
        },
      ];
      return axios.post(`assets/query/?limit=${limit}&offset=${offset}`, { ...data, offset, limit });
    },
  });
};

export const getDiscoverAssets = data => {
  const requests = [];
  do {
    const filters = {
      filters: [
        {
          name: 'system_type',
          operator: 'in',
          value: [1, 3],
        },
        {
          name: 'name',
          operator: 'in',
          value: data?.splice(0, 300),
        },
      ],
    };
    requests.push(axios.post(`assets/query/`, { ...filters }));
  } while (data?.length > 0);
  return callApi({
    types: {
      pending: TYPES.GET_DISCOVER_ASSETS,
      success: TYPES.GET_DISCOVER_ASSETS_SUCCESS,
      error: TYPES.GET_DISCOVER_ASSETS_ERROR,
    },
    request: requests,
    parallelAPICalls: true,
  });
};

export const deleteDiscoverAsset = params =>
  callApi({
    types: {
      pending: TYPES.DELETE_DISCOVER_ASSET,
      success: TYPES.DELETE_DISCOVER_ASSET_SUCCESS,
      error: TYPES.DELETE_DISCOVER_ASSET_ERROR,
    },
    params: {
      assetId: params,
    },
    request: () => axios.delete(`assets/${params}/`),
    messages: {
      success: 'The asset was successfully deleted. Network data will be updated momentarily.',
      error: 'Asset delete failed',
    },
  });

export const getAsset = params =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET,
      success: TYPES.GET_ASSET_SUCCESS,
      error: TYPES.GET_ASSET_ERROR,
    },
    params: params,
    request: () => axios.get(`assets/${params.id}/`),
  });

export const getAssetContacts = assetId =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_CONTACTS,
      success: TYPES.GET_ASSET_CONTACTS_SUCCESS,
      error: TYPES.GET_ASSET_CONTACTS_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/contacts/`),
  });

export const saveAsset = data =>
  callApi({
    types: {
      pending: TYPES.SAVE_ASSET,
      success: TYPES.SAVE_ASSET_SUCCESS,
      error: TYPES.SAVE_ASSET_ERROR,
    },
    request: () => axios.put(`assets/${data.id}/`, data),
    params: { id: data.id },
    messages: {
      success: 'Facility has been successfully edited!',
      error: 'Facility edit unsuccessful',
    },
    dynamicErrorMsg: true,
  });

export const saveAssetContacts = (assetId, contactPayload) =>
  callApi({
    types: {
      pending: TYPES.SAVE_ASSET_CONTACTS,
      success: TYPES.SAVE_ASSET_CONTACTS_SUCCESS,
      error: TYPES.SAVE_ASSET_CONTACTS_ERROR,
    },
    params: { id: assetId },
    request: () => axios.put(`assets/${assetId}/contacts/`, contactPayload),
  });

export const updateAsset = data => ({
  type: TYPES.UPDATE_ASSET,
  payload: { ...data },
});

export const deleteAsset = params =>
  callApi({
    types: {
      pending: TYPES.DELETE_ASSET,
      success: TYPES.DELETE_ASSET_SUCCESS,
      error: TYPES.DELETE_ASSET_ERROR,
    },
    request: () => axios.delete(`assets/${params}/`),
    messages: {
      success: 'Facility has been deleted',
      error: 'Facility delete failed',
    },
  });

export const deleteBulkAssets = data => {
  const payload = {
    action: 'delete',
    asset_ids: [...data],
  };
  return callApi({
    types: {
      pending: TYPES.DELETE_ASSETS,
      success: TYPES.DELETE_ASSETS_SUCCESS,
      error: TYPES.DELETE_ASSETS_ERROR,
    },
    request: () => axios.post(`assets/bulk/`, payload),
    messages: {
      success: 'Assets have been deleted',
      error: 'Assets delete unsuccessful',
    },
  });
};

export const getAssetDetail = assetId =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_DETAIL,
      success: TYPES.GET_ASSET_DETAIL_SUCCESS,
      error: TYPES.GET_ASSET_DETAIL_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/`),
  });

export const getAssetAttributes = assetId =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_ATTRIBUTES,
      success: TYPES.GET_ASSET_ATTRIBUTES_SUCCESS,
      error: TYPES.GET_ASSET_ATTRIBUTES_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/attributes/`),
  });

export const updateAttributeDetail = ({ assetId, riskModelId, componentId, comment, riskScoreValue }) =>
  callApi({
    types: {
      success: TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL,
      pending: TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL_PENDING,
      error: TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL_ERROR,
    },
    request: () =>
      axios.post(`assets/${assetId}/risk-models/${riskModelId}/components/${componentId}/scores/`, {
        value: riskScoreValue,
        comment: comment,
      }),
    messages: {
      success: 'Risk score has been successfully updated!',
      error: 'Risk score update unsuccessful',
    },
  });

export const updateAttributeBatch = (assetId, riskModelId, data) =>
  callApi({
    types: {
      success: TYPES.UPDATE_ASSET_ATTRIBUTE_BATCH,
      pending: TYPES.UPDATE_ASSET_ATTRIBUTE_BATCH_PENDING,
      error: TYPES.UPDATE_ASSET_ATTRIBUTE_BATCH_ERROR,
    },
    request: () => axios.post(`assets/${assetId}/risk-models/${riskModelId}/scores/`, { scores: data }),
    messages: {
      success: 'Risk scores have been successfully updated',
      error: 'Risk scores update unsuccessful',
    },
  });

export const clearAssets = () => {
  return {
    type: TYPES.CLEAR_ASSETS,
    payload: null,
  };
};

export const assetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.CLEAR_ASSETS:
      return INITIAL_STATE;

    case TYPES.GET_ASSETS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        assets: [],
        params: action.payload,
      };
    case TYPES.GET_ASSETS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    //TODO: keep track of state if there is next or prev -- check in action.payload -- next and prev in state
    //TODO: set pagination limit to trigger pagination
    case TYPES.GET_ASSETS_SUCCESS:
      const next = action.payload.next ? getParseParams(action.payload.next.split('?')[1]) : null;
      const previous = action.payload.previous
        ? { offset: 0, ...getParseParams(action.payload.previous.split('?')[1]) }
        : null;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: action.payload.results,
        error: undefined,
        next,
        previous,
      };

    case TYPES.GET_DISCOVER_ASSETS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        assets: [],
        params: action.payload,
        assetFetchStatus: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_DISCOVER_ASSETS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
        assetFetchStatus: REQUESTED_DATA_STATUS.ERROR,
      };
    case TYPES.GET_DISCOVER_ASSETS_SUCCESS:
      const results = [];
      action.payload.forEach(x => {
        if (x?.data?.results) {
          results.push(...x?.data?.results);
        }
      });
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        discover_assets: results,
        error: undefined,
        assetFetchStatus: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.DELETE_DISCOVER_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_DISCOVER_ASSET_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        discover_assets: state.discover_assets.filter(asset => asset.id !== action.params.assetId),
        error: undefined,
      };
    case TYPES.DELETE_DISCOVER_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.GET_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_ASSET_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Asset: {
          ...action.payload,
        },
        error: undefined,
      };
    case TYPES.GET_ASSET_CONTACTS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_ASSET_CONTACTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_ASSET_CONTACTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Asset: {
          ...state.Asset,
          contacts: action.payload.results,
        },
        error: undefined,
      };
    case TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL:
      const proactiveRisk = action.payload;
      const Asset = state.Asset;
      Asset.proactive_scores = Asset.proactive_scores.filter(score => score.model_id !== proactiveRisk.model_id);
      Asset.proactive_scores.push(proactiveRisk);

      return {
        ...state,
        Asset: Asset,
        error: undefined,
        detailStatus: 'SUCCESS',
      };
    case TYPES.CREATE_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.CREATE_ASSET_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: [...state.assets, action.payload],
        error: undefined,
      };
    case TYPES.CREATE_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Asset: { ...state.Asset, ...action.payload },
        error: undefined,
      };
    case TYPES.UPDATE_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SAVE_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_ASSET_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.SAVE_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SAVE_ASSET_CONTACTS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_ASSET_CONTACTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.SAVE_ASSET_CONTACTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_ASSET:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.DELETE_ASSET_SUCCESS:
      const deletedAssets = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: state.assets.filter(asset => asset.id !== deletedAssets.id),
        error: undefined,
      };
    case TYPES.DELETE_ASSET_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_ASSETS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_ASSETS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.DELETE_ASSETS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_ASSET_DETAIL:
      return {
        ...state,
        params: action.payload,
        Asset: null,
        detailStatus: 'PENDING',
      };
    case TYPES.GET_ASSET_DETAIL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        detailStatus: 'ERROR',
      };
    case TYPES.GET_ASSET_DETAIL_SUCCESS:
      return {
        ...state,
        Asset: action.payload,
        error: undefined,
        detailStatus: 'SUCCESS',
      };
    case TYPES.GET_ASSET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        error: undefined,
        detailStatus: 'SUCCESS',
      };
    default:
      return state;
  }
};
