import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearRule, getRule } from 'admin/workflows/modules/rules';
import RuleDetailView from './RuleDetailView';

const WorkflowRuleDetailView = () => {
  const dispatch = useDispatch();
  const rule = useSelector(store => store.workflowRules.rule);
  const { id: workflowId, ruleId } = useParams();
  useEffect(() => {
    if (ruleId) {
      dispatch(getRule(workflowId, ruleId));
    }
    return () => dispatch(clearRule());
  }, [dispatch, workflowId, ruleId]);
  if (rule) {
    return <RuleDetailView rule={rule} />;
  }
  return null;
};

export default WorkflowRuleDetailView;
