import WorkflowDetailView from './WorkflowDetailView';
import WorkflowListView from './WorkflowListView';
import WorkflowRuleDetailView from './WorkflowRuleDetailView';
import WorkflowContainerView from './WorkflowContainerView';

const views = {
  WorkflowDetailView,
  WorkflowListView,
  WorkflowRuleDetailView,
  WorkflowContainerView,
};

export default views;
