import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Row } from 'antd';
import { EditIcon } from '@luxe/components';

const MassEditLink = ({ index, model, editable = false }) => {
  let { url } = useRouteMatch();
  if (url.slice(-1) !== '/') {
    url = url.concat('/');
  }
  if (editable) {
    return (
      <Row type="flex" style={{ margin: '10px', width: '100%', justifyContent: 'center' }}>
        <Link to={`${url}model/${model.id}/index/${index.risk_index_id}/score-edit/`}>
          <EditIcon fontSize={'14px !important'} /> Edit Scores
        </Link>
      </Row>
    );
  }
  return null;
};

export default MassEditLink;
