import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

export default class ThreatRiskCategorySelector extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onCategoryClick = e => {
    let riskCategory = e.target.attributes['data-value'].value;
    if (this.currentRiskCategory === riskCategory) {
      riskCategory = null;
    }
    const value = [riskCategory, this.currentAssetType.value];
    this.props.onChange({ target: { value } });
  };

  onAssetTypeClick = assetType => {
    const value = [this.currentRiskCategory, assetType.value];
    this.props.onChange({ target: { value } });
  };

  get currentRiskCategory() {
    return this.props.value && this.props.value.length === 2 ? this.props.value[0] : '';
  }

  get currentAssetType() {
    return this.props.value && this.props.value.length === 2 ? this.props.value[1] : 'asset';
  }

  get riskCategories() {
    return ['low', 'medium', 'high'].map(value => {
      const selectedClass = this.currentRiskCategory === value ? value : '';
      return (
        <button
          key={value}
          onClick={this.onCategoryClick}
          type="button"
          data-value={value}
          className={`btn btn-secondary risk ${selectedClass}`}
        >
          {value}
        </button>
      );
    });
  }

  get assetTypes() {
    return ['assets', 'lanes', 'shipments'].map(value => {
      return {
        value,
        label: value,
      };
    });
  }

  render() {
    return (
      <div>
        <div className="form-group col-md-4">
          <label>Category</label>
          <br />
          <div className="btn-group" data-toggle="buttons">
            {this.riskCategories}
          </div>
        </div>
        <div className="form-group col-md-3">
          <label>Asset Type</label>
          <br />
          <Select
            multi={false}
            stayOpen={false}
            value={this.currentAssetType}
            onChange={this.onAssetTypeClick}
            options={this.assetTypes}
            backspaceRemoves
          />
        </div>
      </div>
    );
  }
}
