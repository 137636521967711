import React from 'react';
import PropTypes from 'prop-types';

export default class CorporateFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    if (this.props.value) {
      return <div>{this.props.value.name}</div>;
    }

    return '';
  }
}
