import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNetworkGraphState,
  getNetworkGraphViews,
  deselectNode,
  setDrawerVisibility,
  setTableVisibility,
  updateSelectedNodeIdFromURL,
  clearSelectedNodeIdFromURL,
  setIncidentId,
  clearSelectedView,
  clearIncidentDetails,
  getImpactedAssets,
  showFacilityDraweronURL,
  resetInitialNodeIdUrl,
} from 'discover/modules/viz';
import { Col, Layout, Row, Spin, notification } from 'antd';
import styled from '@emotion/styled';
import AssetDiscoverGraph from './AssetDiscoverGraph';
import AssetDiscoverDrawer from './AssetDiscoverDrawer';
import NetworkDiscoverFilters from './NetworkDiscoverFilters';
import DiscoverNetworkTables from './NetworkDiscoverList';
import { clearAssets } from 'admin/assets/modules/assets';
import { WarningOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GraphProvider, { useGraphSelector } from 'discover/modules/GraphProvider';
import { showWarningNotification } from 'common/components/Notification';
import useMixpanel from 'utils/hooks/Mixpanel';

const DiscoverSimpleStatusView = styled(({ className, message, loading }) => {
  return (
    <div className={`${className}`}>
      {message}
      {loading && <Spin size="large" />}
    </div>
  );
})`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
`;

const DiscoverNetworkView = styled(props => {
  const {
    selectedNode,
    tableVisible: tableVisibility,
    drawerVisible: drawerVisibility,
    statusLoading,
    fetchedNodeIdFromURL,
    incidentId,
    initialNodeIdUpdate,
    limitExceeded,
  } = useSelector(store => store.networkGraph);
  const [onInit, setOnInit] = useState(true);
  const [graphInitailized, setGraphInitailized] = useState(false);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const ref = useRef(null);
  const nodes = useGraphSelector(graph => graph.observers.nodes);
  const links = useGraphSelector(graph => graph.observers.links);
  const initialized = useGraphSelector(graph => graph.observers.initialized);
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location?.search), [location]);
  const incId = query.get('inc_id');
  const { assetFetchStatus } = useSelector(store => store.assets);
  const defaultViewPref = useSelector(store => store.account.account?.prefs?.default_network_graph_view);

  const doQueryActions = useCallback(
    (clearSearch, queryName) => {
      if (query.has(queryName) && !clearSearch) {
        const keyName = queryName === 'aname' ? 'name' : queryName;
        dispatch(
          updateSelectedNodeIdFromURL({
            [keyName]: query.get(queryName),
          }),
        );
      } else if (clearSearch) {
        query.delete('aname');
        query.delete('aid');
        query.delete('fname');
        query.delete('fid');
        history.push({
          search: query.toString(),
        });
        dispatch(clearSelectedNodeIdFromURL());
      }
    },
    [query, dispatch, history],
  );

  useEffect(() => {
    if (onInit) {
      if (query.get('aname') && fetchedNodeIdFromURL?.name !== query.get('aname')) {
        doQueryActions(null, 'aname');
      } else {
        dispatch(clearNetworkGraphState());
        dispatch(clearAssets());
        if (incId) {
          dispatch(clearIncidentDetails());
          dispatch(clearSelectedView());
          dispatch(setIncidentId(incId));
          dispatch(getImpactedAssets(incId));
        } else {
          if (query.has('fname')) {
            doQueryActions(null, 'fname');
          } else {
            dispatch(getNetworkGraphViews(defaultViewPref || ''));
          }
        }
      }
      setOnInit(false);
    }
  }, [dispatch, onInit, doQueryActions, fetchedNodeIdFromURL, query, incId, defaultViewPref]);

  useEffect(() => {
    if (initialNodeIdUpdate === false && query.has('fname')) {
      dispatch(resetInitialNodeIdUrl(null));
      dispatch(getNetworkGraphViews(defaultViewPref || ''));
    }
  }, [dispatch, initialNodeIdUpdate, defaultViewPref, query]);

  const closeAssetDrawer = useCallback(() => {
    doQueryActions(true);
    dispatch(deselectNode());
    dispatch(setDrawerVisibility(false));
    dispatch(setTableVisibility(false));
  }, [dispatch, doQueryActions]);

  useEffect(() => {
    if (!selectedNode && drawerVisibility) {
      closeAssetDrawer();
    }
  }, [selectedNode, closeAssetDrawer, drawerVisibility]);

  useEffect(() => {
    if (
      (limitExceeded || (incId && links?.length >= 7000) || query.get('is_high_count') === 'true') &&
      initialized &&
      assetFetchStatus !== 'PENDING'
    ) {
      const message = incId
        ? query.get('is_high_count') === 'true'
          ? 'Your query exceeds the limit of 200 impacted sub-tiers. Results have been limited.'
          : 'Your search results exceeded 7000 rows. Some results are not displayed.'
        : 'Your search result exceeds the allowed limit. Please refine your search criteria.';
      notification.open({
        message: message,
        className: 'notification-discover-warning',
        duration: 0,
        key: 'nodes-limit',
        placement: 'top',
        top: 24,
        icon: <WarningOutlined />,
        getContainer: () => ref.current,
      });
    } else {
      notification.destroy('nodes-limit');
    }
    if (nodes?.length === 0 && initialized) {
      showWarningNotification(
        `Your search did not return any results. ${incId ? '' : 'Please refine your search criteria.'}`,
        'emptyAssetGraph',
        ref.current,
      );
    } else if (nodes?.length > 0 && initialized) {
      dispatch(showFacilityDraweronURL(nodes));
    }
  }, [nodes, links, initialized, incId, assetFetchStatus, limitExceeded, query, dispatch]);

  const onGraphInitailized = flag => {
    setGraphInitailized(flag);
  };

  useEffect(() => {
    if (!statusLoading) {
      setGraphInitailized(false);
    }
  }, [statusLoading]);

  useEffect(() => {
    if (onInit) {
      if (query.has('mixpanel_event') && query.get('mixpanel_event') === 'discovered_asset_name') {
        mixpanelTrack('Email - Impacted Discovered Facility Link');
        query.delete('mixpanel_event');
        history.push({ search: query.toString() });
      }
    }
  }, [query, mixpanelTrack, history, onInit]);

  return (
    <Layout hasSider className={props.className}>
      <Layout.Content>
        <Col className={'discover-container'}>
          <Row ref={ref} className="discover-notification-container">
            {initialized && assetFetchStatus !== 'PENDING' ? (
              <AssetDiscoverGraph onGraphInitailized={onGraphInitailized} />
            ) : statusLoading || assetFetchStatus === 'PENDING' ? (
              <DiscoverSimpleStatusView message="Please wait while the graph is loading..." loading={true} />
            ) : (
              <DiscoverSimpleStatusView message="Network is not available." loading={false} />
            )}
          </Row>
          {(tableVisibility || drawerVisibility) && !statusLoading && graphInitailized && (
            <div className="discover-table">
              {drawerVisibility && (
                <AssetDiscoverDrawer drawerAsset={selectedNode} closeAssetDrawer={closeAssetDrawer} />
              )}
              {!drawerVisibility && <DiscoverNetworkTables incId={incidentId} />}
            </div>
          )}
        </Col>
      </Layout.Content>
      {query.get('ref') !== 'action_center' && query.get('ref') !== 'map' ? (
        <NetworkDiscoverFilters statusLoading={statusLoading || assetFetchStatus === 'PENDING'} />
      ) : null}
    </Layout>
  );
})`
  overflow: hidden;

  .discover-container {
    background-color: #131c1e;
    margin-bottom: 10px;
    overflow: hidden;
    width: 100%;
  }

  .discover-table {
    align-items: end;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-name: discoverAssetDrawerSlideUp;
    animation-timing-function: ease-out;
    background: transparent;
    bottom: -600px;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
  }

  @keyframes discoverAssetDrawerSlideUp {
    0% {
      bottom: -600px;
    }
    85% {
      bottom: 0px;
    }
    100% {
      bottom: 0px;
    }
  }
`;

const DiscoverNetworkViewWrapper = Component => {
  const Wrapper = props => {
    const graph = useSelector(state => state.networkGraph.graph);
    return (
      <GraphProvider value={graph}>
        <Component {...props} />
      </GraphProvider>
    );
  };
  return Wrapper;
};
export default DiscoverNetworkViewWrapper(DiscoverNetworkView);
