import React from 'react';
import { Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { useTheme } from 'emotion-theming';
const { Text } = Typography;

const TotalCounter = ({ total }) => {
  const { fontSizes } = useTheme();
  return (
    <div style={{ marginTop: '5px' }}>
      <Text style={{ fontSize: fontSizes.body, lineHeight: '22px' }}>{total || 0}/100</Text>
      {total > 100 ? <WarningTwoTone twoToneColor="#ff0f0f" /> : null}
    </div>
  );
};
export default TotalCounter;
