import axios from 'axiosInstance';
import { UNITS_ENUM } from 'common/Constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_FORECAST_LAYER: 'GET_FORECAST_LAYER',
  GET_FORECAST_LAYER_ERROR: 'GET_FORECAST_LAYER_ERROR',
  GET_FORECAST_LAYER_SUCCESS: 'GET_FORECAST_LAYER_SUCCESS',
  CLEAR_FORECAST_LAYERS: 'CLEAR_FORECAST_LAYERS',
};

export const INITIAL_STATE = {};

export const LAYERS = {
  TEMPERATURE: {
    key: 'temp',
    description: 'Temperature',
    chart: 'main',
    defaultUnit: UNITS_ENUM.degF,
    gridId: 'blend_2m_temp',
  },
  WIND_GUST: {
    key: 'windGust',
    description: 'Wind Gust',
    chart: 'main',
    defaultUnit: UNITS_ENUM.speedKMPH,
    gridId: 'blend_10gust',
  },
  WIND_SPEED: {
    key: 'windSpeed',
    description: 'Wind Speed',
    chart: 'main',
    defaultUnit: UNITS_ENUM.speedKMPH,
    gridId: 'blend_10speed',
  },
  TOTAL_PRECIP: {
    key: 'precipAcc',
    description: 'Accumulated Precipitation',
    chart: 'secondary',
    defaultUnit: UNITS_ENUM.lengthInch,
    gridId: 'blend_total_precip',
    accumulated: true,
  },
  HOURLY_PRECIP: {
    key: 'precipAmt',
    description: 'Hourly Precipitation',
    chart: 'secondary',
    defaultUnit: UNITS_ENUM.lengthInch,
    gridId: 'blend_total_precip',
  },
  TOTAL_SNOW: {
    key: 'snowAcc',
    description: 'Accumulated Snowfall',
    chart: 'secondary',
    defaultUnit: UNITS_ENUM.lengthInch,
    gridId: 'blend_snowfall',
    accumulated: true,
  },
  HOURLY_SNOW: {
    key: 'snowAmt',
    description: 'Hourly Snowfall',
    chart: 'secondary',
    defaultUnit: UNITS_ENUM.lengthInch,
    gridId: 'blend_snowfall',
  },
  RISKS: {
    key: 'risk',
    description: 'Risk',
    chart: 'risk',
    defaultUnit: UNITS_ENUM.default,
    gridId: 'risk',
  },
};

export const getForecastLayer = (assetId, gridId) => {
  return callApi({
    types: {
      pending: TYPES.GET_FORECAST_LAYER,
      success: TYPES.GET_FORECAST_LAYER_SUCCESS,
      error: TYPES.GET_FORECAST_LAYER_ERROR,
    },
    params: { assetId, gridId },
    request: () => axios.get(`/assets/${assetId}/grids/${gridId}/`),
  });
};

export const clearForecasts = () => {
  return {
    type: TYPES.CLEAR_FORECAST_LAYERS,
  };
};

const forecastsReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  const gridId = action.params?.gridId ?? action.payload?.gridId;

  const forecastState = state[gridId] ?? {};

  switch (type) {
    case TYPES.CLEAR_FORECAST_LAYERS:
      return INITIAL_STATE;
    case TYPES.GET_FORECAST_LAYER:
      forecastState.data = [];
      return {
        ...state,
        [gridId]: {
          ...forecastState,
          loading: true,
          error: false,
        },
      };
    case TYPES.GET_FORECAST_LAYER_SUCCESS:
      forecastState.data = (action.payload?.time ?? []).map((t, i) => [t, action.payload.values[i]]);
      return {
        ...state,
        [gridId]: {
          ...forecastState,
          loading: false,
          error: false,
        },
      };
    case TYPES.GET_FORECAST_LAYER_ERROR:
      forecastState.data = [];
      return {
        ...state,
        [gridId]: {
          ...forecastState,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default forecastsReducer;
