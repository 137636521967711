import React from 'react';
import { Switch } from 'antd';
import IncidentAction from './IncidentActions';

const EmailPreference = ({ ...props }) => {
  const updatePreferenceData = event => {
    props.updateAction({
      action: props.name,
      selected: true,
      is_realtime: event,
      frequency: props.action?.frequency,
    });
  };

  const captureUpdateFrequency = event => {
    props.updateAction({
      action: props.name,
      selected: true,
      frequency: event?.frequency,
      is_realtime: event?.is_realtime,
    });
  };
  return (
    <div className={'email-prefs'}>
      <div className={'notification-label'}>{props.label}</div>
      <Switch className={'realtime-prefs'} checked={props.action?.is_realtime} onChange={updatePreferenceData} />
      <div className={'notification-group'}>
        <IncidentAction className={'profile-channel'} action={props.action} updateFrequency={captureUpdateFrequency} />
      </div>
    </div>
  );
};
export default EmailPreference;
