import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import { ViewTable } from 'views/components';
import { Form } from 'antd';
import { Select } from 'layout/Select';
import { Breadcrumb, BreadcrumbItem, BasicModal as Modal, Label } from '@luxe/components';
import { createAssets, deleteBulkAssets } from 'admin/assets/modules/assets';
import { useTheme } from 'emotion-theming';

import {
  getViewMeta,
  getViewData,
  addSelectedRows,
  removeSelectedRow,
  selectAll,
  deselectAll,
  selectAllVisible,
} from 'views/modules/views';

import { getParams } from 'views/utils';

//TODO: implement create assets modal -- create risk profile or risk models button
const AssetListView = ({
  getViewMeta,
  getViewData,
  createAssets,
  selectAll,
  deselectAll,
  selectAllVisible,
  addSelectedRows,
  removeSelectedRow,
  selectedRows,
  deleteBulkAssets,
  riskProfiles,
  views,
  push,
  router,
}) => {
  const { search: url } = router.location;
  const firstLoad = useRef(true);
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const assets = views.assets;
  if (assets?.items) {
    assets.items = assets?.items?.map(item => ({ ...item, location: null })) || [];
  }

  const meta = views && assets ? assets.meta : undefined;
  const hasMeta = meta !== undefined;
  const urlChanged = assets && assets.currentUrl !== url;
  const shouldLoad = hasMeta && (firstLoad.current || urlChanged);

  useEffect(() => {
    if (!hasMeta) {
      getViewMeta('assets');
    }
  }, [hasMeta, getViewMeta]);

  useEffect(() => {
    if (shouldLoad) {
      firstLoad.current = false;
      let query = {
        columns: ['asset', 'org_type', 'modified_date', 'risk'],
        order_by: [{ name: 'risk__score', direction: 'desc' }],
        ...getParams(url),
        url,
      };
      getViewData('assets', query);
    }
  }, [shouldLoad, url, getViewData]);

  const actions = [
    {
      title: 'Delete',
      action: selectedIds => {
        deleteBulkAssets(selectedIds);
      },
    },
  ];

  const onSelect = (record, selected, selectedRow, event) => {
    if (!selected) {
      removeSelectedRow('assets', record.asset.id);
    } else {
      addSelectedRows('assets', record.asset.id);
    }
  };

  const onSelectAll = (selected, selectedRows, changeRows) => {
    if (!selected) {
      deselectAll('assets');
    } else {
      selectAllVisible({ datasource: 'assets', selectedRows: assets.items.map(x => x.asset.id) });
    }
  };

  const selectAllAssets = () => selectAll('assets', { ...assets.meta.current }, getRowId);

  const { space } = useTheme();
  const onCreate = values => {
    createAssets(values);
    setIsCreateModalShowing(false);
  };

  const getRowId = row => row.asset.id;

  const rowSelection =
    assets && !assets.pending
      ? {
          selectedRowKeys: assets?.selectedRows || [],
          onSelect: onSelect,
          onSelectAll: onSelectAll,
        }
      : null;

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view assets-list">
      <Breadcrumb style={{ margin: `${space.small}px ${space.none}` }}>
        <BreadcrumbItem>
          <Link to="/admin/">Admin</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Assets</BreadcrumbItem>
      </Breadcrumb>
      <Container>
        <ViewTable
          title="Assets"
          actions={actions}
          data={assets}
          push={push}
          rowSelection={rowSelection}
          rowKey={row => row.asset.id}
          selectAll={selectAllAssets}
          allowFiltering={true}
          includes={['profile', 'country_id', 'risk__category', 'corporation']}
        />
      </Container>
      <CreateAssetForm
        title={CREATE_MODAL_TEST}
        visible={isCreateModalShowing}
        onCancel={() => {
          setIsCreateModalShowing(false);
        }}
        onCreate={onCreate}
        riskProfiles={riskProfiles}
      />
    </ListViewContent>
  );
};

const CREATE_MODAL_TEST = 'Create a new asset';
const CreateAssetForm = ({ visible, onCreate, onCancel, riskProfiles }) => {
  const [form] = Form.useForm();
  const options =
    riskProfiles &&
    riskProfiles.length &&
    riskProfiles.map(x => ({
      key: x.id,
      value: x.id,
      label: x.name,
    }));
  return (
    <Modal
      visible={visible}
      title="Create a new Asset"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(values => {
          form.resetFields();
          onCreate({
            id: values.id,
            name: values.name,
            location: {
              city: values.city,
              state: values.state,
              country: {
                name: values.country,
              },
            },
          });
        });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_asset_form"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="id"
          label={<label style={{ color: 'white' }}>Asset id</label>}
          rules={[
            {
              required: true,
              message: 'Please input the asset ID!',
            },
          ]}
        >
          <Label placeholder="Asset id" />
        </Form.Item>
        <Form.Item
          name="name"
          label={<label style={{ color: 'white' }}>Name</label>}
          rules={[
            {
              required: true,
              message: 'Please input the name of Asset!',
            },
          ]}
        >
          <Label placeholder="Asset Name" />
        </Form.Item>

        <Form.Item
          name="city"
          label={<label style={{ color: 'white' }}>City</label>}
          rules={[
            {
              required: true,
              message: 'Please input the city of Asset!',
            },
          ]}
        >
          <Label placeholder="City" />
        </Form.Item>
        <Form.Item
          name="state"
          label={<label style={{ color: 'white' }}>State</label>}
          rules={[
            {
              required: true,
              message: 'Please input the state of Asset!',
            },
          ]}
        >
          <Label placeholder="State" />
        </Form.Item>
        <Form.Item
          name="country"
          label={<label style={{ color: 'white' }}>Country</label>}
          rules={[
            {
              required: true,
              message: 'Please input the Country of Asset!',
            },
          ]}
        >
          <Label placeholder="Country" />
        </Form.Item>
        <Form.Item>
          <Form.Item name="profile_id" noStyle rules={[{ required: true, message: 'Risk Profile is required' }]}>
            <Select placeholder="Select Risk Profile" options={options} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps({ selectedRows, router, riskProfiles, views }) {
  return {
    router,
    riskProfiles,
    views,
    selectedRows,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAssets,
      addSelectedRows,
      removeSelectedRow,
      selectAllVisible,
      deleteBulkAssets,
      selectAll,
      deselectAll,
      getViewData,
      getViewMeta,
      push,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetListView);
