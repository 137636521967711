import React from 'react';
import PropTypes from 'prop-types';
import { RULE_TYPES } from 'admin/constants';
import { Col, Row, Typography, Space } from 'antd';
import { Label as Input } from '@luxe/components';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const valueStyle = {
  color: 'white',
  fontSize: 12,
  textAlign: 'center',
};

const riskStyle = {
  color: '#858585',
  fontSize: 11,
  fontWeight: 'bold',
  textAlign: 'center',
};

const StaticTooltip = ({ value, onClose, disabled }) => {
  return (
    <Col>
      {disabled || (
        <Row justify="end">
          <button className="close-btn" onClick={onClose}>
            <CloseOutlined />
          </button>
        </Row>
      )}
      <Row>
        <Text disabled={true} style={valueStyle}>
          {value.name || value.value}
        </Text>
      </Row>
      <Row justify="center">
        <Text disabled={true} style={riskStyle}>
          {value.risk}
        </Text>
      </Row>
    </Col>
  );
};

const EditableTooltip = ({ value, onChange, disabled }) => {
  const units = value.units;
  return (
    <Col>
      <Space>
        <Input
          className={'input-box'}
          value={value.value}
          size="small"
          style={{ ...valueStyle, backgroundColor: '#3B3B3B' }}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
        ></Input>
        {units && <Text>{units}</Text>}
      </Space>
      <Row justify="center">
        <Text style={riskStyle}>{value.risk}</Text>
      </Row>
    </Col>
  );
};

const TOOLTIP_TYPE = {
  [RULE_TYPES.TIME_SERIES_ABSOLUTE]: EditableTooltip,
  [RULE_TYPES.SPATIAL_INTERSECT]: StaticTooltip,
  [RULE_TYPES.DISTANCE_BASED]: StaticTooltip,
  [RULE_TYPES.DURATION_BASED]: EditableTooltip,
  [RULE_TYPES.TIME_SERIES_WINDOW]: EditableTooltip,
  [RULE_TYPES.CUSTOM_ATTRIBUTE]: EditableTooltip,
};

const Tooltip = ({ offset, type, value, onClose, onChange, unitOfMeasure, disabled }) => {
  const TooltipBody = TOOLTIP_TYPE[type];
  return (
    <div style={{ left: `${offset}%` }} className="tooltip">
      <Row>
        {TooltipBody({ value, onClose, onChange, disabled })}
        <span style={{ paddingLeft: '5px' }}> {unitOfMeasure}</span>
      </Row>
    </div>
  );
};

Tooltip.propTypes = {
  offset: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default Tooltip;
