// Import polyfills - Thank you IE
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import getFromConfig from 'constants/config';
import { TYPES as ACCOUNT_TYPES } from 'account/modules/account';

import './styles.css';

const AUTH0_CLIENT_ID = getFromConfig('REACT_APP_AUTH0_CLIENT_ID');
const AUTH0_AUDIENCE = getFromConfig('REACT_APP_AUTH0_AUDIENCE');
const AUTH0_DOMAIN = getFromConfig('REACT_APP_AUTH0_DOMAIN');
const AUTH0_CONNECTION = getFromConfig('REACT_APP_AUTH0_CONNECTION');

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

function getAuth0Connection() {
  const q_conn = getQueryVariable('connection');

  // Use connection from queryString first and save in localStorage
  if (q_conn) {
    window.localStorage.setItem('auth0_conn', q_conn);
    return q_conn;
  }

  // Prefer localStorage and fallback to default
  const local_conn = window.localStorage.getItem('auth0_conn');
  const auth0_conn = local_conn || AUTH0_CONNECTION;
  console.warn('using connection:', auth0_conn);

  return auth0_conn;
}

const loginWithRedirect = props => {
  store.dispatch({ type: ACCOUNT_TYPES.SET_FRESH_LOGIN, returnTo: props?.returnTo || window.location.pathname });
};

const Root = ({ store }) => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    audience={AUTH0_AUDIENCE}
    connection={getAuth0Connection()}
    redirectUri={window.location.origin}
    onRedirectCallback={loginWithRedirect}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
