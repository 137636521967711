import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { operators as Ops } from 'constants/application';
import { SearchInput, Section } from '@luxe/components';
import { getParams } from 'views/utils';
import { getViewData, getViewMeta } from 'views/modules/views';
import _ from 'lodash';

import { ViewTable } from 'views/components';

export const AssetTab = props => {
  const { region } = useSelector(state => state.regions);
  const views = useSelector(store => store.views);
  const countryId = region?.id;

  const location = useLocation();
  const history = useHistory();
  const currentSearch = useRef(null);
  const dispatch = useDispatch();

  const assets = views?.['assets'] || {};
  const [additionalColumns, setAdditionalColumns] = useState([]);
  const meta = assets.meta;
  const [firstLoad, setFirstLoad] = useState(false);
  if (!firstLoad) {
    setAdditionalColumns([
      {
        widget: 'Input',
        description: 'Active Incidents',
        short_description: 'Active Incidents',
        name: 'active_incidents',
        order_by: 'risk__threats',
        maps: 'risk',
        formatter: data => {
          return Array.isArray(data['threats']) ? data['threats'].length.toString() : '0';
        },
      },
    ]);
    setFirstLoad(true);
  }

  const prepareFilters = useCallback(
    filters => {
      const addFilterDescription = filter => {
        return meta.allowed.filters.find(allowedFilter => {
          return allowedFilter.name === filter;
        });
      };
      const excludedFilters = ['query', 'country_id'];
      filters = filters.concat([{ name: 'country_id', operator: Ops.exact, value: countryId }]);
      filters = filters.filter(filter => {
        if (excludedFilters.includes(filter.name) && !filter.value) {
          return false;
        }
        return true;
      });
      filters = filters.map(filter => {
        const metaFilter = addFilterDescription(filter.name);
        if (metaFilter !== undefined) {
          return { ...filter, description: metaFilter.description };
        }
        return filter;
      });

      return filters;
    },
    [countryId, meta],
  );

  useEffect(() => {
    if (firstLoad) {
      const overrides = {
        asset: {
          inAppNavigation: true,
        },
        risk: {
          description: 'Incident Risk',
        },
        proactive_scores: {
          description: 'Top Score',
        },
        pending_feedback_count: {
          description: 'Pending Feedback',
        },
        risk__category: {
          description: 'Incident Risk',
          operators: ['exact'],
        },
        risk_model__category: {
          description: 'Overall Risk',
          operators: ['exact'],
          widget: 'RiskCategorySelector',
        },
      };
      dispatch(getViewMeta('assets', overrides, additionalColumns));
    }
  }, [dispatch, firstLoad, additionalColumns]);

  useEffect(() => {
    const searchChanged = location.search !== currentSearch.current;
    const pagePathName = location.pathname.split('/')[3];
    const shouldLoad = meta && searchChanged;
    if (shouldLoad && pagePathName === 'assets') {
      const params = getParams(location.search);
      params.limit = 10;
      currentSearch.current = location.search;
      params.filters = prepareFilters(params.filters);
      const query = {
        columns: ['asset', 'overall_risk', 'risk', 'proactive_scores'],
        ...params,
        url: window.location.href,
      };
      dispatch(getViewData('assets', query, additionalColumns));
    }
  }, [dispatch, firstLoad, meta, location, additionalColumns, prepareFilters]);

  const prepareSearch = useCallback(
    (filters, search) => {
      const searchFilters = location.search.includes('query=')
        ? filters
        : [...filters, { name: 'query', value: search }];
      return searchFilters.reduce((result, currentFilter) => {
        if (currentFilter.name === 'query' && currentFilter.value !== search) currentFilter.value = search;
        if (result) {
          result += `&${currentFilter.name}=${currentFilter.value}`;
        } else {
          result = `${currentFilter.name}=${currentFilter.value}`;
        }
        return result;
      }, '');
    },
    [location],
  );

  const onSearchChange = useMemo(
    () =>
      _.debounce(searchTerm => {
        const params = getParams(location.search);
        const search = prepareSearch(params.filters, searchTerm);
        history.push({
          pathname: location.pathname,
          search: search,
        });
      }, 350),
    [history, location, prepareSearch],
  );

  const searchInput = (
    <SearchInput
      key={'assets-search'}
      placeholder="Search Facilities"
      onChange={e => {
        onSearchChange(e.target.value);
      }}
    />
  );

  return (
    <Section title={'Facilities'} buttons={[searchInput]}>
      <div style={{ width: '100%' }}>
        <ViewTable
          title="Facilities"
          data={assets}
          allowFiltering={true}
          actions={[]}
          rowSelection={false}
          selectAll={() => {}}
          rowKey={row => `${row.asset.id}-${row.asset.name}`}
          includes={['risk__category', 'risk_model__category']}
          excludes={['country_id', 'query']}
          push={history.push}
          additionalColumns={additionalColumns}
          size={'small'}
          sorted={true}
        />
      </div>
    </Section>
  );
};

export default AssetTab;
