import { Logo, SideMenu } from '@luxe/components';
import React, { useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import menus from './menus';
import sunriseIcon from './static/everstream-icon.png';
import sunriseLogo from './static/everstream-logo.png';

const SidebarMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const account = useSelector(store => store.account);
  const isAdmin = account.account.isAdmin;
  const location = useLocation();
  const disableSidebar = ['assets', 'country-risk', 'discover'];

  const path = location.pathname.split('/');
  const linkDestination = path[1] === 'admin' && isAdmin ? '/admin/asset-groups/' : '/users/profile/';

  const hideSidebarMenu = pathnames => {
    return pathnames.some(name => location.pathname.indexOf(name) > -1);
  };

  return (
    <>
      {location && hideSidebarMenu(disableSidebar) && location.pathname.indexOf('admin') === -1 ? null : (
        <SideMenu collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} width={215}>
          <Link to={linkDestination}>
            <Logo style={{ justifyContent: collapsed ? 'center' : null }}>
              <img alt="Everstream Analytics" src={collapsed ? sunriseIcon : sunriseLogo} />
            </Logo>
          </Link>
          <Switch>
            {path[1] === 'admin' && isAdmin && <Route path="*" component={menus.AdminMenu} />}
            {path[1] === 'users' && <Route path="*" component={menus.UserProfileMenu} />}
          </Switch>
        </SideMenu>
      )}
    </>
  );
};

export default SidebarMenu;
