import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteIcon } from '@luxe/components';
import { BasicModal as Modal } from '@luxe/components';
import PropTypes from 'prop-types';

const RemoveRule = ({ rule, itemName, deleteRuleFunction }) => {
  const dispatch = useDispatch();
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const onDelete = () => {
    const dataId = rule.workflow;
    dispatch(deleteRuleFunction(dataId, rule.id));
    setIsDeleteModalShowing(false);
  };
  return (
    <>
      <DeleteIcon
        onClick={e => {
          setIsDeleteModalShowing(true);
        }}
        style={{ marginLeft: '5px' }}
      />
      <DeleteRule
        visible={isDeleteModalShowing}
        onCancel={() => {
          setIsDeleteModalShowing(false);
        }}
        onDelete={onDelete}
        selectedRule={rule.name}
      />
    </>
  );
};

const DeleteRule = ({ visible, onDelete, onCancel, selectedRule }) => {
  return (
    <Modal
      visible={visible}
      title="Delete a Rule"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onDelete}
    >
      You will be deleting the following rule:
      <ul>{selectedRule}</ul>
      Are you sure you want to do this?
    </Modal>
  );
};

RemoveRule.propTypes = {
  rule: PropTypes.object.isRequired,
  itemName: PropTypes.string.isRequired,
  deleteRuleFunction: PropTypes.func.isRequired,
};

RemoveRule.defaultProps = {};

export default RemoveRule;
