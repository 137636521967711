import { Slider, ToolLabel } from 'assets/containers/OverallRiskCharts/styles';
import { connect, useSelector } from 'react-redux';
import { getRiskCategory, isEmpty } from 'utils';
import { removeRiskFilter, updateRiskFilter } from 'assets/modules/riskfilter';

import { ChartLabel } from 'assets/containers/AssetDetailView/styles';
import Highcharts from 'highcharts';
import HighchartsCustomEvents from 'highcharts-custom-events';
import HighchartsMore from 'highcharts/highcharts-more';
import IndividualSubIndexChart from './IndividualSubIndexChart';
import { LocationRiskDetail as LocationRiskDetails } from 'assets/containers/LocationRiskDetailsView';
import MassEditLink from 'assets/containers/AssetDetailView/MassEditView/MassEditLink';
import React from 'react';
import { Row } from 'antd';
import { bindActionCreators } from 'redux';
import { historyChartOptions } from 'assets/containers/OverallRiskCharts/HistoryChartOptions';
import { normalizeName } from 'assets/modules/utils';
import { renderToString } from 'react-dom/server';
import styled from '@emotion/styled';
import theme from 'theme';

Highcharts.AST.allowedAttributes.push('data-index');
window.Highcharts = Highcharts;
HighchartsMore(Highcharts);
HighchartsCustomEvents(Highcharts);

export const ChartDiv = styled.div`
  width: 49%;
  &:nth-of-type(1) {
    margin-right: 2%;
  }
  span {
    font-size: ${theme.fontSizes[3]}px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: ${theme.space.xsmall}px;
  }
`;

function getPlotPoints(risks) {
  const locationRisks = {
    name: 'Location Risk',
    data: risks.map(risk => {
      return risk.score || 0;
    }),
    pointPlacement: 'off',
  };
  return [locationRisks];
}

function getChartCategories(risks) {
  return risks.map(risk => {
    return risk.name;
  });
}
function getEditable(riskModel) {
  if (riskModel.hasOwnProperty('indices')) {
    const indices = riskModel.indices;
    riskModel.indices.forEach(index => {
      index.editable = false;
      index.indices.forEach(subindex => {
        subindex.components.forEach(component => {
          if (component.rule_type === 'user_score' || component.rule_type === 'user_question') {
            index.editable = true;
          }
        });
      });
    });
    return indices.reduce((acc, index) => {
      acc[index.id] = index.editable;
      return acc;
    }, {});
  }
  return {};
}

function getRiskHistory(riskHistory, index, subindex) {
  const name = normalizeName(subindex);
  let indexHistory = {};
  if (!isEmpty(riskHistory)) {
    indexHistory = riskHistory.indices[normalizeName(index)];
    return indexHistory?.indices[name]?.scores || [];
  }
  return {};
}

function onLabelClick(node, updateRiskFilter, removeRiskFilter) {
  const eIndex = node.dataset.index
    .split(' ')
    .join('_')
    .toLowerCase();
  const category = node.textContent;
  if (node.classList.contains('active')) {
    removeRiskFilter(category, eIndex);
    node.classList.remove('active', `${eIndex}-active`);
  } else {
    updateRiskFilter(category, eIndex);
    node.classList.add('active', `${eIndex}-active`);
  }
}

export function subIndexChartOptions(risk, riskHistory, updateRiskFilter, removeRiskFilter, isEkg) {
  let indices = risk.indices || [];
  if (isEkg) {
    indices = indices.filter(x => x.name !== 'Internal Risks');
  }
  const tickInterval = 8.34;
  return indices.map(riskItem => {
    const options = {
      chart: {
        marginTop: 30,
        marginBottom: 30,
        polar: true,
        style: { width: '100%' },
        type: 'line',
        backgroundColor: theme.colors.black2,
      },
      colors: ['#2d8fa5'],
      plotOptions: {
        series: {
          lineWidth: 3,
        },
      },
      tooltip: {
        className: 'slider-tooltip-container',
        useHTML: true,
        outside: true,
        style: { zIndex: 950 },
        formatter: function() {
          const history = getRiskHistory(riskHistory, riskItem.name, this.x);
          const index = riskItem.indices.find(item => item.name === this.x);
          const trend = index ? index.trend : 'neutral';
          const category = getRiskCategory(this.y);
          const width = (this.y / 25) * 100;
          const stringSlider = renderToString(<Slider style={{ width: `${width}%` }} class={`risk-${category}`} />);
          const label = renderToString(<ToolLabel name={this.x} score={this.y || '-'} trend={trend} />);
          const benchmark = `<div><span class='slider-benchmark slider-label'>Benchmark:</span><span class='slider-benchmark'>-</span></div>`;
          const historyDiv = `tooltip-history-${riskItem.name}`;
          const historyChart = `<div id='${historyDiv}'></div>`;
          if (!isEmpty(history)) {
            setTimeout(function() {
              window.Highcharts.chart(historyDiv, historyChartOptions(history));
            }, 10);
          }
          return `<div class='slider-tooltip'>${label}${benchmark}${stringSlider}${historyChart}</div>`;
        },
        backgroundColor: 'theme.colors.black3',
        borderWidth: 0,
        padding: 0,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        size: '85%',
      },
      xAxis: {
        categories: getChartCategories(riskItem.indices),
        tickmarkPlacement: 'on',
        lineWidth: 0,
        gridLineWidth: 2,
        labels: {
          useHTML: true,
          distance: 10,
          formatter: function() {
            return renderToString(<ChartLabel indexName={riskItem.name} value={this.value} />);
          },
          events: {
            click: function(e) {
              onLabelClick(e.target.parentNode, updateRiskFilter, removeRiskFilter);
            },
          },
          style: {
            cursor: 'pointer',
            color: theme.colors.white,
            fontSize: theme.fontSizes.small,
          },
          zIndex: 1,
        },
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        alternateGridColor: '#424242',
        min: 0,
        labels: {
          enabled: false,
        },
        gridLineWidth: 2,
        tickInterval,
      },
      legend: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              chart: {
                marginLeft: 60,
                marginRight: 60,
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                marginLeft: 50,
                marginRight: 50,
              },
            },
          },
          {
            condition: {
              minWidth: 500,
            },
            chartOptions: {
              chart: {
                marginLeft: 30,
                marginRight: 30,
              },
            },
          },
        ],
      },
      series: getPlotPoints(riskItem.indices),
    };
    options.yAxis.max = tickInterval * (~~(Math.max(...options.series[0].data) / tickInterval) + 1);
    return options;
  });
}

function ChartDetail({ className, options, key, keyName, risk, index, riskModel, riskHistory, editable, scores }) {
  if (index.indices.length === 0) {
    return (
      <Row
        style={{
          width: '100%',
          height: '400px',
          backgroundColor: 'theme.colors.black4',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No Risk Configured
      </Row>
    );
  } else {
    return (
      <>
        <IndividualSubIndexChart cName={className} options={options} scores={scores} />
        <LocationRiskDetails
          key={`location-risk-detail-${keyName}`}
          risk={risk}
          riskIndex={index}
          riskModel={riskModel}
          history={riskHistory}
          keyName={keyName}
        />
        <MassEditLink index={index} model={riskModel} editable={editable} />
      </>
    );
  }
}

function SubIndexCharts({
  updateRiskFilter,
  removeRiskFilter,
  className,
  risk = {},
  riskHistory = {},
  riskModel = {},
  isEkg = false,
}) {
  const riskModelScores = useSelector(store => store.riskModels.RiskModelScores);

  if (Object.keys(risk).length === 0 || !risk?.indices) {
    return <Row style={{ width: '100%' }}>There is no risk information available.</Row>;
  }
  const opts = subIndexChartOptions(risk, riskHistory, updateRiskFilter, removeRiskFilter);
  const editable = getEditable(riskModel);
  const children = [];
  let indices = risk.indices || [];
  if (isEkg) {
    indices = indices.filter(x => x.name !== 'Internal Risks');
  }
  indices.forEach((index, key) => {
    const keyName = index.name
      .split(' ')
      .join('_')
      .toLowerCase();
    children.push(
      <ChartDiv key={`location-risk-chart-${key}`}>
        <span>{index.name} Risk Indices</span>
        <ChartDetail
          className={className}
          options={opts[key]}
          keyName={keyName}
          scores={riskModelScores}
          risk={risk}
          index={index}
          riskModel={riskModel}
          riskHistory={riskHistory}
          editable={editable[index.risk_index_id]}
        />
      </ChartDiv>,
    );
  });
  return <Row>{children}</Row>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRiskFilter,
      removeRiskFilter,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(SubIndexCharts);
