import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { Slider, TrendIcon } from 'assets/containers/OverallRiskCharts/styles';
import { Benchmark } from 'assets/containers/AssetDetailView/styles';
import {
  CommentLabel,
  ScoreDetailsComponent,
  ScoreValueInput,
  StyledModal,
  StyledRadioGroup,
} from 'assets/containers/LocationRiskDetailsView/ScoreDetailsView/styles';
import { EditIcon, Label } from '@luxe/components';
import { updateAttributeDetail } from 'admin/assets/modules/assets';

const ScoreValue = ({ riskScore, proactiveRisk, riskComponent }) => {
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const [isUserEditable, setIsUserEditable] = useState(false);
  const width = (riskScore.score / 25) * 100;
  const trend = riskScore.trend;

  useEffect(() => {
    const editableRuleTypes = ['user_score', 'user_question'];
    if (riskComponent?.rule_type) {
      const ruleIsEditable = editableRuleTypes.indexOf(riskComponent.rule_type) >= 0;
      setIsUserEditable(ruleIsEditable);
    }
  }, [riskComponent, riskScore.component_id]);

  function editIcon() {
    if (isUserEditable) {
      return (
        <EditIcon
          variant="alternate"
          style={{ fontSize: '16px' }}
          onClick={() => {
            setIsCreateModalShowing(true);
          }}
        />
      );
    }
    return null;
  }

  return (
    <ScoreDetailsComponent>
      <div className="component-score-container">
        <div className="component-score-value">
          <span>Score: </span>
          <span className="slider-heading-score">{riskScore.score || '-'}</span>
          <TrendIcon className="component-score-trend" trend={trend} />
        </div>
        <div className="component-score-button">{editIcon()}</div>
      </div>
      <Benchmark />
      <Slider style={{ width: `${width}%` }} class={`risk-${riskScore.category}`} />
      {isCreateModalShowing && (
        <EditAttributeForm
          title="edit_attribute"
          visible={isCreateModalShowing}
          onCancel={() => setIsCreateModalShowing(false)}
          onCreate={() => setIsCreateModalShowing(false)}
          riskScore={riskScore}
          riskComponent={riskComponent}
          proactiveRisk={proactiveRisk}
        />
      )}
    </ScoreDetailsComponent>
  );
};

const EditAttributeForm = ({ visible, onCreate, onCancel, riskComponent, riskScore, proactiveRisk }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id: assetId } = useParams();
  const [riskScoreValue, setRiskScoreValue] = useState(riskScore.value);
  const ruleType = riskComponent?.rule_type;

  function questionRadioChoices() {
    return (
      riskComponent?.rule.values?.map((choice, i) => {
        return {
          label: `Option ${i + 1} - ${choice.value}`,
          value: choice.value,
        };
      }) || []
    );
  }

  useEffect(() => {
    setRiskScoreValue(riskScore.value);
  }, [riskScore]);

  function buildUserQuestion() {
    return (
      <>
        <div style={{ paddingBottom: '10px' }}>{riskComponent.rule.options.question}</div>
        <StyledRadioGroup
          value={riskScoreValue}
          onChange={e => setRiskScoreValue(e.target.value)}
          options={questionRadioChoices()}
        />
        <Form layout="vertical" form={form}>
          <Form.Item
            name="comment"
            label={
              <label
                style={{
                  color: 'white',
                  paddingTop: '10px',
                  paddingBottom: '4px',
                }}
              >
                Comment (optional)
              </label>
            }
          >
            <Label placeholder="Type your comment here" />
          </Form.Item>
        </Form>
      </>
    );
  }

  function buildUserScore() {
    return (
      <>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            riskScore: riskComponent.rule_type === 'user_question' ? riskScoreValue : riskScore.score,
          }}
        >
          <Form.Item
            name="riskScore"
            label={
              <label
                style={{
                  color: 'white',
                  paddingTop: '10px',
                  paddingBottom: '4px',
                }}
              >
                Please enter value:
              </label>
            }
          >
            <ScoreValueInput
              min={1}
              max={25}
              type="number"
              defaultValue={null}
              placeholder="Please enter a value between 1 and 25"
            />
          </Form.Item>
          <Form.Item
            name="comment"
            label={
              <label
                style={{
                  color: 'white',
                  paddingTop: '10px',
                  paddingBottom: '4px',
                }}
              >
                Comment (optional)
              </label>
            }
          >
            <CommentLabel placeholder="Type your comment here" />
          </Form.Item>
        </Form>
      </>
    );
  }

  return (
    <StyledModal
      visible={visible}
      title={riskScore.name}
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            let riskScore = riskScoreValue;
            if (riskComponent.rule_type !== 'user_question') {
              if (isNaN(parseInt(values.riskScore))) {
                throw new Error('Input must be a number between 1-25');
              } else {
                riskScore = parseInt(values.riskScore);
              }
            }
            dispatch(
              updateAttributeDetail({
                assetId: assetId,
                riskModelId: proactiveRisk.model_id,
                componentId: riskComponent.component_id,
                comment: values.comment,
                riskScoreValue: riskScore,
              }),
            );
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      {ruleType === 'user_question' && questionRadioChoices().length > 0
        ? buildUserQuestion()
        : ruleType === 'user_score' || ruleType === ''
        ? buildUserScore()
        : null}
    </StyledModal>
  );
};

export default ScoreValue;
