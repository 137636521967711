import React from 'react';
import { Label } from '@luxe/components';

export default class Input extends React.Component {
  render() {
    return (
      <div className="form-group col-md-6">
        <label>{this.props.filter.description}</label>
        <br />
        <Label className="form-control" onChange={this.props.onChange} type="text" value={this.props.value} />
      </div>
    );
  }
}
