import { from, of } from 'rxjs';
import { filter, map, switchMap, catchError } from 'rxjs/operators';

const hasRequiredActionTypes = types => {
  const { pending, success, error } = types;
  return (
    pending &&
    typeof pending === 'string' &&
    success &&
    typeof success === 'string' &&
    error &&
    typeof error === 'string'
  );
};

const isAPICallAction = action => {
  if (action.type === 'API_CALL') {
    if (!hasRequiredActionTypes(action.types)) {
      throw new Error('Expected three types of actions: requestType, success, error');
    }
    return true;
  }
  return false;
};

export const apiCallPendingEpic = action$ => {
  return action$.pipe(
    filter(action => isAPICallAction(action)),
    map(action => ({ type: action.types.pending, payload: action.params })),
  );
};

export const apiCallEpic = action$ => {
  return action$.pipe(
    filter(action => isAPICallAction(action)),
    switchMap(action =>
      from(action.request()).pipe(
        map(response => ({
          type: action.types.success,
          payload: response.data,
          params: action.params,
        })),
        catchError(error =>
          of({
            type: action.types.error,
            payload: error.response.data,
            params: action.params,
          }),
        ),
      ),
    ),
  );
};
