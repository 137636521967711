import React from 'react';
import styled from '@emotion/styled';
import { Radio } from 'antd';

const DiscoverFacilitiesToggle = styled(({ value, discoveredFacilitiesToggleChange }) => {
  return (
    <Radio.Group
      className="discover-graph-mode"
      value={value ? 'show' : 'hide'}
      buttonStyle="solid"
      onChange={e => {
        discoveredFacilitiesToggleChange(e.target.value);
      }}
      size="small"
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        zIndex: 1,
      }}
    >
      <Radio.Button value="show">Show</Radio.Button>
      <Radio.Button value="hide">Hide</Radio.Button>
    </Radio.Group>
  );
})``;

export default DiscoverFacilitiesToggle;
