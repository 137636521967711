import React from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useMixpanel from 'utils/hooks/Mixpanel';

import FamilyPanel from './Panel';
import { Button } from '@luxe/components';
import { Container } from '@luxe/components/src/Container';
import {
  updateFamily,
  trackWorkFlowChange,
  updateFamilyRiskSelect,
  validateWorkflowUpdate,
  getWorkflow,
} from 'admin/workflows/modules/workflows';

import styled from '@emotion/styled';
import { Col, Row, Switch, ConfigProvider, Empty, TreeSelect, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Global, css } from '@emotion/react';
import { getCommodities } from 'modal/userprofile/modules/network';
import { updateWorkflow } from 'admin/workflows/modules/workflows';

const StyledButtons = styled(Button)`
  span {
    color: ${props => props.theme.colors.primary} !important;
    font-weight: 700;
    text-align: center;
  }
`;

const ThreatTab = styled(
  ({ workflows, router, updateFamily, checkNetworkCommodity, updateFamilyRiskSelect, ...props }) => {
    const { families } = workflows.workflow;
    const commoditiesValue = React.useMemo(() => {
      return workflows?.workflow?.data?.commodities ?? [];
    }, [workflows]);
    const mixpanelTrack = useMixpanel();
    const dispatch = useDispatch();
    const commodityPref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled || false);
    const { commodities } = useSelector(store => store.userNetwork);
    const [commodityRiskCheck, toggleCommodityRiskCheck] = React.useState(Boolean(commoditiesValue?.length));
    const [formattedCommodities, setFormattedCommodities] = React.useState([]);
    const toolTipStyling = { backgroundColor: '#141414', fontSize: '12px', color: '#ffffff' };

    const allSelected = families.every(item => item.status === 'all_selected');

    React.useEffect(() => {
      dispatch(getCommodities());
    }, [dispatch]);

    React.useEffect(() => {
      const populateChild = arr => {
        return arr.map(i => {
          return {
            title: i.name,
            value: i.id,
            key: i.id,
            children: i.children?.length > 0 ? populateChild(i.children) : [],
          };
        });
      };
      if (commodities?.length && formattedCommodities.length === 0) {
        setFormattedCommodities(populateChild(commodities));
      }
    }, [commodities, formattedCommodities.length]);

    React.useEffect(() => {
      if (commodityRiskCheck && !commoditiesValue?.length) {
        checkNetworkCommodity(true);
      } else {
        checkNetworkCommodity(false);
      }
    }, [commodityRiskCheck, commoditiesValue, checkNetworkCommodity]);

    const expandAll = () => {
      const ids = families
        .filter(x => x.children)
        .flatMap(x => x.children)
        .map(x => x.id);
      updateFamily(ids, { visible: true });
      mixpanelTrack('Workflow - Expand All -  Risk Categories');
    };

    const collapseAll = () => {
      const ids = families
        .filter(x => x.children)
        .flatMap(x => x.children)
        .map(x => x.id);
      updateFamily(ids, { visible: false });
      mixpanelTrack('Workflow - Collapse All -  Risk Categories');
    };

    const checkAll = e => {
      const ids = families
        .filter(x => x.children)
        .flatMap(x => x.children)
        .map(x => x.id);
      updateFamily(ids, { riskCategories: allSelected ? [] : ['medium', 'high'] });
    };
    function isIndividualRiskAll(arr, selectedRisk, initialValue) {
      let val = initialValue;
      for (const obj of arr) {
        if (obj.riskCategories.includes(selectedRisk)) {
          val.push(true);
        } else {
          val.push(false);
        }
        if (obj.children && obj.children.length > 0) {
          if (isIndividualRiskAll(obj.children, selectedRisk, val)) {
            val.push(true);
          } else {
            val.push(false);
          }
        }
      }
      return val.every(val => val);
    }

    const handleRiskSelect = riskType => {
      const ids = families
        .filter(x => x.children)
        .flatMap(x => x.children)
        .map(x => x.id);
      updateFamilyRiskSelect(ids, { riskType, riskTypeValue: !isIndividualRiskAll(families, riskType, []) });
      dispatch(trackWorkFlowChange(true));
    };

    const handleCommodityCheck = checked => {
      dispatch(trackWorkFlowChange(true));
      toggleCommodityRiskCheck(checked);
      if (!checked) {
        updateCommodities([]);
      }
    };

    const updateCommodities = commodities => {
      const currentData = props.workflow?.data;
      dispatch(
        updateWorkflow({
          data: {
            ...currentData,
            commodities,
          },
        }),
      );
      dispatch(trackWorkFlowChange(true));
    };

    const renderEmpty = () => (
      <Empty
        imageStyle={{ height: 40 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span style={{ color: 'white' }}> No Data </span>}
      />
    );

    return (
      <>
        <div className={props.className}>
          <Row justify="space-between">
            <Col className="d-flex">
              <StyledButtons type="text" onClick={expandAll}>
                Expand All
              </StyledButtons>
              <StyledButtons type="text" onClick={collapseAll}>
                Collapse All
              </StyledButtons>
            </Col>
            <Col>
              <StyledButtons type="text" onClick={checkAll}>
                {allSelected ? 'Deselect All' : 'Select All'}
              </StyledButtons>
            </Col>
          </Row>
          <br />
          <br />
          <Container style={{ color: 'white' }}>
            {families && families.filter(x => x.children && x.children.length > 0)?.length ? (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={9}></Col>
                <Col className="gutter-row d-flex" style={{ paddingLeft: '32px' }}>
                  <StyledButtons type="text" onClick={() => handleRiskSelect('low')}>
                    Low
                  </StyledButtons>
                  <StyledButtons type="text" onClick={() => handleRiskSelect('medium')}>
                    Medium
                  </StyledButtons>
                  <StyledButtons type="text" onClick={() => handleRiskSelect('high')}>
                    High
                  </StyledButtons>
                </Col>
              </Row>
            ) : null}
            {families &&
              families
                .filter(x => x.children && x.children.length > 0)
                .map(familyHeader => {
                  const children = familyHeader.children
                    .filter(x => x.children)
                    .map(x => <FamilyPanel family={x} key={x.id} workflows={workflows} />);
                  return (
                    <div key={familyHeader.id} className="family-section">
                      <h3 className="family-header">{familyHeader.name}</h3>
                      {children}
                    </div>
                  );
                })}
          </Container>
          <br />
        </div>
        {Boolean(commodityPref) && (
          <div className={props.className}>
            <header className="mb-1">
              <div className="commodity-section">
                <h1>Commodity Risk</h1>
                <div className="dis-flex">
                  <Switch
                    checked={commodityRiskCheck}
                    onChange={handleCommodityCheck}
                    className="inactive-switch mr-1"
                  />
                  <label className="mr-5">Network Incidents must additionally match selected Commodities</label>
                  <Tooltip
                    title="This Option allows to limit Incident been added to the Network Channel and Notifications to those matching the selected Incident Categories and Commodity Types"
                    overlayInnerStyle={toolTipStyling}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              </div>
            </header>
            {commodityRiskCheck && (
              <ConfigProvider renderEmpty={renderEmpty}>
                <>
                  <label>Commodities </label>
                  <Tooltip title=" Incidents that are relevant to a given commodity" overlayInnerStyle={toolTipStyling}>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <div className={`${props.className}`}>
                    <Global
                      styles={css`
                        .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
                          background-color: #2d8fa5 !important;
                          border-color: #2d8fa5 !important;
                        }
                      `}
                    />{' '}
                    <TreeSelect
                      allowClear={true}
                      name="commodities"
                      onChange={updateCommodities}
                      placeholder={'Select Commodities'}
                      showCheckedStrategy={TreeSelect.SHOW_CHILD}
                      showSearch={true}
                      treeCheckable={true}
                      treeData={formattedCommodities}
                      value={commoditiesValue}
                      dropdownStyle={{
                        backgroundColor: '#212121',
                      }}
                      filterTreeNode={(search, option) => {
                        return (
                          option.title
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </div>
                </>
              </ConfigProvider>
            )}
          </div>
        )}
      </>
    );
  },
)`
  .family-section {
    margin-bottom: 28px;
  }

  .family-header {
    color: #d0d0d0;
    opacity: 0.7;
    line-height: 40px;
    font-weight: 600;
    font-size: 16px;
    padding: 0 10px;
    margin-bottom: 14px;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
  .commodity-section {
    .dis-flex {
      display: flex;
      align-items: center;
    }
    .mr-5 {
      margin-right: 5px;
    }
  }

  .ant-picker-input > input {
    color: #ffffff;
  }

  .ant-select {
    background-color: #2c2c2c;
    color: #919191;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
  }
  .ant-tree-select {
    display: block;
    width: 50%;
  }
  .ant-select-tree,
  .ant-select-dropdown,
  .ant-tree-select-dropdown,
  .ant-tree-select-dropdown .ant-select-tree,
  .ant-select-multiple .ant-select-selection-item {
    background-color: #40424e;
    border: 1px solid #54596e;
    color: #ccc5b8;
    font-size: 85%;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #cccccc;
  }
  .loader {
    color: ${props => props.theme.colors.white};
  }

  label {
    color: ${props => props.theme.colors.white};
  }

  .commodity-country {
    width: 50%;
  }
  .ant-select-clear {
    background-color: transparent;
  }
  .inactive-switch {
    background-color: #38b1cc !important;
  }
  .inactive-switch:focus {
    box-shadow: none;
  }
`;

function mapStateToProps({ workflows, router }) {
  return {
    workflows,
    router,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFamily,
      updateFamilyRiskSelect,
      validateWorkflowUpdate,
      getWorkflow,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreatTab);
