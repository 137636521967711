import { Indice, IndiceScore, Indices, Risk, RiskChart, Score, Title } from '../styles';

import React from 'react';
import { Space } from 'antd';

const RiskStatus = ({ risk = {}, asset = {} }) => (
  <RiskChart>
    <Space size={150} direction={'horizontal'} align={'center'}>
      <div>
        <Space size={4} direction={'horizontal'}>
          <Risk>
            <Title>Strategic Score</Title>
            <Score category={risk.category || ''}>{risk.score || '-'}</Score>
            <Title>{risk.category}</Title>
          </Risk>
          <Indices>
            {risk.indices?.map((index, i) => (
              <Indice key={`header-score-${i}`} category={index.category || ''} title={index.name.toUpperCase()}>
                <IndiceScore>{index.score || '-'}</IndiceScore>
              </Indice>
            ))}
          </Indices>
        </Space>
      </div>
      <Risk>
        <Title>Incident Score</Title>
        <Score category={asset.risk?.category}>{asset.risk?.score || '-'}</Score>
        <Title>{asset.risk?.category}</Title>
      </Risk>
    </Space>
  </RiskChart>
);

export default RiskStatus;
