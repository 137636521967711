import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { updateInList, deleteRecursivelyFromList } from 'admin/utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_USER_GROUPS: 'GET_USER_GROUPS',
  GET_USER_GROUPS_ERROR: 'GET_USER_GROUPS_ERROR',
  GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',

  DELETE_USER_GROUPS: 'DELETE_USER_GROUPS',
  DELETE_USER_GROUPS_ERROR: 'DELETE_USER_GROUPS_ERROR',
  DELETE_USER_GROUPS_SUCCESS: 'DELETE_USER_GROUPS_SUCCESS',

  CREATE_USER_GROUPS: 'CREATE_USER_GROUPS',
  CREATE_USER_GROUPS_ERROR: 'CREATE_USER_GROUPS_ERROR',
  CREATE_USER_GROUPS_SUCCESS: 'CREATE_USER_GROUPS_SUCCESS',

  UPDATE_USER_GROUPS: 'UPDATE_USER_GROUPS',
  UPDATE_USER_GROUPS_ERROR: 'UPDATE_USER_GROUPS_ERROR',
  UPDATE_USER_GROUPS_SUCCESS: 'UPDATE_USER_GROUPS_SUCCESS',

  REMOVE_USER_FROM_GROUP: 'REMOVE_USER_FROM_GROUP',
  REMOVE_USER_FROM_GROUP_ERROR: 'REMOVE_USER_FROM_GROUP_ERROR',
  REMOVE_USER_FROM_GROUP_SUCCESS: 'REMOVE_USER_FROM_GROUP_SUCCESS',

  ADD_USERS_TO_GROUP: 'ADD_USERS_TO_GROUP',
  ADD_USERS_TO_GROUP_ERROR: 'ADD_USERS_TO_GROUP_ERROR',
  ADD_USERS_TO_GROUP_SUCCESS: 'ADD_USERS_TO_GROUP_SUCCESS',
};

export const getUserGroups = () =>
  callApi({
    types: {
      pending: TYPES.GET_USER_GROUPS,
      success: TYPES.GET_USER_GROUPS_SUCCESS,
      error: TYPES.GET_USER_GROUPS_ERROR,
    },
    request: () => axios.get('user-groups/'),
  });

export const deleteUserGroup = userGroupId =>
  callApi({
    types: {
      pending: TYPES.DELETE_USER_GROUPS,
      success: TYPES.DELETE_USER_GROUPS_SUCCESS,
      error: TYPES.DELETE_USER_GROUPS_ERROR,
    },
    request: () => axios.delete(`user-groups/${userGroupId}/`),
    messages: {
      success: 'User group has been successfully deleted!',
      error: 'User group deletion unsuccessful',
    },
  });

export const createUserGroups = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_USER_GROUPS,
      success: TYPES.CREATE_USER_GROUPS_SUCCESS,
      error: TYPES.CREATE_USER_GROUPS_ERROR,
    },
    request: () => axios.post('user-groups/', data),
    messages: {
      success: 'User groups have been successfully created!',
      error: 'User group creation unsuccessful',
    },
  });

export const updateUserGroups = (userGroupId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_USER_GROUPS,
      success: TYPES.UPDATE_USER_GROUPS_SUCCESS,
      error: TYPES.UPDATE_USER_GROUPS_ERROR,
    },
    request: () => axios.put(`user-groups/${userGroupId}/`, data),
    messages: {
      success: 'User groups have been successfully updated!',
      error: 'User group update unsuccessful',
    },
  });

export const removeUserFromGroup = (userGroupId, users, viewDataParams) => {
  let payload = { user_ids: users };
  return callApi({
    types: {
      pending: TYPES.REMOVE_USER_FROM_GROUP,
      success: TYPES.REMOVE_USER_FROM_GROUP_SUCCESS,
      error: TYPES.REMOVE_USER_FROM_GROUP_ERROR,
    },
    params: viewDataParams,
    request: () => axios.delete(`user-groups/${userGroupId}/users/`, { data: payload }),
    messages: {
      success: 'User has been successfully removed!',
      error: 'User removal unsuccessful',
    },
  });
};

export const addUsersToGroup = (userGroupId, data, viewDataParams) => {
  const payload = { user_ids: data };
  return callApi({
    types: {
      pending: TYPES.ADD_USERS_TO_GROUP,
      success: TYPES.ADD_USERS_TO_GROUP_SUCCESS,
      error: TYPES.ADD_USERS_TO_GROUP_ERROR,
    },
    params: viewDataParams,
    request: () => axios.post(`user-groups/${userGroupId}/users/`, payload),
    messages: {
      success: 'User have been successfully added to group!',
      error: 'User addition to group unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  userGroups: [],
  error: undefined,
  params: undefined,
};

export const userGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_USER_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        userGroups: action.payload,
        error: undefined,
      };
    case TYPES.GET_USER_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_USER_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_USER_GROUPS_SUCCESS:
      const deletedUserGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        userGroups: [...deleteRecursivelyFromList(deletedUserGroup, state.userGroups)],
        error: undefined,
      };
    case TYPES.DELETE_USER_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.CREATE_USER_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_USER_GROUPS_SUCCESS:
      const newUserGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        userGroups: [...state.userGroups, newUserGroup],
        error: undefined,
      };
    case TYPES.CREATE_USER_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_USER_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_USER_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        userGroups: [...updateInList(action.payload, state.userGroups)],
        error: undefined,
      };
    case TYPES.UPDATE_USER_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.REMOVE_USER_FROM_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.REMOVE_USER_FROM_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.ADD_USERS_TO_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.ADD_USERS_TO_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.ADD_USERS_TO_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    default:
      return state;
  }
};
