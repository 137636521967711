import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Form, Layout, Row, Spin } from 'antd';
import { Button, Label, Section, StyledForm } from '@luxe/components';
import { RadioGroup, Table } from './styles';
import { updateAttributeBatch } from 'admin/assets/modules/assets';
import { isEmpty } from 'utils';
import { getProactiveRisk } from 'admin/riskmodels/modules/riskmodels';

const MassEditForm = ({ asset, proactiveRisk, updateAttributeBatch, ...props }) => {
  const { riskIndex: riskIndexId, riskModelId, id: assetId } = useParams();
  const riskIndexRef = useRef(riskIndexId);
  const backUrl = `/assets/${asset.id}/risk`;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!proactiveRisk || isEmpty(proactiveRisk) || proactiveRisk.model_id.toString() !== riskModelId.toString()) {
      dispatch(getProactiveRisk({ assetId, riskModelId }));
    }
  }, [assetId, dispatch, proactiveRisk, riskModelId]);

  if (!proactiveRisk || isEmpty(proactiveRisk)) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const onRadioChange = e => {
    const field = form.getFieldsValue()[e.target.name];
    field.display = e.target.value.split(' ')[0];
    field.value = e.nativeEvent.target.labels[0].innerText;
    form.setFieldsValue({ [e.target.name]: field });
  };

  const getColumns = () => [
    { title: 'Score Name', dataIndex: 'name' },
    { title: 'Index', dataIndex: 'index' },
    { title: 'Subindex', dataIndex: 'subindex' },
    {
      title: 'Old Score',
      render: attribute => getOldScoreColumn(attribute),
    },
    {
      title: 'New Score',
      render: attribute => getScoreColumn(attribute),
    },
    {
      title: 'Comment (optional)',
      render: attribute => getCommentColumn(attribute),
    },
  ];

  const getOldScoreColumn = attribute => {
    const userQuestion = attribute.rule_type === 'user_question';
    let oldScore = '';
    if (!attribute.value) {
      oldScore = '-';
    } else {
      if (userQuestion) {
        attribute.rule.values.forEach(choice => {
          if (choice.value === attribute.value) {
            oldScore = String(choice.risk);
          }
        });
      } else {
        oldScore = String(attribute.value);
      }
    }
    return oldScore;
  };

  const getScoreColumn = attribute => {
    const userQuestion = attribute.rule_type === 'user_question';
    if (userQuestion) {
      return (
        <>
          <Form.Item hidden={true} name={[attribute.id, 'value']} />
          <Form.Item name={[attribute.id, 'display']}>
            <Label style={{ width: '50px' }} readOnly={true} />
          </Form.Item>
        </>
      );
    } else {
      return (
        <Form.Item
          name={[attribute.id, 'value']}
          rules={[
            {
              required: false,
              message: 'Score value must be an integer between 1 and 25',
              pattern: new RegExp(/\b([1-9]|1[0-9]|2[0-5])\b/),
            },
          ]}
        >
          <Label style={{ width: '50px' }} />
        </Form.Item>
      );
    }
  };

  const getCommentColumn = attribute => {
    return (
      <Form.Item name={[attribute.id, 'comment']}>
        <Label />
      </Form.Item>
    );
  };

  const generateHeader = columns => {
    const cols = [];
    columns.forEach((column, key) => {
      cols.push(<th key={key}>{column.title}</th>);
    });
    return (
      <thead key={0}>
        <tr className="header" key={0}>
          {cols}
        </tr>
      </thead>
    );
  };

  const generateRow = (columns, row, key, className) => {
    const cols = [];
    columns.forEach((column, colKey) => {
      const content = 'dataIndex' in column ? row[column.dataIndex] : column.render(row);
      cols.push(<td key={`${column.title}-${key}`}>{content}</td>);
    });
    return (
      <tr className={className} key={key}>
        {cols}
      </tr>
    );
  };

  const generateSubRow = (row, key, className) => {
    const radioChoices = [];
    row.rule.values.forEach(choice => {
      radioChoices.push({ label: choice.value, value: `${choice.risk} ${choice.value}` });
    });
    let value = row.rule.values.find(element => element.value === row.value);
    if (value) value = `${value.risk} ${value.value}`;
    return (
      <tr className={className} key={`subrow-${row.id}-${key}`}>
        <td colSpan="6" className="info">
          <div className="question">{row.rule.options.question}</div>
          <RadioGroup options={radioChoices} onChange={onRadioChange} name={row.id} defaultValue={value} />
        </td>
      </tr>
    );
  };

  const generateRows = (columns, data) => {
    const rows = [];
    data.forEach((row, key) => {
      const className = key % 2 !== 0 ? 'alt' : '';
      rows.push(generateRow(columns, row, key, className));
      if (row.rule_type === 'user_question') {
        rows.push(generateSubRow(row, key, className));
      }
    });
    return <tbody key={1}>{rows}</tbody>;
  };

  const generateTableRows = (columns, data) => {
    const rows = [];
    rows.push(generateHeader(columns));
    rows.push(generateRows(columns, data));
    return rows;
  };

  const MassEditTable = ({ attributes }) => {
    const columns = getColumns();
    const rows = generateTableRows(columns, attributes);
    return (
      <div>
        <Table style={{ tableLayout: 'auto' }}>{rows}</Table>
      </div>
    );
  };

  const Cancel = () => {
    return (
      <Link to={backUrl}>
        <Button variant="secondary" type="link">
          Cancel
        </Button>
      </Link>
    );
  };

  const Save = () => {
    return (
      <Button variant="primary" onClick={saveChanges}>
        Save
      </Button>
    );
  };

  const saveChanges = () => {
    const data = [];
    form.validateFields().then(values => {
      Object.keys(values).forEach(key => {
        if (values[key].value) {
          data.push({
            comment: values[key].comment || '',
            id: key,
            value: values[key].value,
          });
        }
      });
      updateAttributeBatch(asset.id, proactiveRisk.model_id, data);
      history.push(backUrl);
    });
  };

  function getEditableAttributes(proactiveRisk, asset, riskIndexRef) {
    if (!proactiveRisk || isEmpty(proactiveRisk)) {
      return false;
    }

    const editableRuleTypes = ['user_score', 'user_question'];
    const editable = proactiveRisk.indices.reduce((acc, index) => {
      if (index.risk_index_id === parseInt(riskIndexRef.current)) {
        index.indices.forEach(innerIndex => {
          innerIndex.components.forEach(comp => {
            if (editableRuleTypes.indexOf(comp.rule_type) > -1) {
              acc.push({
                id: comp.component_id,
                index: index.name,
                name: comp.name,
                rule: comp.rule,
                subindex: innerIndex.name,
                rule_type: comp.rule_type,
                value: comp.value,
              });
            }
          });
        });
      }
      return acc;
    }, []);

    return editable;
  }

  const editable = getEditableAttributes(proactiveRisk, asset, riskIndexRef);

  return (
    <Section
      title={'Edit Scores'}
      buttons={
        <>
          <Cancel /> <Save />
        </>
      }
    >
      <Row style={{ width: '100%', display: 'block' }}>
        <StyledForm form={form}>
          <MassEditTable attributes={editable} form={form} />
        </StyledForm>
      </Row>
    </Section>
  );
};

function mapStateToProps({ assets, riskModels }) {
  return {
    asset: assets.Asset,
    proactiveRisk: riskModels.proactiveRisk,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAttributeBatch,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MassEditForm);
