import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import Menu from './Menu';
import List from './List';
import Detail from './Detail';
import PlanDetail from './PlanDetail';

import { getViewMeta } from 'views/modules/views';
import { getThreat } from 'riskfeed/modules/riskfeed';

import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'modal/userprofile/modules/network';

const RiskFeedView = styled(({ ...props }) => {
  const error = useSelector(state => state.riskfeed.error);
  const { countries: countriesList } = useSelector(store => store.userNetwork);
  const dispatch = useDispatch();
  const { id: threatId = null, tab } = useParams();
  const [prevTab, setPrevTab] = useState('');
  useEffect(() => {
    dispatch(getViewMeta('events/threats'));
  }, [dispatch]);

  useEffect(() => {
    if (threatId) {
      dispatch(getThreat(threatId));
    } else {
      dispatch(getThreat(null));
    }
  }, [threatId, dispatch]);

  useEffect(() => {
    if (threatId && prevTab === 'plan') {
      dispatch(getThreat(threatId));
    }
    setPrevTab(tab);
  }, [threatId, tab, dispatch, prevTab]);

  useEffect(() => {
    if (!countriesList?.length) {
      dispatch(getCountries());
    }
  }, [dispatch, countriesList]);

  return (
    <div className={`${props.className} grid-container`}>
      <Menu />
      {error ? (
        <div className="list">
          <header>
            <h1>Action Center</h1>
            <h3>An Error Occurred</h3>
            <p>Please refresh your browser</p>
          </header>
        </div>
      ) : (
        <List />
      )}
      {window.location.pathname.includes('plan') ? <PlanDetail /> : <Detail />}
    </div>
  );
})`
  &.grid-container {
    display: flex;
    flex-grow: 1;
    width: 100%;

    * {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    td,
    th,
    a {
      color: white;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 400;
    }
  }
  &.grid-container {
    .menu,
    .list,
    .detail {
      align-items: stretch;
    }
    .menu {
      border-right: 1px solid rgba(20, 20, 20, 1);
      width: 280px;
      min-width: 280px;
    }
    .list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      width: calc(40% - 280px);
      min-width: calc(40% - 280px);

      & > section.scrollable {
        overflow: auto;
      }
    }
    .detail {
      width: calc(60% - 280px);
      min-width: calc(60% - 280px);
      overflow: auto;
      flex-grow: 1;
    }
  }
`;
export default RiskFeedView;
