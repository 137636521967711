import React from 'react';
import { operators } from 'constants/application';

export const toFilter = (columnPart, value) => {
  const opIndex = columnPart.lastIndexOf('__');
  const hasOperator = opIndex > -1;
  let operatorName = hasOperator ? columnPart.substring(opIndex + 2) : undefined;
  let filterValue = value;

  let name = columnPart;
  if (hasOperator) {
    const isValidOperator = Object.keys(operators).includes(operatorName);
    name = isValidOperator ? columnPart.substring(0, opIndex) : columnPart;
    operatorName = isValidOperator ? operatorName : 'exact';
    // throw new Error(name + ':' + operatorName + ':' + opIndex + ':' + columnPart);
  } else {
    operatorName = 'exact';
  }

  try {
    filterValue = JSON.parse(value);
  } catch (error) {
    console.warn('Parsing Failed:', error);
    filterValue = value;
  }

  if (operatorName === 'between') {
    filterValue = value.split(',');
  }

  const operator = operators[operatorName];

  return {
    name,
    operator,
    value: filterValue,
  };
};

/*
 The purpose of this function is to piece
 together the meta and marry it up with
 the columns we sent against the api.
*/
export const getCurrent = (data, meta, additionalColumns) => {
  const columnsMeta = Object.assign(
    ...meta.allowed.columns.map(column => {
      return {
        [column.name]: column,
      };
    }),
  );
  let columns = data.columns.map(name => {
    let column = columnsMeta[name];
    return column;
  });
  if (additionalColumns) {
    columns = [...columns, ...additionalColumns];
  }
  return {
    ...data,
    columns,
  };
};

export const getParams = (params, excludedArgs = []) => {
  let paramParser = params;
  excludedArgs.push('connection');
  if (typeof params === 'string') {
    const parts = params.split('?');
    const querystring = parts.length > 1 ? params.substring(parts[0].length + 1) : '';
    paramParser = new URLSearchParams(querystring);
  }
  const newParams = {};
  paramParser.forEach((value, key) => {
    if (excludedArgs.includes(key)) {
      return;
    }

    newParams[key] = value;
  });

  const { offset = 0, limit = 15, order_by = undefined, columns = undefined, ...filterParams } = newParams;
  let filters = undefined;
  if (filterParams && Object.keys(filterParams)) {
    filters = Object.entries(filterParams).map(([columnPart, value]) => {
      let filter = toFilter(columnPart, value);
      return filter;
    });
  }

  const data = {
    offset,
    limit,
    filters,
  };
  if (order_by) {
    data.order_by = order_by.split(',').map(orderItem => {
      const direction = orderItem.startsWith('-') ? 'desc' : 'asc';
      const name = direction === 'desc' ? orderItem.substring(1) : orderItem;
      return {
        name,
        direction,
      };
    });
  } else {
    data.order_by = [];
  }
  if (columns) {
    data.columns = columns;
  }
  return data;
};

//TODO: getUrl --> create url params
export const getUrl = (meta, paramsToKeep = ['channel']) => {
  const url = new URL(window.location);
  Array.from(url.searchParams.keys()).map(key => !paramsToKeep.includes(key) && url.searchParams.delete(key));
  const hasOrderBy = meta.current.order_by && meta.current.order_by.length > 0;
  const hasFilters = meta.current.filters && meta.current.filters.length > 0;
  if (!hasOrderBy && !hasFilters) {
    return `${url.pathname}${url.search}${url.hash}`;
  }

  if (hasFilters) {
    meta.current.filters.forEach(x => {
      const op = Object.entries(operators)
        .map(([name, value]) => {
          return { name, value };
        })
        .find(filter => filter.value === x.operator);
      if (op !== undefined) {
        const filter_value = typeof x.value === 'string' ? x.value : JSON.stringify(x.value);
        if (op.value === '==') {
          url.searchParams.set(x.name, filter_value);
        } else if (x.name !== 'threat__start') {
          url.searchParams.set(`${x.name}__${op.name}`, filter_value);
        }
      }
    });
  }

  if (hasOrderBy) {
    url.searchParams.set(
      'order_by',
      meta.current.order_by.map(x => `${x.direction === 'desc' ? '-' : ''}${x.name}`).join(','),
    );
  }

  if (meta.current.limit !== undefined) {
    url.searchParams.set('limit', meta.current.limit);
  }
  return `${url.pathname}${url.search}${url.hash}`;
};

export const expandColumns = (allowed, overrides) => {
  const nestedColumns = allowed.columns
    .filter(col => col.columns)
    .flatMap(col => col.columns)
    .map(x => {
      const override = overrides && overrides[x.name] ? overrides[x.name] : {};
      return { ...x, ...override, nested: true };
    });
  return [...allowed.columns, ...nestedColumns];
};

export const parseOptions = (config, response) => {
  const items = response.results ? response.results : response;
  if (config.parseOptions) {
    return config.parseOptions(response);
  }
  const { valueKey = 'id' } = config;
  const { labelKey = 'name' } = config;
  return items.map(x => {
    return {
      value: x[valueKey],
      label: x[labelKey],
      display_name: x[labelKey],
    };
  });
};

export function buildActionsColumn(actionButtons = [], idColumn) {
  return {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions-column',
    render: (_, data) => {
      return (
        <div>
          {actionButtons.map((actionButton, i) => {
            const id = resolve(idColumn, data);
            return <Icon key={`id-${i}`} type={actionButton.icon} onClick={() => actionButton.action(id)} />;
          })}
        </div>
      );
    },
  };
}

const Icon = ({ type, ...rest }) => {
  // AntD decided to remove support for dynamic icons and instead you have to import the icon you want.
  // So this is importing the whole library to be able to use any icon
  // Should probably figure out an alternate solution here
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return Component ? <Component {...rest} style={{ marginRight: '5px' }} /> : null;
};

function resolve(path, obj) {
  return path.split('.').reduce(function(prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
}
