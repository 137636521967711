import React, { useCallback, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { Button, DeleteIcon, FormItem, Label as Input, Numeric, PlusIcon, RadioGroup } from '@luxe/components';

const RiskScoreComponentForm = ({
  weight,
  label,
  form,
  riskModelScore,
  rule,
  ruleType = 'datasource',
  setRuleType,
  showWeightedScoreElement,
}) => {
  const uneditableRiskScores = [
    'Cyber / IT Security',
    'Capacity',
    'Sourcing',
    'Financial',
    'Regulatory Compliance',
    'Sustainability',
    'Material',
    'Intellectual Property',
    'Site Audit',
    'Business Continuity Plan',
    'Flexibility',
    'Relationship',
    'Delivery',
    'Quality',
  ];
  const getAttributeData = useCallback(() => {
    const data = {
      modifier: 'public',
      choices: [''],
      name: label,
      weight: weight || 0,
      rule_type: ruleType,
    };

    if (ruleType === 'user_question' && rule) {
      data.question = rule.options.question;
      data.choices = rule.values || [''];
    }
    return data;
  }, [label, weight, ruleType, rule]);

  useEffect(() => {
    const fieldData = getAttributeData();
    for (let prop in fieldData) {
      form.setFieldsValue({ [prop]: fieldData[prop] });
    }
  }, [form, getAttributeData]);

  const fieldType = (formType, placeholder, props = {}, checkDisabled = true) => {
    switch (formType) {
      case 'text':
        return (
          <Input
            {...props}
            placeholder={placeholder}
            {...(checkDisabled && { disabled: uneditableRiskScores.includes(label) })}
          />
        );
      case 'numeric':
        return <Numeric placeholder={placeholder} {...props} />;
      case 'options':
        return <RadioGroup {...props} />;
      default:
        break;
    }
  };

  const getFormItem = data => {
    const children = [];
    data.forEach((f, i) => {
      if (!showWeightedScoreElement() && f.name === 'weight') {
        return; // skip weight element when not showing weighted score elements
      }
      children.push(
        <Form.Item
          name={f.name}
          label={<label style={{ color: 'white' }}>{f.label}</label>}
          rules={f.rules}
          key={i}
          shouldUpdate
        >
          {fieldType(f.type, f.placeholder, f.props, f?.disabled)}
        </Form.Item>,
      );
    });
    return children;
  };

  const formFields = {
    base: [
      {
        label: 'Name:',
        rules: [{ required: true, message: 'Please input the title of Risk Score!' }],
        placeholder: 'Please input the title of Risk Score',
        name: 'name',
        type: 'text',
      },
      {
        label: 'Weight:',
        rules: [{ required: true, message: 'Please input a Total Weight Value!' }],
        placeholder: '0',
        name: 'weight',
        type: 'numeric',
        props: {
          min: 0,
          max: 100,
        },
      },
    ],
    editable: [
      {
        label: 'Rule Type:',
        rules: [{ required: true, message: 'Please choose a type for this rule!' }],
        name: 'rule_type',
        type: 'options',
        props: {
          options: [
            { label: 'User Score', value: 'user_score' },
            { label: 'User Question', value: 'user_question' },
          ],
          onChange: e => setRuleType(e.target.value),
        },
      },
    ],
    userQuestion: [
      {
        label: 'Question:',
        rules: [{ required: true, message: 'Please input your question!' }],
        placeholder: 'Enter your question',
        name: 'question',
        type: 'text',
        disabled: false,
      },
    ],
  };

  function editableRuleFields() {
    if (ruleType === 'user_question') {
      return buildUserQuestion();
    }
  }

  function buildUserQuestion() {
    return (
      <>
        {getFormItem(formFields.userQuestion)}
        <Form.List name="choices">
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, i) => {
                  return <Answers fields={fields} field={field} index={i} remove={remove} add={add} key={field.key} />;
                })}
                <Form.Item>
                  <Button
                    onClick={() => {
                      add();
                    }}
                    variant="alternate"
                    style={{ margin: '0' }}
                  >
                    <PlusIcon variant="alternate" />
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="createRiskScoreAttribute"
      initialValues={getAttributeData()}
      preserve={false}
    >
      {getFormItem(formFields.base)}
      {riskModelScore?.user_editable && getFormItem(formFields.editable)}
      {editableRuleFields()}
    </Form>
  );
};

const Answers = ({ fields, field, index, remove, add }) => {
  const answers = [
    {
      label: 'Answer',
      rules: [{ required: true, message: 'Please input your answer!' }],
      placeholder: 'Please input your answer',
      name: 'value',
      type: 'text',
    },
    {
      label: 'Value',
      rules: [
        { required: true, message: 'Please input a value!' },
        { message: 'Value must be between 1 and 25', type: 'number', max: 25, min: 1 },
      ],
      name: 'risk',
      type: 'numeric',
    },
  ];

  return (
    <Row gutter={24}>
      {answers.map((f, i) => {
        const label = f.name === 'value' ? `${f.label} ${index + 1}:` : `${f.label}:`;
        return (
          <Col span={f.name === 'value' ? 12 : 6} key={`${field.key}:${i}`}>
            <FormItem
              {...field}
              rules={f.rules}
              name={[field.name, f.name]}
              label={<label style={{ color: 'white' }}>{label}</label>}
              required={true}
            >
              {f.type === 'text' ? (
                <Input placeholder={f.placeholder} width={'100%'} maxLength={100} />
              ) : (
                <Numeric placeholder={f.placeholder} />
              )}
            </FormItem>
          </Col>
        );
      })}
      <Col span={6}>
        <Form.Item style={{ position: 'relative', top: '30px', textAlign: 'end' }}>
          <Button
            variant="alternate"
            onClick={() => {
              remove(field.name);
              if (fields.length <= 1) {
                add();
              }
            }}
          >
            <DeleteIcon variant="alternate" />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default RiskScoreComponentForm;
