import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import Tooltip from './Tooltip';
import { Styles, StyledLabel, StyledMark, StyledThumb } from './styles';

const TOTAL_WIDTH_PERCENTAGE = 100;
const METRIC = 'metric';
const IMPERIAL = 'imperial';

const Label = ({ offset, value }) => {
  return <StyledLabel offset={offset}>{value}</StyledLabel>;
};

const Mark = ({ offset }) => {
  return <StyledMark offset={offset}></StyledMark>;
};

const RiskSlider = ({ min = 2, max = 25, marks = {}, onChange = () => null, disabled }) => {
  const userUnitOfMeasurePreference = useSelector(store => store.account.account?.prefs.units.default);
  const rule = useSelector(store => store.riskProfiles?.RiskProfileRule);
  const rule_type = rule.catalog.type;
  const uom = rule.data.options?.uom ? rule.data.options.uom : {};
  const sliderValues = rule.data.values;

  const onThumbMoved = (event, index) => {
    event.preventDefault();
    let updatedValues = [...sliderValues];
    updatedValues[index].risk = parseFloat(event.target.value);
    onChange(updatedValues);
  };

  const onTooltipDeleted = index => {
    if (sliderValues.length > 1) {
      sliderValues.splice(index, 1);
      onChange([...sliderValues]);
    }
  };

  const onInputChange = (index, value) => {
    let updatedValues = [...sliderValues];
    /*
      TODO: We are forcing the input to always have a value, and taking advantage of no input
      being 0 to allow for good user experience for negative numbers
      */
    const mappedVal = value === '0-' || value === '-0' ? '-' : value;
    const val = mappedVal === '-' ? mappedVal : parseFloat(mappedVal) || 0;
    updatedValues[index].value = val;
    onChange(updatedValues);
  };

  const getOffset = value => {
    const markerDistance = TOTAL_WIDTH_PERCENTAGE / (max - min);
    return (value - min) * markerDistance;
  };

  const renderThumbs = values => {
    return values.map((value, index) => (
      <StyledThumb
        key={index}
        type="range"
        value={value.risk}
        min={min}
        max={max}
        onChange={event => onThumbMoved(event, index)}
        disabled={disabled}
      />
    ));
  };

  const renderMarks = marks => {
    let marksComponents = [];
    for (const [key, value] of Object.entries(marks)) {
      const offset = getOffset(key);
      marksComponents.push(<Label offset={offset} value={value} key={`${key}-label`} />);
      marksComponents.push(<Mark offset={offset} key={`${key}-mark`} />);
    }

    return marksComponents;
  };

  const unitOfMeasureHandler = uom => {
    let displayUom = '';
    if (Object.keys(uom).length > 0) {
      displayUom = userUnitOfMeasurePreference === METRIC ? uom[METRIC] : uom[IMPERIAL];
    } else if (rule_type === 6) {
      // Temperature duration has degF as units but the display uom has to be hours
      displayUom = 'hours';
    }
    return displayUom;
  };

  let unitOfMeasure = unitOfMeasureHandler(uom);

  const renderTooltips = values => {
    return values.map((value, index) => {
      const offset = getOffset(value.risk);
      return (
        <Tooltip
          key={index}
          offset={offset}
          value={value}
          type={rule.catalog.type}
          onClose={() => onTooltipDeleted(index)}
          onChange={value => onInputChange(index, value)}
          unitOfMeasure={unitOfMeasure}
          disabled={disabled}
        />
      );
    });
  };
  return (
    <Styles>
      <div className="track-wrapper">
        <div className="track" />
        {renderMarks(marks)}
        {renderTooltips(sliderValues)}
      </div>
      <div className="thumbs-wrapper">{renderThumbs(sliderValues)}</div>
    </Styles>
  );
};

RiskSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.object,
  onValueChange: PropTypes.func,
  onValueDelete: PropTypes.func,
};

export default RiskSlider;
