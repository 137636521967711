import React from 'react';
import PropTypes from 'prop-types';

export default class ModeSelector extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onClick = e => {
    let value = e.target.attributes['data-value'].value;
    if (this.props.value === value) {
      value = null;
    }

    this.props.onChange({ target: { value } });
  };

  get values() {
    return ['RAIL', 'TRUCK', 'FERRY'].map(value => {
      const selectedClass = this.props.value === value ? 'primary' : 'secondary';
      return (
        <button
          key={value}
          onClick={this.onClick}
          type="button"
          data-value={value}
          className={`btn btn-${selectedClass}`}
        >
          {value}
        </button>
      );
    });
  }

  render() {
    return (
      <div className="form-group col-md-7" style={{ marginTop: '25px' }}>
        <div className="btn-group" data-toggle="buttons">
          {this.values}
        </div>
      </div>
    );
  }
}
