import React, { useState } from 'react';
import styled from '@emotion/styled';

import FiltersView from './Filters';
import { Link } from 'react-router-dom';

import FilterIcon from 'layout/icons/filters.svg';
import FolderIcon from 'layout/icons/folder.svg';

import useMixpanel from 'utils/hooks/Mixpanel';
import { urlParam } from 'utils';

import { useSelector } from 'react-redux';

const menus = [
  ['NETWORK_SUMMARY', 'My Network'],
  ['MAJOR_EVENT', 'Major'],
  ['INDUSTRY_COMMODITY', 'Industry'],
  ['GLOBAL_EVENTS', 'Category'],
  ['ALL_EVENTS', 'All Incidents'],
];

const planMenu = ['MY_PLANS', 'My Plans'];
const Menu = styled(({ ...props }) => {
  const mixpanelTrack = useMixpanel();
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { channel = 'NETWORK_SUMMARY' } = urlParam();
  const accountOrg = useSelector(store => store.accountOrg)?.accountOrg;
  const isDiscoverEnabled = accountOrg?.prefs?.discover?.enabled;

  if (isDiscoverEnabled) {
    if (
      !menus.find(x => {
        return x?.[0] === 'MY_SUB_TIER_NETWORK';
      })
    ) {
      menus.splice(1, 0, ['MY_SUB_TIER_NETWORK', 'My Discovered Network']);
    }
    if (
      !menus.find(x => {
        return x?.[0] === 'ORG_SUB_TIER_NETWORK_SUMMARY';
      })
    ) {
      menus.splice(menus?.length - 1, 0, ['ORG_SUB_TIER_NETWORK_SUMMARY', 'My Org’s Discovered Network']);
    }
  }
  const action_plan = accountOrg?.prefs?.action_plan?.enabled;
  const sideMenus = action_plan ? [...menus, planMenu] : menus;

  const clickEvents = val => {
    mixpanelTrack('Action Center - Click Channel', {
      Channel: channel,
    });
    setSelectedChannel(val);
  };

  const channels = sideMenus.map(([value, name]) => {
    let className;
    if (selectedChannel) {
      className = value === selectedChannel ? 'active' : undefined;
    } else {
      className = value === channel ? 'active' : undefined;
      if (!selectedChannel && channel) setSelectedChannel(channel);
    }
    return (
      <li className={className} key={value}>
        <span
          onClick={() => {
            clickEvents(value);
          }}
        >
          <Link to={`/risk-feed/?channel=${value}`}>{name}</Link>
        </span>
      </li>
    );
  });

  return (
    <div className={`${props.className} menu`}>
      <div className="incidents-menu">
        <h3>
          <span className="icon">
            <img src={FolderIcon} alt="Incidents" />
          </span>
          Incidents
        </h3>
        <ul className="nav">{channels}</ul>
      </div>
      <div className="filters-menu">
        <h3>
          <span className="icon">
            <img src={FilterIcon} alt="Filters" />
          </span>
          Filters
        </h3>
        <FiltersView />
      </div>
    </div>
  );
})`
  &.menu {
    padding: 8px;
    .menu-icon {
      margin: 24px 0 48px 16px;
      padding: 3px;
    }

    & > div > h3 {
      font-weight: 400;
      font-size: 14px;
      padding-left: 48px;
      line-height: 32px;
      color: #dedede;
      margin: 0;
      margin-bottom: 8px;

      span.icon {
        position: absolute;
        left: 26px;
        margin-top: -2px;
      }
    }

    & > div.incidents-menu,
    & > div.filters-menu {
      padding: 24px 0;
    }

    .incidents-menu {
      border-bottom: 1px solid #b1b1b1;

      ul.nav {
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          margin: 0;
          margin-bottom: 8px;
          list-style-type: none;
          color: #dedede;
          line-height: 32px;
          font-size: 14px;

          padding-left: 48px;

          span.icon {
            position: absolute;
            left: 26px;
            margin-top: 2px;
          }

          &.active {
            background-color: #2d8fa5;
          }
        }
      }
    }
  }
`;
export default Menu;
