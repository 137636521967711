import React, { useState } from 'react';
import { Tabs } from 'antd';
import { TabContainer } from 'assets/containers/AssetDetailView/styles';

const TabView = ({ tabs }) => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  const onTabChange = key => {
    const tab = tabs.find(tab => tab.title === key);
    if (tab) {
      setActiveTab(tab.title);
    }
  };

  return (
    <>
      <TabContainer onChange={onTabChange} activeKey={activeTab} style={{overflow: 'visible'}}>
        {tabs.map(tab => {
          return (
            <TabPane tab={tab.title} key={tab.title}>
              {tab.content}
            </TabPane>
          );
        })}
      </TabContainer>
    </>
  );
};

export default TabView;
