import React from 'react';
import { useSelector } from 'react-redux';

import { flexbox } from 'styled-system';
import styled from '@emotion/styled';

import { Row } from 'antd';

const HeadlineCount = styled(({ title, count, className }) => {
  const displayCount = count ? count : '-';
  return (
    <div className={className}>
      <h3>{title}</h3>
      <div className="score-values">{displayCount}</div>
    </div>
  );
})`
  ${flexbox}
  display: flex;
  flex-direction: column;
  margin: 1px 15px 15px 15px;
  text-align: center;
  h3 {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small}px;
    font: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.lightGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .score-values {
    font: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.risk};
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
    padding: 3px 36px 3px 36px;
    height: 43px;
  }
`;

const ActiveCountryThreats = () => {
  const { region = {} } = useSelector(store => store.regions);
  const { summary = {} } = region;
  if (!summary) {
    return null;
  }

  const counts = [
    ['Active Incidents', summary.incidents?.total],
    ['Tracked Risks', summary.incidents?.tracked],
    ['Facilities', summary.assets?.total],
    ['Lanes', summary.lanes?.total],
    ['Shipments', summary.shipments?.total],
  ].map(([title, count], i) => {
    return <HeadlineCount key={i} title={title} count={count} />;
  });
  return <Row>{counts}</Row>;
};

export default ActiveCountryThreats;
