import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/lanes/modules/lanes';
import { TYPES as WORKFLOWS_TYPES } from 'admin/workflows/modules/workflows';
import { push } from 'connected-react-router';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { getLanes } from 'admin/lanes/modules/lanes';
import { getParams } from 'views/utils';
import { clearSelection, getViewData } from 'views/modules/views';
import { ofType } from 'redux-observable';

export const createLanesRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.CREATE_LANE_SUCCESS),
    map(action => push(`/admin/lanes/${action.payload.id}/`)),
  );
};

//TODO: check if RiskProfiles is already loaded
//state$.getState() -- redux-observable
//rxjs - observable library -- screen of streamed data (promise: succes || error) -- observer pattern
export const getRiskProfilesWithLanesEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_LANES_SUCCESS),
    map(() => getRiskProfiles()),
  );
};
//TODO: epic to intercept CREATE_LANES_SUCCESS || DELETE_LANES_SUCCESS -- GET_LANES action/getLanes()
//map() needs to always call an action -- return will pass straight into reducer

//redirect after delete
//history.push(getLanesPageUrl(laneId));
export const deleteLaneRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_LANE_SUCCESS),
    map(() => push(`/admin/lanes/`)),
  );
};

export const deleteLaneAndGetLanesRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_LANE_SUCCESS),
    map(() => getLanes()),
  );
};

export const deleteLanesRedirectEpic = (action$, state$) => {
  const { search: url } = state$.value.router.location;
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_LANES_SUCCESS),
    map(() => {
      let query = {
        columns: ['lane', 'risk'],
        order_by: [{ name: 'risk__score', direction: 'desc' }],
        ...getParams(url),
        url,
      };
      return getViewData('lanes', query);
    }),
  );
};

export const clearSelectionAfterDeleteLane = action$ =>
  action$.pipe(
    ofType(TYPES.DELETE_LANES_SUCCESS),
    map(() => {
      return clearSelection('lanes');
    }),
  );

export const clearSelectionAfterDeleteWorkflow = action$ =>
  action$.pipe(
    ofType(WORKFLOWS_TYPES.DELETE_WORKFLOWS_SUCCESS),
    map(() => {
      return clearSelection('workflows');
    }),
  );

const epics = {
  createLanesRedirectEpic,
  getRiskProfilesWithLanesEpic,
  deleteLaneRedirectEpic,
  deleteLaneAndGetLanesRedirectEpic,
  deleteLanesRedirectEpic,
  clearSelectionAfterDeleteLane,
  clearSelectionAfterDeleteWorkflow,
};
export default epics;
