import styled from '@emotion/styled';
import { DistanceRiskBar, SearchTable } from '@luxe/components';
import { valColor } from 'discover/modules/graphUtilities';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearView, getViewData, getViewMeta } from 'views/modules/views';

const LanesTable = styled(({ assetId, className }) => {
  const currentSearchTerm = useRef(null);
  const currentPage = useRef(1);
  const currentOrderBy = useRef('');
  const lanes = useSelector(state => state.views?.['lanes'] || {});
  const userLengthPref = useSelector(state => state.account?.account?.prefs?.units?.length || 'kilometers');
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [orderBy, setOrderBy] = useState([{ name: 'lane__name', direction: 'asc' }]);
  const dispatch = useDispatch();

  const firstLoad = lanes.datasource === undefined;
  const meta = lanes.meta;
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    lanes,
  );

  useEffect(() => () => dispatch(clearView('lanes')), [dispatch]);

  useEffect(() => {
    if (firstLoad) {
      dispatch(getViewMeta('lanes'));
    }
  }, [dispatch, firstLoad]);

  useEffect(() => {
    const searchChanged = searchTerm !== currentSearchTerm.current;
    const pageChanged = activePage !== currentPage.current;
    const orderByChanged = JSON.stringify(orderBy) !== currentOrderBy.current;
    const shouldLoad = meta && assetId && (firstLoad || searchChanged || pageChanged || orderByChanged);

    if (shouldLoad) {
      const params = {
        order_by: orderBy ?? [],
        limit: 10,
      };
      params.offset = (activePage - 1) * params.limit;
      currentSearchTerm.current = searchTerm;
      currentPage.current = activePage;
      currentOrderBy.current = JSON.stringify(orderBy);
      const query = {
        columns: [
          'id',
          'lane__name',
          'origin__name',
          'destination__name',
          'total_miles',
          'total_kilometers',
          'kilometers_at_risk',
          'miles_at_risk',
          'risk__score',
        ],
        ...params,
        utl: 'lanes',
        filters: [{ name: 'stop_asset_id', operator: '==', value: assetId }],
      };
      if (searchTerm) {
        query.filters.push({ name: 'query', operator: '==', value: searchTerm });
      }
      dispatch(getViewData('lanes', query));
    }
  }, [dispatch, firstLoad, meta, orderBy, assetId, searchTerm, activePage]);

  const columns = [
    {
      title: 'Lane',
      dataIndex: ['lane', 'name'],
      sorter: true,
      orderBy: 'lane__name',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Origin',
      dataIndex: ['origin', 'name'],
      sorter: true,
      orderBy: 'origin__name',
    },
    {
      title: 'Destination',
      dataIndex: ['destination', 'name'],
      sorter: true,
      orderBy: 'destination__name',
    },
    {
      title: `Total Distance (${userLengthPref === 'kilometers' ? 'Kilometers' : 'Miles'})`,
      dataIndex: userLengthPref === 'kilometers' ? 'total_kilometers' : 'total_miles',
      align: 'center',
    },
    {
      title: `${userLengthPref === 'kilometers' ? 'Kilometers' : 'Miles'} At Risk`,
      dataIndex: userLengthPref === 'kilometers' ? 'kilometers_at_risk' : 'miles_at_risk',
      render: data => <DistanceRiskBar risks={data} />,
      align: 'center',
    },
    {
      title: 'Incident Score',
      dataIndex: ['risk', 'score'],
      render: data => (
        <span
          style={{
            borderRadius: '10px',
            verticalAlign: 'middle',
            display: 'table',
            background: valColor(data),
            color: 'white',
            width: '35px',
            margin: '0 auto',
          }}
        >
          {data || '-'}
        </span>
      ),
      sorter: true,
      orderBy: 'risk__score',
      align: 'center',
    },
  ];

  return (
    <SearchTable
      description="No matching records found"
      table="lanes-search-table"
      className={className}
      rowKey={row => row.id}
      columns={columns}
      rowData={lanes.items}
      onSearchChange={setSearchTerm}
      onPageChange={setActivePage}
      onSort={setOrderBy}
      paginationData={paginationData}
      tableSize="small"
    />
  );
})`
  padding: 0;
  background-color: unset;
`;

export default LanesTable;
