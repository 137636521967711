import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

window.Highcharts = Highcharts;
HighchartsMore(Highcharts);

const IndividualSubIndexChart = ({ cName, options, scores }) => {
  const chartComponentRef = useRef(null);
  useEffect(() => {
    if (scores && chartComponentRef.current) {
      setTimeout(() => {
        if (chartComponentRef.current) {
          chartComponentRef.current.chart.redraw();
        }
      }, 1000);
    }
  }, [scores]);
  return (
    <HighchartsReact
      containerProps={{ className: cName }}
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};

export default IndividualSubIndexChart;
