import React from 'react';
import styled from '@emotion/styled';

export const HeatMapBox = styled(props => {
  return <div className={`${props.className} risk-chicklets risk-color-${props.category}`}>{props.children}</div>;
})`
& {
    border: 1px solid black;
    height: 33%;
    width: 50%;
    text-align: left;
    background-color: ${props => props.theme.colors.noRisk};
}
&.risk-color-high {
    background-color: ${props => props.theme.colors.highRisk};
}
&.risk-color-medium {
    background-color: ${props => props.theme.colors.medRisk};
}
&.risk-color-low {
    background-color: ${props => props.theme.colors.lowRisk};
}
`;
