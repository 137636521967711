import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { updateInList, deleteRecursivelyFromList, deleteFromListById } from 'admin/utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_PRODUCT_GROUPS: 'GET_PRODUCT_GROUPS',
  GET_PRODUCT_GROUPS_ERROR: 'GET_PRODUCT_GROUPS_ERROR',
  GET_PRODUCT_GROUPS_SUCCESS: 'GET_PRODUCT_GROUPS_SUCCESS',

  DELETE_PRODUCT_GROUPS: 'DELETE_PRODUCT_GROUPS',
  DELETE_PRODUCT_GROUPS_ERROR: 'DELETE_PRODUCT_GROUPS_ERROR',
  DELETE_PRODUCT_GROUPS_SUCCESS: 'DELETE_PRODUCT_GROUPS_SUCCESS',

  CREATE_PRODUCT_GROUPS: 'CREATE_PRODUCT_GROUPS',
  CREATE_PRODUCT_GROUPS_ERROR: 'CREATE_PRODUCT_GROUPS_ERROR',
  CREATE_PRODUCT_GROUPS_SUCCESS: 'CREATE_PRODUCT_GROUPS_SUCCESS',

  UPDATE_PRODUCT_GROUPS: 'UPDATE_PRODUCT_GROUPS',
  UPDATE_PRODUCT_GROUPS_ERROR: 'UPDATE_PRODUCT_GROUPS_ERROR',
  UPDATE_PRODUCT_GROUPS_SUCCESS: 'UPDATE_PRODUCT_GROUPS_SUCCESS',

  BULK_UPDATE_PRODUCT_GROUPS: 'BULK_UPDATE_PRODUCT_GROUPS',
  BULK_UPDATE_PRODUCT_GROUPS_ERROR: 'BULK_UPDATE_PRODUCT_GROUPS_ERROR',
  BULK_UPDATE_PRODUCT_GROUPS_SUCCESS: 'BULK_UPDATE_PRODUCT_GROUPS_SUCCESS',

  BULK_DELETE_PRODUCT_GROUPS: 'BULK_DELETE_PRODUCT_GROUPS',
  BULK_DELETE_PRODUCT_GROUPS_ERROR: 'BULK_DELETE_PRODUCT_GROUPS_ERROR',
  BULK_DELETE_PRODUCT_GROUPS_SUCCESS: 'BULK_DELETE_PRODUCT_GROUPS_SUCCESS',

  REMOVE_PRODUCTS_FROM_GROUP: 'REMOVE_PRODUCTS_FROM_GROUP',
  REMOVE_PRODUCTS_FROM_GROUP_ERROR: 'REMOVE_PRODUCTS_FROM_GROUP_ERROR',
  REMOVE_PRODUCTS_FROM_GROUP_SUCCESS: 'REMOVE_PRODUCTS_FROM_GROUP_SUCCESS',

  ADD_PRODUCTS_TO_GROUP: 'ADD_PRODUCTS_TO_GROUP',
  ADD_PRODUCTS_TO_GROUP_ERROR: 'ADD_PRODUCTS_TO_GROUP_ERROR',
  ADD_PRODUCTS_TO_GROUP_SUCCESS: 'ADD_PRODUCTS_TO_GROUP_SUCCESS',
};

export const getProductGroups = () =>
  callApi({
    types: {
      pending: TYPES.GET_PRODUCT_GROUPS,
      success: TYPES.GET_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.GET_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.get('material-groups/?type=product'),
  });

export const deleteProductGroup = productGroupId =>
  callApi({
    types: {
      pending: TYPES.DELETE_PRODUCT_GROUPS,
      success: TYPES.DELETE_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.DELETE_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.delete(`material-groups/${productGroupId}/`),
    messages: {
      success: 'Product Group has been successfully deleted!',
      error: 'Product Group deletion unsuccessful',
    },
  });

export const createProductGroups = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_PRODUCT_GROUPS,
      success: TYPES.CREATE_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.CREATE_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.post('material-groups/', data),
    messages: {
      success: 'Product Group has been successfully created!',
      error: 'Product Group creation unsuccessful',
    },
  });

export const updateProductGroups = (productGroupId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_PRODUCT_GROUPS,
      success: TYPES.UPDATE_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.UPDATE_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.put(`material-groups/${productGroupId}/`, data),
    messages: {
      success: 'Product Group has been successfully edited!',
      error: 'Product Group update unsuccessful',
    },
  });

export const bulkUpdateProductGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_UPDATE_PRODUCT_GROUPS,
      success: TYPES.BULK_UPDATE_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.BULK_UPDATE_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.put(`material-groups/`, data),
    messages: {
      success: 'Product Groups have been successfully edited!',
      error: 'Product Groups update unsuccessful',
    },
  });

export const bulkDeleteProductGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_DELETE_PRODUCT_GROUPS,
      success: TYPES.BULK_DELETE_PRODUCT_GROUPS_SUCCESS,
      error: TYPES.BULK_DELETE_PRODUCT_GROUPS_ERROR,
    },
    request: () => axios.post('material-groups/bulk/', data),
    messages: {
      success: 'Product Groups have been successfully deleted!',
      error: 'Product Groups deletion unsuccessful',
    },
  });

export const removeProductsFromGroup = (groupId, products, viewDataParams) => {
  const payload = { material_ids: products };
  return callApi({
    types: {
      pending: TYPES.REMOVE_PRODUCTS_FROM_GROUP,
      success: TYPES.REMOVE_PRODUCTS_FROM_GROUP_SUCCESS,
      error: TYPES.REMOVE_PRODUCTS_FROM_GROUP_ERROR,
    },
    params: viewDataParams,
    request: () => axios.delete(`material-groups/${groupId}/materials/`, { data: payload }),
    messages: {
      success: 'Product has been successfully removed from group!',
      error: 'Removing product from group unsuccessful',
    },
  });
};

export const addProductsToGroup = (groupId, products, viewDataParams) => {
  const payload = { material_ids: products };
  return callApi({
    types: {
      pending: TYPES.ADD_PRODUCTS_TO_GROUP,
      success: TYPES.ADD_PRODUCTS_TO_GROUP_SUCCESS,
      error: TYPES.ADD_PRODUCTS_TO_GROUP_ERROR,
    },
    params: viewDataParams,
    request: () => axios.post(`material-groups/${groupId}/materials/`, payload),
    messages: {
      success: 'Products have been successfully updated!',
      error: 'Adding product to group unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  productGroups: [],
  error: undefined,
  params: undefined,
};

export const productGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_PRODUCT_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        productGroups: action.payload,
        error: undefined,
      };
    case TYPES.GET_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_PRODUCT_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_PRODUCT_GROUPS_SUCCESS:
      const deletedProductGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        productGroups: [...deleteRecursivelyFromList(deletedProductGroup, state.productGroups)],
        error: undefined,
      };
    case TYPES.DELETE_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.CREATE_PRODUCT_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_PRODUCT_GROUPS_SUCCESS:
      const newProductGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        productGroups: [...state.productGroups, newProductGroup],
        error: undefined,
      };
    case TYPES.CREATE_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_PRODUCT_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        productGroups: [...updateInList(action.payload, state.productGroups)],
        error: undefined,
      };
    case TYPES.UPDATE_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_UPDATE_PRODUCT_GROUPS:
      return {
        ...state,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_UPDATE_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        error: undefined,
      };
    case TYPES.BULK_UPDATE_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_DELETE_PRODUCT_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_DELETE_PRODUCT_GROUPS_SUCCESS:
      const deletedProductGroups = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        productGroups: [...deleteFromListById(deletedProductGroups.instance_ids, state.productGroups)],
        error: undefined,
      };
    case TYPES.BULK_DELETE_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.REMOVE_PRODUCTS_FROM_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.REMOVE_PRODUCTS_FROM_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.REMOVE_PRODUCTS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    case TYPES.ADD_PRODUCTS_TO_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.ADD_PRODUCTS_TO_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.ADD_PRODUCTS_TO_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    default:
      return state;
  }
};
