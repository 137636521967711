import { filter, map } from 'rxjs/operators';
import { TYPES, getFamilies, validateWorkflowUpdate } from 'admin/workflows/modules/workflows';
import { TYPES as RULETYPES, getRules } from 'admin/workflows/modules/rules';

import { clearSelection, getViewData } from 'views/modules/views';
import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';

export const createWorkflowRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.CREATE_WORKFLOW_SUCCESS),
    map(action => {
      return push(`/admin/workflows/${action.payload.id}/`);
    }),
  );
};

export const createWorkflowReloadEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === TYPES.CREATE_WORKFLOW_SUCCESS) {
        return true;
      } else if (action.type === TYPES.DELETE_WORKFLOWS_SUCCESS) {
        return true;
      }
      if (action.type === TYPES.PAUSE_WORKFLOWS_SUCCESS) {
        return true;
      } else if (action.type === TYPES.RESUME_WORKFLOWS_SUCCESS) {
        return true;
      }
      return false;
    }),
    map(() => {
      const { workflows } = state$.value.views;
      const current = workflows.meta.current;
      const data = {
        ...current,
        columns: current.columns.map(x => x.name),
      };
      return getViewData('workflows', data);
    }),
  );
};

export const clearSelectionAfterDelete = action$ =>
  action$.pipe(
    ofType(TYPES.DELETE_WORKFLOWS_SUCCESS),
    map(() => {
      return clearSelection('workflows');
    }),
  );

export const getWorkflowFamiliesEpic = action$ => {
  return action$.pipe(
    // Note: Update GET_WORKFLOW_SUCCESS state if this Epic updates
    filter(action => action.type === TYPES.GET_WORKFLOW_SUCCESS),
    map(() => getFamilies()),
  );
};

export const deleteWorkflowRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_WORKFLOW_SUCCESS),
    map(() => push(`/admin/workflows/`)),
  );
};

export const createWorkflowRuleRedirectEpic = (action$, state$) => {
  const refetchTypes = [RULETYPES.DELETE_RULE_SUCCESS, RULETYPES.CREATE_RULE_SUCCESS, RULETYPES.SAVE_RULE_SUCCESS];
  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(action => {
      if (action.type === 'DELETE_RULE_SUCCESS') {
        return getRules(action.params.workflow_id);
      }
      return getRules(action.payload.workflow);
    }),
  );
};

export const vallidateWorkflowEpic = action$ => {
  return action$.pipe(
    ofType(
      TYPES.UPDATE_WORKFLOW,
      TYPES.UPDATE_FAMILY,
      TYPES.GET_FAMILIES_SUCCESS,
      TYPES.TOGGLE_FAMILY,
      TYPES.UPDATE_FAMILY_RISK,
    ),
    map(() => validateWorkflowUpdate()),
  );
};

const epics = {
  createWorkflowRedirectEpic,
  deleteWorkflowRedirectEpic,
  getWorkflowFamiliesEpic,
  createWorkflowReloadEpic,
  clearSelectionAfterDelete,
  createWorkflowRuleRedirectEpic,
  vallidateWorkflowEpic,
};
export default epics;
