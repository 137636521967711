import React from 'react';
import { notification } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const showWarningNotification = (msg, key, ref) => {
  notification.open({
    message: msg,
    className: 'notification-discover-warning',
    duration: 0,
    key: key,
    placement: 'top',
    top: !ref ? 84 : 24,
    icon: <WarningOutlined />,
    getContainer: () => (ref ? ref : document.body),
  });
};

export const hideNotification = key => {
  notification.destroy(key);
};
