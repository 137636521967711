import React from 'react';
import { Row } from 'antd';
import styled from '@emotion/styled';

const RowData = styled(({ className, index, value }) => {
  return (
    <Row className={`${className}`} style={{ flexWrap: index === 'Commodities' ? 'nowrap' : 'wrap' }}>
      <span className="meta-row-data-label">{index}</span>: {value}
    </Row>
  );
})`
  .meta-row-data-label {
    font-weight: 800;
  }
`;

export const AssetDrawerMeta = ({ meta }) => {
  return Object.entries(meta).map(entry => {
    return <RowData key={entry[0]} index={entry[0]} value={entry[1]} />;
  });
};

export default AssetDrawerMeta;
