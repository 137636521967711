import React, { useCallback, useEffect } from 'react';
import GroupView from '@luxe/components/src/GroupView';
import { useDispatch, useSelector } from 'react-redux';
import { clearViewData, getViewMeta } from 'views/modules/views';
import {
  addMaterialsToGroup,
  createMaterialGroups,
  deleteMaterialGroup,
  getMaterialGroups,
  removeMaterialsFromGroup,
  updateMaterialGroups,
} from 'admin/materialgroups/modules/materialgroups';
import { fetchMaterials } from 'admin/materialgroups/modules/utils';
import { getEditLink } from './utils';
import { getFormattedDate } from 'assets/modules/utils';

const MaterialGroupsView = () => {
  const views = useSelector(store => store.views);
  const dispatch = useDispatch();
  const materials = views?.['materials'] || {};
  const materialsMeta = materials.meta;
  const materialsItems = materials.items;
  const materialGroups = useSelector(store => store.materialGroups)?.materialGroups || [];
  const componentGroups = materialGroups.filter(group => group.type === 'component');
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    materials,
  );
  useEffect(() => {
    dispatch(getMaterialGroups());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewData('materials'));
    },
    [dispatch],
  );

  const fetchData = useCallback(
    ({ selectedGroup, searchTerm, currentPage, orderBy }) =>
      dispatch(fetchMaterials({ selectedGroup, searchTerm, currentPage, orderBy })),
    [dispatch],
  );

  const fetchMeta = useCallback(fetchData => dispatch(getViewMeta('materials', undefined, undefined, fetchData)), [
    dispatch,
  ]);

  const materialColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      orderBy: 'name',
    },
    {
      title: 'Material ID',
      dataIndex: 'id',
      sorter: true,
      orderBy: 'id',
    },
    {
      title: 'Last Modified',
      render: a => getFormattedDate(a.modified_date, 'YYYY-MM-DD h:mm a'),
      sorter: true,
      orderBy: 'modified_date',
    },
    {
      title: 'Actions',
      render: material => getEditLink(material),
    },
  ];

  return (
    <GroupView
      groups={componentGroups}
      groupsURL={'/material-groups/'}
      title={'Materials & Groups'}
      columns={materialColumns}
      rowData={materialsItems}
      fetchData={fetchData}
      meta={materialsMeta}
      fetchMeta={fetchMeta}
      itemName={'material'}
      createGroupFunction={data => createMaterialGroups({ ...data, type: 'component' })}
      editGroupFunction={updateMaterialGroups}
      deleteGroupFunction={deleteMaterialGroup}
      addItemsToGroupFunction={addMaterialsToGroup}
      removeItemsFromGroupFunction={removeMaterialsFromGroup}
      paginationData={paginationData}
    />
  );
};

export default MaterialGroupsView;
