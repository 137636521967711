import { GeoJSON, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CountryDropDown from './CountryDropDown';
import L from 'leaflet';
import { Row } from 'antd';
import { Section } from '@luxe/components';
import { getAssetIcon } from 'assets/containers/AssetDetailView/styles';
import { getRegions } from 'countries/modules/regions';

const CountryMapView = () => {
  const { regions } = useSelector(store => store.regions);
  const [hasRegionsBeenFetched, setHasRegionsBeenFetched] = useState(false);
  const [regionsList, setRegionsList] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState({ type: 'FeatureCollection', features: [] });
  const dispatch = useDispatch();

  useEffect(() => {
    if (regions.length === 0 && !hasRegionsBeenFetched) {
      dispatch(getRegions());
      setHasRegionsBeenFetched(true);
    } else {
      setRegionsList(
        regions
          .map(country => {
            return { id: country.id, label: country.properties.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
  }, [dispatch, regions, hasRegionsBeenFetched, setRegionsList]);

  useEffect(() => {
    if (regions) {
      setGeoJsonData({
        type: 'FeatureCollection',
        features: regions.map(country => {
          return {
            id: country.id,
            type: country.type,
            geometry: country.geometry,
            properties: {
              ...country.properties,
            },
          };
        }),
      });
    }
  }, [regions]);

  let countryPath = '';
  let path = window.location.pathname.split('/');

  if (path.length > 1) {
    countryPath = path[2].toUpperCase();
  }

  const [countryObj, setCountryObj] = useState({
    position: null,
    countryName: '',
    countryId: '',
    bounds: '',
    luxeUrl: '/country-risk/' + countryPath + '/overview/',
  });

  const getMapIcon = category => {
    return L.icon({
      iconUrl: getAssetIcon(category),
      iconSize: [10, 10],
    });
  };

  const highlightFeature = e => {
    let layer = e.target;

    layer.setStyle({
      weight: 5,
      color: '#097969',
      dashArray: '',
      fillOpacity: 0.7,
    });

    if (countryObj.countryId !== layer.feature.id && countryObj.countryId !== undefined) {
      setCountryObj({
        position: e.latlng,
        countryName: layer.feature.properties.name,
        countryId: layer.feature.id,
        bounds: e.target._bounds,
        luxeUrl: '/country-risk/' + layer.feature.properties.slug.toUpperCase() + '/overview/',
      });
    }

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  const zoomToFeature = e => {
    setCountryObj({
      position: e.latlng,
      countryName: e.target.feature.properties.name,
      countryId: e.target.feature.id,
      bounds: e.target._bounds,
      luxeUrl: '/country-risk/' + e.target.feature.properties.slug.toUpperCase() + '/overview/',
    });
    window.location.href = countryObj.luxeUrl;
  };

  const resetHighlight = event => {
    let layer = event.target;
    layer.setStyle(highlightMap);
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature,
    });
  };

  const MapStyle = {
    marginBottom: '8px',
    height: '897px',
    width: '1024px',
    borderRadius: '0px',
  };

  const highlightMap = {
    color: '#aea6a6',
    weight: 0.7,
    fillOpacity: 0.1,
  };
  return (
    <Section>
      <Row style={{ width: '350px', marginTop: '30px' }}>
        <CountryDropDown allRegions={regionsList} label={'Countries:'} />
      </Row>
      <Row style={{ width: '100%', marginTop: '30px' }}>
        <MapContainer
          center={[0, 0]}
          zoom={3}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          style={MapStyle}
        >
          <TileLayer
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON key={new Date().getTime()} data={geoJsonData} style={highlightMap} onEachFeature={onEachFeature} />
          {countryObj.countryId === undefined || countryObj.countryId === '' ? null : (
            <Marker position={countryObj.position} icon={getMapIcon('low')}>
              <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                {countryObj.countryName}
              </Tooltip>
            </Marker>
          )}
        </MapContainer>
      </Row>
    </Section>
  );
};

export default CountryMapView;
