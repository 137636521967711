import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { useDispatch, useSelector } from 'react-redux';

import { updateFilters } from 'riskfeed/modules/riskfeed';

import ViewFilters from 'views/components/ViewFilters/';
import useMixpanel from 'utils/hooks/Mixpanel';

const FiltersView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();

  const onFiltersChanged = filters => {
    if (filters.length !== 0) {
      const lastFilter = filters[filters.length - 1];
      if (lastFilter?.name) {
        let mixpanelData = {
          Filter_name: lastFilter.name,
        };

        if (lastFilter?.name === 'phenomena__family_id') {
          mixpanelData['Field'] = lastFilter.name;
          mixpanelData['Operator'] = lastFilter.operator;
          mixpanelData['Field Value'] = lastFilter.sendValue;
        }
        mixpanelTrack('Action Center - Apply Filter', mixpanelData);
      }
    }
    dispatch(updateFilters(filters));
  };

  const { views } = useSelector(state => state);
  const filter = views['events/threats']?.filter;
  const { riskfeed } = useSelector(state => state);

  const data = useMemo(() => {
    if (filter) {
      return {
        ...riskfeed.events,
        filter,
      };
    }
    return undefined;
  }, [filter, riskfeed.events]);

  if (data !== undefined) {
    return (
      <div className={`${props.className} filters`}>
        <ViewFilters
          className="risk-filters"
          title="Feed"
          data={data}
          allowSelect={false}
          allowFiltering={true}
          onFiltersChanged={onFiltersChanged}
          includes={['country_id', 'last_update']}
        />
      </div>
    );
  } else {
    return null;
  }
})`
  span.badge {
    color: white;
  }

  &.filters {
    padding: 20px;
    .risk-filters {
      display: flex;
      flex-direction: column;

      .filter-tag {
        margin-right: 0;
        color: white;

        .filter-operator {
          color: #ff8700;
        }

        .filter-labels {
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          line-height: 22px;
          overflow: hidden;
          flex-grow: 1;
        }

        .ant-tag {
          span.badge {
            color: white;
          }
        }

        .ant-tag-close-icon {
          text-align: right;
          color: white;
        }
      }
    }
  }
`;

export default FiltersView;
