import React from 'react';
import { EditIcon } from '@luxe/components';
import { Link } from 'react-router-dom';

export const getEditLink = user => {
  let url = '/admin/users/' + user.id + '/';
  return (
    <Link to={url}>
      <EditIcon />
    </Link>
  );
};
