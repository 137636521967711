import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { updateInList, deleteRecursivelyFromList, deleteFromListById } from 'admin/utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_MATERIAL_GROUPS: 'GET_MATERIAL_GROUPS',
  GET_MATERIAL_GROUPS_ERROR: 'GET_MATERIAL_GROUPS_ERROR',
  GET_MATERIAL_GROUPS_SUCCESS: 'GET_MATERIAL_GROUPS_SUCCESS',

  DELETE_MATERIAL_GROUPS: 'DELETE_MATERIAL_GROUPS',
  DELETE_MATERIAL_GROUPS_ERROR: 'DELETE_MATERIAL_GROUPS_ERROR',
  DELETE_MATERIAL_GROUPS_SUCCESS: 'DELETE_MATERIAL_GROUPS_SUCCESS',

  CREATE_MATERIAL_GROUPS: 'CREATE_MATERIAL_GROUPS',
  CREATE_MATERIAL_GROUPS_ERROR: 'CREATE_MATERIAL_GROUPS_ERROR',
  CREATE_MATERIAL_GROUPS_SUCCESS: 'CREATE_MATERIAL_GROUPS_SUCCESS',

  UPDATE_MATERIAL_GROUPS: 'UPDATE_MATERIAL_GROUPS',
  UPDATE_MATERIAL_GROUPS_ERROR: 'UPDATE_MATERIAL_GROUPS_ERROR',
  UPDATE_MATERIAL_GROUPS_SUCCESS: 'UPDATE_MATERIAL_GROUPS_SUCCESS',

  BULK_UPDATE_MATERIAL_GROUPS: 'BULK_UPDATE_MATERIAL_GROUPS',
  BULK_UPDATE_MATERIAL_GROUPS_ERROR: 'BULK_UPDATE_MATERIAL_GROUPS_ERROR',
  BULK_UPDATE_MATERIAL_GROUPS_SUCCESS: 'BULK_UPDATE_MATERIAL_GROUPS_SUCCESS',

  BULK_DELETE_MATERIAL_GROUPS: 'BULK_DELETE_MATERIAL_GROUPS',
  BULK_DELETE_MATERIAL_GROUPS_ERROR: 'BULK_DELETE_MATERIAL_GROUPS_ERROR',
  BULK_DELETE_MATERIAL_GROUPS_SUCCESS: 'BULK_DELETE_MATERIAL_GROUPS_SUCCESS',

  REMOVE_MATERIALS_FROM_GROUP: 'REMOVE_MATERIALS_FROM_GROUP',
  REMOVE_MATERIALS_FROM_GROUP_ERROR: 'REMOVE_MATERIALS_FROM_GROUP_ERROR',
  REMOVE_MATERIALS_FROM_GROUP_SUCCESS: 'REMOVE_MATERIALS_FROM_GROUP_SUCCESS',

  ADD_MATERIALS_TO_GROUP: 'ADD_MATERIALS_TO_GROUP',
  ADD_MATERIALS_TO_GROUP_ERROR: 'ADD_MATERIALS_TO_GROUP_ERROR',
  ADD_MATERIALS_TO_GROUP_SUCCESS: 'ADD_MATERIALS_TO_GROUP_SUCCESS',
};

export const getMaterialGroups = () =>
  callApi({
    types: {
      pending: TYPES.GET_MATERIAL_GROUPS,
      success: TYPES.GET_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.GET_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.get('material-groups/?type=component'),
  });

export const deleteMaterialGroup = materialGroupId =>
  callApi({
    types: {
      pending: TYPES.DELETE_MATERIAL_GROUPS,
      success: TYPES.DELETE_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.DELETE_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.delete(`material-groups/${materialGroupId}/`),
    messages: {
      success: 'Material Group has been successfully deleted!',
      error: 'Material Group deletion unsuccessful',
    },
  });

export const createMaterialGroups = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_MATERIAL_GROUPS,
      success: TYPES.CREATE_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.CREATE_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.post('material-groups/', data),
    messages: {
      success: 'Material Group has been successfully created!',
      error: 'Material Group creation unsuccessful',
    },
  });

export const updateMaterialGroups = (materialGroupId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_MATERIAL_GROUPS,
      success: TYPES.UPDATE_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.UPDATE_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.put(`material-groups/${materialGroupId}/`, data),
    messages: {
      success: 'Material Group has been successfully edited!',
      error: 'Material Group update unsuccessful',
    },
  });

export const bulkUpdateMaterialGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_UPDATE_MATERIAL_GROUPS,
      success: TYPES.BULK_UPDATE_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.BULK_UPDATE_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.put(`material-groups/`, data),
    messages: {
      success: 'Material Groups have been successfully edited!',
      error: 'Material Groups update unsuccessful',
    },
  });

export const bulkDeleteMaterialGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_DELETE_MATERIAL_GROUPS,
      success: TYPES.BULK_DELETE_MATERIAL_GROUPS_SUCCESS,
      error: TYPES.BULK_DELETE_MATERIAL_GROUPS_ERROR,
    },
    request: () => axios.post('material-groups/bulk/', data),
    messages: {
      success: 'Material Groups have been successfully deleted!',
      error: 'Material Groups deletion unsuccessful',
    },
  });

export const removeMaterialsFromGroup = (groupId, materials, viewDataParams) => {
  const payload = { material_ids: materials };
  return callApi({
    types: {
      pending: TYPES.REMOVE_MATERIALS_FROM_GROUP,
      success: TYPES.REMOVE_MATERIALS_FROM_GROUP_SUCCESS,
      error: TYPES.REMOVE_MATERIALS_FROM_GROUP_ERROR,
    },
    request: () => axios.delete(`material-groups/${groupId}/materials/`, { data: payload }),
    params: viewDataParams,
    messages: {
      success: 'Materials successfully removed from group!',
      error: 'Removing materials from group unsuccessful',
    },
  });
};

export const addMaterialsToGroup = (groupId, materials, viewDataParams) => {
  const payload = { material_ids: materials };
  return callApi({
    types: {
      pending: TYPES.ADD_MATERIALS_TO_GROUP,
      success: TYPES.ADD_MATERIALS_TO_GROUP_SUCCESS,
      error: TYPES.ADD_MATERIALS_TO_GROUP_ERROR,
    },
    request: () => axios.post(`material-groups/${groupId}/materials/`, payload),
    params: viewDataParams,
    messages: {
      success: 'Materials have been successfully updated!',
      error: 'Adding materials to group unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  materialGroups: [],
  error: undefined,
  params: undefined,
};

export const materialGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_MATERIAL_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_MATERIAL_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        materialGroups: action.payload,
        error: undefined,
      };
    case TYPES.GET_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_MATERIAL_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_MATERIAL_GROUPS_SUCCESS:
      const deletedMaterialGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        materialGroups: [...deleteRecursivelyFromList(deletedMaterialGroup, state.materialGroups)],
        error: undefined,
      };
    case TYPES.DELETE_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.CREATE_MATERIAL_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_MATERIAL_GROUPS_SUCCESS:
      const newMaterialGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        materialGroups: [...state.materialGroups, newMaterialGroup],
        error: undefined,
      };
    case TYPES.CREATE_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_MATERIAL_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_MATERIAL_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        materialGroups: [...updateInList(action.payload, state.materialGroups)],
        error: undefined,
      };
    case TYPES.UPDATE_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_UPDATE_MATERIAL_GROUPS:
      return {
        ...state,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_UPDATE_MATERIAL_GROUPS_SUCCESS:
      return {
        ...state,
        error: undefined,
      };
    case TYPES.BULK_UPDATE_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_DELETE_MATERIAL_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_DELETE_MATERIAL_GROUPS_SUCCESS:
      const deletedMaterialGroups = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        materialGroups: [...deleteFromListById(deletedMaterialGroups.instance_ids, state.materialGroups)],
        error: undefined,
      };
    case TYPES.BULK_DELETE_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.REMOVE_MATERIALS_FROM_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.REMOVE_MATERIALS_FROM_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.REMOVE_MATERIALS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    case TYPES.ADD_MATERIALS_TO_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.ADD_MATERIALS_TO_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.ADD_MATERIALS_TO_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    default:
      return state;
  }
};
