import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import styled from '@emotion/styled';

import UserSelector from 'common/components/UserSelector';
import { Select } from 'layout/Select';

import { Form } from 'antd';

import { Label, DatePicker } from '@luxe/components';
import { toggleEdit, saveTask, deleteTask, DATE_FORMAT, TYPES as PLAN_TYPES } from 'riskfeed/modules/plans';
import useMixpanel from 'utils/hooks/Mixpanel';
import Icon from 'layout/icons/Icon';

const STATUSES = {
  PENDING: 'To Do',
  IN_PROGRESS: 'In Progress',
  BLOCKED: 'Blocked',
  COMPLETE: 'Complete',
};

const StatusSelector = ({ name, value, ...props }) => {
  const options = Object.entries(STATUSES).map(([value, label]) => {
    return { value, label };
  });
  const selected = options.find(x => x.value === value);
  return (
    <Select
      key={name}
      isMulti={false}
      stayOpen={false}
      selected={selected}
      onChange={props.onChange}
      options={options}
      backspaceRemoves
      placeholder="Select status"
    />
  );
};

const EditTaskRow = ({ task, form }) => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { id: threatId = null } = useParams();

  const onSubmit = () => {
    // Force form validation
    if (!isSubmitted) {
      form.validateFields().then(values => {
        const { name } = values;
        const due_date = values.due_date ? `${values.due_date.format('YYYY-MM-DD')}T00:00:00` : null;
        const assignees = [values.assignees.value];
        const status = values.status.value;
        setIsSubmitted(true);
        dispatch(
          saveTask(threatId, {
            id: task.id,
            name,
            status,
            assignees,
            due_date,
          }),
        );
      });
    }
  };

  return (
    <tr key={task.id} className="edit">
      <td>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Task is required',
            },
          ]}
        >
          <Label autoComplete="off" placeholder="Task" width="100%" maxLength="255" />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name="assignees"
          rules={[
            {
              required: true,
              message: 'Owner is required',
            },
          ]}
        >
          <UserSelector />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name="due_date"
          rules={[
            {
              required: true,
              message: 'Due Date is required',
            },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name="status"
          rules={[
            {
              required: true,
              message: 'Status is required',
            },
          ]}
        >
          <StatusSelector />
        </Form.Item>
      </td>
      <td className="actions">
        <Icon type="check" onClick={onSubmit} />
        <Icon
          type="cancel"
          onClick={() => {
            dispatch(toggleEdit(task.id));
          }}
        />
      </td>
    </tr>
  );
};

const Status = ({ value }) => {
  const label = STATUSES[value];
  if (label) {
    return <span className={`status ${value}`}>{label}</span>;
  }
  return null;
};

const TaskRow = ({ task }) => {
  const dispatch = useDispatch();
  const { id: threatId = null } = useParams();
  const { tasks } = useSelector(state => state.plans);

  const dueDate = moment(task.due_date).format('MMM Do, YYYY');
  const { assignees = [] } = task;
  const assignee = assignees.map(a => a.name);
  return (
    <tr key={task.id}>
      <td>{task.name}</td>
      <td>{assignee}</td>
      <td>{dueDate}</td>
      <td>
        <Status value={task.status} />
      </td>
      <td className="actions">
        {tasks?.item === null && (
          <Icon
            type="edit"
            onClick={() => {
              dispatch(toggleEdit(task.id));
            }}
          />
        )}
        <Icon
          type="delete"
          onClick={() => {
            dispatch(deleteTask(threatId, task.id));
          }}
        />
      </td>
    </tr>
  );
};

const TaskList = styled(({ ...props }) => {
  const mixpanelTrack = useMixpanel();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { tasks, taskStatus } = useSelector(state => state.plans);

  Form.useWatch('name', form);

  const items = tasks.items.map(item => {
    if (item.edit) {
      return <EditTaskRow key={item.id} task={item} form={form} />;
    } else {
      return <TaskRow key={item.id} task={item} />;
    }
  });

  const item = tasks.item;
  const initialValues = useMemo(() => {
    if (item) {
      const { name, status } = item;
      const assignees = item.assignees ? item.assignees[0] : null;
      const due_date = item.due_date ? moment(item.due_date) : null;
      return {
        name,
        status,
        due_date,
        assignees: assignees ? { value: assignees.id, label: assignees.name } : null,
      };
    }
    return null;
  }, [item]);

  useEffect(() => {
    if (initialValues) {
      // Again... when initialValues are changed, we need to actually
      // call resetFields in order for it to take effect.
      form.resetFields();
    }
  }, [form, initialValues]);

  useEffect(() => {
    if (taskStatus?.isTaskCreated) {
      mixpanelTrack('Action Plan - Add Task');
      dispatch({ type: PLAN_TYPES.RESET_EVENT_TASK_STATUS });
    }
    if (taskStatus?.isTaskUpdated) {
      mixpanelTrack('Action Plan - Edit - Checkmark (Save Task)');
      dispatch({ type: PLAN_TYPES.RESET_EVENT_TASK_STATUS });
    }
    if (taskStatus?.isTaskDeleted) {
      mixpanelTrack('Action Plan - Delete Task');
      dispatch({ type: PLAN_TYPES.RESET_EVENT_TASK_STATUS });
    }
  }, [taskStatus, dispatch, mixpanelTrack]);

  const addItem = tasks.item?.id === null ? <EditTaskRow task={tasks.item} form={form} /> : null;
  const formWrapper = children => {
    // The Antd Form abstraction is quite finnicky...  the first
    // value you initialize it with "sticks" ... in this case, if
    // initialValues is passed in with `null` then it doesn't matter
    // if its initialized again with yet another value (such as when a
    // task is edited).
    if (tasks.item !== null) {
      return (
        <Form form={form} layout="vertical" name="task_form" initialValues={initialValues}>
          {children}
        </Form>
      );
    }
    return children;
  };

  return formWrapper(
    <div className={props.className}>
      <table>
        <thead>
          {tasks.items.length === 0 ? (
            <tr>
              <td colSpan="5">Please add tasks</td>
            </tr>
          ) : (
            <tr>
              <td>Task</td>
              <td>Owner</td>
              <td>Due Date</td>
              <td>Status</td>
              <td></td>
            </tr>
          )}
        </thead>

        <tbody>
          {items}
          {addItem}
        </tbody>
      </table>
    </div>,
  );
})`
  table {
    width: 100%;
    margin-bottom: 32px;

    * {
      td {
        line-height: 32px;
        padding: 0px 10px;
        font-size: 14px;
      }
    }

    thead {
      tr {
        background: #141414;
      }

      td {
        font-weight: 600;
      }
    }

    tbody {
      tr:nth-of-type(odd) {
        background: #212121;
        td {
          .ant-form-item-explain.ant-form-item-explain-connected {
            position: absolute;
            top: 40px;
            background: #212121;
          }
        }
      }
      tr:nth-of-type(even) {
        background: #2e2e2e;

        td {
          .ant-form-item-explain.ant-form-item-explain-connected {
            position: absolute;
            top: 40px;
            background: #2e2e2e;
          }
        }
      }

      tr td span.icon {
        vertical-align: middle;
      }

      tr {
        td.actions {
          width: 55px;
          text-align: right;
          .icon:nth-of-type(even) {
            margin-left: 10px;
          }

          .icon-edit {
            display: none;
          }
        }

        &:hover td.actions .icon-edit {
          display: inline;
        }
      }

      tr.edit td {
        padding: 0 2px 0 10px 0;

        .ant-form-item {
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }
    }
  }

  .anticon.anticon-edit {
  }

  .status {
    border-radius: 10px;
    margin: 5px 0;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    width: 125px;
    text-align: center;

    &.pending {
      background: #ff8700;
    }
    &.in_progress {
      background: #545454;
    }
    &.blocked {
      background: #bf1a04;
    }
    &.complete {
      background: #009702;
    }
  }
`;

export default TaskList;
