import React, { useEffect, useState } from 'react';
import { stringSorter } from 'utils';
import { Table } from '@luxe/components';
import { Button, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { focusNode } from 'discover/modules/viz';
import { valColor } from 'discover/modules/graphUtilities';
import DetermineDiscover from 'common/components/DiscoverDot';

const AssetDiscoverSubTable = ({ subTierData, toggleSubTier, toggleNextDrilldown }) => {
  const [ds, setDataSource] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const findMaterialSupplies = node => {
      return subTierData?.list?.links?.filter(link => {
        const relationID = subTierData?.direction === 'Previous' ? link.source : link.target;
        return node.id === relationID;
      });
    };

    if (subTierData?.list.nodes.length > 0) {
      setDataSource(
        subTierData.list.nodes.map(n => {
          return {
            unique: n.unique ?? n.id,
            id: n.id,
            name: n.name,
            country: n.country,
            incident_risk: n.reactive__max_score,
            proactive_risk: n.proactive_scores__overall_score,
            discovered: <DetermineDiscover item={n} />,
            material_supplies: findMaterialSupplies(n),
          };
        }),
      );
    }
  }, [subTierData]);

  const columns = [
    {
      title: '',
      dataIndex: 'discovered',
      render: nde => nde,
      width: 25,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.name, b.name),
      width: '50%',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.country, b.country),
    },
    {
      title: 'Incident Risk',
      dataIndex: 'incident_risk',
      render: nde => {
        return (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'White',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde}
          </span>
        );
      },
      sorter: (a, b) => a.incident_risk - b.incident_risk,
      width: 125,
      align: 'center',
    },
    {
      title: 'Strategic Risk',
      dataIndex: 'proactive_risk',
      render: nde => {
        return (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'White',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde}
          </span>
        );
      },
      sorter: (a, b) => a.proactive_risk - b.proactive_risk,
      width: 125,
      align: 'center',
    },
    {
      title: subTierData?.direction === 'Previous' ? 'Inbound Materials' : 'Outbound Materials',
      dataIndex: 'material_supplies',
      render: (nde, Row) => {
        const currNodeData = subTierData?.list?.nodes.find(x => x.id === Row.id);
        return nde?.length > 0 ? (
          <span
            onMouseUp={() =>
              toggleNextDrilldown(
                nde,
                subTierData?.direction === 'Previous',
                Row,
                subTierData.selectedNode,
                currNodeData,
              )
            }
            style={{ color: '#2d8fa5', cursor: 'pointer' }}
          >
            {nde?.length}
          </span>
        ) : (
          <span>{nde?.length}</span>
        );
      },
      sorter: (a, b) => {
        return a.material_supplies.length - b.material_supplies.length;
      },
    },
  ];

  const closeSubTierTable = () => {
    toggleSubTier(null);
  };

  return (
    <>
      <Col style={{ width: '100%', marginBottom: '10px' }}>
        <Row style={{ lineHeight: '40px' }}>
          <label style={{ margin: '0 0 10px 0', fontSize: '16px' }}>
            {subTierData?.direction} Locations for <strong>{subTierData?.selectedNode.name}</strong>(
            {subTierData?.list.nodes.length})
          </label>

          <Button
            onClick={closeSubTierTable}
            style={{ backgroundColor: '#545454', position: 'absolute', right: '0', border: '0', padding: '0 6px' }}
          >
            Back
          </Button>
        </Row>
        <Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={ds}
            size="small"
            style={{ width: '100%', zIndex: '0', display: 'flex', flexDirection: 'column' }}
            onRow={record => {
              return {
                onClick: () => {
                  dispatch(focusNode(record.id));
                },
              };
            }}
          />
        </Row>
      </Col>
    </>
  );
};

export default AssetDiscoverSubTable;
