import { Form } from 'antd';
import React from 'react';
import { Select } from 'layout/Select';

const CountryDropDown = ({ selected, label, allRegions }) => {
  const openCountryRoute = value => {
    const countryId = value.id;
    const luxeUrl = '/country-risk/' + countryId + '/';
    window.location.href = luxeUrl;
  };

  return (
    <Form.Item
      style={{ width: '188px' }}
      colon={false}
      label={label ? <label style={{ color: 'white', marginLeft: 20 }}>{label}</label> : null}
    >
      <Select menuPortalTarget={document.body} selected={selected} options={allRegions} onChange={openCountryRoute} />
    </Form.Item>
  );
};

export default CountryDropDown;
