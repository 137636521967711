import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { updateInList, deleteRecursivelyFromList, deleteFromListById } from 'admin/utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_ASSET_GROUPS: 'GET_ASSET_GROUPS',
  GET_ASSET_GROUPS_ERROR: 'GET_ASSET_GROUPS_ERROR',
  GET_ASSET_GROUPS_SUCCESS: 'GET_ASSET_GROUPS_SUCCESS',

  DELETE_ASSET_GROUPS: 'DELETE_ASSET_GROUPS',
  DELETE_ASSET_GROUPS_ERROR: 'DELETE_ASSET_GROUPS_ERROR',
  DELETE_ASSET_GROUPS_SUCCESS: 'DELETE_ASSET_GROUPS_SUCCESS',

  CREATE_ASSET_GROUPS: 'CREATE_ASSET_GROUPS',
  CREATE_ASSET_GROUPS_ERROR: 'CREATE_ASSET_GROUPS_ERROR',
  CREATE_ASSET_GROUPS_SUCCESS: 'CREATE_ASSET_GROUPS_SUCCESS',

  UPDATE_ASSET_GROUPS: 'UPDATE_ASSET_GROUPS',
  UPDATE_ASSET_GROUPS_ERROR: 'UPDATE_ASSET_GROUPS_ERROR',
  UPDATE_ASSET_GROUPS_SUCCESS: 'UPDATE_ASSET_GROUPS_SUCCESS',

  BULK_UPDATE_ASSET_GROUPS: 'BULK_UPDATE_ASSET_GROUPS',
  BULK_UPDATE_ASSET_GROUPS_ERROR: 'BULK_UPDATE_ASSET_GROUPS_ERROR',
  BULK_UPDATE_ASSET_GROUPS_SUCCESS: 'BULK_UPDATE_ASSET_GROUPS_SUCCESS',

  BULK_DELETE_ASSET_GROUPS: 'BULK_DELETE_ASSET_GROUPS',
  BULK_DELETE_ASSET_GROUPS_ERROR: 'BULK_DELETE_ASSET_GROUPS_ERROR',
  BULK_DELETE_ASSET_GROUPS_SUCCESS: 'BULK_DELETE_ASSET_GROUPS_SUCCESS',

  REMOVE_ASSETS_FROM_GROUP: 'REMOVE_ASSETS_FROM_GROUP',
  REMOVE_ASSETS_FROM_GROUP_ERROR: 'REMOVE_ASSETS_FROM_GROUP_ERROR',
  REMOVE_ASSETS_FROM_GROUP_SUCCESS: 'REMOVE_ASSETS_FROM_GROUP_SUCCESS',

  ADD_ASSETS_TO_GROUP: 'ADD_ASSETS_TO_GROUP',
  ADD_ASSETS_TO_GROUP_ERROR: 'ADD_ASSETS_TO_GROUP_ERROR',
  ADD_ASSETS_TO_GROUP_SUCCESS: 'ADD_ASSETS_TO_GROUP_SUCCESS',
};

export const getAssetGroups = params =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_GROUPS,
      success: TYPES.GET_ASSET_GROUPS_SUCCESS,
      error: TYPES.GET_ASSET_GROUPS_ERROR,
    },
    request: () => axios.get('asset-groups/', { params }),
  });

export const deleteAssetGroup = assetGroupId =>
  callApi({
    types: {
      pending: TYPES.DELETE_ASSET_GROUPS,
      success: TYPES.DELETE_ASSET_GROUPS_SUCCESS,
      error: TYPES.DELETE_ASSET_GROUPS_ERROR,
    },
    request: () => axios.delete(`asset-groups/${assetGroupId}/`),
    messages: {
      success: 'Facility Group has been successfully deleted!',
      error: 'Facility Group deletion unsuccessful',
    },
  });

export const createAssetGroups = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_ASSET_GROUPS,
      success: TYPES.CREATE_ASSET_GROUPS_SUCCESS,
      error: TYPES.CREATE_ASSET_GROUPS_ERROR,
    },
    request: () => axios.post('asset-groups/', data),
    messages: {
      success: 'Facility Group has been successfully created!',
      error: 'Facility Group creation unsuccessful',
    },
  });

export const updateAssetGroups = (assetGroupId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_ASSET_GROUPS,
      success: TYPES.UPDATE_ASSET_GROUPS_SUCCESS,
      error: TYPES.UPDATE_ASSET_GROUPS_ERROR,
    },
    request: () => axios.put(`asset-groups/${assetGroupId}/`, data),
    messages: {
      success: 'Facility Group has been successfully edited!',
      error: 'Facility Group update unsuccessful',
    },
  });

export const bulkUpdateAssetGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_UPDATE_ASSET_GROUPS,
      success: TYPES.BULK_UPDATE_ASSET_GROUPS_SUCCESS,
      error: TYPES.BULK_UPDATE_ASSET_GROUPS_ERROR,
    },
    request: () => axios.put(`asset-groups/`, data),
    messages: {
      success: 'Facility Groups have been successfully edited!',
      error: 'Facility Groups update unsuccessful',
    },
  });

export const bulkDeleteAssetGroups = data =>
  callApi({
    types: {
      pending: TYPES.BULK_DELETE_ASSET_GROUPS,
      success: TYPES.BULK_DELETE_ASSET_GROUPS_SUCCESS,
      error: TYPES.BULK_DELETE_ASSET_GROUPS_ERROR,
    },
    request: () => axios.post('asset-groups/bulk/', data),
    messages: {
      success: 'Facility Groups have been successfully deleted!',
      error: 'Facility Groups deletion unsuccessful',
    },
  });

export const removeAssetsFromGroup = (groupId, assets, viewDataParams) => {
  const payload = { asset_ids: assets };
  return callApi({
    types: {
      pending: TYPES.REMOVE_ASSETS_FROM_GROUP,
      success: TYPES.REMOVE_ASSETS_FROM_GROUP_SUCCESS,
      error: TYPES.REMOVE_ASSETS_FROM_GROUP_ERROR,
    },
    request: () => axios.delete(`asset-groups/${groupId}/assets/`, { data: payload }),
    params: viewDataParams,
    messages: {
      success: 'Facility has been successfully removed from group!',
      error: 'Removing facility from group unsuccessful',
    },
  });
};

export const addAssetsToGroup = (groupId, assets, viewDataParams) => {
  const payload = { asset_ids: assets };
  return callApi({
    types: {
      pending: TYPES.ADD_ASSETS_TO_GROUP,
      success: TYPES.ADD_ASSETS_TO_GROUP_SUCCESS,
      error: TYPES.ADD_ASSETS_TO_GROUP_ERROR,
    },
    request: () => axios.post(`asset-groups/${groupId}/assets/`, payload),
    params: viewDataParams,
    messages: {
      success: 'Facilities have been successfully updated!',
      error: 'Adding facility to group unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  assetGroups: [],
  error: undefined,
  params: undefined,
};

export const assetGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ASSET_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_ASSET_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetGroups: action.payload,
        error: undefined,
      };
    case TYPES.GET_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_ASSET_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_ASSET_GROUPS_SUCCESS:
      const deletedAssetGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetGroups: [...deleteRecursivelyFromList(deletedAssetGroup, state.assetGroups)],
        error: undefined,
      };
    case TYPES.DELETE_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.CREATE_ASSET_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_ASSET_GROUPS_SUCCESS:
      const newAssetGroup = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetGroups: [...state.assetGroups, newAssetGroup],
        error: undefined,
      };
    case TYPES.CREATE_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_ASSET_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_ASSET_GROUPS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetGroups: [...updateInList(action.payload, state.assetGroups)],
        error: undefined,
      };
    case TYPES.UPDATE_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_UPDATE_ASSET_GROUPS:
      return {
        ...state,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_UPDATE_ASSET_GROUPS_SUCCESS:
      return {
        ...state,
        error: undefined,
        status: TYPES.BULK_UPDATE_ASSET_GROUPS_SUCCESS,
      };
    case TYPES.BULK_UPDATE_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.BULK_DELETE_ASSET_GROUPS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.BULK_DELETE_ASSET_GROUPS_SUCCESS:
      const deletedAssetGroups = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetGroups: [...deleteFromListById(deletedAssetGroups.instance_ids, state.assetGroups)],
        error: undefined,
      };
    case TYPES.BULK_DELETE_ASSET_GROUPS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.REMOVE_ASSETS_FROM_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.REMOVE_ASSETS_FROM_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.REMOVE_ASSETS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    case TYPES.ADD_ASSETS_TO_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.ADD_ASSETS_TO_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.ADD_ASSETS_TO_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };

    default:
      return state;
  }
};
