import React from 'react';
import PropTypes from 'prop-types';
import AssetFormatter from './AssetFormatter';
import AssetScoreFormatter from './AssetScoreFormatter';
import BaseThreatFormatter from './BaseThreatFormatter';
import BoolFormatter from './BoolFormatter';
import DateTimeFormatter from './DateTimeFormatter';
import DefaultBadgeFormatter from './DefaultBadgeFormatter';
import DistanceAtRiskFormatter from './DistanceAtRiskFormatter';
import DurationFormatter from './DurationFormatter';
import FamilyFormatter from './FamilyFormatter';
import HeatMapFormatter from './HeatMapFormatter';
import InvalidColumnFormatter from './InvalidColumnFormatter';
import LaneFormatter from './LaneFormatter';
import PhenomenaFormatter from './PhenomenaFormatter';
import RecommendationFormatter from './RecommendationFormatter';
import RiskFormatter from './RiskFormatter';
import ShipmentFormatter from './ShipmentFormatter';
import ShipmentOrderFormatter from './ShipmentOrderFormatter';
import ThreatFormatter from './ThreatFormatter';
import VesselFormatter from './VesselFormatter';
import WorkflowFormatter from './WorkflowFormatter';
import SupplierLocationFormatter from './SupplierLocationFormatter';
import CorporateFormatter from './CorporateFormatter';
import ScoreCardFormatter from './ScoreCardFormatter';
import SubTableFormatter from './SubTableFormatter';

class DefaultFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
  };

  render() {
    return <span>{this.props.value}</span>;
  }
}

const formatters = {
  invalid: InvalidColumnFormatter,
  asset: AssetFormatter,
  assets_scores: AssetScoreFormatter,
  base_threat: BaseThreatFormatter,
  bool: BoolFormatter,
  datetime: DateTimeFormatter,
  default_badge: DefaultBadgeFormatter,
  duration: DurationFormatter,
  kilometers_at_risk: DistanceAtRiskFormatter,
  lane: LaneFormatter,
  corporation: CorporateFormatter,
  meters_at_risk: DistanceAtRiskFormatter,
  miles_at_risk: DistanceAtRiskFormatter,
  order: ShipmentOrderFormatter,
  phenomena: PhenomenaFormatter,
  phenomena_family: FamilyFormatter,
  proactive_risk: HeatMapFormatter,
  recommendation: RecommendationFormatter,
  risk: RiskFormatter,
  shipment: ShipmentFormatter,
  supplierlocation: SupplierLocationFormatter,
  supplier: SupplierLocationFormatter,
  threat: ThreatFormatter,
  vessel: VesselFormatter,
  workflow: WorkflowFormatter,
  scorecard: ScoreCardFormatter,
  sub_table: SubTableFormatter,
};

const getFormatter = type => {
  return formatters[type] || DefaultFormatter;
};

export default getFormatter;
