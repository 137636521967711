import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { stringSorter } from 'utils';
import { callApi } from 'utils';

import _ from 'lodash';

export const TYPES = {
  GET_CATALOG_RULES: 'GET_CATALOG_RULES',
  GET_CATALOG_RULES_ERROR: 'GET_CATALOG_RULES_ERROR',
  GET_CATALOG_RULES_SUCCESS: 'GET_CATALOG_RULES_SUCCESS',
};

export const getCatalogRules = params =>
  callApi({
    types: {
      pending: TYPES.GET_CATALOG_RULES,
      success: TYPES.GET_CATALOG_RULES_SUCCESS,
      error: TYPES.GET_CATALOG_RULES_ERROR,
    },
    params: params,
    request: () => axios.get('risk-catalog-rules/'),
  });

const INITIAL_STATE = {
  status: undefined,
  catalogRules: [],
  error: undefined,
  params: undefined,
};

const sortAndDedupe = catalogRules => {
  catalogRules.sort((a, b) => stringSorter(a.name, b.name));
  return _.uniqBy(catalogRules, rule => rule.name);
};

export const catalogRulesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CATALOG_RULES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: action.payload,
      };
    case TYPES.GET_CATALOG_RULES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        catalogRules: sortAndDedupe(action.payload),
        error: undefined,
      };
    case TYPES.GET_CATALOG_RULES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
