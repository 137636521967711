import React from 'react';
import PropTypes from 'prop-types';
import { RiskBadge } from '@luxe/components';
import { getRiskCategory } from 'utils';

export default class RiskFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  getCategory() {
    let category = this.props.value.category;
    const hasCategory = this.props.value.score && this.props.value.category;
    if (!hasCategory) {
      category = getRiskCategory(this.props.value.score);
    }
    return category;
  }

  render() {
    return this.props.value.score !== null ? (
      <RiskBadge variant={this.getCategory(this.props.value.score)} count={this.props.value.score} />
    ) : (
      <span>-</span>
    );
  }
}
