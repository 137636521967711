import React from 'react';
import { useHistory } from 'react-router-dom';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { stringSorter, dateSorter } from 'utils';
import { Table } from '@luxe/components';

const PlanPlaybookTable = ({ status, planPlaybooks, setSelectedPlaybooks }) => {
  let description = 'There are no Plan Playbooks available';
  const history = useHistory();

  if (status === REQUESTED_DATA_STATUS.ERROR) {
    description = 'Something went wrong';
  }

  const getColumns = () => [
    {
      title: 'Playbook Name',
      dataIndex: 'name',
      render: name => <span>{name}</span>,
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'Last Modified By',
      dataIndex: 'modified_by',
      sorter: (a, b) => dateSorter(a.modified_date, b.modified_date),
      defaultSortOrder: 'descend',
    },
  ];

  const rowSelection = {
    onChange: idsSelected => {
      setSelectedPlaybooks(idsSelected);
    },
  };

  const rowInteraction = record => {
    return {
      onClick: () => {
        history.push(`/admin/plan-playbooks/edit/${record.id}/`);
      },
    };
  };

  return (
    <div>
      <Table
        onRow={rowInteraction}
        rowSelection={rowSelection}
        rowKey="id"
        status={status}
        columns={getColumns()}
        dataSource={planPlaybooks}
        description={description}
        size="small"
      />
    </div>
  );
};

export default PlanPlaybookTable;
