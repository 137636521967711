import React from 'react';
import { Radio } from 'antd';
import styled from '@emotion/styled';

const ToggleRadioGroup = styled(({ ...props }) => {
  return (
    <div className={`${props.className}`}>
      <Radio.Group className={'toggle-radio-group'} buttonStyle="solid" name={props.name} value={props.value}>
        {props.options.map((d, i) => (
          <Radio.Button key={i} value={d.value} onChange={props.onChange} className={'toggle-radio-button'}>
            {d.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
})`
  .ant-radio-button {
    background-color: #2d8fa5;
    border: 1px solid #2d8fa5;
  }

  .ant-radio-button-wrapper {
    background-color: #2c2c2c;
    color: #919191;
  }
  .ant-radio-group {
    margin: 5px 0 20px 0;
  }
`;

export default ToggleRadioGroup;
