import React, { useEffect, useCallback } from 'react';
import GroupView from '@luxe/components/src/GroupView';
import { useDispatch, useSelector } from 'react-redux';
import { getEditLink } from './utils';
import { clearViewData, getViewMeta } from 'views/modules/views';
import {
  addAssetsToGroup,
  createAssetGroups,
  deleteAssetGroup,
  getAssetGroups,
  removeAssetsFromGroup,
  updateAssetGroups,
} from 'admin/assetgroups/modules/assetgroups';
import { fetchAssets } from 'admin/assetgroups/modules/utils';

const AssetGroupView = () => {
  const views = useSelector(store => store.views);
  const dispatch = useDispatch();
  const assets = views?.['assets'] || {};
  const assetGroups = useSelector(store => store.assetGroups)?.assetGroups;
  const assetMeta = assets.meta;
  const assetItems = assets.items?.map(item => ({ ...item, name: item.asset.name })) || [];
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    assets,
  );
  useEffect(() => {
    dispatch(getAssetGroups({ members: 'N' }));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewData('assets'));
    },
    [dispatch],
  );

  const fetchData = useCallback(
    ({ selectedGroup, searchTerm, currentPage, orderBy }) =>
      dispatch(fetchAssets({ selectedGroup, searchTerm, currentPage, orderBy })),
    [dispatch],
  );

  const fetchMeta = useCallback(fetchData => dispatch(getViewMeta('assets', undefined, undefined, fetchData)), [
    dispatch,
  ]);

  const assetGroupColumns = [
    {
      title: 'Name',
      dataIndex: ['asset', 'name'],
      sorter: true,
      orderBy: 'asset__name',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      orderBy: 'id',
    },
    {
      title: 'Facility Type',
      dataIndex: 'org_type',
      sorter: true,
      orderBy: 'org_type',
    },
    {
      title: 'Country',
      dataIndex: ['location', 'country', 'name'],
      sorter: true,
      orderBy: 'country',
    },
    {
      title: 'State',
      dataIndex: ['location', 'state'],
      sorter: true,
      orderBy: 'state',
    },
    {
      title: 'City',
      dataIndex: ['location', 'city'],
      sorter: true,
      orderBy: 'city',
    },
    {
      title: 'Actions',
      render: asset => getEditLink(asset),
    },
  ];

  return (
    <div>
      <GroupView
        groups={assetGroups}
        groupsURL={'/asset-groups/'}
        title={'Facilities & Groups'}
        columns={assetGroupColumns}
        rowData={assetItems}
        fetchData={fetchData}
        meta={assetMeta}
        fetchMeta={fetchMeta}
        itemName={'asset'}
        createGroupFunction={createAssetGroups}
        editGroupFunction={updateAssetGroups}
        deleteGroupFunction={deleteAssetGroup}
        addItemsToGroupFunction={addAssetsToGroup}
        removeItemsFromGroupFunction={removeAssetsFromGroup}
        paginationData={paginationData}
      />
    </div>
  );
};

export default AssetGroupView;
