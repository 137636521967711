import axios from 'axiosInstance';
import { getCurrent, expandColumns } from './../utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_VIEW_DATA: 'GET_VIEW_DATA',
  GET_VIEW_DATA_ERROR: 'GET_VIEW_DATA_ERROR',
  GET_VIEW_DATA_SUCCESS: 'GET_VIEW_DATA_SUCCESS',
  GET_ALL_VIEW_DATA: 'GET_ALL_VIEW_DATA',
  GET_ALL_VIEW_DATA_ERROR: 'GET_ALL_VIEW_DATA_ERROR',
  GET_ALL_VIEW_DATA_SUCCESS: 'GET_ALL_VIEW_DATA_SUCCESS',
  GET_VIEW_META: 'GET_VIEW_META',
  GET_VIEW_META_ERROR: 'GET_VIEW_META_ERROR',
  GET_VIEW_META_SUCCESS: 'GET_VIEW_META_SUCCESS',
  SELECT_ROW: 'SELECT_ROW',
  DESELECT_ROW: 'DESELECT_ROW',
  SELECT_ALL: 'SELECT_ALL',
  SELECT_ALL_NEXT: 'SELECT_ALL_NEXT',
  SELECT_ALL_SUCCESS: 'SELECT_ALL_SUCCESS',
  SELECT_ALL_ERROR: 'SELECT_ALL_ERROR',
  DESELECT_ALL: 'DESELECT_ALL',
  SELECT_ALL_VISIBLE: 'SELECT_ALL_VISIBLE',
  UPDATE_VIEW_META_CURRENT: 'UPDATE_VIEW_META_CURRENT',
  SHOW_FILTERS: 'SHOW_FILTERS',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  CLEAR_VIEW: 'CLEAR_VIEW',
  CLEAR_VIEW_DATA: 'CLEAR_VIEW_DATA',
};

export const INITIAL_STATE = {};

// Allows a user to override meta description, widgets etc.
const applyOverrides = (payload, overrides, additionalColumns) => {
  let allowed = payload?.allowed
    ? {
        columns: payload?.allowed?.columns.map(x => {
          const override = overrides ? overrides[x.name] : undefined;
          return {
            ...x,
            ...override,
          };
        }),
        filters: payload?.allowed?.filters.map(x => {
          const override = overrides ? overrides[x.name] : undefined;
          return {
            ...x,
            ...override,
          };
        }),
      }
    : undefined;

  if (additionalColumns) {
    allowed.columns = [...allowed.columns, ...additionalColumns];
  }

  return {
    ...payload,
    allowed: {
      ...allowed,
      columns: expandColumns(allowed, overrides),
    },
  };
};

const views = (state = INITIAL_STATE, action) => {
  const { type, params = {} } = action;

  let datasource = null;
  if (params.datasource) {
    datasource = params.datasource;
  } else if (action.payload && action.payload.datasource) {
    datasource = action.payload.datasource;
  }

  switch (type) {
    case TYPES.GET_VIEW_META:
      return {
        ...state,
        [datasource]: {
          selectedRows: [],
          ...state[datasource],
          items: null,
          currentUrl: undefined,
          meta: null,
          selectedAll: false,
          filter: {},
          pending: true,
          error: false,
        },
      };
    case TYPES.GET_VIEW_META_SUCCESS:
      const { overrides, additionalColumns } = action.params;
      const meta = applyOverrides(action.payload, overrides, additionalColumns);
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          datasource,
          meta,
        },
      };
    case TYPES.GET_VIEW_META_ERROR:
      return {
        ...state,
      };
    case TYPES.GET_VIEW_DATA:
    case TYPES.GET_ALL_VIEW_DATA:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          pending: true,
        },
      };
    case TYPES.GET_VIEW_DATA_SUCCESS:
      const { next, previous, results: items } = action.payload;
      const currentUrl = action.params.data.url;
      return {
        ...state,
        [datasource]: {
          selectedRows: [],
          ...state[datasource],
          datasource,
          currentUrl,
          pending: false,
          showFilters: false,
          error: false,
          items,
          next,
          previous,
          meta: {
            ...state[datasource].meta,
            current: getCurrent(action.params.data, state[datasource].meta, action.params.additionalColumns),
          },
        },
      };
    case TYPES.GET_ALL_VIEW_DATA_SUCCESS:
      const Allitems = (state[`${datasource}_temp`]?.items ?? []).concat(action.payload.results ?? []);
      if (action.payload.next) {
        return {
          ...state,
          [`${datasource}_temp`]: {
            next: action.payload.next,
            previous: action.payload.previous,
            items: Allitems,
          },
        };
      } else {
        return {
          ...state,
          [`${datasource}_temp`]: undefined,
          [datasource]: {
            selectedRows: [],
            ...state[datasource],
            datasource,
            currentUrl: action.params.data.url,
            pending: false,
            showFilters: false,
            error: false,
            items: Allitems,
            next: null,
            previous: null,
            meta: {
              ...state[datasource].meta,
              current: getCurrent(action.params.data, state[datasource].meta, action.params.additionalColumns),
            },
          },
        };
      }
    case TYPES.GET_VIEW_DATA_ERROR:
    case TYPES.GET_ALL_VIEW_DATA_ERROR:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          pending: false,
          error: true,
        },
      };
    case TYPES.SELECT_ROW:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          selectedRows: state[datasource].selectedRows.concat(action.payload),
          askToSelect: true,
        },
      };

    case TYPES.DESELECT_ROW:
      const selectedRows = state[datasource].selectedRows.filter(id => id !== action.payload);
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          selectedRows,
          askToSelect: selectedRows.length > 0,
        },
      };

    case TYPES.SELECT_ALL_SUCCESS:
      return {
        ...state,
        [action.payload.datasource]: {
          ...state[action.payload.datasource],
          selectedRows: action.payload.selectedRows,
          selectedAll: true,
          askToSelect: false,
        },
      };

    case TYPES.DESELECT_ALL:
      return {
        ...state,
        [action.payload.datasource]: {
          ...state[action.payload.datasource],
          selectedRows: [],
          selectedAll: false,
          askToSelect: false,
        },
      };

    case TYPES.SHOW_FILTERS:
      return {
        ...state,
        [action.payload.datasource]: {
          ...state[action.payload.datasource],
          showFilters: !state[action.payload.datasource].showFilters,
        },
      };

    case TYPES.SELECT_ALL_VISIBLE:
      return {
        ...state,
        [action.payload.datasource]: {
          ...state[action.payload.datasource],
          selectedRows: [
            ...new Set([...state[action.payload.datasource].selectedRows, ...action.payload.selectedRows]),
          ],
          askToSelect: true,
        },
      };

    case TYPES.SELECT_ALL:
      return {
        ...state,
      };

    case TYPES.UPDATE_VIEW_META_CURRENT:
      const newData = {
        ...state[datasource].meta.current,
        columns: state[datasource].meta.current.columns.map(columns => columns.name),
        filters: [
          ...state[datasource].meta.current.filters.filter(f => action.payload.filter.name !== f.name),
          action.payload.filter,
        ].filter(f => f.value !== null),
      };
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          meta: {
            ...state[datasource].meta,
            current: getCurrent({ ...newData }, state[datasource].meta),
          },
        },
      };

    case TYPES.UPDATE_FILTER:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          filter: action.payload.filter,
        },
      };

    case TYPES.UPDATE_FILTERS:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          meta: {
            ...state[datasource].meta,
            current: {
              ...state[datasource].meta.current,
              filters: action.payload.filters,
            },
          },
        },
      };

    case TYPES.CLEAR_SELECTION:
      return {
        ...state,
        [datasource]: {
          ...state[datasource],
          selectedRows: [],
          askToSelect: false,
        },
      };

    case TYPES.CLEAR_VIEW:
      return {
        ...state,
        [datasource]: undefined,
      };
    case TYPES.CLEAR_VIEW_DATA:
      return {
        ...state,
        [datasource]: {
          selectedRows: [],
          ...state[datasource],
          items: null,
          currentUrl: undefined,
          selectedAll: false,
          filter: {},
          next: null,
          previous: null,
          pending: false,
          error: false,
        },
      };

    default:
      return state;
  }
};

export const getViewData = (datasource, data, additionalColumns, endPoint) =>
  callApi({
    types: {
      pending: TYPES.GET_VIEW_DATA,
      success: TYPES.GET_VIEW_DATA_SUCCESS,
      error: TYPES.GET_VIEW_DATA_ERROR,
    },
    params: { datasource, data, additionalColumns },
    request: () => {
      return axios.post(`${endPoint ?? datasource}/query/?limit=${data.limit}&offset=${data.offset}`, data);
    },
  });

export const getAllViewData = (datasource, data, additionalColumns, endPoint) =>
  callApi({
    types: {
      pending: TYPES.GET_ALL_VIEW_DATA,
      success: TYPES.GET_ALL_VIEW_DATA_SUCCESS,
      error: TYPES.GET_ALL_VIEW_DATA_ERROR,
    },
    params: { datasource, data, additionalColumns },
    request: () => {
      return axios.post(`${endPoint ?? datasource}/query/?limit=300&offset=${data.offset}`, data);
    },
  });

export const updateViewMetaFilters = (data, filter) => ({
  type: 'UPDATE_VIEW_META_CURRENT',
  params: { datasource: data.datasource },
  payload: { data, filter },
});

export const getViewMeta = (datasource, overrides, additionalColumns, fetchData, endPoint) =>
  callApi({
    types: {
      pending: TYPES.GET_VIEW_META,
      success: TYPES.GET_VIEW_META_SUCCESS,
      error: TYPES.GET_VIEW_META_ERROR,
    },
    params: { datasource, overrides, additionalColumns, fetchData },
    request: () => axios.get(`${endPoint ?? datasource}/query/`),
  });

export const addSelectedRows = (datasource, data) => ({
  type: 'SELECT_ROW',
  params: { datasource },
  payload: data,
});

export const selectAllVisible = ({ datasource, selectedRows }) => ({
  type: 'SELECT_ALL_VISIBLE',
  payload: { datasource, selectedRows },
});

export const removeSelectedRow = (datasource, data) => ({
  type: TYPES.DESELECT_ROW,
  params: { datasource },
  payload: data,
});

export const selectAll = (datasource, data) => ({
  type: TYPES.SELECT_ALL,
  payload: { datasource, ...data },
});

export const selectAllNext = data => ({
  type: TYPES.SELECT_ALL_NEXT,
  payload: { ...data },
});

export const deselectAll = datasource => ({
  type: TYPES.DESELECT_ALL,
  payload: { datasource },
});

export const showFilters = datasource => ({
  type: TYPES.SHOW_FILTERS,
  payload: { datasource },
});

export const updateFilter = (datasource, filter) => ({
  type: TYPES.UPDATE_FILTER,
  payload: { datasource, filter },
});

export const updateFilters = (datasource, filters) => ({
  type: TYPES.UPDATE_FILTERS,
  payload: { datasource, filters },
});

export const clearSelection = datasource => ({
  type: TYPES.CLEAR_SELECTION,
  payload: { datasource },
});

export const clearView = datasource => ({
  type: TYPES.CLEAR_VIEW,
  payload: { datasource },
});

export const clearViewData = datasource => ({
  type: TYPES.CLEAR_VIEW_DATA,
  payload: { datasource },
});

export default views;
