import React from 'react';
import styled from '@emotion/styled';
import { RiskBackground } from 'assets/containers/AssetDetailView/styles';
import PropTypes from 'prop-types';
import { removeIndexRiskFilters } from 'assets/modules/riskfilter';
import { useDispatch } from 'react-redux';

function RiskModelButton({ id, name, score, isActive, setActiveRiskModel, category }) {
  const dispatch = useDispatch();

  function onRiskButtonClick() {
    setActiveRiskModel(id);
    setTimeout(() => {
      dispatch(removeIndexRiskFilters());
    }, 2000);
  }

  return (
    <ButtonContainer onClick={onRiskButtonClick} isActive={isActive}>
      <RiskName>{name}</RiskName>
      <RiskScore color={RiskBackground(category || '')}>{score || '-'}</RiskScore>
    </ButtonContainer>
  );
}

export default RiskModelButton;

RiskModelButton.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive: PropTypes.bool,
  setActiveRiskModel: PropTypes.func,
};

RiskModelButton.defaultProps = {
  score: '-',
  isActive: false,
  setActiveRiskModel: () => {},
};

const RiskScore = styled.div`
  min-width: 30px;
  height: 30px;
  text-align: center;
  background-color: ${props => props.color || '#009702'};
  padding: 6px 0;
  border-radius: 5px;
  text-decoration: none;
`;

const ButtonContainer = styled.div`
  font-size: 12px;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#246472' : '')};
  padding: 5px;
  border-radius: 4px;
  max-width: 16%;
  min-width: 80px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
`;

const RiskName = styled.div`
  margin: 5px 3px 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
