import React from 'react';
import { DatePicker } from 'antd';
import { Select } from 'layout/Select';
import { Label as Input } from '@luxe/components';
import moment from 'moment';

const getWidget = (attribute, value, onChange) => {
  const { widget } = attribute;
  const handleChangeDates = (...args) => {
    if (args[1]) {
      onChange(args[1]);
    }
  };

  if (widget === 'bool' || widget === 'percent') {
    const options =
      widget === 'bool'
        ? [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]
        : [0, 25, 50, 75, 100].map(x => {
            return { value: `${x}%`, label: `${x}%` };
          });
    const optionValue = options.find(opt => {
      return opt.value === value;
    });
    return (
      <Select
        className={`selectors-${widget}`}
        placeholder="Select"
        value={optionValue}
        options={options}
        onChange={(...args) => onChange(args[0].value)}
      />
    );
  } else if (widget === 'calendar') {
    const dateFormat = 'YYYY/MM/DD';
    value = value ? moment(value.split(' ')[0], dateFormat) : null;
    function disabledDate(current) {
      // Can not select days before today
      return current && current < moment().add(-1, 'days');
    }
    return (
      <DatePicker
        className="datepicker"
        value={value}
        format={dateFormat}
        disabledDate={disabledDate}
        onChange={handleChangeDates}
      />
    );
  } else if (widget === 'input') {
    return <Input placeholder="Type" value={value} onChange={ev => onChange(ev.target.value)} />;
  } else if (widget === 'day_selector') {
    const newValue = value ? value.replace(' days', '') : '0';
    return (
      <Input
        className="delay"
        placeholder="0"
        min="0"
        type="number"
        suffix="Days"
        value={newValue}
        onChange={ev => onChange(`${ev.target.value} days`)}
      />
    );
  }
};
export default getWidget;
