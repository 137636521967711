import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { FormItem, Label, Section } from '@luxe/components';

const AssetLocationSection = ({ asset, form }) => {
  useEffect(() => {
    if (asset?.location) {
      const initialValues = {
        address: asset.location.address,
        city: asset.location.city,
        country: asset.location.country.name,
        state: asset.location.state.name,
        postal_code: asset.location.postal_code,
        latitude: asset.location.lat,
        longitude: asset.location.lon,
      };
      form.setFieldsValue(initialValues);
    }
  }, [asset, form]);

  return (
    <>
      <Section title={'Location'} headerFontSize={'12px'} backgroundColor={'transparent'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="address" label="Street Address" labelCol={{ span: 24 }}>
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="city" label="City" labelCol={{ span: 24 }} style={{ marginLeft: '20px' }}>
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="state" label="State" labelCol={{ span: 24 }}>
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="postal_code" label="Postal Code" labelCol={{ span: 24 }} style={{ marginLeft: '20px' }}>
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="latitude" label="Latitude" labelCol={{ span: 24 }}>
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="longitude"
              label="Longitude"
              labelCol={{ span: 24 }}
              style={{ marginLeft: '20px' }}
              disabled
            >
              <Label disabled width={'100%'} />
            </FormItem>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default AssetLocationSection;
