import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustry } from 'modal/userprofile/modules/network';
import { updateAccount } from 'account/modules/accountOrg';

import { TreeSelect } from 'antd';
import { Global, css } from '@emotion/react';

import styled from '@emotion/styled';
import LoadingScreen from 'layout/containers/LoadingScreen';
import useMixpanel from 'utils/hooks/Mixpanel';

const IndustryView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { ignored_industries } = useSelector(store => store.accountOrg)?.accountOrg;

  const industries = useSelector(store => store.userNetwork.industries);
  const [formattedIndustries, setFormattedIndustries] = useState([]);

  useEffect(() => {
    const populateChild = arr => {
      let ind = [];
      arr.forEach(i => {
        ind.push({
          title: i.name,
          value: i.id,
          key: i.id,
          children: i.children?.length > 0 ? populateChild(i.children) : [],
        });
      });
      return ind;
    };

    if (industries?.length) {
      setFormattedIndustries(populateChild(industries));
    }
  }, [industries]);

  useEffect(() => {
    if (industries.length === 0) {
      dispatch(getIndustry());
    }
  }, [industries, dispatch]);

  const updateSubIndustries = industries => {
    mixpanelTrack('Admin Industry Preference Change');
    dispatch(updateAccount({ ignored_industries: industries, update: true }));
  };
  if (industries.length === 0) {
    return (
      <div className={'loader'}>
        <LoadingScreen modal={true} />
      </div>
    );
  }
  return (
    <div className={props.className}>
      <div className={`industry-control`}>
        <h3>Industry Exclusions</h3>
        <h4>
          Incidents with Industry Tags that match all of the selected industries below will be excluded from scoring and
          notifications as well as hidden for all users in your organization.
        </h4>
        <div className={`${props.className}`}>
          <Global
            styles={css`
              .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
                background-color: #2d8fa5 !important;
                border-color: #2d8fa5 !important;
              }
            `}
          />{' '}
          <TreeSelect
            allowClear={true}
            className={'general-subindustry'}
            name="industries"
            onChange={updateSubIndustries}
            placeholder={'Select Industries'}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            showSearch={true}
            treeCheckable={true}
            treeData={formattedIndustries}
            value={ignored_industries}
            dropdownStyle={{
              backgroundColor: 'transparent',
              border: `1px solid ${props => props.theme.colors.white}`,
            }}
            filterTreeNode={(search, option) => {
              return (
                option.title
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) >= 0
              );
            }}
          />
        </div>
      </div>
    </div>
  );
})`
  .industry-control {
    padding: 14px;
    h3,
    h4 {
      color: white;
      font-weight: normal;
    }
    padding: 24px;
    .ant-select-tree,
    .ant-select-tree-list {
      color: red;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #141414;
      border-color: #246472;
      border-radius: 4px;
    }

    .ant-select-tree,
    .ant-select-dropdown,
    .ant-tree-select-dropdown,
    .ant-tree-select-dropdown .ant-select-tree,
    .general-subindustry {
      width: 50%;
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: #40424e;
      border: 1px solid #54596e;
      color: #ccc5b8;
      font-size: 85%;
    }

    .ant-select-selection-search {
      color: ${props => props.theme.colors.white};
    }

    .ant-select-multiple .ant-select-selection-item-remove {
      color: #cccccc;
    }

    .anticon-close-circle {
      color: white;
    }
    .ant-select-clear {
      background-color: #141414;
    }
  }
`;
export default IndustryView;
