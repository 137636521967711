import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { Row, Col, Button, Typography } from 'antd';
import { BasicModal as Modal } from '@luxe/components';
import { Select } from 'layout/Select';
import styled from '@emotion/styled';
import { MainContent } from '@luxe/components/src/Container';
import {
  getRiskProfiles,
  setDiscoveredFacilityProfileValue,
  updateDiscoveredFacilityProfile,
} from 'admin/riskprofiles/modules/riskprofiles';

const DiscoveredFacilityProfile = styled(props => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const { space, colors, fontSizes } = useTheme();
  const { riskProfiles, discoveredFacilityProfileValue } = useSelector(store => store.riskProfiles);
  const defaultDiscoverProfile = useSelector(state => state.accountOrg?.accountOrg?.prefs?.discover?.profiles?.default);
  const riskProfileOptions = useMemo(() => {
    return riskProfiles.map(x => {
      return { value: x.id, label: x.name };
    });
  }, [riskProfiles]);

  const onChangeHandler = val => {
    dispatch(setDiscoveredFacilityProfileValue(val));
  };

  const onSaveHandler = () => {
    if (discoveredFacilityProfileValue?.label) {
      dispatch(updateDiscoveredFacilityProfile(discoveredFacilityProfileValue?.label));
    }
  };

  const onCancelHandler = () => {
    setModalVisible(discoveredFacilityProfileValue?.value ? true : false);
  };

  useEffect(() => {
    dispatch(getRiskProfiles());
  }, [dispatch]);

  useEffect(() => {
    if (!discoveredFacilityProfileValue?.label && riskProfiles?.length > 0) {
      const val = riskProfiles.find(x => x.name === defaultDiscoverProfile);
      if (val) {
        const formattedData = { value: val.id, label: val.name };
        dispatch(setDiscoveredFacilityProfileValue(formattedData));
      }
    }
  }, [defaultDiscoverProfile, dispatch, riskProfiles, discoveredFacilityProfileValue]);

  return (
    <MainContent>
      <div className={`${props.className}`}>
        <div className={'container'} style={{ margin: `${space.none} ${space.small}px`, size: '14px' }}>
          <Row gutter={[4, 8]}>
            <Col span={10} align="start">
              <header>
                <h1>Discovered Facility Profile</h1>
              </header>
            </Col>
            <Col span={14} align="end">
              <Button className={'submit general-button'} onClick={onSaveHandler}>
                Save
              </Button>
              <Button className={'cancel general-button'} onClick={onCancelHandler}>
                Cancel
              </Button>
            </Col>
          </Row>
          <Row className="profile-section">
            <h4>All discovered facilities will use the selected profile.</h4>
            <div className="profile-dropdown">
              <Select
                value={discoveredFacilityProfileValue}
                style={{ width: '100px' }}
                options={riskProfileOptions}
                onChange={onChangeHandler}
              />
            </div>
          </Row>
        </div>
        {modalVisible && (
          <Modal
            visible={modalVisible}
            title="Cancel Account Setting Changes?"
            okText="Yes"
            cancelText="No"
            onCancel={() => setModalVisible(false)}
            onOk={() => {
              setModalVisible(false);
              dispatch(setDiscoveredFacilityProfileValue({ value: '', label: '' }));
            }}
          >
            <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
              Are you sure you want to cancel your changes?
            </Text>
          </Modal>
        )}
      </div>
    </MainContent>
  );
})`
  .container {
    color: white;
    .cancel {
      color: #ffffff;
      background-color: #545454;
    }

    .cancel:hover {
      background-color: transparent;
      border: 0;
    }

    .general-button {
      border: 0;
      border-radius: 4px;
      color: #eeeeee;
      cursor: pointer;
      display: inline;
      float: right;
      font-size: 12px;
      margin: 4px;
      padding: 4px 10px;
    }

    .submit {
      background-color: ${props => props.theme.colors.primary};
      border: 1px solid #39b2c6;
      color: #eeeeee;
    }

    header {
      padding: 24px;

      h1 {
        font-size: 40px;
        line-height: 32px;
        color: white;
      }
    }

    .profile-section {
      display: block;
      padding: 24px;
    }

    .profile-dropdown {
      width: 350px;
    }
  }
`;
export default DiscoveredFacilityProfile;
