import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { stringSorter } from 'utils';
import { deleteRuleFromProfile, updateRiskProfileRuleFromState, insertRiskProfileRule, convertValue } from './utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_RISK_PROFILES: 'GET_RISK_PROFILES',
  GET_RISK_PROFILES_ERROR: 'GET_RISK_PROFILES_ERROR',
  GET_RISK_PROFILES_SUCCESS: 'GET_RISK_PROFILES_SUCCESS',

  GET_RISK_PROFILE_DETAIL: 'GET_RISK_PROFILE_DETAIL',
  GET_RISK_PROFILE_DETAIL_ERROR: 'GET_RISK_PROFILE_DETAIL_ERROR',
  GET_RISK_PROFILE_DETAIL_SUCCESS: 'GET_RISK_PROFILE_DETAIL_SUCCESS',

  DELETE_RISK_PROFILES: 'DELETE_RISK_PROFILES',
  DELETE_RISK_PROFILES_ERROR: 'DELETE_RISK_PROFILES_ERROR',
  DELETE_RISK_PROFILES_SUCCESS: 'DELETE_RISK_PROFILES_SUCCESS',

  SAVE_RISK_PROFILES: 'SAVE_RISK_PROFILES',
  SAVE_RISK_PROFILES_ERROR: 'SAVE_RISK_PROFILES_ERROR',
  SAVE_RISK_PROFILES_SUCCESS: 'SAVE_RISK_PROFILES_SUCCESS',

  CREATE_RISK_PROFILE_RULE: 'CREATE_RISK_PROFILE_RULE',
  CREATE_RISK_PROFILE_RULE_ERROR: 'CREATE_RISK_PROFILE_RULE_ERROR',
  CREATE_RISK_PROFILE_RULE_SUCCESS: 'CREATE_RISK_PROFILE_RULE_SUCCESS',

  UPDATE_RISK_PROFILE_RULE: 'UPDATE_RISK_PROFILE_RULE',
  UPDATE_RISK_PROFILE_RULE_ERROR: 'UPDATE_RISK_PROFILE_RULE_ERROR',
  UPDATE_RISK_PROFILE_RULE_SUCCESS: 'UPDATE_RISK_PROFILE_RULE_SUCCESS',

  DELETE_RISK_PROFILE_RULE: 'DELETE_RISK_PROFILE_RULE',
  DELETE_RISK_PROFILE_RULE_ERROR: 'DELETE_RISK_PROFILE_RULE_ERROR',
  DELETE_RISK_PROFILE_RULE_SUCCESS: 'DELETE_RISK_PROFILE_RULE_SUCCESS',

  CREATE_RISK_PROFILE: 'CREATE_RISK_PROFILE',
  CREATE_RISK_PROFILE_ERROR: 'CREATE_RISK_PROFILE_ERROR',
  CREATE_RISK_PROFILE_SUCCESS: 'CREATE_RISK_PROFILE_SUCCESS',

  UPDATE_RISK_PROFILE: 'UPDATE_RISK_PROFILE',

  EDIT_RISK_PROFILE_RULE: 'EDIT_RISK_PROFILE_RULE',
  EDIT_RISK_PROFILE_RULE_DONE: 'EDIT_RISK_PROFILE_RULE_DONE',

  CLONE_RISK_PROFILES: 'CLONE_RISK_PROFILES',
  CLONE_RISK_PROFILES_ERROR: 'CLONE_RISK_PROFILES_ERROR',
  CLONE_RISK_PROFILES_SUCCESS: 'CLONE_RISK_PROFILES_SUCCESS',

  SET_DISCOVERED_FACILITY_PROFILE: 'SET_DISCOVERED_FACILITY_PROFILE',

  UPDATE_DISCOVERED_FACILITY_PROFILE: 'UPDATE_DISCOVERED_FACILITY_PROFILE',
  UPDATE_DISCOVERED_FACILITY_PROFILE_SUCCESS: 'UPDATE_DISCOVERED_FACILITY_PROFILE_SUCCESS',
  UPDATE_DISCOVERED_FACILITY_PROFILE_ERROR: 'UPDATE_DISCOVERED_FACILITY_PROFILE_ERROR',

  TRACK_RISK_PROFILE_CHANGED: 'TRACK_RISK_PROFILE_CHANGED',
};

const getRuleUOM = uom => {
  if (uom === 'F') {
    return 'degF';
  }

  if (uom === 'C') {
    return 'degC';
  }

  return uom;
};

const convertUnits = rule => {
  if (rule.units) {
    const desiredUnits = rule.desiredUnits || rule.catalog?.data.options?.units;

    if (desiredUnits !== rule.units) {
      let ruleData = { ...rule.data };
      ruleData.values = ruleData.values.map(val => {
        const newVal = convertValue(val.value, rule.units, desiredUnits);
        return { ...val, value: newVal };
      });

      return { ...rule, ...{ data: ruleData, units: desiredUnits } };
    }
  }

  return rule;
};

export const getDesiredRuleUnits = (rule, system) => {
  if (rule.data) {
    const ruleUOM = rule.data.options?.uom ? rule.data.options.uom : {};
    let desiredUnits = rule.catalog ? rule.catalog.data.options?.units : rule.data.options?.units;
    if (ruleUOM) {
      desiredUnits = getRuleUOM(ruleUOM[system]);
    }

    return desiredUnits;
  }
};

export const updateRiskProfile = data => ({
  type: TYPES.UPDATE_RISK_PROFILE,
  payload: { ...data },
});

export const editRiskProfileRule = data => ({
  type: TYPES.EDIT_RISK_PROFILE_RULE,
  payload: data,
});

export const stopEditingRiskProfileRule = data => ({
  type: TYPES.EDIT_RISK_PROFILE_RULE_DONE,
  payload: data,
});

export const setDiscoveredFacilityProfileValue = data => ({
  type: TYPES.SET_DISCOVERED_FACILITY_PROFILE,
  payload: data,
});

export const trackRiskProfileChange = val => ({
  type: TYPES.TRACK_RISK_PROFILE_CHANGED,
  payload: val,
});

//maglev API review
export const createRiskProfile = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_RISK_PROFILE,
      success: TYPES.CREATE_RISK_PROFILE_SUCCESS,
      error: TYPES.CREATE_RISK_PROFILE_ERROR,
    },
    request: () => axios.post('risk-profiles/', data),
    messages: {
      success: 'Risk Profile has been successfully created!',
      error: 'Risk Profile creation unsuccessful',
    },
    dynamicErrorMsg: true,
  });

export const getRiskProfiles = params =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_PROFILES,
      success: TYPES.GET_RISK_PROFILES_SUCCESS,
      error: TYPES.GET_RISK_PROFILES_ERROR,
    },
    params: params,
    request: () => axios.get('risk-profiles/'),
  });

export const getRiskProfileDetail = profileId =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_PROFILE_DETAIL,
      success: TYPES.GET_RISK_PROFILE_DETAIL_SUCCESS,
      error: TYPES.GET_RISK_PROFILE_DETAIL_ERROR,
    },
    request: () => axios.get(`risk-profiles/${profileId}/`),
  });

export const deleteRiskProfiles = profileId =>
  callApi({
    types: {
      pending: TYPES.DELETE_RISK_PROFILES,
      success: TYPES.DELETE_RISK_PROFILES_SUCCESS,
      error: TYPES.DELETE_RISK_PROFILES_ERROR,
    },
    request: () => axios.delete(`risk-profiles/${profileId}/`),
    messages: {
      success: 'Risk profile has been successfully deleted!',
      error: 'Risk profile deletion unsuccessful',
    },
  });

export const saveRiskProfiles = data =>
  callApi({
    types: {
      pending: TYPES.SAVE_RISK_PROFILES,
      success: TYPES.SAVE_RISK_PROFILES_SUCCESS,
      error: TYPES.SAVE_RISK_PROFILES_ERROR,
    },
    request: () => axios.put(`risk-profiles/${data.id}/`, data),
    messages: {
      success: 'Risk Profile has been successfully edited!',
      error: 'Risk Profile update unsuccessful',
    },
    dynamicErrorMsg: true,
  });

export const cloneRiskProfiles = data =>
  callApi({
    types: {
      pending: TYPES.CLONE_RISK_PROFILES,
      success: TYPES.CLONE_RISK_PROFILES_SUCCESS,
      error: TYPES.CLONE_RISK_PROFILES_ERROR,
    },
    request: () => axios.post(`risk-profiles/?source=${data.id}`, data),
    messages: {
      success: 'Risk Profile has been successfully cloned!',
      error: 'Risk Profile clone unsuccessful',
    },
    dynamicErrorMsg: true,
  });

export const INITIAL_STATE = {
  status: undefined,
  riskProfiles: [],
  error: undefined,
  params: undefined,
  RiskProfile: undefined,
  RiskProfileRule: undefined,
  discoveredFacilityProfileValue: {},
  isRiskProfileChanged: false,
};

export const deleteRiskProfileRule = (profileId, ruleId) =>
  callApi({
    types: {
      pending: TYPES.DELETE_RISK_PROFILE_RULE,
      success: TYPES.DELETE_RISK_PROFILE_RULE_SUCCESS,
      error: TYPES.DELETE_RISK_PROFILE_RULE_ERROR,
    },
    params: { ruleId },
    request: () => axios.delete(`risk-profiles/${profileId}/rules/${ruleId}/`),
    messages: {
      success: 'Profile rule has been successfully deleted!',
      error: 'Profile rule deletion unsuccessful',
    },
  });

const prepareRiskProfileRule = data => {
  delete data.desiredUnits;

  const convertedData = convertUnits(data);
  const { catalog, phenomena, ...rule } = convertedData;
  rule.catalog_id = catalog.id;

  return rule;
};

export const updateRiskProfileRule = (profileId, data) => {
  const { desiredUnits } = data;
  const rule = prepareRiskProfileRule(data);
  return callApi({
    types: {
      pending: TYPES.UPDATE_RISK_PROFILE_RULE,
      success: TYPES.UPDATE_RISK_PROFILE_RULE_SUCCESS,
      error: TYPES.UPDATE_RISK_PROFILE_RULE_ERROR,
    },
    request: () => axios.put(`risk-profiles/${profileId}/rules/${data.id}/`, rule),
    params: { desiredUnits },
    messages: {
      success: 'Profile rule has been successfully updated!',
      error: 'Profile rule update unsuccessful',
    },
  });
};

export const updateDiscoveredFacilityProfile = val => {
  const data = {
    discover: {
      default: val,
    },
  };
  return callApi({
    types: {
      pending: TYPES.UPDATE_DISCOVERED_FACILITY_PROFILE,
      success: TYPES.UPDATE_DISCOVERED_FACILITY_PROFILE_SUCCESS,
      error: TYPES.UPDATE_DISCOVERED_FACILITY_PROFILE_ERROR,
    },
    request: () => axios.patch(`account/prefs/`, data),
    messages: {
      success: 'Discover Facility Profile has been successfully updated!',
      error: 'Discover Facility Profile update unsuccessful',
    },
  });
};

export const createRiskProfileRule = (profileId, data) => {
  const { desiredUnits } = data;
  const rule = prepareRiskProfileRule(data);

  return callApi({
    types: {
      pending: TYPES.CREATE_RISK_PROFILE_RULE,
      success: TYPES.CREATE_RISK_PROFILE_RULE_SUCCESS,
      error: TYPES.CREATE_RISK_PROFILE_RULE_ERROR,
    },
    request: () => axios.post(`risk-profiles/${profileId}/rules/`, rule),
    params: { desiredUnits },

    messages: {
      success: 'Profile rule has been successfully created!',
      error: 'Profile rule creation unsuccessful',
    },
  });
};

const updateProfileData = (profile = {}) => {
  profile.parent_id = profile.parent ? profile.parent.id : null;

  if (profile.rules) {
    profile.rules.sort((a, b) => stringSorter(a.name, b.name));
    profile.rules.forEach(rule => {
      if (rule.data.options?.units) {
        rule.units = rule.data.options?.units;
      }
    });
  }

  return profile;
};

export const riskProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_RISK_PROFILES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RISK_PROFILES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RISK_PROFILES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        riskProfiles: action.payload,
        error: undefined,
      };
    case TYPES.SET_DISCOVERED_FACILITY_PROFILE:
      return {
        ...state,
        discoveredFacilityProfileValue: action.payload,
      };
    case TYPES.UPDATE_DISCOVERED_FACILITY_PROFILE_SUCCESS:
      const profileVal = state.riskProfiles.find(
        riskProfile => riskProfile.name === action.payload?.discover?.profiles?.default,
      );
      return {
        ...state,
        discoveredFacilityProfileValue: profileVal ? { value: profileVal?.id, label: profileVal?.name } : {},
      };
    case TYPES.TRACK_RISK_PROFILE_CHANGED:
      return {
        ...state,
        isRiskProfileChanged: action.payload,
      };
    case TYPES.GET_RISK_PROFILE_DETAIL:
      return {
        ...state,
        params: action.payload,
        RiskProfile: null,
        detailStatus: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_RISK_PROFILE_DETAIL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        detailStatus: REQUESTED_DATA_STATUS.ERROR,
      };
    case TYPES.GET_RISK_PROFILE_DETAIL_SUCCESS:
      return {
        ...state,
        RiskProfile: updateProfileData(action.payload),
        error: undefined,
        detailStatus: REQUESTED_DATA_STATUS.SUCCESS,
      };

    case TYPES.CREATE_RISK_PROFILE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.CREATE_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        riskProfiles: [...state.riskProfiles, action.payload],
        error: undefined,
      };

    case TYPES.CREATE_RISK_PROFILE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_RISK_PROFILES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.DELETE_RISK_PROFILES_SUCCESS:
      const deletedRiskProfile = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        riskProfiles: state.riskProfiles.filter(riskProfile => riskProfile.id !== deletedRiskProfile.id),
        error: undefined,
      };
    case TYPES.DELETE_RISK_PROFILES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.SAVE_RISK_PROFILES:
    case TYPES.CLONE_RISK_PROFILES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
      };
    case TYPES.SAVE_RISK_PROFILES_SUCCESS:
    case TYPES.CLONE_RISK_PROFILES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskProfile: updateProfileData(action.payload),
        error: undefined,
      };
    case TYPES.SAVE_RISK_PROFILES_ERROR:
    case TYPES.CLONE_RISK_PROFILES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_RISK_PROFILE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_RISK_PROFILE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_RISK_PROFILE_RULE_SUCCESS:
      const updatedRule = {
        ...action.payload,
        units: action.payload.catalog.data.options?.units,
        desiredUnits: action.params?.desiredUnits,
      };
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        RiskProfileRule: { ...state.RiskProfileRule, ...{ mode: null } },
        RiskProfile: updateRiskProfileRuleFromState(state.RiskProfile, updatedRule),
      };

    case TYPES.DELETE_RISK_PROFILE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_RISK_PROFILE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_RISK_PROFILE_RULE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        RiskProfileRule: { ...state.RiskProfileRule, ...{ mode: null } },
        RiskProfile: deleteRuleFromProfile(state.RiskProfile, action.params.ruleId),
      };

    case TYPES.CREATE_RISK_PROFILE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.CREATE_RISK_PROFILE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CREATE_RISK_PROFILE_RULE_SUCCESS:
      const newRule = {
        ...action.payload,
        ...{ mode: null, units: action.payload.catalog.data.options?.units, desiredUnits: action.params?.desiredUnits },
      };
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        RiskProfileRule: newRule,
        RiskProfile: insertRiskProfileRule(state.RiskProfile, newRule),
      };
    case TYPES.UPDATE_RISK_PROFILE:
      let updatedRiskProfile = {
        RiskProfile: {
          ...state.RiskProfile,
          ...action.payload,
        },
      };
      return {
        ...state,
        ...updatedRiskProfile,
      };
    case TYPES.EDIT_RISK_PROFILE_RULE:
      const { reset, ...payload } = action.payload;
      const convertedPayload = convertUnits(payload);

      if (reset) {
        return {
          ...state,
          RiskProfileRule: {
            ...convertedPayload,
          },
        };
      } else {
        return {
          ...state,
          RiskProfileRule: {
            ...state.RiskProfileRule,
            ...convertedPayload,
          },
        };
      }
    case TYPES.EDIT_RISK_PROFILE_RULE_DONE:
      return {
        ...state,
        ...{ RiskProfileRule: { mode: null } },
      };
    default:
      return state;
  }
};
