import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import { push } from 'connected-react-router';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { updateRule, addSelectedItem, removeSelectedItem, resetSelectedIds } from 'admin/workflows/modules/rules';
import { clearView, getViewData, getViewMeta } from 'views/modules/views';
import { ListViewContent } from '@luxe/components/src/Container';

import { ViewTable } from 'views/components';
import ManualAssignment from './Assignment';
import HeaderRow from './HeaderRow';
import { SearchInput } from '@luxe/components';
import { getParams } from 'views/utils';
import { SectionDivider } from '@luxe/components/src/Section';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const ASSET_TYPE = 'asset';
const ASSET_DATA_SOURCE = 'assets';

const RuleDetailView = styled(({ rule, className }) => {
  const { space } = useTheme();
  const dispatch = useDispatch();
  const { search: url } = useLocation();
  const firstLoad = useRef(true);

  const selectedIds = useSelector(store => store.workflowRules.selectedIds);
  const data = useSelector(store => store.views[ASSET_DATA_SOURCE]);

  const columns = useMemo(() => ['asset', 'org_type', 'location__city'], []);
  const orderBy = useMemo(() => [{ name: 'asset__name', direction: 'desc' }], []);

  useEffect(() => {
    const assetsFilters = [
      ...(rule.settings?.filtering?.assets ?? []),
      { name: 'system_type', operator: 'in', value: [1, 2] },
    ];
    const fetchData = () =>
      dispatch(
        getViewData(ASSET_DATA_SOURCE, {
          columns: columns.map(c => c.split('__')[0]),
          orderBy,
          url,
          ...getParams(url),
          filters: assetsFilters?.filter(x => x.name !== 'id') || [],
        }),
      );

    if (firstLoad.current) {
      firstLoad.current = false;
      return dispatch(getViewMeta(ASSET_DATA_SOURCE, undefined, undefined, fetchData));
    }
    fetchData();
  }, [dispatch, columns, orderBy, url, rule.settings]);

  useEffect(() => () => dispatch(clearView(ASSET_DATA_SOURCE)), [dispatch]);

  const onNameChange = ev => {
    dispatch(updateRule({ name: ev.target.value }));
  };

  const onFiltersChanged = filters => {
    const settings = {
      ...rule.settings,
      filtering: {
        ...rule.settings.filtering,
        assets: filters,
      },
    };
    dispatch(updateRule({ settings }));
  };

  const getRowId = row => row.asset.id;

  const onPush = url => {
    dispatch(push(url));
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onSelect: (record, selected) => {
      if (!selected) {
        dispatch(removeSelectedItem({ ...record[ASSET_TYPE] }));
      } else {
        dispatch(addSelectedItem({ ...record[ASSET_TYPE] }));
      }
    },
    hideSelectAll: true,
  };

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce(searchTerm => {
        const assetsFilters = [
          ...(rule.settings?.filtering?.assets ?? []),
          { name: 'system_type', operator: 'in', value: [1, 2] },
          { name: 'name', operator: 'icontains', value: searchTerm },
        ];
        const fetchData = () =>
          dispatch(
            getViewData(ASSET_DATA_SOURCE, {
              columns: columns.map(c => c.split('__')[0]),
              orderBy,
              url,
              ...getParams(url),
              filters: assetsFilters?.filter(x => x.name !== 'id') || [],
            }),
          );
        fetchData();
      }, 350),
    [columns, dispatch, orderBy, rule.settings, url],
  );

  return data ? (
    <div className={className}>
      <ListViewContent
        style={{ margin: `${space.none} ${space.small}px`, size: '14px' }}
        className="list view risk-models"
      >
        <HeaderRow rule={rule} onChange={onNameChange} />
        <SectionDivider />
        <div className={'assignment-label'}>
          <label>Assignment </label>
          <Tooltip
            title="Select Auto for a logic-based rule and Manual for a custom selection rule"
            overlayInnerStyle={{ backgroundColor: '#141414', fontSize: '10px', color: '#ffffff' }}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <ManualAssignment
          className="type_button"
          ruleType={rule.assignment_type}
          updateRule={updateRule}
          resetSelectedIds={resetSelectedIds}
        />
        <div className={'text'}>
          {rule.assignment_type === 'Manual' ? `${selectedIds.length} facilities are selected` : null}
        </div>
        <div className={'filter-label'}>Filters</div>
        <SearchInput
          className="search-input"
          key={`workflows-search`}
          placeholder={''}
          onChange={e => {
            debouncedChangeHandler(e.target.value);
          }}
          style={{ marginTop: '-38px' }}
        />
        <ViewTable
          title="Assets"
          data={data}
          viewColumns={columns}
          push={onPush}
          rowSelection={rule.assignment_type === 'Manual' ? rowSelection : null}
          selectAll={() => {}}
          rowKey={row => row.asset.id}
          getRowId={getRowId}
          allowSelect={true}
          allowFiltering={data.meta?.current && true}
          onFiltersChanged={onFiltersChanged}
          includes={['country_id', 'group', 'org_type', 'corporation']}
          excludes={['system_type']}
        />
      </ListViewContent>
    </div>
  ) : null;
})`
  .ant-radio-button {
    background-color: #2d8fa5;
    border: 1px solid #2d8fa5;
  }

  .ant-radio-button-wrapper {
    background-color: #2c2c2c;
    color: #919191;
  }
  .ant-radio-group {
    margin: 5px 0 20px 0;
  }
  .filter-label {
    margin-top: 15px;
    padding-bottom: 5px;
  }
  .search-input {
    position: absolute;
    right: 2rem;
  }
  .text {
    font-weight: bold;
    min-height: 22px;
  }
`;

export default RuleDetailView;
