import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { LayoutFilled } from '@ant-design/icons';
import { getData } from 'assets/modules/utils';
import { HeatMapBox } from '@luxe/components';
import styled from '@emotion/styled';

export default class HeatMapFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
  };

  createIndicesData(riskIndex) {
    let arr = [];
    let tempArr = [];
    if (riskIndex !== undefined) {
      for (let i = 0; i < riskIndex.length; i++) {
        let iArray = getData(riskIndex[i], {}, riskIndex[i]);
        if (iArray.length > 0) {
          iArray = iArray.slice(0, 6);
          iArray.forEach(idx => tempArr.push({ name: idx.name, score: idx.score, category: idx.category }));
          tempArr.sort((a, b) => {
            return b.score - a.score;
          });
        }
      }
      arr = tempArr.slice(0, 6);
    }
    return arr;
  }

  formatChicklets(risks) {
    return risks.map((risk, idx) => {
      return (
        <HeatMapBox key={`cra-heatmap-${idx}`} category={risk.category}>
          <p>{risk.name}</p>
          <p>{risk.score || '-'}</p>
        </HeatMapBox>
      );
    });
  }

  render() {
    const StyledChicklets = styled(props => {
      const chicklets = this.formatChicklets(this.createIndicesData(this.props.value[0]?.indices));
      const containerHeight = chicklets.length > 0 ? '210px' : 'auto';
      return (
        <div className={props.className} style={{ height: containerHeight }}>
          {chicklets.length > 0 ? chicklets : <div>No Risk Information Available</div>}
        </div>
      );
    })`
      font-size: ${({ theme }) => theme.fontSizes.small}px;
      width: 240px;
      border: 1px solid black;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    `;
    return (
      <Tooltip
        title={() => {
          return <StyledChicklets />;
        }}
      >
        <LayoutFilled />
      </Tooltip>
    );
  }
}
