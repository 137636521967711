import React from 'react';
import { Skeleton, Table } from '@luxe/components';
import { getFormattedDate } from 'assets/modules/utils';
import { isEmpty } from 'utils';

const ScoreHistoryTable = ({ history }) => {
  function getHistoryTableData() {
    const tableData = [];
    let lastValue = undefined;
    history &&
      history
        .sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
        .forEach(item => {
          const date = new Date(item.created_date);
          tableData.push({
            user: item.created_by.name,
            date: date,
            oldScore: lastValue,
            newScore: item.score,
            comment: item.comment,
          });
          lastValue = item.score;
        });
    return tableData.sort((a, b) => b.date - a.date);
  }

  const columns = [
    { title: 'Who Changed', dataIndex: 'user' },
    { title: 'Date', dataIndex: 'date', render: (_, a) => getFormattedDate(a.date, 'DD-MM-YYYY HH:mm') },
    { title: 'Old Score', dataIndex: 'oldScore' },
    { title: 'New Score', dataIndex: 'newScore' },
    { title: 'Comment', dataIndex: 'comment' },
  ];

  if (!history || isEmpty(history)) {
    return <Skeleton active={true} />;
  }

  return <Table rowKey="date" columns={columns} dataSource={getHistoryTableData()} />;
};

export default ScoreHistoryTable;
