import AssetSelector from './AssetSelector';
import AssetProfileSelector from './AssetProfileSelector';
import LaneSelector from './LaneSelector';
import GroupSelector from './GroupSelector';
import Input from './Input';
import ModeSelector from './ModeSelector';
import RiskCategorySelector from './RiskCategorySelector';
import ThreatRiskCategorySelector from './ThreatRiskCategorySelector';
import ThreatRiskScoreSelector from './ThreatRiskScoreSelector';
import WatchtowerSelector from './WatchtowerSelector';
import CorporateSelector from './CorporateSelector';
import DateSelector from './DateSelector';
import CountrySelector from './CountrySelector';
import AssetGroupSelector from './AssetGroupSelector';
import AssetTypeSelector from './AssetTypeSelector';
import CategorySelector from './CategorySelector';
//TODO: render and pushing down an onchange
const widgets = {
  AssetSelector,
  AssetProfileSelector,
  AssetTypeSelector,
  LaneSelector,
  GroupSelector,
  Input,
  ModeSelector,
  RiskCategorySelector,
  ThreatRiskCategorySelector,
  ThreatRiskScoreSelector,
  WatchtowerSelector,
  CountrySelector,
  CorporateSelector,
  DateSelector,
  AssetGroupSelector,
  CategorySelector,
};

export default widgets;
