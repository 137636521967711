import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BasicModal, Button } from '@luxe/components';
import { Label as Input } from '@luxe/components';
import { Checkbox, Row } from 'antd';
import styled from '@emotion/styled';
import { createNetworkGraphView, updateNetworkGraphView, hideSaveModal } from 'discover/modules/viz';

const SaveModal = styled(({ unappliedFilters }) => {
  const { selectedView = {}, filters: graphFilters } = useSelector(store => store.networkGraph);
  const [inputValues, setInputValues] = useState({
    name: selectedView?.name || '',
    isPublicChecked: selectedView?.access === 'org' || false,
    isDefaultChecked: selectedView?.is_default_view || false,
  });

  const dispatch = useDispatch();

  const saveView = saveAsNew => {
    const savedViewSettings = {
      name: inputValues.name,
      access: inputValues.isPublicChecked ? 'org' : 'user',
      is_default: inputValues.isDefaultChecked,
      settings: { ...graphFilters, ...unappliedFilters },
    };
    if (saveAsNew || !selectedView.name) {
      dispatch(createNetworkGraphView(savedViewSettings));
    } else {
      dispatch(updateNetworkGraphView(selectedView.id, savedViewSettings));
    }
    dispatch(hideSaveModal());
  };
  return (
    <BasicModal
      visible={true}
      title={'Save View'}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={() => dispatch(hideSaveModal())}
      destroyOnClose={true}
      footer={null}
      style={{ backgroundColor: '#212121', color: '#ffffff' }}
    >
      {/* View name input */}
      <Row>
        <Input
          value={inputValues.name}
          onChange={e => {
            const { value } = e.target;
            setInputValues(prevState => ({ ...prevState, name: value }));
          }}
        />
      </Row>
      <Row style={{ padding: `25px 0px 0px 0px` }}>
        {/* Is Default List checkbox */}
        <Checkbox
          checked={inputValues.isDefaultChecked}
          onChange={e => {
            const { checked } = e.target;
            setInputValues(prevState => ({ ...prevState, isDefaultChecked: checked }));
          }}
        >
          Is Default View
        </Checkbox>
        {/* Public/Private checkbox (Org vs User Access) */}
        <Checkbox
          style={{ padding: `0px 58px 0px 0px` }}
          checked={inputValues.isPublicChecked}
          onChange={e => {
            const { checked } = e.target;
            setInputValues(prevState => ({ ...prevState, isPublicChecked: checked }));
          }}
        >
          Public.
        </Checkbox>
      </Row>
      <Row style={{ padding: `25px 0px 0px 0px`, justifyContent: 'end' }}>
        <div>
          {selectedView?.name && (
            <Button key="save" variant="primary" onClick={() => saveView(false)} style={{ fontSize: '14px' }}>
              Save
            </Button>
          )}
          <Button
            key="saveAsNew"
            variant="primary"
            onClick={() => {
              saveView(true);
            }}
            style={{ fontSize: '14px' }}
          >
            Save as New
          </Button>
        </div>
      </Row>
    </BasicModal>
  );
})``;

export default SaveModal;
