import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dc from 'dc';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import crossfilter from 'crossfilter2';
import moment from 'moment';
import _ from 'lodash';
import { LAYERS, clearForecasts, getForecastLayer } from 'assets/modules/forecast';
import { UNITS_ENUM, UNITS_REPRESENTATION } from 'common/Constants';
import { forecastRisks, getUnits } from 'assets/modules/utils';
import { Dropdown, Menu, Space, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const ForecastTab = styled(({ assetId, risk, className }) => {
  const riskChartRef = useRef(null);
  const riskChart = useRef(null);
  const mainChartRef = useRef(null);
  const mainChart = useRef(null);
  const secondaryChartRef = useRef(null);
  const secondaryChart = useRef(null);
  const crossfilterCtx = useRef(null);
  const mainLeftLayers = Object.entries(LAYERS)
    .filter(v => v[1].chart === 'main')
    .map(v => ({
      key: v[0],
      label: v[1].description,
    }));
  const mainRightLayers = mainLeftLayers.slice(1);
  const secondaryLayers = Object.entries(LAYERS)
    .filter(v => v[1].chart === 'secondary')
    .map(v => ({
      key: v[0],
      label: v[1].description,
    }));

  const [timeExtent, setTimeExtend] = useState(null);
  const [mainLeftLayerSelected, setMainLeftLayerSelected] = useState(mainLeftLayers[0].key);
  const [mainRightLayerSelected, setMainRightLayerSelected] = useState(mainRightLayers[0].key);
  const [secondaryLayerSelected, setSecondaryLayerSelected] = useState(secondaryLayers[0].key);

  const unitsSystemPref = useSelector(state =>
    state.account?.account?.prefs?.units?.default === 'imperial' ? 'i' : 'm',
  );

  const temperature = useSelector(state => state.forecasts[LAYERS.TEMPERATURE.gridId]);
  const windGust = useSelector(state => state.forecasts[LAYERS.WIND_GUST.gridId]);
  const windSpeed = useSelector(state => state.forecasts[LAYERS.WIND_SPEED.gridId]);
  const hourlyPrecip = useSelector(state => state.forecasts[LAYERS.HOURLY_PRECIP.gridId]);
  const hourlySnowfall = useSelector(state => state.forecasts[LAYERS.HOURLY_SNOW.gridId]);
  const forecastedRisks = useMemo(() => (timeExtent ? forecastRisks(risk || {}, timeExtent) : null), [
    risk,
    timeExtent,
  ]);

  const dispatch = useDispatch();

  // group reducers
  const reduceAdd = layer => (p, v) => {
    const newV = { value: v[layer.key]?.value ?? NaN, units: v[layer.key]?.units ?? UNITS_ENUM.default };
    if (layer.key === LAYERS.RISKS.key) newV.phenomena = v.phenomena;
    return _.extend(p, newV);
  };
  const reduceRemove = layer => (p, v) => p;
  const reduceInitial = layer => () => {
    const newV = { value: layer.key === LAYERS.RISKS.key ? 1 : NaN, units: UNITS_ENUM.default };
    if (layer.key === LAYERS.RISKS.key) newV.phenomena = [];
    return newV;
  };

  /**
   * @returns {crossfilter.Crossfilter}
   */
  const getndx = useCallback(() => {
    return crossfilterCtx.current ?? crossfilter([]);
  }, [crossfilterCtx]);

  useEffect(() => {
    // fetch required initial datas
    dispatch(getForecastLayer(assetId, LAYERS.TEMPERATURE.gridId));
    dispatch(getForecastLayer(assetId, LAYERS.WIND_GUST.gridId));
    dispatch(getForecastLayer(assetId, LAYERS.WIND_SPEED.gridId));
    dispatch(getForecastLayer(assetId, LAYERS.TOTAL_PRECIP.gridId));
    dispatch(getForecastLayer(assetId, LAYERS.TOTAL_SNOW.gridId));
    return () => {
      dispatch(clearForecasts());
    };
  }, [dispatch, assetId]);

  useEffect(() => {
    if (!crossfilterCtx.current) {
      // Create crossfilter with time dimension only
      const crossfilterData = [];
      const start = moment().startOf('hour');
      const stop = moment()
        .endOf('day')
        .add(9, 'days');
      for (let i = moment(start); i.isBefore(moment(stop).add(10, 'minutes')); i.add(1, 'hours')) {
        crossfilterData.push({ dt: i.unix(), date: i.toDate(), test: '123' });
      }
      crossfilterCtx.current = crossfilter(crossfilterData);
      setTimeExtend(d3.extent(crossfilterData, d => d.date));
    }
  }, []);

  const extendRecords = useCallback(
    (config, data) => {
      const timeDimension = getndx().dimension(d => d.dt);
      let total = 0;
      data.forEach(d => {
        timeDimension.filterExact(moment(d[0]).unix());
        const record = timeDimension.top(1)[0];
        if (record) {
          record[config.key] = getUnits(d[1], config.defaultUnit, unitsSystemPref);
          if (config.accumulated) {
            total = total + record[config.key].value;
            record[config.key].value = total;
          }
          if (config.key === 'risk') record.phenomena = d[2];
          getndx().remove();
          getndx().add([record]);
        }
        timeDimension.filter(null);
      });
    },
    [getndx, unitsSystemPref],
  );

  useEffect(() => {
    const record = getndx()
      .dimension(d => d.dt)
      .top(1)[0];
    const datas = {
      [LAYERS.TEMPERATURE.key]: temperature?.data ?? [],
      [LAYERS.WIND_GUST.key]: windGust?.data ?? [],
      [LAYERS.WIND_SPEED.key]: windSpeed?.data ?? [],
      [LAYERS.TOTAL_PRECIP.key]: hourlyPrecip?.data ?? [],
      [LAYERS.HOURLY_PRECIP.key]: hourlyPrecip?.data ?? [],
      [LAYERS.TOTAL_SNOW.key]: hourlySnowfall?.data ?? [],
      [LAYERS.HOURLY_SNOW.key]: hourlySnowfall?.data ?? [],
      [LAYERS.RISKS.key]: forecastedRisks || [],
    };
    Object.values(LAYERS).forEach(layer => {
      if (datas[layer.key] && !_.has(record, layer.key) && datas[layer.key].length) {
        extendRecords(layer, datas[layer.key]);
      }
    });

    dc.redrawAll();
  }, [temperature, getndx, extendRecords, forecastedRisks, hourlyPrecip, hourlySnowfall, windGust, windSpeed]);

  useEffect(() => {
    if (timeExtent) {
      const timeDimension = getndx().dimension(d => d.date);
      if (riskChartRef.current) {
        riskChart.current = dc
          .barChart(riskChartRef.current)
          .height(80)
          .width(null)
          .margins({ top: 7, right: 60, bottom: 25, left: 60 })
          .dimension(timeDimension)
          .group(
            timeDimension
              .group()
              .reduce(reduceAdd(LAYERS.RISKS), reduceRemove(LAYERS.RISKS), reduceInitial(LAYERS.RISKS)),
            LAYERS.RISKS.key,
          )
          .x(d3.scaleTime().domain(timeExtent))
          .y(d3.scaleLinear().domain([0, 25]))
          .round(d3.timeHour.round)
          .xUnits(d3.timeHours)
          .ordinalColors(['#8A96B2'])
          .elasticY(false)
          .yAxisPadding('50%')
          .gap(0)
          .transitionDuration(150)
          .renderTitle(false)
          .renderHorizontalGridLines(true)
          .renderVerticalGridLines(true)
          .colors(
            d3
              .scaleOrdinal()
              .domain(['high', 'medium', 'low'])
              .range(['#D32100', '#db992f', '#108904']),
          )
          .colorAccessor(d => (d.value.value >= 17 ? 'high' : d.value.value >= 9 ? 'medium' : 'low'))
          .brushOn(false);
        riskChart.current.valueAccessor(p => p.value.value);
        riskChart.current.yAxis().ticks(0);
        riskChart.current
          .xAxis()
          .tickFormat(d => (d3.timeHour(d) < d ? '' : d3.timeDay(d) < d ? 'Today' : d3.timeFormat('%d %b')(d)));

        const tooltip = d3Tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html(d => {
            const value = d.target.__data__.data.value;
            const key = d.target.__data__.data.key;
            return `
              <div class="chart-tip">
                <div class="chart-tip-top">${moment(key).format('ddd hA')}</div>
                <div class="risk-tip-score">
                  Risk ${value.value} (${value.value >= 17 ? 'high' : value.value >= 9 ? 'medium' : 'low'})
                </div>
                <div class="risk-tip-threats"> ${value.phenomena} </div>
              </div>
            `;
          });
        riskChart.current.on('renderlet', chart => {
          chart
            .selectAll('rect')
            .call(tooltip)
            .on('mouseover', tooltip.show)
            .on('mouseout', tooltip.hide);
        });
      }

      if (mainChartRef.current) {
        mainChart.current = dc
          .compositeChart(mainChartRef.current)
          .height(200)
          .width(null)
          .brushOn(false)
          .shareTitle(false)
          .margins({ top: 5, right: 60, bottom: 25, left: 60 })
          .dimension(timeDimension)
          .x(d3.scaleTime().domain(timeExtent))
          .xUnits(d3.timeHours)
          .renderHorizontalGridLines(true)
          .renderVerticalGridLines(true)
          .elasticY(true)
          .yAxisPadding('10%')
          .transitionDuration(150)
          .renderTitle(false);

        const foregroundC = dc
          .lineChart(mainChart.current)
          .dimension(timeDimension)
          .interpolate('basis')
          .group(
            timeDimension
              .group()
              .reduce(
                reduceAdd(LAYERS[mainLeftLayerSelected]),
                reduceRemove(LAYERS[mainLeftLayerSelected]),
                reduceInitial(LAYERS[mainLeftLayerSelected]),
              ),
            LAYERS[mainLeftLayerSelected].key,
          )
          .ordinalColors(['#3DA1B3'])
          .useRightYAxis(false)
          .renderTitle(false)
          .valueAccessor(d => d.value.value);
        const backgroundC = dc
          .lineChart(mainChart.current)
          .dimension(timeDimension)
          .interpolate('basis')
          .group(
            timeDimension
              .group()
              .reduce(
                reduceAdd(LAYERS[mainRightLayerSelected]),
                reduceRemove(LAYERS[mainRightLayerSelected]),
                reduceInitial(LAYERS[mainRightLayerSelected]),
              ),
            LAYERS[mainRightLayerSelected].key,
          )
          .ordinalColors(['#0D4F78'])
          .useRightYAxis(true)
          .renderTitle(false)
          .valueAccessor(d => d.value.value);
        mainChart.current.compose([backgroundC, foregroundC]);
        mainChart.current.yAxis().ticks(5);
        mainChart.current.yAxis().tickFormat(d => {
          const unit = getUnits(d, LAYERS[mainLeftLayerSelected].defaultUnit, unitsSystemPref);
          return d + UNITS_REPRESENTATION[unit.units];
        });
        mainChart.current.rightYAxis().ticks(5);
        mainChart.current.rightYAxis().tickFormat(d => {
          const unit = getUnits(d, LAYERS[mainRightLayerSelected].defaultUnit, unitsSystemPref);
          return d + UNITS_REPRESENTATION[unit.units];
        });
        mainChart.current
          .xAxis()
          .tickFormat(d => (d3.timeHour(d) < d ? '' : d3.timeDay(d) < d ? 'Today' : d3.timeFormat('%d %b')(d)));

        const mainTooltip = d3Tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html(d => {
            const value = d.target.__data__.data.value;
            const key = d.target.__data__.data.key;
            return `
              <div class="chart-tip">
                <div class="chart-tip-top">${moment(key).format('ddd hA')}</div>
                ${value.value + UNITS_REPRESENTATION[value.units]}
              </div>
            `;
          });
        mainChart.current.on('renderlet', chart => {
          chart
            .selectAll('circle.dot')
            .call(mainTooltip)
            .on('mouseover', mainTooltip.show)
            .on('mouseout', e => {
              mainTooltip.hide(e);
              d3.select(e.target)
                .style('fill-opacity', 1e-6)
                .style('stroke-opacity', 1e-6);
              d3.selectAll('path.xRef').style('display', 'none');
              d3.selectAll('path.yRef').style('display', 'none');
            });
        });
      }
      if (secondaryChartRef.current) {
        secondaryChart.current = dc
          .barChart(secondaryChartRef.current)
          .height(80)
          .width(null)
          .brushOn(false)
          .margins({ top: 7, right: 60, bottom: 25, left: 60 })
          .dimension(timeDimension)
          .group(
            timeDimension
              .group()
              .reduce(
                reduceAdd(LAYERS[secondaryLayerSelected]),
                reduceRemove(LAYERS[secondaryLayerSelected]),
                reduceInitial(LAYERS[secondaryLayerSelected]),
              ),
            LAYERS[secondaryLayerSelected].key,
          )
          .x(d3.scaleTime().domain(timeExtent))
          .round(d3.timeHour.round)
          .xUnits(d3.timeHours)
          .ordinalColors(['rgba(3, 103, 168, 0.6)'])
          .elasticY(true)
          .yAxisPadding('50%')
          .gap(0)
          .transitionDuration(150)
          .renderTitle(false)
          .renderHorizontalGridLines(false)
          .renderVerticalGridLines(true)
          .valueAccessor(d => d.value.value);
        secondaryChart.current
          .xAxis()
          .tickFormat(d => (d3.timeHour(d) < d ? '' : d3.timeDay(d) < d ? 'Today' : d3.timeFormat('%d %b')(d)));
        secondaryChart.current.yAxis().ticks(3);
        secondaryChart.current.yAxis().tickFormat(d => {
          const unit = getUnits(d, LAYERS[secondaryLayerSelected].defaultUnit, unitsSystemPref);
          return d + UNITS_REPRESENTATION[unit.units];
        });
        const secondaryTooltip = d3Tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html(d => {
            const value = d.target.__data__.data.value;
            const key = d.target.__data__.data.key;
            return `
              <div class="chart-tip">
                <div class="chart-tip-top">${moment(key).format('ddd hA')}</div>
                ${value.value + UNITS_REPRESENTATION[value.units]}
              </div>
            `;
          });
        secondaryChart.current.on('renderlet', chart => {
          chart
            .selectAll('rect')
            .call(secondaryTooltip)
            .on('mouseover', secondaryTooltip.show)
            .on('mouseout', secondaryTooltip.hide);
        });
      }
      dc.renderAll();
    }
  }, [getndx, timeExtent, mainLeftLayerSelected, mainRightLayerSelected, secondaryLayerSelected, unitsSystemPref]);

  useEffect(() => {
    const listener = window.addEventListener('resize', () => dc.renderAll());
    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <div className={className}>
      <div id="risk-chart">
        <span className="dropdown chart left-select">
          <span className="dropdown-toggle" id="select-risk">
            Hourly Max Risk
          </span>
        </span>
        <div className="chart-svg-container" ref={riskChartRef}></div>
      </div>
      <div id="main-chart" className="{{metric}}">
        <Dropdown
          overlay={
            <Menu className="dropdown-menu">
              {mainLeftLayers?.map(l => {
                return (
                  <Menu.Item key={l.key} onClick={e => setMainLeftLayerSelected(e.key)}>
                    {l.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          trigger={['click']}
        >
          <Typography.Link>
            <Space>
              {LAYERS[mainLeftLayerSelected].description}
              <CaretDownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
        <Dropdown
          overlay={
            <Menu className="dropdown-menu">
              {mainRightLayers?.map(l => {
                return (
                  <Menu.Item key={l.key} onClick={e => setMainRightLayerSelected(e.key)}>
                    {l.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          trigger={['click']}
        >
          <Typography.Link style={{ float: 'right' }}>
            <Space>
              {LAYERS[mainRightLayerSelected].description}
              <CaretDownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
        <div className="chart-svg-container" ref={mainChartRef}></div>
      </div>
      <div id="secondary-chart" className="{{metric}}">
        <Dropdown
          overlay={
            <Menu className="dropdown-menu">
              {secondaryLayers?.map(l => {
                return (
                  <Menu.Item key={l.key} onClick={e => setSecondaryLayerSelected(e.key)}>
                    {l.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          trigger={['click']}
        >
          <Typography.Link>
            <Space>
              {LAYERS[secondaryLayerSelected].description}
              <CaretDownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
        <div className="chart-svg-container" ref={secondaryChartRef}></div>
      </div>
    </div>
  );
})`
  padding: 0;
  background-color: unset;
  .section-row-header {
    display: none;
  }
`;

export default ForecastTab;
