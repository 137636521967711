import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Layout, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { DetailViewContent } from '@luxe/components/src/Container';
import HeaderRow from './HeaderRow';
import { ItemDetailGroupsSection } from '@luxe/components/src/ItemDetail';
import { deleteAsset, getAsset, saveAsset, getAssetContacts } from 'admin/assets/modules/assets';
import { addAssetsToGroup, getAssetGroups, removeAssetsFromGroup } from 'admin/assetgroups/modules/assetgroups';
import { addAssetToCorp, getCorporates, removeAssetFromCorp } from 'admin/corporates/modules/corporates';
import { PreservedTabView } from '@luxe/components/src/Tabs';
import { getRiskModels } from 'admin/riskmodels/modules/riskmodels';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { getAllUsers } from 'admin/users/modules/users';
import AssetGeneralSection from 'admin/assets/containers/AssetDetailView/AssetGeneralSection';
import AssetLocationSection from 'admin/assets/containers/AssetDetailView/AssetLocationSection';
import AssetFeedbackSection from 'admin/assets/containers/AssetDetailView/AssetFeedbackSection';
import AssetInformationSection from 'admin/assets/containers/AssetDetailView/AssetInformationSection';
import { formatSelectOptions } from 'common/utils';
import useQuery from 'utils/hooks/useQuery';
import { useHistory } from 'react-router-dom';

const AssetDetailView = () => {
  const { space } = useTheme();
  const { id: assetId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const asset = useSelector(store => store.assets)?.Asset;
  const assetStoreID = asset?.id;
  const assetGroups = useSelector(store => store.assetGroups)?.assetGroups || [];
  const corporates = useSelector(store => store.corporates).corporates;
  const hasContacts = assetId === assetStoreID && asset?.contacts;
  const viewReady = asset && assetId === assetStoreID && hasContacts;
  const activeTab = useQuery().get('activeTab');
  const source = useQuery().get('source');
  const history = useHistory();

  useEffect(() => {
    if (assetId && assetId !== assetStoreID) {
      dispatch(getAsset({ id: assetId }));
    } else if (!hasContacts) {
      dispatch(getAssetContacts(assetId));
    }
  }, [dispatch, assetId, assetStoreID, hasContacts]);

  useEffect(() => {
    dispatch(getCorporates());
    dispatch(getAssetGroups());
    dispatch(getRiskModels());
    dispatch(getRiskProfiles());
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (viewReady) {
      const initialValues = {
        name: asset.name,
      };
      form.setFieldsValue(initialValues);
    }
  }, [asset, viewReady, form]);

  if (!viewReady) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const getQueryParams = activeTab => {
    if (source) {
      return `?source=${source}&activeTab=${activeTab}`;
    }
    return `?activeTab=${activeTab}`;
  };

  const tabs = [
    {
      title: <Link to={{ search: getQueryParams('settings') }}>Settings</Link>,
      content: <AssetGeneralSection asset={asset} form={form} />,
      key: 'settings',
    },
    {
      title: <Link to={{ search: getQueryParams('assetsAndGroups') }}>Facilities & Groups</Link>,
      content: (
        <ItemDetailGroupsSection
          item={asset}
          itemName={'asset'}
          groups={assetGroups}
          deleteFromGroupFunction={removeAssetsFromGroup}
          addToGroupFunction={addAssetsToGroup}
        />
      ),
      key: 'assetsAndGroups',
    },
    {
      title: <Link to={{ search: getQueryParams('corporations') }}>Corporations</Link>,
      content: (
        <ItemDetailGroupsSection
          item={asset}
          itemName={'corporation'}
          groups={corporates}
          title={'Corporations'}
          deleteFromGroupFunction={removeAssetFromCorp}
          addToGroupFunction={addAssetToCorp}
        />
      ),
      key: 'corporations',
    },
    {
      title: <Link to={{ search: getQueryParams('location') }}>Location</Link>,
      content: <AssetLocationSection asset={asset} form={form} />,
      key: 'location',
    },
    {
      title: <Link to={{ search: getQueryParams('feedback') }}>Feedback</Link>,
      content: <AssetFeedbackSection asset={asset} form={form} />,
      key: 'feedback',
    },
    {
      title: <Link to={{ search: getQueryParams('moreInformation') }}>More Information</Link>,
      content: <AssetInformationSection asset={asset} form={form} />,
      key: 'moreInformation',
    },
  ];

  function formatContacts(newContacts, existingContacts) {
    if (newContacts === undefined) {
      // Form section wasn't loaded and key was not found in form values
      return formatSelectOptions(existingContacts);
    } else if (newContacts === null) {
      // Input was cleared out and react-select returned null
      return [];
    } else {
      return newContacts;
    }
  }

  function editAsset() {
    form.validateFields().then(values => {
      const providers = formatContacts(
        values['feedback_providers'],
        asset.contacts.filter(contact => contact.type === 'feedback_provider').map(c => c.user),
      );
      const feedbackProviders = providers.map(user => ({
        user_id: user.value,
        type: 'feedback_provider',
      }));
      const receivers = formatContacts(
        values['feedback_receivers'],
        asset.contacts.filter(contact => contact.type === 'feedback_receiver').map(c => c.user),
      );
      const feedbackReceivers = receivers.map(user => ({
        user_id: user.value,
        type: 'feedback_receiver',
      }));

      const primaries = formatContacts(
        values['primary_contacts'],
        asset.contacts.filter(contact => contact.type === 'primary_contact').map(c => c.user),
      );

      const primaryContacts = primaries.map(contact => ({
        user_id: contact.value,
        type: 'primary_contact',
      }));
      const payload = {
        id: asset.id,
        name: values.name,
        org_type: values.type?.value ?? asset.org_type,
        profile_ids: values.hasOwnProperty('risk_profiles')
          ? values.risk_profiles?.map(profile => profile.value) ?? []
          : asset.profile_ids,
        risk_model_ids: values.hasOwnProperty('risk_models')
          ? values.risk_models?.map(model => model.value) ?? []
          : asset.risk_model_ids,
        location: asset.location,
        contacts: primaryContacts.concat(feedbackReceivers).concat(feedbackProviders),
        meta: asset.meta,
        corporation_id: asset.corporation ? asset.corporation.id : null,
        commodities: values?.commodity,
      };

      if (values['meta_fields']) {
        const metaPayload = {};
        values['meta_fields'].forEach(meta => {
          metaPayload[meta.key] = meta.value;
        });
        payload.meta = metaPayload;
      }

      dispatch(saveAsset(payload));
    });
  }

  return (
    <DetailViewContent key={`asset-detail-${assetId}`} style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <div style={{ marginTop: '0' }}>
        <Form form={form} name="asset_detail_form" preserve={false}>
          <HeaderRow
            form={form}
            item={asset}
            itemName={'asset'}
            deleteFunction={props => dispatch(deleteAsset(props))}
            editFunction={editAsset}
            cancelFunction={() =>
              history.push(`/admin/${source === 'corporations' ? 'corporations/' : 'asset-groups/'}`)
            }
          />
          <PreservedTabView tabs={tabs} activeTab={activeTab} />
        </Form>
      </div>
    </DetailViewContent>
  );
};

export default AssetDetailView;
