import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Alert as aAlert } from 'antd';

const Alert = styled(aAlert)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  font-family: ${props => props.theme.fonts.body};
  border-radius: 4px;
  margin: 15px auto;
  background-color: ${props => props.theme.colors.secondary};
  border: ${props => props.theme.colors.secondary};
  .ant-alert-message {
     button > span {
      color: ${props => props.theme.colors.primary} !important;
      text-decoration: underline;
      &:visited {
        color: ${props => props.theme.colors.primary} !important;
        text-decoration: underline;
      }
      &:hover {
        color: ${props => props.theme.colors.primaryHover} !important;
        text-decoration: underline;
      }
    }
  }
  .ant-alert-content {
    padding-top: 5px;
  }
`;

export { Alert };
