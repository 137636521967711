const getRuleIndex = (rules, ruleId) => {
  return rules.findIndex(rule => rule.id === ruleId);
};

export const getUpdatedRiskProfiles = (riskProfiles, riskProfile) => {
  const riskProfilesCopy = [...riskProfiles];
  const foundIndex = riskProfiles.findIndex(currentRiskProfile => currentRiskProfile.id === riskProfile.id);
  if (foundIndex === -1) {
    riskProfilesCopy.push(riskProfile);
  } else {
    riskProfilesCopy[foundIndex] = riskProfile;
  }
  return riskProfilesCopy;
};

export const deleteRuleFromProfile = (profile, ruleId) => {
  const ruleIndex = getRuleIndex(profile.rules, ruleId);
  profile.rules.splice(ruleIndex, 1);
  return profile;
};

export const updateRiskProfileRuleFromState = (profile, payload) => {
  const ruleIndex = getRuleIndex(profile.rules, payload.id);
  profile.rules[ruleIndex] = payload;
  return profile;
};

export const insertRiskProfileRule = (profile, payload) => {
  profile.rules.unshift(payload);
  return profile;
};

export const convertFarenheitToCelsius = temperature => {
  return ((parseFloat(temperature) - 32) / 1.8).toFixed();
};

export const convertCelsiusToFarenheit = temperature => {
  return (parseFloat(temperature) * (9 / 5) + 32).toFixed();
};

export const convertInchesToMillimeters = distance => {
  return (parseFloat(distance) * 25.4).toFixed();
};

export const convertMillimeterstoInches = distance => {
  return (parseFloat(distance) / 25.4).toFixed(2);
};

export const mphToKph = speed => {
  return (parseFloat(speed) * 1.60934).toFixed();
};

export const kphToMph = speed => {
  return (parseFloat(speed) / 1.60934).toFixed(1);
};

export const convertValue = (value, from, to) => {
  if (from === 'degC' && to === 'degF') {
    return convertCelsiusToFarenheit(value);
  } else if (from === 'degF' && to === 'degC') {
    return convertFarenheitToCelsius(value);
  } else if (from === 'inches' && to === 'mm') {
    return convertInchesToMillimeters(value);
  } else if (from === 'mm' && to === 'inches') {
    return convertMillimeterstoInches(value);
  } else if (from === 'kph' && to === 'mph') {
    return kphToMph(value);
  } else if (from === 'mph' && to === 'kph') {
    return mphToKph(value);
  }
};
