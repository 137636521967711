import React, { useState } from 'react';
import { Tabs } from 'antd';
import { TabContainer } from '../styles';

export const AssetTabView = ({ assetTabs, currentTab, showMinimal, handleCurrentTabChange }) => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState(currentTab || assetTabs[0].title);

  const onTabChange = key => {
    const tab = assetTabs.find(tab => tab.title === key);
    if (tab) {
      setActiveTab(tab.title);
      handleCurrentTabChange(tab.title);
    }
  };

  return (
    <>
      <TabContainer onChange={onTabChange} activeKey={activeTab}>
        {assetTabs.map(tab => {
          return <TabPane tab={tab.title} key={tab.title} />;
        })}
      </TabContainer>
    </>
  );
};
