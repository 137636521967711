import React from 'react';
import PropTypes from 'prop-types';
import getFromConfig from 'constants/config';
import { Link } from 'react-router-dom';

export default class AssetFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const baseUrl = getFromConfig('REACT_APP_RISKBOX_BASE_URL');
    const url = `assets/${this.props.value.id}/`;
    return this.props.inAppNavigation ? (
      <Link to={`/${url}`}>{this.props.value.name}</Link>
    ) : (
      <a href={`${baseUrl}${url}`}>{this.props.value.name}</a>
    );
  }
}
