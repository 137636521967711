import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/riskprofiles/modules/riskprofiles';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { getCatalogRules } from 'admin/riskprofiles/modules/catalogRules';
import { push } from 'connected-react-router';

export const createRiskProfileRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.CREATE_RISK_PROFILE_SUCCESS),
    map(action => push(`/admin/risk-profiles/${action.payload.id}/`)),
  );
};

export const getProfilesBeforeDetailEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === TYPES.GET_RISK_PROFILE_DETAIL_SUCCESS) {
        return state$.value.riskProfiles.status === undefined;
      }
      return false;
    }),
    map(() => getRiskProfiles()),
  );
};

export const getCatalogBeforeDetailEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === TYPES.EDIT_RISK_PROFILE_RULE) {
        return state$.value.catalogRules.status === undefined;
      }
      return false;
    }),
    map(() => getCatalogRules()),
  );
};

export const deleteRiskProfileRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_RISK_PROFILES_SUCCESS),
    map(() => push(`/admin/risk-profiles/`)),
  );
};

const epics = {
  getProfilesBeforeDetailEpic,
  getCatalogBeforeDetailEpic,
  createRiskProfileRedirectEpic,
  deleteRiskProfileRedirectEpic,
};

export default epics;
