import axios from 'axiosInstance';
import _ from 'lodash';

import { parseOptions } from './../utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_OPTIONS: 'GET_OPTIONS',
  GET_OPTIONS_ERROR: 'GET_OPTIONS_ERROR',
  GET_OPTIONS_SUCCESS: 'GET_OPTIONS_SUCCESS',
  SHOW_FILTERS: 'SHOW_FILTERS',
  GET_ASYNC_OPTIONS: 'GET_ASYNC_OPTIONS',
  GET_ASYNC_OPTIONS_ERROR: 'GET_ASYNC_OPTIONS_ERROR',
  GET_ASYNC_OPTIONS_SUCCESS: 'GET_ASYNC_OPTIONS_SUCCESS',
  CLEAR_ASYNC_OPTIONS: 'CLEAR_ASYNC_OPTIONS',
};

export const INITIAL_STATE = {};

export const getOptions = (config, value) => {
  config.name = config.field ? config.field.name : config.name;
  return callApi({
    types: {
      pending: TYPES.GET_OPTIONS,
      success: TYPES.GET_OPTIONS_SUCCESS,
      error: TYPES.GET_OPTIONS_ERROR,
    },
    params: { config, value },
    request: () => axios.get(`${config.url}${value ? value : ''}`),
  });
};

export const getAsyncOptions = (config, query = '', skip = 0, limit = 10) => {
  config.name = config.field?.name ?? config.name;
  let searchParams = new URLSearchParams(),
    reqBody;
  if (!config.isPOST) {
    searchParams = new URLSearchParams(_.omitBy(config.params ?? {}, _.isEmpty));
    query && searchParams.set('q', query);
    searchParams.set('skip', skip);
    searchParams.set('limit', limit);
  } else {
    reqBody = config.getRequestBody ? config.getRequestBody(query, skip, limit) : {};
  }
  return callApi({
    types: {
      pending: TYPES.GET_ASYNC_OPTIONS,
      success: TYPES.GET_ASYNC_OPTIONS_SUCCESS,
      error: TYPES.GET_ASYNC_OPTIONS_ERROR,
    },
    params: { config, query, skip, limit },
    request: () => axios[config.isPOST ? 'post' : 'get'](`${config.url}?${searchParams.toString()}`, reqBody),
  });
};

export const clearAsyncOptions = name => {
  return {
    type: TYPES.CLEAR_ASYNC_OPTIONS,
    params: { config: { name } },
  };
};

const options = (state = INITIAL_STATE, action) => {
  const { type } = action;
  const config = action?.params?.config || action?.payload?.config || {};
  const name = config.name || null;

  const filterState = state[name] || {};
  const { initialized = false } = filterState;

  switch (type) {
    case TYPES.GET_OPTIONS:
      return {
        ...state,
        [name]: {
          ...filterState,
          loading: true,
          initialized,
        },
      };
    case TYPES.GET_OPTIONS_SUCCESS:
      return {
        ...state,
        [name]: {
          ...filterState,
          loading: false,
          options: parseOptions(action.params.config, action.payload),
          initialized: true,
        },
      };
    case TYPES.GET_ASYNC_OPTIONS:
      if (filterState.query !== action.params.query) {
        filterState.query = action.params.query;
        filterState.options = (filterState?.options ?? []).filter(v => config.selectedOptions.includes(v.value));
        filterState.hasNext = true;
      }
      return {
        ...state,
        [name]: {
          ...filterState,
          loading: true,
          initialized,
        },
      };
    case TYPES.GET_ASYNC_OPTIONS_SUCCESS:
      if (filterState.query !== action.params.query) return state;
      filterState.options.push(...parseOptions(config, action.payload));
      filterState.options = _.uniqBy(filterState.options, v => v[config.uniqBy ?? 'value']);
      if (!config.params?.ids?.length && !action.payload.length) {
        filterState.hasNext = false;
      }
      return {
        ...state,
        [name]: {
          ...filterState,
          loading: false,
          initialized: true,
        },
      };
    case TYPES.CLEAR_ASYNC_OPTIONS:
      const newState = { ...state };
      delete newState[name];
      return {
        ...newState,
      };
    default:
      return state;
  }
};

export default options;
