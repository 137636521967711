import { REQUESTED_DATA_STATUS } from 'admin/constants';
import axios from 'axiosInstance';
import { callApi } from 'utils';

export const INITIAL_STATE = {
  status: undefined,
  regions: [],
  assets: [],
  assetTypes: [],
  error: undefined,
  params: undefined,
  region: null,
};

export const TYPES = {
  GET_REGIONS_PENDING: 'GET_REGIONS_PENDING',
  GET_REGIONS_ERROR: 'GET_REGIONS_ERROR',
  GET_REGIONS_SUCCESS: 'GET_REGIONS_SUCCESS',

  GET_REGION_PENDING: 'GET_REGION_PENDING',
  GET_REGION_ERROR: 'GET_REGION_ERROR',
  GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',

  GET_REGION_SHIPMENTS_PENDING: 'GET_REGION_SHIPMENTS_PENDING',
  GET_REGION_SHIPMENTS_ERROR: 'GET_REGION_SHIPMENTS_ERROR',
  GET_REGION_SHIPMENTS_SUCCESS: 'GET_REGION_SHIPMENTS_SUCCESS',

  GET_REGION_SINGLE_SHIPMENT_PENDING: 'GET_REGION_SINGLE_SHIPMENT_PENDING',
  GET_REGION_SINGLE_SHIPMENT_ERROR: 'GET_REGION_SINGLE_SHIPMENT_ERROR',
  GET_REGION_SINGLE_SHIPMENT_SUCCESS: 'GET_REGION_SINGLE_SHIPMENT_SUCCESS',

  GET_REGION_ASSETS_PENDING: 'GET_REGION_ASSETS_PENDING',
  GET_REGION_ASSETS_ERROR: 'GET_REGION_ASSETS_ERROR',
  GET_REGION_ASSETS_SUCCESS: 'GET_REGION_ASSETS_SUCCESS',

  GET_REGION_STATIC_ASSETS_PENDING: 'GET_REGION_STATIC_ASSETS_PENDING',
  GET_REGION_STATIC_ASSETS_ERROR: 'GET_REGION_STATIC_ASSETS_ERROR',
  GET_REGION_STATIC_ASSETS_SUCCESS: 'GET_REGION_STATIC_ASSETS_SUCCESS',

  GET_REGION_ASSETS_TYPES_PENDING: 'GET_REGION_ASSETS_TYPES_PENDING',
  GET_REGION_ASSETS_TYPES_ERROR: 'GET_REGION_ASSETS_TYPES_ERROR',
  GET_REGION_ASSETS_TYPES_SUCCESS: 'GET_REGION_ASSETS_TYPES_SUCCESS',
};

export const getRegions = params =>
  callApi({
    types: {
      pending: TYPES.GET_REGIONS_PENDING,
      success: TYPES.GET_REGIONS_SUCCESS,
      error: TYPES.GET_REGIONS_ERROR,
    },
    params: params,
    request: () => axios.get(`regions/`),
  });

export const getRegion = id =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_PENDING,
      success: TYPES.GET_REGION_SUCCESS,
      error: TYPES.GET_REGION_ERROR,
    },
    params: id,
    request: () => axios.get(`regions/${id}/`),
  });

export const getRegionAssets = data =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_ASSETS_PENDING,
      success: TYPES.GET_REGION_ASSETS_SUCCESS,
      error: TYPES.GET_REGION_ASSETS_ERROR,
    },
    params: data,
    request: () => axios.post(`assets/query/`, data),
  });

export const postRegionStaticAssets = data =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_STATIC_ASSETS_PENDING,
      success: TYPES.GET_REGION_STATIC_ASSETS_SUCCESS,
      error: TYPES.GET_REGION_STATIC_ASSETS_ERROR,
    },
    params: data,
    request: () => axios.post(`assets/query/`, data),
  });

export const postRegionShipments = data =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_SHIPMENTS_PENDING,
      success: TYPES.GET_REGION_SHIPMENTS_SUCCESS,
      error: TYPES.GET_REGION_SHIPMENTS_ERROR,
    },
    params: data,
    request: () => axios.post(`shipments/query/`, data),
  });

export const getSingleRegionShipment = id =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_SINGLE_SHIPMENT_PENDING,
      success: TYPES.GET_REGION_SINGLE_SHIPMENT_ERROR,
      error: TYPES.GET_REGION_SINGLE_SHIPMENT_SUCCESS,
    },
    params: id,
    request: () => axios.get(`shipments/${id}/`),
  });

export const getRegionTypes = data =>
  callApi({
    types: {
      pending: TYPES.GET_REGION_ASSETS_TYPES_PENDING,
      success: TYPES.GET_REGION_ASSETS_TYPES_SUCCESS,
      error: TYPES.GET_REGION_ASSETS_TYPES_ERROR,
    },
    params: data,
    request: () => axios.post(`assets/query/`, data),
  });

export const regionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_REGIONS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        regions: [],
        params: action.payload,
      };
    case TYPES.GET_REGIONS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGIONS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        regions: action.payload.features,
        error: undefined,
      };
    case TYPES.GET_REGION_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_REGION_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_SUCCESS:
      const { properties, ...regionBase } = action.payload;
      const region = {
        ...regionBase,
        ...properties,
      };
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        region,
        error: undefined,
      };
    case TYPES.GET_REGION_ASSETS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_REGION_ASSETS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_ASSETS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assets: action.payload,
        error: undefined,
      };
    case TYPES.GET_REGION_SHIPMENTS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_REGION_SHIPMENTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_SHIPMENTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        shipments: action.payload.results,
        error: undefined,
      };

    case TYPES.GET_REGION_SINGLE_SHIPMENT_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_REGION_SINGLE_SHIPMENT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_SINGLE_SHIPMENT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        singleShipment: action.payload,
        error: undefined,
      };

    case TYPES.GET_REGION_STATIC_ASSETS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_REGION_STATIC_ASSETS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_STATIC_ASSETS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetsCount: action.payload.results.length,
        error: undefined,
      };
    case TYPES.GET_REGION_ASSETS_TYPES_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        assetTypes: [],
        params: action.payload,
      };
    case TYPES.GET_REGION_ASSETS_TYPES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_REGION_ASSETS_TYPES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        assetTypes: action.payload,
        error: undefined,
      };
    default:
      return state;
  }
};
