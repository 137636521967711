export const getFilterColumns = (meta, includes) => {
  const filters = meta.allowed.filters
    .filter(filter => {
      return includes !== undefined ? includes.map(x => (x.name ? x.name : x)).includes(filter.name) : true;
    })
    .map(filter => {
      const filterOverrides = includes.find(x => {
        return x.name && x.name === filter.name ? true : false;
      });
      return {
        ...filter,
        ...filterOverrides,
      };
    });
  return filters;
};

export const getFormOptions = (columns, selectedColumnName) => {
  const columnOptions = columns.map(({ description: label, name: value }) => {
    return {
      label,
      value,
    };
  });

  const operators = {
    exact: { value: '==', label: 'Equals' },
    exclude: { value: '!=', label: 'Not Equals' },
    in: { value: 'in', label: 'In (any)' },
    in_all: { value: 'in_all', label: 'In (all)' },
    gt: { value: '>', label: `${selectedColumnName} === "last_update" ` ? 'After' : 'Greater Than' },
    gte: {
      value: '>=',
      label: `${selectedColumnName} === "last_update" ` ? 'After Including Today' : 'Greater Than or Equal',
    },
    lte: {
      value: '<=',
      label: `${selectedColumnName} === "last_update"` ? 'Before Including Today' : 'Less Than or Equal',
    },
    lt: { value: '<', label: `${selectedColumnName} === "last_update"` ? 'Before' : 'Less Than' },
    icontains: { value: 'icontains', label: 'Contains' },
    isnull: { value: 'isnull', label: 'Is Null' },
    between: { value: 'between', label: 'Between' },
  };
  const column = columns.find(x => x.name === selectedColumnName);
  const operatorOptions = column ? column.operators.filter(x => operators[x] !== undefined).map(x => operators[x]) : [];
  return [columnOptions, operatorOptions];
};
