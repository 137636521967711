import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

import { transformFamilies, getNestedFamily, updateNestedFamily, validateWorkflow, updateFamilyRisk } from './utils';

export const TYPES = {
  GET_EMAIL_ALERT: 'WORKFLOWS/GET_EMAIL_ALERT',
  GET_EMAIL_ALERT_ERROR: 'WORKFLOWS/GET_EMAIL_ALERT_ERROR',
  GET_EMAIL_ALERT_SUCCESS: 'WORKFLOWS/GET_EMAIL_ALERT_SUCCESS',
  GET_FAMILIES: 'WORKFLOWS/GET_FAMILIES',
  GET_FAMILIES_ERROR: 'WORKFLOWS/GET_FAMILIES_ERROR',
  GET_FAMILIES_SUCCESS: 'WORKFLOWS/GET_FAMILIES_SUCCESS',
  TOGGLE_FAMILY: 'WORKFLOWS/TOGGLE_FAMILY',
  UPDATE_FAMILY: 'WORKFLOWS/UPDATE_FAMILY',
  UPDATE_FAMILY_RISK: 'WORKFLOWS/UPDATE_FAMILY_RISK',

  GET_WORKFLOW: 'WORKFLOWS/GET_WORKFLOW',
  GET_WORKFLOW_ERROR: 'WORKFLOWS/GET_WORKFLOW_ERROR',
  GET_WORKFLOW_SUCCESS: 'WORKFLOWS/GET_WORKFLOW_SUCCESS',

  CREATE_WORKFLOW: 'WORKFLOWS/CREATE_WORKFLOW',
  CREATE_WORKFLOW_ERROR: 'WORKFLOWS/CREATE_WORKFLOW_ERROR',
  CREATE_WORKFLOW_SUCCESS: 'WORKFLOWS/CREATE_WORKFLOW_SUCCESS',

  SAVE_WORKFLOW: 'WORKFLOWS/SAVE_WORKFLOW',
  SAVE_WORKFLOW_ERROR: 'WORKFLOWS/SAVE_WORKFLOW_ERROR',
  SAVE_WORKFLOW_SUCCESS: 'WORKFLOWS/SAVE_WORKFLOW_SUCCESS',

  UPDATE_WORKFLOW: 'WORKFLOWS/UPDATE_WORKFLOW',
  VALIDATE_WORKFLOW: 'WORKFLOWS/VALIDATE_WORKFLOW',

  DELETE_WORKFLOWS: 'WORKFLOWS/DELETE_WORKFLOWS',
  DELETE_WORKFLOWS_ERROR: 'WORKFLOWS/DELETE_WORKFLOWS_ERROR',
  DELETE_WORKFLOWS_SUCCESS: 'WORKFLOWS/DELETE_WORKFLOWS_SUCCESS',

  CANCEL_WORKFLOW_CHANGES: 'WORKFLOWS/CANCEL_WORKFLOW_CHANGES',

  PAUSE_WORKFLOWS: 'WORKFLOWS/PAUSE_WORKFLOWS',
  PAUSE_WORKFLOWS_ERROR: 'WORKFLOWS/PAUSE_WORKFLOWS_ERROR',
  PAUSE_WORKFLOWS_SUCCESS: 'WORKFLOWS/PAUSE_WORKFLOWS_SUCCESS',

  RESUME_WORKFLOWS: 'WORKFLOWS/RESUME_WORKFLOWS',
  RESUME_WORKFLOWS_ERROR: 'WORKFLOWS/RESUME_WORKFLOWS_ERROR',
  RESUME_WORKFLOWS_SUCCESS: 'WORKFLOWS/RESUME_WORKFLOWS_SUCCESS',

  TRACK_WORKFLOW_CHANGE: 'TRACK_WORKFLOW_CHANGE',
};

export const INITIAL_STATE = {
  status: undefined,
  workflow: {
    families: [],
    valid: false,
    validationErrors: [],
  },
  error: undefined,
  params: undefined,
  isWorkFlowChanged: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.GET_FAMILIES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        workflow: {
          ...state.workflow,
          families: [],
        },
        params: action.payload,
      };
    case TYPES.GET_FAMILIES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_FAMILIES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        workflow: {
          ...state.workflow,
          families: transformFamilies(state.workflow, action.payload),
        },
        error: undefined,
      };
    case TYPES.TOGGLE_FAMILY:
      const family = getNestedFamily(state.workflow.families, action.payload.familyId);
      const selected = family && family.selected !== undefined ? !family.selected : true;
      return {
        ...state,
        workflow: {
          ...state.workflow,
          families: updateNestedFamily(state.workflow.families, action.payload.familyId, { selected }),
        },
      };
    case TYPES.UPDATE_FAMILY:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          families: updateNestedFamily(state.workflow.families, action.payload.familyId, action.payload.data),
        },
      };
    case TYPES.TRACK_WORKFLOW_CHANGE:
      return {
        ...state,
        isWorkFlowChanged: action.payload,
      };
    case TYPES.GET_WORKFLOW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_WORKFLOW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        // Removed for the chaining of requests of GET_FAMILIES
        // status: REQUESTED_DATA_STATUS.SUCCESS,
        workflow: {
          ...action.payload,
          data: {
            ...state.workflow.data,
            ...action.payload.data,
            commodities: action.payload.data.commodities ?? [],
          },
          families: state.workflow.families,
        },
        error: undefined,
      };

    case TYPES.CREATE_WORKFLOW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.CREATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.CREATE_WORKFLOW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_WORKFLOW:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          ...action.payload,
          data: {
            ...state.workflow.data,
            ...action.payload.data,
          },
        },
      };
    case TYPES.VALIDATE_WORKFLOW:
      return {
        ...state,
        workflow: validateWorkflow({
          ...state.workflow,
          data: {
            ...state.workflow.data,
          },
        }),
      };
    case TYPES.SAVE_WORKFLOW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_WORKFLOW_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.SAVE_WORKFLOW_ERROR:
      const validationErrors = Object.keys(action.payload).includes('errors') ? action.payload.errors : [];
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        workflow: {
          ...state.workflow,
          validationErrors,
        },
        error: action.payload,
      };

    case TYPES.DELETE_WORKFLOW:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.DELETE_WORKFLOW_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_WORKFLOWS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.DELETE_WORKFLOWS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CANCEL_WORKFLOW_CHANGES:
      return {
        ...state,
        workflow: INITIAL_STATE.workflow,
      };
    case TYPES.PAUSE_WORKFLOWS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.PAUSE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.PAUSE_WORKFLOWS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.RESUME_WORKFLOWS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.RESUME_WORKFLOWS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.RESUME_WORKFLOWS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_FAMILY_RISK:
      return {
        ...state,
        workflow: {
          ...state.workflow,
          families: updateFamilyRisk(state.workflow.families, action.payload.familyId, action.payload.data),
        },
      };
    default:
      return state;
  }
}

export const getFamilies = (data, reactive = true) => {
  return callApi({
    types: {
      pending: TYPES.GET_FAMILIES,
      success: TYPES.GET_FAMILIES_SUCCESS,
      error: TYPES.GET_FAMILIES_ERROR,
    },
    data: data,
    request: () => {
      const url = reactive ? `threats/families/?reactive=true` : `threats/families/`;
      return axios.get(url);
    },
  });
};

export const toggleFamily = (familyId, selected) => {
  return {
    type: TYPES.TOGGLE_FAMILY,
    payload: { familyId, selected },
  };
};

export const updateFamily = (familyId, data) => {
  return {
    type: TYPES.UPDATE_FAMILY,
    payload: { familyId, data },
  };
};

export const getWorkflow = params => {
  return callApi({
    types: {
      pending: TYPES.GET_WORKFLOW,
      success: TYPES.GET_WORKFLOW_SUCCESS,
      error: TYPES.GET_WORKFLOW_ERROR,
    },
    params: params,
    request: () => axios.get(`workflows/${params.id}/`),
  });
};

export const createWorkflow = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_WORKFLOW,
      success: TYPES.CREATE_WORKFLOW_SUCCESS,
      error: TYPES.CREATE_WORKFLOW_ERROR,
    },
    request: () => axios.post('workflows/', data),
    messages: {
      success: 'Workflow has been successfully created!',
      error: 'Workflow creation unsuccessful',
    },
  });

export const saveWorkflow = data =>
  callApi({
    types: {
      pending: TYPES.SAVE_WORKFLOW,
      success: TYPES.SAVE_WORKFLOW_SUCCESS,
      error: TYPES.SAVE_WORKFLOW_ERROR,
    },
    request: () => axios.put(`workflows/${data.id}/`, data),
    messages: {
      success: 'Workflow has been successfully updated!',
      error: 'Workflow update unsuccessful',
    },
  });

export const trackWorkFlowChange = val => ({
  type: TYPES.TRACK_WORKFLOW_CHANGE,
  payload: val,
});

export const updateWorkflow = data => ({
  type: TYPES.UPDATE_WORKFLOW,
  payload: { ...data },
});

export const validateWorkflowUpdate = () => ({
  type: TYPES.VALIDATE_WORKFLOW,
});

export const deleteWorkflow = params =>
  callApi({
    types: {
      pending: TYPES.DELETE_WORKFLOW,
      success: TYPES.DELETE_WORKFLOW_SUCCESS,
      error: TYPES.DELETE_WORKFLOW_ERROR,
    },
    request: () => axios.delete(`workflows/${params}/`),
    messages: {
      success: 'Workflow has been successfully deleted!',
      error: 'Workflow deletion unsuccessful',
    },
  });

export const deleteBulkWorkflows = data => {
  let payload = {
    action: 'delete',
    workflow_ids: [...data],
  };
  return callApi({
    types: {
      pending: TYPES.DELETE_WORKFLOWS,
      success: TYPES.DELETE_WORKFLOWS_SUCCESS,
      error: TYPES.DELETE_WORKFLOWS_ERROR,
    },
    request: () => axios.post(`workflows/bulk/`, payload),
    messages: {
      success: 'Workflows have been successfully deleted!',
      error: 'Workflow deletion unsuccessful',
    },
  });
};

export const pauseWorkflows = data => {
  let payload = {
    action: 'pause',
    workflow_ids: [...data],
  };
  return callApi({
    types: {
      pending: TYPES.PAUSE_WORKFLOWS,
      success: TYPES.PAUSE_WORKFLOWS_SUCCESS,
      error: TYPES.PAUSE_WORKFLOWS_ERROR,
    },
    request: () => axios.post(`workflows/bulk/`, payload),
    messages: {
      success: 'Workflows have been successfully paused!',
      error: 'Workflow pause unsuccessful',
    },
  });
};

export const resumeWorkflows = data => {
  let payload = {
    action: 'resume',
    workflow_ids: [...data],
  };
  return callApi({
    types: {
      pending: TYPES.RESUME_WORKFLOWS,
      success: TYPES.RESUME_WORKFLOWS_SUCCESS,
      error: TYPES.RESUME_WORKFLOWS_ERROR,
    },
    request: () => axios.post(`workflows/bulk/`, payload),
    messages: {
      success: 'Workflows have been successfully resumed!',
      error: 'Workflow resume unsuccessful',
    },
  });
};

export const cancelWorkflowChanges = () => ({
  type: TYPES.CANCEL_WORKFLOW_CHANGES,
});

export const updateFamilyRiskSelect = (familyId, data) => {
  return {
    type: TYPES.UPDATE_FAMILY_RISK,
    payload: { familyId, data },
  };
};
