import styled from '@emotion/styled';
import { space, layout, flexbox, position, size } from 'styled-system';
import { Breadcrumb as aBreadcrumb } from 'antd';
import { Layout as aLayout, Menu as aMenu } from 'antd';

const MainLayout = styled(aLayout)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  ${size}
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.white};
  min-height: 100vh;
  position: relative;
  .ant-layout{
    color: white;
    background-color: ${props => props.theme.colors.background};
  }
  .ant-layout .header {
    border-bottom: none;
    background-color: ${props => props.theme.colors.black1};
    .user-info {
      .ant-dropdown-trigger {
        background-color: ${props => props.theme.colors.black1};
        .ant-avatar.ant-avatar-circle.ant-avatar-icon {
          .anticon.anticon-user {
            margin-right: ${({ theme }) => theme.space.none};
          }
          margin: ${props => props.theme.space.small}px;
        }
      }
    }
  }

  .aside.sidebar {
    color: white;
    background-color: ${props => props.theme.colors.background};
    .logo {
      img {
        width: 140px;
        margin: ${props => props.theme.space.small}px;
      }
      background-color: ${props => props.theme.colors.background};
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .ant-layout-content{
    color: white;
    background-color: ${props => props.theme.colors.background};
    & .asset-scorecard-header {
      margin-bottom: 12px;
      & .ant-col span {
        margin-right: 8px;
      }
    }
  }

  .ant-menu-submenu-popup {
    background-color: blue;
  }
`;

const Menu = styled(aMenu)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
  font-family: ${props => props.theme.fonts.body};
  background-color: ${props => props.theme.colors.black1};
  border-bottom: none;
  color: ${props => props.theme.colors.white};

  ul.ant-menu {
    background-color: ${props => props.theme.colors.black1};
    color: ${props => props.theme.colors.white};
  }

  &.group-menu {
    border-right: none;
    font-size: 12px;

    li {
      margin: 0 auto !important;
    }
  }

  li {
    color: inherit;
  }

  &.group-menu li:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }


  li.ant-menu-item{
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.white};
    }
  }

  a {
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`;

const MenuItem = styled(aMenu.Item)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
`;

const SubMenu = styled(aMenu.SubMenu)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}

  &.ant-menu-submenu-popup {
    background-color: ${props => props.theme.colors.black1};
    border-radius: 0;
  }
`;

const SideMenu = styled(aLayout.Sider)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
  background-color: ${props => props.theme.colors.black1};
  border-color: ${props => props.theme.colors.black1};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.body};

  ul.ant-menu.ant-menu-inline, ul.ant-menu.ant-menu-inline-collapsed {
    border-right: 1px solid ${props => props.theme.colors.black1} !important;
  }

  &:active, .ant-menu-submenu-title:active {
    background-color: ${props => props.theme.colors.black1};
    border-color: ${props => props.theme.colors.black1};
    color: ${props => props.theme.colors.white};
  }

  .ant-menu-title-content {
    user-select: none;
  }

  .ant-menu-submenu-title {
    i {
      color: ${props => props.theme.colors.white};
    }


    &:hover {
      color: ${props => props.theme.colors.primaryHover};
      i {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: ${props => props.theme.colors.primary};
    }

    .ant-menu-item-selected, .ant-menu-item-selected a {
      color: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.black11} !important;
    }

    .ant-menu-item-selected::after {
      border-right: 3px solid ${props => props.theme.colors.primary};
    }
  }

  li.ant-menu-item {
    background-color: ${props => props.theme.colors.black11};
    margin: 0 !important;
  }

  .ant-layout-sider-trigger{
    background-color: ${props => props.theme.colors.black1};
    color: ${props => props.theme.colors.white};
    &:hover {
      background-color: ${props => props.theme.colors.secondary};
      border-color: ${props => props.theme.colors.secondary};
    }
  }

`;

const Footer = styled(aLayout.Footer)`
${space}
${position}
${layout}
${flexbox}
align-items: center;
background-color: ${props => props.theme.colors.background};
color: white;
display: flex;
justify-content: center;
width: 100%;
height: 60px;
`;

const Content = styled(aLayout.Content)`
${space}
${position}
${layout}
${flexbox}
color: white;
background-color: ${props => props.theme.colors.background};
`;

const Header = styled(aLayout.Header)`
${space}
${position}
${layout}
${flexbox}
a,h1,h2,h3,h4{
  color:white;
}
color: white;
background-color: ${props => props.theme.colors.background};
`;

const Breadcrumb = styled(aBreadcrumb)`
${space}
${position}
${layout}
${flexbox}

.ant-breadcrumb {
  margin: ${props => `${props.theme.space.small}px ${props.theme.space.none}`};
}
.ant-breadcrumb-separator {
  color: white;
}
span {
  color: white;
}
`;
const BreadcrumbItem = aBreadcrumb.Item;

export { MainLayout, SideMenu, SubMenu, Menu, MenuItem, Footer, Content, Header, Breadcrumb, BreadcrumbItem };
