import React, { useMemo } from 'react';

import BaseSelector from './BaseSelector';

const AssetSelector = ({ filter, ...props }) => {
  const { operator, name } = filter;
  const onChange = ({ value }) => {
    props.onChange({
      value,
      operator,
      name,
    });
  };

  // We have to use useMemo to ensure that this config object doesn't mutate unless
  // the name is changed.. otherwise our object reference will be different every
  // render cycle and use effect inside of base selector will render over and over
  const config = useMemo(() => {
    return {
      name,
      url: 'assets/?q=',
      valueKey: 'guid',
    };
  }, [name]);

  return (
    <>
      <div>Asset</div>
      <BaseSelector filter={filter} config={config} onChange={onChange} />
    </>
  );
};
export default AssetSelector;
