import React from 'react';
import ReactSelect from 'react-select';

const getMinHeight = ({ size }) => {
  if (size === 'large') {
    return '40px';
  } else if (size === 'small') {
    return '24px';
  }
  return '32px';
};

const getFontSize = ({ size }) => {
  if (size === 'large') {
    return '16px';
  }
  return '14px';
};

const Select = props => {
  const customStyles = {
    option: (provided, { isDisabled }) => {
      return {
        ...provided,
        border: '1px solid #246472',
        color: 'white',
        background: isDisabled ? '#212121' : '#141414',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    dropdownIndicator: provided => ({
      ...provided,
      padding: '5px',
    }),
    placeholder: (provided, selectProps) => ({
      fontFamily: 'Source Sans Pro,sans-serif',
      fontSize: getFontSize(selectProps),
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#bfbfbf',
    }),
    input: provided => ({
      color: '#fff',
      paddingLeft: '8px',
    }),
    control: (provided, selectProps) => ({
      ...provided,
      border: '1px solid #246472',
      boxShadow: '0 0 0 0 1px #246472',
      background: '#141414',
      fontFamily: 'Source Sans Pro,sans-serif',
      fontSize: getFontSize(selectProps),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      borderColor: '#246472',
      borderRadius: '4px',
      minHeight: getMinHeight(selectProps),
      height: '100%',
      '&:hover': {
        borderColor: '#246472',
      },
      '&:active': {
        borderColor: '#246472',
      },
      '&:focus': {
        borderColor: '#246472',
      },
    }),
    menu: provided => ({
      ...provided,
      padding: 0,
      zIndex: 9999,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menuList: provided => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'rgba(255,255,255,0.75)',
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
    }),
    multiValue: (styles, { data }) => {
      return {
        color: 'white',
        fontFamily: 'Source Sans Pro, sans-serif',
        backgroundColor: '#2E2E2E',
        border: '1px solid #246472',
        boxSizing: 'border-box',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '3px 6px 3px 0',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
      letterSpacing: '0em',
      textAlign: 'center',
      padding: '0',
      paddingRight: '8px',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      verticalAlign: 'middle',
      textAlign: 'center',
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '22px',
      margin: 'auto',
    }),
  };
  const value = typeof props.selected === 'undefined' ? props.value : props.selected;
  return (
    <ReactSelect
      {...props}
      defaultValue={props.defaultValue}
      value={value}
      options={props.options}
      styles={customStyles}
      menuPlacement="auto"
    />
  );
};

export { Select };
