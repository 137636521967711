import styled from '@emotion/styled';
import { SearchTable } from '@luxe/components';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearView, getViewData, getViewMeta } from 'views/modules/views';

const IncidentsTable = styled(({ assetId, className }) => {
  const currentPage = useRef(1);
  const currentOrderBy = useRef('');
  const incidents = useSelector(state => state.views?.['threats/assets'] || {});
  const [activePage, setActivePage] = useState(1);
  const [orderBy, setOrderBy] = useState([{ name: 'threat__headline', direction: 'asc' }]);
  const dispatch = useDispatch();

  const firstLoad = incidents.datasource === undefined;
  const meta = incidents.meta;
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    incidents,
  );

  useEffect(() => () => dispatch(clearView('threats/assets')), [dispatch]);

  useEffect(() => {
    if (firstLoad && assetId) {
      dispatch(getViewMeta('threats/assets'));
    }
  }, [dispatch, firstLoad, assetId]);

  useEffect(() => {
    const pageChanged = activePage !== currentPage.current;
    const orderByChanged = JSON.stringify(orderBy) !== currentOrderBy.current;
    const shouldLoad = meta && assetId && (firstLoad || pageChanged || orderByChanged);

    if (shouldLoad) {
      const params = {
        order_by: orderBy ?? [],
        limit: 10,
      };
      params.offset = (activePage - 1) * params.limit;
      currentPage.current = activePage;
      currentOrderBy.current = JSON.stringify(orderBy);
      const query = {
        columns: [
          'id',
          'threat__headline',
          'threat__family_subcategory',
          'family__name',
          'threat__severity',
          'threat__end_time',
        ],
        ...params,
        filters: [
          { name: 'asset__id', operator: '==', value: assetId },
          { name: 'system_type', operator: 'in', value: [1, 3] },
        ],
      };
      dispatch(getViewData('threats/assets', query));
    }
  }, [dispatch, firstLoad, meta, orderBy, assetId, activePage]);

  const columns = [
    {
      title: 'Headline',
      dataIndex: ['threat', 'headline'],
      sorter: true,
      orderBy: 'threat__headline',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Sub Category',
      dataIndex: ['threat', 'family_subcategory', 'name'],
      sorter: true,
      orderBy: 'threat__family_subcategory',
    },
    {
      title: 'Risk',
      dataIndex: ['family', 'name'],
      sorter: true,
      orderBy: 'family__name',
    },
    {
      title: 'Severity',
      dataIndex: ['threat', 'severity'],
      sorter: true,
      orderBy: 'threat__severity',
    },
    {
      title: 'Remaining Hours',
      dataIndex: ['threat', 'end_time'],
      sorter: true,
      orderBy: 'threat__end_time',
      render: date => (Math.abs(Date.now() - new Date(date)) / 36e5) | 0 || '< 1',
    },
  ];

  const onRowClick = (record, rowIndex) => {
    if (record?.id) window.open(`/risk-feed/threats/${record?.id}/overview/?channel=ALL_EVENTS`, '_blank');
  };

  return (
    <SearchTable
      description="No matching records found"
      table="incidents-table"
      className={className}
      rowKey={row => row.id}
      columns={columns}
      rowData={incidents.items}
      onPageChange={setActivePage}
      onSort={setOrderBy}
      paginationData={paginationData}
      tableSize="small"
      displayHidden
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onRowClick(record, rowIndex),
        };
      }}
    />
  );
})`
  padding: 0;
  background-color: unset;
  .section-row-header {
    display: none;
  }
`;

export default IncidentsTable;
