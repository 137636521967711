import React from 'react';
import styled from '@emotion/styled';
import { Radio } from 'antd';
import { GRAPH_MODES } from 'common/Constants';

const AssetDiscoverDimension = styled(props => {
  return (
    <Radio.Group
      className="discover-graph-mode"
      value={props.value}
      buttonStyle="solid"
      onChange={e => {
        props.setGraphMode(e.target.value);
        props.setNodesRefreshed(true);
      }}
      size="small"
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        zIndex: 1,
      }}
    >
      <Radio.Button value={GRAPH_MODES.TIERED_2D}>2D Tiered</Radio.Button>
      <Radio.Button value={GRAPH_MODES.CLUSTERED_2D}>2D Clustered</Radio.Button>
      <Radio.Button value={GRAPH_MODES.CLUSTERED_3D}>3D Clustered</Radio.Button>
    </Radio.Group>
  );
})``;

export default AssetDiscoverDimension;
