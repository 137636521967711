import React from 'react';

import { useSelector } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import styled from '@emotion/styled';
import { MapContainer, Marker, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { getIncidentMapIcon } from 'riskfeed/modules/utils';

const Map = styled(({ threat, category, ...props }) => {
  const { geometry = {}, id, name } = threat;
  const { type = '' } = geometry || {};

  let bounds = undefined;
  let center = undefined;
  let markerLayer = null;

  if (type) {
    if (type === 'Point') {
      const { coordinates } = geometry;
      center = coordinates.reverse();
      markerLayer = (
        <Marker position={center} icon={getIncidentMapIcon(threat.severity?.toLowerCase(), threat.icon)}></Marker>
      );
    } else {
      const featureData = { type: 'Feature', geometry, properties: { id, name } };
      const feature = L.geoJSON(featureData);
      bounds = feature.getBounds();
      markerLayer = <GeoJSON data={featureData} style={{ color: '#c3351d' }} />;
    }
  } else {
    return null;
  }

  return (
    <div className={props.className}>
      <MapContainer
        center={center}
        bounds={bounds}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: '230px', width: '100%' }}
      >
        <TileLayer url="https://api.mapbox.com/styles/v1/stormpulse/ck0zgcsyw0yrc1ckypuh2q4be/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3Rvcm1wdWxzZSIsImEiOiJYd1YxUXpNIn0.N_-0IOIF6RUOHyF9JUIPVQ" />
        {markerLayer}
      </MapContainer>
    </div>
  );
})`
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    background: #2e2e2e;

    .leaflet-control-zoom-in {
      border-bottom: 1px solid #000;
    }
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      background: #2e2e2e;
      color: #ccc;

      &:hover {
        background: #7a7a7a;
        color: #fff;
      }
    }
  }
  .incident-icon {
    background: none;
    .incident-icon-background,
    .incident-category-icon {
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .incident-category-icon {
      padding: 12px 9px 5px;
      filter: drop-shadow(0 0 0 black);
    }
  }
`;

const OverviewTab = () => {
  const { threat } = useSelector(state => state.riskfeed);
  const { controlFormattedCountries } = useSelector(store => store.userNetwork);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const modifiedDate = new Date(threat.item.modified_date).toLocaleDateString('en-US', options);

  const { area_description: areaDescription } = threat.item;
  const areaImpact = areaDescription ? (
    <dl style={{ display: 'block', overflow: 'auto' }}>
      <dt>Area of impact</dt>
      <dd>{areaDescription}</dd>
    </dl>
  ) : null;

  let countries = '';
  if (threat.item.countries) {
    countries = threat.item.countries.map(id => {
      const country = controlFormattedCountries.find(x => x.value === id);
      return country ? country.label : '';
    });
  }

  let parentCompany = '';
  if (threat?.item?.entity_ids?.company?.length) {
    try {
      parentCompany = threat?.item?.entity_ids?.company
        .filter(element => element?.company_name)
        .map(v => v.company_name)
        .join(',');
    } catch (err) {
      parentCompany = '';
    }
  }

  return (
    <section>
      {!parentCompany ? (
        <>
          {' '}
          <Map threat={threat.item} />
          {areaImpact}
        </>
      ) : (
        <dl style={{ display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
          <dt>Parent Company: </dt>
          <dd>{parentCompany}</dd>
        </dl>
      )}
      <dl>
        <dt>Countries</dt>
        <dd>{countries}</dd>
        <dt>Last Update</dt>
        <dd>{modifiedDate}</dd>
      </dl>
    </section>
  );
};
export default OverviewTab;
