import { aRangePicker as RangePicker, Section, Button, SearchInput } from '@luxe/components';
import { ViewTable } from 'views/components';
import { buildActionsColumn, getParams } from 'views/utils';
import { getViewData, getViewMeta } from 'views/modules/views';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ShareIncidentModal from 'assets/modals/ShareIncidentModal';
import IncidentHistoryModal from 'assets/modals/IncidentHistoryModal';
import { Col } from 'antd';
import { operators as Ops } from 'constants/application';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import useMixpanel from 'utils/hooks/Mixpanel';
import _ from 'lodash';
import moment from 'moment';

dayjs.extend(weekday);
dayjs.extend(localeData);
const dateFormat = 'YYYY-MM-DD';

function AssetThreatSection() {
  const params = useParams();
  const { id: assetId } = params;
  const location = useLocation();
  const history = useHistory();
  const currentSearch = useRef(null);
  const views = useSelector(store => store.views);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const searchParams = new URLSearchParams(location.search);

  const dateRange = searchParams.get('date_reported__between');
  const [startDate, setStartDate] = useState(dateRange ? new Date(dateRange.split(',')[0]) : null);
  const [endDate, setEndDate] = useState(dateRange ? new Date(dateRange.split(',')[1]) : null);

  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [isShareIncidentModalVisible, setIsShareIncidentModalVisible] = useState(false);
  const [isIncidentHistoryModalVisible, setIsIncidentHistoryModalVisible] = useState(false);
  const [activeIncidents, setActiveIncidents] = useState(true);

  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const assetThreats = views?.['threats/assets'] || {};
  const firstLoad = assetThreats.currentUrl === undefined;
  const meta = assetThreats.meta;

  const actionButtons = [
    {
      icon: 'ShareAltOutlined',
      title: 'Share Incident',
      action: selectedId => {
        setSelectedIncidentId(selectedId);
        setIsShareIncidentModalVisible(true);
      },
    },
    {
      icon: 'HistoryOutlined',
      title: 'Incident History',
      action: selectedId => {
        setSelectedIncidentId(selectedId);
        setIsIncidentHistoryModalVisible(true);
      },
    },
  ];

  function handlePickerOpen() {
    setVisible(true);
    // Disable scrolling on the page
    document.body.style.overflow = 'hidden';
  }

  function handlePickerClose() {
    setVisible(false);
    // Reset the overflow property of the body element
    document.body.style.overflow = null;
  }

  useEffect(() => {
    if (firstLoad) {
      const overrides = {
        assets: {
          description: 'Incident Risk',
        },
      };
      dispatch(getViewMeta('threats/assets', overrides));
    }
  }, [dispatch, firstLoad]);

  const apiCallConfiguration = React.useCallback(
    (activeIncidentVal, searchTerm = '') => {
      const params = getParams(location.search);
      params.limit = 10;
      currentSearch.current = location.search;

      if (!params.order_by?.length > 0) {
        params.order_by.push({ name: 'date_updated', direction: 'desc' });
      }
      params.filters = params.filters.concat([
        { name: 'asset__id', operator: Ops.exact, value: assetId },
        { name: 'system_type', operator: Ops.in, value: [1, 3] },
      ]);
      if (searchTerm) {
        params.filters = [...params.filters, { name: 'query', value: searchTerm, operator: '==' }];
      }
      const query = {
        columns: ['threat', 'category', 'assets', 'date_reported', 'date_updated'],
        ...params,
        url: 'threats/assets',
        show_inactive: activeIncidentVal,
      };
      dispatch(getViewData('threats/assets', query));
    },
    [dispatch, assetId, location.search],
  );

  useEffect(() => {
    const searchChanged = location.search !== currentSearch.current;
    const shouldLoad = meta && (firstLoad || searchChanged);
    if (shouldLoad) {
      apiCallConfiguration(!activeIncidents, searchValue);
    }
  }, [
    dispatch,
    firstLoad,
    meta,
    location.search,
    assetId,
    startDate,
    endDate,
    activeIncidents,
    apiCallConfiguration,
    searchValue,
  ]);

  const date = startDate && endDate ? [dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)] : [null, null];
  const convertToUTC = date => moment(date).toISOString();
  const onTabClicked = range => {
    if (range) {
      setStartDate(`${range[0].format('YYYY-MM-DD')}T00:00:00`);
      setEndDate(`${range[1].format('YYYY-MM-DD')}T23:59:59`);
      const startUTC = convertToUTC(`${range[0].format('YYYY-MM-DD')}T00:00:00`);
      const endUTC = convertToUTC(`${range[1].format('YYYY-MM-DD')}T23:59:59`);
      history.push({
        pathname: location.pathname,
        search: `?date_reported__between=${startUTC},${endUTC}`,
      });
    } else {
      setStartDate(null);
      setEndDate(null);

      history.push({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  const toggleIncidentsButtonClick = () => {
    setActiveIncidents(!activeIncidents);
    apiCallConfiguration(activeIncidents, searchValue);
    if (activeIncidents) {
      mixpanelTrack('Asset Scorecard - Click Inactive Incidents Button');
    } else {
      mixpanelTrack('Asset Scorecard - Click Active Incidents Button');
    }
  };

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce(searchTerm => {
        setSearchValue(searchTerm);
        apiCallConfiguration(!activeIncidents, searchTerm);
      }, 350),
    [activeIncidents, apiCallConfiguration],
  );

  const getModifiedAssetThreat = () => {
    let newAssetThreats = JSON.parse(JSON.stringify(assetThreats));
    try {
      if (newAssetThreats?.items?.length) {
        newAssetThreats.items.forEach(val => {
          if (val?.threat?.end_time) {
            val.end_time = val?.threat?.end_time;
          }
          if (val?.threat?.family_subcategory?.name) {
            val.category = val?.threat?.family_subcategory?.name;
          }
          return val;
        });
      }
      if (newAssetThreats?.meta?.current?.columns?.length) {
        let incidentColumns = newAssetThreats.meta.current.columns;
        incidentColumns.push({
          widget: 'Input',
          description: 'End Date',
          short_description: 'End Date',
          type: 'datetime',
          name: 'end_time',
          order_by: 'end_time',
          columns: [],
          longDateFormat: true,
        });
        incidentColumns.forEach(val => {
          if (val.type === 'datetime') {
            if (val.name === 'date_reported') {
              val.description = 'Start Date';
              val.longDateFormat = true;
            }
            if (val.name === 'date_updated') {
              val.description = 'Last Update';
              val.longDateFormat = true;
            }
          }
          if (val.name === 'threat') {
            val.description = 'Incident Headline';
          }
          return val;
        });
      }
      return newAssetThreats;
    } catch (error) {
      return newAssetThreats;
    }
  };

  return (
    <>
      <Section
        title={activeIncidents ? 'Active Incidents' : 'Inactive Incidents'}
        buttons={[
          <Button
            variant="secondary"
            type="button"
            onClick={toggleIncidentsButtonClick}
            key={'btn1'}
            style={{ fontSize: 'medium', backgroundColor: '#545454', borderColor: '#545454' }}
          >
            {activeIncidents ? 'View Inactive Incidents' : 'View Active Incidents'}
          </Button>,
        ]}
      >
        <Col span={18} style={{ marginBottom: '10px' }}>
          <RangePicker
            onChange={onTabClicked}
            value={date}
            onOpenChange={handlePickerOpen}
            onBlur={handlePickerClose}
            visible={visible}
          />
        </Col>
        <Col span={6} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <SearchInput
            className="search-input"
            onChange={e => {
              debouncedChangeHandler(e.target.value);
            }}
          />
        </Col>
        <div style={{ width: '100%' }}>
          <ViewTable
            title="Incidents"
            data={getModifiedAssetThreat()}
            actions={[]}
            rowSelection={false}
            selectAll={() => {}}
            rowKey={row => `${row.threat.id}-${row.threat.name}`}
            includes={['name', 'created_date', 'modified_date']}
            push={history.push}
            actionColumn={buildActionsColumn(actionButtons, 'threat.id')}
            size={'small'}
            sorted={true}
            paramsToKeep={['threat__start__gte', 'threat__start__between']}
          />
        </div>
      </Section>
      <ShareIncidentModal
        isModalVisible={isShareIncidentModalVisible}
        closeModal={() => setIsShareIncidentModalVisible(false)}
        incidentId={selectedIncidentId}
      />
      <IncidentHistoryModal
        isModalVisible={isIncidentHistoryModalVisible}
        closeModal={() => setIsIncidentHistoryModalVisible(false)}
        incidentId={selectedIncidentId}
      />
    </>
  );
}

export default AssetThreatSection;
