import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Col, Row, Tooltip } from 'antd';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { generateRiskModelTemplate, uploadRiskModelTemplate } from 'admin/riskmodels/modules/riskmodels';
import { stringSorter, dateSorter } from 'utils';
import { Button, BasicModal as Modal, UploadIcon as UploadTwoTone, Table, Empty } from '@luxe/components';

const DATE_FORMAT = 'MMM DD, YYYY';

const getFormattedDate = date =>
  moment(date)
    .utc()
    .format(DATE_FORMAT);

const RiskModelTable = ({ status, riskModels, setSelectedRiskModel }) => {
  const dispatch = useDispatch();
  const [isTemplateModalShowing, setIsTemplateModalShowing] = useState(false);
  let description = 'There are no Risk Models available';
  const history = useHistory();
  const [hovered, setHovered] = useState(0);
  const [riskModelId, setRiskModelId] = useState();
  const [template, setTemplate] = useState();
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  const generateTemplate = () => {
    dispatch(generateRiskModelTemplate({ id: riskModelId }));
    setIsTemplateModalShowing(false);
  };

  const updateTemplate = e => {
    setTemplate(e.target.files[0]);
    setUploadButtonDisabled(e.target.files.length !== 1);
  };

  const uploadTemplate = () => {
    const formData = new FormData();
    formData.append('templateFile', template, template.name);
    dispatch(uploadRiskModelTemplate({ id: riskModelId }, formData));
    setIsTemplateModalShowing(false);
  };

  if (status === REQUESTED_DATA_STATUS.ERROR) {
    description = 'Something went wrong';
  }

  const actionColumn = (id, name) => {
    return (
      <Tooltip title="Manual Risk Import">
        <UploadTwoTone
          onClick={event => {
            event.stopPropagation();
            setRiskModelId(id);
            setIsTemplateModalShowing(true);
          }}
        />
      </Tooltip>
    );
  };

  const getColumns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      render: riskModel => <span>{riskModel}</span>,
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'Model Type',
      dataIndex: 'system_wide',
      render: riskModel => {
        return <span>{riskModel ? 'System-Wide' : 'Org-Wide'}</span>;
      },
      sorter: (a, b) => (a.system_wide === b.system_wide ? 0 : b.system_wide ? -1 : 1),
    },
    {
      title: 'Last Modified',
      render: riskModel => getFormattedDate(riskModel.modified_date),
      sorter: (a, b) => dateSorter(a.modified_date, b.modified_date),
    },
    {
      title: 'Mass Import',
      render: riskModel => actionColumn(riskModel.id, riskModel.name),
      align: 'center',
    },
  ];

  const setUrl = path => {
    if (path.includes('/admin/risk-models/')) {
      history.push(hovered.toString() + '/');
    } else {
      history.push('/admin/risk-models/' + hovered.toString() + '/');
    }
  };

  const rowSelection = {
    getCheckboxProps: record => {
      return {
        disabled: record?.system_wide,
      };
    },
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRiskModel(selectedRows);
    },
  };

  const rowInteraction = record => {
    return {
      onClick: () => {
        let path = history.location.pathname;
        setUrl(path);
      },
      onMouseEnter: () => {
        setHovered(record.id);
      },
      onMouseLeave: () => {
        setHovered(0);
      },
    };
  };

  return (
    <div>
      <Table
        onRow={rowInteraction}
        rowSelection={rowSelection}
        rowKey="id"
        status={status}
        columns={getColumns()}
        dataSource={riskModels}
        description={description}
        size="small"
        locale={{ emptyText: <Empty description={status === REQUESTED_DATA_STATUS.PENDING ? 'Loading' : 'No Data'} /> }}
      />
      <Modal
        title={'Manual Risk Import'}
        visible={isTemplateModalShowing}
        onOk={() => {
          setIsTemplateModalShowing(false);
        }}
        onCancel={() => {
          setIsTemplateModalShowing(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Row>
          <Col style={{ width: '100%', marginBottom: '25px' }}>
            <p>
              To mass import the risk scores for a risk model, you need to download a template. The template contains
              all assets that have the selected risk model. Populate the risk score data in the template and upload the
              file. Processing of the data could take a while.
            </p>
            <p>
              You will receive an email notification once processing is completed, indicating success or listing errors.
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '65%', marginBottom: '15px' }}>Generate template file for importing scores</Col>
          <Col style={{ width: '35%', marginBottom: '15px' }}>
            <Button variant="menu" type="link" onClick={generateTemplate}>
              Generate Template
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '65%' }}>Upload completed file to import scores</Col>
        </Row>
        <Row>
          <Col style={{ width: '65%' }}>
            <input type="file" onChange={updateTemplate} accept=".xls,.xlsx" />
          </Col>
          <Col style={{ width: '35%' }}>
            {!uploadButtonDisabled ? (
              <Button variant="menu" type="link" onClick={uploadTemplate}>
                Upload Template
              </Button>
            ) : (
              <Button variant="disabled" type="link" style={{ width: '150px' }}>
                Upload Template
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RiskModelTable;
