import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { TabContainer } from 'assets/containers/AssetDetailView/styles';

const AssetDrawerTabs = styled(({ className, assetTabs }) => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState();

  const onTabChange = key => {
    const tab = assetTabs.find(tab => tab.title === key);
    if (tab) {
      setActiveTab(tab.title);
    }
  };

  return (
    <TabContainer className={className} onChange={onTabChange} activeKey={activeTab}>
      {assetTabs.length > 0 &&
        assetTabs.map(tab => (
          <TabPane tab={tab.title} key={tab.tabKey}>
            {tab.content}
          </TabPane>
        ))}
    </TabContainer>
  );
})`
  background-color: #212121;
  bottom: 0;
  padding: 10px;

  .ant-tabs-content-holder {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.6em;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 30px;
      border: 1px solid black;
    }
  }
`;

export default AssetDrawerTabs;
