import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { SelectStyle } from '@luxe/components/src/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { clearAsyncOptions, getAsyncOptions } from 'views/modules/options';

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {!props.isLoading && props.selectProps.additional?.isLoading && (
        <div style={{ padding: '12px' }}>Loading More Data...</div>
      )}
    </components.MenuList>
  );
};

const AsyncSelect = SelectStyle(({ menuPlacement, styles, isMulti, config, ...props }) => {
  // config = {
  //   selectedOptions, // Pass list of selected values
  //   params, // [Object] Params to be passed on get request
  //   isPOST, // To make POST request instead of get request
  //   getRequestBody, //Used for generating post request body, (query, skip, limit) => {[request body]}
  //   url, // URL of API
  //   name, // name for state management
  //   limit, // default limit=10
  //   valueKey, // for parsing value from response
  //   labelKey, // for parsing label from response
  //   parseOptions, // function for overiding response parsing
  //   uniqBy, // make options uniq by this key in options
  // };
  const { name, limit = 10, selectedOptions = [] } = config;
  const [searchInput, SetSearchInput] = useState('');
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  const { options = [], query, loading: isLoading, hasNext, initialized } = useSelector(
    store => store.options[name] || {},
  );
  const value = options.filter(option => selectedOptions.includes(option.value));

  // For multi select with input
  const searchSelectOptions = {};
  searchSelectOptions.inputValue = searchInput;
  searchSelectOptions.closeMenuOnSelect = !isMulti;
  searchSelectOptions.onInputChange = (input, action) => {
    if (['input-change', 'menu-close'].includes(action.action)) {
      if (searchInput !== input) {
        setSkip(limit);
        dispatch(getAsyncOptions(config, input ?? '', 0, limit));
      }
      SetSearchInput(input ?? '');
    }
  };

  useEffect(() => () => dispatch(clearAsyncOptions(name)), [name, dispatch]);

  useEffect(() => {
    if (!initialized && !isLoading && selectedOptions.length) {
      const temp = { ...config, params: { ids: selectedOptions } };
      dispatch(getAsyncOptions(temp, '', 0, 1000));
      setSkip(0);
    }
  }, [initialized, isLoading, selectedOptions, config, dispatch]);

  const handlePaginate = useCallback(() => {
    if (hasNext && !isLoading) {
      dispatch(getAsyncOptions(config, searchInput ?? '', skip, limit));
      setSkip(skip + limit);
    }
  }, [hasNext, skip, limit, searchInput, isLoading, config, dispatch]);

  // fetch data if options avalable in dropdown is less
  useEffect(() => {
    if (!isLoading && hasNext && options.length - selectedOptions.length < 10) {
      handlePaginate();
    }
  }, [isLoading, hasNext, options, selectedOptions, handlePaginate]);

  const handleMenuOpen = () => {
    if (query !== searchInput || options.length - selectedOptions.length < 1) {
      setSkip(limit);
      dispatch(getAsyncOptions(config, searchInput ?? '', 0, limit));
    }
  };

  return (
    <Select
      {...{
        ...searchSelectOptions,
        ...props,
        value,
        options,
        onMenuScrollToBottom: handlePaginate,
        onMenuOpen: handleMenuOpen,
        additional: { isLoading },
        isLoading: !((options?.length ?? 0) - [value ?? []].flat().length) && isLoading,
        isMulti: isMulti ?? false,
        menuPlacement: menuPlacement ? menuPlacement : 'auto',
        styles: styles
          ? { input: styles => ({ ...styles, color: 'white' }), ...styles }
          : { input: styles => ({ ...styles, color: 'white' }) },
      }}
      components={{ MenuList }}
    />
  );
});

export default AsyncSelect;
