import React from 'react';
import PropTypes from 'prop-types';
import { RiskBadge as Badge } from '@luxe/components';

export default class DefaultBadgeFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
  };

  get SentenceCase() {
    const { value } = this.props;
    return value.charAt(0) + value.slice(1).toLowerCase();
  }

  render() {
    if (this.props.value) {
      return <Badge style={{ width: 'max-content' }} variant={'primary'} count={this.SentenceCase} />;
    }
    return null;
  }
}
