import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';

import createRootReducer from './reducers.js';

import rootEpic from './epics';

const epicMiddleware = createEpicMiddleware();

export const history = createBrowserHistory();

const enhancers = [];
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), thunk, epicMiddleware), ...enhancers),
  );
  return store;
}
const store = configureStore();
epicMiddleware.run(rootEpic);
export default store;
