import React, { useCallback, useEffect } from 'react';
import GroupView from '@luxe/components/src/GroupView';
import { useDispatch, useSelector } from 'react-redux';
import { clearViewData, getViewMeta } from 'views/modules/views';
import {
  addProductsToGroup,
  createProductGroups,
  deleteProductGroup,
  getProductGroups,
  removeProductsFromGroup,
  updateProductGroups,
} from 'admin/productgroups/modules/productgroups';
import { fetchProducts } from 'admin/productgroups/modules/utils';
import { getEditLink } from './utils';
import { getFormattedDate } from 'assets/modules/utils';

const ProductGroupsView = () => {
  const views = useSelector(store => store.views);
  const dispatch = useDispatch();
  const products = views?.['materials'] || {};
  const productsMeta = products.meta;
  const productsItems = products.items;
  const productGroups = useSelector(store => store.productGroups)?.productGroups || [];
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    products,
  );
  useEffect(() => {
    dispatch(getProductGroups());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewData('materials'));
    },
    [dispatch],
  );

  const fetchData = useCallback(
    ({ selectedGroup, searchTerm, currentPage, orderBy }) =>
      dispatch(fetchProducts({ selectedGroup, searchTerm, currentPage, orderBy })),
    [dispatch],
  );

  const fetchMeta = useCallback(fetchData => dispatch(getViewMeta('materials', undefined, undefined, fetchData)), [
    dispatch,
  ]);

  const productColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      orderBy: 'name',
    },
    {
      title: 'Product ID',
      dataIndex: 'id',
      sorter: true,
      orderBy: 'id',
    },
    {
      title: 'Last Modified',
      render: a => getFormattedDate(a.modified_date, 'YYYY-MM-DD h:mm a'),
      sorter: true,
      orderBy: 'modified_date',
    },
    {
      title: 'Actions',
      render: product => getEditLink(product),
    },
  ];

  return (
    <GroupView
      groups={productGroups}
      groupsURL={'/product-groups/'}
      title={'Products & Groups'}
      columns={productColumns}
      rowData={productsItems}
      fetchData={fetchData}
      meta={productsMeta}
      fetchMeta={fetchMeta}
      itemName={'product'}
      createGroupFunction={data => createProductGroups({ ...data, type: 'product' })}
      editGroupFunction={updateProductGroups}
      deleteGroupFunction={deleteProductGroup}
      addItemsToGroupFunction={addProductsToGroup}
      removeItemsFromGroupFunction={removeProductsFromGroup}
      paginationData={paginationData}
    />
  );
};

export default ProductGroupsView;
