import React from 'react';
import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Tag as aTag } from 'antd';
import XDeleteIcon from '../Icons/XDeleteIcon.svg';

const StylizedTag = styled(aTag)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  box-sizing: border-box;
  font-family: "Source Sans Pro",sans-serif;
  background-color: ${props => props.theme.colors.black2};
  border: 1px solid rgba(36, 100, 114, 1);
  border-radius: 10px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  height: 22px;
  width: 72px;
  margin-left: 4px;

`;

const TagTitle = styled.span`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  height: 20px;
  width: 42px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 1px 8px 1px 0;

`;

const Icon = styled.img`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  margin: 3px 4px 1px 8px;
`;

const TagInnerContainer  = styled.div`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Tags = ({tagName, ...props}) => {
  return(
    <StylizedTag>
      <TagInnerContainer>
        <Icon src={XDeleteIcon} alt="delete" />
        <TagTitle>{tagName}</TagTitle>
      </TagInnerContainer>
    </StylizedTag>
  )
}


export { Tags };
