import React from 'react';
import { useSelector } from 'react-redux';
import { Section, Table } from '@luxe/components';

const EconomicLogisticTab = () => {
  const { region } = useSelector(store => store.regions);
  const { content } = region;

  const titleCase = text => {
    let splitText = text.split('_');
    splitText = splitText.map(word => {
      word = word.toLowerCase();
      let first = word[0].toUpperCase();
      let last = word.substring(1);
      return first + last;
    });
    return splitText.join(' ');
  };

  const EconomicData = () => {
    let result = [];
    const titles = ['economics', 'logistics', 'private_sector'];
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '70%',
      },
      {
        title: 'Value',
        dataIndex: 'data',
        key: 'data',
        width: '15%',
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        width: '15%',
      },
    ];
    if (content !== null) {
      result = titles
        .filter(title => content[title] !== null && content[title] !== undefined)
        .map((title, idx) => {
          return (
            <div key={`econ-logistic-${idx}`} style={{ width: '100%', marginBottom: '30px' }}>
              <h2>{titleCase(title)}</h2>
              <Table pagination={false} rowKey="id" dataSource={Object.values(content[title])} columns={columns} />
            </div>
          );
        });
    }

    result = result.length > 0 ? result : <div>No Data Available</div>;
    return result;
  };

  return (
    <Section rules={true}>
      <EconomicData />
    </Section>
  );
};

export default EconomicLogisticTab;
