import React from 'react';
import PropTypes from 'prop-types';

export default class RecommendationFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    return <span className={`badge ${this.props.value.category}`}> {this.props.value.score}</span>;
  }
}
