import CountryDropDown from 'countries/containers/CountryMapView/CountryDropDown';
import React from 'react';
import { Row } from 'antd';
import { ShowOnMap } from '../styles';
import styled from '@emotion/styled';

const CountryNavButtons = ({ allRegions }) => {
  const HeaderButtons = styled.div`
    .ant-row {
      margin-top: 10px;
      width: 188px;
    }
  `;

  const mapHandler = () => {
    const luxeUrl = '/country-risk/';
    window.location.href = luxeUrl;
  };

  return (
    <HeaderButtons>
      <Row type="flex">
        <ShowOnMap variant="primary" htmlType="button" onClick={mapHandler} target="_blank">
          Show On Map
        </ShowOnMap>
        <CountryDropDown allRegions={allRegions} />
      </Row>
    </HeaderButtons>
  );
};

export default CountryNavButtons;
