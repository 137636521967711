import axios from 'axios';
import getFromConfig from 'constants/config';

const axiosInstance = axios.create({
  baseURL: getFromConfig('REACT_APP_MAGLEV_BASE_URL'),
});

const errorHandler = async error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
};

const setAxiosTokenInterceptor = async accessToken => {
  axiosInstance.interceptors.request.use(async config => {
    const requestConfig = config;
    if (accessToken) {
      requestConfig.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    return requestConfig;
  }, errorHandler);
};

export default axiosInstance;
export { setAxiosTokenInterceptor };
