import React, { useEffect } from 'react';
import { getRiskModel, saveRiskModel, updateRiskModel } from 'admin/riskmodels/modules/riskmodels';
import { useTheme } from 'emotion-theming';
import { Form, Row, Col } from 'antd';
import { Container, DetailViewContent } from '@luxe/components/src/Container';
import RiskModelIndex from './RiskModelIndex';
import HeaderRow from './HeaderRow';
import ScoreComponent from 'admin/riskmodels/containers/RiskModelDetailView/ScoresListComponent';
import RiskModelManagement from './RiskModelManagement';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const RiskModelDetailView = () => {
  const dispatch = useDispatch();

  const riskModel = useSelector(store => store.riskModels.RiskModel);

  const { id } = useParams();
  const { space } = useTheme();

  const isEditable = !riskModel?.system_wide;

  useEffect(() => {
    if (id) {
      dispatch(getRiskModel({ id }));
    }
  }, [id, dispatch]);

  const showWeightedScoreElement = () => {
    return riskModel?.scoring_type !== 'max_score';
  };

  return riskModel ? (
    <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <Form name="edit_risk_model" onFinish={() => dispatch(saveRiskModel(riskModel))}>
        <Container>
          <HeaderRow isEditable={isEditable} item={riskModel} form="edit_risk_model" />
          <RiskModelManagement
            item={riskModel}
            updateRiskModel={updateRiskModel}
            isEditable={isEditable}
            showWeightedScoreElement={showWeightedScoreElement}
          />
          <Row>
            <Col span={16}>
              {riskModel?.indices.map(model => (
                <Row key={model.key}>
                  <RiskModelIndex
                    label={model.name}
                    indices={model.indices}
                    weight={model.weight}
                    indexArray={model.indexArray}
                    total={model.total}
                    isEditable={isEditable}
                    showWeightedScoreElement={showWeightedScoreElement}
                  />
                </Row>
              ))}
            </Col>
            <Col span={8}>{isEditable && <ScoreComponent />}</Col>
          </Row>
        </Container>
      </Form>
    </DetailViewContent>
  ) : null;
};

export default RiskModelDetailView;
