import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { riskProfileReducer } from './admin/riskprofiles/modules/riskprofiles';
import { riskModelsReducer } from './admin/riskmodels/modules/riskmodels';
import { catalogRulesReducer } from './admin/riskprofiles/modules/catalogRules';
import { assetGroupsReducer } from './admin/assetgroups/modules/assetgroups';
import { materialsReducer } from './admin/materials/modules/materials';
import { materialGroupsReducer } from './admin/materialgroups/modules/materialgroups';
import { productsReducer } from './admin/products/modules/products';
import { productGroupsReducer } from './admin/productgroups/modules/productgroups';
import { planPlaybookReducer } from './admin/playbooks/modules/planPlaybooks';
import { corporatesReducer } from './admin/corporates/modules/corporates';
import { userGroupsReducer } from './admin/usergroups/modules/userGroups';
import { assetsReducer } from './admin/assets/modules/assets';
import { lanesReducer } from './admin/lanes/modules/lanes';
import { userReducer } from './admin/users/modules/users';
import { riskHistoryReducer } from './assets/modules/riskhistory';
import { riskFilterReducer } from './assets/modules/riskfilter';
import { regionsReducer } from 'countries/modules/regions';
import { networkGraphReducer } from 'discover/modules/viz';
import userProfileReducer from './modal/userprofile/modules/userprofile';
import networkReducer from './modal/userprofile/modules/network';
import userProfileNotificationsReducer from './modal/userprofile/modules/notifications';
import channelReducer from './modal/incidentfeed/modules/channel';
import familyReducer from './modal/incidentfeed/modules/utils';
import subscriptionReducer from './modal/incidentfeed/modules/incidentsubscribe';
import channelEventsReducer from './modal/incidentfeed/modules/channelevents';
import workflows from './admin/workflows/modules/workflows';
import workflowRulesReducer from './admin/workflows/modules/rules';
import riskfeed from './riskfeed/modules/riskfeed';
import plans from './riskfeed/modules/plans';
import views from './views/modules/views';
import options from './views/modules/options';
import feedback from './feedback/modules/feedback';
import account from './account/modules/account';
import accountOrg from './account/modules/accountOrg';
import { componentsReducer } from 'assets/modules/components';
import { apiOperationsReducer } from 'common/reducers/apiOperationsReducer';
import { incidentsReducer } from 'assets/modules/incidents';
import graphs from 'assets/modules/graphs';
import forecastsReducer from 'assets/modules/forecast';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    riskProfiles: riskProfileReducer,
    riskModels: riskModelsReducer,
    catalogRules: catalogRulesReducer,
    assetGroups: assetGroupsReducer,
    materials: materialsReducer,
    materialGroups: materialGroupsReducer,
    products: productsReducer,
    productGroups: productGroupsReducer,
    planPlaybooks: planPlaybookReducer,
    corporates: corporatesReducer,
    assets: assetsReducer,
    incidents: incidentsReducer,
    components: componentsReducer,
    lanes: lanesReducer,
    userGroups: userGroupsReducer,
    users: userReducer,
    userProfile: userProfileReducer,
    userNetwork: networkReducer,
    userNotifications: userProfileNotificationsReducer,
    channel: channelReducer,
    channelEvents: channelEventsReducer,
    families: familyReducer,
    riskfeed,
    riskhistory: riskHistoryReducer,
    riskFilter: riskFilterReducer,
    regions: regionsReducer,
    networkGraph: networkGraphReducer,
    subscriptionReducer,
    workflows,
    workflowRules: workflowRulesReducer,
    options,
    plans,
    views,
    feedback,
    account,
    accountOrg,
    operations: apiOperationsReducer,
    graphs,
    forecasts: forecastsReducer,
  });
export default createRootReducer;
