import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import getFromConfig from 'constants/config';
import { useHistory, useLocation } from 'react-router-dom';
import { setTableVisibility } from 'discover/modules/viz';
import AssetSearchBar from 'discover/containers/DiscoverNetworkView/AssetSearchBar';
import useQuery from 'utils/hooks/useQuery';
import SupplyChainIcon from '../../icons/supply-chain-icon-blue.svg';

const NavigationMenu = styled(props => {
  const history = useHistory();
  const location = useLocation();
  const { accountOrg } = useSelector(store => store.accountOrg);
  const isAdmin = useSelector(store => store.account.account?.isAdmin);
  const dispatch = useDispatch();
  const incID = useQuery().get('inc_id');
  const redirectToMap = () => (window.location.href = getFromConfig('REACT_APP_RISKBOX_BASE_URL'));
  const redirectToNetwork = () => {
    history.push('/discover/network/');
    if (location?.search?.includes('inc_id')) {
      window.location.reload(false);
    } else {
      window.location.reload(true);
    }
  };
  const redirectToList = () => {
    if (!incID) {
      history.push('/discover/network/?list=true');
    }
    dispatch(setTableVisibility(true));
  };

  const redirectToIncidentFeed = () => {
    history.push('/risk-feed/');
  };
  return (
    <div className={`${props.className}`}>
      {getFromConfig('REACT_APP_COMET_URL') && (accountOrg?.prefs?.ux_overhaul || isAdmin) && (
        <span
          className="menu-item"
          onClick={() => window.open(`${getFromConfig('REACT_APP_COMET_URL')}incidents/`, '_blank')}
        >
          <img src={SupplyChainIcon} alt="Supply Chain Icon" className="supply-chain-icon" />
          <label>Supply Chain</label>
        </span>
      )}
      <span className="menu-item" onClick={redirectToMap}>
        <span className={`ft-map_view`}></span>
        <label>Map</label>
      </span>
      {accountOrg?.prefs?.discover?.network_viz !== false &&
        (accountOrg?.prefs?.discover?.enabled || accountOrg?.prefs?.materials?.enabled) && (
          <span className="menu-item" onClick={redirectToNetwork}>
            <span className={`ft-earth`}></span>
            <label>Network</label>
          </span>
        )}
      <span className="menu-item" onClick={redirectToList}>
        <span className={`ft-list2`}></span>
        <label>Lists</label>
      </span>
      <span className="menu-item" onClick={redirectToIncidentFeed}>
        <span className={`ft-feed`}></span>
        <label>Action Center</label>
      </span>
      <AssetSearchBar className="menu-item-search" />
    </div>
  );
})`
  align-items: left;
  display: flex;
  height: 70px !important;
  width: 100%;

  .menu-iframe {
    background-color: #2e2e2e;
    border: 0;
    left: 0;
    top: 60px;
    position: fixed;
    height: 95vh;
    width: 100vw;
    z-index: 20;
  }

  .menu-item {
    color: #8a96b2;
    cursor: pointer;
    padding: 13px 12px;
    text-align: center;

    label {
      display: block;
      font-size: 11px;
    }
  }

  .menu-item-search {
    width: 40vw;
    min-width: 40%;
    max-width: 585px;
    margin: auto 12px;
  }

  [class^='ft-'],
  [class*=' ft-'] {
    font-family: 'r360' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ft-earth,
  .ft-feed,
  .ft-list2,
  .ft-map_view {
    font-size: 16px;
  }
  .ft-map_view:before {
    content: '\\e83c';
  }
  .ft-earth:before {
    content: '\\e9e1';
  }
  .ft-list2:before {
    content: '\\e9cf';
  }
  .ft-feed:before {
    content: '\\e931';
  }
  .supply-chain-icon {
    width: 15px;
    height: 15px;
    margin-bottom: 4px;
  }
`;
export default NavigationMenu;
