import React from 'react';
import styled from '@emotion/styled';
import { Button, Tags } from '@luxe/components';
import asset_icon_generic_low from 'layout/icons/asset_icon_generic_low.png';
import asset_icon_generic_medium from 'layout/icons/asset_icon_generic_medium.png';
import asset_icon_generic_high from 'layout/icons/asset_icon_generic_high.png';
import { flexbox } from 'styled-system';
import theme from 'theme';
import { Tabs as aTabs } from 'antd';

export const AlternativeIDs = ({ altIds, ...props }) => {
  const DataTitle = styled.dt`
    color: ${({ theme }) => theme.colors.lightGrey} !important;
    height: 24px;
    width: max-content;
    text-align: left;
    font-family: ${props => props.theme.fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  `;

  const Data = styled.dd`
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.65) !important;
    margin-left: 8px;
    &:nth-of-type(1) {
      margin-left: 0;
    }
  `;

  const DataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

  return (
    <dl>
      <DataTitle>Alternative IDs:</DataTitle>
      <DataContainer>
        {altIds &&
          Object.entries(altIds).map(id => (
            <Data key={id[0]}>
              {id[0]} : {id[1]}
            </Data>
          ))}
      </DataContainer>
    </dl>
  );
};

export const IndustryTags = ({ tags, ...props }) => {
  const TagTitle = styled.h3`
    font-size: 16px;
    color: #bfbfbf !important;
    font-weight: 400;
    line-height: 24px;
  `;

  const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
  `;

  return (
    <TagContainer>
      <TagTitle>Tags:</TagTitle>
      {tags.map(tag => (
        <Tags key={tag} style={{ marginLeft: '8px' }} tagName={tag} />
      ))}
    </TagContainer>
  );
};

export const HQLocation = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.85) !important;
  width: max-content;
`;

export const AssetTypeSpan = styled.span`
  color: ${({ theme }) => theme.colors.primary} !important;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 10.5px;
`;

const iconMap = {
  low: asset_icon_generic_low,
  medium: asset_icon_generic_medium,
  high: asset_icon_generic_high,
};

export function getAssetIcon(category) {
  return category && iconMap[category] ? iconMap[category] : asset_icon_generic_low;
}

export const AssetType = ({ category }) => {
  return (
    <AssetTypeSpan>
      <img src={getAssetIcon(category)} alt="asset" style={{ marginRight: '2px' }} />
      ASSET
    </AssetTypeSpan>
  );
};

export const DescriptionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lightGrey} !important;
  height: 24px;
  width: 81px;
  text-align: left;
  font-family: ${props => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const AltIDTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lightGrey} !important;
  height: 24px;
  text-align: left;
  font-family: ${props => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const AltIDText = styled.p`
  color: rgba(255, 255, 255, 0.85) !important;
  font-family: ${props => props.theme.fonts.body};
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  .kvp {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.85) !important;
    margin: 0 5px 0 5px;
  }
`;

export const DescriptionText = styled.p`
  color: rgba(255, 255, 255, 0.85) !important;
  font-family: ${props => props.theme.fonts.body};
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 4px;
`;

export const AssetTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1px;
`;

export const ShowOnMap = styled(Button)`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  letter-spacing: 0em;
  line-height: 20px;
  text-align: center;
  width: 188px;
  width: 188px;
`;

export const ActionButton = styled(Button)`
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: center;
  width: 188px;
`;

export const RiskChart = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
`;

export const Risk = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: column;
  &:nth-of-type(1) {
    margin-right: 4px;
  }
`;

export const Indices = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;

export const Title = styled.div`
  ${flexbox}
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  font: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 5px;
`;

export const Score = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.contentTitle}px;
  font: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.risk};
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: center;
  background-color: ${({ category }) => RiskBackground(category)};
  border-radius: 4px;
  padding: 3px 36px 3px 36px;
  margin: auto;
  width: 86px;
  height: 43px;
`;

export const Indice = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
  width: 42px;
  height: 18px;
  border-radius: 4px;
  padding: 0 10px 0 10px;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  font: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ category }) => RiskBackground(category)};
  &:nth-of-type(1) {
    margin-bottom: 4px;
  }
`;

export const IndiceScore = styled.span`
  ${flexbox}
  height: 37px;
  width: 39.50617218017578px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.contentTitle}px;
  font: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${props => props.theme.fonts.risk};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const RiskPills = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
  font-size: 12px;
  .pill-link {
    margin: 0 2px;
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid transparent;
    text-align: center;
    color: #fff !important;
    &:hover {
      background-color: #38b1cc;
      color: #fff !important;
      cursor: pointer;
    }
  }
  .active {
    background-color: #246472;
    color: #fff !important;
  }
`;

export const RiskBackground = category => {
  switch (category) {
    case 'low':
      return theme.colors.lowRisk;
    case 'medium':
      return theme.colors.medRisk;
    case 'high':
      return theme.colors.highRisk;
    default:
      return '';
  }
};

export const ChartLabel = ({ value, indexName, onClick }) => {
  const Label = styled.div`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small}px !important;
    padding: 0 5px;

    & > p {
      display: contents;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: ${theme.colors.primaryHover};
      border-radius: 2px;
    }
  `;
  return (
    <Label data-index={indexName} className={'risk-spider-chart-label'}>
      <p>{value}</p>
    </Label>
  );
};

export const Benchmark = ({ value = '-' }) => {
  const BenchmarkContainer = styled.div`
    span.slider-label {
      color: ${({ theme }) => theme.colors.lightGrey};
      margin-right: ${({ theme }) => theme.space.xxsmall}px;
    }
    span.slider-value {
      font-weight: ${theme.fontWeights.bold};
    }
  `;
  return (
    <BenchmarkContainer className="slider-benchmark-container">
      <span className="slider-benchmark slider-label">Benchmark:</span>
      <span className="slider-benchmark slider-value">{value}</span>
    </BenchmarkContainer>
  );
};

export const ActiveThreatContainer = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: column;
  margin: 1px 15px 15px 15px;
  text-align: center;
  h3 {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small}px;
    font: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.lightGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .score-values {
    font: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.risk};
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
    padding: 3px 36px 3px 36px;
    height: 43px;
  }
`;

export const DateRangePills = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
  font-size: 12px;
  .pill-link {
    margin: 0 2px;
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid transparent;
    text-align: center;
    color: #fff !important;
    &:hover {
      background-color: #38b1cc;
      color: #fff !important;
      cursor: pointer;
    }
  }
  .active {
    background-color: #246472;
    color: #fff !important;
  }
`;

export const Tabs = styled(aTabs)`
  color: #fff;
  .ant-tabs-tab {
    &:hover {
      color: #38b1cc;
    }
  }
  .ant-tabs-tab-btn {
    &:active {
      color: #fff !important;
    }
  }
  .ant-tabs-tab-active {
    div {
      color: #38b1cc !important;
    }
  }
  .ant-tabs-ink-bar {
    background: #38b1cc !important;
  }
`;

export const TabPane = aTabs.TabPane;
