export const usa = {
  type: 'MultiPolygon',
  coordinates: [
    [
      [
        [-122.84, 49.00000000000011],
        [-120, 49.00000000000011],
        [-117.03121, 49],
        [-116.04818, 49],
        [-113, 49],
        [-110.05, 49],
        [-107.05, 49],
        [-104.04826, 48.99986000000006],
        [-100.65, 49.00000000000011],
        [-97.22872000000479, 49.0007],
        [-95.15906950917205, 49],
        [-95.15609, 49.38425],
        [-94.81758000000002, 49.38904999999999],
        [-94.64, 48.84],
        [-94.32914000000001, 48.67074],
        [-93.63087000000001, 48.60926],
        [-92.61000000000001, 48.44999999999993],
        [-91.64, 48.14],
        [-90.82999999999999, 48.27],
        [-89.60000000000002, 48.0100000000001],
        [-89.27291744663665, 48.01980825458281],
        [-88.37811418328671, 48.3029175888937],
        [-87.43979262330027, 47.93999999999999],
        [-86.46199083122826, 47.553338019392],
        [-85.65236324740341, 47.22021881773051],
        [-84.87607988151489, 46.90008331968238],
        [-84.77923824739991, 46.63710195574901],
        [-84.54374874544583, 46.53868419044914],
        [-84.60490000000004, 46.43959999999998],
        [-84.3367, 46.40877],
        [-84.1421195136734, 46.5122258571157],
        [-84.0918512641615, 46.27541860613826],
        [-83.89076534700574, 46.11692698829901],
        [-83.61613094759059, 46.11692698829901],
        [-83.46955074739469, 45.99468638771253],
        [-83.5928507148431, 45.81689362241252],
        [-82.55092464875821, 45.34751658790543],
        [-82.33776312543113, 44.44000000000005],
        [-82.13764238150395, 43.57108755143997],
        [-82.42999999999999, 42.98],
        [-82.9, 42.43],
        [-83.12, 42.07999999999999],
        [-83.14199968131264, 41.97568105729287],
        [-83.02981014680699, 41.83279572200598],
        [-82.69008928092023, 41.67510508886732],
        [-82.43927771679159, 41.67510508886732],
        [-81.27774654816715, 42.20902598730681],
        [-80.24744767934794, 42.36619985612254],
        [-78.93936214874375, 42.86361135514798],
        [-78.92, 42.96499999999999],
        [-79.01, 43.27],
        [-79.17167355011186, 43.46633942318425],
        [-78.72027991404235, 43.62508942318493],
        [-77.7378850979577, 43.62905558936328],
        [-76.82003414580557, 43.62878428809375],
        [-76.50000000000001, 44.01845889375864],
        [-76.375, 44.09631],
        [-75.31821, 44.81645],
        [-74.86700000000001, 45.00048000000009],
        [-73.34783, 45.00737999999999],
        [-71.50506, 45.0082],
        [-71.405, 45.25499999999999],
        [-71.08482000000003, 45.30524000000014],
        [-70.65999999999999, 45.46],
        [-70.305, 45.91499999999999],
        [-69.99997, 46.69306999999999],
        [-69.237216, 47.44778099999999],
        [-68.90500000000003, 47.18500000000005],
        [-68.23444, 47.35485999999997],
        [-67.79046000000001, 47.06635999999999],
        [-67.79134, 45.70281],
        [-67.13741, 45.13752999999999],
        [-66.96465999999998, 44.80970000000013],
        [-68.03251999999997, 44.32519999999999],
        [-69.05999999999994, 43.98000000000007],
        [-70.11616999999995, 43.68405000000012],
        [-70.64547563341102, 43.09023834896402],
        [-70.81488999999999, 42.86529999999993],
        [-70.82499999999998, 42.33499999999998],
        [-70.49499999999994, 41.805],
        [-70.07999999999998, 41.78000000000003],
        [-70.185, 42.14500000000009],
        [-69.88496999999995, 41.92283000000009],
        [-69.96502999999995, 41.63717000000014],
        [-70.63999999999998, 41.47500000000002],
        [-71.12039000000004, 41.49445000000014],
        [-71.8599999999999, 41.32000000000005],
        [-72.29500000000001, 41.26999999999998],
        [-72.87643000000002, 41.22065000000003],
        [-73.71000000000003, 40.93110235165448],
        [-72.24125999999995, 41.11948000000012],
        [-71.94499999999987, 40.93000000000006],
        [-73.34499999999997, 40.63000000000005],
        [-73.98200000000002, 40.62799999999992],
        [-73.95232499999997, 40.75075000000003],
        [-74.25670999999999, 40.47351000000003],
        [-73.96243999999995, 40.42763000000002],
        [-74.17838, 39.70925999999997],
        [-74.90603999999996, 38.93954000000002],
        [-74.98041, 39.19640000000004],
        [-75.20001999999999, 39.2484500000001],
        [-75.52805, 39.49850000000009],
        [-75.31999999999999, 38.96000000000003],
        [-75.07183476478985, 38.78203223017925],
        [-75.05672999999995, 38.40412000000009],
        [-75.37746999999996, 38.01551],
        [-75.94022999999998, 37.21689000000009],
        [-76.03126999999995, 37.25659999999999],
        [-75.72204999999985, 37.93705000000011],
        [-76.23286999999999, 38.31921499999998],
        [-76.35000000000002, 39.14999999999997],
        [-76.54272499999996, 38.71761500000008],
        [-76.32933000000002, 38.08326000000005],
        [-76.98999793161351, 38.23999176691336],
        [-76.30161999999995, 37.91794499999991],
        [-76.25873999999998, 36.96640000000007],
        [-75.97179999999997, 36.89726000000001],
        [-75.86803999999989, 36.55125000000003],
        [-75.72748999999998, 35.55074000000013],
        [-76.36318, 34.8085400000001],
        [-77.39763499999992, 34.51200999999997],
        [-78.05495999999999, 33.92547000000001],
        [-78.55434999999988, 33.86133000000012],
        [-79.06067000000001, 33.49394999999998],
        [-79.20357000000001, 33.15839000000005],
        [-80.30132499999996, 32.50935500000008],
        [-80.86498, 32.03330000000005],
        [-81.33629000000002, 31.44049000000001],
        [-81.49041999999997, 30.7299900000001],
        [-81.31371000000001, 30.03552000000007],
        [-80.97999999999996, 29.18000000000012],
        [-80.53558499999991, 28.47212999999999],
        [-80.52999999999985, 28.04000000000007],
        [-80.05653928497758, 26.8800000000001],
        [-80.08801499999998, 26.20576499999998],
        [-80.13155999999992, 25.81677500000006],
        [-80.38103000000001, 25.20616000000001],
        [-80.67999999999995, 25.08000000000004],
        [-81.17212999999998, 25.2012600000001],
        [-81.33000000000004, 25.63999999999998],
        [-81.70999999999986, 25.87],
        [-82.23999999999995, 26.73000000000013],
        [-82.70515, 27.49504000000007],
        [-82.85525999999998, 27.88624000000004],
        [-82.64999999999997, 28.55000000000012],
        [-82.92999999999995, 29.10000000000008],
        [-83.70958999999999, 29.93656000000004],
        [-84.09999999999996, 30.09000000000008],
        [-85.10881999999998, 29.63615000000004],
        [-85.28784000000001, 29.68612000000013],
        [-85.77309999999999, 30.15261000000009],
        [-86.39999999999992, 30.40000000000009],
        [-87.53035999999991, 30.27433000000002],
        [-88.41781999999994, 30.38490000000001],
        [-89.1804899999999, 30.31598000000002],
        [-89.5938311784198, 30.15999400483684],
        [-89.41373499999997, 29.89418999999998],
        [-89.43, 29.48863999999997],
        [-89.21766999999999, 29.29108000000002],
        [-89.40822999999994, 29.15961000000004],
        [-89.77927999999997, 29.30714000000011],
        [-90.15462999999999, 29.11743000000007],
        [-90.88022499999993, 29.14853500000009],
        [-91.62678499999992, 29.67700000000013],
        [-92.49905999999998, 29.5523],
        [-93.22636999999997, 29.78375000000005],
        [-93.84841999999997, 29.71363000000008],
        [-94.68999999999999, 29.48000000000013],
        [-95.60025999999999, 28.73863000000005],
        [-96.59403999999995, 28.30748000000005],
        [-97.13999999999987, 27.83000000000004],
        [-97.36999999999994, 27.38000000000005],
        [-97.37999999999993, 26.69000000000005],
        [-97.32999999999998, 26.21000000000009],
        [-97.13999999999987, 25.87],
        [-97.52999999999991, 25.84000000000008],
        [-98.23999999999995, 26.06000000000005],
        [-99.01999999999992, 26.37000000000006],
        [-99.30000000000001, 26.84000000000003],
        [-99.51999999999992, 27.54000000000002],
        [-100.1099999999999, 28.11000000000012],
        [-100.45584, 28.69612000000012],
        [-100.9575999999999, 29.38071000000013],
        [-101.6623999999999, 29.77930000000009],
        [-102.48, 29.75999999999999],
        [-103.11, 28.97000000000002],
        [-103.9399999999999, 29.27000000000003],
        [-104.4569699999998, 29.57196000000004],
        [-104.7057499999999, 30.12173000000001],
        [-105.03737, 30.64401999999995],
        [-105.63159, 31.08383000000009],
        [-106.1428999999999, 31.39995000000004],
        [-106.5075899999998, 31.75452000000001],
        [-108.24, 31.75485371816637],
        [-108.2419399999999, 31.34222000000005],
        [-109.035, 31.34194000000013],
        [-111.02361, 31.33471999999994],
        [-113.30498, 32.03914000000008],
        [-114.8149999999999, 32.52528],
        [-114.7213899999999, 32.72082999999992],
        [-115.9913499999999, 32.61239000000011],
        [-117.1277599999998, 32.53533999999996],
        [-117.2959376912739, 33.04622461520386],
        [-117.944, 33.62123643120139],
        [-118.4106022758975, 33.74090922312444],
        [-118.5198948227997, 34.02778157757575],
        [-119.081, 34.07799999999991],
        [-119.4388406420167, 34.34847717828427],
        [-120.3677799999999, 34.44711000000006],
        [-120.62286, 34.60854999999998],
        [-120.7443299999999, 35.1568600000001],
        [-121.7145699999999, 36.16152999999991],
        [-122.5474699999999, 37.55176000000011],
        [-122.51201, 37.78339000000011],
        [-122.95319, 38.11371000000008],
        [-123.7272, 38.95166000000011],
        [-123.8651699999999, 39.76699000000007],
        [-124.39807, 40.31319999999999],
        [-124.1788599999999, 41.14202000000011],
        [-124.2137, 41.99964000000011],
        [-124.5328399999999, 42.7659900000001],
        [-124.1421399999999, 43.70838000000003],
        [-124.0205349999999, 44.61589499999996],
        [-123.8989299999999, 45.52341000000006],
        [-124.0796349999999, 46.86475000000001],
        [-124.3956699999999, 47.72017000000011],
        [-124.6872100830078, 48.18443298339855],
        [-124.5661010742187, 48.37971496582036],
        [-123.12, 48.04000000000002],
        [-122.5873599999999, 47.09600000000006],
        [-122.34, 47.36000000000001],
        [-122.5, 48.18000000000006],
        [-122.84, 49.00000000000011],
      ],
    ],
    [
      [
        [-155.40214, 20.07975],
        [-155.22452, 19.99302],
        [-155.06226, 19.8591],
        [-154.80741, 19.50871],
        [-154.8314699999999, 19.45328],
        [-155.22217, 19.23971999999999],
        [-155.54211, 19.08348],
        [-155.68817, 18.91619],
        [-155.9366499999999, 19.05939],
        [-155.90806, 19.33888],
        [-156.07347, 19.70294],
        [-156.02368, 19.81421999999999],
        [-155.85008, 19.97729],
        [-155.91907, 20.17395],
        [-155.86108, 20.26721],
        [-155.78505, 20.24869999999999],
        [-155.40214, 20.07975],
      ],
    ],
    [
      [
        [-155.99566, 20.76404],
        [-156.07926, 20.64397],
        [-156.4144499999999, 20.57241],
        [-156.5867299999999, 20.783],
        [-156.70167, 20.8643],
        [-156.7105499999999, 20.92676],
        [-156.61258, 21.01249],
        [-156.25711, 20.91745],
        [-155.99566, 20.76404],
      ],
    ],
    [
      [
        [-156.75824, 21.17684],
        [-156.78933, 21.06873],
        [-157.3252099999999, 21.09777],
        [-157.25027, 21.21957999999999],
        [-156.75824, 21.17684],
      ],
    ],
    [
      [
        [-158.0252, 21.71696],
        [-157.9416099999999, 21.65271999999999],
        [-157.65283, 21.32217],
        [-157.70703, 21.26442],
        [-157.7786, 21.27729],
        [-158.12667, 21.31243999999999],
        [-158.2538, 21.53919],
        [-158.29265, 21.57912],
        [-158.0252, 21.71696],
      ],
    ],
    [
      [
        [-159.36569, 22.21493999999999],
        [-159.34512, 21.982],
        [-159.4637199999999, 21.88298999999999],
        [-159.80051, 22.06533],
        [-159.74877, 22.1382],
        [-159.5962, 22.23617999999999],
        [-159.36569, 22.21493999999999],
      ],
    ],
    [
      [
        [-166.4677921214246, 60.38416982689775],
        [-165.6744296946636, 60.29360687930625],
        [-165.5791641917335, 59.90998688418753],
        [-166.1927701487672, 59.75444082298899],
        [-166.8483373688219, 59.94140615502098],
        [-167.45527706609, 60.21306915957936],
        [-166.4677921214246, 60.38416982689775],
      ],
    ],
    [
      [
        [-153.2287294179211, 57.96896841087247],
        [-152.5647906158351, 57.90142731386699],
        [-152.1411472239063, 57.59105866152199],
        [-153.0063140533369, 57.11584219016592],
        [-154.0050902984581, 56.73467682558107],
        [-154.51640275777, 56.99274892844668],
        [-154.6709928049711, 57.46119578717252],
        [-153.7627795074415, 57.81657461204373],
        [-153.2287294179211, 57.96896841087247],
      ],
    ],
    [
      [
        [-140.985987610376, 69.71199839952635],
        [-140.9859999999999, 69.712],
        [-140.9925, 66.00003000000001],
        [-140.99778, 60.30639],
        [-140.013, 60.27682],
        [-139.039, 60],
        [-138.34089, 59.56211],
        [-137.4524999999999, 58.905],
        [-136.47972, 59.46389000000004],
        [-135.47583, 59.78778],
        [-134.945, 59.2705600000001],
        [-134.27111, 58.86111000000005],
        [-133.35556, 58.41028],
        [-132.73042, 57.69289],
        [-131.7078099999999, 56.55212],
        [-130.00778, 55.91583000000008],
        [-129.9799999999999, 55.285],
        [-130.53611, 54.80278],
        [-130.5361089527368, 54.80275447679923],
        [-130.5361101894673, 54.8027534043494],
        [-131.0858182379721, 55.17890615500203],
        [-131.9672114671423, 55.497775580459],
        [-132.2500107428595, 56.3699962428974],
        [-133.5391810843564, 57.17888743756213],
        [-134.078062920296, 58.12306753196691],
        [-135.038211032279, 58.18771474876393],
        [-136.6280623099547, 58.21220937767043],
        [-137.8000062796859, 58.49999542910376],
        [-139.867787041413, 59.53776154238914],
        [-140.8252738171329, 59.72751740176505],
        [-142.5744435355644, 60.08444651960496],
        [-143.9588809948799, 59.99918040632337],
        [-145.9255568168278, 60.45860972761426],
        [-147.1143739491466, 60.88465607364463],
        [-148.2243062001276, 60.67298940697713],
        [-148.0180655588508, 59.97832896589363],
        [-148.5708225168608, 59.9141726752033],
        [-149.7278578358758, 59.70565827090553],
        [-150.6082433746164, 59.36821116803946],
        [-151.7163927886832, 59.15582103131993],
        [-151.8594331532672, 59.74498403587955],
        [-151.4097190012471, 60.72580272077937],
        [-150.3469414947325, 61.03358755150986],
        [-150.621110806257, 61.28442495385439],
        [-151.8958391998168, 60.72719798445126],
        [-152.5783298410955, 60.06165721296423],
        [-154.0191721262576, 59.35027944603427],
        [-153.2875113596531, 58.86472768821977],
        [-154.2324924387584, 58.14637360293051],
        [-155.3074914215102, 57.7277945013663],
        [-156.308334723923, 57.42277435976359],
        [-156.5560973785463, 56.97998484967064],
        [-158.1172165598677, 56.46360809999419],
        [-158.4333212961971, 55.99415355083851],
        [-159.6033273997174, 55.56668610292013],
        [-160.2897196116342, 55.64358063417057],
        [-161.2230476552577, 55.36473460552349],
        [-162.237766079741, 55.02418691672011],
        [-163.0694465810463, 54.68973704692712],
        [-164.7855692210271, 54.40417308208213],
        [-164.94222632552, 54.57222483989534],
        [-163.8483396067656, 55.03943146424609],
        [-162.8700013906159, 55.34804311789321],
        [-161.804174974596, 55.89498647727037],
        [-160.5636047027811, 56.00805451112501],
        [-160.0705598622844, 56.41805532492873],
        [-158.6844429189195, 57.01667511659786],
        [-158.461097378554, 57.21692129172885],
        [-157.7227703521839, 57.57000051536306],
        [-157.5502744211936, 58.3283263210302],
        [-157.0416749745769, 58.91888458926172],
        [-158.1947312083055, 58.61580231386977],
        [-158.517217984023, 58.78778148053731],
        [-159.0586061269287, 58.42418610293162],
        [-159.7116670400173, 58.93139028587631],
        [-159.9812888255001, 58.57254914004164],
        [-160.3552711659964, 59.07112335879361],
        [-161.355003425115, 58.67083771426075],
        [-161.9688936025263, 58.67166453717737],
        [-162.0549865387246, 59.26692536074745],
        [-161.8741707021353, 59.63362132429057],
        [-162.5180590484921, 59.98972361921386],
        [-163.8183414378202, 59.79805573184336],
        [-164.6622175771465, 60.26748444278263],
        [-165.3463877024748, 60.50749563256238],
        [-165.3508318756518, 61.0738951686975],
        [-166.121379157556, 61.50001902937623],
        [-165.7344518707705, 62.07499685327178],
        [-164.9191786367179, 62.63307648380794],
        [-164.5625079010393, 63.14637848576302],
        [-163.753332485997, 63.21944896102377],
        [-163.0672244944578, 63.05945872664801],
        [-162.2605553863817, 63.54193573674115],
        [-161.5344498362486, 63.45581696232676],
        [-160.7725066803211, 63.76610810002324],
        [-160.9583351308426, 64.22279857040274],
        [-161.5180684072121, 64.40278758407527],
        [-160.7777776764148, 64.78860382756641],
        [-161.3919262359876, 64.77723501246231],
        [-162.4530500966689, 64.55944468856819],
        [-162.7577860178941, 64.33860545516876],
        [-163.5463942128842, 64.55916046819049],
        [-164.9608298411451, 64.44694509546883],
        [-166.4252882558644, 64.68667206487066],
        [-166.8450042389391, 65.08889557561451],
        [-168.1105600657671, 65.66999705673674],
        [-166.7052711660219, 66.08831777613937],
        [-164.4747096425754, 66.5766600612975],
        [-163.6525117665956, 66.5766600612975],
        [-163.7886016510362, 66.07720734319667],
        [-161.6777744212101, 66.11611969671241],
        [-162.48971452538, 66.73556509059511],
        [-163.7197169667911, 67.11639455837007],
        [-164.4309913808565, 67.61633820257776],
        [-165.3902868317067, 68.04277212185024],
        [-166.764440680996, 68.35887685817965],
        [-166.2047074046266, 68.88303091091614],
        [-164.4308105133434, 68.91553538682774],
        [-163.1686136546144, 69.37111481391286],
        [-162.9305661692619, 69.85806183539926],
        [-161.9088972646355, 70.33332998318763],
        [-160.9347965159336, 70.44768992784958],
        [-159.0391757883871, 70.89164215766891],
        [-158.1197228668339, 70.82472117785101],
        [-156.580824551398, 71.35776357694175],
        [-155.0677902903242, 71.14777639432367],
        [-154.3441652089412, 70.69640859647017],
        [-153.9000062733925, 70.88998851183566],
        [-152.2100060699352, 70.82999217394484],
        [-152.2700024078261, 70.60000621202982],
        [-150.7399924387444, 70.43001658800568],
        [-149.7200030181674, 70.53001048449044],
        [-147.613361579357, 70.21403493924179],
        [-145.6899898002253, 70.12000967068672],
        [-144.9200109590763, 69.98999176704046],
        [-143.5894461804252, 70.15251414659832],
        [-142.0725103487134, 69.85193817817264],
        [-140.9859875215607, 69.71199839952635],
        [-140.985987610376, 69.71199839952635],
      ],
    ],
    [
      [
        [-171.7316568675394, 63.78251536727593],
        [-171.1144335602452, 63.59219106714495],
        [-170.4911124339407, 63.6949754909735],
        [-169.6825054596536, 63.43111562769119],
        [-168.6894394603006, 63.29750621200055],
        [-168.7719408844546, 63.18859813094543],
        [-169.529439867205, 62.97693146427791],
        [-170.2905562002159, 63.19443756779442],
        [-170.6713856679909, 63.3758218451389],
        [-171.5530631175387, 63.3177892116751],
        [-171.7911106028912, 63.40584585230045],
        [-171.7316568675394, 63.78251536727593],
      ],
    ],
  ],
};

export const nzl = {
  type: 'MultiPolygon',
  coordinates: [
    [
      [
        [176.8858236026052, -40.0659778785822],
        [176.5080172061192, -40.60480803808957],
        [176.0124402204402, -41.28962411882147],
        [175.2395674990829, -41.68830779395327],
        [175.0678983910093, -41.42589487077513],
        [174.6509729352784, -41.2818209775454],
        [175.2276302432235, -40.45923552832336],
        [174.9001566917899, -39.9089332008472],
        [173.8240466657439, -39.50885426204351],
        [173.8522619977753, -39.14660247167748],
        [174.5748018740803, -38.79768320084275],
        [174.7434737490809, -38.02780771255842],
        [174.6970166364505, -37.38112883885791],
        [174.2920284365791, -36.71109221776149],
        [174.3190035342355, -36.53482390721391],
        [173.8409965355357, -36.12198088963413],
        [173.0541711774596, -35.23712533950038],
        [172.6360054873537, -34.52910654066943],
        [173.0070422712094, -34.45066171645036],
        [173.5512984561074, -35.006183363588],
        [174.3293904971262, -35.26549570082862],
        [174.6120089053304, -36.15639739354052],
        [175.3366158389271, -37.20909799575827],
        [175.3575964704376, -36.52619394302117],
        [175.8088867536425, -36.79894215265767],
        [175.9584900251275, -37.55538176854612],
        [176.7631954287765, -37.88125335057867],
        [177.4388131045604, -37.96124846776649],
        [178.0103544457086, -37.57982472102017],
        [178.5170935407627, -37.69537322362479],
        [178.2747310733138, -38.58281259537314],
        [177.9704602399792, -39.16634286881297],
        [177.2069926292991, -39.14577564876081],
        [176.939980503647, -39.44973642350161],
        [177.0329464053401, -39.87994272233146],
        [176.8858236026052, -40.0659778785822],
      ],
    ],
    [
      [
        [169.6678145693731, -43.55532561622637],
        [170.5249198753661, -43.03168832781281],
        [171.125089960004, -42.51275359473782],
        [171.5697139834432, -41.76742441179213],
        [171.9487089378718, -41.51441659929112],
        [172.0972270042786, -40.95610442480971],
        [172.798579543344, -40.4939620908235],
        [173.0203747907407, -40.91905242285644],
        [173.247234328502, -41.33199879330081],
        [173.9584053897027, -40.92670053483564],
        [174.2475867048081, -41.34915536882171],
        [174.2485168805894, -41.77000823340672],
        [173.8764465680879, -42.23318409603879],
        [173.2227396995957, -42.97003834408861],
        [172.7112463727707, -43.37228769304855],
        [173.0801127464701, -43.8533436012536],
        [172.3085836123524, -43.86569426857136],
        [171.4529252464636, -44.24251881284369],
        [171.1851379743271, -44.89710418068486],
        [170.6166972191165, -45.90892872495973],
        [169.8314221540092, -46.35577483498757],
        [169.3323311709342, -46.64123544696787],
        [168.4113537946285, -46.61994475686363],
        [167.7637447451468, -46.29019744240918],
        [166.6768860211841, -46.21991749449223],
        [166.5091443219646, -45.85270476662618],
        [167.0464241885032, -45.11094125750863],
        [168.3037634625968, -44.12397307716614],
        [168.9494088076515, -43.93581918719143],
        [169.6678145693731, -43.55532561622637],
      ],
    ],
  ],
};

export const rus = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [107.05078125, 77.69287033641928],
            [106.69921875, 78.1344931829381],
            [105.8203125, 79.13826033168402],
            [104.23828125, 79.46455976888949],
            [102.12890625, 79.71860546904045],
            [99.66796875, 80.70399666821143],
            [97.03125, 81.36128726057069],
            [94.21875, 81.30832090051811],
            [90.87890625, 80.84490140679905],
            [89.6484375, 80.4157074446218],
            [90.17578124999999, 79.78116424879934],
            [92.63671875, 79.13826033168402],
            [97.734375, 78.69910592550542],
            [98.4375, 78.27820145542813],
            [98.0859375, 77.46602847687329],
            [96.6796875, 77.0394184427303],
            [94.5703125, 76.84081641443098],
            [93.69140625, 76.434603583513],
            [90.87890625, 76.18499546094715],
            [88.41796875, 75.88809074612949],
            [85.4296875, 75.58493740869223],
            [83.84765625, 74.59010800882325],
            [81.9140625, 74.01954331150228],
            [79.8046875, 73.67726447634907],
            [78.046875, 73.02259157147301],
            [75.9375, 72.97118902284586],
            [73.30078125, 73.3782147793946],
            [71.19140625, 73.72659470212253],
            [68.90625, 73.67726447634907],
            [67.32421875, 72.28906720017675],
            [66.26953125, 71.30079291637452],
            [65.56640625, 70.25945200030638],
            [64.51171875, 69.83962194067463],
            [62.22656249999999, 70.67088107015755],
            [58.53515625, 70.95969716686398],
            [57.30468749999999, 72.39570570653261],
            [60.64453125000001, 74.01954331150228],
            [64.6875, 75.14077784070429],
            [68.73046875, 75.27541260821627],
            [70.48828125, 75.62863223279015],
            [71.19140625, 77.35106958476409],
            [63.984375, 76.80073870685207],
            [59.4140625, 76.3518964311259],
            [55.72265625, 75.62863223279015],
            [53.4375, 74.49641311694307],
            [51.328125, 73.42842364106816],
            [50.09765625, 71.85622888185527],
            [54.140625, 70.31873847853124],
            [53.61328124999999, 69.2249968541159],
            [50.09765625, 69.65708627301174],
            [47.63671875, 69.53451763078358],
            [47.109375, 68.5924865825295],
            [43.2421875, 68.97416358340674],
            [43.76953125, 67.80924450600011],
            [41.8359375, 67.47492238478702],
            [40.42968749999999, 68.07330474079025],
            [38.67187499999999, 69.03714171275197],
            [32.87109375, 69.90011762668541],
            [29.8828125, 69.16255790810501],
            [29.70703125, 68.39918004344189],
            [30.761718749999996, 67.7427590666639],
            [29.355468750000004, 67.13582938531948],
            [29.70703125, 65.58572002329473],
            [30.234375, 64.32087157990324],
            [31.46484375, 63.23362741232569],
            [30.05859375, 61.3546135846894],
            [28.4765625, 60.06484046010452],
            [28.30078125, 58.63121664342478],
            [28.30078125, 57.42129439209407],
            [29.53125, 56.46249048388979],
            [30.937499999999996, 55.47885346331034],
            [33.57421875, 52.908902047770255],
            [34.98046875, 51.72702815704774],
            [36.38671875, 50.401515322782366],
            [40.25390625, 49.49667452747045],
            [39.375, 47.87214396888731],
            [37.265625, 46.07323062540835],
            [38.3203125, 44.59046718130883],
            [41.1328125, 43.45291889355465],
            [46.05468749999999, 42.68243539838623],
            [47.28515625, 43.068887774169625],
            [47.4609375, 45.089035564831036],
            [48.515625, 46.07323062540835],
            [47.28515625, 47.27922900257082],
            [45.87890625, 48.80686346108517],
            [46.7578125, 50.958426723359935],
            [47.63671875, 51.72702815704774],
            [52.20703125, 52.3755991766591],
            [54.66796875, 51.28940590271679],
            [57.30468749999999, 51.17934297928927],
            [59.765625, 50.736455137010665],
            [60.46875, 51.72702815704774],
            [59.94140624999999, 53.4357192066942],
            [60.64453125000001, 54.059387886623576],
            [62.57812500000001, 54.16243396806779],
            [65.91796875, 54.57206165565852],
            [68.02734375, 55.07836723201515],
            [70.13671875, 55.27911529201561],
            [72.59765625, 53.64463782485651],
            [74.35546875, 53.225768435790194],
            [74.70703125, 54.57206165565852],
            [76.9921875, 54.36775852406841],
            [81.2109375, 51.83577752045248],
            [82.265625, 51.17934297928927],
            [83.3203125, 51.17934297928927],
            [85.60546875, 50.064191736659104],
            [87.01171875, 49.83798245308484],
            [89.296875, 50.064191736659104],
            [92.10937499999999, 50.84757295365389],
            [93.1640625, 50.17689812200107],
            [95.625, 50.064191736659104],
            [97.91015624999999, 50.17689812200107],
            [97.55859375, 51.28940590271679],
            [99.140625, 52.26815737376817],
            [101.6015625, 51.508742458803326],
            [102.65625, 50.064191736659104],
            [105.8203125, 50.51342652633956],
            [108.10546875, 49.83798245308484],
            [110.390625, 49.49667452747045],
            [112.67578124999999, 49.95121990866204],
            [114.08203125, 50.401515322782366],
            [115.6640625, 49.83798245308484],
            [117.59765625, 49.61070993807422],
            [118.65234374999999, 50.064191736659104],
            [119.35546875000001, 50.958426723359935],
            [120.41015624999999, 51.72702815704774],
            [120.9375, 52.26815737376817],
            [119.88281249999999, 52.802761415419674],
            [120.9375, 53.330872983017066],
            [123.04687499999999, 53.74871079689897],
            [124.98046874999999, 53.330872983017066],
            [126.21093749999999, 52.05249047600099],
            [127.08984375000001, 50.62507306341435],
            [128.49609375, 49.83798245308484],
            [129.90234375, 49.26780455063753],
            [130.60546875, 48.10743118848039],
            [131.8359375, 47.754097979680026],
            [133.41796874999997, 48.10743118848039],
            [134.47265625, 48.45835188280866],
            [134.12109375, 47.27922900257082],
            [133.9453125, 46.07323062540835],
            [132.71484375, 45.336701909968134],
            [131.1328125, 45.213003555993964],
            [131.30859375, 43.83452678223682],
            [132.36328125, 42.4234565179383],
            [134.47265625, 42.4234565179383],
            [136.58203125, 44.08758502824516],
            [138.1640625, 45.583289756006316],
            [139.921875, 47.517200697839414],
            [140.80078125, 48.69096039092549],
            [141.328125, 49.95121990866204],
            [141.85546875, 52.16045455774706],
            [142.20703125, 53.12040528310657],
            [143.96484375, 53.54030739150022],
            [143.61328125, 54.87660665410869],
            [142.03125, 54.265224078605684],
            [140.625, 54.16243396806779],
            [139.39453125, 54.57206165565852],
            [138.69140625, 55.3791104480105],
            [138.33984375, 56.17002298293205],
            [139.921875, 56.9449741808516],
            [141.50390625, 57.79794388498275],
            [143.08593749999997, 58.44773280389084],
            [144.84375, 58.722598828043374],
            [148.53515625, 58.90464570302001],
            [149.94140625, 58.99531118795094],
            [151.69921875, 58.53959476664049],
            [152.9296875, 58.81374171570782],
            [154.3359375, 58.44773280389084],
            [155.56640625, 59.085738569819505],
            [155.0390625, 59.977005492196],
            [156.09375, 60.673178565817715],
            [157.85156249999997, 61.3546135846894],
            [159.2578125, 60.930432202923335],
            [160.3125, 59.88893689676585],
            [158.203125, 58.63121664342478],
            [155.56640625, 57.51582286553883],
            [154.68749999999997, 56.559482483762245],
            [154.51171875, 54.57206165565852],
            [155.0390625, 53.014783245859235],
            [154.68749999999997, 51.28940590271679],
            [155.56640625, 50.064191736659104],
            [157.67578125, 50.736455137010665],
            [159.78515624999997, 52.26815737376817],
            [162.24609375, 53.54030739150022],
            [163.4765625, 54.57206165565852],
            [164.53125, 55.677584411089526],
            [164.1796875, 57.42129439209407],
            [165.234375, 58.90464570302001],
            [165.58593749999997, 59.80063426102869],
            [167.6953125, 60.06484046010452],
            [169.27734375, 59.265880628258095],
            [170.859375, 60.413852350464914],
            [172.96875, 61.10078883158897],
            [175.4296875, 61.85614879566797],
            [177.71484375, 61.60639637138628],
            [180.52734375, 62.186013857194226],
            [180.52734375, 63.31268278043484],
            [179.47265625, 64.32087157990324],
            [180.35156249999997, 64.99793920061401],
            [182.63671875, 65.07213008560697],
            [184.21874999999997, 64.09140752262307],
            [186.50390625, 63.6267446447533],
            [188.7890625, 64.01449619484472],
            [188.26171875, 64.54844014422517],
            [187.91015625, 65.2198939361321],
            [190.37109375, 65.6582745198266],
            [190.72265625, 66.08936427047088],
            [189.84375, 66.72254132270653],
            [187.734375, 67.33986082559095],
            [185.9765625, 67.67608458198097],
            [184.92187499999997, 68.2042121888185],
            [182.98828124999997, 68.78414378041504],
            [181.23046875, 69.2249968541159],
            [178.9453125, 69.71810669906763],
            [179.12109374999997, 70.37785394109224],
            [180.52734375, 70.67088107015755],
            [183.515625, 70.90226826757711],
            [182.28515624999997, 71.63599288330609],
            [178.9453125, 71.74643171904148],
            [177.71484375, 70.95969716686398],
            [176.48437499999997, 70.25945200030638],
            [172.79296875, 70.31873847853124],
            [170.15625, 70.31873847853124],
            [166.640625, 70.02058730174062],
            [164.1796875, 70.02058730174062],
            [161.71874999999997, 69.90011762668541],
            [161.3671875, 70.49557354093136],
            [160.83984375, 71.01695975726373],
            [158.37890625, 71.24435551310674],
            [155.21484375, 71.24435551310674],
            [152.75390624999997, 71.24435551310674],
            [151.875, 71.91088787611527],
            [150.1171875, 72.50172235139388],
            [146.77734375, 72.71190310803662],
            [145.01953124999997, 73.07384351277217],
            [144.66796875, 73.72659470212253],
            [143.96484375, 74.16408546675687],
            [145.1953125, 74.44935750063425],
            [148.359375, 74.4021625984244],
            [151.5234375, 74.59010800882325],
            [152.05078125, 75.23066741281573],
            [149.0625, 75.62863223279015],
            [146.77734375, 75.80211845876491],
            [145.1953125, 76.05850791800292],
            [142.3828125, 76.26869465080624],
            [139.04296875, 76.3518964311259],
            [135.87890625, 76.22690740640385],
            [133.9453125, 74.44935750063425],
            [134.12109375, 73.12494524712693],
            [131.1328125, 73.17589717422607],
            [130.4296875, 74.06786624952264],
            [125.15625000000001, 74.35482803013984],
            [120.9375, 73.77577986189993],
            [118.125, 73.9710776393399],
            [115.13671875, 74.49641311694307],
            [114.78515624999999, 75.54111255781133],
            [114.2578125, 76.39331166244496],
            [109.51171875, 76.88077457250164],
            [107.05078125, 77.69287033641928],
          ],
        ],
      },
    },
  ],
};
