import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

import { transformRiskDetails } from './utils';

export const TYPES = {
  GET_COMPONENT_HISTORY_DETAIL: 'GET_COMPONENT_HISTORY_DETAIL',
  GET_COMPONENT_HISTORY_DETAIL_PENDING: 'GET_COMPONENT_HISTORY_DETAIL_PENDING',
  GET_COMPONENT_HISTORY_DETAIL_ERROR: 'GET_COMPONENT_HISTORY_DETAIL_ERROR',

  GET_COMPONENT_RISK_DETAILS: 'GET_COMPONENT_RISK_DETAILS',
  GET_COMPONENT_RISK_DETAILS_PENDING: 'GET_COMPONENT_RISK_DETAILS_PENDING',
  GET_COMPONENT_RISK_DETAILS_ERROR: 'GET_COMPONENT_RISK_DETAILS_ERROR',

  UPDATE_COMPONENT_SCORE_DETAIL: 'UPDATE_COMPONENT_SCORE_DETAIL',
  UPDATE_ASSET_ATTRIBUTE_DETAIL: 'UPDATE_ASSET_ATTRIBUTE_DETAIL',
  CLEAR_COMPONENT_HISTORY: 'CLEAR_COMPONENT_HISTORY',
  CLEAR_COMPONENT_RISK_DETAILS: 'CLEAR_COMPONENT_RISK_DETAILS',
};

export const getComponentHistoryDetail = ({ id, riskModelId, componentId, datasource }) =>
  callApi({
    types: {
      success: TYPES.GET_COMPONENT_HISTORY_DETAIL,
      pending: TYPES.GET_COMPONENT_HISTORY_DETAIL_PENDING,
      error: TYPES.GET_COMPONENT_HISTORY_DETAIL_ERROR,
    },
    request: () => axios.get(`${datasource}/${id}/risk-models/${riskModelId}/components/${componentId}/scores/`),
  });

export const getComponentRiskDetails = ({ id, riskModelId, componentId, datasource }) =>
  callApi({
    types: {
      success: TYPES.GET_COMPONENT_RISK_DETAILS,
      pending: TYPES.GET_COMPONENT_RISK_DETAILS_PENDING,
      error: TYPES.GET_COMPONENT_RISK_DETAILS_ERROR,
    },
    params: { datasource },
    request: () => axios.get(`${datasource}/${id}/risk-models/${riskModelId}/components/${componentId}/details/`),
  });

export const updateComponentScoreData = data => {
  return {
    type: TYPES.UPDATE_COMPONENT_SCORE_DETAIL,
    payload: data,
  };
};

export const clearComponentHistory = data => {
  return {
    type: TYPES.CLEAR_COMPONENT_HISTORY,
    payload: data,
  };
};

export const clearComponentRiskDetails = data => {
  return {
    type: TYPES.CLEAR_COMPONENT_RISK_DETAILS,
    payload: data,
  };
};

export const INITIAL_STATE = {
  status: undefined,
  riskHistory: null,
  riskDetails: null,
  error: undefined,
};

export const componentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_COMPONENT_HISTORY_DETAIL:
      return {
        ...state,
        riskHistory: action.payload,
        error: undefined,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_COMPONENT_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_COMPONENT_HISTORY_DETAIL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_COMPONENT_RISK_DETAILS:
      return {
        ...state,
        riskDetails: transformRiskDetails(action.params.datasource, action.payload),
        error: undefined,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_COMPONENT_RISK_DETAILS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_COMPONENT_RISK_DETAILS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CLEAR_COMPONENT_HISTORY:
      return {
        ...state,
        riskHistory: null,
      };
    case TYPES.CLEAR_COMPONENT_RISK_DETAILS:
      return {
        ...state,
        riskDetails: null,
      };
    case TYPES.UPDATE_COMPONENT_SCORE_DETAIL:
      return {
        ...state,
        scoreData: action.payload,
      };
    default:
      return state;
  }
};
