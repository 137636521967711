import React from 'react';
import { useDispatch } from 'react-redux';
import { BasicModal } from '@luxe/components';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { deleteDiscoverAsset } from 'admin/assets/modules/assets';
import useMixpanel from 'utils/hooks/Mixpanel';

const { Text } = Typography;

const DeleteAssetModal = styled(({ assetId, hideModal, closeDrawer }) => {
  const mixpanelTrack = useMixpanel();
  const { colors, fontSizes } = useTheme();
  const dispatch = useDispatch();

  return (
    <BasicModal
      visible={true}
      closable={true}
      title={`Confirm`}
      okText="Delete"
      onOk={() => {
        dispatch(deleteDiscoverAsset(assetId));
        mixpanelTrack('Discover - delete asset');
        closeDrawer();
        hideModal();
      }}
      onCancel={() => {
        hideModal();
      }}
      destroyOnClose={true}
      style={{ backgroundColor: '#212121', color: '#ffffff' }}
      okButtonProps={{ style: { backgroundColor: '#ff4d4f', border: 'none' } }}
    >
      <Text style={{ fontSize: fontSizes.body, color: colors.white }}>Are you sure you want to delete this asset?</Text>
    </BasicModal>
  );
})``;

export default DeleteAssetModal;
