import {
  createRiskComponentRecursive,
  createRiskIndexRecursive,
  deleteRiskComponentRecursive,
  deleteRiskIndexRecursive,
  fetchRiskModelScoreIds,
  isModelValid,
  reorderRiskComponentRecursive,
  reorderRiskIndexRecursive,
  transformRiskModel,
  transformRiskModelScores,
  updateRiskComponentRecursive,
  updateRiskIndexRecursive,
  updatedModelMatchesCopy,
} from './utils';

import { TYPES as ASSET_TYPES } from 'admin/assets/modules/assets';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import axios from 'axiosInstance';
import { callApi } from 'utils';

export const TYPES = {
  CREATE_RISK_MODEL: 'CREATE_RISK_MODEL',
  CREATE_RISK_MODEL_ERROR: 'CREATE_RISK_MODEL_ERROR',
  CREATE_RISK_MODEL_SUCCESS: 'CREATE_RISK_MODEL_SUCCESS',

  GET_RISK_MODEL: 'GET_RISK_MODEL',
  GET_RISK_MODEL_ERROR: 'GET_RISK_MODEL_ERROR',
  GET_RISK_MODEL_SUCCESS: 'GET_RISK_MODEL_SUCCESS',

  GET_RISK_MODELS: 'GET_RISK_MODELS',
  GET_RISK_MODELS_ERROR: 'GET_RISK_MODELS_ERROR',
  GET_RISK_MODELS_SUCCESS: 'GET_RISK_MODELS_SUCCESS',

  GET_RISK_MODEL_SCORES: 'GET_RISK_MODEL_SCORES',
  GET_RISK_MODEL_SCORES_ERROR: 'GET_RISK_MODEL_SCORES_ERROR',
  GET_RISK_MODEL_SCORES_SUCCESS: 'GET_RISK_MODEL_SCORES_SUCCESS',

  GENERATE_RISK_MODEL_TEMPLATE: 'GENERATE_RISK_MODEL_TEMPLATE',
  GENERATE_RISK_MODEL_TEMPLATE_ERROR: 'GENERATE_RISK_MODEL_TEMPLATE_ERROR',
  GENERATE_RISK_MODEL_TEMPLATE_SUCCESS: 'GENERATE_RISK_MODEL_TEMPLATE_SUCCESS',

  UPLOAD_RISK_MODEL_TEMPLATE: 'UPLOAD_RISK_MODEL_TEMPLATE',
  UPLOAD_RISK_MODEL_TEMPLATE_ERROR: 'UPLOAD_RISK_MODEL_TEMPLATE_ERROR',
  UPLOAD_RISK_MODEL_TEMPLATE_SUCCESS: 'UPLOAD_RISK_MODEL_TEMPLATE_SUCCESS',

  SAVE_RISK_MODEL: 'SAVE_RISK_MODEL',
  SAVE_RISK_MODEL_ERROR: 'SAVE_RISK_MODEL_ERROR',
  SAVE_RISK_MODEL_SUCCESS: 'SAVE_RISK_MODEL_SUCCESS',

  CREATE_RISK_MODEL_COMPONENT: 'CREATE_RISK_MODEL_COMPONENT',
  CREATE_RISK_MODEL_COMPONENT_ERROR: 'CREATE_RISK_MODEL_COMPONENT_ERROR',
  CREATE_RISK_MODEL_COMPONENT_SUCCESS: 'CREATE_RISK_MODEL_COMPONENT_SUCCESS',

  GET_PROACTIVE_RISK: 'GET_PROACTIVE_RISK',
  GET_PROACTIVE_RISK_ERROR: 'GET_PROACTIVE_RISK_ERROR',
  GET_PROACTIVE_RISK_SUCCESS: 'GET_PROACTIVE_RISK_SUCCESS',

  GET_PROACTIVE_COUNTRY_RISK: 'GET_PROACTIVE_COUNTRY_RISK',
  GET_PROACTIVE_COUNTRY_RISK_ERROR: 'GET_PROACTIVE_COUNTRY_RISK_ERROR',
  GET_PROACTIVE_COUNTRY_RISK_SUCCESS: 'GET_PROACTIVE_COUNTRY_RISK_SUCCESS',

  DELETE_RISK_MODEL: 'DELETE_RISK_MODEL',
  DELETE_RISK_MODEL_ERROR: 'DELETE_RISK_MODEL_ERROR',
  DELETE_RISK_MODEL_SUCCESS: 'DELETE_RISK_MODEL_SUCCESS',

  CANCEL_RISK_MODEL_CHANGES: 'CANCEL_RISK_MODEL_CHANGES',

  CREATE_RISK_COMPONENT: 'CREATE_RISK_COMPONENT',
  DELETE_RISK_COMPONENT: 'DELETE_RISK_COMPONENT',
  REORDER_RISK_COMPONENT: 'REORDER_RISK_COMPONENT',
  UPDATE_RISK_COMPONENT: 'UPDATE_RISK_COMPONENT',

  CREATE_RISK_INDEX: 'CREATE_RISK_INDEX',
  DELETE_RISK_INDEX: 'DELETE_RISK_INDEX',
  REORDER_RISK_INDEX: 'REORDER_RISK_INDEX',
  UPDATE_RISK_INDEX: 'UPDATE_RISK_INDEX',

  UPDATE_RISK_MODEL: 'UPDATE_RISK_MODEL',
  ADD_RISK_COMPONENT: 'ADD_RISK_COMPONENT',

  CREATE_RISK_SCORE: 'CREATE_RISK_SCORE',
  EDIT_RISK_SCORE: 'EDIT_RISK_SCORE',

  SET_ACTIVE_RISK_MODEL_ID: 'SET_ACTIVE_RISK_MODEL_ID',

  TRACK_RISK_MODEL_CHANGED: 'TRACK_RISK_MODEL_CHANGED',
};

export const INITIAL_STATE = {
  RiskModel: undefined,
  RiskModels: [],
  RiskModelCopy: undefined,
  dataTest: undefined,
  usedRiskModelScores: [],
  error: undefined,
  params: undefined,
  updated: false,
  proactiveRisk: {},
  proactiveCountryRisk: {},
  activeRiskModel: {},
  isRiskModelChanged: false,
};

export const riskModelsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_RISK_MODEL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RISK_MODEL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RISK_MODEL_SUCCESS:
      const fetchedRiskModel = transformRiskModel(action.payload);
      const fetchedValid = isModelValid(fetchedRiskModel);
      const usedScoreIds = fetchRiskModelScoreIds(action.payload);
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModel: fetchedRiskModel,
        RiskModelCopy: fetchedRiskModel,
        usedRiskModelScores: usedScoreIds,
        valid: fetchedValid,
        error: undefined,
      };
    case TYPES.TRACK_RISK_MODEL_CHANGED:
      return {
        ...state,
        isRiskModelChanged: action.payload,
      };
    case TYPES.GET_RISK_MODEL_SCORES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RISK_MODEL_SCORES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RISK_MODEL_SCORES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModelScores: transformRiskModelScores(action.payload),
        error: undefined,
      };
    case TYPES.UPLOAD_RISK_MODEL_TEMPLATE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        RiskModel: state.RiskModel,
        error: action.payload,
      };
    case TYPES.UPLOAD_RISK_MODEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UPLOAD_RISK_MODEL_TEMPLATE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RISK_MODELS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RISK_MODELS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RISK_MODELS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModels: action.payload,
        error: undefined,
      };
    case TYPES.GET_PROACTIVE_RISK:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_PROACTIVE_RISK_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_PROACTIVE_RISK_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        proactiveRisk: action.payload,
        error: undefined,
      };
    case TYPES.GET_PROACTIVE_COUNTRY_RISK:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_PROACTIVE_COUNTRY_RISK_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_PROACTIVE_COUNTRY_RISK_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        proactiveCountryRisk: action.payload,
        error: undefined,
      };
    case ASSET_TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL:
      return {
        ...state,
        proactiveRisk: action.payload,
        error: undefined,
        detailStatus: 'SUCCESS',
      };
    case ASSET_TYPES.UPDATE_ASSET_ATTRIBUTE_DETAIL_PENDING:
      return {
        ...state,
        riskData: null,
        params: action.payload,
        detailStatus: 'PENDING',
      };
    case ASSET_TYPES.UPDATE_ASSET_ATTRIBUTE_BATCH:
      return {
        ...state,
        proactiveRisk: action.payload,
        error: undefined,
        detailStatus: 'SUCCESS',
      };
    case ASSET_TYPES.UPDATE_ASSET_ATTRIBUTE_BATCH_PENDING:
      return {
        ...state,
        riskData: null,
        params: action.payload,
        detailStatus: 'PENDING',
      };
    case TYPES.DELETE_RISK_MODEL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_RISK_MODEL_SUCCESS:
      const deletedRiskModel = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModels: state.RiskModels.filter(RiskModel => RiskModel.id !== deletedRiskModel.id),
        error: undefined,
      };
    case TYPES.DELETE_RISK_MODEL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.CREATE_RISK_MODEL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        RiskModel: state.RiskModel,
        error: action.payload,
      };
    case TYPES.CREATE_RISK_MODEL_SUCCESS:
      let newRiskModel = transformRiskModel(action.payload);
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModels: [...state.RiskModels, newRiskModel],
        riskModels: newRiskModel,
        error: undefined,
        created: true,
      };
    case TYPES.CREATE_RISK_MODEL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        RiskModel: state.RiskModel,
        error: action.payload,
      };
    case TYPES.SAVE_RISK_MODEL:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        riskModels: state.RiskModel,
        error: undefined,
      };
    case TYPES.SAVE_RISK_MODEL_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        RiskModel: transformRiskModel(action.payload),
        RiskModelCopy: transformRiskModel(action.payload),
        error: undefined,
        valid: true,
        updated: false,
      };
    case TYPES.SAVE_RISK_MODEL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        RiskModel: state.RiskModel,
        error: action.payload,
        valid: false,
      };
    case TYPES.CANCEL_RISK_MODEL_CHANGES:
      return {
        ...state,
        updated: false,
      };
    case TYPES.CREATE_RISK_INDEX:
      let createdRiskIndex = transformRiskModel(
        createRiskIndexRecursive(state.RiskModel, action.payload.indexArray, action.payload.data),
      );
      return {
        ...state,
        RiskModel: createdRiskIndex,
        valid: isModelValid(createdRiskIndex),
        updated: true,
      };
    case TYPES.UPDATE_RISK_INDEX:
      let updatedRiskIndex = transformRiskModel(
        updateRiskIndexRecursive(state.RiskModel, action.payload.indexArray, action.payload.data),
      );
      let validIndex = isModelValid(updatedRiskIndex);
      let modelMatchesCopy = updatedModelMatchesCopy(updatedRiskIndex, state.RiskModelCopy);
      return {
        ...state,
        RiskModel: updatedRiskIndex,
        valid: validIndex && !modelMatchesCopy,
        updated: true,
      };
    case TYPES.DELETE_RISK_INDEX:
      let deletedRiskIndex = transformRiskModel(deleteRiskIndexRecursive(state.RiskModel, action.payload.indexArray));
      return {
        ...state,
        RiskModel: deletedRiskIndex,
        valid: isModelValid(deletedRiskIndex),
        updated: true,
      };
    case TYPES.REORDER_RISK_INDEX:
      return {
        ...state,
        RiskModel: transformRiskModel(reorderRiskIndexRecursive({ obj: state.RiskModel, ...action.payload })),
        updated: true,
      };
    case TYPES.CREATE_RISK_COMPONENT:
      const createdRiskComponent = transformRiskModel(
        createRiskComponentRecursive(state.RiskModel, action.payload.indexArray, action.payload.data),
      );
      return {
        ...state,
        RiskModel: createdRiskComponent,
        usedRiskModelScores: [...state.usedRiskModelScores, action.payload.data.id],
        valid: isModelValid(createdRiskComponent),
        updated: true,
      };
    case TYPES.REORDER_RISK_COMPONENT:
      return {
        ...state,
        RiskModel: transformRiskModel(reorderRiskComponentRecursive({ obj: state.RiskModel, ...action.payload })),
        updated: true,
      };
    case TYPES.UPDATE_RISK_COMPONENT:
      let updatedRiskComponent = transformRiskModel(
        updateRiskComponentRecursive(state.RiskModel, action.payload.indexArray, action.payload.data),
      );
      let valid = isModelValid(updatedRiskComponent);
      return {
        ...state,
        RiskModel: updatedRiskComponent,
        valid: valid,
        updated: true,
      };
    case TYPES.DELETE_RISK_COMPONENT:
      const deletedRiskComponent = transformRiskModel(
        deleteRiskComponentRecursive(state.RiskModel, action.payload.indexArray),
      );
      return {
        ...state,
        RiskModel: deletedRiskComponent,
        usedRiskModelScores: fetchRiskModelScoreIds(state.RiskModel).filter(x => x !== action.payload.data),
        valid: isModelValid(deletedRiskComponent),
        updated: true,
      };
    case TYPES.UPDATE_RISK_MODEL:
      let updatedRiskModel = {
        RiskModel: {
          ...state.RiskModel,
          ...action.payload,
        },
      };
      return {
        ...state,
        ...updatedRiskModel,
        valid: isModelValid(updatedRiskModel),
        updated: true,
      };
    case TYPES.CREATE_RISK_SCORE:
      let riskScore = transformRiskModel(
        updateRiskComponentRecursive(state.RiskModel, action.payload.indexArray, action.payload.data),
      );
      let validScore = isModelValid(riskScore);
      return {
        ...state,
        RiskModel: riskScore,
        valid: validScore,
        updated: true,
      };
    case TYPES.SET_ACTIVE_RISK_MODEL_ID:
      return {
        ...state,
        activeRiskModel: { id: action.payload.id, type: action.payload.type },
      };
    default:
      return state;
  }
};

export const createRiskModel = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_RISK_MODEL,
      success: TYPES.CREATE_RISK_MODEL_SUCCESS,
      error: TYPES.CREATE_RISK_MODEL_ERROR,
    },
    request: () => axios.post(`risk-models/`, data),
    messages: {
      success: 'Risk model has been successfully created!',
      error: 'Risk model failed to create',
    },
    dynamicErrorMsg: true,
  });

export const saveRiskModel = data => {
  return callApi({
    types: {
      pending: TYPES.SAVE_RISK_MODEL,
      success: TYPES.SAVE_RISK_MODEL_SUCCESS,
      error: TYPES.SAVE_RISK_MODEL_ERROR,
    },
    request: () => axios.put(`risk-models/${data.id}/`, data),
    messages: {
      success: 'Risk model has been successfully edited!',
      error: 'Risk model update unsuccessful',
    },
    dynamicErrorMsg: true,
  });
};

export const deleteRiskModel = params =>
  callApi({
    types: {
      pending: TYPES.DELETE_RISK_MODEL,
      success: TYPES.DELETE_RISK_MODEL_SUCCESS,
      error: TYPES.DELETE_RISK_MODEL_ERROR,
    },
    params: params,
    request: () => axios.delete(`risk-models/${params.id}/`),
    messages: {
      success: 'Risk model was deleted',
      error: 'Risk model delete unsuccessful',
    },
  });

export const getRiskModel = params =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_MODEL,
      success: TYPES.GET_RISK_MODEL_SUCCESS,
      error: TYPES.GET_RISK_MODEL_ERROR,
    },
    params: params,
    request: () => axios.get(`risk-models/${params.id}/`),
  });

export const getRiskModels = params =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_MODELS,
      success: TYPES.GET_RISK_MODELS_SUCCESS,
      error: TYPES.GET_RISK_MODELS_ERROR,
    },
    params: params,
    request: () => axios.get('risk-models/'),
  });

export const getRiskModelScores = params => {
  return callApi({
    types: {
      pending: TYPES.GET_RISK_MODEL_SCORES,
      success: TYPES.GET_RISK_MODEL_SCORES_SUCCESS,
      error: TYPES.GET_RISK_MODEL_SCORES_ERROR,
    },
    params: params,
    request: () => axios.get(`risk-models/scores/`),
  });
};

export const generateRiskModelTemplate = params =>
  callApi({
    types: {
      pending: TYPES.GENERATE_RISK_MODEL_TEMPLATE,
      success: TYPES.GENERATE_RISK_MODEL_TEMPLATE_SUCCESS,
      error: TYPES.GENERATE_RISK_MODEL_TEMPLATE_ERROR,
    },
    request: () => axios.get(`risk-models/${params.id}/template/`),
    messages: {
      success: 'Risk model template will be sent to your email once it has been generated',
      error: 'Risk model template failed to generate',
    },
    dynamicErrorMsg: true,
  });

export const uploadRiskModelTemplate = (params, data) =>
  callApi({
    types: {
      pending: TYPES.UPLOAD_RISK_MODEL_TEMPLATE,
      success: TYPES.UPLOAD_RISK_MODEL_TEMPLATE_SUCCESS,
      error: TYPES.UPLOAD_RISK_MODEL_TEMPLATE_ERROR,
    },
    request: () => axios.post(`risk-models/${params.id}/template/`, data),
    messages: {
      success: 'Risk model template has been successfully uploaded!',
      error: 'Risk model template failed to upload',
    },
  });

export const getProactiveRisk = ({ assetId, riskModelId }) =>
  callApi({
    types: {
      pending: TYPES.GET_PROACTIVE_RISK,
      success: TYPES.GET_PROACTIVE_RISK_SUCCESS,
      error: TYPES.GET_PROACTIVE_RISK_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/risk-models/${riskModelId}/`),
  });

export const getProactiveCountryRisk = ({ countryId, riskModelId }) =>
  callApi({
    types: {
      pending: TYPES.GET_PROACTIVE_COUNTRY_RISK,
      success: TYPES.GET_PROACTIVE_COUNTRY_RISK_SUCCESS,
      error: TYPES.GET_PROACTIVE_COUNTRY_RISK_ERROR,
    },
    request: () => axios.get(`countries/${countryId}/risk-models/${riskModelId}/`),
  });

export const cancelRiskModelChanges = () => ({
  type: TYPES.CANCEL_RISK_MODEL_CHANGES,
});

export const deleteRiskIndex = indexArray => ({
  type: TYPES.DELETE_RISK_INDEX,
  payload: { indexArray },
});

export const updateRiskIndex = (indexArray, data) => ({
  type: TYPES.UPDATE_RISK_INDEX,
  payload: { indexArray, data },
});

export const reorderRiskIndex = ({ ...props }) => ({
  type: TYPES.REORDER_RISK_INDEX,
  payload: { ...props },
});

export const deleteRiskComponent = (indexArray, data) => ({
  type: TYPES.DELETE_RISK_COMPONENT,
  payload: { indexArray, data },
});

export const reorderRiskComponent = ({ ...props }) => ({
  type: TYPES.REORDER_RISK_COMPONENT,
  payload: { ...props },
});

export const updateRiskComponent = (indexArray, data) => ({
  type: TYPES.UPDATE_RISK_COMPONENT,
  payload: { indexArray, data },
});

export const createRiskIndex = (indexArray, data) => ({
  type: TYPES.CREATE_RISK_INDEX,
  payload: { indexArray, data },
});

export const createRiskComponent = (indexArray, data) => ({
  type: TYPES.CREATE_RISK_COMPONENT,
  payload: { indexArray, data },
});

export const updateRiskModel = data => ({
  type: TYPES.UPDATE_RISK_MODEL,
  payload: { ...data },
});

export const createRiskScore = (indexArray, data) => ({
  type: TYPES.CREATE_RISK_SCORE,
  payload: { indexArray, data },
});

export const setActiveRiskModel = (id, type) => ({
  type: TYPES.SET_ACTIVE_RISK_MODEL_ID,
  payload: { id, type },
});

export const trackRiskModelChange = val => ({
  type: TYPES.TRACK_RISK_MODEL_CHANGED,
  payload: val,
});
