import styled from '@emotion/styled';
import { Modal, Radio, Input, InputNumber } from 'antd';
import { space, layout, flexbox, position } from 'styled-system';

export const ScoreDetailsComponent = styled.div`
  padding: ${({ theme }) => theme.space['5']}px;
  background-color: ${({ theme }) => theme.colors.black3};
  width: ${props => props.width};

  & div.component-score-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space['4']}px;
    font-size: ${({ theme }) => theme.fontSizes['2']}px;
    align-items: center;

    .component-score-value {
      display: inherit;
      align-items: center;
    }

    .component-score-trend {
      display: inherit;
    }

    .slider-heading-score {
      font-size: ${({ theme }) => theme.fontSizes.heading}px;
      font-weight: ${({ theme }) => theme.fontWeights.heading};
      margin: 0 ${({ theme }) => theme.space.xsmall}px;
    }
  }

  & div.slider-benchmark-container {
    margin-bottom: ${({ theme }) => theme.space.xsmall}px;
  }
`;

export const AboutScoreComponent = styled.div`
  padding: ${({ theme }) => theme.space['5']}px;
  background-color: ${({ theme }) => theme.colors.black3};
  width: ${props => props.width};

  & div.about-score-container {
    display: flex;
    flex-direction: column;
    p {
      color: #e2e2e2;
    }
  }
`;

export const CommentLabel = styled(Input)`
${space}
${position}
${layout}
${flexbox}
flex-direction: column;
background-color: ${props => props.theme.colors.black1};
color: rgba(255,255,255, 0.75);
border: ${props => `1px solid ${props.theme.colors.BG5}`};
border-radius: 4px;
font-family: ${props => props.theme.fonts.body};
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
&:focus {
  border-color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.black1};
}
&:hover {
  border-color: ${props => props.theme.colors.primary};
}
&:disabled {
  background-color: ${props => props.theme.colors.disabled};
  border-color: ${props => props.theme.colors.disabled};
  color: rgba(255,255,255,0.45);
}
`;

export const ScoreValueInput = styled(InputNumber)`
${space}
${position}
${layout}
${flexbox}
flex-direction: column;
background-color: ${props => props.theme.colors.black1};
color: rgba(255,255,255, 0.75);
border: ${props => `1px solid ${props.theme.colors.BG5}`};
border-radius: 4px;
font-family: ${props => props.theme.fonts.body};
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
min-width: 100%;
input.ant-input {
  background-color: transparent !important;
}
&:focus {
  border-color: ${props => props.theme.colors.primary};
}
&:hover {
  border-color: ${props => props.theme.colors.primary};
}
&:disabled {
  background-color: ${props => props.theme.colors.disabled};
  border-color: ${props => props.theme.colors.disabled};
  color: rgba(255,255,255,0.45);
}
`;

export const StyledRadioGroup = styled(Radio.Group)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
display: flex;
flex-direction: column;

&:disabled {
  background-color: ${props => props.theme.colors.disabled};
  border-color: ${props => props.theme.colors.disabled};
  color: rgba(255,255,255,0.45);
}
.ant-radio-inner {
  background-color: ${props => props.theme.colors.background};
  border-color: ${props => props.theme.colors.disabled};
  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
  }
}
.ant-radio-wrapper {
  color: white;
}
label{
  padding:7px 0px 7px 0px;
}
`;

export const StyledModal = styled(Modal)`
  ${position}
  ${layout}
  width: 100%;
  background-color: ${props => props.theme.colors.background};

  .ant-modal-content {
    box-shadow: none;
    text-align: left;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: ${props => props.theme.colors.background};
    border: none;
  }
  .ant-modal-header {
    text-align: center;
    background-color: rgb(19, 28, 30);
    border-bottom: none;
    .ant-modal-title {
      color: white;
    }
  }
  .ant-modal-close-icon svg {
    fill: white !important;
    font-size: 11px;
  }
  button.ant-modal-close {
    float: right;
  }
  .ant-modal-body {
    width: ${props => (props.bodyWidth ? props.bodyWidth : '70%')};
    margin: 0 auto;
    border: none;
    box-shadow: none;
  }
  .ant-modal-footer {
    text-align: center;
    border: none;
    button {
      border-radius: 4px;
      border: none;
    }
    button.ant-btn:nth-of-type(1) {
      background-color: ${props => props.theme.colors.secondary};
      color: white;
      &:hover {
        border: ${props => `1px solid ${props.theme.colors.primary}`};
      }
    }
    button.ant-btn:nth-of-type(2) {
      border: ${props => `1px solid ${props.theme.colors.primary}`};
      background-color: ${props => props.theme.colors.primary};
      color: white;
      &:hover {
        border: ${props => `1px solid ${props.theme.colors.primary}`};
      }
    }
  }
`;
