import React, { useEffect, useMemo, useState } from 'react';
import { JOB_FUNCTIONS, JOB_ROLES, UOM } from 'common/Constants';
import styled from '@emotion/styled';
import { Checkbox, Input, Button } from 'antd';
import { Select } from '@luxe/components';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'modal/userprofile/modules/network';
import LoadingScreen from 'layout/containers/LoadingScreen';
import moment from 'moment-timezone';
import UserProfileCancelButton from '../UserProfileCancelButton';

export const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + span {
    border-color: ${props => props.theme.colors.white} !important;
    border: 2px solid;
  }

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} !important;
    background-color: ${props => props.theme.colors.primary} !important;
  }
`;
const GeneralView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const countryList = useSelector(store => store.userNetwork.countries);
  const [formattedCountries, setFormattedCountries] = useState([]);

  const TIME_ZONES = useMemo(() => {
    const userZone = props?.userPreference?.units?.timezone;
    const usertimeZone = moment.tz.names().indexOf(userZone) > -1;
    let timeZones = moment.tz.names().map(zone => {
      return {
        label: zone,
        value: zone,
      };
    });
    if (!usertimeZone) {
      timeZones.push({
        label: userZone,
        value: userZone,
      });
    }
    return timeZones;
  }, [props.userPreference.units.timezone]);

  if (countryList && formattedCountries.length < 1) {
    const updatedCountryList = [];
    countryList.forEach(c => {
      updatedCountryList.push({
        label: c.name,
        value: c.id,
      });
    });
    setFormattedCountries(updatedCountryList);
  }
  useEffect(() => {
    if (!countryList?.length) dispatch(getCountries());
  }, [countryList, dispatch]);

  const handleSingleInputChange = (event, type) => {
    props.updateProfile({ [type.name]: event.value || [] });
  };
  const updateUnitsPreference = (event, type) => {
    props.updatePreference('units', { ...props.userPreference.units, [type.name]: event.value });
  };
  const updateUOMPreference = event => {
    let updatedUOM = {};
    if (event.value === 'metric') {
      updatedUOM = { default: event.value, length: 'kilometers', temp: 'C' };
    } else if (event.value === 'imperial') {
      updatedUOM = { default: event.value, length: 'miles', temp: 'F' };
    }
    props.updatePreference('units', { ...props.userPreference.units, ...updatedUOM });
  };

  const updateAnalyticsPreference = event => {
    const updatedPayload =
      event.target.checked !== props.userPreference.analytics.tracking && event.target.checked
        ? { tracking: true, privacy_policy_date: new Date().toLocaleDateString() }
        : { tracking: false, privacy_policy_date: '' };
    props.updatePreference('analytics', { ...props.userPreference.analytics, ...updatedPayload });
  };
  const handleMultiInputChange = (selectedValues, type) => {
    let selectedString = [];
    if (selectedValues?.length > 0) selectedString = [...new Set(selectedValues.map(item => item.value))];
    props.updateProfile({ [type.name]: selectedString });
  };
  const getCheckedCountries = checked_list => {
    if (checked_list) return formattedCountries.filter(entry => checked_list.includes(entry.value));
    else return [];
  };
  const getCheckedJobFunctions = checked_list => {
    if (checked_list) return JOB_FUNCTIONS.filter(entry => checked_list.includes(entry.value));
    else return [];
  };
  const getCheckedJobRole = checked_list => {
    return checked_list ? JOB_ROLES.filter(entry => checked_list.includes(entry.value)) : [];
  };
  const getCheckedTimeZone = checked_list => {
    return checked_list ? TIME_ZONES.filter(entry => checked_list === entry.value) : [];
  };

  const getCheckedUOM = checked_values => {
    return checked_values ? UOM.filter(entry => checked_values.includes(entry.value)) : [];
  };

  if (!props.user || countryList?.length === 0) {
    return (
      <div className={'loader'}>
        Loading.... <br />
        <LoadingScreen modal={true} />
      </div>
    );
  }

  const SectionDivider = styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    height: 1px;
    margin-bottom: 15px;
  `;

  return (
    <div className={'profile-content'}>
      <div>
        <Button className={'submit profile-button'} onClick={props.onSave}>
          Save
        </Button>
        <UserProfileCancelButton />
        <h1>Profile Settings</h1>
      </div>
      <SectionDivider />
      <div className={'profile-general'}>
        <label>User Name</label>
        <Input className={'text-input'} value={props.user?.name} readOnly />
        <label>Email</label>
        <Input className={'text-input'} value={props.user?.email} readOnly />
        <label>Units of Measure</label>
        <Select
          options={UOM}
          onChange={updateUOMPreference}
          name="default"
          value={getCheckedUOM(props.userPreference.units.default)}
        />
        <label>Timezone</label>
        <Select
          options={TIME_ZONES}
          onChange={updateUnitsPreference}
          name="timezone"
          value={getCheckedTimeZone(props.userPreference.units.timezone)}
        />
        <label>Select job function</label>
        <Select
          isMulti={true}
          options={JOB_FUNCTIONS}
          onChange={handleMultiInputChange}
          name="job_functions"
          value={getCheckedJobFunctions(props.userProfile.job_functions)}
        />
        <label>Job Role</label>
        <Select
          options={JOB_ROLES}
          onChange={handleSingleInputChange}
          name="job_role"
          value={getCheckedJobRole(props.userProfile.job_role)}
        />
        <label>Countries</label>
        <Select
          isMulti={true}
          options={formattedCountries}
          onChange={handleMultiInputChange}
          name="countries"
          value={getCheckedCountries(props.userProfile.countries)}
        />
        <StyledCheckbox
          className={'profile-tracking'}
          onChange={updateAnalyticsPreference}
          checked={props.userPreference.analytics.tracking}
          name="tracking"
        >
          Allow{' '}
          <a
            href="https://www.everstream.ai/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            className={'profile-tracking-link'}
          >
            Usage Tracking
          </a>
        </StyledCheckbox>
      </div>
    </div>
  );
})``;

export default GeneralView;
