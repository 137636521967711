import { REQUESTED_DATA_STATUS } from 'admin/constants';
import axios from 'axiosInstance';
import { callApi } from 'utils';
import { getParseParams } from 'admin/utils';

export const TYPES = {
  GET_LANES: 'GET_LANES',
  GET_LANES_ERROR: 'GET_LANES_ERROR',
  GET_LANES_SUCCESS: 'GET_LANES_SUCCESS',

  GET_LANE: 'GET_LANE',
  GET_LANE_ERROR: 'GET_LANE_ERROR',
  GET_LANE_SUCCESS: 'GET_LANE_SUCCESS',

  GET_LANE_QUERY_PENDING: 'GET_LANE_QUERY_PENDING',
  GET_LANE_QUERY_ERROR: 'GET_LANE_QUERY_ERROR',
  GET_LANE_QUERY_SUCCESS: 'GET_LANE_QUERY_SUCCESS',

  CREATE_LANE: 'CREATE_LANE',
  CREATE_LANE_ERROR: 'CREATE_LANE_ERROR',
  CREATE_LANE_SUCCESS: 'CREATE_LANE_SUCCESS',

  UPDATE_LANE: 'UPDATE_LANE',
  UPDATE_LANE_ERROR: 'UPDATE_LANE_ERROR',
  UPDATE_LANE_SUCCESS: 'UPDATE_LANE_SUCCESS',

  SAVE_LANE: 'SAVE_LANE',
  SAVE_LANE_ERROR: 'SAVE_LANE_ERROR',
  SAVE_LANE_SUCCESS: 'SAVE_LANE_SUCCESS',

  DELETE_LANES: 'DELETE_LANES',
  DELETE_LANES_ERROR: 'DELETE_LANES_ERROR',
  DELETE_LANES_SUCCESS: 'DELETE_LANES_SUCCESS',

  DELETE_LANE: 'DELETE_LANE',
  DELETE_LANE_ERROR: 'DELETE_LANE_ERROR',
  DELETE_LANE_SUCCESS: 'DELETE_LANE_SUCCESS',
};

export const createLanes = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_LANE,
      success: TYPES.CREATE_LANE_SUCCESS,
      error: TYPES.CREATE_LANE_ERROR,
    },
    request: () => axios.post('lanes/', data),
    messages: {
      success: 'Lane has been successfully created!',
      error: 'Lane creation unsuccessful',
    },
  });

export const getLanes = data =>
  callApi({
    types: {
      pending: TYPES.GET_LANES,
      success: TYPES.GET_LANES_SUCCESS,
      error: TYPES.GET_LANES_ERROR,
    },
    request: () => {
      const currentUrl = window.location.href.split('?');
      const params = currentUrl.length > 1 ? getParseParams(currentUrl[1]) : {};
      const { offset = 0, limit = 10 } = params;
      return axios.post(`lanes/query/?limit=${limit}&offset=${offset}`, { ...data, offset, limit });
    },
  });

export const getLane = params =>
  callApi({
    types: {
      pending: TYPES.GET_LANE,
      success: TYPES.GET_LANE_SUCCESS,
      error: TYPES.GET_LANE_ERROR,
    },
    params: params,
    request: () => axios.get(`lanes/${params.id}/`),
  });

export const postLanesQuery = data =>
  callApi({
    types: {
      pending: TYPES.GET_LANE_QUERY_PENDING,
      success: TYPES.GET_LANE_QUERY_SUCCESS,
      error: TYPES.GET_LANE_QUERY_ERROR,
    },
    params: data,
    request: () => axios.post(`lanes/query/`, data),
  });

export const saveLane = data =>
  callApi({
    types: {
      pending: TYPES.SAVE_LANE,
      success: TYPES.SAVE_LANE_SUCCESS,
      error: TYPES.SAVE_LANE_ERROR,
    },
    request: () => axios.put(`lanes/${data.id}/`, data),
    messages: {
      success: 'Lane has been successfully edited!',
      error: 'Lane update unsuccessful',
    },
  });

export const updateLane = data => ({
  type: TYPES.UPDATE_LANE,
  payload: { ...data },
});

export const deleteLanes = params =>
  callApi({
    types: {
      pending: TYPES.DELETE_LANE,
      success: TYPES.DELETE_LANE_SUCCESS,
      error: TYPES.DELETE_LANE_ERROR,
    },
    request: () => axios.delete(`lanes/${params}/`),
    messages: {
      success: 'Lane has been deleted!',
      error: 'Lane removal unsuccessful',
    },
  });

export const deleteBulkLanes = data => {
  let payload = {
    action: 'delete',
    lane_ids: [...data],
  };
  return callApi({
    types: {
      pending: TYPES.DELETE_LANES,
      success: TYPES.DELETE_LANES_SUCCESS,
      error: TYPES.DELETE_LANES_ERROR,
    },
    request: () => axios.post(`lanes/bulk/`, payload),
    messages: {
      success: 'Lanes have been successfully deleted!',
      error: 'Lane deletion unsuccessful',
    },
  });
};

export const INITIAL_STATE = {
  status: undefined,
  lanes: [],
  error: undefined,
  params: undefined,
  next: null,
  previous: null,
};

export const lanesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_LANES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        lanes: [],
        params: action.payload,
      };
    case TYPES.GET_LANES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    //TODO: keep track of state if there is next or prev -- check in action.payload -- next and prev in state
    //TODO: set pagination limit to trigger pagination
    case TYPES.GET_LANES_SUCCESS:
      const next = action.payload.next ? getParseParams(action.payload.next.split('?')[1]) : null;
      const previous = action.payload.previous
        ? { offset: 0, ...getParseParams(action.payload.previous.split('?')[1]) }
        : null;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        lanes: action.payload.results,
        error: undefined,
        next,
        previous,
      };

    case TYPES.GET_LANE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_LANE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_LANE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Lane: action.payload,
        error: undefined,
      };

    case TYPES.GET_LANE_QUERY_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_LANE_QUERY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_LANE_QUERY_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        LanesQS: action.payload,
        error: undefined,
      };

    case TYPES.CREATE_LANE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.CREATE_LANE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        lanes: [...state.lanes, action.payload],
        error: undefined,
      };
    case TYPES.CREATE_LANE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_LANE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_LANE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        Lane: { ...state.Lane, ...action.payload },
        error: undefined,
      };
    case TYPES.UPDATE_LANE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SAVE_LANE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_LANE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.SAVE_LANE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_LANE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.DELETE_LANE_SUCCESS:
      const deletedLanes = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        lanes: state.lanes.filter(lane => lane.id !== deletedLanes.id),
        error: undefined,
      };
    case TYPES.DELETE_LANE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_LANES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_LANES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.DELETE_LANES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};
