import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';

export const OverviewContainer = styled.div`
  display: flex;
  width: 100%;
  .craOverview-content-div0 {
    margin-right: 2%;
  }
  .craOverview-content {
    width: 49%;
  }
`;

export const ExpandableText = styled(props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const hasElipsis = el => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        const hadClampClass = containerRef.current.classList.contains('not-expanded');
        if (!hadClampClass) containerRef.current.classList.add('not-expanded');
        setReadMore(hasElipsis(containerRef.current));
        if (!hadClampClass) containerRef.current.classList.remove('not-expanded');
      }
    };

    const debouncedCheck = _.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef]);

  return (
    <>
      <div
        ref={containerRef}
        className={`expandable-text-container ${isExpanded ? '' : 'not-expanded'} ${props.className}`}
      >
        {props.text ? (
          <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
        ) : (
          <h3 className={`no-content ${props.className}`}>{props.noContentMessage}</h3>
        )}
      </div>
      {readMore ? (
        <div
          className={`read-more-link ${props.className}`}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </div>
      ) : null}
    </>
  );
})`
  &.read-more-link {
    float: right;
    color: ${props => props.theme.colors.primary};
    &:hover {
      color: ${props => props.theme.colors.white};
      cursor: pointer;
    }
    h3.no-content {
      width: 100%;
      text-align: center;
    }
  }
  &.not-expanded {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
`;
