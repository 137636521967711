import axios from 'axiosInstance';

import { callApi } from 'utils';
import { REQUESTED_DATA_STATUS } from 'admin/constants';

export const TYPES = {
  GET_EVENT_PLAN: 'RISKFEED/GET_EVENT_PLAN_PENDING',
  GET_EVENT_PLAN_SUCCESS: 'RISKFEED/GET_EVENT_PLAN_SUCCESS',
  GET_EVENT_PLAN_ERROR: 'RISKFEED/GET_EVENT_PLAN_ERROR',
  UPDATE_EVENT_PLAN: 'RISKFEED/UPDATE_EVENT_PLAN_PENDING',
  UPDATE_EVENT_PLAN_SUCCESS: 'RISKFEED/UPDATE_EVENT_PLAN_SUCCESS',
  UPDATE_EVENT_PLAN_ERROR: 'RISKFEED/UPDATE_EVENT_PLAN_ERROR',
  DELETE_EVENT_PLAN: 'RISKFEED/DELETE_EVENT_PLAN_PENDING',
  DELETE_EVENT_PLAN_SUCCESS: 'RISKFEED/DELETE_EVENT_PLAN_SUCCESS',
  DELETE_EVENT_PLAN_ERROR: 'RISKFEED/DELETE_EVENT_PLAN_ERROR',
  GET_EVENT_TASK: 'RISKFEED/GET_EVENT_TASK_PENDING',
  GET_EVENT_TASK_SUCCESS: 'RISKFEED/GET_EVENT_TASK_SUCCESS',
  GET_EVENT_TASK_ERROR: 'RISKFEED/GET_EVENT_TASK_ERROR',
  CREATE_EVENT_TASK: 'RISKFEED/CREATE_EVENT_TASK_PENDING',
  CREATE_EVENT_TASK_SUCCESS: 'RISKFEED/CREATE_EVENT_TASK_SUCCESS',
  CREATE_EVENT_TASK_ERROR: 'RISKFEED/CREATE_EVENT_TASK_ERROR',
  UPDATE_EVENT_TASK: 'RISKFEED/UPDATE_EVENT_TASK_PENDING',
  UPDATE_EVENT_TASK_SUCCESS: 'RISKFEED/UPDATE_EVENT_TASK_SUCCESS',
  UPDATE_EVENT_TASK_ERROR: 'RISKFEED/UPDATE_EVENT_TASK_ERROR',
  DELETE_EVENT_TASK: 'RISKFEED/DELETE_EVENT_TASK_PENDING',
  DELETE_EVENT_TASK_SUCCESS: 'RISKFEED/DELETE_EVENT_TASK_SUCCESS',
  DELETE_EVENT_TASK_ERROR: 'RISKFEED/DELETE_EVENT_TASK_ERROR',
  TOGGLE_EDIT: 'RISKFEED/TOGGLE_EDIT',
  ADD_TASK: 'RISKFEED/ADD_TASK',
  RESET_EVENT_PLAN: 'RISKFEED/RESET_EVENT_PLAN',
  RESET_EVENT_TASK_STATUS: 'RISKFEED/RESET_EVENT_TASK_STATUS',
};

export const INITIAL_STATE = {
  initialized: false,
  error: false,
  plan: {
    item: null,
    error: false,
    loading: false,
    initialized: false,
  },
  tasks: {
    items: [],
    loading: false,
    error: false,
  },
  taskStatus: {
    isTaskCreated: false,
    isTaskUpdated: false,
    isTaskDeleted: false,
  },
};

export const DATE_FORMAT = 'YYYY/MM/DD';

export const defaultTask = () => {
  return {
    id: null,
    name: '',
    status: 'pending',
    due_date: null,
    assignees: [],
  };
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.GET_EVENT_PLAN:
      return {
        ...state,
        plan: {
          item: null,
          error: false,
          loading: true,
          initialized: false,
        },
        tasks: {
          item: null,
          items: [],
          loading: true,
          error: false,
        },
      };
    case TYPES.TOGGLE_EDIT:
      const editItem = state.tasks.items.find(x => x.id === action.payload.id);
      const editStatus = editItem?.edit === false;
      const taskItems = state.tasks.items.map(x => {
        if (x.id === action.payload.id) {
          return { ...x, edit: editStatus };
        }
        return { ...x, edit: false };
      });
      const taskItem = editStatus ? taskItems.find(x => x.id === action.payload.id) : null;
      return {
        ...state,
        tasks: {
          items: taskItems,
          item: taskItem,
          loading: true,
          error: false,
        },
      };
    case TYPES.ADD_TASK:
      return {
        ...state,
        tasks: {
          items: state.tasks.items.map(x => {
            return { ...x, edit: false };
          }),
          item: defaultTask(),
          loading: true,
          error: false,
        },
      };

    case TYPES.GET_EVENT_PLAN_SUCCESS:
      const { tasks, ...plan } = action.payload;
      return {
        ...state,
        plan: {
          item: plan,
          error: false,
          loading: false,
          initialized: true,
        },
        tasks: {
          item: null,
          items: tasks.map(x => {
            return { ...x, edit: false };
          }),
          loading: false,
          error: false,
        },
      };
    case TYPES.GET_EVENT_PLAN_ERROR:
      if (action.payload.status_code === 404) {
        return {
          ...state,
          plan: {
            item: {
              owner: null,
              status: 'new',
            },
            error: false,
            loading: true,
            initialized: true,
          },
          tasks: {
            item: null,
            items: [],
            loading: false,
            error: true,
          },
        };
      } else {
        return {
          ...state,
          plan: {
            item: null,
            error: true,
            loading: false,
            initialized: true,
          },
          tasks: {
            item: null,
            items: [],
            loading: false,
            error: true,
          },
        };
      }
    case TYPES.UPDATE_EVENT_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          error: false,
          loading: true,
        },
      };
    case TYPES.UPDATE_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          item: action.payload,
          error: false,
          loading: false,
          initialized: true,
        },
      };
    case TYPES.UPDATE_EVENT_PLAN_ERROR:
      return {
        ...state,
        plan: {
          item: null,
          error: true,
          loading: false,
          initialized: true,
        },
      };
    case TYPES.DELETE_EVENT_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          error: false,
          loading: true,
        },
      };
    case TYPES.DELETE_EVENT_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          item: null,
          error: false,
          loading: false,
          initialized: true,
        },
      };
    case TYPES.DELETE_EVENT_PLAN_ERROR:
      return {
        ...state,
        plan: {
          item: null,
          error: true,
          loading: false,
          initialized: true,
        },
      };
    case TYPES.CREATE_EVENT_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: true,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskCreated: false,
        },
      };
    case TYPES.CREATE_EVENT_TASK_SUCCESS:
      return {
        ...state,
        tasks: {
          items: [...state.tasks.items, action.payload],
          loading: false,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskCreated: true,
        },
      };
    case TYPES.CREATE_EVENT_TASK_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        taskStatus: {
          ...state.taskStatus,
          isTaskCreated: false,
        },
      };
    case TYPES.UPDATE_EVENT_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: true,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskUpdated: false,
        },
      };
    case TYPES.UPDATE_EVENT_TASK_SUCCESS:
      return {
        ...state,
        tasks: {
          items: state.tasks.items.map(x => {
            if (x.id === action.payload.id) {
              return action.payload;
            } else {
              return x;
            }
          }),
          loading: false,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskUpdated: true,
        },
      };
    case TYPES.UPDATE_EVENT_TASK_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: false,
          error: true,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskUpdated: false,
        },
      };
    case TYPES.DELETE_EVENT_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: true,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskDeleted: false,
        },
      };
    case TYPES.DELETE_EVENT_TASK_SUCCESS:
      return {
        ...state,
        tasks: {
          item: null,
          items: state.tasks.items.filter(x => {
            return x.id !== action.params.taskId;
          }),
          loading: false,
          error: false,
        },
        taskStatus: {
          ...state.taskStatus,
          isTaskDeleted: true,
        },
      };
    case TYPES.DELETE_EVENT_TASK_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        taskStatus: {
          ...state.taskStatus,
          isTaskDeleted: false,
        },
      };
    case TYPES.RESET_EVENT_PLAN:
      return {
        ...INITIAL_STATE,
      };
    case TYPES.RESET_EVENT_TASK_STATUS:
      return {
        ...state,
        taskStatus: { ...INITIAL_STATE.taskStatus },
      };
    default:
      return state;
  }
}

export const getPlan = threatId => {
  return callApi({
    types: {
      pending: TYPES.GET_EVENT_PLAN,
      success: TYPES.GET_EVENT_PLAN_SUCCESS,
      error: TYPES.GET_EVENT_PLAN_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/`;
      return axios.get(url);
    },
  });
};

export const updatePlan = (threatId, plan) => {
  return callApi({
    types: {
      pending: TYPES.UPDATE_EVENT_PLAN,
      success: TYPES.UPDATE_EVENT_PLAN_SUCCESS,
      error: TYPES.UPDATE_EVENT_PLAN_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/`;
      return axios.put(url, plan);
    },
  });
};

export const deletePlan = threatId => {
  return callApi({
    types: {
      pending: TYPES.DELETE_EVENT_PLAN,
      success: TYPES.DELETE_EVENT_PLAN_SUCCESS,
      error: TYPES.DELETE_EVENT_PLAN_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/`;
      return axios.delete(url);
    },
  });
};

export const getTask = (threatId, taskId) => {
  return callApi({
    types: {
      pending: TYPES.GET_EVENT_TASK,
      success: TYPES.GET_EVENT_TASK_SUCCESS,
      error: TYPES.GET_EVENT_TASK_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/tasks/${taskId}/`;
      return axios.get(url);
    },
  });
};

export const createTask = (threatId, task) => {
  return callApi({
    types: {
      pending: TYPES.CREATE_EVENT_TASK,
      success: TYPES.CREATE_EVENT_TASK_SUCCESS,
      error: TYPES.CREATE_EVENT_TASK_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/tasks/`;
      return axios.post(url, task);
    },
  });
};

export const updateTask = (threatId, task) => {
  return callApi({
    types: {
      pending: TYPES.UPDATE_EVENT_TASK,
      success: TYPES.UPDATE_EVENT_TASK_SUCCESS,
      error: TYPES.UPDATE_EVENT_TASK_ERROR,
    },
    request: () => {
      const url = `events/threats/${threatId}/plan/tasks/${task.id}/`;
      return axios.put(url, task);
    },
  });
};

export const saveTask = (threatId, task) => {
  if (task.id === null) {
    return createTask(threatId, task);
  }
  return updateTask(threatId, task);
};

export const deleteTask = (threatId, taskId) => {
  return callApi({
    types: {
      pending: TYPES.DELETE_EVENT_TASK,
      success: TYPES.DELETE_EVENT_TASK_SUCCESS,
      error: TYPES.DELETE_EVENT_TASK_ERROR,
    },
    params: { threatId, taskId },

    request: () => {
      const url = `events/threats/${threatId}/plan/tasks/${taskId}/`;
      return axios.delete(url, taskId);
    },
  });
};

export const toggleEdit = id => {
  return { type: TYPES.TOGGLE_EDIT, payload: { id } };
};

export const addTask = () => {
  return { type: TYPES.ADD_TASK };
};

export const removePlan = () => {
  return { type: TYPES.RESET_EVENT_PLAN };
};
