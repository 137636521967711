import React, { useCallback, useEffect } from 'react';
import GroupView from '@luxe/components/src/GroupView';
import { useDispatch, useSelector } from 'react-redux';
import { clearViewData, getViewMeta } from 'views/modules/views';
import {
  getCorporates,
  deleteCorporate,
  createCorporates,
  updateCorporates,
  removeAssetFromCorp,
  addAssetToCorp,
} from '../../modules/corporates';
import { fetchAssets } from 'admin/corporates/modules/utils';
import { getEditLink } from './utils';

const CorporatesView = () => {
  const views = useSelector(store => store.views);
  const corporates = useSelector(store => store.corporates.corporates);
  const assets = views?.['assets'] || {};
  const assetsMeta = assets.meta;
  const assetItems = assets.items?.map(item => ({ ...item, name: item.asset.name })) || [];
  const paginationData = (({ pending, next, previous, currentUrl }) => ({ pending, next, previous, currentUrl }))(
    assets,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCorporates());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewData('assets'));
    },
    [dispatch],
  );

  const fetchData = useCallback(
    ({ selectedGroup, searchTerm, currentPage, orderBy }) => {
      return dispatch(fetchAssets({ selectedGroup, searchTerm, currentPage, orderBy }));
    },
    [dispatch],
  );

  const fetchMeta = useCallback(
    fetchData => {
      return dispatch(getViewMeta('assets', undefined, undefined, fetchData));
    },
    [dispatch],
  );

  const assetColumns = [
    {
      title: 'Name',
      dataIndex: ['asset', 'name'],
      sorter: true,
      orderBy: 'asset__name',
    },
    {
      title: 'Facility Type',
      dataIndex: 'org_type',
      sorter: true,
      orderBy: 'org_type',
    },
    {
      title: 'Actions',
      render: asset => getEditLink(asset),
    },
  ];
  return (
    <GroupView
      groups={corporates}
      groupsURL={'/corporations/'}
      title={'Corporations'}
      columns={assetColumns}
      rowData={assetItems}
      fetchData={fetchData}
      meta={assetsMeta}
      fetchMeta={fetchMeta}
      itemName={'asset'}
      createGroupFunction={createCorporates}
      editGroupFunction={updateCorporates}
      deleteGroupFunction={deleteCorporate}
      addItemsToGroupFunction={addAssetToCorp}
      removeItemsFromGroupFunction={removeAssetFromCorp}
      paginationData={paginationData}
    />
  );
};

export default CorporatesView;
