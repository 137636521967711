import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/productgroups/modules/productgroups';
import { getProductGroups } from 'admin/productgroups/modules/productgroups';
import { fetchProducts } from 'admin/productgroups/modules/utils';
import { getProduct } from 'admin/products/modules/products';

const refetchProductGroups = (action$, state$) => {
  const refetchTypes = [
    TYPES.CREATE_PRODUCT_GROUPS_SUCCESS,
    TYPES.UPDATE_PRODUCT_GROUPS_SUCCESS,
    TYPES.DELETE_PRODUCT_GROUPS_SUCCESS,
  ];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getProductGroups()),
  );
};

const refetchProducts = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_PRODUCTS_TO_GROUP_SUCCESS, TYPES.REMOVE_PRODUCTS_FROM_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && !action.params.isSingle),
    map(action => fetchProducts(action.params)),
  );
};

const refetchProduct = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_PRODUCTS_TO_GROUP_SUCCESS, TYPES.REMOVE_PRODUCTS_FROM_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && action.params.isSingle),
    map(action => getProduct(action.params.id)),
  );
};

const epics = {
  refetchProductGroups,
  refetchProducts,
  refetchProduct,
};

export default epics;
