import React from 'react';
import { Col, Row } from 'antd';
import { FormItem, Label, Numeric, Section } from '@luxe/components';

const MaterialSettingsSection = props => {
  return (
    <>
      <Section title={'General'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="description" label="Description" labelCol={{ span: 24 }}>
              <Label width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="totalSpend" label="Total Spend" labelCol={{ span: 24 }} style={{ marginLeft: '20px' }}>
              <Numeric width={'100%'} />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem name="totalQuantity" label="Total Quantity" labelCol={{ span: 24 }}>
              <Numeric width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="totalQuantityUnit"
              label="Total Quantity Unit"
              labelCol={{ span: 24 }}
              style={{ marginLeft: '20px' }}
            >
              <Numeric width={'100%'} />
            </FormItem>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default MaterialSettingsSection;
