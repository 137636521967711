import React from 'react';
import { EditIcon } from '@luxe/components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import RemoveRule from '@luxe/components/src/RemoveRule';
import { deleteRule } from 'admin/workflows/modules/rules';

export const getEditLink = (rule, currentApp) => {
  let url;
  if (currentApp === 'users') {
    url = '/users/network/' + rule.workflow + '/network/rules/' + rule.id + '/';
  } else {
    url = '/admin/workflows/' + rule.workflow + '/network/rules/' + rule.id + '/';
  }
  return (
    <div>
      <Link to={url}>
        <EditIcon style={{ marginRight: '5px' }} />
      </Link>
      <Tooltip title="Remove Rule from workflow">
        <RemoveRule rule={rule} itemName={'workflow'} deleteRuleFunction={deleteRule} />
      </Tooltip>
    </div>
  );
};
