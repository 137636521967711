import React, { useMemo, useState } from 'react';
import { SearchInput, Section, Table, Empty } from '@luxe/components';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function SearchTable({
  onSearchChange,
  itemName,
  onPageChange,
  tableKey,
  searchPlaceHolderText,
  selectedRows,
  displayHidden,
  paginationData,
  isServerSide,
  onSort,
  onRow,
  ...props
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce(searchTerm => {
        setCurrentPage(1);
        onPageChange(1);
        onSearchChange(searchTerm);
      }, 350),
    [onPageChange, onSearchChange],
  );

  function changePage(pageNumber) {
    if (isServerSide) {
      onPageChange(pageNumber);
    }
    setCurrentPage(pageNumber);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      const sort = value => {
        if (onSort) {
          setCurrentPage(1);
          onPageChange(1);
          return onSort(value);
        }
        return false;
      };

      if (!sorter.order || !sorter.column?.orderBy) {
        return sort([]);
      }
      if (sorter.order === 'ascend') {
        return sort([{ name: sorter.column.orderBy, direction: 'asc' }]);
      }
      if (sorter.order === 'descend') {
        return sort([{ name: sorter.column.orderBy, direction: 'desc' }]);
      }
    }
  };

  let searchDisplay = 'visible';

  if (displayHidden === true) {
    searchDisplay = 'hidden';
  }

  if (itemName === 'user') {
    searchDisplay = 'visible';
  }

  const getTotalPagecount = () => {
    if (isServerSide) {
      // if the paginationData is provided from the parent and it has next link
      // Enable the next pagination button by increasing the total count
      if (paginationData.next) {
        return (currentPage + 1) * 10;
      } else if (paginationData.next === null) {
        return (currentPage - 1) * 10 + props.rowData?.length;
      } else {
        return 99999999;
      }
    } else {
      return props.rowData?.length;
    }
  };
  const searchInput = (
    <SearchInput
      key={`${tableKey}-search`}
      display={searchDisplay}
      placeholder={searchPlaceHolderText || ''}
      onChange={e => {
        debouncedChangeHandler(e.target.value);
      }}
    />
  );
  const rowSelectionProps = props.selectable
    ? {
        type: 'checkbox',
        onChange: props.onSelect,
        selectedRowKeys: selectedRows,
      }
    : false;
  return (
    <Section
      title={props.title || ''}
      actionText={props.actionText}
      actionButtons={props.actionButtons}
      buttons={[...props.buttons, searchInput]}
      className={props.className}
    >
      <div style={{ width: '100%' }}>
        <Table
          rowKey={props.rowKey}
          columns={props.columns}
          dataSource={props.rowData}
          description={props.description}
          locale={{ emptyText: <Empty description={paginationData.pending ? 'Loading' : 'No Data'} /> }}
          rowSelection={rowSelectionProps}
          onChange={onChange}
          pagination={{
            current: currentPage,
            total: getTotalPagecount(),
            showQuickJumper: false,
            showSizeChanger: false,
            showTotal: false,
            showTitle: false,
            onChange: changePage,
            className: `server-side-pagination ${isServerSide && paginationData.pending ? 'loading' : ''}`,
            disabled: isServerSide && paginationData.pending,
          }}
          size={props.tableSize ?? 'medium'}
          scroll={{ x: 'auto' }}
          onRow={onRow}
        />
      </div>
    </Section>
  );
}

SearchTable.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  actionButtons: PropTypes.array,
  actionText: PropTypes.string,
  rowData: PropTypes.array,
  selectedRows: PropTypes.array,
  selectable: PropTypes.bool,
  isServerSide: PropTypes.bool,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  paginationData: PropTypes.object,
};

SearchTable.defaultProps = {
  title: '',
  buttons: [],
  actionButtons: [],
  actionText: '',
  rowData: [],
  selectedRows: [],
  selectable: false,
  isServerSide: true,
  onSelect: () => {},
  onSearchChange: () => {},
  paginationData: { pending: false },
};
