import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { flexbox } from 'styled-system';
import { dateRange } from 'constants/application';
import { useLocation } from 'react-router-dom';

const Pills = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: row;
  font-size: 12px;
  .pill-link {
    margin: 0 2px;
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid transparent;
    text-align: center;
    color: ${props => props.theme.colors.white} !important;
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
      color: ${props => props.theme.colors.white} !important;
      cursor: pointer;
    }
  }
  .active {
    background-color: ${props => props.theme.colors.BG5};
    color: ${props => props.theme.colors.white} !important;
  }
`;

export const FilterRangePills = props => {
  const { search } = useLocation();

  const [activeFilterTab, setActiveFilterTab] = useState('All');

  let filters = Object.values(dateRange);
  if (props.filters) {
    filters = props.filters;
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(search);
    for (const searchItem of searchParams.keys()) {
      if (searchItem.includes('threat__start')) {
        const startDate = searchParams.get(searchItem)?.split(',')[0];
        const currentFilter = filters.find(filterItem => filterItem.startDate === startDate);
        setActiveFilterTab(currentFilter?.title || '');
        return;
      }
    }
    setActiveFilterTab('All')
  }, [search, filters]);

  const onTabClicked = filter => {
    if (props.onTabClick) {
      props.onTabClick(filter);
    }
  };

  return (
    <>
      <Row>
        <p>{props.heading}</p>
      </Row>
      <Pills>
        {filters.map((filter, i) => {
          return (
            <span
              className={filter.title === activeFilterTab ? 'pill-link active' : 'pill-link'}
              key={i}
              onClick={() => onTabClicked(filter)}
            >
              {filter.title}
            </span>
          );
        })}
      </Pills>
    </>
  );
};

FilterRangePills.propTypes = {
  onTabClick: PropTypes.func,
  filters: PropTypes.array,
};
