import React from 'react';
import { Numeric as InputNumber } from '@luxe/components';

import styled from '@emotion/styled';

const RelationshipStrengthInput = styled(({ className, label, value, onChange }) => {
  const handleValueChange = event => {
    onChange(event.target.value); // parent component handles state
  };

  return (
    <div className={className}>
      <label>{label}</label>
      <InputNumber min={0.0} max={1.0} step={0.01} value={value} onBlur={handleValueChange} precision={2} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  & label {
    margin-bottom: 5px;
  }

  & .ant-input-number {
    border: none;
    font-size: 1rem;
    padding: 5px;
    width: calc(100% - 20px);
  }
`;

export default RelationshipStrengthInput;
