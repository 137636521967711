import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider, Empty, TreeSelect } from 'antd';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { getCommodities, getCountries } from 'modal/userprofile/modules/network';
import { updateWorkflow } from 'admin/workflows/modules/workflows';
import LoadingScreen from 'layout/containers/LoadingScreen';
import useMixpanel from 'utils/hooks/Mixpanel';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select } from '@luxe/components';

const CommoditiesTab = styled(({ includedChannels, ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { commodities, countries: countryData } = useSelector(store => store.userNetwork);
  const [formattedCommodities, setFormattedCommodities] = useState([]);
  const [formattedCountries, setFormattedCountries] = useState([]);
  const user_profile_commodities = props.workflow?.data.filtering?.commodities;
  const user_profile_countries = props.workflow?.data.filtering?.countries;
  const toolTipStyling = { backgroundColor: '#141414', fontSize: '12px', color: '#ffffff' };
  const isInUserProfile = props?.workflow?.user_profile;

  const countries = useMemo(
    () => (countryData?.length ? [{ id: '*', name: 'All Countries', region_type: 'COUNTRY' }, ...countryData] : []),
    [countryData],
  );

  const setChildrenFalse = useCallback((parents, list) => {
    parents.forEach(p => {
      p.disableCheckbox = !list.includes(p.title);
      if (p.children?.length > 0) setChildrenFalse(p.children, list);
    });
    return parents;
  }, []);

  useEffect(() => {
    isInUserProfile === null
      ? mixpanelTrack('Admin - Workflows - Commodities')
      : mixpanelTrack('User Profile - Network Settings - Commodities');
  }, [isInUserProfile, mixpanelTrack]);

  useEffect(() => {
    const populateChild = arr => {
      return arr.map(i => {
        return {
          title: i.name,
          value: i.id,
          key: i.id,
          children: i.children?.length > 0 ? populateChild(i.children) : [],
        };
      });
    };
    if (commodities?.length && formattedCommodities.length === 0) {
      setFormattedCommodities(populateChild(commodities));
    }
  }, [commodities, formattedCommodities.length]);

  useEffect(() => {
    const populate = arr => arr.map(i => ({ label: i.name, value: i.id }));
    if (countries?.length && formattedCountries.length === 0) {
      setFormattedCountries(populate(countries));
    }
  }, [countries, formattedCountries.length]);

  useEffect(() => {
    dispatch(getCommodities());
    dispatch(getCountries());
  }, [dispatch]);

  const updateCommodities = commodities => {
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            commodities,
          },
        },
      }),
    );
  };

  const updateCountries = countries => {
    const currentFiltering = props.workflow?.data?.filtering;
    dispatch(
      updateWorkflow({
        data: {
          filtering: {
            ...currentFiltering,
            countries: (countries ?? []).map(c => c.value),
          },
        },
      }),
    );
  };

  if (commodities?.length === 0 || countries?.length === 0) {
    return (
      <div className={'loader'}>
        <LoadingScreen modal={true} />
      </div>
    );
  }

  const renderEmpty = () => (
    <Empty
      imageStyle={{ height: 40 }}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span style={{ color: 'white' }}> No Data </span>}
    />
  );

  return (
    <div className={`${props.className}`}>
      <div className={'profile-content'}>
        <div className={'profile-network'}>
          <ConfigProvider renderEmpty={renderEmpty}>
            <>
              <label>Commodities </label>
              <Tooltip title=" Incidents that are relevant to a given commodity" overlayInnerStyle={toolTipStyling}>
                <InfoCircleOutlined />
              </Tooltip>
              <div className={`${props.className}`}>
                <Global
                  styles={css`
                    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
                      background-color: #2d8fa5 !important;
                      border-color: #2d8fa5 !important;
                    }
                  `}
                />{' '}
                <TreeSelect
                  allowClear={true}
                  name="commodities"
                  onChange={updateCommodities}
                  placeholder={'Select Commodities'}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  showSearch={true}
                  treeCheckable={true}
                  treeData={formattedCommodities}
                  value={user_profile_commodities}
                  dropdownStyle={{
                    backgroundColor: '#212121',
                  }}
                  filterTreeNode={(search, option) => {
                    return (
                      option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                    );
                  }}
                />
                <br />
                <label>Countries </label>
                <Select
                  showSearch={false}
                  className={'commodity-country'}
                  isMulti={true}
                  backspaceRemoves
                  options={formattedCountries}
                  value={(countries?.filter(c => user_profile_countries?.includes(c.id)) ?? []).map(c => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  onChange={updateCountries}
                  name="countries"
                />
              </div>
            </>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
})`
  &.dark {
    background-color: #131c1e;

    h1,
    h2,
    h3,
    p,
    div {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .ant-picker-input > input {
    color: #ffffff;
  }

  .ant-select {
    background-color: #2c2c2c;
    color: #919191;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
  }
  .ant-tree-select {
    display: block;
    width: 50%;
  }
  .ant-select-tree,
  .ant-select-dropdown,
  .ant-tree-select-dropdown,
  .ant-tree-select-dropdown .ant-select-tree,
  .ant-select-multiple .ant-select-selection-item {
    background-color: #40424e;
    border: 1px solid #54596e;
    color: #ccc5b8;
    font-size: 85%;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #cccccc;
  }
  .loader {
    color: ${props => props.theme.colors.white};
  }

  label {
    color: ${props => props.theme.colors.white};
  }

  .commodity-country {
    width: 50%;
  }
  .ant-select-clear {
    background-color: transparent;
  }
`;

export default CommoditiesTab;
