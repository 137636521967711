import styled from '@emotion/styled';
import { Row, Tooltip } from 'antd';
import theme from 'theme';

export const LocationDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  height: 160px;
  width: 100%;
  margin-top: ${theme.space.xsmall}px;
  justify-content: start;
  align-content: center;

  & > a {
    width: calc(16.67%);
    font: ${theme.fonts.body};
    font-size: 10px;
    border: 1px solid ${theme.colors.black3};
    padding: 0 3px;
    height: 50px;
  }

  & div.location-detail-risk-item-label p:nth-of-type(2) {
    margin: 0;
    font-weight: ${theme.fontWeights.bold};
  }

  & div.location-detail-risk-item-label p:nth-of-type(1) {
    margin: 0;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: 2) {
      width: 100%;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  & > a.risk-chart-item-none {
    background-color: ${theme.colors.noRisk};
  }
  & > a.risk-chart-item-low {
    background-color: ${theme.colors.lowRisk};
  }
  & > a.risk-chart-item-medium {
    background-color: ${theme.colors.medRisk};
  }
  & > a.risk-chart-item-high {
    background-color: ${theme.colors.highRisk};
  }
`;

export const HeatMapRow = styled(Row)`
  position: relative;

  & span.anticon-reload {
    position: absolute;
    right: 0;
    margin-top: 8px;
    font-size: 15px;
  }

  & .ant-layout {
    background-color: transparent;
    margin-top: 8px;
  }
`;

export const CompTooltip = styled(Tooltip)`
  & div.ant-tooltip {
    min-width: 400px;
  }
  & div.ant-tooltip-inner {
    background: ${theme.colors.black3};
    height: 250px;
    padding: 15px;
  }
`;
