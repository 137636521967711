import React, { useCallback, useEffect } from 'react';
import GroupView from '@luxe/components/src/GroupView';
import { stringSorter } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserGroups,
  deleteUserGroup,
  createUserGroups,
  updateUserGroups,
  removeUserFromGroup,
  addUsersToGroup,
} from 'admin/usergroups/modules/userGroups';
import { getEditLink } from './utils';
import { fetchUsers } from 'admin/usergroups/modules/utils';

const UserGroupsView = () => {
  const users = useSelector(store => store.users)?.users;
  const pendingStatus = useSelector(store => store.users.status === 'PENDING');
  const dispatch = useDispatch();

  const userGroups = useSelector(store => store.userGroups)?.userGroups || [];

  useEffect(() => {
    dispatch(getUserGroups());
  }, [dispatch]);

  const fetchData = useCallback(
    ({ selectedGroup, searchTerm }) => dispatch(fetchUsers({ selectedGroup, searchTerm })),
    [dispatch],
  );

  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'User ID',
      dataIndex: 'id',
      sorter: (a, b) => stringSorter(a.id, b.id),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => stringSorter(a.email, b.email),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      sorter: (a, b) => stringSorter(a.phone, b.phone),
    },
    {
      title: 'Type',
      render: user => (user.permissions && user.permissions[0] ? user.permissions[0] : user.type),
    },
    {
      title: 'Actions',
      render: user => getEditLink(user),
    },
  ];

  return (
    <GroupView
      groups={userGroups}
      groupsURL={'/user-groups/'}
      title={'Users & Groups'}
      columns={userColumns}
      rowData={users}
      fetchData={fetchData}
      meta={{}}
      itemName={'user'}
      createGroupFunction={createUserGroups}
      editGroupFunction={updateUserGroups}
      deleteGroupFunction={deleteUserGroup}
      addItemsToGroupFunction={addUsersToGroup}
      removeItemsFromGroupFunction={removeUserFromGroup}
      isServerSide={false}
      paginationData={{ pending: pendingStatus }}
    />
  );
};

export default UserGroupsView;
