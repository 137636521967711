import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { urlParam } from 'utils';
import { Button, Skeleton, Label } from '@luxe/components';
import TaskList from './TaskList';
import { Breadcrumb, Col, Row, Tooltip } from 'antd';
import { addTask, getPlan, updatePlan } from 'riskfeed/modules/plans';
import Icon from 'layout/icons/Icon';
import UserSelector from 'common/components/UserSelector';
import { Select } from 'layout/Select';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';
import _ from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadPlans, TYPES as RISKFEED_TYPES } from 'riskfeed/modules/riskfeed';
import useMixpanel from 'utils/hooks/Mixpanel';

const statusOptions = [
  { value: 'PENDING', label: 'To Do' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'BLOCKED', label: 'Blocked' },
  { value: 'COMPLETE', label: 'Complete' },
];

const ActionDetail = styled(({ ...props }) => {
  const { channel = 'NETWORK_SUMMARY', mixpanel_event } = urlParam();
  const { id: threatId = null } = useParams();
  const dispatch = useDispatch();
  const { threat } = useSelector(state => state.riskfeed);
  const { plan, tasks } = useSelector(state => state.plans);
  const hasPlan = threat?.item?.has_plan;
  const mixpanelTrack = useMixpanel();
  const { exportPlans } = useSelector(state => state.riskfeed);
  const { exportPlanData } = exportPlans || {};

  const [isNameEdit, setIsNameEdit] = useState(false);
  const [name, setName] = useState(null);
  const [budget, setBudget] = useState(null);
  const [status, setStatus] = useState(null);
  const [owner, setOwner] = useState(null);

  const loading = [
    threat.loading,
    threat.item?.loading,
    threat.items?.loading,
    plan.loading,
    plan.item?.loading,
    plan.item?.threat_id && plan.item.threat_id !== threatId,
  ].some(x => x);

  useEffect(() => {
    if (mixpanel_event === 'action_plan_email_action_plan_link') {
      mixpanelTrack('Action Plan Email - Action Plan Link');
      window.history.replaceState(null, null, '?channel=MY_PLANS');
    }
    if (mixpanel_event === 'action_plan_email_action_plan_button') {
      mixpanelTrack('Action Plan Email - Action Plan Button');
      window.history.replaceState(null, null, '?channel=MY_PLANS');
    }
  }, [mixpanel_event, mixpanelTrack]);

  useEffect(() => {
    if (threatId) {
      if (hasPlan === true) {
        dispatch(getPlan(threatId));
      } else if (hasPlan === false) {
        dispatch(updatePlan(threatId));
      }
    }
  }, [threatId, hasPlan, dispatch]);

  useEffect(() => {
    if (plan?.item?.owner?.id) {
      setOwner({ display_name: plan?.item?.owner?.name, label: plan?.item?.owner?.name, value: plan?.item?.owner?.id });
    }
    if (plan?.item?.budget) {
      setBudget(plan?.item?.budget);
    } else {
      setBudget(null);
    }
    if (plan?.item?.status) {
      let statusOption = statusOptions.find(val => val.value === plan?.item?.status);
      if (statusOption) {
        setStatus(statusOption);
      }
    }
    if (plan?.item?.name) {
      setName(plan?.item?.name);
    } else if (threat?.item?.name) {
      setName(threat?.item?.name);
    }
  }, [plan, threat]);

  useEffect(() => {
    if (exportPlanData) {
      mixpanelTrack('Action Plan - Export');
      dispatch({
        type: OPERATION_TYPES.SHOW_NOTIFICATION,
        payload: {
          content: 'An email will be sent to your inbox when the export is ready and will be available for 24 hours.',
          type: 'success',
        },
      });
      dispatch({
        type: RISKFEED_TYPES.RESET_EXPORT_PLAN_DATA,
      });
    }
  }, [exportPlanData, dispatch, mixpanelTrack]);

  const updatePlanData = useCallback(
    data => {
      dispatch(updatePlan(threatId, data));
      setIsNameEdit(false);
    },
    [dispatch, threatId],
  );

  const handleNameError = () => {
    dispatch({
      type: OPERATION_TYPES.SHOW_NOTIFICATION,
      payload: {
        content: 'Invalid Name',
        type: 'error',
      },
    });
  };

  const handleCancel = () => {
    if (plan?.item?.name) {
      setName(plan?.item?.name);
    } else if (threat?.item?.name) {
      setName(threat?.item?.name);
    }
    setIsNameEdit(false);
  };

  const handleKeyPress = e => {
    if (e?.charCode === 13) {
      if (name) {
        updatePlanData({ name: name });
      } else {
        handleNameError();
      }
    }
  };

  const checkIsPositiveInteger = val => {
    if (!val) return false;
    const number = Number(val);
    const isInteger = Number.isInteger(number);
    const isPositive = number >= 0;
    return isInteger && isPositive;
  };

  const handleBudgetChange = value => {
    const isPositiveInteger = checkIsPositiveInteger(value);
    if (!value || isPositiveInteger) {
      setBudget(value);
    }
    debouncedChangeHandler(value);
  };

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce(value => {
        const isPositiveInteger = checkIsPositiveInteger(value);
        if (!value || isPositiveInteger) {
          updatePlanData({ budget: value ? value : 0 });
        } else {
          dispatch({
            type: OPERATION_TYPES.SHOW_NOTIFICATION,
            payload: {
              content: 'Integer values only',
              type: 'error',
              duration: 1,
            },
          });
        }
      }, 1500),
    [dispatch, updatePlanData],
  );

  const handleOwnerChange = val => {
    setOwner(val);
    updatePlanData({ owner_id: val?.value });
  };

  const handleExport = () => {
    dispatch(
      downloadPlans({
        threat_id: threatId,
      }),
    );
  };

  if (loading) {
    return (
      <div className={`${props.className} detail plan`}>
        <Breadcrumb className="nav">
          <Breadcrumb.Item>
            <Link to={`/risk-feed/threats/${threatId}/overview/?channel=${channel}`}>Back to Incident</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3>Action Plan</h3>
        <Skeleton paragraph={3} />
      </div>
    );
  }

  return (
    <div className={`${props.className} detail plan`}>
      <Breadcrumb className="nav">
        <Breadcrumb.Item>
          <Link to={`/risk-feed/threats/${threatId}/overview/?channel=${channel}`}>Back to Incident</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '20px' }}>
        <Col className="gutter-row" span={12}>
          <h3>Action Plan</h3>
          {isNameEdit ? (
            <div style={{ marginBottom: '10px' }}>
              <Row>
                <Col span={21}>
                  {' '}
                  <Label
                    autoComplete="off"
                    placeholder="Name"
                    width="100%"
                    maxLength="255"
                    value={name}
                    onChange={e => setName(e.target.value.trimStart())}
                    onKeyPress={handleKeyPress}
                  />
                </Col>
                <Col span={3}>
                  {' '}
                  <Icon
                    type="check"
                    className="ml-10"
                    onClick={() => (name ? updatePlanData({ name: name }) : handleNameError())}
                  />
                  <Icon type="cancel" className="ml-10" onClick={() => handleCancel()} />
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 className="text-break">{name}</h4>
              <Icon type="edit" className="ml-10" onClick={() => setIsNameEdit(true)} />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={12}>
          {tasks?.items?.length !== 0 && Array.isArray(tasks?.items) ? (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <div style={{ float: 'right', color: 'white', cursor: 'pointer', marginBottom: '5px' }}>
                  <Tooltip title="Click to receive an export of the underlying Action Plan data via email.">
                    <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleExport} />
                  </Tooltip>
                </div>
              </Col>
            </Row>
          ) : null}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={8}>
              {' '}
              <span className="fields-label">Owner</span>
              <UserSelector onChange={handleOwnerChange} value={owner} />
            </Col>
            <Col className="gutter-row" span={8}>
              {' '}
              <span className="fields-label">Budget</span>
              <Label
                autoComplete="off"
                placeholder="Budget"
                width="100%"
                maxLength="255"
                value={budget}
                onChange={e => handleBudgetChange(e.target.value.trim())}
              />
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="fields-label">Status</span>{' '}
              <Select
                key={name}
                isMulti={false}
                stayOpen={false}
                selected={status}
                options={statusOptions}
                backspaceRemoves
                placeholder="Select status"
                onChange={val => {
                  setStatus(val);
                  updatePlanData({ status: val?.value });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <TaskList plan={plan} tasks={tasks} threatId={threatId} />
      {/* {tasks?.item === null && ( */}
      <div className="add-button">
        <Button
          onClick={() => {
            dispatch(addTask());
          }}
          variant="secondary"
        >
          Add Task
        </Button>
      </div>
      {/* )} */}
    </div>
  );
})`
  &.plan {
    padding: 24px;
    border-left: 1px solid #141414;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  .ant-breadcrumb li:last-child a {
    color: white;
  }
  .nav {
    font-size: 13px;
  }
  .add-button {
    & > button > span {
      font-size: 14px;
    }
    display: flex;
    justify-content: center;
  }
  .text-break {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .fields-label {
    color: #dedede;
    font-size: 13px;
    letter-spacing: 1px;
  }
`;

export default ActionDetail;
