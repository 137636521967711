import React, { useEffect, useState } from 'react';
import { BasicModal as Modal, Button } from '@luxe/components';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { getIncidentHistory } from 'assets/modules/incidents';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDate } from 'assets/modules/utils';

function IncidentHistoryModal({ isModalVisible, closeModal, incidentId }) {
  const dispatch = useDispatch();
  const { incidentHistory, status } = useSelector(store => store.incidents);
  const [fetchHistoryInit, setFetchHistoryInit] = useState(false);

  useEffect(() => {
    if (incidentId && isModalVisible) {
      dispatch(getIncidentHistory({ incidentId }));
      setFetchHistoryInit(true);
    }
  }, [dispatch, incidentId, isModalVisible]);

  return (
    <Modal
      width={800}
      visible={isModalVisible}
      title="Incident History"
      okText="Close"
      onCancel={closeModal}
      onOk={closeModal}
      footer={
        <Button type="primary" onClick={closeModal}>
          Ok
        </Button>
      }
    >
      {status === 'SUCCESS' && fetchHistoryInit && !incidentHistory.length && (
        <h4 style={{ color: 'white', textAlign: 'center' }}>No history available for this incident</h4>
      )}

      {incidentHistory.map(revision => {
        return (
          <HistoryContainer key={revision.id}>
            <TopRow>
              <ChangedOnContainer>
                <HistoryHeader>Changed On</HistoryHeader>
                <HistoryText>{getFormattedDate(revision.modified_date, 'MMM DD, YYYY HH:mm')}</HistoryText>
              </ChangedOnContainer>
              <HeadlineContainer>
                <HistoryHeader>Headline</HistoryHeader>
                <HistoryText>{revision.headline}</HistoryText>
              </HeadlineContainer>
            </TopRow>
            <DescriptionContainer>
              <HistoryHeader>Description</HistoryHeader>
              <HistoryText>{revision.description}</HistoryText>
            </DescriptionContainer>
          </HistoryContainer>
        );
      })}
    </Modal>
  );
}

export default IncidentHistoryModal;

IncidentHistoryModal.propTypes = {
  incidentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const HistoryContainer = styled.div`
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;

  :nth-of-type(odd) {
    background-color: #383839;
  }
`;

const TopRow = styled.div`
  display: flex;
`;

const HistoryHeader = styled.div`
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 5px;
`;

const HistoryText = styled.div`
  color: rgba(255, 255, 255, 0.85);
`;

const ChangedOnContainer = styled.div`
  width: 33%;
  white-space: nowrap;
  margin-right: 10px;
`;

const HeadlineContainer = styled.div`
  width: 62%;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;
