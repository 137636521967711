import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';
import axios from 'axios';

const stringSorter = (first_string, second_string) => {
  return `${first_string}`.localeCompare(`${second_string}`);
};

const dateSorter = (first_date, second_date) => {
  const parsedFirstDate = new Date(first_date);
  const parsedSecondDate = new Date(second_date);
  return parsedFirstDate - parsedSecondDate;
};

const callApi = ({
  request,
  types,
  params,
  messages = {},
  showLoading,
  dynamicErrorMsg = false,
  parallelAPICalls = false,
  existingData,
}) => {
  const useLoadingSpinner = showLoading || request.toString().includes('.post') || request.toString().includes('.put');
  const orgRequest = parallelAPICalls ? axios.all(request) : request();
  return dispatch => {
    dispatch({ type: types.pending, params });
    useLoadingSpinner && dispatch({ type: OPERATION_TYPES.SHOW_LOADING_SPINNER });
    orgRequest
      .then(response => {
        dispatch({
          type: types.success,
          payload: parallelAPICalls ? response : response.data,
          params,
        });
        useLoadingSpinner && dispatch({ type: OPERATION_TYPES.HIDE_LOADING_SPINNER });
        if (existingData && existingData?.find(usr => response.data.id === usr.id)) {
          dispatch({
            type: OPERATION_TYPES.SHOW_NOTIFICATION,
            payload: {
              content: messages.userError,
              type: 'error',
            },
          });
        } else {
          messages.success &&
            dispatch({
              type: OPERATION_TYPES.SHOW_NOTIFICATION,
              payload: {
                content: messages.success,
                type: 'success',
              },
            });
        }
      })
      .catch(error => {
        const payload = error.response ? error.response.data : error.response;
        let responseMsg = dynamicErrorMsg && payload?.errors?.[0]?.message;
        dispatch({
          type: types.error,
          payload,
          params,
        });
        useLoadingSpinner && dispatch({ type: OPERATION_TYPES.HIDE_LOADING_SPINNER });
        messages.error &&
          dispatch({
            type: OPERATION_TYPES.SHOW_NOTIFICATION,
            payload: {
              content: responseMsg || messages.error,
              type: 'error',
            },
          });
      });
  };
};

const getRiskCategory = val => {
  const categoryTresholds = [9, 17];
  let category = null;
  if (val === null) {
    category = 'none';
  } else if (val < categoryTresholds[0]) {
    category = 'low';
  } else if (val < categoryTresholds[1]) {
    category = 'medium';
  } else if (val >= categoryTresholds[1]) {
    category = 'high';
  }
  return category;
};

const isEmpty = obj => {
  return Object.keys(obj).length === 0;
};

const changeAssetLabelToFacility = value => {
  if (value) {
    if (value.indexOf('Assets') !== -1) {
      value = value.replace(/Assets/, 'Facilities');
    } else if (value.indexOf('Asset') !== -1) {
      value = value.replace(/Asset/, 'Facility');
    }
  }
  return value;
};

const urlParam = () => {
  // Its 'Object'-like
  //   ... not a first class object, so look at this hoopla:
  const entries = [...new URLSearchParams(window.location.search).entries()];
  const obj = entries.reduce((acc, [attribute, value]) => {
    return { ...acc, [attribute]: value };
  }, {});
  return obj;
};

export { callApi, stringSorter, dateSorter, getRiskCategory, isEmpty, urlParam, changeAssetLabelToFacility };
