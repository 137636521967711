import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'layout/Select';
import { getOptions } from 'views/modules/options';

export default function AttributeSelect({ modelType, value, ...props }) {
  const dispatch = useDispatch();
  const options = useSelector(store => store.options);

  const [attributeOptions, setAttributeOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [modelAttributeName, setModelAttributeName] = useState(`${modelType}_attribute`);

  useEffect(() => {
    dispatch(
      getOptions({
        field: {
          name: `${modelType}_attribute`,
        },
        url: `account/${modelType}/attributes/`,
        valueKey: 'name',
        labelKey: 'display_name',
      }),
    );
    setModelAttributeName(`${modelType}_attribute`);
  }, [modelType, dispatch]);

  useEffect(() => {
    const modelAttributeOptions = options[modelAttributeName];
    if (modelAttributeOptions?.loading === false) {
      setAttributeOptions(modelAttributeOptions.options);
    }
  }, [options, modelAttributeName]);

  useEffect(() => {
    if (attributeOptions?.length && value) {
      const chosenOption = attributeOptions.find(item => item.value === value);
      if (chosenOption) {
        setSelectedValue(chosenOption);
      }
    }
  }, [attributeOptions, value]);

  const onChange = chosenOption => {
    if (props.onChange) {
      props.onChange(chosenOption);
    }
  };

  return <Select value={selectedValue} onChange={onChange} options={attributeOptions} isDisabled={props.disabled} />;
}
