import { getViewData } from 'views/modules/views';

export function fetchAssets({ selectedGroup, searchTerm, currentPage, orderBy }) {
  const pageOffset = (currentPage - 1) * 10;

  let query = {
    columns: ['asset', 'id', 'org_type', 'location'],
    url: 'assets',
    filters: [],
    limit: 10,
    offset: pageOffset,
  };
  if (orderBy) {
    query = { ...query, order_by: orderBy };
  }
  if (selectedGroup && selectedGroup.id !== 'all') {
    query.filters.push({ name: 'corporation_id', operator: '==', value: selectedGroup.id });
  }
  if (searchTerm) {
    query.filters.push({ name: 'query', operator: 'icontains', value: searchTerm });
  }
  return getViewData('assets', query);
}
