import { filter, map } from 'rxjs/operators';
import { getAsset, getAssetContacts, TYPES } from 'admin/assets/modules/assets';
import { push } from 'connected-react-router';
import { getRiskProfiles } from 'admin/riskprofiles/modules/riskprofiles';
import { getAssets } from 'admin/assets/modules/assets';
import { getParams } from 'views/utils';
import { getViewData } from 'views/modules/views';

export const createAssetsRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.CREATE_ASSET_SUCCESS),
    map(action => push(`/admin/assets/${action.payload.id}/`)),
  );
};

//TODO: check if RiskProfiles is already loaded
//state$.getState() -- redux-observable
//rxjs - observable library -- screen of streamed data (promise: succes || error) -- observer pattern
export const getRiskProfilesWithAssetsEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === TYPES.GET_ASSETS_SUCCESS),
    map(() => getRiskProfiles()),
  );
};
//TODO: epic to intercept CREATE_ASSETS_SUCCESS || DELETE_ASSETS_SUCCESS -- GET_ASSETS action/getAssets()
//map() needs to always call an action -- return will pass straight into reducer

//redirect after delete
//history.push(getAssetsPageUrl(assetId));
export const deleteAssetRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_ASSET_SUCCESS),
    map(() => push(`/admin/asset-groups/`)),
  );
};

export const deleteAssetAndGetAssetsRedirectEpic = (action$, state$) => {
  const { search: url } = state$.value.router.location;

  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_ASSET_SUCCESS),
    map(() => {
      let query = {
        columns: ['asset', 'org_type', 'modified_date', 'risk'],
        order_by: [{ name: 'name', direction: 'desc' }],
        ...getParams(url),
        url,
      };
      return getAssets(query);
    }),
  );
};

export const deleteAssetsRedirectEpic = (action$, state$) => {
  const { search: url } = state$.value.router.location;
  return action$.pipe(
    filter(action => action.type === TYPES.DELETE_ASSETS_SUCCESS),
    map(() => {
      let query = {
        columns: ['asset', 'risk'],
        order_by: [{ name: 'risk__score', direction: 'desc' }],
        ...getParams(url),
        url,
      };
      return getViewData('assets', query);
    }),
  );
};

const refetchAsset = (action$, state$) => {
  const refetchTypes = [TYPES.SAVE_ASSET_SUCCESS];
  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params),
    map(action => getAsset({ id: action.params.id })),
  );
};

const refetchAssetContacts = (action$, state$) => {
  const refetchTypes = [TYPES.SAVE_ASSET_CONTACTS_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params),
    map(action => getAssetContacts(action.params.id)),
  );
};

const epics = {
  createAssetsRedirectEpic,
  getRiskProfilesWithAssetsEpic,
  deleteAssetRedirectEpic,
  deleteAssetAndGetAssetsRedirectEpic,
  deleteAssetsRedirectEpic,
  refetchAsset,
  refetchAssetContacts,
};
export default epics;
