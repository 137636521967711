import React from 'react';
import { Tabs } from 'antd';
import { TabContainer } from 'assets/containers/AssetDetailView/styles';

const { TabPane } = Tabs;

const PreservedTabView = ({ tabs, activeTab }) => {
  return (
    <>
      <TabContainer activeKey={activeTab || tabs?.[0].key} style={{ overflow: 'visible' }}>
        {tabs.map(tab => {
          return (
            <TabPane tab={tab.title} key={tab.key}>
              {tab.content}
            </TabPane>
          );
        })}
      </TabContainer>
    </>
  );
};

export default PreservedTabView;
