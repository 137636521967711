import React from 'react';
import PropTypes from 'prop-types';
import ThreatIcon from 'common/components/ThreatIcon';

export default class ThreatFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const value = this.props.value.headline
      ? this.props.value.headline
      : this.props.value.name
      ? this.props.value.name
      : `Threat ${this.props.value.id}`;
    return (
      <>
        <ThreatIcon severity={this.props.value.severity || 'low'} category={this.props.value.category} /> &nbsp;
        <a href={this.props.value.url}>{value}</a>
      </>
    );
  }
}
