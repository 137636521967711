import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { Button, DeleteIcon, FormItem, Label as Input, PlusIcon, Section } from '@luxe/components';

const AssetInformationSection = ({ asset, form }) => {
  useEffect(() => {
    if (asset?.meta) {
      const initialValues = {
        meta_fields: Object.entries(asset.meta).map(([key, value]) => ({ key, value })),
      };
      form.setFieldsValue(initialValues);
    }
  }, [asset, form]);

  return (
    <>
      <Section title={'More Information'} headerFontSize={'12px'} backgroundColor={'transparent'}>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.List name="meta_fields">
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    {fields.map((field, i) => {
                      return <MetaFields field={field} index={i} remove={remove} key={field.key} />;
                    })}
                    <Form.Item>
                      <Button
                        onClick={() => {
                          add();
                        }}
                        variant="alternate"
                        style={{ margin: '0' }}
                      >
                        <PlusIcon variant="alternate" />
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Section>
    </>
  );
};

const MetaFields = ({ field, remove }) => {
  const keyValuePair = [
    {
      rules: [{ required: true, message: 'Please input a key!' }],
      placeholder: 'Key',
      name: 'key',
    },
    {
      rules: [{ required: true, message: 'Please input a value!' }],
      placeholder: 'Value',
      name: 'value',
    },
  ];

  return (
    <Row gutter={24}>
      {keyValuePair.map((f, i) => {
        return (
          <Col span={11} key={`${field.key}:${i}`}>
            <FormItem {...field} rules={f.rules} name={[field.name, f.name]} label={''} required={true}>
              <Input placeholder={f.placeholder} width={'100%'} />
            </FormItem>
          </Col>
        );
      })}
      <Col span={2}>
        <Button
          variant="alternate"
          onClick={() => {
            remove(field.name);
          }}
        >
          <DeleteIcon variant="alternate" />
        </Button>
      </Col>
    </Row>
  );
};

export default AssetInformationSection;
