import React, { useCallback } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'antd';
import styled from '@emotion/styled';

import { trackWorkFlowChange, updateWorkflow } from 'admin/workflows/modules/workflows';

import UserSelect from './UserSelect';
import GroupSelect from './GroupSelect';
import WebhookSelector from './WebhookSelector';
import { InfoCircleOutlined } from '@ant-design/icons';

export const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + span {
    border-color: ${props => props.theme.colors.white} !important;
    border: 1px solid;
  }

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} !important;
    background-color: ${props => props.theme.colors.primary} !important;
  }

  input[type='checkbox'] + span {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.white};
    &:focus {
      border-color: white !important;
    }
    &:disabled {
      background-color: ${props => props.theme.colors.disabled} !important;
    }
  }
`;

const Notifications = styled(({ className }) => {
  const { recipients = [], webhooks = [], channel = [] } = useSelector(state => state.workflows?.workflow?.data);
  const dispatch = useDispatch();

  const assetContactChecked = recipients.filter(x => x.type === 'asset_contact').length > 0;

  const toolTipStyling = { backgroundColor: '#141414', fontSize: '10px', color: '#ffffff' };

  const users = recipients
    .filter(x => x.type === 'user_id')
    .map(({ value, label }) => {
      return { value, label };
    });

  const groups = recipients
    .filter(x => x.type === 'user_group_id')
    .map(({ value, label }) => {
      return { label, value };
    });

  const onAssetContactChange = useCallback(
    e => {
      const type = 'asset_contact';
      if (e.target.checked) {
        const contacts = [
          {
            value: '*',
            label: 'Asset Contact',
            type: type,
          },
        ];
        dispatch(
          updateWorkflow({
            data: {
              recipients: [...recipients.filter(x => x.type !== type), ...contacts],
            },
          }),
        );
      } else {
        dispatch(
          updateWorkflow({
            data: {
              recipients: [...recipients.filter(x => x.type !== type)],
            },
          }),
        );
      }
      dispatch(trackWorkFlowChange(true));
    },
    [dispatch, recipients],
  );

  const onWebhookChange = useCallback(
    value => {
      let newChannel = channel;
      if (value !== null && !channel.includes('webhooks')) {
        newChannel = [...channel, 'webhooks'];
      } else if (value === null && channel.includes('webhooks')) {
        newChannel = channel.filter(item => item !== 'webhooks');
      }
      dispatch(trackWorkFlowChange(true));
      dispatch(
        updateWorkflow({
          data: {
            channel: newChannel,
            webhooks: value,
          },
        }),
      );
    },
    [dispatch, channel],
  );

  return (
    <div className={className}>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <div className="field">
            <label>Recipient Configuration </label>
            <Tooltip
              title="Select users and groups as recipients for notifications related to this workflow. These users will also receive related threats in their digests."
              overlayInnerStyle={toolTipStyling}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '50%' }}>
          <div className="selectors">
            <div className="field">
              <label>Users</label>
              <UserSelect value={users} />
            </div>
            <div className="field">
              <label>User &amp; Groups</label>
              <GroupSelect value={groups.length > 0 ? groups : undefined} style={{ marginBottom: '10px' }} />
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <div className="field">
            <label>Facility Contacts </label>
            <Tooltip
              title=" Select facility contacts to send a notification to primary contacts of a facility when this workflow matches a threat on their facility."
              overlayInnerStyle={toolTipStyling}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '50%' }}>
          <div className="field">
            <StyledCheckbox
              onChange={onAssetContactChange}
              defaultChecked={assetContactChecked}
              name="asset_contacts"
            />
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <div className="field">
            <label>Webhook Configuration </label>
            <Tooltip
              title=" Select webhooks to trigger when this workflow matches a threat to your network."
              overlayInnerStyle={toolTipStyling}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '50%' }}>
          <div className="selectors">
            <div className="field">
              <label>Webhooks</label>
              <WebhookSelector multi value={webhooks} onChange={onWebhookChange} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
})`
  & {
    font-weight: 400;
  }
  .alert {
    width: 400px;
  }
  .selectors {
    width: 100%;
    margin-bottom: 75px;
  }

  .field {
    color: white;
    margin-bottom: 10px;
    margin-right: 20px;
  }

  .bottom {
    margin-top: 10px;
    bottom: 38px;
  }

  .bottom-left {
    float: left;
  }

  .bottom-right {
    float: right;
  }
`;

export default Notifications;
