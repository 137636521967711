import React from 'react';
import { Select } from 'layout/Select';

import { ASSET_TYPES } from 'common/Constants';

const AssetTypeSelector = ({ ...props }) => {
  const { operator, name } = props.filter;
  const multi = operator === 'in';

  const onChange = value => {
    props.onChange({
      value,
      operator,
      name,
    });
  };

  const options = ASSET_TYPES;
  const value = props.filter.value;
  return (
    <>
      <div>Facility Type</div>
      <Select
        key={props.filter.name}
        isMulti={multi}
        stayOpen={multi}
        selected={value}
        onChange={onChange}
        options={options}
        backspaceRemoves
      />
    </>
  );
};
export default AssetTypeSelector;
