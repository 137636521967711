import { Slider, ToolLabel } from 'assets/containers/OverallRiskCharts/styles';
import { getRiskCategory, isEmpty } from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import { Benchmark } from 'assets/containers/AssetDetailView/styles';
import { HeatMapRow } from './styles';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import RiskChart from './HeatMapBoxes';
import { historyChartOptions } from 'assets/containers/OverallRiskCharts/HistoryChartOptions';
import { normalizeName } from 'assets/modules/utils';
import { removeIndexRiskFilters } from 'assets/modules/riskfilter';
import { useRouteMatch } from 'react-router-dom';

window.Highcharts = Highcharts;
HighchartsMore(Highcharts);

export function getToolTip(riskItem, riskHistory, riskIndex) {
  const riskIndexName = riskIndex.name;
  const subIndexName = riskItem.index.name;
  const history = getRiskHistory(riskHistory, riskIndexName, subIndexName, riskItem.name);
  const index = riskIndex.indices.find(item => item.name === riskItem.name);
  const trend = index ? index.trend : 'neutral';
  const category = getRiskCategory(riskItem.score);
  const width = (riskItem.score / 25) * 100;
  const stringSlider = <Slider style={{ width: `${width}%` }} class={`risk-${category}`} />;
  const label = <ToolLabel name={riskItem.name} score={riskItem.score} trend={trend} />;
  const benchmark = <Benchmark />;
  const historyDiv = `tooltip-history-${riskItem.name}`;
  const historyChart = <div id={historyDiv}></div>;
  if (!isEmpty(history)) {
    setTimeout(function() {
      window.Highcharts.chart(historyDiv, historyChartOptions(history));
    }, 10);
  }
  return (
    <div className="slider-tooltip">
      {label}
      {benchmark}
      {stringSlider}
      {historyChart}
    </div>
  );
}

export function getRiskHistory(riskHistory = {}, index, subIndex, component) {
  const name = normalizeName(subIndex);
  const subIndexname = normalizeName(component);
  let componentHistory = {};
  if (!isEmpty(riskHistory)) {
    const indexHistory = riskHistory.indices[normalizeName(index)];
    componentHistory = indexHistory.indices[name].component[subIndexname];
    return componentHistory;
  }
  return {};
}

export function filterComponents(data, riskFilter, keyName) {
  const filteredData = data.filter(component => {
    if (riskFilter.filteredIndices[keyName].includes(component.index.name)) {
      return component;
    }
    return null;
  });
  return filteredData;
}

export function LocationRiskDetail({ risk, riskIndex, history, keyName, riskModel }) {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const riskFilter = useSelector(store => store.riskFilter);

  if (url.slice(-1) !== '/') url = url.concat('/');

  function showRemoveFilterIcon(riskFilter, keyName) {
    return !!(riskFilter.isFiltered && riskFilter.currentlyFiltered.includes(keyName));
  }

  function handleRemoveFilters(e, keyName) {
    dispatch(removeIndexRiskFilters(e.currentTarget.dataset.index));
    const activeEls = document.getElementsByClassName(`${keyName}-active`);
    Array(...activeEls).forEach(el => {
      el.classList.remove('active', `${keyName}-active`);
    });
  }
  return (
    <HeatMapRow>
      <RiskChart
        risk={risk}
        riskModel={riskModel}
        keyName={keyName}
        url={url}
        riskIndex={riskIndex}
        history={history}
        riskFilter={riskFilter}
      />
      {showRemoveFilterIcon(riskFilter, keyName) ? (
        <ReloadOutlined
          data-index={keyName}
          onClick={e => {
            e.persist();
            handleRemoveFilters(e, keyName);
          }}
        />
      ) : (
        ''
      )}
    </HeatMapRow>
  );
}
