import React from 'react';
import PropTypes from 'prop-types';

export default class InvalidColumnFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    return <span>Invalid Column</span>;
  }
}
