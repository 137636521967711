import { Checkbox } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import styled from '@emotion/styled';
//import { RULE_TYPES } from 'admin/constants';
import { Col, Row, Typography } from 'antd';
import 'antd/dist/antd.css';

import { getDesiredRuleUnits } from 'admin/riskprofiles/modules/riskprofiles';
import { Select } from 'layout/Select';
import { components } from 'react-select';
import _ from 'lodash';
import Options from './Options';
import RiskSlider from './RiskSlider';

const Text = styled(Typography.Text)`
  color: white;
`;

const MARKS = {
  2: '2',
  9: '9',
  17: '17',
  25: '25',
};

const RuleSettings = ({ catalogRules, RiskProfileRule, RiskProfile, updateRule, disabled }) => {
  const [threat, setThreat] = useState(null);
  const gutter = [40, 10];

  const userUOM = useSelector(store => store.account.account?.prefs.units.default);

  useEffect(() => {
    if (RiskProfileRule.catalog) {
      setThreat({ value: RiskProfileRule.catalog.name, label: RiskProfileRule.catalog.name });
    }
  }, [RiskProfileRule]);

  useEffect(() => {
    const catalogRule = threat && catalogRules.filter(x => x.name === threat.value)[0];
    if (threat && catalogRule) {
      const desiredUnits = getDesiredRuleUnits(catalogRule, userUOM);

      if (!RiskProfileRule.catalog || RiskProfileRule.catalog.id !== catalogRule.id) {
        const { id, name, ...defaults } = _.cloneDeep(catalogRule);
        const rule = {
          ...defaults,
          ...{ desiredUnits: desiredUnits, units: catalogRule.data.options?.units, catalog: catalogRule },
        };
        updateRule(rule);
      }
    }
  }, [threat, catalogRules, RiskProfileRule, updateRule, userUOM]);

  const getCatalogRulesNames = () => {
    const existingRules = RiskProfile?.rules?.map(rule => rule.catalog?.id) ?? [];
    return catalogRules.map(x => {
      return { value: x.name, label: x.name, exists: existingRules.includes(x.id) };
    });
  };

  const onChange = value => {
    setThreat(value);
  };

  if (Array.isArray(catalogRules) && catalogRules.length > 0) {
    return (
      <>
        <ThreatSection
          threat={threat}
          onChange={onChange}
          gutter={gutter}
          options={getCatalogRulesNames()}
          disabled={disabled}
        />

        {RiskProfileRule.data &&
          RiskProfileRule.data.options &&
          Object.keys(RiskProfileRule.data.options).length > 0 && (
            <OptionsSection rule={RiskProfileRule} onChange={updateRule} disabled={disabled} />
          )}

        {RiskProfileRule.data && RiskProfileRule.data.values && (
          <SliderSection
            rule={RiskProfileRule}
            marks={MARKS}
            gutter={gutter}
            onChange={updateRule}
            threat={threat}
            disabled={disabled}
          />
        )}
      </>
    );
  }
  return null;
};

const CustomOption = props => {
  return (
    <div className="custom-option">
      <components.Option {...props} />
      {props.data?.exists && <label>Already In Profile</label>}
    </div>
  );
};

const ThreatSection = ({ threat, onChange, gutter, options, disabled }) => {
  return (
    <>
      <Row gutter={gutter}>
        <Col span={20} push={4}>
          <label> Incident or condition </label>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={4}>
          <Text strong>IF</Text>
        </Col>
        <Col span={20} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Select
            autoFocus
            selected={threat}
            style={{ width: '100%' }}
            options={options}
            onChange={onChange}
            isDisabled={disabled}
            components={{ Option: CustomOption }}
          />
        </Col>
      </Row>
    </>
  );
};

const OptionsSection = ({ rule, onChange, disabled }) => {
  const checked = rule.data.options.limit_to_entities || false;
  /*
  setting incidentType to false below to hide the 'limit incident' checkbox
  when we undo this change, also uncomment the 'import RULE_TYPES' line
  const incidentType =
    rule.catalog.type === RULE_TYPES.SPATIAL_INTERSECT || rule.catalog.type === RULE_TYPES.DISTANCE_BASED;
  */
  const incidentType = false;

  const onBoxChecked = e => {
    if (rule && rule.catalog && rule.catalog.data) {
      rule.data.options.limit_to_entities = e.target.checked;
      onChange(rule);
    }
  };

  const StyledCheckbox = styled(Checkbox)`
    margin-left: 10px;
    input[type='checkbox'] + span {
      border-color: ${props => props.theme.colors.white} !important;
      border: 1px solid;
    }

    input[type='checkbox']:checked + span {
      border-color: ${props => props.theme.colors.primary} !important;
      background-color: ${props => props.theme.colors.primary} !important;
    }

    input[type='checkbox'] + span {
      background-color: ${props => props.theme.colors.background};
      border-color: ${props => props.theme.colors.white};
      &:focus {
        border-color: white !important;
      }
      &:disabled {
        background-color: ${props => props.theme.colors.disabled} !important;
      }
    }
  `;

  return (
    <>
      <Row style={{ margin: '10px' }}>
        <Col push={4}>
          <Options rule={rule} onChange={onChange} disabled={disabled} />
        </Col>
      </Row>
      {incidentType && (
        <Row style={{ marginTop: '10px', marginBottom: '15px' }}>
          <Col push={4}>
            <StyledCheckbox onChange={onBoxChecked} defaultChecked={checked} disabled={disabled} />
            <span style={{ marginLeft: '10px' }}>Limit Incident Scoring to Entity Based Tagging</span>
          </Col>
        </Row>
      )}
    </>
  );
};

const SliderSection = ({ threat, rule, marks, gutter, onChange, disabled }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    _.debounce(newData => onChange(newData), 50),
    [],
  );

  const onSliderChange = values => {
    const { data } = rule;
    let newData = { data: { ...data, values } };
    debouncedChange(newData);
  };

  return (
    <>
      <Row gutter={gutter}>
        <Col push={4}>
          <label style={{ marginTop: '5px', marginBottom: '5px' }}> Assign Risk Score </label>
        </Col>
      </Row>
      <Row gutter={gutter} push={4}>
        <Col span={4}>
          <Text strong>THEN</Text>
        </Col>
        <Col span={20}>
          <RiskSlider marks={marks} onChange={onSliderChange} disabled={disabled} />
        </Col>
        <div style={{ height: '100px' }}></div>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    ...state.catalogRules,
    ...state.riskProfiles,
  };
}

export default connect(mapStateToProps)(RuleSettings);
