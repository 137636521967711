import AssetsTab from './AssetsTab';
import IncidentsTab from './IncidentsTab';
import OverviewTab from './OverviewTab';
import RiskTab from './RiskTab';
import EconomicLogisticTab from './CountryEconomicLogisticTab';

const tabs = {
  AssetsTab,
  IncidentsTab,
  OverviewTab,
  RiskTab,
  EconomicLogisticTab,
};
export default tabs;
