import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_CHANNELS: 'GET_CHANNELS',
  GET_CHANNELS_ERROR: 'GET_CHANNELS_ERROR',
  GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
};

export const INITIAL_STATE = {};

export const getChannels = () => {
  return callApi({
    types: {
      pending: TYPES.GET_CHANNELS,
      success: TYPES.GET_CHANNELS_SUCCESS,
      error: TYPES.GET_CHANNELS_ERROR,
    },
    request: () => axios.get(`account/user/channels/`),
  });
};

export const channelReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.GET_CHANNELS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_CHANNELS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        channel: action.payload,
      };
    case TYPES.GET_CHANNELS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default channelReducer;
