import theme from 'theme';

export function historyChartOptions(history) {
  return {
    chart: {
      backgroundColor: theme.colors.black3,
      height: 150,
      plotBackgroundColor: '#424242',
      type: 'area',
    },
    colors: ['#2d8fa5'],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    pane: {
      size: '100%',
    },
    plotLines: {
      color: '#cccccc',
      width: 1,
    },
    series: [
      {
        data: history,
      },
    ],
    title: {
      align: 'left',
      style: {
        color: '#cccccc',
        fontSize: '8px',
      },
      text: 'Last 13 Months',
    },
    tooltip: {
      formatter: function() {
        const date = new Date(this.x);
        const month = date.toLocaleString('default', { month: 'long' });
        return month + ' ' + date.getDate() + ', ' + date.getFullYear() + ': <strong>' + this.y + '</strong>';
      },
    },
    xAxis: {
      labels: {
        style: {
          color: '#cccccc',
          fontSize: '8px',
        },
      },
      tickmarkPlacement: 'on',
      title: {
        text: undefined,
      },
      type: 'datetime',
    },
    yAxis: {
      labels: {
        style: {
          color: '#cccccc',
          fontSize: '8px',
        },
      },
      softMin: 0,
      softMax: 25,
      opposite: true,
      tickInterval: 10,
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      series: {
        animation: {
          duration: 0,
        },
      },
    },
  };
}
