import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { Menu, SubMenu, MenuItem } from '@luxe/components';
import { RadarChartOutlined, ApartmentOutlined, BranchesOutlined, ToolOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import theme from '../../../theme';
import useMixpanel from 'utils/hooks/Mixpanel';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import { BasicModal as Modal } from '@luxe/components';
const { Text } = Typography;

const AdminMenu = ({ location, accountOrg }) => {
  const mixpanelTrack = useMixpanel();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pathToRedirect, setPathToRedirect] = useState('');
  const acct = accountOrg ? accountOrg.accountOrg : null;
  const isWorkFlowChanged = useSelector(state => state?.workflows?.isWorkFlowChanged);
  const isRiskProfileChanged = useSelector(state => state?.riskProfiles?.isRiskProfileChanged);
  const isRiskModelChanged = useSelector(state => state?.riskModels?.isRiskModelChanged);
  let key = 'risk-profiles';
  const subMenus = ['risk', 'network', 'account'];
  if (!acct) {
    return null;
  }

  let pathname = location.pathname.split('/');
  key = pathname[2];

  const proactiveScoring = acct?.prefs?.proactive?.scoring;
  const materialsEnabled = acct?.prefs?.materials?.enabled;
  const discoverEnabled = acct?.prefs?.discover?.enabled;
  const actionPlanEnabled = acct?.prefs?.action_plan?.enabled;
  const isBillingPlanAcct = acct?.billing_plan?.type;

  if (actionPlanEnabled) {
    subMenus.push('setting');
  }

  const linkClickHandler = (e, path) => {
    e.preventDefault();
    if (location?.pathname === '/admin/general/') {
      if (path !== location?.pathname && acct?.update) {
        setPathToRedirect(path);
        setIsModalVisible(true);
        return;
      }
    } else if (location?.pathname.includes('/admin/workflows')) {
      if (path !== location?.pathname && isWorkFlowChanged) {
        setPathToRedirect(path);
        setIsModalVisible(true);
        return;
      }
    } else if (location?.pathname.includes('/admin/risk-profiles')) {
      if (path !== location?.pathname && isRiskProfileChanged) {
        setPathToRedirect(path);
        setIsModalVisible(true);
        return;
      }
    } else if (location?.pathname.includes('/admin/risk-models')) {
      if (path !== location?.pathname && isRiskModelChanged) {
        setPathToRedirect(path);
        setIsModalVisible(true);
        return;
      }
    }
    history.push(path);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Menu mode="inline" selectedKeys={[key]} defaultOpenKeys={subMenus}>
          <SubMenu title="Account Settings" key="setting" icon={<ToolOutlined />}>
            <MenuItem key="general">
              <Link to="/admin/general/" onClick={e => linkClickHandler(e, '/admin/general/')}>
                <span>General</span>
              </Link>
            </MenuItem>
            {actionPlanEnabled ? (
              <MenuItem key="plan-playbooks">
                <Link to="/admin/plan-playbooks/" onClick={e => linkClickHandler(e, '/admin/plan-playbooks/')}>
                  <span>Plan Playbooks</span>
                </Link>
              </MenuItem>
            ) : null}
          </SubMenu>
          <SubMenu title="Network" key="network" className="crappola" icon={<BranchesOutlined />}>
            <MenuItem key="asset-groups">
              <Link to="/admin/asset-groups/" onClick={e => linkClickHandler(e, '/admin/asset-groups/')}>
                <span>Facilities</span>
              </Link>
            </MenuItem>
            {materialsEnabled ? (
              <MenuItem key="product-groups">
                <Link to="/admin/product-groups/" onClick={e => linkClickHandler(e, '/admin/product-groups/')}>
                  <span>Products</span>
                </Link>
              </MenuItem>
            ) : null}
            {materialsEnabled ? (
              <MenuItem key="material-groups">
                <Link to="/admin/material-groups/" onClick={e => linkClickHandler(e, '/admin/material-groups/')}>
                  <span>Materials</span>
                </Link>
              </MenuItem>
            ) : null}
            <MenuItem key="lanes">
              <Link to="/admin/lanes/" onClick={e => linkClickHandler(e, '/admin/lanes/')}>
                <span>Lanes</span>
              </Link>
            </MenuItem>
            <MenuItem key="corporations">
              <Link to="/admin/corporations/" onClick={e => linkClickHandler(e, '/admin/corporations/')}>
                <span>Corporations</span>
              </Link>
            </MenuItem>
            <MenuItem key="user-groups">
              <Link to="/admin/user-groups/" onClick={e => linkClickHandler(e, '/admin/user-groups/')}>
                <span>Users</span>
              </Link>
            </MenuItem>
          </SubMenu>

          <SubMenu title="Risk Management" key="risk" icon={<RadarChartOutlined />}>
            {proactiveScoring ? (
              <MenuItem key="risk-models">
                <Link to="/admin/risk-models/" onClick={e => linkClickHandler(e, '/admin/risk-models/')}>
                  <span>Risk Models</span>
                </Link>
              </MenuItem>
            ) : null}
            <MenuItem key="risk-profiles">
              <Link to="/admin/risk-profiles/" onClick={e => linkClickHandler(e, '/admin/risk-profiles/')}>
                <span>Incident Profiles</span>
              </Link>
            </MenuItem>
            {discoverEnabled ? (
              <MenuItem key="discover-facility-profile">
                <Link to="/admin/discover-facility-profile/">
                  <span>Discovered Facility Profile</span>
                </Link>
              </MenuItem>
            ) : null}
            <MenuItem
              key="workflows"
              onClick={() => {
                mixpanelTrack('Click Workflows');
              }}
            >
              <Link to="/admin/workflows/" onClick={e => linkClickHandler(e, '/admin/workflows/')}>
                <span>Workflows</span>
              </Link>
            </MenuItem>
          </SubMenu>

          {isBillingPlanAcct === 'SMB' ? (
            <SubMenu title="Organization" key="account" icon={<ApartmentOutlined />}>
              <MenuItem key="smb-link">
                <a
                  aria-label="SMB Billing"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.billingportal.com/s/Everstream2"
                >
                  <span>My Subscription</span>
                </a>
              </MenuItem>
            </SubMenu>
          ) : null}
        </Menu>
      </ThemeProvider>
      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          title="Leave Page?"
          okText="Leave"
          cancelText="Cancel"
          onCancel={() => setIsModalVisible(false)}
          onOk={() => {
            setIsModalVisible(false);
            history.push(pathToRedirect);
          }}
        >
          <Text style={{ fontSize: '16px', color: 'white' }}>
            Are you sure you want to leave this page? Unsaved changes will be lost.
          </Text>
        </Modal>
      )}
    </>
  );
};

function mapStateToProps({ accountOrg, router }) {
  return {
    accountOrg,
    location: router.location,
  };
}

export default connect(mapStateToProps)(AdminMenu);
