import { deleteFromList, updateInList } from 'admin/utils';

import { REQUESTED_DATA_STATUS } from 'admin/constants';
import axios from 'axiosInstance';
import { callApi } from 'utils';

export const TYPES = {
  GET_USERS: 'GET_USERS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',

  GET_USERS_FROM_GROUP: 'GET_USERS_FROM_GROUP',
  GET_USERS_FROM_GROUP_ERROR: 'GET_USERS_FROM_GROUP_ERROR',
  GET_USERS_FROM_GROUP_SUCCESS: 'GET_USERS_FROM_GROUP_SUCCESS',

  GET_USER: 'GET_USER',
  GET_USER_ERROR: 'GET_USER_ERROR',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',

  DELETE_USER: 'DELETE_USER',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',

  CREATE_USER: 'CREATE_USER',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
};

export const getUsersFromGroup = (groupId, searchTerm = '') => {
  return callApi({
    types: {
      pending: TYPES.GET_USERS_FROM_GROUP,
      success: TYPES.GET_USERS_FROM_GROUP_SUCCESS,
      error: TYPES.GET_USERS_FROM_GROUP_ERROR,
    },
    request: () => axios.get(`user-groups/${groupId}/users/${searchTerm ? `?search=${searchTerm}` : ''}`),
  });
};

export const getUserFromId = userId =>
  callApi({
    types: {
      pending: TYPES.GET_USER,
      success: TYPES.GET_USER_SUCCESS,
      error: TYPES.GET_USER_ERROR,
    },
    request: () => axios.get(`users/${userId}/`),
  });

export const getAllUsers = (searchTerm = '') => {
  return callApi({
    types: {
      pending: TYPES.GET_USERS,
      success: TYPES.GET_USERS_SUCCESS,
      error: TYPES.GET_USERS_ERROR,
    },
    request: () => axios.get(`users/${searchTerm ? `?search=${searchTerm}` : ''}`),
  });
};

export const deleteUser = userId =>
  callApi({
    types: {
      pending: TYPES.DELETE_USER,
      success: TYPES.DELETE_USER_SUCCESS,
      error: TYPES.DELETE_USER_ERROR,
    },
    request: () => axios.delete(`users/${userId}/`),
    messages: {
      success: 'User has been successfully deleted!',
      error: 'User deletion was unsuccessful',
    },
  });

export const createUser = (data, users) =>
  callApi({
    types: {
      pending: TYPES.CREATE_USER,
      success: TYPES.CREATE_USER_SUCCESS,
      error: TYPES.CREATE_USER_ERROR,
    },
    request: () => axios.post('users/', data),
    messages: {
      success: 'User has been successfully created!',
      error: 'User creation unsuccessful',
      userError: 'User already exists',
    },
    existingData: users,
  });

export const updateUser = (userId, data) =>
  callApi({
    types: {
      pending: TYPES.UPDATE_USER,
      success: TYPES.UPDATE_USER_SUCCESS,
      error: TYPES.UPDATE_USER_ERROR,
    },
    request: () => axios.put(`users/${userId}/`, data),
    messages: {
      success: 'User has been successfully edited!',
      error: 'User edit unsuccessful',
    },
  });

export const INITIAL_STATE = {
  status: undefined,
  user: {},
  users: [],
  error: undefined,
  params: undefined,
  userFetching: undefined,
};

const updateUserProps = user => {
  user.isAdmin = user.permissions.includes('admin');
  user.isStaff = user.email.endsWith('riskpulse.com') || user.email.endsWith('everstream.ai');
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_USERS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: action.payload,
        userFetching: true,
      };
    case TYPES.GET_USERS_SUCCESS:
      action.payload.forEach(u => {
        updateUserProps(u);
      });
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        users: action.payload,
        error: undefined,
        userFetching: false,
      };
    case TYPES.GET_USERS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
        userFetching: false,
      };

    case TYPES.GET_USERS_FROM_GROUP:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: action.payload,
      };
    case TYPES.GET_USERS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        users: action.payload,
        error: undefined,
      };
    case TYPES.GET_USERS_FROM_GROUP_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };

    case TYPES.GET_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: action.payload,
      };
    case TYPES.GET_USER_SUCCESS:
      updateUserProps(action.payload);
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        user: action.payload,
        error: undefined,
      };
    case TYPES.GET_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };

    case TYPES.DELETE_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.DELETE_USER_SUCCESS:
      const deletedUser = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        users: [...deleteFromList(deletedUser, state.users)],
        error: undefined,
      };
    case TYPES.DELETE_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: undefined,
      };

    case TYPES.CREATE_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.CREATE_USER_SUCCESS:
      const newUser = action.payload;
      const isUserFound = state.users.find(usr => newUser.id === usr.id);
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        users: isUserFound ? [...state.users] : [newUser, ...state.users],
        error: undefined,
      };
    case TYPES.CREATE_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.UPDATE_USER:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_USER_SUCCESS:
      updateUserProps(action.payload);
      const updatedUser = action.payload;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        users: [...updateInList(updatedUser, state.users)],
        user: updatedUser,
        error: undefined,
      };
    case TYPES.UPDATE_USER_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
