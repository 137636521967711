import styled from '@emotion/styled';
import { space, layout, flexbox, position, variant } from 'styled-system';
import { Button as aButton } from 'antd';

const ButtonVariants = variant({
  variants: {
    primary: {
      bg: 'primary',
      color: '#eeeeee',
      border: 0,
      borderRadius: '4px',
      margin: '4px',
      padding: '4px 10px',
      fontSize: '12px',
      '&:hover': {
        bg: 'primaryHover',
        borderColor: 'primary',
        color: 'white',
      },
      '&:focus': {
        bg: 'primaryPressed',
        borderColor: 'primary',
        color: 'white',
      },
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
      borderColor: 'secondary',
      border: 0,
      borderRadius: '4px',
      margin: '4px',
      padding: '4px 10px',
      fontSize: '12px',
      '&:hover': {
        bg: 'secondaryHover',
        borderColor: 'secondary',
        color: 'white',
      },
      '&:focus': {
        bg: 'secondaryPressed',
        borderColor: 'secondary',
        color: 'white',
      },
    },
    danger: {
      color: 'white !important',
      bg: 'danger',
      borderColor: 'danger',
      border: 0,
      borderRadius: '4px',
      margin: '4px',
      padding: '4px 10px',
      fontSize: '12px',
      '&:hover': {
        bg: 'dangerHover',
        borderColor: 'danger',
        color: 'white',
      },
      '&:focus': {
        bg: 'dangerPressed',
        borderColor: 'danger',
        color: 'white',
      },
      '&:visited': {
        bg: 'dangerPressed',
        borderColor: 'danger',
        color: 'white !important',
      },
    },
    disabled: {
      color: 'disabledText',
      bg: 'disabled',
      borderColor: 'disabled',
      margin: '4px',
      fontSize: '12px',
      padding: '4px 10px',
      border: 0,
      borderRadius: '4px',
      '&:hover': {
        bg: 'disabled',
        borderColor: 'disabled',
        color: 'disabledText',
      },
      '&:focus': {
        bg: 'disabled',
        borderColor: 'disabled',
        color: 'disabledText',
      },
    },
    alternate: {
      bg: '#545454',
      color: 'white',
      borderColor: 'primary',
      border: 0,
      borderRadius: '4px',
      margin: '4px',
      padding: '4px 10px',
      fontSize: '12px',
      '&:hover': {
        bg: 'primaryHover',
        borderColor: 'primary',
        color: 'white',
      },
      '&:focus': {
        bg: 'primaryPressed',
        borderColor: 'primary',
        color: 'white',
      },
    },
    link: {
      '&:hover': {
        bg: 'primaryHover',
        '&&& span': {
          color: 'white',
        },
      },
      '&:focus': {
        bg: 'primaryPressed',
        '&&& span': {
          color: 'white',
        },
      },
      '&&& span': {
        color: 'primary',
      },
    },
    menu: {
      bg: 'BG1',
      borderColor: 'black1',
      width: '150px',
      '&:hover': {
        bg: 'primaryHover',
        '&&& span': {
          color: 'white',
        },
      },
      '&:focus': {
        bg: 'primaryPressed',
        '&&& span': {
          color: 'white',
        },
      },
      '&&& span': {
        color: 'white',
      },
    },
  },
});

const Button = styled(aButton)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  font-family: ${props => props.theme.fonts.body};
  height: 32px;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  ${ButtonVariants}
  &&&.ant-btn > a:only-child {
    color: white !important;
  }
  &&&.ant-btn[disabled] {
    color: ${props => props.theme.colors.disabledText};
    background-color: ${props => props.theme.colors.disabled};
    border-color: ${props => props.theme.colors.disabled};
    '&:hover' {
      background-color: ${props => props.theme.colors.disabled};
      border-color: ${props => props.theme.colors.disabled};
      color: ${props => props.theme.colors.disabledText};
    }
    ,
    '&:focus' {
      background-color: ${props => props.theme.colors.disabled};
      border-color: ${props => props.theme.colors.disabled};
      color: ${props => props.theme.colors.disabledText};
    }
  }
`;

export { Button };
