import React from 'react';
import { Slider, SliderHeader, TrendIcon } from './styles';
import { normalizeName } from 'assets/modules/utils';
import { isEmpty, getRiskCategory } from 'utils';
import { HistoryChart } from './HistoryChart';

function getSliderValues(risk, riskHistory = {}, isEkg, showHistory) {
  let indices = risk.indices || [];
  if (isEkg) {
    let filCondition = ['Internal Risks'];
    if (!showHistory) {
      filCondition.push('External Risks');
    }
    indices = indices.filter(x => !filCondition.includes(x.name));
  }
  const riskSliderValues = [
    {
      name: 'Strategic Risk',
      score: risk.score,
      trend: risk.trend || 'neutral',
      category: risk.category,
      history: riskHistory?.overall,
    },
  ];
  indices.forEach(element => {
    const name = normalizeName(element.name);
    let scores = {};
    if (!isEmpty(riskHistory)) {
      scores = riskHistory.indices[name]?.scores || [];
    }
    riskSliderValues.push({
      name: element.name,
      score: element.score,
      trend: element.trend,
      category: getRiskCategory(element.score),
      history: scores,
    });
  });
  return riskSliderValues;
}

function SliderRiskChart({ proactiveRisk, riskHistory, showBenchMark = true, showHistory = true, isEkg }) {
  if (isEmpty(proactiveRisk)) {
    return null;
  }

  function SliderCharts(props) {
    const sliderValues = getSliderValues(proactiveRisk, riskHistory, props?.isEkg, showHistory);
    return sliderValues.map((risk, idx) => {
      const classname = `risk-${risk.category}`;
      const score = risk.score || '-';
      const width = (risk.score / 25) * 100 || 0;
      return (
        <SliderHeader key={`risk-details-slider-${idx}`}>
          <div>
            <label className={idx === 0 ? 'overall' : 'indices'}>{risk.name}:</label>
            <span style={{ marginRight: '6px' }}>{score}</span>
            <TrendIcon trend={risk.trend} />
          </div>
          {showBenchMark ? (
            <div>
              {/* Placeholder for benchmark data */}
              <p>Benchmark: -</p>
            </div>
          ) : null}
          <Slider style={{ width: `${width}%` }} class={classname} />
          {showHistory && <HistoryChart history={risk.history} />}
        </SliderHeader>
      );
    });
  }

  return <SliderCharts isEkg={isEkg} showHistory={showHistory} />;
}

export default SliderRiskChart;
