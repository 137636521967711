import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_RULES: 'GET_RULES',
  GET_RULES_ERROR: 'GET_RULES_ERROR',
  GET_RULES_SUCCESS: 'GET_RULES_SUCCESS',
  CREATE_RULE: 'CREATE_RULE',
  CREATE_RULE_ERROR: 'CREATE_RULE_ERROR',
  CREATE_RULE_SUCCESS: 'CREATE_RULE_SUCCESS',
  DELETE_RULE: 'DELETE_RULE',
  DELETE_RULE_ERROR: 'DELETE_RULE_ERROR',
  DELETE_RULE_SUCCESS: 'DELETE_RULE_SUCCESS',
  GET_RULE: 'GET_RULE',
  GET_RULE_ERROR: 'GET_RULE_ERROR',
  GET_RULE_SUCCESS: 'GET_RULE_SUCCESS',
  SAVE_RULE: 'SAVE_RULE',
  SAVE_RULE_ERROR: 'SAVE_RULE_ERROR',
  SAVE_RULE_SUCCESS: 'SAVE_RULE_SUCCESS',
  UPDATE_RULE: 'UPDATE_RULE',
  UPDATE_RULE_FILTERS: 'UPDATE_RULE_FILTERS',
  ADD_SELECTED_ITEM: 'ADD_SELECTED_ITEM',
  REMOVE_SELECTED_ITEM: 'REMOVE_SELECTED_ITEM',
  RESET_SELECTED_IDS: 'RESET_SELECTED_IDS',
  CLEAR_RULE: 'CLEAR_RULE',
};

export const INITIAL_STATE = {
  status: undefined,
  workflow_rules: [],
  rule: undefined,
  selectedIds: undefined,
  error: undefined,
  params: undefined,
};

export default function workflowRulesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.GET_RULES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RULES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_RULES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        workflow_rules: action.payload,
        rule: undefined,
        error: undefined,
      };
    case TYPES.CREATE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.CREATE_RULE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.CREATE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.DELETE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };

    case TYPES.DELETE_RULE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.DELETE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SAVE_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_RULE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        rule: action.payload,
        error: undefined,
      };
    case TYPES.SAVE_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_RULE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RULE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_RULE_SUCCESS:
      const assetFilters = action.payload.settings.filtering?.assets || [];
      const idFilter = assetFilters.find(x => x.name === 'id');
      const selectedIds = idFilter ? idFilter.value.map(f => f.value) : [];
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        rule: action.payload,
        selectedIds,
        error: undefined,
      };
    case TYPES.UPDATE_RULE:
      return {
        ...state,
        rule: {
          ...state.rule,
          ...action.payload,
        },
      };
    case TYPES.ADD_SELECTED_ITEM:
      return {
        ...state,
        selectedIds: [...state.selectedIds, action.payload.id],
      };
    case TYPES.REMOVE_SELECTED_ITEM:
      return {
        ...state,
        selectedIds: state.selectedIds.filter(x => x !== action.payload.id),
      };
    case TYPES.RESET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: [],
      };
    case TYPES.CLEAR_RULE:
      return {
        ...state,
        rule: INITIAL_STATE.rule,
      };
    default:
      return state;
  }
}

export const getRules = workflow_id => {
  return callApi({
    types: {
      pending: TYPES.GET_RULES,
      success: TYPES.GET_RULES_SUCCESS,
      error: TYPES.GET_RULES_ERROR,
    },
    request: () => axios.get(`workflows/${workflow_id}/rules/`),
  });
};

export const createRule = (data, workflow_id) => {
  return callApi({
    types: {
      pending: TYPES.CREATE_RULE,
      success: TYPES.CREATE_RULE_SUCCESS,
      error: TYPES.CREATE_RULE_ERROR,
    },
    request: () => axios.post(`workflows/${workflow_id}/rules/`, data),
    messages: {
      success: 'Rule has been successfully created!',
      error: 'Rule creation unsuccessful',
    },
  });
};

export const deleteRule = (workflow_id, rule_id) => {
  return callApi({
    types: {
      pending: TYPES.DELETE_RULE,
      success: TYPES.DELETE_RULE_SUCCESS,
      error: TYPES.DELETE_RULE_ERROR,
    },
    params: { workflow_id },
    request: () => axios.delete(`workflows/${workflow_id}/rules/${rule_id}/`),
    messages: {
      success: 'Rule has been successfully deleted!',
      error: 'Rule deletion unsuccessful',
    },
  });
};

export const saveRule = (workflow_id, rule_id, data) => {
  return callApi({
    types: {
      pending: TYPES.SAVE_RULE,
      success: TYPES.SAVE_RULE_SUCCESS,
      error: TYPES.SAVE_RULE_ERROR,
    },
    request: () => axios.put(`workflows/${workflow_id}/rules/${rule_id}/`, data),
    messages: {
      success: 'Workflow rule has been successfully updated!',
      error: 'Workflow rule update unsuccessful',
    },
  });
};

export const getRule = (workflow_id, rule_id) => {
  return callApi({
    types: {
      pending: TYPES.GET_RULE,
      success: TYPES.GET_RULE_SUCCESS,
      error: TYPES.GET_RULE_ERROR,
    },
    request: () => axios.get(`workflows/${workflow_id}/rules/${rule_id}/`),
  });
};

export const updateRule = data => ({
  type: TYPES.UPDATE_RULE,
  payload: { ...data },
});

export const addSelectedItem = data => ({
  type: TYPES.ADD_SELECTED_ITEM,
  payload: { ...data },
});

export const removeSelectedItem = data => ({
  type: TYPES.REMOVE_SELECTED_ITEM,
  payload: { ...data },
});

export const resetSelectedIds = data => ({
  type: TYPES.RESET_SELECTED_IDS,
  payload: { ...data },
});

export const clearRule = () => ({
  type: TYPES.CLEAR_RULE,
});
