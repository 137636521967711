import React from 'react';
import { Layout } from 'antd';
import styled from '@emotion/styled';
import { Collapse as AntdCollapse } from 'antd';
import { useTheme } from 'emotion-theming';

const { Content } = Layout;

export const Container = ({ children, style }) => {
  const { fonts, colors } = useTheme();
  return <div style={{ fontFamily: fonts.body, background: colors.background, minHeight: 360, color: colors.white, ...style }}>{children}</div>;
};

export const MainContent = styled(Content)`
  const { fonts, colors } = useTheme();
  padding: 15px;
  h1, h2, h3, h4, p, span {
    color: white;
  }
  a {
    color: white !important;
   &:hover, &:visited {
     color:  ${props => props.theme.colors.primary} !important;
   }
 }
`;

export const DetailViewContent = styled(MainContent)`
  h1 {
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: ${({ theme }) => theme.fontSizes.contentTitle}px;
    color: ${props => props.theme.colors.white};
    background-color:#212121;
    margin:0;
  }
  .ant-tree-list{
    margin-left: -25px;
  }
  .ant-tree{
    background-color: ${props => props.theme.colors.black2};
  }
  .ant-tree-node-content-wrapper{
    width: 100%;
    overflow-x: hidden;
  }

`;

export const ProfileContent = styled(DetailViewContent)`
input{
  background-color: #141414;
  border-color:#246472;
  color: #808080;
}
.text-input:hover
 {
   cursor:not-allowed;
 } 

.cancel {
  color: #ffffff;
  background-color: #545454;
}
.cancel:hover {
  background-color: transparent;
  border: 0;
}

.profile-button {
  border: 0;
  border-radius: 4px;
  color: #eeeeee;
  cursor: pointer;
  display: inline;
  float: right;
  font-size: 12px;
  margin: 4px;
  padding: 4px 10px;
}
.submit {
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid #39b2c6;
  color: #eeeeee;
}

.profile-general > * {
  margin:10px;
  width:100%;
}
.profile-general{
  margin-top:15px;
}
`

export const NotificationContent = styled(ProfileContent)`

.ant-switch-handle {
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px);
}
.ant-switch-checked {
  background-color: #2d8fa5;
  border-radius: 40px;
  border-color: #2d8fa5;
}
.ant-radio-button-wrapper {
  background-color: #2c2c2c;
  color: #919191;
  border-color: #2d8fa5;
}

.ant-radio-group .ant-radio-button-wrapper {
  display: inline-block;
  border-color: #2d8fa5;
}
.ant-radio-button-wrapper::before{
  background-color:${props => props.theme.colors.primary};
}
.ant-select {
  background-color: #2c2c2c;
  color: #919191;
  font-size: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #000000;
  border-color: #246472;
  border-radius: 4px;
  margin: 0 0 10px;
}
.ant-radio-button {
  background-color: #2d8fa5;
  border: 1px solid #2d8fa5;
}

button.realtime-prefs {
  border: solid #2d8fa5;
  border-radius: 35px;
  padding: 0px;
  right: 25px;
}
.email-prefs {
  display: flex;
  font-size: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  height: auto;
  justify-content: flex-start;
  margin-top: 3px;
  min-width: 630px;
  height: 40px;
  gap: 20px;

  .notification-label {
    flex: 0 0 150px;
  }
}
.channel-preferences {
  display: flex;
  flex-direction: row;
  width: 15%;
}

.notification-title {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 25px;
  gap: 20px;
  margin:10px;
  .realtime-title {
    width: 190px;
    text-align: right;
  }
  .channel-title {
    margin-left: 12px;
  }
}
`;

export const ListViewContent = styled(MainContent)`

  &.dark {
    background-color: #131C1E;

    h1, h2, h3, p, div {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.contentTitle}px;
    color: ${props => props.theme.colors.white}
    background-color: #141414;
  }
  .cancel {
    color: #ffffff;
    background-color: #545454;
  }
  .cancel:hover {
    background-color: transparent;
    border: 0;
  }
  
  .profile-button {
    border: 0;
    border-radius: 4px;
    color: #eeeeee;
    cursor: pointer;
    display: inline;
    float: right;
    font-size: 12px;
    margin: 4px;
    padding: 4px 10px;
  }
  .submit {
    background-color: ${props => props.theme.colors.primary};
    border: 1px solid #39b2c6;
    color: #eeeeee;
  }
`;

export const Center = ({ children }) => {
  const { itemAlignment } = useTheme();
  return <div style={{ ...itemAlignment }}>{children}</div>;
};

export const Collapse = styled(AntdCollapse)`
  border: none !important;
  background-color: ${props => props.theme.colors.background} !important;
  div.ant-collapse-content {
    border: none;
  }
  div.ant-collapse-content-box {
    background-color: ${props => props.theme.colors.black3};
  }
  span.ant-collapse-arrow {
    color: ${props => props.theme.colors.white} !important;
    font-weight: bold;
    font-size: 24px;
  }
`;
