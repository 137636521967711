import React from 'react';
import RuleView from '@luxe/components/src/RuleView';
import { getEditLink } from './utils';
import { createRule } from 'admin/workflows/modules/rules';
import IncludeRules from './includeRules';
import { useDispatch, useSelector } from 'react-redux';
import { trackWorkFlowChange, updateWorkflow } from 'admin/workflows/modules/workflows';

const NetworkTabView = ({ router, rules, currentApp, ...props }) => {
  const isDiscoverEnabled = useSelector(state => state.accountOrg?.accountOrg?.prefs?.discover?.enabled ?? false);
  const dispatch = useDispatch();
  const workflowRulesColumns = [
    {
      title: 'Rule Name',
      dataIndex: 'name',
    },
    {
      title: 'Assignment',
      dataIndex: 'assignment_type',
    },
    {
      title: 'Rule Type',
      dataIndex: 'rule_type',
    },
    {
      title: 'Actions',
      render: rule => getEditLink(rule, currentApp),
      align: 'center',
    },
  ];

  const updateIncludeRules = value => {
    dispatch(trackWorkFlowChange(true));
    dispatch(
      updateWorkflow({
        data: {
          includes: value,
        },
      }),
    );
  };

  return (
    <div>
      <RuleView
        title={'Workflow'}
        columns={workflowRulesColumns}
        data={props.workflow}
        rules={rules}
        createRuleFunction={createRule}
      />
      <IncludeRules data={props.data} updateIncludeRules={updateIncludeRules} isDiscoverEnabled={isDiscoverEnabled} />
    </div>
  );
};

export default NetworkTabView;
