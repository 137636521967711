import React, { useMemo } from 'react';

import BaseSelector from './BaseSelector';

const AssetProfileSelector = ({ filter, ...props }) => {
  const { operator, name } = filter;

  const config = useMemo(() => {
    return {
      name,
      url: 'risk-profiles/?q=',
      valueKey: 'id',
      labelKey: 'name',
    };
  }, [name]);

  const onChange = value => {
    props.onChange({
      value,
      operator,
      name,
    });
  };

  return (
    <>
      <div>Risk Profile</div>
      <BaseSelector filter={filter} config={config} onChange={onChange} />
    </>
  );
};
export default AssetProfileSelector;
