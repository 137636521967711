import React from 'react';
import { Col, Row, Space } from 'antd';
import { Button } from '@luxe/components';
import { DeleteFilled } from '@ant-design/icons';

const HeaderRow = ({ title = '', disabled = false, onDeleteClick, onUpdateLane, form }) => {
  return (
    <Row gutter={[4, 8]} style={{ marginBottom: `10px` }}>
      <Col span={20} align="start">
        <h1>{title}</h1>
      </Col>
      <Col span={4} align="end">
        <Space>
          <Button
            disabled={disabled}
            variant={disabled ? 'disabled' : 'danger'}
            danger={true}
            icon={<DeleteFilled />}
            onClick={onDeleteClick}
          >
            Delete
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default HeaderRow;
