import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Card, Row, Slider, Button, Tooltip } from 'antd';
import { Select } from '@luxe/components';
import { generateMarks } from 'discover/modules/graphUtilities';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'modal/userprofile/modules/network';
import { cleanCodes } from 'assets/modules/graphs';
import { AsyncSelect } from 'views/components/Select';

const AssetDiscoverFilters = styled(props => {
  const toolTipStyling = { backgroundColor: '#141414', fontSize: '10px', color: '#ffffff' };
  const dispatch = useDispatch();
  const { controlFormattedCountries } = useSelector(store => store.userNetwork);
  const materialsEnabled = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.materials?.enabled;

  useEffect(() => {
    if (!controlFormattedCountries) dispatch(getCountries());
  }, [dispatch, controlFormattedCountries]);

  const sliderChanged = x => {
    props.updateFilters({ max_depth: x });
  };

  const clearFilter = () => {
    props.clearFilters();
  };

  const handleMultiInputChange = selectedValues => {
    let selectedString = [];
    if (selectedValues?.length > 0) {
      selectedString = [...new Set(selectedValues.map(item => item.value))];
    }
    props.updateFilters({ countries: selectedString });
  };
  const handleHSInputChange = selectedValues => {
    let selectedString = [];
    if (selectedValues?.length > 0) {
      selectedString = [...new Set(selectedValues.map(item => item.value))];
    }
    props.updateFilters({ hs_codes: selectedString });
  };

  const getCheckedCountries = () => {
    return controlFormattedCountries?.filter(entry => props.graphFilters?.countries?.includes(entry.value));
  };

  const HScodeConfig = useMemo(
    () => ({
      selectedOptions: props.graphFilters.hs_codes,
      params: {
        id: props.graphFilters?.id,
        max_depth: props.graphFilters?.max_depth,
        product: props.graphFilters?.product,
        material: props.graphFilters?.material,
        asset_group: props.graphFilters?.asset_group,
        risk: props.graphFilters?.risk,
        facilities: props.graphFilters?.facilities,
      },
      url: 'graph/facilities/hscodes/',
      name: 'hs_codes_scorecard',
      limit: 10,
      parseOptions: cleanCodes,
      uniqBy: 'label',
    }),
    [props.graphFilters],
  );

  return (
    <div className={props.className}>
      <Row>
        <Card className={'controller-container'}>
          <label>Countries</label>
          <Tooltip
            title="Choose all countries you would like to receive alerts for. You can leave blank if you want to monitor disruptions globally."
            overlayInnerStyle={toolTipStyling}
          >
            <InfoCircleOutlined />
          </Tooltip>
          <Select
            isMulti={true}
            options={controlFormattedCountries}
            onChange={handleMultiInputChange}
            name="countries"
            value={getCheckedCountries()}
          />
        </Card>
        <Card className={'controller-container'}>
          <div className={'tooltip-realtime'}>
            <label>Tier</label>
            <Tooltip title="Choose tier level you'd like to filter on." overlayInnerStyle={toolTipStyling}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <Slider
            marks={generateMarks(3)}
            min={1}
            max={3}
            tooltipVisible={false}
            onChange={sliderChanged}
            value={props.graphFilters.max_depth}
            style={{ width: '100%' }}
            name={'max_depth'}
          />
        </Card>

        {materialsEnabled && (
          <Card className={'controller-container'}>
            <label>HS Codes</label>
            <Tooltip title="Choose all HS Codes you would like to see." overlayInnerStyle={toolTipStyling}>
              <InfoCircleOutlined />
            </Tooltip>
            <AsyncSelect
              isMulti
              className={'filters-hscode'}
              name="hs_codes"
              onChange={handleHSInputChange}
              config={HScodeConfig}
            />
          </Card>
        )}

        <Card className={'controller-container buttons'}>
          <Button onClick={clearFilter} style={{ backgroundColor: '#545454', marginRight: '20px', right: '0' }}>
            <ReloadOutlined />
          </Button>
        </Card>
      </Row>
    </div>
  );
})`
  border: 0;
  width: 100%;

  .ant-card-bordered {
    border: 0;
  }

  .controller-container {
    background-color: #2e2e2e;
    flex: 1;
    height: 100%;
    margin-right: 1%;
    overflow: visible;

    input {
      color: #ffffff !important;
    }
  }
  .controller-container.buttons {
    flex: 2;
    margin: 16px 0 0;

    button {
      position: absolute;
      padding: 4px 8px;
      margin-top: 16px;
      border: 0;
      border-radius: 4px;
    }
  }

  .ant-input {
    flex: 1;
    width: 200px;
  }

  .tooltip-realtime {
    background-color: transparent;
    display: inline-block;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    svg {
      margin-top: 2px;
    }
  }

  label {
    color: #ffffff;
    font-size: 12px;
    margin: 0 0 3px;
  }

  .anticon-info-circle {
    position: absolute;
    right: 12px;
  }

  .filters-hscode {
    height: auto;
  }
`;

export default AssetDiscoverFilters;
