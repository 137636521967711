import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/corporates/modules/corporates';
import { getAsset } from 'admin/assets/modules/assets';
import { fetchAssets } from 'admin/corporates/modules/utils';

const refetchCorporate = (action$, state$) => {
  const refetchTypes = [TYPES.REMOVE_ASSETS_FROM_CORP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && !action.params.isSingle),
    map(action => fetchAssets(action.params)),
  );
};

const refetchCorporates = (action$, state$) => {
  const refetchTypes = [
    TYPES.REMOVE_ASSETS_FROM_CORP_SUCCESS,
    TYPES.ADD_ASSETS_TO_CORP_SUCCESS,
    TYPES.DELETE_CORPORATES_SUCCESS,
  ];
  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && action.params.isSingle),
    map(action => getAsset({ id: action.params.id })),
  );
};

const epics = {
  refetchCorporate,
  refetchCorporates,
};

export default epics;
