import React, { useContext, useMemo } from 'react';
import { stringSorter } from 'utils';
import { Table } from '@luxe/components';
import { useDispatch, useSelector } from 'react-redux';
import { DEPS_TABLE_STATE_FACILITIES, focusNode } from 'discover/modules/viz';
import { valColor } from 'discover/modules/graphUtilities';
import { FundOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GraphContext, useGraphSelector } from 'discover/modules/GraphProvider';
import useMixpanel from 'utils/hooks/Mixpanel';
import DetermineDiscover from 'common/components/DiscoverDot';

const getNodesAndLinks = (graph, tier, source) => {
  const nodes = [];
  const links = [];
  const traverseGraph = (t, node) => {
    const n = graph.getNode(node);
    if (!n) return;
    nodes.push(n);
    if (t === 0) return;
    const targetNodes = (n[`tier${t}`] ?? []).map(n => n.id);
    const childLinks = graph.getGraphNode(node).childLinks;
    const allLinks = graph.getLinks(childLinks);
    links.push(...allLinks.filter(l => targetNodes.includes(l.target)));
    targetNodes.forEach(n => traverseGraph(t - 1, n));
  };
  traverseGraph(tier, source);
  return {
    nodes: [...new Set(nodes)],
    links: [...new Set(links)],
  };
};

const getAllNodesAndLinks = (graph, tier1 = [], tier2 = [], tier3 = []) => {
  const nodes = [];
  const links = [];
  tier1.forEach(s => {
    const list = getNodesAndLinks(graph, 1, s);
    nodes.push(...list.nodes);
    links.push(...list.links);
  });
  tier2.forEach(s => {
    const list = getNodesAndLinks(graph, 2, s);
    nodes.push(...list.nodes);
    links.push(...list.links);
  });
  tier3.forEach(s => {
    const list = getNodesAndLinks(graph, 3, s);
    nodes.push(...list.nodes);
    links.push(...list.links);
  });
  return {
    nodes: [...new Set(nodes)],
    links: [...new Set(links)],
  };
};

const NetworkDependenciesTable = ({ toggleSubTier }) => {
  const { filters, depsTableToggleState } = useSelector(store => store.networkGraph);
  const nodes = useGraphSelector(graph => graph.observers.nodes);
  const countryDeps = useGraphSelector(graph => graph.observers.countryDependency);
  const graph = useContext(GraphContext);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();

  const TEXT_COLUMN_WIDTHS = 100;
  const TIER_COLUMN_WIDTHS = 15;
  const countryRisk = useSelector(State => State?.accountOrg?.accountOrg?.prefs?.country_risk?.enabled || false);

  const suppliersColumns = () => {
    let columns = [
      {
        title: '',
        dataIndex: 'discovered',
        render: nde => nde,
        width: 25,
      },
      {
        title: 'Facility',
        dataIndex: 'facility',
        render: nde => <span>{nde}</span>,
        sorter: (a, b) => stringSorter(a.facility, b.facility),
        width: TEXT_COLUMN_WIDTHS,
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Incident Risk',
        dataIndex: 'incident_risk',
        render: nde => (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'white',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde || '-'}
          </span>
        ),
        sorter: (a, b) => a.incident_risk - b.incident_risk,
        width: 125,
        align: 'center',
      },
      {
        title: 'Strategic Risk',
        dataIndex: 'proactive_risk',
        render: nde => (
          <span
            style={{
              borderRadius: '10px',
              verticalAlign: 'middle',
              display: 'table',
              background: valColor(nde),
              color: 'white',
              width: '35px',
              margin: '0 auto',
            }}
          >
            {nde || '-'}
          </span>
        ),
        sorter: (a, b) => a.proactive_risk - b.proactive_risk,
        width: 125,
        align: 'center',
      },
      {
        title: 'City',
        dataIndex: 'city',
        render: nde => <span>{nde}</span>,
        sorter: (a, b) => stringSorter(a.city, b.city),
        width: TEXT_COLUMN_WIDTHS,
      },
      {
        title: 'State/Province',
        dataIndex: 'stateProvince',
        render: nde => <span>{nde}</span>,
        sorter: (a, b) => stringSorter(a.stateProvince, b.stateProvince),
        width: TEXT_COLUMN_WIDTHS,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        render: nde => <span>{nde}</span>,
        sorter: (a, b) => stringSorter(a.country, b.country),
        width: TEXT_COLUMN_WIDTHS,
        align: 'center',
      },
      {
        title: 'T1',
        dataIndex: 't1Links',
        render: (nde, row) => {
          return nde.length > 0 ? (
            <span
              onMouseUp={() => {
                mixpanelTrack('Facility dependency list - show drilldown table');
                toggleSubTier({
                  relations: nde,
                  list: getNodesAndLinks(graph, 1, row.id),
                  tier: 1,
                  facility: row.facility,
                });
              }}
              style={{ color: '#2d8fa5', cursor: 'pointer' }}
            >
              {nde.length}
            </span>
          ) : (
            <span>{nde.length}</span>
          );
        },
        sorter: (a, b) => a.t1Links.length - b.t1Links.length,
        width: TIER_COLUMN_WIDTHS,
        align: 'center',
      },
    ];
    if (filters.max_depth > 1) {
      columns = [
        ...columns,
        {
          title: 'T2',
          dataIndex: 't2Links',
          render: (nde, row) => {
            return nde.length > 0 ? (
              <span
                onMouseUp={() => {
                  mixpanelTrack('Facility dependency list - show drilldown table');
                  toggleSubTier({
                    relations: nde,
                    list: getNodesAndLinks(graph, 2, row.id),
                    tier: 2,
                    facility: row.facility,
                  });
                }}
                style={{ color: '#2d8fa5', cursor: 'pointer' }}
              >
                {nde.length}
              </span>
            ) : (
              <span>{nde.length}</span>
            );
          },
          sorter: (a, b) => a.t2Links.length - b.t2Links.length,
          width: TIER_COLUMN_WIDTHS,
          align: 'center',
        },
      ];
    }
    if (filters.max_depth > 2) {
      columns = [
        ...columns,
        {
          title: 'T3',
          dataIndex: 't3Links',
          render: (nde, row) => {
            return nde.length > 0 ? (
              <span
                onMouseUp={() => {
                  mixpanelTrack('Facility dependency list - show drilldown table');
                  toggleSubTier({
                    relations: nde,
                    list: getNodesAndLinks(graph, 3, row.id),
                    tier: 3,
                    facility: row.facility,
                  });
                }}
                style={{ color: '#2d8fa5', cursor: 'pointer' }}
              >
                {nde.length}
              </span>
            ) : (
              <span>{nde.length}</span>
            );
          },
          sorter: (a, b) => a.t3Links.length - b.t3Links.length,
          width: TIER_COLUMN_WIDTHS,
          align: 'center',
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: 'Total',
        dataIndex: 'total',
        render: (nde, row) => {
          return nde.length > 0 ? (
            <span
              onMouseUp={() => {
                mixpanelTrack('Facility dependency list - show drilldown table');
                toggleSubTier({
                  relations: nde,
                  list: getAllNodesAndLinks(
                    graph,
                    [row.id],
                    filters.max_depth > 1 ? [row.id] : [],
                    filters.max_depth > 2 ? [row.id] : [],
                  ),
                  facility: row.facility,
                });
              }}
              style={{ color: '#2d8fa5', cursor: 'pointer' }}
            >
              {nde.length}
            </span>
          ) : (
            <span>{nde.length}</span>
          );
        },
        sorter: (a, b) => a.total.length - b.total.length,
        width: TIER_COLUMN_WIDTHS,
        defaultSortOrder: 'descend',
        align: 'center',
      },
    ];
    return columns;
  };

  const countriesColumns = () => {
    let columns = [
      {
        title: '',
        dataIndex: 'discovered',
        render: nde => nde,
        width: 25,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        defaultSortOrder: 'ascend',
        render: nde => <span>{nde}</span>,
        sorter: (a, b) => stringSorter(a.country, b.country),
        width: TEXT_COLUMN_WIDTHS,
        align: 'center',
      },
    ];

    if (countryRisk) {
      columns.push({
        title: 'Country Risk',
        dataIndex: 'countryRisk',
        render: nde => {
          return (
            <span
              style={{
                borderRadius: '10px',
                verticalAlign: 'middle',
                display: 'table',
                background: valColor(nde),
                color: 'White',
                width: '35px',
                margin: '0 auto',
              }}
            >
              {nde || '-'}
            </span>
          );
        },
        sorter: (a, b) => a.countryRisk - b.countryRisk,
        width: TEXT_COLUMN_WIDTHS,
        align: 'center',
      });
    }
    columns = [
      ...columns,
      {
        title: 'T1',
        dataIndex: 'tier1Links',
        render: (nde, row) => {
          return nde.length > 0 ? (
            <span
              onMouseUp={() => {
                mixpanelTrack('Country dependency list - show drilldown table');
                toggleSubTier({
                  relations: nde,
                  list: getAllNodesAndLinks(
                    graph,
                    row.tier1.map(n => n.id),
                  ),
                  tier: 1,
                  country: row.country,
                });
              }}
              style={{ color: '#2d8fa5', cursor: 'pointer' }}
            >
              {nde.length}
            </span>
          ) : (
            <span>{nde.length}</span>
          );
        },
        sorter: (a, b) => a.tier1Links.length - b.tier1Links.length,
        width: TIER_COLUMN_WIDTHS,
        align: 'center',
      },
    ];
    if (filters.max_depth > 1) {
      columns = [
        ...columns,
        {
          title: 'T2',
          dataIndex: 'tier2Links',
          render: (nde, row) => {
            return nde.length > 0 ? (
              <span
                onMouseUp={() => {
                  mixpanelTrack('Country dependency list - show drilldown table');
                  toggleSubTier({
                    relations: nde,
                    list: getAllNodesAndLinks(
                      graph,
                      [],
                      row.tier2.map(n => n.id),
                    ),
                    tier: 2,
                    country: row.country,
                  });
                }}
                style={{ color: '#2d8fa5', cursor: 'pointer' }}
              >
                {nde.length}
              </span>
            ) : (
              <span>{nde.length}</span>
            );
          },
          sorter: (a, b) => a.tier2Links.length - b.tier2Links.length,
          width: TIER_COLUMN_WIDTHS,
          align: 'center',
        },
      ];
    }
    if (filters.max_depth > 2) {
      columns = [
        ...columns,
        {
          title: 'T3',
          dataIndex: 'tier3Links',
          render: (nde, row) => {
            return nde.length > 0 ? (
              <span
                onMouseUp={() => {
                  mixpanelTrack('Country dependency list - show drilldown table');
                  toggleSubTier({
                    relations: nde,
                    list: getAllNodesAndLinks(
                      graph,
                      [],
                      [],
                      row.tier3.map(n => n.id),
                    ),
                    tier: 3,
                    country: row.country,
                  });
                }}
                style={{ color: '#2d8fa5', cursor: 'pointer' }}
              >
                {nde.length}
              </span>
            ) : (
              <span>{nde.length}</span>
            );
          },
          sorter: (a, b) => a.tier3Links.length - b.tier3Links.length,
          width: TIER_COLUMN_WIDTHS,
          align: 'center',
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: 'Total',
        dataIndex: 'total',
        render: (nde, row) => {
          return nde.length > 0 ? (
            <span
              onMouseUp={() => {
                mixpanelTrack('Country dependency list - show drilldown table');
                toggleSubTier({
                  relations: nde,
                  list: getAllNodesAndLinks(
                    graph,
                    row.tier1.map(n => n.id),
                    filters.max_depth > 1 ? row.tier2.map(n => n.id) : [],
                    filters.max_depth > 2 ? row.tier3.map(n => n.id) : [],
                  ),
                  country: row.country,
                });
              }}
              style={{ color: '#2d8fa5', cursor: 'pointer' }}
            >
              {nde.length}
            </span>
          ) : (
            <span>{nde.length}</span>
          );
        },
        sorter: (a, b) => a.total.length - b.total.length,
        width: TIER_COLUMN_WIDTHS,
        align: 'center',
      },
    ];
    columns = [
      ...columns,
      {
        title: 'Action',
        dataIndex: 'country',
        render: nde => (
          <span>
            {nde && (
              <Link to={`/country-risk/${nde}/overview`} target="_blank">
                <FundOutlined />
              </Link>
            )}
          </span>
        ),
        width: TIER_COLUMN_WIDTHS,
        align: 'center',
      },
    ];
    return columns;
  };

  const suppliersDepsTableData = useMemo(
    () =>
      [...nodes.values()]
        .map(n => ({
          id: n.id,
          facility: n.name,
          city: n.city,
          stateProvince: n.state_province,
          discovered: <DetermineDiscover item={n} />,
          country: n.country,
          incident_risk: n.reactive__max_score,
          proactive_risk: n.proactive_scores__overall_score,
          t1Links: n.t1Links,
          t2Links: n.t2Links,
          t3Links: n.t3Links,
          total: [
            ...new Set(
              n.t1Links.concat(filters.max_depth > 1 ? n.t2Links : [], filters.max_depth > 2 ? n.t3Links : []),
            ),
          ],
        }))
        .filter(n => n.total.length),
    [nodes, filters],
  );

  const countriesDepsTableData = useMemo(
    () =>
      [...countryDeps.entries()]
        .map(c => ({
          id: c[0],
          country: c[0],
          countryRisk: c[1].countryRisk,
          discovered: <DetermineDiscover item={c} />,
          tier1: c[1].tier1,
          tier2: c[1].tier2,
          tier3: c[1].tier3,
          tier1Links: [...new Set((c[1].tier1 ?? []).flatMap(n => n.t1Links))],
          tier2Links: [...new Set((c[1].tier2 ?? []).flatMap(n => n.t2Links))],
          tier3Links: [...new Set((c[1].tier3 ?? []).flatMap(n => n.t3Links))],
          total: [
            ...new Set(
              (c[1].tier1 ?? [])
                .flatMap(n => n.t1Links)
                .concat(
                  filters.max_depth > 1 ? (c[1].tier2 ?? []).flatMap(n => n.t2Links) : [],
                  filters.max_depth > 2 ? (c[1].tier3 ?? []).flatMap(n => n.t3Links) : [],
                ),
            ),
          ],
        }))
        .filter(c => c.total.length),
    [countryDeps, filters],
  );

  return (
    <Table
      key={depsTableToggleState}
      rowKey="id"
      columns={depsTableToggleState === DEPS_TABLE_STATE_FACILITIES ? suppliersColumns() : countriesColumns()}
      dataSource={
        depsTableToggleState === DEPS_TABLE_STATE_FACILITIES ? suppliersDepsTableData : countriesDepsTableData
      }
      size="small"
      style={{
        width: '100%',
        margin: '0 auto',
        zIndex: '0',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#212121',
        userSelect: 'none',
      }}
      onRow={record => {
        return depsTableToggleState === DEPS_TABLE_STATE_FACILITIES
          ? {
              onClick: () => {
                dispatch(focusNode(record.id));
              },
            }
          : {};
      }}
    />
  );
};

export default NetworkDependenciesTable;
