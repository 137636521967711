import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import { Button, BasicModal as Modal, FormItem, Label } from '@luxe/components';
import { useTheme } from 'emotion-theming';
import { UNIT_OPTIONS } from 'common/Constants';

const { Text } = Typography;

const HeaderRow = ({ form, user, deleteUserFunction, editUserFunction, activeTab }) => {
  const { space, colors, fontSizes } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const backUrl = `/admin/user-groups/`;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const nameRules = [
    {
      required: true,
      message: 'The user name cannot be blank',
    },
  ];

  const saveUserChanges = () => {
    if (editUserFunction) {
      form
        .validateFields()
        .then(({ name, email, admin, phone, password, type: { value: type } = {}, units: { value: units } = {} }) => {
          const prefs = {
            units: (units && UNIT_OPTIONS[units]) || UNIT_OPTIONS['imperial'],
          };

          let payload = {
            name,
            email,
            phone,
            type,
            permissions: [],
            prefs,
          };

          if (type === 'user') {
            payload = { ...payload, password };
          }

          if (admin === true) {
            payload.permissions = ['admin'];
          }
          dispatch(editUserFunction(user.id, payload));
        });
    }
  };

  const deleteUser = () => {
    setDeleteModalVisible(true);
  };

  const notificationsTab = activeTab === 'Notifications';

  return (
    <>
      <Row style={{ marginBottom: `${space.xsmall}px` }}>
        <Col span={10} align="start">
          <FormItem name="name" label="Name" labelCol={{ span: 24 }} rules={nameRules}>
            <Label disabled={notificationsTab} />
          </FormItem>
        </Col>
        {activeTab !== 'Notifications' && (
          <Col span={14} align="end">
            <Space>
              <Button
                variant="menu"
                type="link"
                form={form}
                onClick={saveUserChanges}
                style={{ backgroundColor: colors.primary }}
              >
                Save
              </Button>
              <Button variant="menu" type="link" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button variant="menu" type="link" form={form} onClick={deleteUser}>
                Delete
              </Button>
            </Space>
          </Col>
        )}
      </Row>
      <Modal
        visible={deleteModalVisible}
        title="Delete User?"
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        onOk={() => {
          setDeleteModalVisible(false);
          if (deleteUserFunction) {
            dispatch(deleteUserFunction(user.id));
          }
          history.push(backUrl);
        }}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>
          Are you sure that you want to delete {user.name}?
        </Text>
      </Modal>
    </>
  );
};

export default HeaderRow;
