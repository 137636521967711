import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

/*
const setIndices = (obj, index, parent) => {
  // This is really screwy but I need to keep
  // track of what the indexes are for a RiskIndex or Component.
  let indices = [index];
  if (parent && parent._indices) {
    indices = parent._indices.concat(indices);
  }

  return {
    ...obj,
    indexArray: indices,
    _key: key,
    fucl: 'set',
  };
};
*/

const setIndicesRecursive = (parent, obj, i) => {
  const indexArray = parent && parent.indexArray ? parent.indexArray.concat([i]) : [i];
  const key = obj.key === undefined ? uuidv4() : obj.key;
  let newIndices = undefined;
  let total = undefined;
  if (obj.indices) {
    newIndices = obj.indices.map((x, j) => {
      const parent = {
        ...obj,
        indexArray,
        key,
      };
      return setIndicesRecursive(parent, x, j);
    });
    total = newIndices.map(x => x.weight || 0).reduce((acc, x) => parseInt(acc) + parseInt(x), 0);
  }

  let newComponents = undefined;
  let componentTotal = undefined;
  if (obj.components) {
    newComponents = obj.components.map((x, j) => {
      const parent = {
        ...obj,
        indexArray,
        key,
      };
      return setIndicesRecursive(parent, x, j);
    });

    total = newIndices.map(x => x.weight || 0).reduce((acc, x) => parseInt(acc) + parseInt(x), 0);
    componentTotal = newComponents
      ? newComponents.map(x => x.weight || 0).reduce((acc, x) => parseInt(acc) + parseInt(x), 0)
      : undefined;
  }

  return {
    ...obj,
    indexArray,
    key,
    indices: newIndices,
    components: newComponents,
    usedRiskModelScores: newComponents ? newComponents.map(x => x.id).filter(x => x) : [],
    componentTotal,
    total,
  };
};

const transformRiskModel = obj => {
  const newIndices = obj.indices.map((x, i) => {
    return setIndicesRecursive(null, x, i);
  });
  return {
    ...obj,
    indices: newIndices,
  };
};

const fetchRiskModelScoreIds = model => {
  const ids = [];
  fetchRiskModelScoreIdsRecursive(model, ids);
  return ids;
};

const fetchRiskModelScoreIdsRecursive = (model, ids) => {
  const obj = model;
  if (obj.components) {
    ids.push(...obj.components.map(x => x.rule.id).filter(x => x));
  }
  if (obj.indices) {
    obj.indices.map(i => fetchRiskModelScoreIdsRecursive(i, ids));
  } else {
    return;
  }
};

const isIndexValid = index => {
  //check for component totals
  if (index.components && index.components.length > 0) {
    if (index.componentTotal !== 100) {
      return false;
    }
  }

  //check indices if components are correct
  if (index.indices && index.indices.length > 0) {
    if (index.total === 100) {
      for (let i in index.indices) {
        //if index is not valid then end loop and return
        if (!isIndexValid(index.indices[i])) {
          return false;
        }
      }
    } else {
      //indices total is off
      return false;
    }
  }
  //empty index should be fine
  return true;
};

const isModelValid = model => {
  //if index components exist and are correct then check index totals
  for (let i in model.indices) {
    if (!isIndexValid(model.indices[i])) {
      return false;
    }
  }

  //check top level model for final output
  return (
    model.indices &&
    model.indices.length &&
    model.indices.map(x => x.weight).reduce((total, weight) => parseInt(total) + parseInt(weight)) === 100
  );
};

const updatedModelMatchesCopy = (updatedModel, modelCopy) => {
  if (updatedModel.indices && modelCopy.indices && updatedModel.indices.length !== modelCopy.indices.length) {
    return false;
  }
  for (let i in modelCopy.indices) {
    if (!compareIndices(modelCopy.indices[i], updatedModel.indices[i])) {
      return false;
    }
  }
  return true;
};

const compareIndices = (modelIndex, copyIndex, areEqual = true) => {
  if (
    modelIndex.components &&
    copyIndex.components &&
    modelIndex.components.length > 0 &&
    copyIndex.components.length > 0
  ) {
    if (modelIndex.components.length !== copyIndex.components.length) {
      return false;
    }
    if (modelIndex.componentTotal !== 100 || copyIndex.componentTotal !== 100) {
      return false;
    }
  }

  if (modelIndex.indices && copyIndex.indices && modelIndex.indices.length > 0 && copyIndex.indices.length > 0) {
    areEqual = compareIndices(modelIndex.indices, copyIndex.indices, areEqual);
  }

  for (let key in modelIndex) {
    if (copyIndex.hasOwnProperty(key)) {
      if (key === 'indexArray') {
        continue;
      } else if (!_.isEqual(modelIndex[key], copyIndex[key])) {
        areEqual = false;
      }
    } else {
      areEqual = false;
    }
  }

  return areEqual;
};

const transformRiskModelScores = objs => {
  const families = {};
  objs.forEach(({ family, phenomena, attribute, datasource_id, rule, rule_type, user_editable, name }) => {
    if (!family.parent) {
      family.parent = {
        id: null,
        name: 'Other',
      };
    }

    if (families[family.parent.id] === undefined) {
      families[family.parent.id] = {
        id: family.parent.id,
        name: family.parent.name,
        riskModelScores: [],
      };
    }

    const { riskModelScores: allRiskModelScores } = families[family.parent.id];
    families[family.parent.id].riskModelScores = allRiskModelScores.concat([
      {
        ...phenomena,
        name: name,
        attribute: attribute,
        datasource_id: datasource_id,
        rule: rule,
        rule_type: rule_type,
        user_editable: user_editable,
      },
    ]);
  });
  return Object.values(families);
};

const removeRiskModelScores = (families, remove) => {
  const { family, riskModelScoreId } = remove;
  for (let f in families) {
    if (family === families[f].id) {
      const { riskModelScore: allRiskModelScores } = families[f];
      families[f].riskModelScore = allRiskModelScores.filter(riskModelScore => riskModelScore.id !== riskModelScoreId);
    }
  }
  return families;
};

const updateRiskIndexRecursive = (obj, indexArray, data) => {
  const n = indexArray.shift();
  // If its undefined, were inside of the last object
  // that is supposed to be updated..
  if (n === undefined) {
    return {
      ...obj,
      ...data,
    };
  } else {
    const newIndices = obj.indices.map((x, i) => {
      if (i === n) {
        return updateRiskIndexRecursive(x, indexArray, data);
      } else {
        return x;
      }
    });
    return {
      ...obj,
      indices: newIndices,
      total: newIndices.map(x => x.weight).reduce((acc, x) => parseInt(acc) + parseInt(x), 0),
    };
  }
};

const createRiskIndexRecursive = (obj, indexArray, data) => {
  if (indexArray.length === 0) {
    const order = obj.indices.length + 1;
    return {
      ...obj,
      indices: obj.indices.concat({ ...data, order }),
    };
  }

  // Otherwise we continue to call recursively as needed:
  const n = indexArray.shift();
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return createRiskIndexRecursive(x, indexArray, data);
      }
      return x;
    }),
  };
};

const deleteRiskIndexRecursive = (obj, indexArray) => {
  const n = indexArray.shift();
  // If the size of remaining array is 0, then we're
  // at the level where an index needs to be deleted:
  if (indexArray.length === 0) {
    return {
      ...obj,
      indices: obj.indices.filter((x, i) => i !== n),
    };
  }

  // Otherwise we continue to call recursively as needed:
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return deleteRiskIndexRecursive(x, indexArray);
      }

      return x;
    }),
  };
};

const updateRiskComponentRecursive = (obj, indexArray, data) => {
  const n = indexArray.shift();
  // If size of remaining array is zero, we are
  // inside of the object that has the component
  // that needs to be updated:
  if (indexArray.length === 0) {
    return {
      ...obj,
      components: obj.components.map((x, i) => {
        if (i === n) {
          return {
            ...x,
            ...data,
          };
        } else {
          return x;
        }
      }),
    };
  } else {
    const newIndices = obj.indices.map((x, i) => {
      if (i === n) {
        return updateRiskComponentRecursive(x, indexArray, data);
      } else {
        return x;
      }
    });
    return {
      ...obj,
      indices: newIndices,
    };
  }
};

const createRiskComponentRecursive = (obj, indexArray, data) => {
  if (indexArray.length === 0) {
    const components = obj.components || [];
    const order = components.length + 1;
    return {
      ...obj,
      components: components.concat({ ...data, order }),
    };
  }

  // Otherwise we continue to call recursively as needed:
  const n = indexArray.shift();
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return createRiskComponentRecursive(x, indexArray, data);
      }
      return x;
    }),
  };
};

const deleteRiskComponentRecursive = (obj, indexArray) => {
  const n = indexArray.shift();
  // If the size of remaining array is 0, then we're
  // at the level where an index needs to be deleted:

  if (indexArray.length === 0) {
    return {
      ...obj,
      components: obj.components.filter((x, i) => i !== n),
    };
  }

  // Otherwise we continue to call recursively as needed:
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return deleteRiskComponentRecursive(x, indexArray);
      }
      return x;
    }),
  };
};

const reorderRiskComponentRecursive = ({ obj, indexArray, ...props }) => {
  // If the size of remaining array is 0, then we're
  // at the level where an index needs to be deleted:
  if (indexArray.length === 0) {
    const { fromIndex, toIndex } = props;
    const deleteIndex = fromIndex > toIndex ? fromIndex + 1 : fromIndex;
    const insertIndex = fromIndex > toIndex ? toIndex : toIndex + 1;
    const from = obj.components[fromIndex];
    const newComponents = [...obj.components.slice(0, insertIndex), from, ...obj.components.slice(insertIndex)];
    const components = newComponents
      .filter((_, i) => i !== deleteIndex)
      .map((x, i) => {
        return { ...x, order: i + 1 };
      });

    return {
      ...obj,
      components,
    };
  }

  const n = indexArray.shift();

  // Otherwise we continue to call recursively as needed:
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return reorderRiskComponentRecursive({ obj: x, ...props, indexArray });
      }
      return x;
    }),
  };
};

const reorderRiskIndexRecursive = ({ obj, indexArray, ...props }) => {
  // If the size of remaining array is 0, then we're
  // at the level where an index needs to be deleted:
  if (indexArray.length === 0) {
    const { fromIndex, toIndex } = props;
    const deleteIndex = fromIndex > toIndex ? fromIndex + 1 : fromIndex;
    const insertIndex = fromIndex > toIndex ? toIndex : toIndex + 1;
    const from = obj.indices[fromIndex];
    const newIndex = [...obj.indices.slice(0, insertIndex), from, ...obj.indices.slice(insertIndex)];
    const indices = newIndex
      .filter((_, i) => i !== deleteIndex)
      .map((x, i) => {
        return { ...x, order: i + 1 };
      });

    return {
      ...obj,
      indices,
    };
  }

  const n = indexArray.shift();

  // Otherwise we continue to call recursively as needed:
  return {
    ...obj,
    indices: obj.indices.map((x, i) => {
      if (i === n) {
        return reorderRiskIndexRecursive({ obj: x, ...props, indexArray });
      }
      return x;
    }),
  };
};

export {
  createRiskComponentRecursive,
  createRiskIndexRecursive,
  deleteRiskComponentRecursive,
  deleteRiskIndexRecursive,
  reorderRiskComponentRecursive,
  reorderRiskIndexRecursive,
  transformRiskModelScores,
  transformRiskModel,
  updateRiskComponentRecursive,
  updateRiskIndexRecursive,
  removeRiskModelScores,
  fetchRiskModelScoreIds,
  isModelValid,
  updatedModelMatchesCopy,
};
