import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { ExpandableText, OverviewContainer } from './styles';

const RiskAndEconomicSection = () => {
  const { region } = useSelector(store => store.regions);
  const { content } = region;

  const OverviewContent = ({ content = {} }) => {
    const itemRef = useRef(null);

    let result = [];
    if (content?.risk_overview) {
      result.push({
        title: 'Risk',
        description: content.risk_overview,
      });
    }

    if (content?.economic_overview) {
      result.push({
        title: 'Economic',
        description: content.economic_overview,
      });
    }

    return result.map((tile, idx) => {
      return (
        <div
          ref={itemRef}
          key={`craOverview-content-${idx}`}
          className={`craOverview-content craOverview-content-div${idx}`}
        >
          <h2>{`${tile.title} Overview`}</h2>
          <ExpandableText text={tile.description} rows={4} noContentMessage={`No ${tile.title} Overview Information`} />
        </div>
      );
    });
  };

  return (
    <>
      <OverviewContainer className="craOverview-container">
        {content ? <OverviewContent content={content?.overview} /> : null}
      </OverviewContainer>
    </>
  );
};

export default RiskAndEconomicSection;
