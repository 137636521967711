import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, TreeSelect } from 'antd';
import { FormItem, Section, Select } from '@luxe/components';
import { ASSET_TYPES } from 'common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { formatSelectOptions } from 'common/utils';
import { getCommodities } from 'modal/userprofile/modules/network';
import styled from '@emotion/styled';

const AssetGeneralSection = styled(({ asset, form, ...props }) => {
  const firstLoad = useRef(true);
  const dispatch = useDispatch();
  const { commodities } = useSelector(store => store.userNetwork);
  const riskModels = useSelector(store => store.riskModels)?.RiskModels || [];
  const riskProfiles = useSelector(store => store.riskProfiles)?.riskProfiles || [];
  const users = useSelector(store => store.users?.users) || [];
  const commodity_pref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled);
  const [formattedCommodities, setFormattedCommodities] = useState([]);
  const riskModelOptions = formatSelectOptions(riskModels);
  const riskProfileOptions = formatSelectOptions(riskProfiles);
  const assetContacts = asset?.contacts || [];
  const primaryContacts = assetContacts.filter(c => c.type === 'primary_contact').map(contact => contact.user);
  const allAssetTypes = useMemo(() => [...ASSET_TYPES], []);

  useEffect(() => {
    if (asset && firstLoad.current) {
      const profiles = asset.profile_ids.map(profileId =>
        riskProfileOptions.find(profileOption => profileOption.value === profileId),
      );
      const models = asset.risk_model_ids.map(modelId =>
        riskModelOptions.find(modelOption => modelOption.value === modelId),
      );
      let assetType = ASSET_TYPES.find(aType => aType.value === asset.org_type);
      if (!assetType && asset.org_type) {
        assetType = { label: asset.org_type.replaceAll('-', ' ').toUpperCase(), value: asset.org_type };
        allAssetTypes.push(assetType);
      }

      const commodityValue = asset.commodities?.map(comm => {
        return comm.id;
      });

      const initialValues = {
        type: assetType,
        risk_profiles: profiles,
        risk_models: models,
        primary_contacts: formatSelectOptions(primaryContacts),
        commodity: commodityValue || [],
      };
      form.setFieldsValue(initialValues);
      firstLoad.current = false;
    }
  }, [allAssetTypes, asset, form, riskModelOptions, riskProfileOptions, primaryContacts]);

  useEffect(() => {
    dispatch(getCommodities());
  }, [dispatch]);

  useEffect(() => {
    const populateChild = arr => {
      return arr.map(i => {
        return {
          title: i.name,
          value: i.id,
          key: i.id,
          children: i.children?.length > 0 ? populateChild(i.children) : [],
        };
      });
    };
    if (commodities?.length && formattedCommodities.length === 0) {
      setFormattedCommodities(populateChild(commodities));
    }
  }, [commodities, formattedCommodities.length]);

  return (
    <>
      <Section title={'General'} headerFontSize={'12px'} backgroundColor={'transparent'}>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <FormItem
              name="type"
              label="Facility Type"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: 'You must select an asset type' }]}
            >
              <Select options={allAssetTypes} name="asset_types" width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="risk_profiles" label="Risk Profiles" labelCol={{ span: 24 }} style={{ marginLeft: '20px' }}>
              <Select isMulti={true} options={riskProfileOptions} name="risk_profiles" width={'100%'} />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '15px' }}>
          <Col span={12}>
            <FormItem name="risk_models" label="Risk Models" labelCol={{ span: 24 }}>
              <Select isMulti={true} options={riskModelOptions} name="risk_models" width={'100%'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="primary_contacts"
              label="Facility Contacts"
              labelCol={{ span: 24 }}
              style={{ marginLeft: '20px' }}
            >
              <Select isMulti={true} options={formatSelectOptions(users)} name="primary_contacts" width={'100%'} />
            </FormItem>
          </Col>
        </Row>
        {commodity_pref && (
          <Row style={{ width: '100%', marginTop: '15px' }}>
            <Col span={12}>
              <FormItem name="commodity" label="Commodities" labelCol={{ span: 24 }} className={props.className}>
                <TreeSelect
                  allowClear={true}
                  name="commodity"
                  placeholder={'Select Commodities'}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  showSearch={true}
                  treeCheckable={true}
                  treeData={formattedCommodities}
                  dropdownStyle={{
                    backgroundColor: '#212121',
                  }}
                  filterTreeNode={(search, option) => {
                    return (
                      option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                    );
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        )}
      </Section>
    </>
  );
})`
  .ant-picker-input > input {
    color: #ffffff;
  }

  .ant-select {
    background-color: #2c2c2c;
    color: #919191;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #141414;
    border-color: #246472;
    border-radius: 4px;
  }
  .ant-select-tree,
  .ant-select-dropdown,
  .ant-tree-select-dropdown,
  .ant-tree-select-dropdown .ant-select-tree,
  .ant-select-multiple .ant-select-selection-item {
    background-color: #40424e;
    border: 1px solid #54596e;
    color: #ccc5b8;
    font-size: 85%;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #cccccc;
  }
  .loader {
    color: ${props => props.theme.colors.white};
  }

  label {
    color: ${props => props.theme.colors.white};
  }

  .ant-select-clear {
    background-color: transparent;
  }
`;

export default AssetGeneralSection;
