import { Table } from '@luxe/components';
import { Button, Col, Row } from 'antd';
import { GraphContext } from 'discover/modules/GraphProvider';
import { focusNode } from 'discover/modules/viz';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringSorter } from 'utils';
import useMixpanel from 'utils/hooks/Mixpanel';

const SupplierDependenciesSubTable = ({ subTierData, toggleSubTier }) => {
  const [ds, setDataSource] = useState([]);
  const materialFlowEnabled = useSelector(store => store.accountOrg.accountOrg?.prefs?.materials?.enabled || false);
  const isDiscoverEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled || false);
  const relationshipStrengthEnabled = useSelector(
    store => store.accountOrg?.accountOrg?.prefs?.discover?.relationship_strength_enabled,
  );
  const graph = useContext(GraphContext);
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();

  useEffect(() => {
    if (subTierData?.relations?.length > 0) {
      setDataSource(
        subTierData.relations.map(l => {
          const source = graph.getNode(l.source);
          const target = graph.getNode(l.target);
          return {
            id: l.id,
            sourceNodeId: l.source,
            tier: source.tier,
            material: l.material__id,
            finalProduct: l.final_material__name,
            hsCode: l.hscode6,
            hsCodeDesc: l.hscode6_desc,
            nextLocation: target.name,
            relationshipStrength: l.relationship_strength,
          };
        }),
      );
    }
  }, [subTierData.relations, graph]);

  const columns = [
    {
      title: 'Tier',
      dataIndex: 'tier',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.tier, b.tier),
      width: 50,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'Material',
      dataIndex: 'material',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.material, b.material),
      width: 100,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'Final Product',
      dataIndex: 'finalProduct',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.finalProduct, b.finalProduct),
      width: 125,
      align: 'center',
    },
    isDiscoverEnabled && {
      title: 'HS Code',
      dataIndex: 'hsCode',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCode, b.hsCode),
      width: 50,
      align: 'center',
    },
    isDiscoverEnabled && {
      title: 'HS Code Description',
      dataIndex: 'hsCodeDesc',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCodeDesc, b.hsCodeDesc),
      width: 125,
      align: 'center',
    },
    {
      title: 'Next Location',
      dataIndex: 'nextLocation',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.nextLocation, b.nextLocation),
      width: 125,
      align: 'center',
    },
    relationshipStrengthEnabled && {
      title: 'Relationship Strength',
      dataIndex: 'relationshipStrength',
      render: nde => <span>{nde?.toFixed(2)}</span>,
      sorter: (a, b) => a.relationshipStrength - b.relationshipStrength,
      width: 50,
      align: 'center',
    },
  ].filter(e => e);

  const closeSubTierTable = () => {
    mixpanelTrack('Facility dependency list - hide drilldown table');
    toggleSubTier(null);
  };

  return (
    <>
      <Col style={{ width: '100%', marginBottom: '10px' }}>
        <Row style={{ lineHeight: '40px' }}>
          <label style={{ margin: '0 0 10px 0', fontSize: '16px' }}>
            Facility Dependencies for <strong>{subTierData?.facility} </strong>
            {subTierData?.tier ? (
              <>
                at Tier <strong>{subTierData?.tier}</strong>
              </>
            ) : (
              ''
            )}
          </label>

          <Button
            onClick={closeSubTierTable}
            style={{ backgroundColor: '#545454', position: 'absolute', right: '0', border: '0', padding: '0 6px' }}
          >
            Back
          </Button>
        </Row>
        <Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={ds}
            size="small"
            style={{ width: '100%', zIndex: '0', display: 'flex', flexDirection: 'column' }}
            onRow={record => {
              return {
                onClick: () => {
                  dispatch(focusNode(record.sourceNodeId));
                },
              };
            }}
          />
        </Row>
      </Col>
    </>
  );
};

export default SupplierDependenciesSubTable;
