import React from 'react';
import { Menu, MenuItem } from '@luxe/components';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { MODAL_TYPES } from 'admin/constants';

const GroupMenu = ({ onSelect }) => {
  return (
    <Menu onClick={onSelect} className="group-menu">
      <MenuItem key={MODAL_TYPES.ADD} icon={<PlusOutlined />}>
        Add
      </MenuItem>
      <MenuItem key={MODAL_TYPES.EDIT} icon={<EditOutlined />}>
        Edit
      </MenuItem>
      <MenuItem key={MODAL_TYPES.DELETE} icon={<DeleteOutlined />} danger={true}>
        Delete
      </MenuItem>
    </Menu>
  );
};

export default GroupMenu;
