import React from 'react';
import PropTypes from 'prop-types';

export default class SupplierLocationFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    if (this.props.value) {
      const url = `/assets/${this.props.value.id}/`;
      return <a href={url}>{this.props.value.name}</a>;
    }
    return '';
  }
}
