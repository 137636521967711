import React from 'react';
import PropTypes from 'prop-types';

export default class SubTableFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    onValueClick: PropTypes.func,
  };

  constructor() {
    super();
    this.state = { color: '#2d8fa5' };
  }

  handleMouseEnter = () => {
    this.setState({ color: 'white' });
  };

  handleMouseLeave = () => {
    this.setState({ color: '#2d8fa5' });
  };

  render() {
    return (
      <>
        {this.props.value && this.props.value !== '0' && (
          <span
            onClick={this.props.onValueClick}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            style={{ color: this.state.color, cursor: 'pointer' }}
          >
            {this.props.value}
          </span>
        )}
        {(!this.props.value || this.props.value === '0') && <span style={{ color: this.state.color }}>0</span>}
      </>
    );
  }
}
