import { Button, Col, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTable } from 'views/components';
import { getViewData, getViewMeta } from 'views/modules/views';
import { ASSET_META_OVERRIDES } from '.';
import { operators as Ops } from 'constants/application';
import { getParams } from 'views/utils';

const ImpactedAssets = ({ onBackButtonClick, selectedSubTier, fromList = false }) => {
  const dispatch = useDispatch();
  const views = useSelector(store => store.views);

  const assets = views?.ImpactedAssets;
  const init = assets === undefined;

  useEffect(() => {
    if (init) {
      dispatch(getViewMeta('ImpactedAssets', ASSET_META_OVERRIDES, null, undefined, 'assets'));
    }
  }, [dispatch, init]);

  const hasMeta = assets?.meta?.allowed !== undefined;
  const { search: url } = useSelector(store => store.router.location);
  if (assets) assets.currentUrl = url;

  const query = useMemo(() => {
    if (!hasMeta) {
      return null;
    }

    const params = getParams(url, ['channel', 'mixpanel_event']);
    let filters = [
      {
        name: 'downstream_assets',
        operator: Ops.exact,
        value: selectedSubTier?.asset?.id,
      },
    ];

    if (!fromList) {
      filters = [...filters, ...params.filters];
    }

    return {
      columns: ['asset', 'org_type', 'location__city', 'in_network', 'risk'],
      ...params,
      filters,
      limit: 10,
    };
  }, [hasMeta, selectedSubTier, url, fromList]);

  useEffect(() => {
    if (query !== null) {
      dispatch(getViewData('ImpactedAssets', query, null, 'assets'));
    }
  }, [dispatch, query]);

  if (assets?.items === undefined) {
    return null;
  }
  const getRowId = row => row?.asset?.id;

  return (
    <>
      <Col style={{ width: '100%', marginBottom: '10px' }}>
        <Row style={{ lineHeight: '40px' }}>
          <label style={{ margin: '0 0 10px 0', color: 'white', maxWidth: '88%' }}>
            Impacted Facilities for{' '}
            <span style={{ fontSize: '16px', fontWeight: 600 }}>{selectedSubTier?.asset?.name}</span>
          </label>
          <Button
            onClick={onBackButtonClick}
            style={{ backgroundColor: '#545454', position: 'absolute', right: '0', border: '0', padding: '0 6px' }}
          >
            Back
          </Button>
        </Row>
        <ViewTable data={assets} rowKey={getRowId} />
      </Col>
    </>
  );
};

export default ImpactedAssets;
