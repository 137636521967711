import React from 'react';

import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from '@luxe/components';

const getParams = (url, exclude) => {
  const paramParser = new URLSearchParams(url);
  const newParams = {};
  paramParser.forEach((value, key) => {
    if (!exclude || !exclude.includes(key)) newParams[key] = value;
  });
  return newParams;
};

const formatParams = url => {
  const newParams = getParams(url, ['offset', 'limit']);
  return new URLSearchParams(newParams).toString();
};

const getUrl = currentUrl => {
  let baseUrl = `${window.location.pathname}?`;
  if (!currentUrl) {
    return baseUrl;
  }
  const hasParams = currentUrl.indexOf('?') !== -1;
  let paramsPart = hasParams ? formatParams(currentUrl.split('?')[1]) : '';
  const newUrl = paramsPart ? `${baseUrl}${paramsPart}&` : `${baseUrl}`;
  return newUrl;
};

const Pagination = styled(({ currentUrl, previous, next, pending, ...props }) => {
  const dispatch = useDispatch();
  const baseUrl = getUrl(currentUrl);

  const nextClick = () => {
    const nextParams = getParams(next.split('?')[1]);
    const url = `${baseUrl}offset=${nextParams.offset}&limit=${nextParams.limit}`;
    dispatch(push(url));
  };
  const prevClick = () => {
    const previousParams = getParams(previous.split('?')[1]);
    const { offset = 0 } = previousParams;
    const url = `${baseUrl}offset=${offset}&limit=${previousParams.limit}`;
    dispatch(push(url));
  };
  const previousButton =
    !pending && previous ? (
      <Button variant="primary" onClick={prevClick}>
        <LeftOutlined />
      </Button>
    ) : null;
  const nextButton =
    !pending && next ? (
      <Button variant="primary" onClick={nextClick}>
        <RightOutlined />
      </Button>
    ) : null;

  return (
    <div className={props.className}>
      {previousButton}
      {nextButton}
    </div>
  );
})`
  text-align: right;
  margin-top: 20px;

  button.ant-btn {
    margin-left: 10px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export default Pagination;
