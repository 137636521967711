import { NODE_COLORS, NODE_COLOR_INCIDENT_RISK_SCORE, NODE_COLOR_RISK_SCORE, NODE_COLOR_TIER } from './viz';

const nodeColor = (n, type) => {
  switch (type) {
    case NODE_COLOR_TIER:
      const tier = (n?.tier_level ?? []).sort()[0];
      return NODE_COLORS[NODE_COLOR_TIER][tier] ?? NODE_COLORS[NODE_COLOR_TIER].default;
    case NODE_COLOR_RISK_SCORE:
      return valColor(n?.proactive_scores__overall_score);
    case NODE_COLOR_INCIDENT_RISK_SCORE:
    default:
      return incidentValColor(n?.reactive__max_score);
  }
};

const valColor = n => {
  let color = NODE_COLORS[NODE_COLOR_RISK_SCORE].default;
  if (n) {
    let score = Math.round(n);
    if (score >= 17) {
      color = NODE_COLORS[NODE_COLOR_RISK_SCORE].high;
    } else if (score >= 9) {
      color = NODE_COLORS[NODE_COLOR_RISK_SCORE].medium;
    } else {
      color = NODE_COLORS[NODE_COLOR_RISK_SCORE].low;
    }
  }
  return color;
};

const incidentValColor = n => {
  let color = NODE_COLORS[NODE_COLOR_INCIDENT_RISK_SCORE].default;
  if (n) {
    let score = Math.round(n);
    if (score >= 17) {
      color = NODE_COLORS[NODE_COLOR_INCIDENT_RISK_SCORE].high;
    } else if (score >= 9) {
      color = NODE_COLORS[NODE_COLOR_INCIDENT_RISK_SCORE].medium;
    } else {
      color = NODE_COLORS[NODE_COLOR_INCIDENT_RISK_SCORE].low;
    }
  }
  return color;
};

const arrowColor = l => {
  return '#767676';
};

const linkColor = l => {
  return '#c3c3c3';
};

const generateMarks = number => {
  let marks = {};
  for (let i = 1; i <= number; i++) {
    marks = { ...marks, [i]: i };
  }
  return marks;
};

const isDiscovered = (node, org_id) => node?.source_type === 'ekg';

const linkName = link => {
  let textDisplayed = link.material__id ?? '';
  if (link.material__id && link.material__name) textDisplayed = `${textDisplayed} - ${link.material__name}`;
  if (textDisplayed.length < 1) {
    textDisplayed = link.hscode6 ?? 'No data available.';
    if (link.hscode6 && link.hscode6_desc) textDisplayed = `${textDisplayed} - ${link.hscode6_desc}`;
  }
  return textDisplayed;
};

const link2DWidth = (link, widthTiers) => {
  if (widthTiers.length === 1) return 1;
  if (widthTiers.length === 2 && link.collapsedLinks?.length > Math.min(...widthTiers)) return 7;
  if (widthTiers.length > 2) {
    const breakdown = Math.ceil(widthTiers.length / 3);
    widthTiers.sort((first, last) => last - first);
    const largeLinks = widthTiers.slice().splice(0, breakdown);
    if (largeLinks.includes(link.collapsedLinks?.length)) return 7;
    const mediumLinks = widthTiers.slice().splice(breakdown, breakdown);
    if (mediumLinks.includes(link.collapsedLinks?.length)) return 4;
    const smallLinks = widthTiers.slice().splice(-breakdown);
    if (smallLinks.includes(link.collapsedLinks?.length)) return 1;
  }
  return 1;
};

const focusGraphNode = (node, graphRef, is2D, padding) => {
  const distance = 40;
  if (!node) return;
  if (!is2D) {
    if (!node.z) return;
    const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

    graphRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio },
      node,
      3000,
    );
  } else {
    graphRef.current.zoomToFit(300, padding, n => n.id === node.id);
  }
};

export {
  arrowColor,
  generateMarks,
  linkColor,
  nodeColor,
  valColor,
  isDiscovered,
  linkName,
  link2DWidth,
  focusGraphNode,
};
