import styled from '@emotion/styled';
import { GraphContext, useGraphSelector } from 'discover/modules/GraphProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { stringSorter } from 'utils';
import { Table } from '@luxe/components';

const OutboundFlows = styled(({ node, className }) => {
  const [dataSource, setDataSource] = useState([]);
  const { filters } = useSelector(store => store.networkGraph);
  const graph = useContext(GraphContext);
  const nodes = useGraphSelector(graph => graph.observers.nodes);
  const materialFlowEnabled = useSelector(store => store.accountOrg.accountOrg?.prefs?.materials?.enabled || false);
  const relationshipStrengthEnabled = useSelector(
    store => store.accountOrg?.accountOrg?.prefs?.discover?.relationship_strength_enabled,
  );

  const selectedNode = useMemo(() => {
    return nodes?.find(x => x.id === node.id);
  }, [nodes, node]);

  useEffect(() => {
    if (selectedNode) {
      const data = [
        ...new Set(
          selectedNode.t1Links.concat(
            filters.max_depth > 1 ? selectedNode.t2Links : [],
            filters.max_depth > 2 ? selectedNode.t3Links : [],
          ),
        ),
      ];
      setDataSource(
        data.map(l => {
          const source = graph.getNode(l.source);
          const target = graph.getNode(l.target);
          return {
            id: l.id,
            sourceNodeId: l.source,
            tier: source.tier,
            material: l.material__id,
            finalProduct: l.final_material__name,
            hsCode: l.hscode6,
            hsCodeDesc: l.hscode6_desc,
            nextLocation: target.name,
            relationshipStrength: l.relationship_strength,
          };
        }),
      );
    }
  }, [selectedNode, graph, filters]);

  const columns = [
    {
      title: 'Tier',
      dataIndex: 'tier',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.tier, b.tier),
      width: 50,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'Material',
      dataIndex: 'material',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.material, b.material),
      width: 100,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'Final Product',
      dataIndex: 'finalProduct',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.finalProduct, b.finalProduct),
      width: 125,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'HS Code',
      dataIndex: 'hsCode',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCode, b.hsCode),
      width: 50,
      align: 'center',
    },
    materialFlowEnabled && {
      title: 'HS Code Description',
      dataIndex: 'hsCodeDesc',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.hsCodeDesc, b.hsCodeDesc),
      width: 125,
      align: 'center',
    },
    {
      title: 'Next Location',
      dataIndex: 'nextLocation',
      render: nde => <span>{nde}</span>,
      sorter: (a, b) => stringSorter(a.nextLocation, b.nextLocation),
      width: 125,
      align: 'center',
    },
    relationshipStrengthEnabled && {
      title: 'Relationship Strength',
      dataIndex: 'relationshipStrength',
      render: nde => <span>{nde?.toFixed(2)}</span>,
      sorter: (a, b) => a.relationshipStrength - b.relationshipStrength,
      width: 50,
      align: 'center',
    },
  ].filter(e => e);

  return (
    <Table
      rowKey={row => row.id}
      columns={columns}
      dataSource={dataSource}
      size="small"
      style={{ width: '100%', zIndex: '0', display: 'flex', flexDirection: 'column' }}
    />
  );
})`
  padding: 0;
  background-color: unset;
  .section-row-header {
    display: none;
  }
`;

export default OutboundFlows;
