import { Col, Layout, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getRegion, getRegions } from 'countries/modules/regions';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Tabs, TabPane } from './styles';

import ActiveCountryThreats from 'countries/containers/CountryDetailView/header/ActiveCountryThreats';
import { AssetTitle } from './styles';

import tabs from './tabs';

import CountryNavButtons from './header/CountryNavButtons';
import CountryRiskStatus from './header/CountryRiskStatus';
import { DetailViewContent } from '@luxe/components/src/Container';

import HeaderRow from 'assets/containers/AssetDetailView/header/HeaderRow';
import ScoreDetails from './tabs/RiskTab/ScoreDetails';
import MiniCountryMap from './header/MiniCountryMap';
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import useMixpanel from 'utils/hooks/Mixpanel';

const Header = () => {
  const { region, regions } = useSelector(store => store.regions);
  const [hasRegionsBeenFetched, setHasRegionsBeenFetched] = useState(false);
  const [regionsList, setRegionsList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (regions.length === 0 && !hasRegionsBeenFetched) {
      dispatch(getRegions());
      setHasRegionsBeenFetched(true);
    } else {
      setRegionsList(
        regions
          .map(country => {
            return { id: country.id, label: country.properties.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
  }, [dispatch, regions, hasRegionsBeenFetched, setRegionsList]);
  return (
    <>
      <HeaderRow breadcrumbs={['RISK OVERVIEW', 'COUNTRY']} />
      <Row className="asset-scorecard-header">
        <Space size={16} align={'start'}>
          <Col>
            <MiniCountryMap />
            <CountryNavButtons allRegions={regionsList} />
          </Col>
          <Col>
            <Row>
              <Col>
                <Space size={8} direction={'vertical'}>
                  <Row>
                    <AssetTitle style={{ color: 'white' }} className="asset-title">
                      {region.name}
                    </AssetTitle>
                  </Row>
                  <Row>
                    <CountryRiskStatus />
                    <ActiveCountryThreats />
                  </Row>
                </Space>
              </Col>
            </Row>
          </Col>
        </Space>
      </Row>
    </>
  );
};

const HeaderMinimalOverview = () => {
  const { region = {} } = useSelector(store => store.regions);
  return (
    <>
      <HeaderRow breadcrumbs={['RISK OVERVIEW', 'COUNTRY']} />
      <Row className="country-scorecard-header">
        <Space size={16} align={'start'}>
          <Col>
            <Row>
              <Col>
                <Space size={8} direction={'vertical'}>
                  <Row>
                    <AssetTitle>{region.name}</AssetTitle>
                  </Row>
                  <Row>
                    <CountryRiskStatus />
                    <ActiveCountryThreats />
                  </Row>
                </Space>
              </Col>
            </Row>
          </Col>
        </Space>
      </Row>
    </>
  );
};

const CountryDetailView = props => {
  const [showMinimal] = useState(false);
  const { region } = useSelector(store => store.regions);
  const dispatch = useDispatch();
  const { id: regionId, tab } = useParams();
  const location = useLocation();
  const { space } = useTheme();
  const mixpanelTrack = useMixpanel();

  const selectedTab = tab || 'overview';
  const assetTabs = [
    {
      key: 'overview',
      title: 'Overview',
      content: <tabs.OverviewTab />,
    },
    {
      key: 'risk',
      title: 'Risk',
      content: <tabs.RiskTab />,
    },
    {
      key: 'incidents',
      title: 'Incidents',
      content: <tabs.IncidentsTab />,
    },
    {
      key: 'assets',
      title: 'Facilities',
      content: <tabs.AssetsTab />,
    },
    {
      key: 'economicLogistics',
      title: 'Economic / Logistic Data',
      content: <tabs.EconomicLogisticTab />,
    },
  ].map(({ key, title, content }) => {
    return (
      <TabPane tab={title} key={key}>
        {content}
      </TabPane>
    );
  });

  useEffect(() => {
    dispatch(getRegion(regionId));
  }, [dispatch, regionId]);

  if (!region) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const onClickTab = key => {
    dispatch(push(`/country-risk/${regionId}/${key}/`));
    mixpanelTrack('Country Risk Detail - Switch Tab', {
      Tab: key,
    });
  };
  return (
    <>
      <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
        <div style={{ marginTop: '0' }}>
          <HeaderContainer>{showMinimal ? <HeaderMinimalOverview /> : <Header />}</HeaderContainer>
        </div>
        {location.pathname.includes('score-details') ? (
          <ScoreDetails />
        ) : (
          <Tabs activeKey={selectedTab} onChange={onClickTab}>
            {assetTabs}
          </Tabs>
        )}
      </DetailViewContent>
    </>
  );
};

export default CountryDetailView;

export const HeaderContainer = styled.div`
  position: sticky;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 900;
  background-color: #212121;
`;
