import 'leaflet/dist/leaflet.css';

import React, { useEffect } from 'react';

import L from 'leaflet';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import * as countries from './countries';

const mapBuilder = region => {
  const map = L.map('mapId', { zoomControl: false, zoomSnap: 0.25, zoomDelta: 1 });
  const category = region?.proactive_scores[0]?.category;
  let color = {};

  if (category) {
    if (category === 'low') {
      color = '#009702';
    } else if (category === 'medium') {
      color = '#FF8700';
    } else if (category === 'high') {
      color = '#DE3700';
    }
  } else {
    color = '#2D8FA5';
  }

  L.tileLayer(
    'https://api.mapbox.com/styles/v1/stormpulse/ck0zgcsyw0yrc1ckypuh2q4be/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3Rvcm1wdWxzZSIsImEiOiJYd1YxUXpNIn0.N_-0IOIF6RUOHyF9JUIPVQ',
  ).addTo(map);

  // Working USA and NZL geoJSON hardcoded for now, as certain anomalies including but not necessarily
  // limited to USA, Russia and New Zealand data, are not fitting bounds like other multiploygons
  const country = countries[region.slug] || region.geometry;

  const addedGeoJson = L.geoJSON(country, {
    style: function(feature) {
      return {
        color: color,
        weight: 0.5,
      };
    },
  });
  addedGeoJson.addTo(map);
  map.fitBounds(addedGeoJson.getBounds());
};

const MiniCountryMap = styled(({ className }) => {
  const { region } = useSelector(store => store.regions);
  useEffect(() => {
    let container = L.DomUtil.get('mapId');
    if (container != null) {
      container._leaflet_id = null;
    }
    if (region) {
      mapBuilder(region);
    }
  }, [region]);
  return <div id="mapId" style={{ height: '180px', width: '188px' }} className={className}></div>;
})`
  .leaflet-control-attribution {
    display: none;
  }
`;

export default MiniCountryMap;
