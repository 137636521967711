import styled from '@emotion/styled';
import React from 'react';
const { CheckboxGroup, Section } = require('@luxe/components');
const { Col, Row } = require('antd');

const IncludeRules = styled(({ className, data, updateIncludeRules, isDiscoverEnabled }) => {
  const options = [
    { label: 'Facilities', value: 'assets' },
    { label: 'Lanes', value: 'lanes' },
    { label: 'Shipments', value: 'shipments' },
  ];

  isDiscoverEnabled && options.push({ label: 'Discovered Facilities', value: 'subTier' });

  const onChange = value => {
    updateIncludeRules(value);
  };

  return (
    <div className={className}>
      <Row>
        <Col span={12} align="start" style={{ color: 'white' }}>
          Include
        </Col>
      </Row>
      <Section rules className="rules-checkboxes">
        <CheckboxGroup options={options} value={data?.includes ?? []} onChange={onChange} />
      </Section>
    </div>
  );
})`
  .rules-checkboxes {
    margin-top: 1em;
  }
`;

export default IncludeRules;
