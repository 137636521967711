export const JOB_FUNCTIONS = [
  {
    label: 'Procurement/Sourcing',
    value: 'procurement/sourcing',
  },
  {
    label: 'Transportation & Logistics',
    value: 'transportation & logistics',
  },
  {
    label: 'Supply Chain Planning',
    value: 'supply chain planning',
  },
  {
    label: 'Business Continuity Management',
    value: 'business continuity management',
  },
];

export const JOB_ROLES = [
  {
    label: 'Analyst',
    value: 'Analyst',
  },
  {
    label: 'Manager',
    value: 'Manager',
  },
  {
    label: 'Director',
    value: 'Director',
  },
];

export const MENU = [
  {
    head: 'General',
    subHead: ['Personal Settings', 'Job Role'],
  },
  {
    head: 'My Network',
    subHead: ['Incidents', 'Commodities', 'Major Incidents'],
  },
  {
    head: 'Notifications',
    subHead: [],
  },
];

export const UOM = [
  {
    label: 'Imperial',
    value: 'imperial',
  },
  {
    label: 'Metric',
    value: 'metric',
  },
];

export const UNIT_OPTIONS = {
  metric: {
    temp: 'C',
    length: 'kilometers',
    default: 'metric',
  },
  imperial: {
    temp: 'F',
    length: 'miles',
    default: 'imperial',
  },
};

export const USER_TYPES = {
  CONTACT: 'contact',
  USER: 'user',
};

export const USER_OPTIONS = [
  {
    label: 'Contact',
    value: USER_TYPES.CONTACT,
  },
  {
    label: 'User',
    value: USER_TYPES.USER,
  },
];

export const DELIVERY = [
  {
    label: 'Real-time',
    value: 'real-time',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

export const FREQUENCY = [
  {
    label: 'Every 8 hours',
    value: '0 */8 * * *',
  },
  {
    label: 'Daily',
    value: '0 8 * * *',
  },
  {
    label: 'Weekly',
    value: '0 8 * * 1',
  },
  {
    label: 'None',
    value: '',
  },
];

export const ASSIGNMENTS = [
  {
    label: 'Auto',
    value: 'Auto',
  },
  {
    label: 'Manual',
    value: 'Manual',
  },
];

export const ASSET_TYPES = [
  {
    label: 'Airport',
    value: 'airport',
  },
  {
    label: 'Border Crossing',
    value: 'border_crossing',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
  {
    label: 'Distribution Center',
    value: 'distribution_center',
  },
  {
    label: 'Distributor',
    value: 'distributor',
  },
  {
    label: 'Headquarter',
    value: 'headquarter',
  },
  {
    label: 'Natural Resources',
    value: 'natural_resources',
  },
  {
    label: 'Office',
    value: 'office',
  },
  {
    label: 'Port',
    value: 'port',
  },
  {
    label: 'Production Plant',
    value: 'production_plant',
  },
  {
    label: 'Rail Terminal',
    value: 'rail_terminal',
  },
  {
    label: 'Supplier',
    value: 'supplier',
  },
  {
    label: 'Warehouse',
    value: 'warehouse',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const SYSTEM_TYPES = {
  assets: 1,
  waypoints: 2,
  discovered: 3,
};

export const NETWORK_LINKS_LIMIT = {
  tiered2D: 250,
  scorecard: 4000,
  default: 3000,
};

export const GRAPH_MODES = {
  TIERED_2D: 'tiered2d',
  CLUSTERED_2D: 'clustered2d',
  CLUSTERED_3D: 'clustered3d',
  DEFAULT: 'clustered2d',
};

export const CHANNEL_ACTIONS = [
  {
    name: 'Network',
    value: 'network_summary',
    key: 'NETWORK_SUMMARY',
  },
  {
    name: 'Sub-Tier Network',
    value: 'sub_tier_network_summary',
    key: 'SUB_TIER_NETWORK_SUMMARY',
  },
  {
    name: 'Major',
    value: 'major_event',
    key: 'MAJOR_EVENT',
  },
  {
    name: 'Category',
    value: 'global_events',
    key: 'GLOBAL_EVENTS',
  },
  {
    name: 'Industry',
    value: 'industry_commodity',
    key: 'INDUSTRY_COMMODITY',
  },
  {
    name: 'Commodity Risk',
    value: 'commodity',
    key: 'COMMODITY',
  },
];

export const TRANSPORTATION_MODES = [
  {
    label: 'Air',
    value: 'air',
  },
  {
    label: 'Ocean',
    value: 'ocean',
  },
  {
    label: 'Rail',
    value: 'rail',
  },
  {
    label: 'Truck',
    value: 'truck',
  },
];

export const MAJOR_EVENT_PREFERENCE = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const UNITS_ENUM = {
  degC: 'degC',
  degF: 'degF',
  speedKMPH: 'speedKMPH',
  speedMPH: 'speedMPH',
  lengthMM: 'lengthMM',
  lengthCM: 'lengthCM',
  lengthInch: 'lengthInch',
  default: 'default',
};

export const UNITS_REPRESENTATION = {
  [UNITS_ENUM.degC]: '\u00B0C',
  [UNITS_ENUM.degF]: '\u00B0F',
  [UNITS_ENUM.speedKMPH]: ' km/h',
  [UNITS_ENUM.speedMPH]: ' mph',
  [UNITS_ENUM.lengthMM]: 'mm',
  [UNITS_ENUM.lengthCM]: 'cm',
  [UNITS_ENUM.lengthInch]: '"',
  [UNITS_ENUM.default]: '',
};
