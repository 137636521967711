import React from 'react';
import { Form } from 'antd';

import { Label, BasicModal as Modal, Select, FormItem, Checkbox } from '@luxe/components';
import { StyledForm } from '@luxe/components/src/Form';
import { USER_OPTIONS, UOM, USER_TYPES } from 'common/Constants';

export const MODAL_TYPES_KEYS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Please enter a ${name}',
  types: {
    email: 'Please enter a valid email',
  },
};

const rules = {
  name: [
    {
      required: true,
    },
  ],
  email: [
    {
      required: true,
      type: 'email',
      message: 'Please enter an email',
    },
  ],
  userType: [
    {
      required: true,
      message: 'Please select a user type',
    },
  ],
  units: [
    {
      required: true,
      message: 'Please select a unit type',
    },
  ],
};

const AddModal = ({ modalSettings, isModalShowing, onCancel }) => {
  const [form] = Form.useForm();
  const userType = Form.useWatch('type', form);

  return (
    <Modal
      forceRender
      title={modalSettings.title}
      visible={isModalShowing}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      style={{ color: 'white' }}
    >
      <StyledForm
        form={form}
        name="user"
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={values => {
          form.resetFields();
          modalSettings.onOk(values);
        }}
      >
        <FormItem name="type" label="Type" rules={rules.userType}>
          <Select placeholder={'Please select a user type'} options={USER_OPTIONS} width={'100%'} />
        </FormItem>
        <FormItem name="name" label="Name" rules={rules.name}>
          <Label width={'100%'} autoComplete="off" />
        </FormItem>
        <FormItem name="email" label="Email" rules={rules.email}>
          <Label width={'100%'} autoComplete="off" />
        </FormItem>
        <FormItem name="phone" label="Phone">
          <Label width={'100%'} autoComplete="off" />
        </FormItem>
        <FormItem name="units" label="Units" rules={rules.units}>
          <Select className="Select-control" placeholder={'Please select a unit type'} options={UOM} width={'100%'} />
        </FormItem>
        {(userType === undefined || userType?.value === USER_TYPES.USER) && (
          <>
            <FormItem name="admin" valuePropName="checked">
              <Checkbox>Admin</Checkbox>
            </FormItem>
            <div style={{ color: '#fff', fontSize: '16px', textAlign: 'center' }}>
              An email will be sent to the new user to set up their password.
            </div>
          </>
        )}
      </StyledForm>
    </Modal>
  );
};

const UserModal = ({ user, modalType, isModalShowing, onAdd, onEdit, onDelete, onCancel }) => {
  const MODAL_TYPES = {
    [MODAL_TYPES_KEYS.ADD]: {
      title: 'New User',
      onOk: user => {
        onAdd(user);
      },
      bodyWidget: AddModal,
    },
  };

  const modalSettings = MODAL_TYPES[modalType];

  return <>{modalSettings.bodyWidget({ user, modalSettings, isModalShowing, onCancel })}</>;
};

export default UserModal;
