import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Modal } from 'antd';

const BasicModal = styled(Modal)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  .ant-row {
    justify-content: space-between;
  }
  .ant-modal-content {
    color: white !important;
    background-color: ${props => props.theme.colors.background};
    border-bottom: none;
  }
  .ant-modal-header{
    background-color: ${props => props.theme.colors.background};
    border-bottom: none;
    .ant-modal-title {
      color: white;
      width: 95%;
    }
  }

  .ant-modal-close-icon svg {
    fill:  white !important;
  }

  .ant-modal-body .modal-content {
    margin-top: 20px;
  }

  .ant-checkbox-wrapper {
    color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2d8fa5;
    border-color: #1890ff;
  }

  .ant-modal-footer {
    text-align: center;
    border: none ;
    button.ant-btn:nth-of-type(1){
      background-color: ${props => props.theme.colors.secondary};
      color: white;
      &:hover{
        border: ${props => `1px solid ${props.theme.colors.primary}`} ;
      }
    }
    button.ant-btn:nth-of-type(2){
      border: ${props => `1px solid ${props.theme.colors.primary}`} ;
      background-color: ${props => props.theme.colors.primary};
      color: white;
      &:hover {
        border: ${props => `1px solid ${props.theme.colors.primary}`} ;
      }
    }
  }
`;

const ModalText = styled.div`
  margin: 5px;
  color: ${props => props.theme.colors.track}
`;

export { BasicModal, ModalText };
