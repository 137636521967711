import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { historyChartOptions } from './HistoryChartOptions';

window.Highcharts = Highcharts;
HighchartsMore(Highcharts);

export function HistoryChart({ history = [] }) {
  const opts = historyChartOptions(history);
  return <HighchartsReact highcharts={Highcharts} options={opts} />;
}
