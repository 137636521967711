import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import { BasicModal as Modal, Button } from '@luxe/components';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import {
  createRiskProfileRule,
  deleteRiskProfileRule,
  editRiskProfileRule,
  updateRiskProfileRule,
  getDesiredRuleUnits,
  trackRiskProfileChange,
} from 'admin/riskprofiles/modules/riskprofiles';
import { RuleModal } from '../RuleSection/RuleModal';

import RulesTable from './RulesTable';

const RuleSection = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const RiskProfile = useSelector(store => store.riskProfiles.RiskProfile);
  const RiskProfileRule = useSelector(store => store.riskProfiles.RiskProfileRule);
  const userUOM = useSelector(store => store.account.account?.prefs.units.default);
  const urlParams = useParams();

  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [selectedRules, setSelectedRules] = useState([]);

  const newRule = () => {
    dispatch(editRiskProfileRule({ mode: 'EDITING', profile_id: RiskProfile.id, reset: true }));
  };

  const deleteRule = () => {
    setIsDeleteModalShowing(true);
  };

  const onRuleChecked = checkedRules => {
    const selectedRules = [];
    RiskProfile.rules.forEach(rule => {
      if (checkedRules.includes(rule.id)) {
        selectedRules.push({ id: rule.id, name: rule.name });
      }
    });
    setSelectedRules(selectedRules);
  };

  const createRule = (profileID, ruleData) => {
    dispatch(trackRiskProfileChange(true));
    dispatch(createRiskProfileRule(profileID, ruleData));
  };

  const editRule = ruleData => {
    const units = getDesiredRuleUnits(ruleData, userUOM);
    if (units) {
      ruleData.desiredUnits = units;
    }
    dispatch(trackRiskProfileChange(true));
    dispatch(editRiskProfileRule(ruleData));
  };

  const updateRule = (id, data) => {
    dispatch(updateRiskProfileRule(id, data));
  };

  const editing = RiskProfileRule ? RiskProfileRule.mode === 'EDITING' : false;

  return (
    <>
      <Row>
        <Col span={8} align="start">
          <h3>Scoring Rules</h3>
        </Col>
        <Col span={12} align="end">
          {selectedRules.length > 0 && (
            <>
              <span>{selectedRules.length} Rules Selected</span>
              <Button
                disabled={isReadOnly}
                onClick={deleteRule}
                justify="right"
                variant="danger"
                danger={true}
                icon={<DeleteFilled />}
              >
                Delete Rule
              </Button>
            </>
          )}
        </Col>
        <Col span={4} align="end" style={{ marginBottom: '10px' }}>
          <Button disabled={isReadOnly} onClick={newRule} variant="primary" icon={<PlusCircleOutlined />}>
            New Rule
          </Button>
        </Col>
      </Row>
      <RulesTable
        RiskProfile={RiskProfile}
        RiskProfileRule={RiskProfileRule}
        onRuleChecked={onRuleChecked}
        readOnly={isReadOnly}
        setSelectedRule={editRule}
      />

      {editing && (
        <RuleModal
          profile={RiskProfile}
          rule={RiskProfileRule}
          editRule={editRule}
          createRiskProfileRule={createRule}
          updateRiskProfileRule={updateRule}
        />
      )}
      {isDeleteModalShowing && (
        <Modal
          title="Delete a Risk Profile Rule"
          visible={isDeleteModalShowing}
          onCancel={() => {
            setIsDeleteModalShowing(false);
          }}
          onOk={() => {
            setIsDeleteModalShowing(false);
            setSelectedRules([]);
            selectedRules.forEach(rule => dispatch(deleteRiskProfileRule(urlParams.id, rule.id)));
          }}
        >
          Confirm deleting the following Risk Profile Rules from this Risk Profile:
          <ul>
            {selectedRules.map((rule, index) => {
              return <li key={index}>{rule.name}</li>;
            })}
          </ul>
          <p>Are You Sure You Want To Do This?</p>
        </Modal>
      )}
    </>
  );
};

export default RuleSection;
