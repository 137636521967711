import AutocompleteBaseSelector from './BaseSelector';

//TODO: attach to endpoint
export default class GroupSelector extends AutocompleteBaseSelector {
  get options() {
    return this.props.groups.items.map(x => {
      const value = x.name === 'My Network' ? 'my_network' : x.id;
      return {
        value,
        label: x.name,
      };
    });
  }
}
