import React from 'react';
import { stringSorter } from 'utils';
import { Table, Badge as Tag } from '@luxe/components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const scoreSorter = (key, selector = 'value') => (a, b) =>
  (_.find(a.data?.values ?? [], { [selector]: key })?.risk || Infinity) -
  (_.find(b.data?.values ?? [], { [selector]: key })?.risk || Infinity);
const getColumns = (profileId, defaultUnit) => [
  {
    title: 'Name',
    dataIndex: '',
    key: 'name',
    render: rule => rule.name,
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: 'Risk Sub-category/Type',
    dataIndex: '',
    key: 'riskType',
    render: rule => rule.phenomena.family || 'NA',
    sorter: (a, b) => stringSorter(a.phenomena.family, b.phenomena.family),
  },
  {
    title: 'Origination',
    dataIndex: '',
    key: 'organization',
    render: (_, rule) => {
      if (profileId !== rule.profile_id) {
        return <Tag>{'From Profile'}</Tag>;
      }
    },
    sorter: (a, b) => stringSorter(a.profileId, b.profile_id),
  },
  {
    title: `Within Distance (${defaultUnit === 'imperial' ? 'mi' : 'km'})`,
    dataIndex: ['data', 'options', 'meters'],
    key: 'distance',
    align: 'center',
    render: distance => {
      const d = defaultUnit === 'imperial' ? distance * 0.00062137119 : distance / 1000;
      return d ? d.toFixed(1) : '';
    },
    sorter: (a, b) => (a.data?.options?.meters ?? Infinity) - (b.data?.options?.meters ?? Infinity),
  },
  {
    title: 'Minor',
    dataIndex: ['data', 'values'],
    key: 'minor',
    align: 'center',
    render: values => {
      return _.find(values, { value: 'Minor' })?.risk ?? '';
    },
    sorter: scoreSorter('Minor'),
  },
  {
    title: 'Moderate',
    dataIndex: ['data', 'values'],
    key: 'moderate',
    align: 'center',
    render: values => {
      return _.find(values, { value: 'Moderate' })?.risk ?? '';
    },
    sorter: scoreSorter('Moderate'),
  },
  {
    title: 'Severe',
    dataIndex: ['data', 'values'],
    key: 'severe',
    align: 'center',
    render: values => {
      return _.find(values, { value: 'Severe' })?.risk ?? '';
    },
    sorter: scoreSorter('Severe'),
  },
  {
    title: 'Extreme',
    dataIndex: ['data', 'values'],
    key: 'extreme',
    align: 'center',
    render: values => {
      return _.find(values, { value: 'Extreme' })?.risk ?? '';
    },
    sorter: scoreSorter('Extreme'),
  },
  {
    title: 'Watch',
    dataIndex: ['data', 'values'],
    key: 'watch',
    align: 'center',
    render: values => {
      return _.find(values, { name: 'Watch' })?.risk ?? '';
    },
    sorter: scoreSorter('Watch', 'name'),
  },
  {
    title: 'Warning',
    dataIndex: ['data', 'values'],
    key: 'warning',
    align: 'center',
    render: values => {
      return _.find(values, { name: 'Warning' })?.risk ?? '';
    },
    sorter: scoreSorter('Warning', 'name'),
  },
  {
    title: 'Best',
    dataIndex: ['data', 'values'],
    key: 'best',
    align: 'center',
    render: values => {
      return _.find(values, { name: 'Best' })?.risk ?? '';
    },
    sorter: scoreSorter('Best', 'name'),
  },
  {
    title: 'Worst',
    dataIndex: ['data', 'values'],
    key: 'worst',
    align: 'center',
    render: values => {
      return _.find(values, { name: 'Worst' })?.risk ?? '';
    },
    sorter: scoreSorter('Worst', 'name'),
  },
];

const RulesTable = ({ onRuleChecked, RiskProfileRule, RiskProfile, readOnly, setSelectedRule }) => {
  const defaultUnit = useSelector(store => store.account.account?.prefs.units.default);

  const rowInteraction = rule => {
    return {
      onClick: () => {
        setSelectedRule({ ...rule, ...{ mode: 'EDITING' } });
      },
    };
  };
  const rowSelection = {
    onChange: whichRule => {
      onRuleChecked(whichRule);
    },
    getCheckboxProps: record => ({
      disabled: RiskProfile.id !== record.profile_id,
    }),
  };
  if (!RiskProfile) {
    return null;
  }
  const columns = getColumns(RiskProfile.id, defaultUnit);
  const rules = RiskProfile.rules;

  return (
    <div>
      <Table
        onRow={rowInteraction}
        rowSelection={!readOnly ? rowSelection : false}
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={[...rules]}
      />
    </div>
  );
};

export default RulesTable;
