import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Section } from '@luxe/components';

function RiskDetails({ attributes }) {
  const groupedAttributes = useMemo(() => {
    return attributes.reduce((accum, attr) => {
      const category = attr.schema.category || 'Other';
      if (!accum[category]) {
        accum[category] = [];
      }

      accum[category].push(attr);

      return accum;
    }, {});
  }, [attributes]);

  if (!attributes?.length) {
    return null;
  }

  const DetailSectionContent = ({ attrs }) => {
    return (
      <DetailSection>
        {attrs
          .sort((a, b) => (a.display_name < b.display_name ? -1 : 1))
          .map(attr => {
            let content = attr.value;
            if (typeof content === 'string' && content.includes('https')) {
              content = (
                <a href={content} target={'_blank'} rel="noopener noreferrer">
                  Report Link
                </a>
              );
            }
            return <DetailItem key={attr.name} title={attr.schema.display_name} content={content} />;
          })}
      </DetailSection>
    );
  };

  return (
    <Section title={'Risk Details'}>
      <RiskDetailContainer>
        {Object.entries(groupedAttributes).map(([group, attrs], i) => {
          return (
            <React.Fragment key={`riskdetails-${i}`}>
              <DetailSectionHeader>{group}</DetailSectionHeader>
              <DetailSectionContent attrs={attrs} />
            </React.Fragment>
          );
        })}
      </RiskDetailContainer>
    </Section>
  );
}

export default RiskDetails;

function DetailItem({ title, content }) {
  return (
    <DetailItemContainer>
      <DetailItemHeader>{title}</DetailItemHeader>
      <DetailItemContent>{content || '-'}</DetailItemContent>
    </DetailItemContainer>
  );
}

const RiskDetailContainer = styled.div`
  margin: 0 10px;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
`;

const DetailSection = styled.div`
  width: 100%;
  margin: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const DetailSectionHeader = styled.div`
  font-size: 20px;
  margin-top: 10px;
`;

const DetailItemHeader = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
`;

const DetailItemContent = styled.div`
  margin: 0 10px;
`;

const DetailItemContainer = styled.div`
  width: 33%;
  margin: 10px 0;
`;
