import React from 'react';
import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';
import { Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import FilterForm from './FilterForm';
import { updateFilter } from 'views/modules/views';
import moment from 'moment';

const convertToLocal = date =>
  moment(date)
    .local()
    .format();

const toLabel = (value, operator, name) => {
  if (name === 'last_update') {
    if (operator === 'between') {
      return value
        .map(x => {
          return convertToLocal(x).split('T')[0];
        })
        .join(' and ');
    } else {
      return convertToLocal(value).split('T')[0];
    }
  }
  if (Array.isArray(value)) {
    return value
      .map(x => {
        return x?.label ? x.label : x;
      })
      .join(', ');
  } else {
    return value?.label ? value.label : value;
  }
};

const Filter = ({ descr, name, operator, value, field, ...props }) => {
  const label = toLabel(value, operator, name);
  const displayName = descr || name;
  const onEdit = () => {
    props.onEdit({ name, operator, value });
  };
  const onDelete = () => {
    props.onDelete({ name, operator, value });
  };
  return (
    <div>
      <Tag onClick={onEdit} closable onClose={onDelete} className="filter-tag">
        {displayName}
        <span className="filter-operator">{operator}</span>
        <span className="filter-labels" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {label}
        </span>
      </Tag>
    </div>
  );
};

const ViewFilters = styled(({ data, onFiltersChanged, ...props }) => {
  const dispatch = useDispatch();
  const { datasource } = data;
  if (datasource === undefined) {
    return null;
  }

  const { filter = {} } = data;
  const { visible = false } = filter;

  const hideFilter = filters => {
    return filters.filter(currentFilter => {
      return !props.excludes.includes(currentFilter.name);
    });
  };

  const onEdit = ({ ...filter }) => {
    dispatch(updateFilter(datasource, { ...filter, visible: true }));
  };

  const onAdd = () => {
    const filter = { name: '', operator: '', value: [], description: '' };
    dispatch(updateFilter(datasource, { ...filter, visible: true }));
  };

  const onSave = ({ i, ...filter }) => {
    let filtersData = data.meta.current.filters || [];
    if (filtersData.find(item => item.name === filter.name)) {
      onFiltersChanged(
        filtersData.map(item => {
          return item.name === filter.name ? filter : item;
        }),
      );
    } else {
      onFiltersChanged([...filtersData, filter]);
    }
    if (props.excludes !== undefined && filtersData.length > 0) {
      filtersData = hideFilter(filtersData);
    }
    dispatch(updateFilter(datasource, { visible: false }));
  };

  const onCancel = () => {
    dispatch(updateFilter(datasource, { visible: false }));
  };

  const onDelete = ({ ...filter }) => {
    let newFilters = data.meta.current.filters.filter(({ name }) => name !== filter.name);
    onFiltersChanged(newFilters);
  };

  const Filters = () => {
    const filteredFilters =
      props.excludes !== undefined && data?.meta?.current?.filters !== undefined
        ? hideFilter(data?.meta?.current?.filters)
        : data?.meta?.current?.filters;

    return filteredFilters !== undefined
      ? filteredFilters.map((filter, i) => {
          const { description = undefined } = data.meta.allowed.filters.find(({ name }) => name === filter.name);
          return (
            <Filter
              key={filter.name}
              descr={description}
              onEdit={onEdit}
              onDelete={onDelete}
              includes={props.includes}
              {...filter}
            />
          );
        })
      : null;
  };

  return (
    <div className={props.className} style={{ flexWrap: 'wrap' }}>
      <Filters />
      {visible && (
        <FilterForm
          datasource={datasource}
          filter={filter}
          includes={props.includes}
          meta={data.meta}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}

      <Tag onClick={onAdd} className={`filter-tag filter-add`}>
        <span className="badge">
          <PlusOutlined /> Add
        </span>
      </Tag>
    </div>
  );
})`
  & {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .filter-tag,
  .site-tag-plus {
    display: flex;
    background: black;
    border-radius: 30px;
    border: 1px solid #246472;
    line-height: 22px;
    padding: 0px 10px;
    cursor: pointer;
    margin-bottom: 10px;
    &.filter-add {
      width: 60px;
    }

    .badge > .anticon.anticon-plus {
      margin-left: -3px;
    }
    .anticon.anticon-close {
      margin-right: -3px;
      margin-top: 6px;
    }
  }

  .filter-operator {
    color: #ccc;
    padding: 0 5px;
  }
`;

export default ViewFilters;
