import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Col, Layout, Row, Space, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import ProtectedRoute from 'utils/ProtectedRoute';
import styled from '@emotion/styled';

import HeaderRow from './header/HeaderRow';
import MiniMap from './header/MiniMap';
import RiskStatus from './header/RiskStatus';
import NavButtons from './header/NavButtons';
import MassEditForm from './MassEditView';
import ScoreDetailsView from 'assets/containers/LocationRiskDetailsView/ScoreDetailsView';

import { DetailViewContent } from '@luxe/components/src/Container';
import { AssetTitle, AssetType, DescriptionText, DescriptionTitle, HQLocation, AltIDTitle, AltIDText } from './styles';
import { ActiveThreats } from './header/ActiveThreats';
import { AssetTabView } from './header/AssetTabView';
import { getAsset } from 'admin/assets/modules/assets';
import AssetThreatSection from 'assets/containers/AssetDetailView/AssetThreatSection/AssetThreatSection';
import AssetDiscoverSection from 'assets/containers/AssetDetailView/AssetDiscoverSection/AssetDiscoverSection';
import AssetMaterialSection from 'assets/containers/AssetDetailView/AssetMaterialSection/AssetMaterialSection';
import AssetRiskSection from 'assets/containers/AssetDetailView/AssetRiskSection/AssetRiskSection';
import { getProactiveRisk, getRiskModels, setActiveRiskModel } from 'admin/riskmodels/modules/riskmodels';

const AssetDetailView = () => {
  const history = useHistory();
  const { space } = useTheme();
  const { id: assetId, tabTitle } = useParams();
  const dispatch = useDispatch();

  const proactiveRisk = useSelector(store => store.riskModels.proactiveRisk);
  const asset = useSelector(store => store.assets.Asset);
  const proactiveScoring = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.proactive?.scoring;
  const materialRisk = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.materials?.enabled;
  const discoverEnabled = useSelector(store => store.accountOrg)?.accountOrg?.prefs?.discover?.enabled;
  const activeRiskModelId = useSelector(store => store.riskModels.activeRiskModel.id);

  const incidentRef = useRef(null);
  const discoverRef = useRef(null);
  const materialRef = useRef(null);
  const riskRef = useRef(null);
  const firstLoad = useRef(true);
  const prevScrollY = useRef(0);
  const [showMinimal, setShowMinimal] = useState(false);
  const [currentTab, setCurrentTab] = useState('Incidents');

  let { path } = useRouteMatch();
  if (path.slice(-1) !== '/') path = path.concat('/');

  const isEkg = useMemo(() => {
    return asset?.type === 3;
  }, [asset]);

  useEffect(() => {
    if (!tabTitle) {
      history.push(`/assets/${assetId}/incidents`);
    }
  }, [history, assetId, tabTitle]);

  useEffect(() => {
    document.title = 'Everstream Analytics';

    dispatch(getRiskModels());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (prevScrollY.current > window.scrollY && prevScrollY.current < 100) {
        setShowMinimal(false);
      } else if (prevScrollY.current > 100) {
        setShowMinimal(true);
      }
      prevScrollY.current = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(getAsset({ id: assetId }));
  }, [dispatch, assetId]);

  useEffect(() => {
    if (assetId && activeRiskModelId) {
      dispatch(getProactiveRisk({ assetId: assetId, riskModelId: activeRiskModelId }));
    }
  }, [assetId, activeRiskModelId, dispatch]);

  useEffect(() => {
    const assetLoaded = assetId === asset?.id;
    if (assetLoaded && !asset?.proactive_scores?.length) {
      firstLoad.current = false;
    } else if (assetLoaded && asset?.proactive_scores?.length && firstLoad.current) {
      dispatch(setActiveRiskModel(asset.proactive_scores[0]?.model_id, 'assets'));
      firstLoad.current = false;
    }
  }, [asset, assetId, dispatch]);

  const address = asset?.location?.data?.search_text === '' ? '' : 'ADDRESS: ' + asset?.location?.name + ' |';
  const assetTabs = [
    {
      title: 'Incidents',
      ref: incidentRef,
      content: <AssetThreatSection />,
      name: 'incidents',
    },
  ];

  if (materialRisk && !isEkg) {
    assetTabs.push({
      title: 'Material Flow',
      ref: materialRef,
      content: <AssetMaterialSection />,
      name: 'material_flow',
    });
  }

  if (proactiveScoring) {
    assetTabs.push({
      title: 'Risk',
      ref: riskRef,
      content: <AssetRiskSection isEkg={isEkg} />,
      name: 'risk',
    });
  }

  if (discoverEnabled || materialRisk) {
    assetTabs.push({
      title: 'Network',
      ref: discoverRef,
      content: <AssetDiscoverSection />,
      name: 'network',
    });
  }

  const handleCurrentTabChange = tab => {
    let tabData = assetTabs.find(val => val.title === tab);
    history.replace(`/assets/${assetId}/${tabData.name}`);
    setCurrentTab(tab);
  };

  const renderTabContent = () => {
    let tabData = assetTabs.find(val => val.title === currentTab);
    return tabData?.content;
  };

  return asset ? (
    <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <div style={{ marginTop: '0' }}>
        <Switch>
          <ProtectedRoute exact path={path}>
            <HeaderContainer>
              {showMinimal ? (
                <HeaderMinimalOverview asset={asset} proactiveRisk={proactiveRisk} isEkg={isEkg} />
              ) : (
                <HeaderOverview asset={asset} address={address} proactiveRisk={proactiveRisk} isEkg={isEkg} />
              )}
              <AssetTabView
                assetTabs={assetTabs}
                currentTab={currentTab}
                showMinimal={showMinimal}
                handleCurrentTabChange={handleCurrentTabChange}
              />
            </HeaderContainer>
            {renderTabContent()}
          </ProtectedRoute>
          <ProtectedRoute path={`${path}model/:riskModelId/score-details/:compId/`}>
            <HeaderOverview asset={asset} address={address} proactiveRisk={proactiveRisk} isEkg={isEkg} />
            <ScoreDetailsView />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}model/:riskModelId/index/:riskIndex/score-edit/`}>
            <HeaderOverview asset={asset} address={address} proactiveRisk={proactiveRisk} isEkg={isEkg} />
            <MassEditForm />
          </ProtectedRoute>
        </Switch>
      </div>
    </DetailViewContent>
  ) : (
    <Layout>
      <Spin size={'large'} />
    </Layout>
  );
};

const HeaderOverview = ({ asset, address, proactiveRisk, isEkg }) => {
  const assetExternalIDs = asset?.external_ids || [];
  return (
    <>
      <HeaderRow breadcrumbs={['RISK OVERVIEW', 'FACILITIES']} />
      <Row className="asset-scorecard-header">
        <Space size={16} align={'start'}>
          <Col>
            <MiniMap asset={asset} category={asset.risk?.category || 'low'} />
            {!isEkg && <NavButtons detailUrl={asset?.detail_url} />}
          </Col>
          <Col>
            <Row>
              <Col>
                <Space size={8} direction={'vertical'}>
                  <Row>
                    <AssetType category={asset.risk?.category || 'low'} />
                  </Row>
                  <Row>
                    <AssetTitle>{asset.name}</AssetTitle>
                  </Row>
                  <Row>
                    <HQLocation>
                      {address} Lat: {asset.location.lat}, Long: {asset.location.lon}
                    </HQLocation>
                  </Row>
                  <Row>
                    <RiskStatus risk={proactiveRisk} asset={asset} />
                    <ActiveThreats risk={proactiveRisk} asset={asset} isEkg={isEkg} />
                  </Row>
                  <Row>
                    <Space direction={'vertical'} size={0}>
                      <DescriptionTitle>Description:</DescriptionTitle>
                      <DescriptionText>{asset.description}</DescriptionText>
                    </Space>
                  </Row>
                  <Row>
                    <Space direction={'vertical'} size={0}>
                      <AltIDTitle>Alternative IDs:</AltIDTitle>
                      <AltIDText>
                        {assetExternalIDs.map(idPair =>
                          Object.entries(idPair).map(([key, value]) => {
                            return (
                              <div className="kvp" key={value}>
                                {key} : {value}
                              </div>
                            );
                          }),
                        )}
                      </AltIDText>
                    </Space>
                  </Row>
                </Space>
              </Col>
            </Row>
          </Col>
        </Space>
      </Row>
    </>
  );
};

const HeaderMinimalOverview = ({ asset, proactiveRisk, isEkg }) => {
  return (
    <>
      <HeaderRow breadcrumbs={['RISK OVERVIEW', 'FACILITIES']} />
      <Row className="asset-scorecard-header">
        <Space size={16} align={'start'}>
          <Col>
            <Row>
              <Col>
                <Space size={8} direction={'vertical'}>
                  <Row>
                    <AssetTitle>{asset.name}</AssetTitle>
                  </Row>
                  <Row>
                    <RiskStatus risk={proactiveRisk} asset={asset} />
                    <ActiveThreats risk={proactiveRisk} asset={asset} isEkg={isEkg} />
                  </Row>
                </Space>
              </Col>
            </Row>
          </Col>
        </Space>
      </Row>
    </>
  );
};

export default AssetDetailView;

export const HeaderContainer = styled.div`
  position: sticky;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 900;
  background-color: #212121;
`;
