import React, { useMemo } from 'react';
import BaseSelector from 'common/components/BaseSelector';

const WebhookSelector = ({ name = 'webhooks', valueKey = 'id', ...props }) => {
  const config = useMemo(
    () => ({
      name,
      url: 'account/webhooks/',
      valueKey,
      labelKey: 'callback_url',
    }),
    [name, valueKey],
  );

  return <BaseSelector {...{ name, config, ...props }} />;
};

export default WebhookSelector;
