import React from 'react';

import { Skeleton as AntdSkeleton } from 'antd';
import styled from '@emotion/styled';

const Skeleton = ({ ...props }) => {
  return <AntdSkeleton { ...props } />;
};

styled(AntdSkeleton)`

`;

export { Skeleton };
