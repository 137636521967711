import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Layout, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { DetailViewContent } from '@luxe/components/src/Container';
import HeaderRow from './HeaderRow';
import {
  addProductsToGroup,
  getProductGroups,
  removeProductsFromGroup,
} from 'admin/productgroups/modules/productgroups';
import { getProduct, deleteProduct, updateProduct } from 'admin/products/modules/products';
import { ItemDetailGroupsSection } from '@luxe/components/src/ItemDetail';
import MaterialSettingsSection from 'admin/materials/containers/MaterialDetailView/MaterialSettingsSection';
import { TabView } from '@luxe/components/src/Tabs';

const ProductDetailView = () => {
  const { space } = useTheme();
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const product = useSelector(store => store.products)?.Product;
  const productGroups = useSelector(store => store.productGroups)?.productGroups || [];

  const settingsRef = useRef(null);
  const groupsRef = useRef(null);

  useEffect(() => {
    dispatch(getProduct(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(getProductGroups());
  }, [dispatch]);

  useEffect(() => {
    if (product) {
      const initialValues = {
        name: product.name,
        description: product.description,
        totalSpend: product.total_spend,
        totalQuantity: product.total_quantity,
        totalQuantityUnit: product.total_quantity_unit,
      };
      form.setFieldsValue(initialValues);
    }
  }, [product, form]);

  if (!product) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  const tabs = [
    {
      title: 'Settings',
      ref: settingsRef,
      content: <MaterialSettingsSection />,
    },
    {
      title: 'Groups',
      ref: groupsRef,
      content: (
        <ItemDetailGroupsSection
          item={product}
          itemName={'product'}
          groups={productGroups}
          deleteFromGroupFunction={removeProductsFromGroup}
          addToGroupFunction={addProductsToGroup}
        />
      ),
    },
  ];

  return (
    <DetailViewContent style={{ margin: `${space.none} ${space.xsmall}px` }}>
      <div style={{ marginTop: '0' }}>
        <Form form={form} name="product_detail_form" preserve={false}>
          <HeaderRow
            form={form}
            product={product}
            deleteProductFunction={deleteProduct}
            editProductFunction={updateProduct}
          />
          <TabView tabs={tabs} />
        </Form>
      </div>
    </DetailViewContent>
  );
};

export default ProductDetailView;
