import styled from '@emotion/styled';
import { space, layout, flexbox, size } from 'styled-system';
import { Menu as aMenu } from 'antd';

const DropdownMenu = styled(aMenu)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
  font-family: ${props => props.theme.fonts.body};
  background-color: ${props => props.theme.colors.black1};
  border-bottom: none;
  .ant-dropdown-menu-item {
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.small}px;
  }
  .ant-dropdown-menu-item-active {
    background-color: ${props => props.theme.colors.primaryHover};
    color: ${props => props.theme.colors.white};
  }
`;

const DropdownMenuItem = styled(aMenu.Item)`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
`;

export { DropdownMenu, DropdownMenuItem };
