import axios from 'axiosInstance';
import { callApi } from 'utils';
import { REQUESTED_DATA_STATUS } from 'admin/constants';

import { TYPES as VIEW_TYPES } from 'views/modules/views';
import moment from 'moment';

export const TYPES = {
  GET_EVENTS_META_PENDING: 'RISKFEED/GET_EVENTS_META_PENDING',
  GET_EVENTS_META_SUCCESS: 'RISKFEED/GET_EVENTS_META_SUCCESS',
  GET_EVENTS_META_ERROR: 'RISKFEED/GET_EVENTS_META_ERROR',
  GET_THREAT_PENDING: 'RISKFEED/GET_THREAT_PENDING',
  GET_THREAT_SUCCESS: 'RISKFEED/GET_THREAT_SUCCESS',
  GET_THREAT_ERROR: 'RISKFEED/GET_THREAT_ERROR',
  FETCH_EVENTS_PENDING: 'RISKFEED/FETCH_EVENTS_PENDING',
  FETCH_EVENTS_SUCCESS: 'RISKFEED/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_ERROR: 'RISKFEED/FETCH_EVENTS_ERROR',
  UPDATE_FILTERS: 'RISKFEED/UPDATE_FILTERS',
  GET_MATERIALS_SUMMARY_PENDING: 'RISKFEED/GET_MATERIALS_SUMMARY_PENDING',
  GET_MATERIALS_SUMMARY_SUCCESS: 'RISKFEED/GET_MATERIALS_SUMMARY_SUCCESS',
  GET_MATERIALS_SUMMARY_ERROR: 'RISKFEED/GET_MATERIALS_SUMMARY_ERROR',
  IGNORE_THREAT_PENDING: 'IGNORE_THREAT_PENDING',
  IGNORE_THREAT_SUCCESS: 'IGNORE_THREAT_SUCCESS',
  IGNORE_THREAT_ERROR: 'IGNORE_THREAT_ERROR',
  TRACK_THREAT_PENDING: 'TRACK_THREAT_PENDING',
  TRACK_THREAT_SUCCESS: 'TRACK_THREAT_SUCCESS',
  TRACK_THREAT_ERROR: 'TRACK_THREAT_ERROR',
  UN_TRACK_THREAT_PENDING: 'UN_TRACK_THREAT_PENDING',
  UN_TRACK_THREAT_SUCCESS: 'UN_TRACK_THREAT_SUCCESS',
  UN_TRACK_THREAT_ERROR: 'UN_TRACK_THREAT_ERROR',
  EXPORT_MATERIALS_PENDING: 'EXPORT_MATERIALS_PENDING',
  EXPORT_MATERIALS_SUCCESS: 'EXPORT_MATERIALS_SUCCESS',
  EXPORT_MATERIALS_ERROR: 'EXPORT_MATERIALS_ERROR',
  RESET_EXPORT_MATERIALS_DATA: 'RESET_EXPORT_MATERIALS_DATA',
  EXPORT_ASSETS_PENDING: 'EXPORT_ASSETS_PENDING',
  EXPORT_ASSETS_SUCCESS: 'EXPORT_ASSETS_SUCCESS',
  EXPORT_ASSETS_ERROR: 'EXPORT_ASSETS_ERROR',
  RESET_EXPORT_ASSETS_DATA: 'RESET_EXPORT_ASSETS_DATA',
  EXPORT_PLAN_PENDING: 'EXPORT_PLAN_PENDING',
  EXPORT_PLAN_SUCCESS: 'EXPORT_PLAN_SUCCESS',
  EXPORT_PLAN_ERROR: 'EXPORT_PLAN_ERROR',
  RESET_EXPORT_PLAN_DATA: 'RESET_EXPORT_PLAN_DATA',
};

export const INITIAL_STATE = {
  initialized: false,
  error: false,
  events: {
    error: false,
    loading: undefined,
    items: [],
    channel_name: null,
  },
  threat: {
    error: false,
    loading: undefined,
    item: null,
  },
  summary: {
    error: false,
    loading: undefined,
    materials: [],
  },
  exportMaterial: {
    error: false,
    loading: undefined,
    exportMaterialData: false,
  },
  exportAssets: {
    error: false,
    loading: undefined,
    exportMAssetsData: false,
  },
  exportPlans: {
    error: false,
    loading: undefined,
    exportPlanData: false,
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.FETCH_EVENTS_PENDING:
      return {
        ...state,
        events: {
          ...state.events,
          loading: action.params.append !== true,
          error: false,
        },
      };
    case TYPES.FETCH_EVENTS_SUCCESS:
      const { results, ...data } = action.payload;
      const { append = false } = action.params;
      const items = append ? [...state.events.items, ...results] : results;
      return {
        ...state,
        events: {
          ...state.events,
          ...data,
          error: false,
          loading: false,
          items: items.map(x => {
            return { ...x, visible: true };
          }),
          channel_name: action.params.channel,
        },
      };
    case TYPES.FETCH_EVENTS_ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          error: true,
          loading: false,
          items: [],
        },
      };
    case VIEW_TYPES.GET_VIEW_META_SUCCESS:
      if (action.params.datasource === 'events/threats') {
        return {
          ...state,
          initialized: true,
          events: {
            ...state.events,
            ...action.params,
            meta: {
              ...action.payload,
              current: {
                filters: state.events?.meta?.current?.filters || [],
              },
            },
          },
        };
      } else {
        return { ...state };
      }
    case VIEW_TYPES.GET_VIEW_META_ERROR:
      return {
        ...state,
        initialized: false,
        error: true,
      };
    case TYPES.UPDATE_FILTERS:
      const filters = action.payload;
      return {
        ...state,
        events: {
          ...state.events,
          meta: {
            ...state.events.meta,
            current: {
              ...state.events.meta.current,
              filters,
            },
          },
        },
      };
    case TYPES.GET_THREAT_PENDING:
      return {
        ...state,
        threat: {
          loading: true,
          status: false,
          item: null,
        },
      };
    case TYPES.GET_THREAT_SUCCESS:
      const { properties, ...threat } = action.payload;
      return {
        ...state,
        threat: {
          loading: false,
          status: false,
          item: {
            ...threat,
            ...properties,
          },
        },
      };
    case TYPES.GET_THREAT_ERROR:
      return {
        ...state,
        threat: {
          loading: false,
          error: true,
          item: null,
        },
      };
    case TYPES.GET_MATERIALS_SUMMARY_PENDING:
      return {
        ...state,
        summary: {
          loading: true,
          status: REQUESTED_DATA_STATUS.PENDING,
          materials: null,
        },
      };
    case TYPES.GET_MATERIALS_SUMMARY_SUCCESS:
      const { materials } = action.payload;
      return {
        ...state,
        summary: {
          loading: false,
          status: REQUESTED_DATA_STATUS.SUCCESS,
          materials,
        },
      };
    case TYPES.GET_MATERIALS_SUMMARY_ERROR:
      return {
        ...state,
        summary: {
          loading: false,
          error: true,
          materials: null,
        },
      };
    case TYPES.IGNORE_THREAT_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS,
        error: undefined,
        params: {},
      };
    case TYPES.IGNORE_THREAT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        threat: {
          loading: false,
          status: false,
          item: {
            ...state.threat.item,
            remove_status: !state.threat.item.remove_status,
          },
        },
        error: undefined,
      };
    case TYPES.IGNORE_THREAT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UN_TRACK_THREAT_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS,
        error: undefined,
        params: {},
      };
    case TYPES.UN_TRACK_THREAT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          ...data,
          error: false,
          loading: false,
          items: state.events.items.map(x => {
            if (x.threat.id === state.threat.item.id) {
              return { ...x, visible: false };
            } else {
              return x;
            }
          }),
        },
        threat: {
          loading: false,
          status: false,
          item: {
            ...state.threat.item,
            track_status: 'not_tracking',
          },
        },
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UN_TRACK_THREAT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.TRACK_THREAT_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS,
        error: undefined,
        params: {},
      };
    case TYPES.TRACK_THREAT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          ...data,
          error: false,
          loading: false,
          items: state.events.items.map(x => {
            if (x.threat.id === state.threat.item.id) {
              return { ...x, visible: true };
            } else {
              return x;
            }
          }),
        },
        threat: {
          loading: false,
          status: false,
          item: {
            ...state.threat.item,
            track_status: 'tracking',
          },
        },
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.TRACK_THREAT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.EXPORT_MATERIALS_PENDING:
      return {
        ...state,
        exportMaterial: {
          loading: true,
          status: REQUESTED_DATA_STATUS.PENDING,
          exportMaterialData: false,
        },
      };
    case TYPES.EXPORT_MATERIALS_SUCCESS:
      return {
        ...state,
        exportMaterial: {
          loading: false,
          status: REQUESTED_DATA_STATUS.SUCCESS,
          exportMaterialData: true,
        },
      };
    case TYPES.EXPORT_MATERIALS_ERROR:
      return {
        ...state,
        exportMaterial: {
          loading: false,
          error: true,
          exportMaterialData: false,
        },
      };

    case TYPES.RESET_EXPORT_MATERIALS_DATA:
      return {
        ...state,
        exportMaterial: {
          loading: false,
          error: false,
          exportMaterialData: false,
        },
      };

    case TYPES.EXPORT_ASSETS_PENDING:
      return {
        ...state,
        exportAssets: {
          loading: true,
          status: REQUESTED_DATA_STATUS.PENDING,
          exportMAssetsData: false,
        },
      };
    case TYPES.EXPORT_ASSETS_SUCCESS:
      return {
        ...state,
        exportAssets: {
          loading: false,
          status: REQUESTED_DATA_STATUS.SUCCESS,
          exportMAssetsData: true,
        },
      };
    case TYPES.EXPORT_ASSETS_ERROR:
      return {
        ...state,
        exportAssets: {
          loading: false,
          error: true,
          exportMAssetsData: false,
        },
      };

    case TYPES.RESET_EXPORT_ASSETS_DATA:
      return {
        ...state,
        exportAssets: {
          loading: false,
          error: false,
          exportMAssetsData: false,
        },
      };

    case TYPES.EXPORT_PLAN_PENDING:
      return {
        ...state,
        exportPlans: {
          loading: true,
          status: REQUESTED_DATA_STATUS.PENDING,
          exportPlanData: false,
        },
      };
    case TYPES.EXPORT_PLAN_SUCCESS:
      return {
        ...state,
        exportPlans: {
          loading: false,
          status: REQUESTED_DATA_STATUS.SUCCESS,
          exportPlanData: true,
        },
      };
    case TYPES.EXPORT_PLAN_ERROR:
      return {
        ...state,
        exportPlans: {
          loading: false,
          error: true,
          exportPlanData: false,
        },
      };

    case TYPES.RESET_EXPORT_PLAN_DATA:
      return {
        ...state,
        exportPlans: {
          loading: false,
          error: false,
          exportPlanData: false,
        },
      };
    default:
      return state;
  }
}

export const getEventsMeta = () => {
  return callApi({
    types: {
      pending: TYPES.GET_EVENTS_META_PENDING,
      success: TYPES.GET_EVENTS_META_SUCCESS,
      error: TYPES.GET_EVENTS_META_ERROR,
    },
    request: () => {
      const url = `events/threats/query/`;
      return axios.get(url);
    },
  });
};

export const fetchEvents = ({ ...options }) => {
  // TODO: PASS the channel
  const convertToUTC = date => moment(date).toISOString();
  const newOptions = {
    ...options,
    filters: options.filters?.map(filter =>
      filter.name === 'last_update'
        ? filter.operator === 'between'
          ? { ...filter, value: filter.value.map(f => convertToUTC(f)) }
          : { ...filter, value: convertToUTC(filter.value) }
        : filter,
    ),
  };
  const { channel, query = undefined, filters = [], inactiveChecked = false } = newOptions;

  const channelFilter = {
    name: 'channel_name',
    value: channel,
    operator: '==',
  };

  const queryFilter = {
    name: 'query',
    value: query,
    operator: '==',
  };
  const extraFilters = [...filters, channelFilter];

  const allFilters = query ? [...extraFilters, queryFilter] : extraFilters;

  return callApi({
    types: {
      pending: TYPES.FETCH_EVENTS_PENDING,
      success: TYPES.FETCH_EVENTS_SUCCESS,
      error: TYPES.FETCH_EVENTS_ERROR,
    },

    params: newOptions,
    request: () => {
      const { type = 'threats', limit = 10 } = options;
      const nextUrl = options.nextUrl ? options.nextUrl.split('v2/')[1] : null;
      const url = nextUrl ? nextUrl : `events/${type}/query/?limit=${limit}`;
      const payLoadData = {
        filters: allFilters,
        order_by: [{ name: 'reported_date', direction: 'desc' }],
      };
      if (inactiveChecked) {
        payLoadData.show_inactive = true;
      }
      return axios.post(url, payLoadData);
    },
  });
};

export const getThreat = threatId => {
  return callApi({
    types: {
      pending: TYPES.GET_THREAT_PENDING,
      success: TYPES.GET_THREAT_SUCCESS,
      error: TYPES.GET_THREAT_ERROR,
    },
    params: { threatId },
    request: () => {
      const url = `threats/${threatId}/`;
      return axios.get(url);
    },
  });
};

export const updateFilters = filters => {
  return {
    type: TYPES.UPDATE_FILTERS,
    payload: filters,
  };
};

export const getMaterialSummary = threatId => {
  return callApi({
    types: {
      pending: TYPES.GET_MATERIALS_SUMMARY_PENDING,
      success: TYPES.GET_MATERIALS_SUMMARY_SUCCESS,
      error: TYPES.GET_MATERIALS_SUMMARY_ERROR,
    },
    params: { threatId },
    request: () => {
      const url = `threats/${threatId}/summary/`;
      return axios.get(url);
    },
  });
};

export const ignoreThreat = threatId => {
  return callApi({
    types: {
      pending: TYPES.IGNORE_THREAT_PENDING,
      success: TYPES.IGNORE_THREAT_SUCCESS,
      error: TYPES.IGNORE_THREAT_ERROR,
    },
    request: () => axios.post(`threats/${threatId}/ignore/`),
    messages: {
      success: 'This incident has been removed from scoring and notifications for your organization.',
    },
  });
};

export const unTrackThreat = (threatId, hashId = undefined) => {
  return callApi({
    types: {
      pending: TYPES.UN_TRACK_THREAT_PENDING,
      success: TYPES.UN_TRACK_THREAT_SUCCESS,
      error: TYPES.UN_TRACK_THREAT_ERROR,
    },
    request: () => {
      const url = hashId ? `threats/${threatId}/track/?hash=${hashId}` : `threats/${threatId}/track/`;
      return axios.delete(url);
    },
    messages: {
      success: 'Threat has been successfully untracked!',
      error: 'Error already not tracking',
    },
  });
};

export const trackThreat = threatId => {
  return callApi({
    types: {
      pending: TYPES.TRACK_THREAT_PENDING,
      success: TYPES.TRACK_THREAT_SUCCESS,
      error: TYPES.TRACK_THREAT_ERROR,
    },
    request: () => axios.post(`threats/${threatId}/track/`),
  });
};

export const downloadMaterial = data => {
  return callApi({
    types: {
      pending: TYPES.EXPORT_MATERIALS_PENDING,
      success: TYPES.EXPORT_MATERIALS_SUCCESS,
      error: TYPES.EXPORT_MATERIALS_ERROR,
    },
    request: () => axios.post(`material/export/`, data),
  });
};

export const downloadAssets = data => {
  return callApi({
    types: {
      pending: TYPES.EXPORT_ASSETS_PENDING,
      success: TYPES.EXPORT_ASSETS_SUCCESS,
      error: TYPES.EXPORT_ASSETS_ERROR,
    },
    request: () => axios.post(`assets/export/`, data),
  });
};

export const downloadPlans = data => {
  return callApi({
    types: {
      pending: TYPES.EXPORT_PLAN_PENDING,
      success: TYPES.EXPORT_PLAN_SUCCESS,
      error: TYPES.EXPORT_PLAN_ERROR,
    },
    request: () => axios.post(`events/threats/plan/export/`, data),
  });
};
