import React from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import trend_up_icon from 'layout/icons/sl_risk_score_trend_up.svg';
import trend_down_icon from 'layout/icons/sl_risk_score_trend_down.svg';
import trend_neutral_icon from 'layout/icons/sl_risk_score_trend_neutral.svg';

export const Slider = styled(props => {
  return (
    <div className={props.className}>
      <span className={props.class} style={props.style}></span>
    </div>
  );
})`
  height: 20px;
  margin-bottom: 10px;
  position: relative;
  background: ${theme.colors.lightGrey};

  span {
    display: block;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 0;
  }

  .risk-high {
    background-color: ${theme.colors.highRisk};
  }

  .risk-medium {
    background-color: ${theme.colors.medRisk};
  }
  .risk-low {
    background-color: ${theme.colors.lowRisk};
  }
`;

export const SliderHeader = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  padding: 0 8% 0 2%;
  & > div label {
    font-size: ${theme.fontSizes[2]}px;
    font-weight: ${theme.fontWeights.bold};
    margin-right: 6px;
  }
  & > div .overall {
    font-weight: normal;
    font-size: ${theme.fontSizes[3]}px;
  }
  & > div span {
    font-size: ${theme.fontSizes.heading}px;
    font-weight: ${theme.fontWeights.bold};
  }
`;

export const TrendIcon = ({ trend = 'neutral' }) => {
  const trends = {
    up: trend_up_icon,
    down: trend_down_icon,
    neutral: trend_neutral_icon,
  };

  const url = trends[trend];
  if (url) {
    return (
      <span style={{ height: '100%', display: 'inline-block' }}>
        <img src={url} alt={trend} height="13" />
      </span>
    );
  }
  return null;
};

export const ToolLabel = styled(props => {
  return (
    <div>
      <span className="slider-heading slider-label">{props.name}: </span>
      <span className="slider-heading-score">{props.score || '-'}</span>
      <TrendIcon trend={props.trend} />
    </div>
  );
})`
  .slider-heading-score {
    font-weight: 700;
    margin-right: 4px;
  }
`;
