import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getProactiveCountryRisk, setActiveRiskModel } from 'admin/riskmodels/modules/riskmodels';

import { Col, Row, Layout, Spin } from 'antd';
import { ChartDiv, subIndexChartOptions } from 'assets/containers/AssetDetailView/AssetRiskSection/SubIndexCharts';
import IndividualSubIndexChart from 'assets/containers/AssetDetailView/AssetRiskSection/IndividualSubIndexChart';
import RiskChart from 'assets/containers/LocationRiskDetailsView/HeatMapBoxes';
import { removeRiskFilter, updateRiskFilter } from 'assets/modules/riskfilter';
import { Section } from '@luxe/components';
import SliderRiskChart from 'assets/containers/OverallRiskCharts';

const RiskTab = ({ updateRiskFilter, removeRiskFilter }) => {
  const className = undefined;
  const keyName = '';
  const riskFilter = {};
  const dispatch = useDispatch();
  const params = useParams();
  const { url } = useRouteMatch();
  const { id: countryId } = params;

  const { riskHistory } = useSelector(store => store.riskhistory);
  const { activeRiskModel, proactiveCountryRisk, RiskModelScores: riskModelScores, RiskModel: riskModel } = useSelector(
    store => store.riskModels,
  );
  const { region } = useSelector(store => store.regions);

  useEffect(() => {
    if (activeRiskModel.id !== proactiveCountryRisk.model_id) {
      dispatch(getProactiveCountryRisk({ countryId, riskModelId: activeRiskModel.id }));
    }
  }, [countryId, activeRiskModel.id, dispatch, proactiveCountryRisk.model_id]);

  useEffect(() => {
    if (region?.proactive_scores?.length && activeRiskModel.type !== 'country-risk') {
      dispatch(setActiveRiskModel(region.proactive_scores[0]?.model_id, 'country-risk'));
    }
  }, [activeRiskModel.type, region, dispatch]);

  if (!riskModel || activeRiskModel.type !== 'country-risk') {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  if (proactiveCountryRisk.category === undefined || proactiveCountryRisk.category == null) {
    return <Row style={{ width: '100%', justifyContent: 'center' }}>No Risk Configured</Row>;
  }
  const options = subIndexChartOptions(proactiveCountryRisk, riskHistory, updateRiskFilter, removeRiskFilter);
  const riskIndex = proactiveCountryRisk?.indices.length > 0 ? proactiveCountryRisk.indices[0] : {};
  const sliderRisk = {
    score: proactiveCountryRisk.score || 1,
    trend: proactiveCountryRisk.trend || 'neutral',
    category: proactiveCountryRisk.category || 'low',
  };

  return (
    <Section title={'Country Risk Details'}>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '25%', alignSelf: 'center', paddingRight: '20px' }}>
          <SliderRiskChart proactiveRisk={sliderRisk} riskHistory={riskHistory} showBenchMark={false} />
        </Col>
        <Col style={{ width: '75%', display: 'flex' }}>
          <ChartDiv key={`country-risk-chart`}>
            <span>{riskIndex?.name} Indices</span>
            <Col>
              <IndividualSubIndexChart cName={className} options={options[0]} scores={riskModelScores} />
            </Col>
          </ChartDiv>
          <Col style={{ width: '50%', marginTop: '30px' }}>
            <RiskChart
              risk={proactiveCountryRisk}
              riskIndex={riskIndex}
              riskModel={riskModel}
              history={riskHistory}
              keyName={keyName}
              riskFilter={riskFilter}
              url={url}
            />
          </Col>
        </Col>
      </Row>
    </Section>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRiskFilter,
      removeRiskFilter,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(RiskTab);
