import React from 'react';
import DrawerInformation from './DrawerInformation';

const DiscoveredAssetDrawerInformation = ({ asset, className, closeDrawer, isEkg }) => {
  const tierLevels = asset?.tier_level?.sort().join(', ') || '';
  const threatScores = {};
  ['overall', 'external', 'internal'].forEach(type => {
    threatScores[type] = {
      category: asset[`proactive_scores__${type}_category`] || 'low',
      score: asset[`proactive_scores__${type}_score`],
    };
  });
  threatScores.max = {
    score: asset.reactive__max_score,
    category: asset.reactive__max_category,
  };
  return (
    <DrawerInformation
      {...{
        className,
        name: asset.name,
        type: asset.type,
        countryName: asset.country_name,
        location: {
          longitude: asset.longitude,
          latitude: asset.latitude,
        },
        threatScores,
        closeDrawer,
        tierLevels: tierLevels,
        isEkg: isEkg,
        selectedNode: asset,
        isAssetDrawer: false,
      }}
    />
  );
};

export default DiscoveredAssetDrawerInformation;
