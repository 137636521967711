import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import { updateInList } from 'admin/utils';

export const TYPES = {
  GET_PLAN_PLAYBOOKS: 'GET_PLAN_PLAYBOOKS',
  GET_PLAN_PLAYBOOKS_ERROR: 'GET_PLAN_PLAYBOOKS_ERROR',
  GET_PLAN_PLAYBOOKS_SUCCESS: 'GET_PLAN_PLAYBOOKS_SUCCESS',

  GET_PLAN_PLAYBOOK_DETAIL: 'GET_PLAN_PLAYBOOK_DETAIL',
  GET_PLAN_PLAYBOOK_DETAIL_ERROR: 'GET_PLAN_PLAYBOOK_DETAIL_ERROR',
  GET_PLAN_PLAYBOOK_DETAIL_SUCCESS: 'GET_PLAN_PLAYBOOK_DETAIL_SUCCESS',

  CREATE_PLAN_PLAYBOOK: 'CREATE_PLAN_PLAYBOOK',
  CREATE_PLAN_PLAYBOOK_SUCCESS: 'CREATE_PLAN_PLAYBOOK_SUCCESS',
  CREATE_PLAN_PLAYBOOK_ERROR: 'CREATE_PLAN_PLAYBOOK_ERROR',

  UPDATE_PLAN_PLAYBOOK: 'UPDATE_PLAN_PLAYBOOK',
  UPDATE_PLAN_PLAYBOOK_SUCCESS: 'UPDATE_PLAN_PLAYBOOK_SUCCESS',
  UPDATE_PLAN_PLAYBOOK_ERROR: 'UPDATE_PLAN_PLAYBOOK_ERROR',

  UPDATE_PLAN_PLAYBOOK_TASKS: 'UPDATE_PLAN_TASKS_PLAYBOOK',
  UPDATE_PLAN_PLAYBOOK_TASKS_SUCCESS: 'UPDATE_PLAN_PLAYBOOK_TASKS_SUCCESS',
  UPDATE_PLAN_PLAYBOOK_TASKS_ERROR: 'UPDATE_PLAN_PLAYBOOK_TASKS_ERROR',

  DELETE_PLAN_PLAYBOOK: 'DELETE_PLAN_PLAYBOOK',
  DELETE_PLAN_PLAYBOOK_SUCCESS: 'DELETE_PLAN_PLAYBOOK_SUCCESS',
  DELETE_PLAN_PLAYBOOK_ERROR: 'DELETE_PLAN_PLAYBOOK_ERROR',
};

export const getPlanPlaybooks = params =>
  callApi({
    types: {
      pending: TYPES.GET_PLAN_PLAYBOOKS,
      success: TYPES.GET_PLAN_PLAYBOOKS_SUCCESS,
      error: TYPES.GET_PLAN_PLAYBOOKS_ERROR,
    },
    params,
    request: () => axios.get('plan-playbooks/'),
  });

export const getPlanPlaybook = playbookId =>
  callApi({
    types: {
      pending: TYPES.GET_PLAN_PLAYBOOK_DETAIL,
      success: TYPES.GET_PLAN_PLAYBOOK_DETAIL_SUCCESS,
      error: TYPES.GET_PLAN_PLAYBOOK_DETAIL_ERROR,
    },
    request: () => axios.get(`plan-playbooks/${playbookId}/`),
  });

export const createPlanPlaybook = data =>
  callApi({
    types: {
      pending: TYPES.CREATE_PLAN_PLAYBOOK,
      success: TYPES.CREATE_PLAN_PLAYBOOK_SUCCESS,
      error: TYPES.CREATE_PLAN_PLAYBOOK_ERROR,
    },
    request: () => axios.post('plan-playbooks/', data),
    messages: {
      success: 'Plan Playbook has been successfully created!',
      error: 'Plan Playbook creation unsuccessful.',
    },
  });

export const updatePlanPlaybook = data =>
  callApi({
    types: {
      pending: TYPES.UPDATE_PLAN_PLAYBOOK,
      success: TYPES.UPDATE_PLAN_PLAYBOOK_SUCCESS,
      error: TYPES.UPDATE_PLAN_PLAYBOOK_ERROR,
    },
    request: () => axios.put(`plan-playbooks/${data.id}/`, data),
    messages: {
      success: 'Plan Playbook has been successfully updated!',
      error: 'Plan Playbook update unsuccessful',
    },
  });

export const updatePlanPlaybookTasks = data =>
  callApi({
    types: {
      pending: TYPES.UPDATE_PLAN_PLAYBOOK_TASKS,
      success: TYPES.UPDATE_PLAN_PLAYBOOK_TASKS_SUCCESS,
      error: TYPES.UPDATE_PLAN_PLAYBOOK_TASKS_ERROR,
    },
    request: () => axios.put(`plan-playbooks/${data.id}/`, data),
    messages: {
      success: 'Plan Playbook has been successfully updated!',
      error: 'Plan Playbook update unsuccessful',
    },
  });

export const deletePlanPlaybook = playbookId =>
  callApi({
    types: {
      pending: TYPES.DELETE_PLAN_PLAYBOOK,
      success: TYPES.DELETE_PLAN_PLAYBOOK_SUCCESS,
      error: TYPES.DELETE_PLAN_PLAYBOOK_ERROR,
    },
    request: () => axios.delete(`plan-playbooks/${playbookId}/`),
    params: {
      playbookId,
    },
    messages: {
      success: 'The Plan Playbook has been successfully deleted.',
      error: 'Plan Playbook deletion unsuccessful.',
    },
  });

export const INITIAL_STATE = {
  status: undefined,
  planPlaybooks: [],
  error: undefined,
  params: undefined,
  PlanPlaybook: undefined,
};

export const planPlaybookReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_PLAN_PLAYBOOKS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_PLAN_PLAYBOOKS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_PLAN_PLAYBOOKS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        planPlaybooks: action.payload,
        error: undefined,
      };
    case TYPES.GET_PLAN_PLAYBOOK_DETAIL:
      return {
        ...state,
        params: action.payload,
        PlanPlaybook: null,
        detailStatus: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_PLAN_PLAYBOOK_DETAIL_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        detailStatus: REQUESTED_DATA_STATUS.ERROR,
      };
    case TYPES.GET_PLAN_PLAYBOOK_DETAIL_SUCCESS:
      return {
        ...state,
        PlanPlaybook: action.payload,
        error: undefined,
        detailStatus: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.UPDATE_PLAN_PLAYBOOK:
      return {
        ...state,
        detailStatus: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_PLAN_PLAYBOOK_SUCCESS:
      return {
        ...state,
        detailStatus: REQUESTED_DATA_STATUS.SUCCESS,
        planPlaybooks: [...updateInList(action.payload, state.planPlaybooks)],
        PlanPlaybook: action.payload,
      };
    case TYPES.UPDATE_PLAN_PLAYBOOK_ERROR:
      return {
        ...state,
        detailStatus: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.DELETE_PLAN_PLAYBOOK:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.DELETE_PLAN_PLAYBOOK_SUCCESS:
      const { playbookId } = action.params;
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        planPlaybooks: state.planPlaybooks.filter(planPlaybook => planPlaybook.id !== playbookId),
        error: undefined,
      };
    case TYPES.DELETE_PLAN_PLAYBOOK_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
