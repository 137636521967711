import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_ERROR: 'UPDATE_USER_PROFILE_ERROR',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  PATCH_ACCOUNT_USER_PREFERENCE: 'PATCH_ACCOUNT_USER_PREFERENCE',
  PATCH_ACCOUNT_USER_PREFERENCE_ERROR: 'PATCH_ACCOUNT_USER_PREFERENCE_ERROR',
  PATCH_ACCOUNT_USER_PREFERENCE_SUCCESS: 'PATCH_ACCOUNT_USER_PREFERENCE_SUCCESS',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FROM_ID: 'GET_USER_PROFILE_FROM_ID',
  GET_USER_PROFILE_FROM_ID_ERROR: 'GET_USER_PROFILE_FROM_ID_ERROR',
  GET_USER_PROFILE_FROM_ID_SUCCESS: 'GET_USER_PROFILE_FROM_ID_SUCCESS',
};

export const INITIAL_STATE = {
  industries: [],
  countries: [],
  job_role: '',
};

export const putUserProfile = data => {
  return callApi({
    types: {
      pending: TYPES.UPDATE_USER_PROFILE,
      success: TYPES.UPDATE_USER_PROFILE_SUCCESS,
      error: TYPES.UPDATE_USER_PROFILE_ERROR,
    },
    request: () => axios.put(`account/user/profile/`, data),
    messages: {
      success: 'User Profile successfully updated!',
      error: 'User Profile update unsuccessful',
    },
  });
};
export const patchUserPreference = data =>
  callApi({
    types: {
      pending: TYPES.PATCH_ACCOUNT_USER_PREFERENCE,
      success: TYPES.PATCH_ACCOUNT_USER_PREFERENCE_SUCCESS,
      error: TYPES.PATCH_ACCOUNT_USER_PREFERENCE_ERROR,
    },
    request: () => axios.patch(`account/user/prefs/`, data),
  });
export const getUserProfile = () => {
  return callApi({
    types: {
      pending: TYPES.GET_USER_PROFILE,
      success: TYPES.GET_USER_PROFILE_SUCCESS,
      error: TYPES.GET_USER_PROFILE_ERROR,
    },
    request: () => axios.get(`account/user/profile/`),
  });
};
export const getUserProfileFromId = userId => {
  return callApi({
    types: {
      pending: TYPES.GET_USER_PROFILE_FROM_ID,
      success: TYPES.GET_USER_PROFILE_FROM_ID_SUCCESS,
      error: TYPES.GET_USER_PROFILE_FROM_ID_ERROR,
    },
    request: () => axios.get(`account/user/${userId}/profile/`),
  });
};
export const userProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.UPDATE_USER_PROFILE:
      return {
        ...state,
        putProfileStatus: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        putProfileStatus: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        putProfileStatus: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.PATCH_ACCOUNT_USER_PREFERENCE:
      return {
        ...state,
        patchPreferenceStatus: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.PATCH_ACCOUNT_USER_PREFERENCE_SUCCESS:
      return {
        ...state,
        patchPreferenceStatus: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.PATCH_ACCOUNT_USER_PREFERENCE_ERROR:
      return {
        ...state,
        patchPreferenceStatus: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_USER_PROFILE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_USER_PROFILE_FROM_ID:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_USER_PROFILE_FROM_ID_SUCCESS:
      return {
        ...state,
        selectedUserProfile: action.payload,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.GET_USER_PROFILE_FROM_ID_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userProfileReducer;
