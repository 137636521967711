import React from 'react';
import styled from '@emotion/styled';

const AVAILABLE_INCIDENT_CATEGORIES = {
  Aviation: 'aviation',
  Cargo: 'cargo',
  'Civil Unrest': 'civil_unrest',
  Cyber: 'cyber',
  Fire: 'fire',
  'Ground Transportation': 'ground_transportation',
  Hazmat: 'hazmat',
  Health: 'health',
  Infrastructure: 'infrastructure',
  Labor: 'labor',
  'Manual Incidents': 'manual_incidents',
  Maritime: 'maritime',
  'Natural Disaster': 'natural_disaster',
  None: 'other',
  Other: 'other',
  Production: 'production',
  'Public Holidays': 'public_holidays',
  Regulatory: 'regulatory',
  Security: 'security',
  Structural: 'structural',
  Terrorism: 'terrorism',
  War: 'war',
  Weather: 'weather',
};

const AVAILABLE_SEVERITIES_ICONS = {
  extreme: 'triangle_red',
  severe: 'triangle_red',
  moderate: 'triangle_amber',
  unknown: 'triangle_amber',
  minor: 'triangle_green',
};

const DEFAULT_CATEGORY_ICON = AVAILABLE_INCIDENT_CATEGORIES.Other;
const DEFAULT_SEVERITY_ICON = AVAILABLE_SEVERITIES_ICONS.unknown;

function getBackgroundIcon(severity) {
  const severityIconName = AVAILABLE_SEVERITIES_ICONS[severity] || DEFAULT_SEVERITY_ICON;
  return 'incident_' + severityIconName + '.svg';
}

function getCategoryIcon(category) {
  const categoryIconName = AVAILABLE_INCIDENT_CATEGORIES[category] || DEFAULT_CATEGORY_ICON;
  return 'incident_category-' + categoryIconName + '.svg';
}

function ThreatIcon({ severity, category }) {
  return (
    <IconContainer>
      <img src={require('layout/icons/' + getBackgroundIcon(severity))} alt={'severity-icon'} />
      <Icon src={require('layout/icons/' + getCategoryIcon(category))} alt={'category-icon'} />
    </IconContainer>
  );
}

export default ThreatIcon;

const Icon = styled.img`
  position: absolute;
  height: 8px;
  left: 6px;
  top: 8px;
`;

const IconContainer = styled.div`
  display: inline;
  position: relative;
`;
