import {
  centerOnNode,
  changeCooldown,
  changeGraphFilters,
  changeGraphView,
  changeVelocity,
  getNetworkAssetGraph,
  REFRESH_COOLDOWN,
  REFRESH_VELOCITY,
  setDrawerVisibility,
  STOP_COOLDOWN,
  STOP_VELOCITY,
  TYPES as VIZ_TYPES,
  zoomToFitCamera,
  clearSearchedNode,
  selectNodeThroughSearch,
  clearGraphFilters,
  clearSelectedNodeIdFromURL,
  getImpactedAssets,
  updateGraphFilters,
  getAssetGraphOnFilters,
  focusNode,
  NODE_COLOR_INCIDENT_RISK_SCORE,
  NODE_COLOR_RISK_SCORE,
  resetInitialNodeIdUrl,
} from 'discover/modules/viz';
import { ofType } from 'redux-observable';
import { map, filter, mergeMap } from 'rxjs/operators';
import { getDiscoverAssets } from 'admin/assets/modules/assets';
import { TYPES as GRAPHTYPES } from 'assets/modules/graphs';
import { getSearchFacilities } from 'assets/modules/graphs';
import { TYPES as SPINNER_TYPES } from 'common/reducers/apiOperationsReducer';
import { getCountries } from 'modal/userprofile/modules/network';
import { getAssetGroups } from 'admin/assetgroups/modules/assetgroups';
import { of } from 'rxjs';
import { GRAPH_MODES } from 'common/Constants';

const setGraphViewFromFetchedViewsEpic = (action$, state$) =>
  // set graph view when the views have been fetched
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_GRAPH_VIEWS_SUCCESS),
    filter(() => !state$.value.networkGraph.selectedView || state$.value.networkGraph.incidentId),
    map(() => {
      if (!state$.value.networkGraph.incidentId) {
        // only change the selected view when it has not already been set
        return changeGraphView(state$.value.account.account?.prefs?.default_network_graph_view);
      } else {
        return getImpactedAssets(state$.value.networkGraph.incidentId);
      }
    }),
  );

const setGraphViewAfterCreateEpic = (action$, state$) =>
  // set graph view when the views have been fetched
  action$.pipe(
    ofType(VIZ_TYPES.CREATE_NETWORK_GRAPH_VIEW_SUCCESS),
    map(() => {
      // only change the selected view when it has not already been set
      return changeGraphView(state$.value.networkGraph.selectedView);
    }),
  );

const updateGraphFiltersToSelectedViewEpic = (action$, state$) =>
  // updating graph filters when the selected view has changed
  action$.pipe(
    ofType(VIZ_TYPES.CHANGED_GRAPH_VIEW),
    mergeMap(() => {
      if (state$.value.networkGraph.selectedView && !state$.value.networkGraph.fetchedNodeIdFromURL?.fname) {
        // The graph mode has been set to default for managing the graph mode of previously saved views.
        const filters = { graphMode: GRAPH_MODES.DEFAULT, ...state$.value.networkGraph.selectedView.settings };
        return of(changeGraphFilters(filters), clearSearchedNode());
      } else {
        let facility;
        if (state$.value.networkGraph.fetchedNodeIdFromURL?.fname) {
          facility = state$.value.graphs.search_facilities.find(
            f => f.name === state$.value.networkGraph.fetchedNodeIdFromURL?.fname,
          );
        }
        return of(
          changeGraphFilters({
            node_color: NODE_COLOR_INCIDENT_RISK_SCORE,
            graphMode: GRAPH_MODES.DEFAULT,
            max_depth: 1,
            asset_group: [],
            countries: [],
            facilities: facility?.id ? [facility?.id] : [],
            hs_codes: [],
            material: [],
            product: [],
            risk: [],
          }),
          clearSearchedNode(),
        );
      }
    }),
  );

const fetchGraphWithNewFiltersEpic = (action$, state$) =>
  // fetch the graph when filters update
  action$.pipe(
    ofType(
      VIZ_TYPES.CHANGED_GRAPH_FILTERS,
      VIZ_TYPES.CHANGED_GRAPH_MODE,
      VIZ_TYPES.UPDATE_NODE_COLOR,
      VIZ_TYPES.UPDATE_DISCOVERED_FACILITIES_TOGGLE,
    ),
    map(action => {
      let filters = { ...state$.value.networkGraph.filters };
      if (state$.value.networkGraph.incidentId) {
        filters.max_depth = 3;
      }
      const isMaterialDiscoverEnabled =
        state$.value?.accountOrg?.accountOrg?.prefs?.discover?.enabled &&
        state$.value?.accountOrg?.accountOrg?.prefs?.materials?.enabled;
      if (
        (action.type === VIZ_TYPES.CHANGED_GRAPH_MODE &&
          ![action.payload?.currentMode, action.payload?.newMode].includes(GRAPH_MODES.TIERED_2D)) ||
        (action.type === VIZ_TYPES.UPDATE_NODE_COLOR &&
          ![NODE_COLOR_INCIDENT_RISK_SCORE, NODE_COLOR_RISK_SCORE].includes(action.payload?.node_color))
      ) {
        return { type: VIZ_TYPES.NO_ACTION };
      }
      return getNetworkAssetGraph(
        filters,
        state$.value.networkGraph.incidentId,
        state$.value.networkGraph.fetchedNodeIdFromURL?.fname,
        isMaterialDiscoverEnabled,
      );
    }),
  );

const enableSpinner = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.CHANGED_GRAPH_FILTERS),
    map(() => {
      return { type: SPINNER_TYPES.SHOW_LOADING_SPINNER };
    }),
  );

const disableSpinner = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH, VIZ_TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS),
    map(() => {
      return { type: SPINNER_TYPES.HIDE_LOADING_SPINNER };
    }),
  );

const fetchDiscoverAssetsEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH, VIZ_TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS),
    map(action => {
      let nodes = [];
      if (action.type === VIZ_TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS) {
        nodes = state$.value.networkGraph.graph.getAllNodes()?.map(n => n.name);
      } else {
        nodes = action.payload.nodes.map(n => n.name);
      }
      return getDiscoverAssets(nodes);
    }),
  );

const fetchCountriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH),
    filter(() => !state$.value.userNetwork.countries),
    map(() => {
      return getCountries();
    }),
  );

const fetchFacilitiesEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH, VIZ_TYPES.UPDATE_NODEID_URL, VIZ_TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS),
    map(action => {
      return getSearchFacilities({
        max_depth:
          action.type === VIZ_TYPES.UPDATE_NODEID_URL && !state$.value.networkGraph.fetchedNodeIdFromURL?.fname
            ? 3
            : state$.value.networkGraph.filters.max_depth,
        query: action.type === VIZ_TYPES.UPDATE_NODEID_URL ? action.payload?.fname || action.payload?.name : null,
        product: state$.value.networkGraph.filters.product,
        material: state$.value.networkGraph.filters.material,
        hs_codes: state$.value.networkGraph.filters.hs_codes,
        countries: state$.value.networkGraph.filters.countries,
      });
    }),
  );

const fetchAssetGroupsEpic = action$ =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH),
    map(() => {
      return getAssetGroups();
    }),
  );

const refreshCooldownAfterNewGraphEpic = action$ =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH, VIZ_TYPES.GET_NETWORK_ASSET_GRAPH_DETAILS),
    map(() => {
      return changeCooldown(REFRESH_COOLDOWN);
    }),
  );

const centerOnNodeAfterNewGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH),
    filter(() => !state$.value.networkGraph.searchedNodeId && !state$.value.networkGraph.fetchedNodeIdFromURL?.fname),
    map(() => {
      return centerOnNode(null);
    }),
  );

const refitZoomAfterNewGraphEpic = action$ =>
  action$.pipe(
    ofType(
      VIZ_TYPES.GET_NETWORK_ASSET_GRAPH,
      VIZ_TYPES.CHANGED_GRAPH_MODE,
      VIZ_TYPES.UPDATE_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES,
      VIZ_TYPES.RESET_NETWORK_GRAPH_VISIBLE_EDGES_AND_NODES,
      VIZ_TYPES.UNFOCUS_NODE,
    ),
    map(() => {
      return zoomToFitCamera(true);
    }),
  );

const stopVelocityAfterZoomToFitEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.ZOOMED_TO_FIT_CAMERA),
    map(() => {
      if (state$.value.networkGraph.zoomToFit) {
        return changeVelocity(REFRESH_VELOCITY);
      } else {
        return changeVelocity(STOP_VELOCITY);
      }
    }),
  );

const stopCooldownAfterZoomToFitEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.ZOOMED_TO_FIT_CAMERA),
    map(() => {
      if (state$.value.networkGraph.zoomToFit) {
        return changeCooldown(REFRESH_COOLDOWN);
      } else {
        return changeCooldown(STOP_COOLDOWN);
      }
    }),
  );

const raiseDrawerForSelectedNodeEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.SELECTED_NODE),
    map(() => {
      return setDrawerVisibility(true);
    }),
  );

const applyFiltersForSearchNodeEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.SELECT_NODE_THROUGH_SEARCH),
    filter(action => action.payload),
    map(action => {
      return changeGraphFilters({
        max_depth: state$.value.networkGraph.fetchedNodeIdFromURL?.fname
          ? state$.value.networkGraph.filters.max_depth
          : 3,
        facilities: [action.payload],
      });
    }),
  );

const selectNodeAfterSearchResultEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.GET_NETWORK_ASSET_GRAPH),
    filter(() => state$.value.networkGraph.searchedNodeId),
    mergeMap(action => {
      return of(
        centerOnNode(
          action.payload?.nodes?.find(n => String(n.id) === String(state$.value.networkGraph.searchedNodeId)),
        ),
        setDrawerVisibility(true),
      );
    }),
  );

const showDrawerOnURL = (action$, state$) =>
  action$.pipe(
    ofType(GRAPHTYPES.SEARCH_FACILITIES_SUCCESS),
    filter(() => state$.value.networkGraph.fetchedNodeIdFromURL?.name),
    mergeMap(() => {
      const facility = state$.value.graphs.search_facilities.find(
        f => f.name === state$.value.networkGraph.fetchedNodeIdFromURL?.name,
      );
      if (state$.value.networkGraph.searchedNodeId === facility?.id || state$.value.networkGraph.searchedNodeId) {
        return of({ type: VIZ_TYPES.NO_ACTION });
      } else if (!facility) {
        return of(clearGraphFilters(), clearSelectedNodeIdFromURL());
      }
      return of(selectNodeThroughSearch(facility.id));
    }),
  );

const updateInitialNodeIdUrl = (action$, state$) =>
  action$.pipe(
    ofType(GRAPHTYPES.SEARCH_FACILITIES_SUCCESS),
    filter(
      () => state$.value.networkGraph.fetchedNodeIdFromURL?.fname && state$.value.networkGraph?.initialNodeIdUpdate,
    ),
    mergeMap(() => {
      return of(resetInitialNodeIdUrl(false));
    }),
  );

const showFacilityDrawerOnURL = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.SHOW_FACILITY_DRAWER_ON_URL),
    filter(() => state$.value.networkGraph.fetchedNodeIdFromURL?.fname),
    mergeMap(action => {
      const facility = state$.value.graphs.search_facilities.find(
        f => f.name === state$.value.networkGraph.fetchedNodeIdFromURL?.fname,
      );
      if (state$.value.networkGraph.fetchedNodeIdFromURL?.fname) {
        return of(
          updateGraphFilters({
            facilities: facility?.id ? [facility?.id] : [],
          }),
          centerOnNode(action.payload?.find(n => String(n.id) === String(facility?.id))),
          focusNode(facility?.id),
          setDrawerVisibility(true),
        );
      }
      return { type: VIZ_TYPES.NO_ACTION };
    }),
  );

const clearSearchedNodeEpic = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.CHANGED_DRAWER_VISIBILITY),
    filter(action => !action.payload),
    map(() => {
      return clearSearchedNode();
    }),
  );

const nextImpactedAssets = (action$, state$) =>
  action$.pipe(
    // ofType(VIZ_TYPES.GET_IMPACTED_ASSETS_SUCCESS),
    ofType(VIZ_TYPES.GET_IMPACTED_ASSETS),
    map(() => {
      // if (state$.value.networkGraph.nextImpactedAssetsURL) {
      //   return getImpactedAssets(state$.value.networkGraph.incidentId, state$.value.networkGraph.nextImpactedAssetsURL);
      // } else {
      let filters = { ...state$.value.networkGraph.filters };
      filters.max_depth = 3;
      const isMaterialDiscoverEnabled =
        state$.value?.accountOrg?.accountOrg?.prefs?.discover?.enabled &&
        state$.value?.accountOrg?.accountOrg?.prefs?.materials?.enabled;
      return getNetworkAssetGraph(
        filters,
        state$.value.networkGraph.incidentId,
        state$.value.networkGraph.fetchedNodeIdFromURL?.fname,
        isMaterialDiscoverEnabled,
      );
    }),
  );

const changeFiltersOnImpactedAssets = (action$, state$) =>
  action$.pipe(
    ofType(VIZ_TYPES.FETCH_NETWORK_ASSET_GRAPH_DETAILS),
    mergeMap(() => {
      // const allNodes = state$.value.networkGraph.graph.getAllNodes();
      // const facIds = allNodes
      //   ?.filter(item => {
      //     return state$.value.networkGraph.impactedAssets.find(x => x?.asset?.name === item.name);
      //   })
      //   ?.map(fac => fac.id);
      return of(
        updateGraphFilters({
          // facilities: facIds ? facIds : [],
          max_depth: 3,
        }),
        getAssetGraphOnFilters(),
      );
    }),
  );

const epics = {
  setGraphViewFromFetchedViewsEpic,
  setGraphViewAfterCreateEpic,
  updateGraphFiltersToSelectedViewEpic,
  fetchGraphWithNewFiltersEpic,
  fetchDiscoverAssetsEpic,
  fetchCountriesEpic,
  fetchFacilitiesEpic,
  fetchAssetGroupsEpic,
  refreshCooldownAfterNewGraphEpic,
  refitZoomAfterNewGraphEpic,
  stopCooldownAfterZoomToFitEpic,
  stopVelocityAfterZoomToFitEpic,
  raiseDrawerForSelectedNodeEpic,
  applyFiltersForSearchNodeEpic,
  clearSearchedNodeEpic,
  selectNodeAfterSearchResultEpic,
  centerOnNodeAfterNewGraphEpic,
  showDrawerOnURL,
  showFacilityDrawerOnURL,
  enableSpinner,
  disableSpinner,
  nextImpactedAssets,
  changeFiltersOnImpactedAssets,
  updateInitialNodeIdUrl,
};
export default epics;
