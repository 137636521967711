import React from 'react';

import { Row } from 'antd';
import { Breadcrumb, BreadcrumbItem } from '@luxe/components';
import { useTheme } from 'emotion-theming';

const HeaderRow = ({ breadcrumbs = [] }) => {
  const { space } = useTheme();
  return (
    <Row type="flex" justify="space-between">
      <Breadcrumb style={{ margin: `${space.small}px ${space.none}` }}>
        {breadcrumbs.map((item, idx) => {
          return <BreadcrumbItem key={`scorecard-header-breadcrumb-${idx}`}>{item}</BreadcrumbItem>;
        })}
      </Breadcrumb>
    </Row>
  );
};

export default HeaderRow;
