import 'leaflet/dist/leaflet.css';

import { Layout, Spin } from 'antd';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import L from 'leaflet';
import React from 'react';
import { getAssetIcon } from 'assets/containers/AssetDetailView/styles';

const MapStyle = {
  marginBottom: '8px',
  height: '180px',
  width: '188px',
  borderRadius: '0px',
};

function getMapIcon(category) {
  return L.icon({
    iconUrl: getAssetIcon(category),
    iconSize: [50, 60],
  });
}

const MiniMap = ({ asset, category }) => {
  if (!asset) {
    return (
      <Layout>
        <Spin size={'small'} />
      </Layout>
    );
  }
  //TODO: remove zoom controls
  return (
    <MapContainer
      center={[asset.location.lat, asset.location.lon]}
      zoom={13}
      scrollWheelZoom={false}
      zoomControl={false}
      style={MapStyle}
    >
      <TileLayer url="https://api.mapbox.com/styles/v1/stormpulse/ck0zgcsyw0yrc1ckypuh2q4be/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3Rvcm1wdWxzZSIsImEiOiJYd1YxUXpNIn0.N_-0IOIF6RUOHyF9JUIPVQ" />
      <Marker position={[asset.location.lat, asset.location.lon]} icon={getMapIcon(category)} />
    </MapContainer>
  );
};

export default MiniMap;
