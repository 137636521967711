import React, { useState } from 'react';

import { Input, Tag } from 'antd';
import styled from '@emotion/styled';
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const KeywordSelectors = styled(({ value, ...props }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleClose = removedTag => {
    const newTags = value.filter(tag => tag !== removedTag);
    props.onChange(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  /** return True if input is valid else False with displaying error message */
  const handleInputValidation = input => {
    if (!/^[a-zA-Z0-9()]+$/.test(input)) {
      setShowErrorMessage(true);
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateKeywords = (newTags, input) => {
    if (input && value?.indexOf(input) === -1 && /^[a-zA-Z0-9()]+$/.test(input)) {
      setShowErrorMessage(false);
    }
    props.onChange(newTags);
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (handleInputValidation(inputValue)) {
      const newTags = [...value, inputValue];
      handleUpdateKeywords(newTags, inputValue);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = e => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    if (handleInputValidation(editInputValue)) {
      const newTags = [...value];
      newTags[editInputIndex] = editInputValue;

      handleUpdateKeywords(newTags, editInputValue);
    }
    setEditInputIndex(-1);
    setInputValue('');
  };

  return (
    <div className={`${props.className}`}>
      {value?.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              style={{
                width: 78,
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '9px',
                borderColor: '#246472',
              }}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag className="edit-tag" key={tag} closable onClose={() => handleClose(tag)}>
            <span
              onDoubleClick={e => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 15)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          type="text"
          size="small"
          style={{
            width: 78,
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '9px',
            borderColor: '#246472',
          }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (value === undefined || value?.length < 5) && (
        <Tag onClick={showInput}>
          <PlusOutlined /> New Keyword
        </Tag>
      )}
      {showErrorMessage && (
        <div className="tag-validation">
          Keyword has to be a complete word (only alpha characters and numbers are allowed)
        </div>
      )}
    </div>
  );
})`
  & {
    margin-top: 5px;
  }
  .ant-tag {
    background-color: black;
    border-radius: 9px;
    border-color: #246472;
  }
  .tag-validation {
    color: red;
    margin-top: 2px;
  }
`;

export default KeywordSelectors;
