import React from 'react';
import PropTypes from 'prop-types';

export default class BoolFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const dotStyle = {
      height: '10px',
      width: '10px',
      backgroundColor: '#377d22',
      borderRadius: '50%',
      display: 'inline-block',
    };
    if (this.props.value) {
      return (
        <div>
          <span style={dotStyle}></span>
        </div>
      );
    }

    return '';
  }
}
