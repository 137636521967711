import styled from '@emotion/styled';
import { space, layout, flexbox, position } from 'styled-system';
import { Radio } from 'antd';

export const Table = styled.table`
  width: 100%;
  text-align: left;
  display: table;
  color: rgba(0, 0, 0, 0.85);
  .ant-radio-inner {
    background-color: black;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #2d8fa5 !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #2d8fa5;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #2d8fa5;
  }

  .ant-radio-wrapper {
    margin-bottom: 10px;
    cursor: auto;
  }

  .ant-radio-button-wrapper {
    background-color: #2c2c2c;
    color: #919191;
  }

  .ant-radio-group .ant-radio-button-wrapper {
    display: inline-block;
  }

  & tr.header {
    background-color: #141414;
  }

  & tr {
    border: none;
    background-color: #2e2e2e;
    color: rgba(255, 255, 255, 0.85);
  }

  & tr.alt {
    background-color: #212121;
  }

  & td {
    border: none;
    padding: 5px;
    vertical-align: top;
  }

  & th {
    padding: 10px;
  }

  & td.info {
    padding-bottom: 20px;
  }

  & div.question {
    margin-bottom: 10px;
  }

  & div.ant-form-item,
  div.ant-row,
  div.ant-col,
  div.ant-form-item-control-input,
  div.ant-form-item-control-input-content {
    margin-bottom: 0;
    margin-left: 0;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  ${space}
  ${position}
  ${layout}
  ${flexbox}
  display: flex;
  flex-direction: column;
`;
