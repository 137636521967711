import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  HIDE_INCIDENT: 'HIDE_INCIDENT',
  HIDE_INCIDENT_ERROR: 'HIDE_INCIDENT_ERROR',
  HIDE_INCIDENT_SUCCESS: 'HIDE_INCIDENT_SUCCESS',
  UNHIDE_INCIDENT: 'UNHIDE_INCIDENT',
  UNHIDE_INCIDENT_ERROR: 'UNHIDE_INCIDENT_ERROR',
  UNHIDE_INCIDENT_SUCCESS: 'UNHIDE_INCIDENT_SUCCESS',
};

export const INITIAL_STATE = {};

export const hideIncident = (incidentId, hashId = undefined) => {
  return callApi({
    types: {
      pending: TYPES.HIDE_INCIDENT,
      success: TYPES.HIDE_INCIDENT_SUCCESS,
      error: TYPES.HIDE_INCIDENT_ERROR,
    },
    request: () => {
      const url = hashId ? `threats/${incidentId}/track/?hash=${hashId}` : `threats/${incidentId}/track/`;
      return axios.delete(url);
    },
    messages: {
      success: 'Incident has been successfully untracked!',
      error: 'Error already not tracking',
    },
  });
};

export const unhideIncident = incidentId => {
  return callApi({
    types: {
      pending: TYPES.UNHIDE_INCIDENT,
      success: TYPES.UNHIDE_INCIDENT_SUCCESS,
      error: TYPES.UNHIDE_INCIDENT_ERROR,
    },
    request: () => axios.post(`threats/${incidentId}/track/`),
  });
};

export default function subscriptionReducer(state = INITIAL_STATE, action) {
  switch (action?.type) {
    case TYPES.HIDE_INCIDENT:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS,
        error: undefined,
        params: {},
      };
    case TYPES.HIDE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.HIDE_INCIDENT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UNHIDE_INCIDENT:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS,
        error: undefined,
        params: {},
      };
    case TYPES.UNHIDE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.UNHIDE_INCIDENT_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
}
