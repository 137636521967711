import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { stringSorter, dateSorter } from 'utils';
import { Empty, Table } from '@luxe/components';

const DATE_FORMAT = 'MMM DD, YYYY';

const getFormattedDate = date =>
  moment(date)
    .utc()
    .format(DATE_FORMAT);

const RiskProfileTable = ({ status, riskProfiles, setSelectedProfiles }) => {
  let description = 'There are no Risk Profiles available';
  const history = useHistory();
  const [hovered, setHovered] = useState(0);

  if (status === REQUESTED_DATA_STATUS.ERROR) {
    description = 'Something went wrong';
  }

  const getColumns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      render: riskProfile => <span>{riskProfile}</span>,
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'Profile Type',
      dataIndex: 'system_wide',
      render: riskProfile => {
        return <span>{riskProfile ? 'System-Wide' : 'Org-Wide'}</span>;
      },
      sorter: (a, b) => !(a.system_wide === b.system_wide ? 0 : b.system_wide ? -1 : 1),
    },
    {
      title: 'Profile Based On',
      render: riskProfile =>
        riskProfile.parent ? (
          <span
            onMouseEnter={() => updateLinkState(riskProfile.parent.id)}
            onMouseLeave={() => resetLinkState(riskProfile.id)}
          >
            {riskProfile.parent.name}
          </span>
        ) : (
          '-'
        ),
      sorter: (a, b) => stringSorter(a.parent?.name, b.parent?.name),
    },
    {
      title: 'Last Modified',
      dataIndex: 'modified_date',
      render: riskProfile => getFormattedDate(riskProfile),
      sorter: (a, b) => dateSorter(a.modified_date, b.modified_date),
    },
  ];

  const updateLinkState = parentId => {
    setHovered(parentId);
  };

  const resetLinkState = childId => {
    setHovered(childId);
  };

  const setUrl = path => {
    if (path.includes('/admin/risk-profiles/')) {
      history.push(hovered.toString() + '/');
    } else {
      history.push('/admin/risk-profiles/' + hovered.toString() + '/');
    }
  };

  const rowSelection = {
    getCheckboxProps: record => {
      return {
        disabled: record?.system_wide,
      };
    },
    onChange: idsSelected => {
      setSelectedProfiles(idsSelected);
    },
  };
  const rowInteraction = record => {
    return {
      onClick: () => {
        let path = history.location.pathname;
        setUrl(path);
      },
      onMouseEnter: () => {
        setHovered(record.id);
      },
      onMouseLeave: () => {
        setHovered(0);
      },
    };
  };

  return (
    <div>
      <Table
        onRow={rowInteraction}
        rowSelection={rowSelection}
        rowKey="id"
        status={status}
        columns={getColumns()}
        dataSource={riskProfiles}
        description={description}
        size="small"
        locale={{ emptyText: <Empty description={status === REQUESTED_DATA_STATUS.PENDING ? 'Loading' : 'No Data'} /> }}
      />
    </div>
  );
};

export default RiskProfileTable;
