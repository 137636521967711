import React from 'react';
import styled from '@emotion/styled';

export const DistanceRiskBar = styled(({ risks, className }) => {
  const label = risks.high || risks.medium || risks.low;
  const labelClass = risks.high ? 'high' : risks.medium ? 'medium' : 'low';

  return (
    <div className={className}>
      <div className={`risk-color-${labelClass}`}>{label}</div>
      <div className="risk-bar-container">
        <span
          className="flat-risk-high risk-bar"
          style={{ flexGrow: risks.high, display: risks.high ? 'inline' : 'none' }}
        >
          &nbsp;
        </span>
        <span
          className="flat-risk-medium risk-bar"
          style={{ flexGrow: risks.medium, display: risks.medium ? 'inline' : 'none' }}
        >
          &nbsp;
        </span>
        <span className="flat-risk-low risk-bar" style={{ flexGrow: risks.low }}>
          &nbsp;
        </span>
      </div>
    </div>
  );
})`
  display: flex;
  max-width: 130px;
  width: 100%;
  margin: 0 auto;
  justify-content: end;

  .risk-bar-container {
    width: 60px;
    height: 20px;
    margin: 0px 20px 0px 10px;
    display: flex;
  }

  .risk-bar {
    height: 14px;
    margin-top: 3px;
  }

  .flat-risk-low {
    background-color: #108904;
  }

  .flat-risk-medium {
    background-color: #db992f;
  }

  .flat-risk-high {
    background-color: #d32100;
  }

  .risk-color-low {
    color: #108904;
  }

  .risk-color-medium {
    color: #db992f;
  }

  .risk-color-high {
    color: #d32100;
  }
`;
