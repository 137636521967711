import React, { useEffect, useState } from 'react';
import { updateRiskModel, trackRiskModelChange } from 'admin/riskmodels/modules/riskmodels';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from 'emotion-theming';
import { Row, Col, Card, Typography, Form } from 'antd';
import { Label as Input, Button, BasicModal as Modal } from '@luxe/components';
import TotalCounter from './TotalCounter';

const RiskModelManagement = ({
  item,
  riskModels,
  updateRiskModel,
  trackRiskModelChange,
  isEditable,
  showWeightedScoreElement,
}) => {
  const { space, fontSizes, colors, itemAlignment } = useTheme();
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(item.name);

  useEffect(() => {
    setName(item.name);
  }, [item.name]);

  const updateName = () => {
    updateRiskModel({ name: name });
    setVisible(!visible);
  };

  return (
    <>
      <Row id="model-profile-management" style={{ marginBottom: `${space.xsmall}px` }}>
        <Card
          style={{
            width: '100%',
            margin: '15px',
            marginRight: 0,
            minHeight: '48px',
            backgroundColor: colors.BG1,
          }}
          bordered={false}
          title={
            <Row align="middle" gutter={16}>
              <Col style={{ ...itemAlignment.center, justifyContent: 'normal', width: 'auto', height: '48px' }}>
                <Text style={{ fontSize: `${fontSizes.heading}px`, whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                  {name}
                </Text>
              </Col>
              <Col>
                {isEditable && (
                  <Button
                    variant="link"
                    type="link"
                    onClick={() => {
                      setVisible(!visible);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          }
          extra={
            <Row align="middle" style={{ width: '25vw' }}>
              <Col span={6} style={{ left: '60px' }}>
                {showWeightedScoreElement() && (
                  <TotalCounter
                    total={
                      riskModels.RiskModel.indices &&
                      riskModels.RiskModel.indices.length &&
                      riskModels.RiskModel.indices
                        .map(x => x.weight)
                        .reduce((total, weight) => parseInt(total) + parseInt(weight))
                    }
                  />
                )}
              </Col>
            </Row>
          }
          headStyle={{
            border: 'none',
            lineHeight: '28px',
            fontSize: '20px',
            padding: '0 15px',
          }}
          bodyStyle={{ display: 'none' }}
        />
      </Row>
      <Modal
        visible={visible}
        title="Edit Risk Model Label"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => {
          setVisible(!visible);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(() => {
              updateName();
            })
            .catch(info => {
              console.error('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="edit_risk_model_label_form"
          initialValues={{
            modifier: 'public',
            name: name,
          }}
        >
          <Form.Item
            name="name"
            label={<label style={{ color: 'white' }}>Name</label>}
            rules={[
              {
                required: true,
                message: 'Please input the title of the Risk Model!',
              },
            ]}
          >
            <Input
              onChange={e => {
                setName(e.target.value);
                trackRiskModelChange(true);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

function mapStateToProps({ riskModels, router }) {
  return {
    riskModels,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRiskModel,
      trackRiskModelChange,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskModelManagement);
