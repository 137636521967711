import React from 'react';
import { Button } from '@luxe/components';
import { Row } from 'antd';
import styled from '@emotion/styled';

const NavButtons = styled(({ detailUrl, className }) => {
  return (
    <div className={className}>
      <Row type="flex" justify="space-between">
        <Button className="show-on-map" variant="primary" htmlType="button" href={detailUrl} target="_blank">
          Show On Map
        </Button>
      </Row>
    </div>
  );
})`
  .ant-row {
    margin-bottom: 5px;
    margin-left: -5px;
  }

  .show-on-map {
    padding-top: 5px;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    letter-spacing: 0em;
    text-align: center;
    width: 188px;
  }
`;
export default NavButtons;
