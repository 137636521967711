import { getViewData } from 'views/modules/views';

export function validateWorkflow(workflow) {
  const validationErrors = [];
  const items = workflow.families
    .filter(x => x.children)
    .flatMap(x => x.children)
    .filter(x => x.children)
    .flatMap(x => x.children)
    .filter(x => x.selected);

  const families = items.map(x => {
    return {
      family_id: x.id,
      risk_categories: x.riskCategories,
    };
  });

  if (families.length === 0) {
    validationErrors.push({
      message:
        'Please select at least one incident category (Network Incidents) or additional incidents category (Additional Incidents) to continue.',
      code: 'data.filtering.threats',
    });
  }

  if (workflow.data.recipients?.length === 0 && !workflow.data.channel.includes('webhooks')) {
    validationErrors.push({
      message: 'You have to select at least one recipient to continue.',
      code: 'data.recipients.threats',
    });
  }

  if (workflow.data.includes?.length === 0) {
    validationErrors.push({
      message: 'Please select at least one entity type (My Network) to continue.',
      code: 'data.network.include',
    });
  }

  if (
    !workflow.rules &&
    workflow.data.filtering.major_events === false &&
    workflow.data.filtering.industries.length === 0 &&
    workflow.data.filtering.global_events_categories.length === 0 &&
    !workflow.data.keywords
  ) {
    validationErrors.push({
      message:
        'Please create at least one filter rule (My Network) or enable additional incidents category (Additional Incidents) to continue.',
      code: 'data.filtering.threats',
    });
  }

  return {
    ...workflow,
    data: {
      ...workflow.data,
      filtering: {
        ...workflow.data.filtering,
        threats: undefined,
        families,
      },
    },
    validationErrors,
  };
}

export const getStatus = items => {
  if (items === undefined) {
    return undefined;
  }
  let activeCount = items.map(x => x.riskCategories).filter(x => x && x.length > 0).length;
  if (activeCount === items.length) {
    return 'all_selected';
  } else if (activeCount > 0) {
    return 'some_selected';
  }
  return 'none_selected';
};

export const getRiskCategory = children => {
  const childCats = (children || []).map(c => c.riskCategories).flat();
  return Array.from(new Set(childCats)).sort((a, b) => (a === 'low' ? -1 : a === 'high' ? 1 : 0));
};

export function getNestedFamily(families, familyId) {
  const find = items => {
    const found = items.find(x => x.id === familyId);
    if (found) {
      return found;
    } else {
      let found = undefined;
      items.forEach(item => {
        if (item.children) {
          const itemFound = find(item.children);
          if (itemFound) {
            found = itemFound;
          }
        }
      });
      return found;
    }
  };
  return find(families);
}

/* Updates one or more families within the tree of given items (families).
 *
 * If a given family matches the id, it is updated, and the changes are
 * propagated to its children.
 *
 * Sorry. No other legible way to do this. Hate the tree not me.
 */
export function updateNestedFamily(families, familyId, data) {
  const ids = typeof familyId === 'number' ? [familyId] : familyId;

  const update = items => {
    return items.map(x => {
      if (ids.includes(x.id)) {
        const children = x.children
          ? updateNestedFamily(
              x.children,
              x.children.map(x => x.id),
              data,
            )
          : x.children;
        if (data.riskCategories !== undefined) {
          if (data.riskCategories.length === 0) {
            return {
              ...x,
              ...data,
              riskCategories: [],
              selected: false,
              children,
              status: getStatus(children),
            };
          } else {
            return {
              ...x,
              ...data,
              riskCategories: data.riskCategories.map(y => y),
              selected: true,
              children,
              status: getStatus(children),
            };
          }
        } else if (data.selected !== undefined) {
          if (data.selected === true) {
            const noCategories = !x.riskCategories || x.riskCategories.length === 0;
            const riskCategories = noCategories ? ['medium', 'high'] : x.riskCategories;
            return {
              ...x,
              riskCategories,
              selected: true,
              children,
              status: getStatus(children),
            };
          } else {
            return {
              ...x,
              riskCategories: null,
              selected: false,
              children,
              status: getStatus(children),
            };
          }
        } else {
          return {
            ...x,
            ...data,
          };
        }
      } else if (x.children) {
        const children = update(x.children);
        const riskCategories = getRiskCategory(children);
        return {
          ...x,
          children,
          riskCategories: riskCategories.length ? riskCategories : x.riskCategories,
          status: getStatus(children),
        };
      } else {
        return x;
      }
    });
  };
  return update(families);
}

export function transformFamilies(workflow, families) {
  const { filtering = {} } = workflow.data;
  // This part handles backward compatibility when loading a
  // workflow that was configured with `threats` (phenomena).
  if (filtering.threats !== undefined) {
    const phenomena = families
      .filter(x => x.phenomena && x.phenomena.length)
      .flatMap(x =>
        x.phenomena.map(p => {
          return { [p.id]: x.id };
        }),
      );
    const phenomenaLookup = Object.assign({}, ...phenomena);

    if (filtering.families === undefined) {
      filtering.families = [];
    }
    filtering.threats.forEach(({ phenomena_id: phenomenaId, ...data }) => {
      const familyId = phenomenaLookup[phenomenaId];
      if (familyId && !filtering.families.map(x => x['family_id']).includes(familyId)) {
        filtering.families.push({ family_id: familyId, ...data });
      }
    });
    filtering.threats = undefined;
  }

  const { families: selectedFamilies = [] } = filtering;
  const getNode = (family, depth) => {
    const children =
      family.phenomena && family.phenomena.length === 0
        ? families
            .filter(x => x.parent_id === family.id)
            .map(child => {
              return getNode(child, depth + 1);
            })
            .filter(x => x !== false)
        : [];

    if (depth === 2 && children.length === 0) {
      // there should be three levels of families
      // any family at level one or two without children should not be returned
      // level one families without children are handled elsewhere
      return false;
    }

    let selected = false;
    let riskCategories = [];
    const status = getStatus(children);

    if (filtering.families !== undefined && depth === 3) {
      const item = selectedFamilies.find(x => x.family_id === family.id);
      if (item) {
        selected = item.risk_categories?.length ? true : false;
        riskCategories = item.risk_categories;
      }
    } else if (Array.isArray(children) && children.length) {
      selected = status === 'none_selected' ? false : true;
      riskCategories = getRiskCategory(children);
    }

    const phenomena = depth === 3 ? family.phenomena : [];
    return {
      ...family,
      phenomena,
      children,
      selected,
      riskCategories,
      status,
    };
  };
  const tree = families
    .filter(x => x.parent_id == null && x.meta.reactive)
    .map(x => {
      return getNode(x, 1);
    });
  return tree;
}

export function setFamilies(workflow, families) {
  const { filtering = {} } = workflow.data;
  const { threats } = filtering;

  if (!threats) {
    return families;
  }

  const values = Object.assign(
    {},
    ...threats.map(({ phenomena_id: id, risk_categories: riskCategories }) => {
      return { [id]: riskCategories };
    }),
  );

  return families.map(family => {
    const phenomena = family.phenomena.map(x => {
      const riskCategories = values[x.id] || [];
      const selected = riskCategories && riskCategories.length > 0;
      return {
        ...x,
        riskCategories,
        selected,
      };
    });

    const status = getStatus(phenomena);
    return {
      ...family,
      phenomena,
      status,
    };
  });
}

export function fetchAssets({ selectedGroup, searchTerm, currentPage }, filters, isRuleFilter = false) {
  const pageOffset = (currentPage - 1) * 10;
  const query = {
    columns: ['asset', 'id', 'org_type', 'location'],
    url: 'assets',
    filters: [],
    limit: 10,
    offset: pageOffset,
  };
  let filterVal;
  if (filters) {
    for (let filter of filters) {
      if (isRuleFilter && filter.operator === 'in') {
        filterVal = filter.value.map(v => v.value);
      } else {
        filterVal = filter.value[0].value;
      }
      query.filters.push({ name: filter.name, operator: filter.operator, value: filterVal });
    }
  }

  if (selectedGroup && selectedGroup.id !== 'all') {
    query.filters.push({ name: 'group_id', operator: '==', value: selectedGroup.id });
  }
  if (searchTerm) {
    query.filters.push({ name: 'query', operator: '==', value: searchTerm });
  }
  return getViewData('assets', query);
}

export function updateFamilyRisk(families, familyId, data) {
  const update = items => {
    return items.map(x => {
      const children = x?.children?.length
        ? updateFamilyRisk(
            x.children,
            x.children.map(x => x.id),
            data,
          )
        : x?.children;
      if (data !== undefined && data?.riskType) {
        if (data?.riskTypeValue) {
          return {
            ...x,
            riskCategories: [...new Set([...x.riskCategories, data?.riskType])],
            selected: true,
            children,
            status: getStatus(children),
          };
        } else {
          return {
            ...x,
            riskCategories: x.riskCategories.filter(val => val !== data?.riskType),
            selected: true,
            children,
            status: getStatus(children),
          };
        }
      } else {
        return x;
      }
    });
  };
  return update(families);
}

const riskTypeOrder = { low: 1, medium: 2, high: 3 };

export const sortRiskCategoryData = arr => {
  const sortedArr = arr?.length
    ? arr.sort((a, b) => {
        return riskTypeOrder[a] - riskTypeOrder[b];
      })
    : [];
  return sortedArr;
};
