import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from '@luxe/components';
import styled from '@emotion/styled';

export default class WorkflowFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    const StyledWarningOutlined = styled(WarningOutlined)`
      padding-left: 5px;
    `;
    let url = '/admin/workflows/' + this.props.value.id + '/';
    return (
      <div>
        <Link to={url}>{this.props.value.name}</Link>
        <Tooltip
          placement="right"
          title="This workflow configuration is incomplete as there is no channel configured to send notifications in its current state."
        >
          {!this.props.value.is_configured ? <StyledWarningOutlined /> : null}
        </Tooltip>
      </div>
    );
  }
}
