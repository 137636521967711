import { FilterRangePills, Section } from '@luxe/components';
import { operators as Ops, dateRange } from 'constants/application';
import React, { useEffect, useRef, useState } from 'react';
import { buildActionsColumn, getParams } from 'views/utils';
import { getViewData, getViewMeta } from 'views/modules/views';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Col } from 'antd';
import IncidentHistoryModal from 'assets/modals/IncidentHistoryModal';
import ShareIncidentModal from 'assets/modals/ShareIncidentModal';
import { ViewTable } from 'views/components';

const RiskIncidentsTable = () => {
  const { region } = useSelector(state => state.regions);
  const countryId = region?.id;

  const location = useLocation();
  const history = useHistory();
  const currentSearch = useRef(null);
  const views = useSelector(store => store.views);
  const dispatch = useDispatch();

  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [isShareIncidentModalVisible, setIsShareIncidentModalVisible] = useState(false);
  const [isIncidentHistoryModalVisible, setIsIncidentHistoryModalVisible] = useState(false);

  const assetThreats = views?.['threats/assets'] || {};
  const firstLoad = assetThreats.currentUrl === undefined;
  const meta = assetThreats.meta;

  const actionButtons = [
    {
      icon: 'ShareAltOutlined',
      title: 'Share Incident',
      action: selectedId => {
        setSelectedIncidentId(selectedId);
        setIsShareIncidentModalVisible(true);
      },
    },
    {
      icon: 'HistoryOutlined',
      title: 'Incident History',
      action: selectedId => {
        setSelectedIncidentId(selectedId);
        setIsIncidentHistoryModalVisible(true);
      },
    },
  ];

  useEffect(() => {
    const overrides = {
      assets: {
        description: 'Risk',
      },
      status: {
        type: 'default_badge',
      },
      threat: {
        description: 'Incidents',
        type: 'base_threat',
      },
    };
    if (firstLoad) {
      dispatch(getViewMeta('threats/assets', overrides));
    }
  }, [dispatch, firstLoad]);

  useEffect(() => {
    const searchChanged = location.search !== currentSearch.current;
    const pagePathname = location.pathname.split('/')[3];
    const shouldLoad = meta && (firstLoad || searchChanged);
    if (location.search === '' && !meta?.current) {
      history.push({
        pathname: location.pathname,
        search: 'order_by=-date_updated&limit=10',
      });
    } else if (shouldLoad && pagePathname === 'incidents') {
      const params = getParams(location.search);
      params.limit = 10;
      currentSearch.current = location.search;
      if (!params.order_by?.length > 0 && location.search === 'order_by=-date_updated&limit=10') {
        params.order_by.push({ name: 'date_updated', direction: 'desc' });
      }
      params.filters = params.filters.concat([{ name: 'country_id', operator: Ops.in, value: [countryId] }]);
      const query = {
        columns: ['threat', 'category', 'assets', 'date_reported', 'date_updated', 'duration', 'status'],
        ...params,
        url: window.location.href,
        filter_threats: false,
      };
      dispatch(getViewData('threats/assets', query));
    }
  }, [dispatch, firstLoad, meta, location, countryId, history]);

  const onTabClicked = range => {
    if (range.title === dateRange.all.title) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    } else if ([dateRange.last7Days.title, dateRange.last30Days.title].includes(range.title)) {
      history.push({
        pathname: location.pathname,
        search: `?threat__start__gte=${range.startDate}`,
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: `?threat__start__between=${range.startDate},${range.endDate}`,
      });
    }
  };

  return (
    <>
      <Section title={'Incidents'}>
        <Col style={{ width: '100%', marginBottom: '10px' }}>
          <FilterRangePills onTabClick={onTabClicked} heading="Date Range" />
        </Col>
        <div style={{ width: '100%' }}>
          <ViewTable
            title="Incidents"
            data={assetThreats}
            allowFilterOptions={false}
            actions={[]}
            rowSelection={false}
            selectAll={() => {}}
            rowKey={row => `${row.threat.id}-${row.threat.name}`}
            showFilters={false}
            includes={['threat__start']}
            push={history.push}
            actionColumn={buildActionsColumn(actionButtons, 'threat.id')}
            size={'small'}
            sorted={true}
            paramsToKeep={['threat__start__gte', 'threat__start__between']}
          />
        </div>
      </Section>
      <ShareIncidentModal
        isModalVisible={isShareIncidentModalVisible}
        closeModal={() => setIsShareIncidentModalVisible(false)}
        incidentId={selectedIncidentId}
      />
      <IncidentHistoryModal
        isModalVisible={isIncidentHistoryModalVisible}
        closeModal={() => setIsIncidentHistoryModalVisible(false)}
        incidentId={selectedIncidentId}
      />
    </>
  );
};

export default RiskIncidentsTable;
