import extreme from 'feedback/icons/extreme.png';
import unknown from 'feedback/icons/unknown.png';
import minor from 'feedback/icons/minor.png';
import moderate from 'feedback/icons/moderate.png';
import severe from 'feedback/icons/severe.png';
import moment from 'moment';

export const operators = {
  exact: '==',
  exclude: '!=',
  in: 'in',
  in_any: 'in_any',
  gte: '>=',
  lte: '<=',
  lt: '<',
  gt: '>',
  icontains: 'icontains',
  between: 'between',
};

export const dateRange = {
  all: {
    title: 'All',
  },
  today: {
    title: 'Today',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  },
  yesterday: {
    title: 'Yesterday',
    startDate: moment()
      .subtract(1, 'd')
      .format('YYYY-MM-DD'),
    endDate: moment()
      .subtract(1, 'd')
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  },
  last7Days: {
    title: 'Last 7 Days',
    startDate: moment()
      .subtract(7, 'd')
      .format('YYYY-MM-DD'),
  },
  last30Days: {
    title: 'Last 30 Days',
    startDate: moment()
      .subtract(30, 'd')
      .format('YYYY-MM-DD'),
  },
};

export const severityLevels = {
  EXTREME: extreme,
  UNKNOWN: unknown,
  SEVERE: severe,
  MODERATE: moderate,
  MINOR: minor,
};

export const riskLevels = {
  all: {
    title: 'All',
    value: 'all',
  },
  low: {
    title: 'Low',
    value: 'low',
  },
  medium: {
    title: 'Medium',
    value: 'medium',
  },
  high: {
    title: 'High',
    value: 'high',
  },
};
