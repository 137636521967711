import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getParams } from 'views/utils';
import { getAllViewData, getViewMeta } from 'views/modules/views';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchTable } from '@luxe/components/src/Table';
import { getFinalLoc, getFlowIcon, getNextPrevLoc, nextPrevNodeSorter } from './utils';
import { stringSorter } from 'utils';

function AssetMaterialSection() {
  const params = useParams();
  const { id: assetId } = params;
  const location = useLocation();
  const currentAssetID = useRef(assetId);
  const materialFlows = useSelector(store => store.views?.['material-flows'] || {});
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const firstLoad = materialFlows.currentUrl === undefined;
  const meta = materialFlows.meta;

  useEffect(() => {
    if (firstLoad) {
      dispatch(getViewMeta('material-flows'));
    }
  }, [dispatch, firstLoad]);

  const rowData = useMemo(() => {
    if (!searchTerm) {
      return materialFlows.items;
    } else {
      return materialFlows.items.filter(row => {
        return row.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
      });
    }
  }, [materialFlows, searchTerm]);

  useEffect(() => {
    const assetIDChanged = assetId !== currentAssetID.current;
    const shouldLoad = meta && (firstLoad || assetIDChanged);
    if (shouldLoad) {
      const filterValue = [
        {
          type: 'to_node',
          value: assetId,
        },
        {
          type: 'from_node',
          value: assetId,
        },
      ];
      const params = getParams(location.search);
      delete params.limit;
      currentAssetID.current = assetId;
      params.order_by = [{ name: 'name', direction: 'asc' }];
      const query = {
        columns: [
          'name',
          'mid',
          'smid',
          'product',
          'tier',
          'from_node',
          'to_node',
          'final_node',
          'next_material',
          'material_id',
          'id',
        ],
        ...params,
        url: 'material-flows',
        filters: [{ name: 'node', operator: '==', value: filterValue }],
      };
      dispatch(getAllViewData('material-flows', query));
    }
  }, [dispatch, firstLoad, meta, location.search, assetId]);

  const materialColumns = [
    {
      title: 'Flow',
      render: flow => getFlowIcon(assetId, flow),
      sorter: (a, b) => (a.from_node?.id === assetId) - (b.from_node?.id === assetId),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: 'Material #',
      dataIndex: 'mid',
      sorter: (a, b) => stringSorter(a.mid, b.mid),
    },
    {
      title: 'Next Step',
      dataIndex: ['next_material', 'name'],
      sorter: (a, b) => stringSorter(a.next_material.name, b.next_material.name),
    },
    {
      title: 'Next/Prev Loc',
      render: flow => getNextPrevLoc(assetId, flow),
      sorter: (a, b) => nextPrevNodeSorter(assetId, a, b),
    },
    {
      title: 'Final Location',
      render: flow => getFinalLoc(flow),
      sorter: (a, b) => stringSorter(a.final_node.name, b.final_node.name),
    },
    {
      title: 'Final Product',
      dataIndex: 'product',
      sorter: (a, b) => stringSorter(a.product, b.product),
    },
  ];

  return (
    <SearchTable
      title={'Material Flow'}
      description={'There are no Materials available'}
      table={'material-search-table'}
      rowKey={row => `${row.mid}-${row.smid}-${Math.random()}`}
      columns={materialColumns}
      rowData={rowData}
      onSearchChange={setSearchTerm}
      onPageChange={() => {}}
      paginationData={{ pending: materialFlows.pending }}
      isServerSide={false}
    />
  );
}

export default AssetMaterialSection;
