import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { operators as Ops } from 'constants/application';

import { getViewData, getViewMeta } from 'views/modules/views';
import { ViewTable } from 'views/components';
import { getMaterialSummary, downloadMaterial, TYPES as RISKFEED_TYPES } from 'riskfeed/modules/riskfeed';
import styled from '@emotion/styled';
import { ASSET_META_OVERRIDES } from '.';
import { Skeleton } from '@luxe/components';
import { getParams } from 'views/utils';
import { convertedNumber } from 'admin/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';
import { Tooltip } from 'antd';
import useMixpanel from 'utils/hooks/Mixpanel';

const Loading = () => {
  return (
    <div className="message">
      <Skeleton />
    </div>
  );
};
const MaterialsTab = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { summary, exportMaterial } = useSelector(state => state.riskfeed);
  const views = useSelector(store => store.views);
  const assets = views?.assets;
  const init = assets === undefined;
  const { id: threatId = null } = useParams();

  useEffect(() => {
    dispatch(getMaterialSummary(threatId));
  }, [dispatch, threatId]);

  useEffect(() => {
    if (init) {
      dispatch(getViewMeta('assets', ASSET_META_OVERRIDES));
    }
  }, [dispatch, init]);
  const { status, error } = summary;
  const { exportMaterialData } = exportMaterial;

  useEffect(() => {
    if (exportMaterialData) {
      dispatch({
        type: OPERATION_TYPES.SHOW_NOTIFICATION,
        payload: {
          content: 'An email will be sent to your inbox when the export is ready and will be available for 24 hours.',
          type: 'success',
        },
      });

      dispatch({
        type: RISKFEED_TYPES.RESET_EXPORT_MATERIALS_DATA,
      });
    }
  }, [exportMaterialData, dispatch]);

  const summaryLoading = !summary.materials;

  const hasMeta = assets?.meta?.allowed !== undefined;
  const { search: url } = useSelector(store => store.router.location);

  const query = useMemo(() => {
    if (!hasMeta) {
      return null;
    }
    const params = getParams(url, ['channel', 'mixpanel_event']);
    const filters = [
      ...params.filters,
      {
        name: 'threat_id',
        operator: Ops.exact,
        value: threatId,
      },
      {
        name: 'assets_with_materials',
        operator: Ops.exact,
        value: true,
      },
    ];

    return {
      columns: [
        'asset',
        'summary__materials_out',
        'summary__materials_final_product',
        'summary__materials_final_product_value',
      ],
      ...params,
      filters,
      limit: 10,
    };
  }, [hasMeta, threatId, url]);

  useEffect(() => {
    if (query !== null) {
      dispatch(getViewData('assets', query));
    }
  }, [dispatch, query]);

  const handleExport = () => {
    dispatch(downloadMaterial({ filters: [{ name: 'threat_id', operator: '==', value: threatId }] }));
    mixpanelTrack('Action Center - Materials Tab - Export');
  };

  const push = () => {};
  if (assets?.items === undefined) {
    return null;
  }
  const getRowId = row => row.asset.id;
  if ((status === 'PENDING' || summaryLoading) && !error) {
    return <Loading />;
  }
  return (
    <>
      <div className={`${props.className} aggregate`}>
        <table>
          <tbody>
            <tr>
              <th scope="col">OUTBOUND</th>
              <th scope="col">FINAL</th>
              <th scope="col">VALUE</th>
            </tr>
            <tr>
              <td className="data">
                <b>{summary.materials && summary.materials?.from !== null ? summary.materials?.from : '-'}</b>
              </td>
              <td className="data">
                {summary.materials && summary.materials?.final_products !== null
                  ? summary.materials?.final_products
                  : '-'}
              </td>
              <td className="data">
                {summary.materials && summary.materials?.final_product_value !== null
                  ? convertedNumber(Number(summary.materials?.final_product_value))
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>MATERIALS</td>
              <td>PRODUCTS</td>
              <td>OF PRODUCTS</td>
            </tr>
          </tbody>
        </table>
      </div>
      {assets?.items?.length ? (
        <div style={{ float: 'right', color: 'white', cursor: 'pointer', marginBottom: '5px' }}>
          <Tooltip title="Click to receive an export of the underlying Material Flow data via email.">
            <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleExport} />
          </Tooltip>
        </div>
      ) : null}

      <ViewTable data={assets} push={push} rowKey={getRowId} />
    </>
  );
})`
  &.aggregate {
    display: flex;
    justify-content: space-evenly;
    td,
    th {
      font-size: 10px;
      max-width: 125px !important;
      text-align: center;
      padding-right: 40px;
    }

    td.space {
      width: 143px;
    }

    .data {
      font-size: 30px;
    }
  }
`;

export default MaterialsTab;
