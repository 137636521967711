import { filter, map } from 'rxjs/operators';

import { TYPES, fetchEvents } from 'riskfeed/modules/riskfeed';

import { TYPES as PLAN_TYPES, getPlan, updatePlan } from 'riskfeed/modules/plans';

export const fetchThreatsEpic = (action$, state$) => {
  const refetchTypes = [TYPES.UN_TRACK_THREAT_SUCCESS, TYPES.TRACK_THREAT_SUCCESS];
  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => {
      const { channel_name } = state$.value.riskfeed.events;
      return fetchEvents({ channel: channel_name });
    }),
  );
};

export const fetchPlanEpic = (action$, state$) => {
  const types = [
    PLAN_TYPES.CREATE_EVENT_TASK_SUCCESS,
    PLAN_TYPES.UPDATE_EVENT_TASK_SUCCESS,
    PLAN_TYPES.DELETE_EVENT_TASK_SUCCESS,
  ];
  return action$.pipe(
    filter(action => types.includes(action.type)),
    map(action => {
      const { id } = state$.value.riskfeed.threat.item;
      const { tasks } = state$.value.plans;
      if (action.type === PLAN_TYPES.DELETE_EVENT_TASK_SUCCESS && !tasks.item?.length) {
        return updatePlan(id);
      }
      return getPlan(id);
    }),
  );
};

const epics = {
  fetchThreatsEpic,
  fetchPlanEpic,
};
export default epics;
