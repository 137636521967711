import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'layout/Select';

const CategorySelector = ({ ...props }) => {
  const { operator, name } = props.filter;
  const familyData = useSelector(store => store.families.families);

  const [options, setOptions] = React.useState([]);

  const multi = operator === 'in';

  React.useEffect(() => {
    if (familyData?.length) {
      let values = familyData.reduce((acc, val) => {
        return !val.parent_id ? [...acc, { label: val.name, value: val.id }] : [...acc];
      }, []);
      setOptions(values);
    }
  }, [familyData]);

  const onChange = value => {
    props.onChange({
      value,
      operator,
      name,
    });
  };
  const value = props.filter.value;

  return (
    <>
      <div>Category</div>
      <Select
        key={props.filter.name}
        isMulti={multi}
        stayOpen={multi}
        selected={value}
        onChange={onChange}
        options={options}
        backspaceRemoves
      />
    </>
  );
};

export default CategorySelector;
