import React from 'react';
import PropTypes from 'prop-types';

export default class PhenomenaFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
  };

  render() {
    return <span>{this.props.value.name}</span>;
  }
}
