import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Container, DetailViewContent } from '@luxe/components/src/Container';
import { MODAL_TYPES_KEYS, getGroupModalSettings } from '@luxe/components/src/GroupModal';
import { Button } from '@luxe/components/src/Button';
import { SearchTable } from '@luxe/components/src/Table';
import { arrangeIntoTree, treeToYAML } from 'admin/utils';
import { createUser } from 'admin/users/modules/users';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { BasicModal, TextArea } from '@luxe/components';
import { bulkUpdateAssetGroups, addAssetsToGroup } from 'admin/assetgroups/modules/assetgroups';
import UserModal, {
  MODAL_TYPES_KEYS as USER_MODAL_TYPES_KEYS,
} from 'admin/usergroups/containers/UserGroupsView/UserModal';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';

import AssetTable from 'feedback/containers/FeedbackResponseView/AssetTable';
import GroupTree from '@luxe/components/src/GroupTree';
import PropTypes from 'prop-types';
import YAML from 'yaml';

export const EMPTY_TREE_NODE = { title: '', parentId: undefined, id: undefined };
const ALL_GROUP_NODE = { key: 'all', title: 'All', id: 'all', children: [] };

const GroupView = ({
  assets,
  rowData,
  columns,
  title,
  groups,
  removeAssetsFromGroup,
  fetchData,
  assetGroupsTreeStatus,
  getAssets,
  assetsTableStatus,
  meta,
  fetchMeta,
  itemName,
  createGroupFunction,
  editGroupFunction,
  deleteGroupFunction,
  addItemsToGroupFunction,
  removeItemsFromGroupFunction,
  paginationData,
  isServerSide,
}) => {
  const dispatch = useDispatch();

  const account = useSelector(store => store.account);
  const user = account?.account;

  const { space } = useTheme();
  const [yamlEditor, setYamlEditor] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(ALL_GROUP_NODE);
  const [userModalType] = useState(USER_MODAL_TYPES_KEYS.ADD);
  const [isUserModalShowing, setIsUserModalShowing] = useState(false);
  const [groupsTree, setGroupsTree] = useState([]);
  const [groupsMap, setGroupsMap] = useState({});
  const [modalType, setModalType] = useState(MODAL_TYPES_KEYS.ADD);
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [assetGroupYaml, setAssetGroupYaml] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);

  const currentSearchTerm = useRef(searchTerm);
  const currentSelectedGroup = useRef(selectedGroup);
  const currentOrderBy = useRef('');
  const currentPage = useRef(1);
  const firstLoad = useRef(true);
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    setSelectedItems([]);
  }, [selectedGroup]);

  useEffect(() => {
    if (groups) {
      setGroupsTree(arrangeIntoTree(groups, [ALL_GROUP_NODE]));
      setAssetGroupYaml(treeToYAML(groups));
      const arrangedGroups = [...groups, { id: 'all', name: title, path: 'all' }];
      const groupMap = arrangedGroups.reduce((obj, item) => {
        obj[item.id] = item.name;
        return obj;
      }, {});
      setGroupsMap(groupMap);
    }
  }, [groups, title]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      if (fetchMeta && !meta) {
        fetchMeta();
      }
    }
  }, [fetchMeta, selectedGroup, searchTerm, activePage, meta]);

  useEffect(() => {
    const searchTermChanged = currentSearchTerm.current !== searchTerm;
    const currentSelectedGroupChanged = currentSelectedGroup.current !== selectedGroup;
    const orderByChanged = JSON.stringify(orderBy) !== currentOrderBy.current;
    const currentPageChanged = currentPage.current !== activePage;
    const shouldLoad = searchTermChanged || currentSelectedGroupChanged || currentPageChanged || orderByChanged;
    if (shouldLoad && (meta || itemName === 'user')) {
      currentSearchTerm.current = searchTerm;
      currentSelectedGroup.current = selectedGroup;
      currentPage.current = activePage;
      currentOrderBy.current = JSON.stringify(orderBy);
      fetchData({ selectedGroup, searchTerm, currentPage: activePage, orderBy });
    }
  }, [fetchData, meta, searchTerm, selectedGroup, currentPage, activePage, orderBy, itemName]);

  useEffect(() => {
    if (assetGroupsTreeStatus === REQUESTED_DATA_STATUS.SUCCESS) {
      const getSelectedAssets = groupId => {
        const filters = groupId === ALL_GROUP_NODE.id ? [] : [{ name: 'group', value: groupId, operator: '==' }];
        getAssets({ filters });
      };
      getSelectedAssets(selectedGroup.id);
    }
  }, [selectedGroup, getAssets, assetGroupsTreeStatus]);

  const showModal = index => {
    setModalType(index);
    setShowGroupModal(true);
  };

  const closeModal = () => {
    setShowGroupModal(false);
  };

  const addGroup = (name, parent) => {
    dispatch(
      createGroupFunction({
        name: name,
        parent_id: parent?.value !== 'all' ? parent.value : null,
      }),
    );
    closeModal();
  };

  const bulkEditAssetGroups = yaml => {
    try {
      dispatch(bulkUpdateAssetGroups(YAML.parse(yaml)));
      setYamlEditor(false);
    } catch (err) {
      dispatch({
        type: OPERATION_TYPES.SHOW_NOTIFICATION,
        payload: {
          content: 'Not valid YAML',
          type: 'error',
        },
      });
    }
  };

  const addObjectsToGroup = (group_id, object_ids) => {
    addAssetsToGroup(group_id, { asset_ids: object_ids });
  };

  const addItemsToGroup = (groupId, items) => {
    if (title === 'Corporations') {
      items = selectedItems;
    }
    const viewDataParams = { selectedGroup, searchTerm, currentPage: activePage };
    dispatch(addItemsToGroupFunction(groupId, items, viewDataParams));
    setSelectedItems([]);
    closeModal();
  };

  const removeItemsFromGroup = (groupId, items) => {
    if (title === 'Corporations') {
      items = selectedItems;
    }
    const viewDataParams = { selectedGroup, searchTerm, currentPage: activePage };
    dispatch(removeItemsFromGroupFunction(groupId, items, viewDataParams));
    setSelectedItems([]);
    closeModal();
  };

  const showUserModal = index => {
    setModalType(index);
    setIsUserModalShowing(true);
  };

  const closeUserModal = () => {
    setIsUserModalShowing(false);
  };

  const unitOptions = {
    metric: {
      temp: 'C',
      length: 'kilometers',
      default: 'metric',
    },
    imperial: {
      temp: 'F',
      length: 'miles',
      default: 'imperial',
    },
  };

  const createUsers = ({
    name,
    email,
    admin,
    phone = '',
    password,
    type: { value: type } = {},
    units: { value: units } = {},
  }) => {
    const prefs = {
      units: (units && unitOptions[units]) || unitOptions['imperial'],
    };

    let userOptions = {
      name,
      email,
      phone,
      type,
      prefs,
    };

    if (type === 'user') {
      userOptions = { ...userOptions, password, send_welcome_email: true };
    }

    if ((selectedGroup.id && selectedGroup.id !== 'all') || selectedGroup.id === 0) {
      userOptions.user_group = selectedGroup.id;
    }

    if (admin) {
      userOptions.permissions = ['admin'];
    }
    dispatch(createUser(userOptions, rowData));
    closeUserModal();
  };

  const editGroup = (name, groupId, parent) => {
    dispatch(
      editGroupFunction(groupId, {
        name: name,
        parent_id: parent?.value ? parent.value : null,
      }),
    );
    setSelectedGroup({
      ...selectedGroup,
      title: name,
    });
    closeModal();
  };

  const deleteGroup = groupId => {
    dispatch(deleteGroupFunction(groupId));
    closeModal();
    setSelectedGroup(ALL_GROUP_NODE);
  };

  const groupModalSettings = getGroupModalSettings({
    modalType: modalType,
    group: selectedGroup,
    itemName: itemName,
    title,
    onAdd: addGroup,
    onAddToGroup: addItemsToGroup,
    onRemoveFromGroup: removeItemsFromGroup,
    onEdit: editGroup,
    onDelete: deleteGroup,
  });

  function buildGroupActionButtons() {
    if (selectedItems.length === 0) {
      return [];
    }

    const groupActionButtons = [
      <Button
        key={'add-items-to-group-button'}
        variant="secondary"
        icon={<PlusOutlined />}
        shape="round"
        onClick={() => showModal(MODAL_TYPES_KEYS.ADD_TO_GROUP)}
      />,
    ];

    if (selectedGroup.id !== 'all') {
      groupActionButtons.push(
        <Button
          key={'remove-items-from-group-button'}
          variant="secondary"
          icon={<CloseOutlined />}
          shape="round"
          onClick={() => showModal(MODAL_TYPES_KEYS.REMOVE_FROM_GROUP)}
        />,
      );
    }
    return groupActionButtons;
  }
  const modalName = title === 'Corporations' ? 'Add Corporation' : 'Add Group';
  return (
    <>
      <DetailViewContent style={{ margin: `${space.none} ${space.small}px`, height: '100%' }}>
        <h1>{title}</h1>
        <Container>
          <Row>
            <Col span={6}>
              <div style={{ marginBottom: '10px' }}>
                {title === 'Facilities & Groups' &&
                (user.email.includes('@everstream.ai') || user.email.includes('@riskpulse.com')) ? (
                  <Button
                    variant="primary"
                    style={{ width: '100%', textOverflow: 'ellipsis', margin: '4px 0' }}
                    icon={<PlusOutlined />}
                    shape="round"
                    onClick={() => {
                      setYamlEditor(true);
                    }}
                  >
                    Import
                  </Button>
                ) : null}
                <Button
                  style={{ width: '100%', textOverflow: 'ellipsis', margin: '4px 0' }}
                  variant="primary"
                  icon={<PlusOutlined />}
                  shape="round"
                  onClick={() => {
                    setSelectedGroup(ALL_GROUP_NODE);
                    showModal(MODAL_TYPES_KEYS.ADD);
                  }}
                >
                  {modalName}
                </Button>
                {itemName === 'user' && (
                  <>
                    <Button
                      style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', margin: '4px 0' }}
                      variant="primary"
                      icon={<PlusOutlined />}
                      shape="round"
                      onClick={() => {
                        showUserModal(USER_MODAL_TYPES_KEYS.ADD);
                      }}
                    >
                      Add User
                    </Button>
                    <UserModal
                      modalType={userModalType}
                      isModalShowing={isUserModalShowing}
                      onAdd={createUsers}
                      onCancel={() => closeUserModal()}
                    />
                  </>
                )}
                <Col span={18}>
                  <AssetTable
                    showModal={showModal}
                    onremoveAssetsFromGroup={removeAssetsFromGroup}
                    assets={assets}
                    status={assetsTableStatus}
                  />
                </Col>
              </div>
              <GroupTree
                showModal={showModal}
                treeData={groupsTree}
                onTreeNodeSelect={setSelectedGroup}
                onTreeNodeFocus={setSelectedGroup}
                selectedTreeNode={selectedGroup}
                addObjectsToGroup={addObjectsToGroup}
                defaultTreeNode={ALL_GROUP_NODE}
                getObjectId={object => object.id}
                checkable={false}
                isLoading={false}
              />
            </Col>
            <Col span={17} style={{ marginLeft: '20px' }}>
              <SearchTable
                title={selectedGroup.title}
                rowData={rowData}
                itemName={itemName}
                columns={columns}
                rowKey={'id'}
                selectable={true}
                onSelect={items => {
                  const fullItems = items.map(item => rowData.find(row => row.id === item));
                  var filteredSelectedIds = selectedItems.filter(
                    selected => !rowData.find(row => row.id === selected.id),
                  );
                  setSelectedItems([...filteredSelectedIds, ...fullItems]);
                }}
                onSearchChange={setSearchTerm}
                onPageChange={setActivePage}
                actionButtons={buildGroupActionButtons()}
                actionText={
                  selectedItems.length > 0
                    ? `${selectedItems.length} ${itemName === 'asset' ? 'facility' : itemName}(s):`
                    : ''
                }
                selectedRows={selectedItems.map(item => item.id)}
                paginationData={paginationData}
                isServerSide={isServerSide}
                onSort={orderBy => {
                  setOrderBy(orderBy);
                }}
              />
            </Col>
          </Row>
          <groupModalSettings.bodyWidget
            groups={groups}
            checkedItems={selectedItems}
            rowData={rowData}
            group={selectedGroup}
            groupsMap={groupsMap}
            modalType={modalType}
            isModalShowing={showGroupModal}
            itemName={itemName}
            modalSettings={groupModalSettings}
            onCancel={closeModal}
          />
          <BasicModal
            visible={yamlEditor}
            onCancel={() => setYamlEditor(false)}
            onOk={() => bulkEditAssetGroups(assetGroupYaml)}
          >
            <div className="modal-content">
              <TextArea
                value={assetGroupYaml.toString()}
                autoSize={true}
                onChange={e => setAssetGroupYaml(e.target.value)}
              />
              <p>
                {' '}
                Validate your YAML before submitting it. Validate <a href="http://www.yamllint.com/">here</a>{' '}
              </p>
            </div>
          </BasicModal>
        </Container>
      </DetailViewContent>
    </>
  );
};

export default GroupView;

GroupView.propTypes = {
  rowData: PropTypes.array,
  columns: PropTypes.array,
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
  groupsURL: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  itemName: PropTypes.string.isRequired,
  createGroupFunction: PropTypes.func.isRequired,
  editGroupFunction: PropTypes.func.isRequired,
  deleteGroupFunction: PropTypes.func.isRequired,
  isServerSide: PropTypes.bool,
};

GroupView.defaultProps = {
  rowData: [],
  columns: [],
  isServerSide: true,
  meta: undefined,
};
