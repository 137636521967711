import React from 'react';
import L from 'leaflet';

import incident_triangle_amber from 'layout/icons/incident_triangle_amber.svg';
import incident_triangle_green from 'layout/icons/incident_triangle_green.svg';
import incident_triangle_red from 'layout/icons/incident_triangle_red.svg';

import aviationUrl, { ReactComponent as AviationIcon } from 'layout/icons/incident_category-aviation.svg';
import cargoUrl, { ReactComponent as CargoIcon } from 'layout/icons/incident_category-cargo.svg';
import civilUnrestUrl, { ReactComponent as CivilUnrestIcon } from 'layout/icons/incident_category-civil_unrest.svg';
import cyberUrl, { ReactComponent as CyberIcon } from 'layout/icons/incident_category-cyber.svg';
import fireUrl, { ReactComponent as FireIcon } from 'layout/icons/incident_category-fire.svg';
import hazmatUrl, { ReactComponent as HazmatIcon } from 'layout/icons/incident_category-hazmat.svg';
import healthUrl, { ReactComponent as HealthIcon } from 'layout/icons/incident_category-health.svg';
import laborUrl, { ReactComponent as LaborIcon } from 'layout/icons/incident_category-labor.svg';
import incidentsUrl, { ReactComponent as IncidentsIcon } from 'layout/icons/incident_category-manual_incidents.svg';
import maritimeUrl, { ReactComponent as MaritimeIcon } from 'layout/icons/incident_category-maritime.svg';
import otherUrl, { ReactComponent as OtherIcon } from 'layout/icons/incident_category-other.svg';
import productionUrl, { ReactComponent as ProductionIcon } from 'layout/icons/incident_category-production.svg';
import regulatoryUrl, { ReactComponent as RegulatoryIcon } from 'layout/icons/incident_category-regulatory.svg';
import securityUrl, { ReactComponent as SecurityIcon } from 'layout/icons/incident_category-security.svg';
import structuralUrl, { ReactComponent as StructuralIcon } from 'layout/icons/incident_category-structural.svg';
import terrorismUrl, { ReactComponent as TerrorismIcon } from 'layout/icons/incident_category-terrorism.svg';
import warUrl, { ReactComponent as WarIcon } from 'layout/icons/incident_category-war.svg';
import weatherUrl, { ReactComponent as WeatherIcon } from 'layout/icons/incident_category-weather.svg';
import groundTransportationUrl, {
  ReactComponent as GroundTransportationIcon,
} from 'layout/icons/incident_category-ground_transportation.svg';
import infrastructureUrl, {
  ReactComponent as InfrastructureIcon,
} from 'layout/icons/incident_category-infrastructure.svg';
import naturalDisasterUrl, {
  ReactComponent as NaturalDisasterIcon,
} from 'layout/icons/incident_category-natural_disaster.svg';
import publicHolidaysUrl, {
  ReactComponent as PublicHolidaysIcon,
} from 'layout/icons/incident_category-public_holidays.svg';

export const getIncidentIcon = category => {
  const icons = {
    aviation: { Component: AviationIcon, url: aviationUrl },
    cargo: { Component: CargoIcon, url: cargoUrl },
    civil_unrest: { Component: CivilUnrestIcon, url: civilUnrestUrl },
    cyber: { Component: CyberIcon, url: cyberUrl },
    fire: { Component: FireIcon, url: fireUrl },
    ground_transportation: { Component: GroundTransportationIcon, url: groundTransportationUrl },
    hazmat: { Component: HazmatIcon, url: hazmatUrl },
    health: { Component: HealthIcon, url: healthUrl },
    infrastructure: { Component: InfrastructureIcon, url: infrastructureUrl },
    labor: { Component: LaborIcon, url: laborUrl },
    incidents: { Component: IncidentsIcon, url: incidentsUrl },
    maritime: { Component: MaritimeIcon, url: maritimeUrl },
    natural_disaster: { Component: NaturalDisasterIcon, url: naturalDisasterUrl },
    other: { Component: OtherIcon, url: otherUrl },
    production: { Component: ProductionIcon, url: productionUrl },
    public_holidays: { Component: PublicHolidaysIcon, url: publicHolidaysUrl },
    regulatory: { Component: RegulatoryIcon, url: regulatoryUrl },
    security: { Component: SecurityIcon, url: securityUrl },
    structural: { Component: StructuralIcon, url: structuralUrl },
    terrorism: { Component: TerrorismIcon, url: terrorismUrl },
    war: { Component: WarIcon, url: warUrl },
    weather: { Component: WeatherIcon, url: weatherUrl },
  };
  return category && icons[category] ? icons[category] : OtherIcon;
};

const severityIcons = {
  extreme: incident_triangle_red,
  severe: incident_triangle_red,
  moderate: incident_triangle_amber,
  unknown: incident_triangle_amber,
  minor: incident_triangle_green,
};

export const getIncidentSeverityIcon = level => {
  return level && severityIcons[level] ? severityIcons[level] : incident_triangle_amber;
};

export const IncidentIcon = ({ type }) => {
  const Icon = (getIncidentIcon(type) || OtherIcon).Component;
  return <Icon />;
};

export const getIncidentMapIcon = (level, category) => {
  const getIconHtml = () =>
    `<img class="incident-icon-background" src="${getIncidentSeverityIcon(level)}"/>
    <img class="incident-category-icon" src="${getIncidentIcon(category).url}"/>`;

  return L.divIcon({
    html: getIconHtml(),
    iconSize: [28, 28],
    className: 'incident-icon',
  });
};
