import React, { useEffect } from 'react';
import { getRiskModel, setActiveRiskModel } from 'admin/riskmodels/modules/riskmodels';
import { useDispatch, useSelector } from 'react-redux';

import RiskModelButton from 'assets/containers/AssetDetailView/components/RiskModelButton';
import { Col, Row } from 'antd';
import { Section } from '@luxe/components';
import SliderRiskChart from 'assets/containers/OverallRiskCharts';
import SubIndexCharts from 'assets/containers/AssetDetailView/AssetRiskSection/SubIndexCharts';
import { getRiskHistory } from 'assets/modules/riskhistory';

function AssetRiskSection(props) {
  const riskHistory = useSelector(store => store.riskhistory)?.riskHistory;
  const riskModel = useSelector(store => store.riskModels.RiskModel);
  const riskModels = useSelector(store => store.riskModels)?.RiskModels || [];
  const proactiveRisk = useSelector(store => store.riskModels)?.proactiveRisk;
  const activeRiskModel = useSelector(store => store.riskModels)?.activeRiskModel;
  const asset = useSelector(store => store.assets.Asset);
  const dispatch = useDispatch();

  useEffect(() => {
    if (asset?.id && activeRiskModel?.id) {
      dispatch(getRiskModel({ id: activeRiskModel.id }));
      dispatch(getRiskHistory({ assetId: asset.id, riskModelId: activeRiskModel.id }));
    }
  }, [activeRiskModel, asset, dispatch]);

  function buildReadyModelButtons(proactiveScores) {
    return proactiveScores.map(score => {
      return (
        <RiskModelButton
          key={score.model_id}
          id={score.model_id}
          name={score.model_name}
          score={score.score}
          category={score.category}
          setActiveRiskModel={id => dispatch(setActiveRiskModel(id, 'assets'))}
          isActive={activeRiskModel.id === score.model_id}
        />
      );
    });
  }

  function buildPendingModelButtons(riskModelIds, proactiveScoreIds) {
    const riskModelMap = riskModels.reduce(function(accum, model) {
      accum[model.id] = model;
      return accum;
    }, {});

    return riskModelIds
      .map(riskModelId => {
        if (proactiveScoreIds.indexOf(riskModelId) === -1) {
          const riskModel = riskModelMap[riskModelId] || {};
          const riskModelName = riskModel?.name || '-';
          return (
            <RiskModelButton key={riskModelId} id={riskModelId} name={riskModelName} score={'-'} category={'low'} />
          );
        }
        return null;
      })
      .filter(x => x);
  }

  function buildRiskModelButtons() {
    if (asset?.proactive_scores && (asset.proactive_scores.length > 1 || asset.risk_model_ids.length > 1)) {
      const sortedScores = asset.proactive_scores.sort(
        (a, b) => asset.risk_model_ids.indexOf(a.model_id) - asset.risk_model_ids.indexOf(b.model_id),
      );
      const scoreButtons = buildReadyModelButtons(sortedScores);
      const proactiveScoreIds = asset.proactive_scores.map(score => {
        return score.model_id;
      });

      const pendingButtons = buildPendingModelButtons(asset.risk_model_ids, proactiveScoreIds);

      return scoreButtons.concat(pendingButtons);
    }
    return [];
  }

  return riskModel || activeRiskModel.type === 'assets' ? (
    <Section title={'Location Risk Details'} buttons={buildRiskModelButtons()}>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '25%' }}>
          <SliderRiskChart proactiveRisk={proactiveRisk} riskHistory={riskHistory} isEkg={props?.isEkg} />
        </Col>
        <Col style={{ width: '75%' }}>
          <SubIndexCharts
            key={'subindex-charts'}
            risk={proactiveRisk}
            riskHistory={riskHistory}
            riskModel={riskModel}
            isEkg={props?.isEkg}
          />
        </Col>
      </Row>
    </Section>
  ) : (
    <Row style={{ width: '100%', justifyContent: 'center', height: '200px', display: 'flex', alignItems: 'center' }}>
      No Risk Configured
    </Row>
  );
}

export default AssetRiskSection;
