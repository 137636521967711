import { filter, map } from 'rxjs/operators';
import { TYPES as RMTYPES, getRiskModel } from 'admin/riskmodels/modules/riskmodels';

export const getCountryRiskModelEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => action.type === RMTYPES.GET_PROACTIVE_COUNTRY_RISK_SUCCESS),
    map(() => {
      const { id } = state$.value.riskModels.activeRiskModel;
      return getRiskModel({ id });
    }),
  );
};

const epics = {
  getCountryRiskModelEpic,
};
export default epics;
