import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import moment from 'moment';

export const TYPES = {
  GET_CHANNEL_EVENTS: 'GET_CHANNEL_EVENTS',
  GET_CHANNEL_EVENTS_ERROR: 'GET_CHANNEL_EVENTS_ERROR',
  GET_CHANNEL_EVENTS_SUCCESS: 'GET_CHANNEL_EVENTS_SUCCESS',
  GET_PAGINATION_CHANNEL_EVENTS: 'GET_PAGINATION_CHANNEL_EVENTS',
  GET_PAGINATION_CHANNEL_EVENTS_ERROR: 'GET_PAGINATION_CHANNEL_EVENTS_ERROR',
  GET_PAGINATION_CHANNEL_EVENTS_SUCCESS: 'GET_PAGINATION_CHANNEL_EVENTS_SUCCESS',
  GET_MANUALLY_TRACKED_INCIDENTS: 'GET_MANUALLY_TRACKED_INCIDENTS',
  GET_MANUALLY_TRACKED_INCIDENTS_ERROR: 'GET_MANUALLY_TRACKED_INCIDENTS_ERROR',
  GET_MANUALLY_TRACKED_INCIDENTS_SUCCESS: 'GET_MANUALLY_TRACKED_INCIDENTS_SUCCESS',
};

export const INITIAL_STATE = {};

const DEFAULT_FILTERS = {
  type: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment()
    .add(1, 'days')
    .format('YYYY-MM-DD'),
  family: '',
  severity: '',
  search: '',
  hiddenStatus: '',
};

export const getChannelEvents = (channelType, userFilters) => {
  const channel = channelType || '';
  const filters = { ...DEFAULT_FILTERS, ...userFilters };
  return callApi({
    types: {
      pending: TYPES.GET_CHANNEL_EVENTS,
      success: TYPES.GET_CHANNEL_EVENTS_SUCCESS,
      error: TYPES.GET_CHANNEL_EVENTS_ERROR,
    },
    request: () =>
      axios.get(
        `account/user/channels/events?channel_type=${channel}&start=${filters.startDate}&end=${filters.endDate}&family=${filters.family}&severity=${filters.severity}&search=${filters.search}&show_hidden=${filters.hiddenStatus}`,
      ),
  });
};

export const getPaginatedChannelEvents = path => {
  return callApi({
    types: {
      pending: TYPES.GET_PAGINATION_CHANNEL_EVENTS,
      success: TYPES.GET_PAGINATION_CHANNEL_EVENTS_SUCCESS,
      error: TYPES.GET_PAGINATION_CHANNEL_EVENTS_ERROR,
    },
    request: () => axios.get(path),
  });
};

export const getManuallyTrackedEvents = userFilters => {
  const filters = { ...DEFAULT_FILTERS, ...userFilters };
  return callApi({
    types: {
      pending: TYPES.GET_MANUALLY_TRACKED_INCIDENTS,
      success: TYPES.GET_MANUALLY_TRACKED_INCIDENTS_SUCCESS,
      error: TYPES.GET_MANUALLY_TRACKED_INCIDENTS_ERROR,
    },
    request: () =>
      axios.get(
        `account/user/channels/manual-tracked?start=${filters.startDate}&end=${filters.endDate}&severity=${filters.severity}&search=${filters.search}&show_hidden=${filters.hiddenStatus}`,
      ),
  });
};

export const channelEventsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.GET_CHANNEL_EVENTS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_CHANNEL_EVENTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        events: action.payload,
      };
    case TYPES.GET_CHANNEL_EVENTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_PAGINATION_CHANNEL_EVENTS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_PAGINATION_CHANNEL_EVENTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        events: action.payload,
      };
    case TYPES.GET_PAGINATION_CHANNEL_EVENTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_MANUALLY_TRACKED_INCIDENTS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_MANUALLY_TRACKED_INCIDENTS_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        manually_tracked_events: action.payload,
      };
    case TYPES.GET_MANUALLY_TRACKED_INCIDENTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default channelEventsReducer;
