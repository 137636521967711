import React, { useEffect } from 'react';
import { BasicModal as Modal, CreatableSelect, Label as Input, StyledForm, TextArea } from '@luxe/components';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { getAllUsers } from 'admin/users/modules/users';
import { useDispatch, useSelector } from 'react-redux';
import { getIncident, shareIncident } from 'assets/modules/incidents';

function ShareIncidentModal({ isModalVisible, closeModal, incidentId }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const users = useSelector(store => store.users.users);
  const incident = useSelector(store => store.incidents.incident);

  useEffect(() => {
    if (incidentId && isModalVisible) {
      dispatch(getAllUsers());
      dispatch(getIncident(incidentId));
    }
  }, [dispatch, incidentId, isModalVisible]);

  useEffect(() => {
    if (form && incident.properties?.headline) {
      form.setFieldsValue({
        subject: incident.properties.headline,
      });
    }
  }, [form, incident]);

  return (
    <Modal
      visible={isModalVisible}
      title="Share Incident"
      okText="Send"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            values.recipients = values.recipients.map(recipient => recipient.value);
            values.user_comment = values.user_comment || '';
            dispatch(shareIncident(incidentId, values));
            form.resetFields();
            closeModal();
          })
          .catch(info => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <StyledForm form={form} layout="vertical">
        <Form.Item
          name="recipients"
          label={<label style={{ color: 'white' }}>Recipient(s)</label>}
          rules={[
            {
              required: true,
              message: 'Please enter recipients to share this incident with!',
            },
          ]}
        >
          <CreatableSelect
            isMulti={true}
            options={users.map(user => {
              return {
                label: user.name,
                value: user.email,
              };
            })}
            styles={{
              input: (baseStyles, state) => ({
                ...baseStyles,
                color: 'white',
              }),
            }}
          />
        </Form.Item>
        <Form.Item
          name="subject"
          label={<label style={{ color: 'white' }}>Subject</label>}
          rules={[
            {
              required: true,
              message: 'Please enter a subject!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="user_comment" label={<label style={{ color: 'white' }}>Comments</label>} rules={[]}>
          <TextArea />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default ShareIncidentModal;

ShareIncidentModal.propTypes = {
  incidentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
