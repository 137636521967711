import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { operators as Ops } from 'constants/application';

import { getViewData, getViewMeta } from 'views/modules/views';
import { ViewTable } from 'views/components';
import { getParams } from 'views/utils';

import { ASSET_META_OVERRIDES } from '.';

import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import useMixpanel from 'utils/hooks/Mixpanel';
import { downloadAssets, TYPES as RISKFEED_TYPES } from 'riskfeed/modules/riskfeed';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';
import { SYSTEM_TYPES } from 'common/Constants';

const AssetsTab = () => {
  const mixpanelTrack = useMixpanel();
  const dispatch = useDispatch();
  const views = useSelector(store => store.views);
  const assets = views?.assets;
  const init = assets === undefined;
  const { exportAssets } = useSelector(state => state.riskfeed);
  const { exportMAssetsData } = exportAssets;

  useEffect(() => {
    if (init) {
      dispatch(getViewMeta('assets', ASSET_META_OVERRIDES));
    }
  }, [dispatch, init]);

  useEffect(() => {
    if (exportMAssetsData) {
      dispatch({
        type: OPERATION_TYPES.SHOW_NOTIFICATION,
        payload: {
          content: 'An email will be sent to your inbox when the export is ready and will be available for 24 hours.',
          type: 'success',
        },
      });

      dispatch({
        type: RISKFEED_TYPES.RESET_EXPORT_ASSETS_DATA,
      });
    }
  }, [exportMAssetsData, dispatch]);

  const hasMeta = assets?.meta?.allowed !== undefined;
  const { id: threatId = null } = useParams();
  const { search: url } = useSelector(store => store.router.location);
  if (assets) assets.currentUrl = url;

  const query = useMemo(() => {
    if (!hasMeta) {
      return null;
    }

    const params = getParams(url, ['channel', 'mixpanel_event']);
    const filters = [
      ...params.filters,
      {
        name: 'threat_id',
        operator: Ops.exact,
        value: threatId,
      },
    ];

    return {
      columns: ['asset', 'org_type', 'location__city', 'in_network', 'risk'],
      ...params,
      filters,
      limit: 10,
    };
  }, [hasMeta, threatId, url]);

  useEffect(() => {
    if (query !== null) {
      dispatch(getViewData('assets', query));
    }
  }, [dispatch, query]);

  if (assets?.items === undefined) {
    return null;
  }
  const getRowId = row => row.asset.id;

  const handleExport = () => {
    dispatch(
      downloadAssets({
        filters: [
          { name: 'threat_id', operator: Ops.exact, value: threatId },
          { name: 'system_type', operator: Ops.in, value: [SYSTEM_TYPES.assets, SYSTEM_TYPES.waypoints] },
        ],
        datasource: 'assets',
      }),
    );
    mixpanelTrack('Action Center - Assets Tab - Export');
  };
  return (
    <>
      {assets?.items?.length ? (
        <div style={{ float: 'right', color: 'white', cursor: 'pointer', marginBottom: '5px' }}>
          <Tooltip title="Click to receive an export of the underlying Assets Flow data via email.">
            <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleExport} />
          </Tooltip>
        </div>
      ) : null}

      <ViewTable data={assets} rowKey={getRowId} />
    </>
  );
};

export default AssetsTab;
