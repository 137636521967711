import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from '@emotion/styled';
import { Table } from '@luxe/components';
import moment from 'moment';

import getWidget from './Widget';
import { updateFeedbackResponse } from 'feedback/modules/feedback';

const renderAttribute = (response, attribute, onUpdate) => {
  const value = response.data ? response.data[attribute.attr_name] : '';
  const onChange = value => {
    if (attribute.attr_name === 'impacted' && !value) {
      onUpdate(response.id, 'capacity', '100%');
      onUpdate(response.id, 'delay', '0 days');
      onUpdate(response.id, 'recovery_date', moment().format('YYYY/MM/DD'));
    }
    return onUpdate(response.id, attribute.attr_name, value);
  };
  return getWidget(attribute, value, onChange);
};

const AssetTable = styled(({ feedback, ...props }) => {
  if (feedback.item && feedback.item.assets) {
    const { template } = feedback.item;
    const { assets } = feedback.item;
    const attributes = template.attributes.map(attribute => {
      var title =
        attribute.name === 'Impacted' && assets?.length === 1 ? (
          <span>
            {attribute.name}
            <span>*</span>
          </span>
        ) : (
          <span>{attribute.name}</span>
        );
      return {
        title: title,
        dataIndex: attribute.attr_name,
        key: attribute.attr_name,
        render: (_, data) => {
          return renderAttribute(data, attribute, props.updateFeedbackResponse);
        },
      };
    });
    const columns = [
      {
        title: 'Facility',
        dataIndex: 'node',
        key: 'node',
        render: (_, data) => {
          return data.name;
        },
      },
    ].concat(attributes);
    return (
      <div className={props.className}>
        <Table rowKey="id" columns={columns} dataSource={assets} pagination={false} />
      </div>
    );
  } else {
    return null;
  }
})`
  .selectors-bool {
    width: 100px;
    height: 32px;
    font-size: 14px;
  }

  .selectors-percent {
    width: 120px;
    height: 32px;
  }

  .datepicker {
    background-color: #141414;
    border: 1px solid #246472;
    border-radius: 4px;
    width: 120px;
    height: 32px;
  }

  .ant-picker-input > input {
    color: #ffffff;
  }

  .ant-input-affix-wrapper {
    .ant-input-suffix {
      font-size: 14px;
    }
  }

  input.ant-input {
    color: #ffffff;
    font-size: 14px;
  }

  .delay {
    width: 100px;
    height: 32px;
    color: white;
    flex-direction: row;
  }

  tbody tr td {
    overflow: unset !important;
  }
`;

function mapStateToProps({ feedback }) {
  return {
    feedback,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFeedbackResponse,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetTable);
