import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { ReactComponent as IconAttachment } from './icon_attachment.svg';
import { ReactComponent as IconCancel } from './icon_cancel.svg';
import { ReactComponent as IconCheck } from './icon_check.svg';
import { ReactComponent as IconDelete } from './icon_delete.svg';
import { ReactComponent as IconEdit } from './icon_edit.svg';
import { ReactComponent as IconSend } from './icon_send.svg';

const Icon = styled(({ type, onClick, className, button = false, ...props }) => {
  const icon = {
    cancel: <IconCancel />,
    check: <IconCheck />,
    delete: <IconDelete />,
    edit: <IconEdit />,
    attachment: <IconAttachment />,
    send: <IconSend />,
  }[type];
  if (icon && button) {
    return (
      <Button onClick={onClick}>
        <span onClick={onClick} className={`${className} icon icon-${type}`}>
          {icon}
        </span>
      </Button>
    );
  } else if (icon) {
    return (
      <span onClick={onClick} className={`${className} icon icon-${type}`}>
        {icon}
      </span>
    );
  }
  console.warn('No icon found');
  return null;
})`
  & {
    cursor: pointer;
  }
`;

export default Icon;
