import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form } from 'antd';
import { BasicModal as Modal, Label, TextArea } from '@luxe/components';
import { shareIncident } from 'assets/modules/incidents';

import UserSelector from 'common/components/UserSelector';

const Share = styled(({ ...props }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id: threatId = null } = useParams();

  const onCancel = () => {
    props.setVisible(false);
  };

  useEffect(() => {
    if (props.incidentName) {
      form.setFieldsValue({ subject: props.incidentName });
    }
  }, [form, props.incidentName]);

  return (
    <Modal
      visible={props.visible}
      title="Share Incident"
      okText="Share"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(values => {
          const { subject, comments, recipients } = values;
          const payload = {
            subject,
            user_comment: comments,
            recipients: recipients.map(x => x.value),
          };
          props.mixpanelTrack('Action Center - Share Incident', {
            Incident: threatId,
          });
          dispatch(shareIncident(threatId, payload));
          form.resetFields();
          props.setVisible(false);
        });
      }}
      className={props.className}
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          name="create_workflow_form"
          initialValues={{
            modifier: 'public',
          }}
        >
          <Form.Item
            name="recipients"
            label={<label style={{ color: 'white' }}>Recipients</label>}
            rules={[
              {
                required: true,
                message: 'Recipients field is required',
              },
            ]}
          >
            <UserSelector multi valueKey="email" />
          </Form.Item>
          <Form.Item
            name="subject"
            label={<label style={{ color: 'white' }}>Subject</label>}
            rules={[
              {
                required: true,
                message: 'Subject field is required',
              },
            ]}
          >
            <Label placeholder="Subject" />
          </Form.Item>
          <Form.Item
            name="comments"
            label={<label style={{ color: 'white' }}>Comments</label>}
            rules={[
              {
                required: true,
                message: 'Comments field is required',
              },
            ]}
          >
            <TextArea rows="10" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
})`
  input,
  textarea {
    font-size: 14px;
  }
`;

export default Share;
