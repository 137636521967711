import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_FAMILIES: 'GET_FAMILIES',
  GET_FAMILIES_ERROR: 'GET_FAMILIES_ERROR',
  GET_FAMILIES_SUCCESS: 'GET_FAMILIES_SUCCESS',
};

export const INITIAL_STATE = {};

export const getFamilies = (reactive = false) => {
  return callApi({
    types: {
      pending: TYPES.GET_FAMILIES,
      success: TYPES.GET_FAMILIES_SUCCESS,
      error: TYPES.GET_FAMILIES_ERROR,
    },
    request: () => {
      const url = reactive ? `threats/families/?reactive=true` : `threats/families/`;
      return axios.get(url);
    },
  });
};

export const familyReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case TYPES.GET_FAMILIES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        error: undefined,
        params: {},
      };
    case TYPES.GET_FAMILIES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        families: action.payload,
      };
    case TYPES.GET_FAMILIES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default familyReducer;
