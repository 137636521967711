import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { getProactiveRisk, getRiskModel } from 'admin/riskmodels/modules/riskmodels';
import { getAllComponentsFromRiskIndex } from 'assets/modules/utils';
import { isEmpty } from 'utils';
import {
  clearComponentHistory,
  clearComponentRiskDetails,
  getComponentHistoryDetail,
  getComponentRiskDetails,
  updateComponentScoreData,
} from 'assets/modules/components';
import ScoreDetailsPage from './ScoreDetailsPage';

const ScoreDetails = ({
  asset,
  riskScore,
  updateComponentScoreData,
  riskhistory,
  proactiveRisk,
  riskDetails,
  riskModel,
}) => {
  const { id: assetId, riskModelId, compId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [riskComponent, setRiskComponent] = useState(null);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (!proactiveRisk || isEmpty(proactiveRisk) || proactiveRisk.model_id.toString() !== riskModelId.toString()) {
      dispatch(getProactiveRisk({ assetId, riskModelId }));
      dispatch(getRiskModel({ id: riskModelId }));
    }
  }, [assetId, dispatch, proactiveRisk, riskModelId]);

  useEffect(() => {
    if (proactiveRisk && !isEmpty(proactiveRisk) && compId && riskModel) {
      const allComponents = getAllComponentsFromRiskIndex(proactiveRisk);
      const modelComponents = getAllComponentsFromRiskIndex(riskModel);
      const component = allComponents.find(comp => comp.component_id.toString() === compId.toString());
      const modelComponent = modelComponents.find(comp => comp.id.toString() === compId.toString());
      component.summary = modelComponent.summary;
      component.description = modelComponent.description;
      component.rule = modelComponent.rule;
      updateComponentScoreData(component);
      setRiskComponent(component);
    }
  }, [proactiveRisk, compId, updateComponentScoreData, riskScore, riskModel]);

  useEffect(() => {
    if (asset && riskModelId && compId) {
      if (firstLoad.current) {
        dispatch(
          getComponentHistoryDetail({
            id: asset.id,
            riskModelId: riskModelId,
            componentId: compId,
            datasource: 'assets',
          }),
        );
        dispatch(
          getComponentRiskDetails({
            id: asset.id,
            riskModelId: riskModelId,
            componentId: compId,
            datasource: 'assets',
          }),
        );
        firstLoad.current = false;
      }
    }
  }, [proactiveRisk, asset, riskModelId, compId, dispatch, riskhistory, riskDetails]);

  if (!riskScore) {
    return (
      <Layout>
        <Spin size={'large'} />
      </Layout>
    );
  }

  function returnToScorecard() {
    history.push(`/assets/${asset.id}/risk`);
    dispatch(clearComponentHistory());
    dispatch(clearComponentRiskDetails());
  }

  return (
    <>
      <ScoreDetailsPage
        proactiveRisk={proactiveRisk}
        riskScore={riskScore}
        riskDetails={riskDetails}
        riskhistory={riskhistory}
        riskComponent={riskComponent}
        returnToScorecard={returnToScorecard}
      />
    </>
  );
};

function mapStateToProps({ assets, riskModels, components }) {
  return {
    asset: assets.Asset,
    proactiveRisk: riskModels.proactiveRisk,
    riskModel: riskModels.RiskModel,
    riskScore: components.scoreData,
    riskhistory: components.riskHistory,
    riskDetails: components.riskDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRiskModel,
      updateComponentScoreData,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ScoreDetails);
