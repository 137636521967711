import React, { useState } from 'react';
import { Radio, Typography } from 'antd';
import { ASSIGNMENTS } from 'common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { BasicModal as Modal } from '@luxe/components';

const { Text } = Typography;

const ManualAssignment = ({ ruleType, updateRule, resetSelectedIds, ...props }) => {
  const dispatch = useDispatch();
  const { colors, fontSizes } = useTheme();

  const { selectedIds } = useSelector(store => store.workflowRules);
  const [visible, setVisible] = useState(false);

  const changeType = ev => {
    const isUpdated = selectedIds.length > 0 && ev.target.value === 'Auto';
    if (isUpdated) {
      setVisible(true);
    } else {
      dispatch(updateRule({ assignment_type: ev.target.value }));
    }
  };

  const onOk = () => {
    dispatch(updateRule({ assignment_type: 'Auto', settings: {} }));
    dispatch(resetSelectedIds());
    setVisible(false);
  };
  return (
    <>
      <Radio.Group buttonStyle="solid" className="assignment-switch" value={ruleType}>
        <div>
          <label className={'realtime-title'}>Assignment</label>
        </div>

        {ASSIGNMENTS.map((d, i) => (
          <Radio.Button key={i} value={d.value} onChange={changeType}>
            {d.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Modal
        visible={visible}
        okText="Yes"
        cancelText="No"
        onCancel={() => {
          setVisible(false);
        }}
        onOk={onOk}
        destroyOnClose={true}
      >
        <Text style={{ fontSize: fontSizes.body, color: colors.white }}>You will lose selected assets</Text>
      </Modal>
    </>
  );
};

export default ManualAssignment;
