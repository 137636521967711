import React, { useMemo, useState } from 'react';
import getFromConfig from 'constants/config';
import DrawerInformation from './DrawerInformation';
import DeleteAssetModal from './DeleteAssetModal';
import { useSelector } from 'react-redux';

const AssetDrawerInformation = ({ asset, className, closeDrawer, activeRiskModel, selectedNode }) => {
  const materialsEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.materials?.enabled);
  const discoverEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled);
  const commodityEnabled = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const isAdmin = useSelector(store => store.account.account?.isAdmin);
  const tierLevels = selectedNode?.tier_level?.sort().join(', ') || '';
  let scoreCardURL, showMapURL;

  const isEkg = useMemo(() => {
    return asset?.system_type === '3';
  }, [asset]);

  if (asset && asset.asset.id) {
    const baseURL = getFromConfig('REACT_APP_RISKBOX_BASE_URL');
    scoreCardURL = `/assets/${asset.asset.id}`;
    if (!isEkg) {
      showMapURL = `${baseURL}assets/${asset.asset.id}`;
    }
  }

  const proactiveScoreSource = asset.proactive_scores?.length ? asset.proactive_scores[activeRiskModel] : {};
  const risk = asset.risk;

  const threatScores = {
    overall: {
      score: typeof proactiveScoreSource?.score == 'number' ? proactiveScoreSource?.score : '-',
      category: typeof proactiveScoreSource?.category === 'string' ? proactiveScoreSource?.category : 'low',
    },
    external: {
      score: '-',
    },
    internal: {
      score: '-',
    },
    max: {
      score: typeof risk?.score === 'number' ? risk?.score : '-',
      category: typeof risk?.category === 'string' ? risk?.category : 'low',
    },
  };
  proactiveScoreSource.indices &&
    proactiveScoreSource.indices.forEach(score => {
      if (/^external /i.test(score?.name)) {
        threatScores.external.score = typeof score?.score === 'number' ? score?.score : '-';
      }
      if (/^internal /i.test(score?.name)) {
        threatScores.internal.score = typeof score?.score === 'number' ? score?.score : '-';
      }
    });

  const actions =
    isAdmin && !isEkg
      ? [
          {
            title: 'Delete',
            action: () => setShowDeleteConfirmModal(true),
          },
        ]
      : null;

  return (
    <>
      <DrawerInformation
        {...{
          className,
          name: asset.asset.name,
          type: asset.org_type,
          countryName: asset.location?.country.name,
          location: {
            latitude: asset.location?.latitude,
            longitude: asset.location?.longitude,
          },
          meta:
            (materialsEnabled || discoverEnabled) && commodityEnabled && asset?.commodities?.length
              ? { ...asset.meta, Commodities: asset.commodities.map(val => val.name).join(', ') }
              : asset.meta,
          threatScores,
          scoreCardURL,
          showMapURL,
          closeDrawer,
          actions,
          subtitle: asset?.profiles?.[0]?.name || '',
          isEkg: isEkg,
          tierLevels: tierLevels,
          selectedNode: asset.location,
          isAssetDrawer: true,
        }}
      />
      {showDeleteConfirmModal && (
        <DeleteAssetModal
          assetId={asset.asset.id}
          hideModal={() => setShowDeleteConfirmModal(false)}
          closeDrawer={closeDrawer}
        />
      )}
    </>
  );
};

export default AssetDrawerInformation;
