import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { Label as Input, Alert } from '@luxe/components';
import { Row, Col, Tabs, Button } from 'antd';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import useMixpanel from 'utils/hooks/Mixpanel';

import { ListViewContent } from '@luxe/components/src/Container';

import { updateWorkflow, saveWorkflow, trackWorkFlowChange, getWorkflow } from 'admin/workflows/modules/workflows';
import ThreatTab from './ThreatTab';
import NotificationsTab from './NotificationsTab';
import NetworkTabView from './NetworkTab';
import MyNetworkView from 'modal/userprofile/containers/UserProfileView/MyNetwork/MyNetwork';
import CancelButton from 'admin/workflows/containers/WorkflowDetailView/CancelButton';
import CommoditiesTab from './CommoditiesTab';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    min-height: 300px;
  }
  .ant-tabs-nav-list {
    color: ${props => props.theme.colors.white} !important;
    font-weight: 700;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.theme.colors.primary} !important;
    font-weight: 700;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid #2d8fa5;
  }
`;

const WorkflowDetailView = ({ router, views, getOptions, ...props }) => {
  const mixpanelTrack = useMixpanel();
  const { space } = useTheme();
  const dispatch = useDispatch();
  const [isNetworkCommodityError, setIsNetworkCommodityError] = React.useState(false);

  let cancelUrl = props.currentApp === 'users' ? '/users/network/' : '/admin/workflows/';
  let selectedTab = (router.location.pathname.match(`${cancelUrl}[^/]*/([^/]*)`) || [])[1];
  selectedTab = ['network-incidents', 'network', 'recipients', 'additional-incidents', 'commodity-risk'].includes(
    selectedTab,
  )
    ? selectedTab
    : 'network-incidents';
  const { workflow } = props.workflows;

  const rules = useSelector(store => store.workflowRules.workflow_rules);
  const includedChannels = useSelector(store => store?.accountOrg?.accountOrg?.prefs?.digests?.include_channels);
  const commodity_pref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled);

  const clickTab = key => {
    if (props.currentApp === 'users') {
      props.push(`/users/network/${workflow.id}/${key}/`);
      mixpanelTrack('User Profile - Switch Tab', {
        Tab: key,
      });
    } else {
      props.push(`/admin/workflows/${workflow.id}/${key}/`);
      mixpanelTrack('Workflow - Switch Tab', {
        Tab: key,
      });
    }
    if (workflow?.id) dispatch(getWorkflow({ id: workflow.id }));
  };

  const ValidationErrors = () => {
    const { validationErrors = [] } = workflow;
    const defaultMessage = (
      <>
        <p>
          To configure the incidents of interest for notifications, please select desired incident categories, risk
          levels and a filter rule or additional incidents channel. The activation of notifications can be configured
          under the &quot;Notifications&quot; tab.
        </p>
        <p>
          <b>Please note:</b> You will only receive notifications for (Network Incidents) incident categories setup by
          an account admin. As a result you may have selected a category under Network Incidents which does <b>not </b>
          result in a notification.
        </p>
      </>
    );
    const message = validationErrors.map((validationError, i) => {
      return <p key={i}>{`* ${validationError.message}`}</p>;
    });
    return (
      <div className="alert">
        <Alert
          message={
            <>
              {defaultMessage}
              {message}
            </>
          }
          type="warning"
          closable
        />
      </div>
    );
  };

  const onNameChange = ev => {
    mixpanelTrack('Workflow - Update name');
    dispatch(trackWorkFlowChange(true));
    props.updateWorkflow({ name: ev.target.value });
  };

  const handleSave = () => {
    if (isNetworkCommodityError && !Boolean(workflow?.data?.commodities?.length)) {
      dispatch({
        type: OPERATION_TYPES.SHOW_NOTIFICATION,
        payload: {
          content: 'At least one commodity must be selected when commodities are enabled for network incidents',
          type: 'error',
        },
      });
      return;
    }
    if (window.location.href.includes('users/network')) {
      mixpanelTrack('User Profile Network Settings - Click on Save');
    } else if (window.location.href.includes('users/notifications')) {
      mixpanelTrack('User Profile Notifications - Click on Save');
    } else if (window.location.href.includes('workflows')) {
      mixpanelTrack('Workflows - Click on Save');
    }
    if (workflow.status === 'PENDING') {
      workflow.status = 'ACTIVE';
    }
    dispatch(saveWorkflow(workflow));
  };

  const networkLabel = workflow?.user_profile !== null ? 'My Network' : 'Network';
  const feedBackWorkflow =
    workflow?.type === 'feedback_workflow' || workflow?.user_profile !== null ? null : (
      <TabPane tab="Recipients" key="recipients">
        <NotificationsTab currentApp={props.CurrentApp} />
      </TabPane>
    );

  useEffect(() => {
    dispatch(trackWorkFlowChange(false));
  }, [dispatch]);

  const checkNetworkCommodity = (value = false) => {
    setIsNetworkCommodityError(value);
  };

  return (
    <>
      <ListViewContent
        style={{ margin: `${space.none} ${space.small}px`, size: '14px' }}
        className="list view risk-models"
      >
        <Row gutter={[4, 8]}>
          <Col span={10} align="start">
            {props.currentApp !== 'users' ? (
              <Input
                style={{ width: '440px', height: '38px', radius: '4px' }}
                value={workflow.name}
                onChange={onNameChange}
              />
            ) : (
              <Input style={{ width: '440px', height: '38px', radius: '4px' }} value={workflow.name} readOnly />
            )}
          </Col>
          <Col span={14} align="end">
            <Button className={'submit profile-button'} onClick={handleSave}>
              Save
            </Button>
            <CancelButton cancelUrl={cancelUrl} />
          </Col>
        </Row>
        <br />
        <br />
        <ValidationErrors />
        <StyledTabs tab="Threat Tab" activeKey={selectedTab} onChange={clickTab}>
          <TabPane tab="Network Incidents" key="network-incidents">
            <ThreatTab currentApp={props.currentApp} checkNetworkCommodity={checkNetworkCommodity} />
          </TabPane>
          <TabPane tab={networkLabel} key="network">
            <NetworkTabView workflow={workflow.id} rules={rules} currentApp={props.currentApp} data={workflow.data} />
          </TabPane>
          {(includedChannels === undefined || !includedChannels.every(item => item.includes('NETWORK_SUMMARY'))) && (
            <TabPane tab="Additional Incidents" key="additional-incidents">
              <MyNetworkView workflow={workflow} includedChannels={includedChannels} />
            </TabPane>
          )}
          {commodity_pref ? (
            <TabPane tab="Commodity Risk" key="commodity-risk">
              <CommoditiesTab workflow={workflow} />
            </TabPane>
          ) : null}
          {feedBackWorkflow}
        </StyledTabs>
      </ListViewContent>
    </>
  );
};

function mapStateToProps({ router, workflows, views }) {
  return {
    router,
    workflows,
    views,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkflow,
      push,
      getWorkflow,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDetailView);
