import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/usergroups/modules/userGroups';
import { getAllUsers, TYPES as USER_TYPES } from 'admin/users/modules/users';
import { getUserGroups } from 'admin/usergroups/modules/userGroups';
import { getUserFromId } from 'admin/users/modules/users';
import { fetchUsers } from '../modules/utils';

const refetchUserGroups = (action$, state$) => {
  const refetchTypes = [
    TYPES.CREATE_USER_GROUPS_SUCCESS,
    TYPES.UPDATE_USER_GROUPS_SUCCESS,
    TYPES.DELETE_USER_GROUPS_SUCCESS,
  ];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(() => getUserGroups()),
  );
};

const refetchGroupUsers = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_USERS_TO_GROUP_SUCCESS, TYPES.REMOVE_USER_FROM_GROUP_SUCCESS];
  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(action => fetchUsers(action.params)),
  );
};

const refetchUsers = (action$, state$) => {
  const refetchTypes = [USER_TYPES.DELETE_USER_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    map(action => getAllUsers()),
  );
};

const refetchUser = (action$, state$) => {
  const refetchTypes = [TYPES.ADD_USERS_TO_GROUP_SUCCESS, TYPES.REMOVE_USER_FROM_GROUP_SUCCESS];

  return action$.pipe(
    filter(action => refetchTypes.indexOf(action.type) > -1),
    filter(action => action.params && action.params.isSingle),
    map(action => getUserFromId(action.params.id)),
  );
};

const epics = {
  refetchUserGroups,
  refetchUsers,
  refetchUser,
  refetchGroupUsers,
};

export default epics;
