import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Logo } from '@luxe/components';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import NavigationMenu from './NavigationMenu';
import UserMenu from 'layout/containers/menus/UserMenu';
import eaLogo from '../static/everstream-logo.png';

const HeaderMenu = styled(props => {
  let path = window.location.pathname.split('/');
  const adminRoute = path[1] === 'admin' || path[1] === 'users';
  const discoverNetworkRoute = path[1] === 'discover' && path[2] === 'network';
  return (
    <span className={`${props.className}`}>
      {!adminRoute && !discoverNetworkRoute && (
        <Logo>
          <Link to="/">
            <img alt="Everstream Analytics" src={eaLogo} />
          </Link>
        </Logo>
      )}
      {!adminRoute && discoverNetworkRoute && <NavigationMenu />}
      <LoadingSpinner style={{ marginTop: '7px', marginRight: '15px' }} />
      <UserMenu />
    </span>
  );
})`
  display: flex;
  height: 60px !important;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  width: 100%;
`;
export default HeaderMenu;
