import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'layout/Select';

import { getOptions } from 'views/modules/options';

const BaseSelector = ({ name, value, config, ...props }) => {
  const multi = props.multi === true;

  const dispatch = useDispatch();
  const optionsData = useSelector(store => store.options);
  const { options = undefined, initialized = false } = optionsData[name] || {};
  useEffect(() => {
    if (!initialized) {
      dispatch(getOptions(config, ''));
    }
  }, [initialized, config, dispatch]);

  if (!initialized) {
    return null;
  }

  let selected = null;
  if (multi) {
    selected = options.filter(x => value?.map(v => v.value).includes(x.value));
  } else {
    selected = options.find(x => x.value === value?.value);
  }

  return (
    <Select
      key={name}
      isMulti={multi}
      stayOpen={multi}
      selected={selected}
      onChange={props.onChange}
      options={options}
      backspaceRemoves
      placeholder={props.placeholder}
    />
  );
};
export default BaseSelector;
