import { filter, map } from 'rxjs/operators';
import { TYPES } from 'admin/playbooks/modules/planPlaybooks';
import { push } from 'connected-react-router';

export const createPlanPlaybookRedirectEpic = action$ => {
  return action$.pipe(
    filter(action => action.type === TYPES.CREATE_PLAN_PLAYBOOK_SUCCESS),
    map(action => push(`/admin/plan-playbooks/edit/${action.payload.id}/`)),
  );
};

const redirectToPlaybookListEpic = (action$, state$) => {
  const playbookListRedirectTypes = [TYPES.DELETE_PLAN_PLAYBOOK_SUCCESS, TYPES.UPDATE_PLAN_PLAYBOOK_SUCCESS];

  return action$.pipe(
    filter(action => playbookListRedirectTypes.indexOf(action.type) > -1),
    map(() => push(`/admin/plan-playbooks/`)),
  );
};

const epics = {
  createPlanPlaybookRedirectEpic,
  redirectToPlaybookListEpic,
};

export default epics;
