import React from 'react';
import { useTheme } from 'emotion-theming';
import { Tree, Dropdown } from 'antd';
import GroupMenu from '@luxe/components/src/GroupMenu';
import { MoreOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

const TreeNode = ({ id, title, onDrop, seeMoreButton, getId, ...props }) => {
  return (
    <NodeContainer {...props}>
      <TitleContainer>{title}</TitleContainer>
      {seeMoreButton}
    </NodeContainer>
  );
};

const TitleContainer = styled.div`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const NodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  padding: 5px;
`;

const GroupTree = ({
  treeData,
  showModal,
  onTreeNodeFocus,
  onTreeNodeSelect,
  selectedTreeNode,
  addObjectsToGroup,
  defaultTreeNode,
  getObjectId = object => object.id,
  checkable = false,
  onCheck,
  checkedKeys,
}) => {
  const { colors } = useTheme();
  const handleMenuSelect = (key, treeNode, showModal, onTreeNodeFocus) => {
    onTreeNodeFocus({ ...treeNode });
    showModal(key);
  };

  const renderSeeMoreButton = (treeNode, showModal, setFocusedTreeNode) => (
    <Dropdown
      trigger={['click']}
      onClick={e => e.stopPropagation()}
      overlay={
        <GroupMenu
          onSelect={({ key }) => {
            handleMenuSelect(key, treeNode, showModal, setFocusedTreeNode);
          }}
        />
      }
    >
      <MoreOutlined />
    </Dropdown>
  );

  const renderTreeNode = treeNode => {
    if (treeNode.id === defaultTreeNode.id) {
      return (
        <TreeNode
          id={treeNode.id}
          title={treeNode.title}
          onDrop={addObjectsToGroup}
          seeMoreButton={null}
          getId={getObjectId}
        />
      );
    }
    return (
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <GroupMenu
            onSelect={({ key }) => {
              handleMenuSelect(key, treeNode, showModal, onTreeNodeFocus);
            }}
            style={{backgroundColor: colors.secondary}}
          />
        }
      >
        <TreeNode
          id={treeNode.id}
          title={treeNode.title}
          onDrop={addObjectsToGroup}
          seeMoreButton={renderSeeMoreButton(treeNode, showModal, onTreeNodeFocus)}
          getId={getObjectId}
        />
      </Dropdown>
    );
  };

  const getTree = () => {
    return (
      <Tree
        onClick={e => e.preventDefault}
        blockNode
        treeData={treeData}
        selectedKeys={[selectedTreeNode.key]}
        titleRender={renderTreeNode}
        onSelect={(_, { node }) => (selectedTreeNode.id !== node.id ? onTreeNodeSelect(node) : null)}
        checkable={checkable}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        style={{backgroundColor:  '#212121' }}
      />
    );
  };
  return <div style={{ width: '100%' }}>{getTree()}</div>;
};

export default GroupTree;
