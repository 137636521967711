import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import WorkflowDetailView from '../WorkflowDetailView';

import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { Skeleton } from '@luxe/components';
import { useTheme } from 'emotion-theming';

import { ListViewContent } from '@luxe/components/src/Container';
import { getRules } from 'admin/workflows/modules/rules';

import { useLocation } from 'react-router-dom';

import { getWorkflow, updateWorkflow } from 'admin/workflows/modules/workflows';

const WorkflowContainerView = ({ getWorkflow, router, views, getOptions, ...props }) => {
  const { space } = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  let workflowId = props.userWorkflowId || location.pathname.split('/')[3];

  const workflow = useSelector(store => store.workflows?.workflow);

  let currentApp = workflow?.user_profile === null ? 'admin' : 'users';
  const cancelUrl = currentApp === 'users' ? '/users/network/' : '/admin/workflows/';

  const currentId = workflow?.id;

  const { status } = props.workflows;

  useEffect(() => {
    if (workflowId && workflowId !== currentId) {
      getWorkflow({ id: workflowId });
      dispatch(getRules(workflowId));
    }
  }, [dispatch, getWorkflow, workflowId, currentId, props.userWorkflowId]);

  const workflowLoading = !workflow || !workflow.data;

  if (status === 'PENDING' || workflowLoading) {
    return (
      <div style={{ padding: '38px', paddingRight: '200px' }}>
        <Skeleton />
      </div>
    );
  }
  return (
    <>
      <ListViewContent
        style={{ margin: `${space.none} ${space.small}px`, size: '14px' }}
        className="list view risk-models"
      >
        <WorkflowDetailView workflow={workflow} currentApp={currentApp} cancelUrl={cancelUrl} />
      </ListViewContent>
    </>
  );
};

function mapStateToProps({ router, workflows, views }) {
  return {
    router,
    workflows,
    views,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWorkflow,
      updateWorkflow,
      push,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowContainerView);
