import React, { useState } from 'react';
import { Button, Section, Table, Label, BasicModal as Modal } from '@luxe/components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Col, Row } from 'antd';
import { Form } from 'antd';
import { Select } from 'layout/Select';
import { PlusCircleOutlined } from '@ant-design/icons';
import { trackWorkFlowChange } from 'admin/workflows/modules/workflows';

const RuleView = ({ rules, columns, data, createRuleFunction }) => {
  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const dispatch = useDispatch();
  const onCreate = values => {
    dispatch(trackWorkFlowChange(true));
    dispatch(
      createRuleFunction(
        {
          name: values.name,
          rule_type: values.type.value,
          assignment_type: 'Auto',
        },
        data,
      ),
    );
    setIsCreateModalShowing(false);
  };

  const formatRules = (rulesData) => {
    return rulesData.map(item => {
      if(item.rule_type === 'Assets'){
        item.rule_type = 'Facilities'
      }
      return item
    });
  };

  const showCreateModal = () => {
    setIsCreateModalShowing(true);
  };
  return (
    <>
      <Row>
        <Col span={12} align="start" style={{ color: 'white' }}>
          Filter Rules
        </Col>
        <Col span={12} align="end">
          <Button
            style={{ marginBottom: '5px' }}
            variant="primary"
            onClick={showCreateModal}
            icon={<PlusCircleOutlined />}
          >
            New Rule
          </Button>
        </Col>
      </Row>
      <Section rules="rules">
        <div style={{ width: '100%' }}>
          <Table rowKey="id" columns={columns} dataSource={formatRules(rules)} size="small" />
        </div>
      </Section>
      <CreateRuleForm
        title={CREATE_MODAL_TEST}
        visible={isCreateModalShowing}
        onCancel={() => {
          setIsCreateModalShowing(false);
        }}
        onCreate={onCreate}
      />
    </>
  );
};

const CREATE_MODAL_TEST = 'Create a new Filter Rule';
const CreateRuleForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const options = [{ key: 1, value: 'Assets', label: 'Facilities' }];
  const rules = type => {
    return [{ required: true, message: `Rule ${type} is required` }];
  };

  const onOk = values => {
    form.validateFields().then(values => {
      form.resetFields();
      onCreate({
        name: values.name,
        type: values.type,
      });
    });
  };

  return (
    <Modal
      visible={visible}
      title={CREATE_MODAL_TEST}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_rule_form"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item name="name" label={<label style={{ color: 'white' }}>Name</label>} rules={rules('name')}>
          <Label style={{ height: '38px', width: '100%' }} placeholder="Rule Name" />
        </Form.Item>
        <Form.Item>
          <label style={{ color: 'white' }}>Choose what are you going to use this rule for:</label>
          <Form.Item name="type" noStyle rules={rules('type')}>
            <Select placeholder="Select a Rule Type" options={options} rules={rules('type')} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default RuleView;

RuleView.propTypes = {
  rules: PropTypes.array.isRequired,
  columns: PropTypes.array,
  createRuleFunction: PropTypes.func.isRequired,
  data: PropTypes.string,
};

RuleView.defaultProps = {};
