export const INITIAL_STATE = {
  isLoading: false,
  notification: {
    content: null,
    type: null,
  },
};

export const TYPES = {
  SHOW_LOADING_SPINNER: 'SHOW_LOADING_SPINNER',
  HIDE_LOADING_SPINNER: 'HIDE_LOADING_SPINNER',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
};

export const apiOperationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SHOW_LOADING_SPINNER:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.HIDE_LOADING_SPINNER:
      return {
        ...state,
        isLoading: false,
      };
    case TYPES.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};
