import { from, of } from 'rxjs';
import { filter, map, switchMap, catchError } from 'rxjs/operators';
import axios from 'axiosInstance';
import { TYPES, getAllViewData, selectAllNext } from 'views/modules/views';
import { getParams } from 'views/utils';
import { ofType } from 'redux-observable';

const selectAllNextEpic = action$ => {
  return action$.pipe(
    filter(action => [TYPES.SELECT_ALL, TYPES.SELECT_ALL_NEXT].includes(action.type)),
    switchMap(action => {
      let { datasource } = action.payload;
      let offset = action.type === TYPES.SELECT_ALL ? 0 : null;
      if (offset === null) {
        offset = action.payload.offset;
      }
      const data = {
        ...action.payload,
        limit: 1000,
        offset,
        columns: ['id'],
        order_by: [
          {
            name: 'id',
            direction: 'asc',
          },
        ],
      };
      const request = axios.post(`${datasource}/query/?limit=${data.limit}&offset=${data.offset}`, data);
      return from(request).pipe(
        map(response => {
          const nextUrl = response.data.next ? getParams(response.data.next) : null;
          const selected = action.payload.selectedRows
            ? action.payload.selectedRows.concat(response.data.results.map(x => x.id))
            : response.data.results.map(x => x.id);
          if (nextUrl) {
            return selectAllNext({
              ...action.payload,
              ...nextUrl,
              selectedRows: [...new Set(selected)],
            });
          } else {
            return {
              type: TYPES.SELECT_ALL_SUCCESS,
              payload: {
                ...action.payload,
                selectedRows: [...new Set(selected)],
              },
            };
          }
        }),
        catchError(error => {
          return of({
            type: TYPES.SELECT_ALL_ERROR,
            payload: { error },
          });
        }),
      );
    }),
  );
};

const fetchDataAfterMetaEpic = action$ => {
  return action$.pipe(
    ofType(TYPES.GET_VIEW_META_SUCCESS),
    map(action => {
      if (action?.params?.fetchData) {
        action.params.fetchData();
      }
      return { type: '' };
    }),
  );
};

const fetchAllDataEpic = action$ => {
  return action$.pipe(
    ofType(TYPES.GET_ALL_VIEW_DATA_SUCCESS),
    map(action => {
      if (action.payload?.next) {
        const data = action.params?.data;
        data.offset = new URLSearchParams(action.payload.next).get('offset');
        return getAllViewData(
          action.params?.datasource,
          data,
          action.params?.additionalColumns,
          action.params?.endPoint,
        );
      }
      return { type: '' };
    }),
  );
};

const allEpics = {
  selectAllNextEpic,
  fetchDataAfterMetaEpic,
  fetchAllDataEpic,
};
export default allEpics;
