import React from 'react';

import styled from '@emotion/styled';

import { Section } from '@luxe/components';
import { useSelector } from 'react-redux';

const NetworkOverview = styled(props => {
  const { region } = useSelector(store => store.regions);
  const { summary } = region;

  if (!summary) {
    return null;
  }

  const orgTypes = {
    airport: 'Airport',
    border_crossing: 'Border Crossing',
    customer: 'Customer',
    distribution_center: 'Distribution Center',
    distributor: 'Distributor',
    headquarter: 'Headquarter',
    natural_resources: 'Natural Resources',
    office: 'Office',
    port: 'Port',
    production_plant: 'Production Plant',
    rail_terminal: 'Rail Terminal',
    supplier: 'Supplier',
    warehouse: 'Warehouse',
    other: 'Other',
  };

  const assetTypes = Object.entries(summary.assets)
    .filter(x => Object.keys(orgTypes).includes(x[0]))
    .map(([attr, count]) => {
      const label = orgTypes[attr];
      return (
        <div key={attr} className="count">
          <div style={{ textAlign: 'center' }}>{label}</div>
          <div style={{ textAlign: 'center', color: '#38b1cc' }}>{count}</div>
        </div>
      );
    });

  return (
    <Section title={'Network Overview'}>
      <div className={props.className}>{assetTypes}</div>
    </Section>
  );
})`
  & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 70px;
  }

  & div.asset-count {
    width: 200px;
  }

  & div.asset-count > div {
    text-align: center;
  }

  & div.asset-count > .count {
    color: #38b1cc;
  }
`;

export default NetworkOverview;
