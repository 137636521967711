import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { getHistoryValues } from './utils';
import { callApi } from 'utils';

export const TYPES = {
  GET_RISK_HISTORY: 'GET_RISK_HISTORY',
  GET_RISK_HISTORY_ERROR: 'GET_RISK_HISTORY_ERROR',
  GET_RISK_HISTORY_SUCCESS: 'GET_RISK_HISTORY_SUCCESS',
};

export const getRiskHistory = params =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_HISTORY,
      success: TYPES.GET_RISK_HISTORY_SUCCESS,
      error: TYPES.GET_RISK_HISTORY_ERROR,
    },
    params: params,
    request: () => axios.get(`assets/${params.assetId}/risk-models/${params.riskModelId}/scores/`),
  });

export const getCountryRiskHistory = params =>
  callApi({
    types: {
      pending: TYPES.GET_RISK_HISTORY,
      success: TYPES.GET_RISK_HISTORY_SUCCESS,
      error: TYPES.GET_RISK_HISTORY_ERROR,
    },
    params: params,
    request: () => axios.get(`countries/${params.countryId}/risk-models/${params.riskModelId}/scores/`),
  });

export const INITIAL_STATE = {
  status: undefined,
  riskHistory: {},
  error: undefined,
  params: undefined,
  next: null,
  previous: null,
};

export const riskHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_RISK_HISTORY:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_RISK_HISTORY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
        params: undefined,
      };
    case TYPES.GET_RISK_HISTORY_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        riskHistory: getHistoryValues(action.payload),
        riskHistoryData: action.payload,
        error: undefined,
      };
    default:
      return state;
  }
};
