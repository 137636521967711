import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleFamily, updateFamily, trackWorkFlowChange } from 'admin/workflows/modules/workflows';

import { Select } from 'antd';
import styled from '@emotion/styled';
import { StyledCheckbox } from './Panel';
import { sortRiskCategoryData } from 'admin/workflows/modules/utils';

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    border: 2px solid;
  }
  .ant-select-selection-item {
    background-color: ${props => props.theme.colors.primary} !important;
  }
`;

const phenomenaTable = ({ family, toggleFamily, updateFamily, trackWorkFlowChange, className }) => {
  const renderWidget = phenomena => {
    if (phenomena.selected) {
      const value = sortRiskCategoryData(phenomena.riskCategories);
      const op = ['low', 'medium', 'high'];
      const children = op.map((x, i) => {
        return (
          <Option key={i} value={x}>
            {x}
          </Option>
        );
      });
      const onChange = value => {
        trackWorkFlowChange(true);
        updateFamily(phenomena.id, { riskCategories: value });
      };
      return (
        <StyledSelect mode="multiple" onChange={onChange} value={value} style={{ width: '300px' }}>
          {children}
        </StyledSelect>
      );
    }
    return null;
  };

  const phenomena = family.children.map(phenomena => {
    const widget = renderWidget(phenomena);
    const clickCheckbox = () => {
      const riskCategories = phenomena.selected ? [] : ['medium', 'high'];
      trackWorkFlowChange(true);
      toggleFamily(phenomena.id);
      updateFamily(phenomena.id, { riskCategories });
    };
    return (
      <div key={phenomena.id} className="phenomena">
        <div className="title">
          <span>{phenomena.name}</span>
        </div>
        <div className="widgets">{widget}</div>
        <div className="checkbox">
          <StyledCheckbox checked={phenomena.selected} className="checkbox" onChange={clickCheckbox} />
        </div>
      </div>
    );
  });

  return <div className={className}>{phenomena}</div>;
};

const PhenomenaTable = styled(phenomenaTable)`
  & .phenomena {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    margin-top: 3px;
    height: 40px;
    background-color: ${props => props.theme.colors.black4};

    & > .title {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      font-weight: 200;
      font-size: 14px;
      line-height: 40px;

      span {
        padding-left: 34px;
      }
    }

    & > .widgets {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      text-align: center;
      padding-top: 4px;
    }

    & > .checkbox {
      order: 0;
      flex: 1 1 0;
      align-self: auto;
      line-height: 40px;
      text-align: right;
      padding-right: 15px;
    }
  }
`;

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleFamily,
      updateFamily,
      trackWorkFlowChange,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PhenomenaTable);
