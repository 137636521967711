import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse, Card, Typography, Tooltip } from 'antd';
import { AppstoreOutlined as GripIcon, EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
const { Panel } = Collapse;
const { Text } = Typography;

const RiskModelScoreComponent = ({ familyId, riskModelScore }) => {
  const { colors, itemAlignment, fontSizes } = useTheme();
  const handleDragStart = e => {
    const data = {
      id: riskModelScore.id,
      name: riskModelScore.name,
      family: familyId,
      phenomena_id: riskModelScore.id,
      rule: {
        ...riskModelScore.rule,
        id: riskModelScore.id,
      },
      rule_type: riskModelScore.rule_type,
      datasource_id: riskModelScore.datasource_id,
      user_editable: riskModelScore.user_editable,
    };
    e.dataTransfer.setData(
      'data',
      JSON.stringify({ addComponent: true, data: data, draggingComponent: true, newComponent: true }),
    );
  };

  return (
    <Row
      style={{
        backgroundColor: `${colors.black2}`,
        border: `${colors.border}`,
        color: 'white',
        height: '80px',
        margin: '5px 0',
      }}
      draggable="true"
      onDragStart={e => handleDragStart(e)}
      onDrag={() => {}}
    >
      <Col span={24} style={{ height: 'inherit' }}>
        <Row align="middle" style={{ height: 'inherit' }}>
          <Col span={2} style={{ backgroundColor: `${colors.black1}`, ...itemAlignment.center, height: 'inherit' }}>
            <GripIcon width="18px" height="18px" style={{ color: `${colors.white}` }} />
          </Col>
          <Col
            span={19}
            style={{
              padding: '4px 10px 4px 25px',
              display: 'flex',
              flexDirection: 'column',
              height: 'inherit',
              justifyContent: 'center',
            }}
          >
            <Text style={{ color: `${colors.white}`, fontSize: fontSizes.body, marginBottom: '5px' }}>
              {riskModelScore.name}
            </Text>
            <Text
              style={{
                fontSize: fontSizes[0],
                color: colors.disabled,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {riskModelScore.description}
            </Text>
          </Col>
          <Col span={3} style={{ ...itemAlignment.center, height: 'inherit' }}>
            {riskModelScore.user_editable && (
              <Tooltip title="This is a user editable score">
                <EditOutlined width="18px" height="18px" style={{ color: `${colors.disabled}` }} />
              </Tooltip>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ScorePanel = ({ usedRiskModelScores, name, key, familyId, riskModelScores, ...props }) => {
  const { colors } = useTheme();
  const StyledPanel = styled(Panel)`
    border: none !important;
    color: white !important;
    .ant-collapse-header {
      color: white !important;
      background-color: ${colors.black3};
    }
    margin-bottom: 5px;
    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  `;

  const properName = name
    ? name
        .split('_')
        .map(w => w.toUpperCase())
        .join(' ')
    : '';
  return (
    <StyledPanel {...props} header={properName}>
      {riskModelScores &&
        riskModelScores
          .filter(x => !usedRiskModelScores.includes(x.id))
          .map((score, i) => (
            <RiskModelScoreComponent key={`${score.id} - ${i}`} riskModelScore={score} familyId={familyId} />
          ))}
    </StyledPanel>
  );
};

const setScoreListHeight = scoreLength => {
  if (!scoreLength) return;
  return `${scoreLength * 55}px`;
};

const ScoresListComponent = ({ usedRiskModelScores, RiskModelScores }) => {
  const { space, colors } = useTheme();
  return (
    <>
      <Card
        title="Scores List"
        className="score-list-card"
        headStyle={{
          backgroundColor: `${colors.black3}`,
          color: 'white',
          border: `0.3px solid ${colors.white}14`,
          borderRadius: '4px 4px 0px 0px',
          height: '40px',
        }}
        style={{
          marginLeft: `${space.xsmall}px`,
          padding: '0px',
          backgroundColor: `${colors.black3}`,
          color: 'white',
          border: 'none',
        }}
        bodyStyle={{ padding: '16px', position: 'relative', top: '2px', backgroundColor: `${colors.black4}` }}
      >
        <Collapse
          accordion="true"
          expandIconPosition="right"
          bordered={false}
          style={{
            color: 'white',
            border: 'none',
            overflow: 'auto',
            backgroundColor: `${colors.black4}`,
            maxHeight: setScoreListHeight(RiskModelScores?.length),
          }}
        >
          {RiskModelScores &&
            RiskModelScores.map(x => (
              <ScorePanel
                key={x.id}
                familyId={x.id}
                name={x.name}
                riskModelScores={x.riskModelScores}
                usedRiskModelScores={usedRiskModelScores}
              />
            ))}
        </Collapse>
      </Card>
    </>
  );
};

function mapStateToProps({ riskModels, router }) {
  return {
    ...riskModels,
    location: router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoresListComponent);
