import { combineEpics } from 'redux-observable';
import { apiCallPendingEpic, apiCallEpic } from './apiCallEpics';
import { createAssetsRedirectEpic, deleteAssetRedirectEpic } from 'admin/assets/epics/routes';
import riskProfileEpics from 'admin/riskprofiles/epics/routes';
import riskModelEpics from 'admin/riskmodels/epics/routes';
import assetsEpics from 'admin/assets/epics/routes';
import lanesEpics from 'admin/lanes/epics/routes';
import assetGroupEpics from 'admin/assetgroups/epics/routes';
import materialGroupEpics from 'admin/materialgroups/epics/routes';
import productGroupEpics from 'admin/productgroups/epics/routes';
import viewsEpics from '../views/epics/routes';
import workflowsEpics from 'admin/workflows/epics/routes';
import assetEpics from 'assets/epics/routes';
import corporatesEpics from 'admin/corporates/epics/routes';
import feedbackEpics from 'feedback/epics/routes';
import userGroupEpics from 'admin/usergroups/epics/routes';
import networkGraphEpics from 'discover/epics/networkGraph';
import countryRiskEpics from 'countries/epics/routes';
import riskfeedEpics from 'riskfeed/epics/routes';
import userProfileEpics from 'modal/userprofile/epics/routes';
import planPlaybookEpics from 'admin/playbooks/epics/routes';

const allEpics = Object.values({
  apiCallEpic,
  apiCallPendingEpic,
  createAssetsRedirectEpic,
  deleteAssetRedirectEpic,
  ...riskProfileEpics,
  ...corporatesEpics,
  ...countryRiskEpics,
  ...assetGroupEpics,
  ...materialGroupEpics,
  ...productGroupEpics,
  ...userGroupEpics,
  ...assetsEpics,
  ...lanesEpics,
  ...riskModelEpics,
  ...viewsEpics,
  ...workflowsEpics,
  ...assetEpics,
  ...feedbackEpics,
  ...networkGraphEpics,
  ...riskfeedEpics,
  ...userProfileEpics,
  ...planPlaybookEpics,
});

const rootEpic = combineEpics(...allEpics);

export default rootEpic;
