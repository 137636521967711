import React, { useEffect } from 'react';
import { Select } from 'layout/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'modal/userprofile/modules/network';

const CountrySelector = ({ ...props }) => {
  const dispatch = useDispatch();
  const { controlFormattedCountries } = useSelector(store => store.userNetwork);
  const { operator, name } = props.filter;
  const multi = operator === 'in';

  const onChange = value => {
    props.onChange({
      value,
      operator,
      name,
    });
  };

  const value = props.filter.value;

  useEffect(() => {
    if (!controlFormattedCountries) dispatch(getCountries());
  }, [controlFormattedCountries, dispatch]);

  return (
    <>
      <div>Country</div>
      <Select
        key={props.filter.name}
        isMulti={multi}
        stayOpen={multi}
        selected={value}
        onChange={onChange}
        options={controlFormattedCountries}
        backspaceRemoves
      />
    </>
  );
};

export default CountrySelector;
