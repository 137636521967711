import { UNITS_ENUM } from 'common/Constants';
import moment from 'moment';

export const normalizeName = name => {
  return name.replace(' ', '-');
};

export const getHistoryValues = history => {
  let overall = [];
  let indices = {};
  history.forEach((item, key) => {
    const date = new Date(item.created_date);
    const hdate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    overall.push([hdate, item.score]);
    item.indices.forEach(index => {
      let name = normalizeName(index.name);
      if (!indices[name]) {
        indices[name] = {
          scores: [],
          indices: {},
        };
      }
      index.indices.forEach(subindex => {
        let subname = normalizeName(subindex.name);
        if (!indices[name].indices[subname]) {
          indices[name].indices[subname] = {
            scores: [],
            component: {},
          };
        }
        subindex.components.forEach(component => {
          let componentName = normalizeName(component.name);
          if (!indices[name].indices[subname].component[componentName]) {
            indices[name].indices[subname].component[componentName] = [];
          }
          indices[name].indices[subname].component[componentName].push([hdate, component.score]);
        });
        indices[name].indices[subname].scores.push([hdate, subindex.score]);
      });
      indices[name].scores.push([hdate, index.score]);
    });
  });
  return {
    overall: overall,
    indices: indices,
  };
};

export const isStateEmpty = state => {
  for (const key in state) {
    if (Array.isArray(state[key]) && state[key].length) {
      return false;
    }
  }
  return true;
};

export const assignState = state => {
  return Object.keys(state).reduce((acc, cv) => {
    if (Array.isArray(state[cv])) {
      acc[cv] = [...state[cv]];
    } else if (typeof state[cv] === 'boolean') {
      acc[cv] = state[cv];
    } else {
      acc[cv] = assignState(state[cv]);
    }
    return acc;
  }, {});
};

export const getData = (model, risk, riskIndex) => {
  const componentScores = Object.assign(
    {},
    ...riskIndex.indices.flatMap(x =>
      x.components.map(c => {
        return {
          [c.id]: c,
        };
      }),
    ),
  );

  const components = model.indices.flatMap(index => {
    const indexComponents = index.components.map(c => {
      const backupScore = {
        id: c.id,
        name: c.name,
        trend: 'neutral',
        category: 'low',
        score: 0,
      };
      const score = componentScores[c.id] || backupScore;

      if (score.score === null) {
        score.category = 'none';
      }

      return {
        ...c,
        ...score,
        index,
      };
    });
    return indexComponents;
  });

  components.sort((x, y) => {
    // sort by score
    if (x.score > y.score) return -1;
    if (x.score < y.score) return 1;
    // sort alphabetically
    if (x.name > y.name) return 1;
    if (x.name < y.name) return -1;

    return 0;
  });

  const categories = Object.assign(
    {},
    ...['high', 'medium', 'low', 'none'].map(category => {
      return {
        [category]: components.filter(x => x.category === category),
      };
    }),
  );

  return Object.values(categories).reduce((arr, cat) => {
    return arr.concat(cat);
  }, []);
};

export const getSingleScoreData = (riskIndex, compId) => {
  const component = riskIndex.components.find(c => c.component_id === compId);
  return {
    ...component,
    riskIndex,
  };
};

export const getAllComponentsFromRiskIndex = riskIndex => {
  if (!riskIndex) {
    return [];
  }
  return riskIndex.indices
    .reduce((acc, index) => {
      if (index.indices && index.indices.length) {
        acc = acc.concat(getAllComponentsFromRiskIndex(index));
      }
      acc.push(index.components);

      return acc;
    }, [])
    .flat();
};

export const getFormattedDate = (date, format) =>
  moment(date)
    .utc()
    .format(format);

export function transformRiskDetails(datasource, riskDetails) {
  if (datasource === 'countries') {
    riskDetails.related_attributes.forEach(attr => {
      let score = '';
      if (attr['data']) {
        let data = attr['data'];
        if (data['score']) {
          score = data['score'];
        }
      }
      attr['value'] = score;
    });
  }
  return riskDetails;
}

export const getUnits = (val, units, system) => {
  if (system === 'm') {
    switch (units) {
      case UNITS_ENUM.degC:
        return { value: val, units: UNITS_ENUM.degC };
      case UNITS_ENUM.degF:
        return { value: Math.round((5 / 9) * (val - 32)), units: UNITS_ENUM.degC };
      case UNITS_ENUM.lengthCM:
        return { value: val, units: UNITS_ENUM.lengthCM };
      case UNITS_ENUM.lengthMM:
        return { value: val, units: UNITS_ENUM.lengthMM };
      case UNITS_ENUM.lengthInch:
        return { value: Math.round(val * 25.4), units: UNITS_ENUM.lengthMM };
      case UNITS_ENUM.speedMPH:
        return { value: Math.round(val * 1.60934), units: UNITS_ENUM.speedKMPH };
      case UNITS_ENUM.speedKMPH:
        return { value: val, units: UNITS_ENUM.speedKMPH };
      default:
        return { value: val, units: UNITS_ENUM.default };
    }
  } else {
    switch (units) {
      case UNITS_ENUM.degC:
        return { value: Math.round((9 * val) / 5 + 32), units: UNITS_ENUM.degF };
      case UNITS_ENUM.degF:
        return { value: val, units: UNITS_ENUM.degF };
      case UNITS_ENUM.lengthCM:
        return { value: Math.round(val * 0.393701), units: UNITS_ENUM.lengthInch };
      case UNITS_ENUM.lengthMM:
        return { value: Math.round(val * 0.0393701), units: UNITS_ENUM.lengthInch };
      case UNITS_ENUM.lengthInch:
        return { value: val, units: UNITS_ENUM.lengthInch };
      case UNITS_ENUM.speedMPH:
        return { value: val, units: UNITS_ENUM.speedMPH };
      case UNITS_ENUM.speedKMPH:
        return { value: Math.round(val * 0.621371), units: UNITS_ENUM.speedMPH };
      default:
        return { value: val, units: UNITS_ENUM.default };
    }
  }
};

export const forecastRisks = (risks, timeExtent) => {
  const formattedRisks = {};
  for (let i = moment(timeExtent[0]); i.isBefore(moment(timeExtent[1]).add(12, 'hours')); i.add(1, 'hours')) {
    formattedRisks[i.unix()] = [i.toDate(), 1, []];
  }
  if (risks?.start) {
    for (let i = moment(risks.start).startOf('hour'); i.isBefore(moment(risks.end).endOf('hour')); i.add(1, 'hours')) {
      if (formattedRisks[i.unix()]) {
        formattedRisks[i.unix()] = [i.toDate(), risks.score, risks.phenomena];
      }
    }
  }
  return Object.values(formattedRisks);
};
