import styled from '@emotion/styled';
import { Form } from 'antd';
import theme from 'theme';

const FormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    white-space: nowrap;
    margin-top: 5px;
  }

  .ant-form-item-label > label {
    color: ${props => props.theme.colors.white};
  }

  &.ant-form-item-has-error {
    .ant-input-affix-wrapper, input {
      background-color: ${props => props.theme.colors.black1} !important;
    }
  }

  .ant-form-item-control-input-content {
    height: auto;
  }

  input[type='text'] {
    height: 36px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item-has-error .ant-input {
    background-color: transparent;
    color: ${theme.colors.danger};
  }
`;

export { FormItem, StyledForm };
