import styled from '@emotion/styled';
import {
  CloseOutlined as Delete,
  DownOutlined as Down,
  EditOutlined as Edit,
  ExportOutlined as Export,
  ImportOutlined as Import,
  PlusOutlined as Plus,
  UploadOutlined as Upload,
  CheckOutlined as Check,
} from '@ant-design/icons';
import { variant } from 'styled-system';
import { Tag } from 'antd';

const IconVariants = {
  delete: variant({
    variants: {
      primary: {
        color: 'primary !important',
      },
      alternate: {
        color: 'white',
      },
    },
  }),
  plus: variant({
    variants: {
      primary: {
        color: 'primary !important',
      },
      alternate: {
        color: 'white',
      },
    },
  }),
  edit: variant({
    variants: {
      alternate: {
        color: 'white !important',
      },
    },
  }),
};

const DeleteIcon = styled(Delete)`
  ${IconVariants.delete}
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const EditIcon = styled(Edit)`
  color: ${props => props.theme.colors.primary} !important;
  ${IconVariants.edit}
  font-weight: bold;
  font-size: ${props => props.fontSize || '12px'};
  line-height: 12px;
  cursor: pointer;
`;

const DownIcon = styled(Down)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const Badge = styled(Tag)`
  border: none;
  align-items: center;
  padding: 6px 12px;
  background: ${props => props.theme.colors.primary} !important;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  .ant-tag-close-icon svg {
    fill: white !important;
    color: white !important;
  }
`;

const PlusIcon = styled(Plus)`
  ${IconVariants.plus}
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const UploadIcon = styled(Upload)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
`;

const ExportIcon = styled(Export)`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const ImportIcon = styled(Import)`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

const CheckIcon = styled(Check)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
`;

export { Badge, DeleteIcon, DownIcon, EditIcon, PlusIcon, UploadIcon, ExportIcon, ImportIcon, CheckIcon };
