import React, { useEffect } from 'react';
import { CHANNEL_ACTIONS } from 'common/Constants';
import EmailPreference from './EmailPreference';
import { Checkbox, Button } from 'antd';
import styled from '@emotion/styled';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from '@luxe/components';

import { getWorkflow, updateWorkflow } from 'admin/workflows/modules/workflows';
import { push } from 'connected-react-router';

import { getUserProfileChannels } from 'modal/userprofile/modules/notifications';
import UserProfileCancelButton from '../UserProfileCancelButton';
import { getRules } from 'admin/workflows/modules/rules';

export const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + span {
    border-color: ${props => props.theme.colors.white} !important;
    border: 1px solid;
  }

  input[type='checkbox']:checked + span {
    border-color: ${props => props.theme.colors.primary} !important;
    background-color: ${props => props.theme.colors.primary} !important;
  }

  input[type='checkbox'] + span {
    background-color: ${props => props.theme.colors.background};
    border-color: ${props => props.theme.colors.white};
    &:focus {
      border-color: white !important;
    }
    &:disabled {
      background-color: ${props => props.theme.colors.disabled} !important;
    }
  }
`;
const NotificationsView = styled(({ ...props }) => {
  const workflow = useSelector(store => store.workflows)?.workflow;
  const discoverPref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.discover?.enabled || false);
  const includedChannels = useSelector(store => store?.accountOrg?.accountOrg?.prefs?.digests?.include_channels);
  const commodityPref = useSelector(store => store.accountOrg?.accountOrg?.prefs?.commodity_risk?.enabled || false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.userWorkflowId) {
      dispatch(getWorkflow({ id: props.userWorkflowId }));
      dispatch(getRules(props.userWorkflowId));
    }
  }, [dispatch, props.userWorkflowId]);

  if (!workflow.data) {
    return (
      <div style={{ padding: '38px', paddingRight: '200px' }}>
        <Skeleton />
      </div>
    );
  }

  const captureChannelPreference = event => {
    const updatedProfile = {
      [event.action]: {
        is_realtime: event.is_realtime ? event.is_realtime : false,
        frequency: event.frequency,
      },
    };
    props.updateProfile(updatedProfile);
  };

  const handleCheckboxChange = e => {
    const val = e.target.name;
    let channel = workflow?.data.channel || [];
    let newChannels = [...channel];
    if (e.target.checked) {
      newChannels.push(val);
    } else {
      newChannels = newChannels.filter(x => x !== val);
    }
    dispatch(updateWorkflow({ data: { channel: newChannels } }));
  };

  const handleSave = () => {
    const channelData = { channel: workflow?.data?.channel || [] };
    props.onSave(channelData);
  };

  const SectionDivider = styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    height: 1px;
    margin-bottom: 15px;
  `;

  const ACTIONS = CHANNEL_ACTIONS.filter(a =>
    a.key === 'COMMODITY'
      ? Boolean(commodityPref)
      : discoverPref && ['SUB_TIER_NETWORK_SUMMARY'].includes(a.key)
      ? true
      : (includedChannels === undefined && a.key !== 'SUB_TIER_NETWORK_SUMMARY') || includedChannels?.includes(a.key),
  );

  return (
    <div className={`${props.className}`}>
      <div className={'header'}>
        <Button className={'submit profile-button'} onClick={handleSave}>
          Save
        </Button>
        <UserProfileCancelButton />
        {!props.userDetailView && (
          <span>
            <h1>Notification Settings</h1>
            <SectionDivider />
          </span>
        )}
      </div>
      <div className={'profile-contents'}>
        <div className={'notification-title'}>
          <label className={'realtime-title'}>Real Time</label>
          <label className={'channel-title'}>Digest</label>
        </div>
        {ACTIONS.map(a => (
          <EmailPreference
            label={a.name}
            name={a.value}
            key={a.key}
            updateAction={captureChannelPreference}
            action={props.userProfile[a.value]}
          />
        ))}
      </div>
      <label className={'channel-prefs-label'}>Network Notification Channel</label>
      {props.userWorkflowId && (
        <div className="channel-preferences">
          <StyledCheckbox
            className="channel-pref"
            onChange={handleCheckboxChange}
            defaultChecked={workflow?.data?.channel?.indexOf('email') > -1}
            name="email"
          >
            Email
          </StyledCheckbox>
        </div>
      )}
    </div>
  );
})``;

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserProfileChannels,
      push,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsView);
