import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

export default class ThreatRiskScoreSelector extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onScoreChange = e => {
    let riskScore = e.target.value;
    const value = [riskScore, this.currentAssetType];
    this.props.onChange({ target: { value } });
  };

  onAssetTypeClick = assetType => {
    const value = [this.currentRiskScore, assetType.value];
    this.props.onChange({ target: { value } });
  };

  get currentRiskScore() {
    return this.props.value && this.props.value.length === 2 ? this.props.value[0] : 1;
  }

  get currentAssetType() {
    return this.props.value && this.props.value.length === 2 ? this.props.value[1] : 'asset';
  }

  get assetTypes() {
    return ['assets', 'lanes', 'shipments'].map(value => {
      return {
        value,
        label: value,
      };
    });
  }

  render() {
    return (
      <div>
        <div className="form-group col-md-4">
          <label>Score</label>
          <br />
          <input className="form-control" onChange={this.onScoreChange} type="text" value={this.currentRiskScore} />
        </div>
        <div className="form-group col-md-3">
          <label>Asset Type</label>
          <br />
          <Select
            multi={false}
            stayOpen={false}
            value={this.currentAssetType}
            onChange={this.onAssetTypeClick}
            options={this.assetTypes}
            backspaceRemoves
          />
        </div>
      </div>
    );
  }
}
