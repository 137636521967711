import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubTiersTab from 'riskfeed/containers/RiskFeedView/Detail/SubTiersTab';
import { getThreat } from 'riskfeed/modules/riskfeed';

const SubTierTable = ({ incId, hideClose }) => {
  const dispatch = useDispatch();
  const { item: threatDetail } = useSelector(state => state.riskfeed?.threat);
  const [isSubTableOpen, setIsSubTableOpen] = useState(false);

  useEffect(() => {
    dispatch(getThreat(incId));
  }, [dispatch, incId]);

  const subTableToggle = flag => {
    setIsSubTableOpen(flag);
    hideClose(flag);
  };

  return (
    <>
      <Col style={{ width: '100%', marginBottom: '10px', paddingLeft: '8px', paddingRight: '8px' }}>
        {!isSubTableOpen && (
          <Row style={{ lineHeight: '40px' }}>
            {threatDetail?.headline && (
              <label style={{ margin: '0 0 10px 0', fontSize: '16px' }}>
                Discovered facilities for{' '}
                <strong>{`${threatDetail?.headline} (${threatDetail?.risk?.discovered_assets?.count ?? 0})`}</strong>
              </label>
            )}
          </Row>
        )}
        <SubTiersTab incId={incId} subTableToggle={subTableToggle} fromList={true} />
      </Col>
    </>
  );
};

export default SubTierTable;
